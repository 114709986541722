import BaseMod, {ISomeAsset} from "./BaseMod";

// DamAssetMetadata[] actual array that is being worked on

class WebDamMod extends BaseMod {

  public enhanceWebDamAssets(assets: ISomeAsset[]): ISomeAsset[] {
    this.sortAssets(assets);
    return assets;
  }

}

export default WebDamMod;
