import React, {useContext, useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FacetMemberInfo, SideNavChildrenProps} from 'types/searchTypes';
import ClearFacetButton from "../ClearFacetButton";
import useFacetSelections from "features/Facets/hooks/useFacetSelections";
import {alignMemberCounts, filterMembersForBusinessReason} from "../facetUtil";
import {isNullish} from "../../../../utils/general";
import {FacetContext} from "../../../../app/facetContext";
import {useGlobalState} from "../../../../app/state/useGlobalState";
import {useObserver} from "mobx-react";

export default function CategoryFacet(props: SideNavChildrenProps) {
  const classes = useStyles();
  // manage items that are selected

  // if (props.field === 'verticalMarket') {
  //   console.log('!@#97 verticalMarket props', props);
  // }
  const facetCache = useContext(FacetContext);
  // todo: rewrite to other versions of this hook
  const [memberCounts, facetActions] = useFacetSelections(props, facetCache);
  // manage facet component expanded state
  //const [expandedFacetId, {setExpandedFacetId}] = useExpandedFacetId();
  const gs = useGlobalState();
  const [expanded, setExpanded] = useState(false);

  const handleToggleMode = () => facetActions.toggleMode();
  const handleToggleValue = (value: string | number) => facetActions.toggleValue(value);

  const handleResetAndClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(false);
    // expandedFacetId_FIX
    gs.expandedFacetId = undefined;
    facetActions.clearFacetSelections();
  }

  function handleAccordionChange(_event: unknown) {
    // if it is already my id then it shouldn't be me and I should close
    const nextId = (gs.expandedFacetId === props.id) ? 'none' : props.id;
    gs.setExpandedFacetId(nextId);
    // console.log('!!!gs before set', {
    //       test: (gs.expandedFacetId === props.id), nextId,
    //       'gs.expandedFacetId': gs.expandedFacetId,
    //       'props.id': props.id
    //     }
    // );
    // const nextId = (gs.facets.expandedFacetId === props.id) ? undefined: props.id;

    // gs.facets = {...gs.facets, ...{expandedFacetId: nextId}};
    // const nextId = (gs.expandedFacetId === props.id) ? undefined: props.id;
    // gs.expandedFacetId = nextId;
    console.log('!!!gs proof', gs.expandedFacetId);
  }

  const facetSelections = facetActions.getFacetSelections();

  // prep latest counts
  useEffect(() => {
    //console.log('!@@ CategoryFacet useEffect', memberCounts);
    if (memberCounts) {
      alignMemberCounts(props.members!, memberCounts!);
    }
  }, [memberCounts]);

  // business filter
  const members = filterMembersForBusinessReason(props.entityName, props.field, props.members);

  const currentMode = (!isNullish(facetSelections?.mode))
      ? facetSelections?.mode
      : props.defaultMode;


  return useObserver(() =>
      <>
        <Accordion className={classes.accordion}
                   TransitionProps={{timeout: 50}}
                   onChange={handleAccordionChange}
                   expanded={gs.expandedFacetId === props.id}>

          <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreIcon/>}>
            <ClearFacetButton visible={facetSelections?.values?.length > 0}
                              onClick={handleResetAndClose}/>
            <Typography className="text-left">{props.label}</Typography>
          </AccordionSummary>

          <AccordionDetails className={classes.accordionDetails} style={{fontSize: '14px'}}>
            <div>
              {props.type === 'multiselect' ? (
                  <div>
                    <span>Any selected value(s)</span>
                    <Switch color="primary" checked={currentMode === 'allof'} onChange={handleToggleMode}/>
                    <span>All selected value(s)</span>
                  </div>
              ) : <></>}
              <List>
                {members?.filter((fmi: FacetMemberInfo) => fmi.count === undefined || fmi.count > 0)
                    .map((member: FacetMemberInfo, index: number) => (
                        <ListItem style={{height: 'auto', marginTop: '1px', width: '100%', padding: '4px 4px'}}
                                  disableGutters button key={index}
                                  selected={facetSelections?.values?.includes(member.value ?? '')}
                                  onClick={() => handleToggleValue(member.value ?? '')}
                        ><ListItemText style={{display: 'flex', justifyContent: 'space-between'}}
                                       primary={<Typography className={classes.itemLabel}>{member.value}</Typography>}
                                       secondary={<Typography
                                           className={classes.itemCount}>({member.count})</Typography>}
                        /></ListItem>
                    ))}
              </List>
            </div>

          </AccordionDetails>
        </Accordion>
        <div className="accordionDetailEnd"></div>
      </>
  );
}

const useStyles = makeStyles(() => ({
  itemLabel: {
    fontSize: '14px',
    color: '#000',
  },
  itemCount: {
    fontSize: '14px',
    color: '#999',
  },
  // start accordion style
  accordion: {
    width: '275px',
    //borderTop: '1px solid #bbbbb',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    fontSize: '14px',
    '& > div': {
      width: '100%',
    }
  }
}));