import { partition } from 'lodash';
import dateFormat from "../patterns/dateFormat";
export interface IViewField {
  property: string;
  label: string;
  value?: string;
}

export const findAndSplit = (arr: IViewField[], condition: (field: IViewField) => boolean): [IViewField | undefined, IViewField[]] => {
  const [matchingFields, nonMatchingFields] = partition(arr, condition);
  return [matchingFields[0], nonMatchingFields];
};

export default class DashDisplayModel {
  toViewFieldArray(): IViewField[] {
    const fields: IViewField[] = [];
    const properties = Object.getOwnPropertyNames(this);

    for (const property of properties) {
      const label = Reflect.getMetadata('label', this, property);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let value = this[property];
      if (value instanceof Date) {
        value = dateFormat(value);
      }


      if (label) {
        fields.push({ label, value, property });
      }
    }

    return fields;
  }
}
