import { useState, useEffect } from 'react';
import {FacetSettingsInfo} from "../types/searchTypes";
import {serialize} from "../components/search/FacetSideNav/serdeUtil";
import {useSearchParams} from "react-router-dom";

export interface IFacetState<T> {
    entityName: string,
    field: string,
    mode: string,
    selectedItems: T[],
}

type UseFacetState<T> = {
    field: string;
    mode: string;
    selectedItems: T[];
    setMode: (mode: string) => void;
    setSelectedItems: (selectedItems: T[]) => void;
}


export default function useFacetState<T extends { value: string }>(initState: IFacetState<T>, onChange: (state: IFacetState<T>) => void): UseFacetState<T> {
    const [searchParams, setSearchParams] = useSearchParams();
    const entityName = initState.entityName;
    const [field] = useState(initState.field);
    const [mode, setMode] = useState(initState.mode);
    const [selectedItems, setSelectedItems] = useState<T[]>([]);

    useEffect(() => {
        onChange({ field, mode, selectedItems } as IFacetState<T>);
    }, [field, mode, selectedItems]);

    function updateSearchParams(mode: string, selectedItems: T[]) {
        const sp = Object.fromEntries(searchParams.entries());
        const values = selectedItems.map(x => x.value);
        if (values.length === 0) {
            delete sp[field];
        } else {
            const fsi: FacetSettingsInfo = {field, mode, values};
            sp[field] = serialize(fsi);
        }
        const newSearchParams = new URLSearchParams(sp);
        setSearchParams(newSearchParams, { replace: true });
    }

    const setModePassThru = (mode: string) => {
        setMode(mode);
        updateSearchParams(mode, selectedItems);
    }

    const setSelectedItemsPassThru = (selectedItems: T[]) => {
        setSelectedItems(selectedItems);
        updateSearchParams(mode, selectedItems);
    }

    return {
        field,
        mode,
        setMode: setModePassThru,
        selectedItems,
        setSelectedItems: setSelectedItemsPassThru,
    };
}
