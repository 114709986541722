import React, { useEffect, useState } from 'react';

interface ExportProgressGraphicProps {
  amountComplete?: number;
}

const BASE_LOCATION_GIFS = "/images/gifs";

const ExportProgressGraphic: React.FC<ExportProgressGraphicProps> = ({ amountComplete = 0 }) => {
  const [buildingImage, setBuildingImage] = useState<string>("");
  const [showCraneImage, setShowCraneImage] = useState<boolean>(false);

  useEffect(() => {
    console.log('set amount complete', amountComplete);
    if (amountComplete >= 0 && amountComplete <= 1) {
      updateImages(amountComplete);
    }
  }, [amountComplete]);

  const getBuildingImageName = (amountComplete: number): string => {
    if (amountComplete < 0.2) return "building_0.png";
    else if (amountComplete < 0.4) return "building_20.png";
    else if (amountComplete < 0.6) return "building_40.png";
    else if (amountComplete < 0.8) return "building_60.png";
    else if (amountComplete < 1.0) return "building_80.png";
    else return "building_100.png";
  }

  const updateImages = (amountComplete: number) => {
    setBuildingImage(`${BASE_LOCATION_GIFS}/${getBuildingImageName(amountComplete)}`);
    setShowCraneImage(amountComplete < 1.0);
    console.log('updated images has been called', amountComplete);
  }

  return (
      <div className="w-full flex justify-center">
        {showCraneImage && <img src={`${BASE_LOCATION_GIFS}/crane_v2.gif`} style={{ display: "inline-block" }} alt="" />}
        <img src={buildingImage} style={{ display: "inline-block" }} alt="" />
      </div>
  )
}

export default ExportProgressGraphic;
