import React, { useState, useEffect,
  useImperativeHandle, forwardRef, useRef, ForwardedRef
} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DisplayField from "../../shared/DisplayField";
import EditIcon from '@material-ui/icons/Edit';
import AthenaClient, {ApiPerson,IApiPerson,IApiProject,
  IApiProjectStakeholder
} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {Card, CardContent, CardHeader,
  CircularProgress, Switch, Dialog
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import DashDetailsField from "components/DashDetailsField";
import numberFormatter from "services/shared/numberFormatter";
import EntityDialog from "../../shared/EntityDialog";
import {getNamedData} from "utils/forms";
import PersonDetailService from "services/PersonService";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import { v4 as uuidv4 } from 'uuid';
import {commasFmt, currencyFmt } from "utils/numberToStringUtil";
import {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
// end imports

const SECTION_ID = 'projectAssetApprovalSection';

const ProjectAssetChecklistApprovalSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & {onSave?: ()=> void}>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {

      const classes = useSharedLargeCardStyles();
      const [model, setModel] = useState<IApiProject | null>(null);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
  const [sectionKey,setSectionKey] = useState(uuidv4());

      if (model === null) {
        setModel(props.model);
      }

      const toggleEditMode = () => {
        props.setEditMode(!props.editMode);
      }

      useImperativeHandle(forwardRef, () => ({
        save: () => {
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);

          athenaClient?.patchProject(data).then(m => {
            setModel(m);
            props.setEditMode(false);
            saved();
          });
        }
      }));


      if (props.model === null || model === null) {
        console.log('project is null');
        return <DashItemProgress/>;
      }

      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      return (
          <React.Fragment>
            {/* id */}
            <DashDetailsField mode="hidden"
                              label="Id"
                              showLabel={false}
                              editMode={props.editMode}
                              name="id"
                              defaultValue={props.model.id}
            />

            {/* assetChecklist reference */}
            <DashDetailsField mode="reference"
                              label="Project Asset Checklist"
                              showLabel={true}
                              width="100%"
                              editMode={false}
                              name="projectAssetChecklist"
                              defaultValue="/images/projects/asset_checklist.pdf"
            />

            {/* projectAssetChecklistApproval */}
            <DashDetailsField mode="approval"
                              label=""
                              showLabel={true}
                              width="100%"
                              editMode={props.editMode}
                              name="assetChecklistApproval"
                              valueGraph={model}
                              approvalKey="assetChecklist"
            />



            <DashDivider width="80vw" height="5px" />

            <Dialog
                open={showInfotipModal}
                onClose={() => setShowInfotipModal(false)}>
              <div className={classes.innerDialogPadding}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </Dialog>

          </React.Fragment>
      );
    });

export default withDashSection('Project Asset Checklist Approval', SECTION_ID, ProjectAssetChecklistApprovalSection);
