import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

const MSAL_SCOPES = ['User.Read'];
const API_SCOPES = ['8328d09c-c470-4b31-9734-ad386af72616/user_impersonation'];
const CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
const AD_TENANT_ID = process.env.REACT_APP_AZURE_AD_TENANT_ID;

export const loginRequest = {
  scopes: MSAL_SCOPES,
};

export const tokenRequest = {
  scopes: API_SCOPES,
};

const configuration: Configuration = {
  auth: {
    clientId: `${CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${AD_TENANT_ID}/`,
    redirectUri: '/',
    postLogoutRedirectUri: '/?clear=1',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // check this for old IE
  },
};

export const getMsalInstance = () => {
  const msalInstance = new PublicClientApplication(configuration);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  return msalInstance;
};

const acquireToken = async (msalInstance: PublicClientApplication) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  const request = {
    ...loginRequest,
    account: activeAccount ?? accounts[0],
  };

  const apiRequest = {
    ...tokenRequest,
    account: activeAccount ?? accounts[0],
  };

  return await msalInstance.acquireTokenSilent(apiRequest);
};

export const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
  const authResult = await acquireToken(msalInstance);
  return authResult.accessToken;
};

export const acquireIdToken = async (msalInstance: PublicClientApplication) => {
  const authResult = await acquireToken(msalInstance);
  return authResult.idToken;
};

export const logout = async (msalInstance: PublicClientApplication, url: string) => {
  try {
    await msalInstance.logoutRedirect({
      postLogoutRedirectUri: url,
    });
    // Code here will probably not be reached as logout will redirect the user.
  } catch (error) {
    console.error("Failed to logout: ", error);
    // Handle logout failure
  }
};


