const rawList = `
  default_icon.png
  avi_icon.png
  docx_icon.png
  dwg_icon.png
  indd_icon.png
  mpeg_icon.png
  pdf_icon.png
  ppt_icon.png
  rar_icon.png
  xlsx_icon.png
  zip_icon.png
`;

const fileExtImages: string[] = rawList.split('\n')
  .map((s) => s.trim())
  .filter((s) => s.length > 0);

export function getFileExtensionFromPath(path: string) {
      const lastDotIndex = path.lastIndexOf('.');
      if (lastDotIndex === -1) {
          return '';
      }
      return path.substring(lastDotIndex + 1);  
}

export function normalizeFileExtension(fileExtension: string) {
      switch (fileExtension.toLowerCase()) {
          case 'jpg':
          case 'jpeg':
              return 'jpg';
          case 'png':
              return 'png';
          case 'gif':
              return 'gif';
          case 'bmp':
              return 'bmp';
          case 'tiff':
          case 'tif':
              return 'tif';
          case 'indd':
                return 'indd';
          default:
              return 'default';
      }
}

// function to map the extensions above to the correct image url
export function getImageNameFromExtension(fileExtension: string) {
    if (fileExtImages.includes(normalizeFileExtension(fileExtension))) {
        return normalizeFileExtension(fileExtension) + '_icon.png';
    } else {
        return 'default_icon.png';
    }
}
