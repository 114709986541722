import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SchoolIcon from '@material-ui/icons/School';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import ContactsIcon from '@material-ui/icons/Contacts';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {DetailNavItemModel} from "./detailSideNavBarData";

export const peopleIcons: Record<string, JSX.Element> = {
  Person: <PersonIcon />,
  LocationOn: <LocationOnIcon />,
  Schedule: <ScheduleIcon />,
  School: <SchoolIcon />,
  LocalActivity: <LocalActivityIcon />,
  Contacts: <ContactsIcon />,
  AttachFile: <AttachFileIcon />,
};

export function getPeopleNavBarData() {
  return [
    { text: 'Details', to: 'person-details-module', iconName: 'Person'},
    { text: 'Location', to: 'location-module', iconName: 'LocationOn' },
    { text: 'Project History', to: 'project-history-module', iconName: 'Schedule' },
    { text: 'Education and Training', to: 'education-training-module', iconName: 'School' },
    { text: 'Awards', to: 'awards-module', iconName: 'LocalActivity' },
    { text: 'References', to:'references-module', iconName: 'Contacts' },
    { text: 'Assets', to:'assets-module', iconName: 'AttachFile' },
  ] as DetailNavItemModel[];
}