import React from "react";
import {Button, Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import {DeleteOutline, EditOutlined} from "@material-ui/icons";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import {makeStyles} from "@material-ui/core/styles";
import DataRow from "patterns/DataRow";
import InfotipButton from "../../InfotipButton/InfotipButton";

type ComponentType<T> = React.ComponentType<{ model: T }>;

type DashListProps2<T extends { id: string }, C extends ComponentType<T>> = {
  list: T[] | undefined;
  showEdit?: boolean;
  showDelete?: boolean;
  showAdd?: boolean;
  addButtonText?: string;
  infotipKey?: string;
  emptyText?: string;
  titleExpression?: (model: T) => string;
  tag?: string | number;
  onAction: (action: string, payload?: DataRow<{ id?: string }> | string) => void;
  Component: C;
};

const useStyles = makeStyles(() => ({
  gradientBackground: {
    background: 'linear-gradient(to right, #a1c8ff, white)',
  },
  cardHeader: {
    background: 'linear-gradient(to top, white 98%, #A6192E)',
    margin: '0 0',
    padding: '8px 12px',
    '& .MuiCardHeader-content': {
      flexGrow: 0,
    },
    '& .MuiCardHeader-action': {
      flexGrow: 1,
    },
    '& .MuiCardHeader-title': {
      fontSize: '1.25rem',
      flexGrow: 0,
    },
  },
  // for CardHeader
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap'
  },
  leftDiv: {
    textAlign: 'left'
  },
  middleDiv: {
    flexGrow: 1,
    textAlign: 'center'
  },
  rightDiv: {
    textAlign: 'right'
  }
}));

// function passThru(comment: string, obj: unknown) {
//     console.log(comment, obj);
//     return obj;
// }

function DashList2<T extends DataRow<{
  id?: string
}> | undefined, C extends ComponentType<T>>(props: DashListProps2<T, C>) {
  const classes = useStyles();

  function handleAction(action: string, payload?: DataRow<{ id?: string }> | string) {
    props.onAction(action, payload);
  }

  //console.log('DashList', props.list);

  if (props.list == null) {
    return (
        <span>{props.emptyText}</span>
    );
  }

  return (
      <div style={{width: '100%'}}>
        <div style={{width: '100%', paddingBottom: '5px'}}>
          {(props.showAdd === true || props.showAdd === undefined) &&
              <Button startIcon={<AddCircleOutline/>}
                      data-dash-id={'new-button'}
                      onClick={() => handleAction('new')}>
                {props.addButtonText}
              </Button>
          }
        </div>
        {props.list && props.list.map((item: T, index) =>
            item != null &&
            (<Card key={index}
                   style={{marginBottom: '15px'}}>
                  <CardHeader title={props.titleExpression && props.titleExpression(item)}
                              className={classes.cardHeader}
                              action={

                                <div className={classes.container}>
                                  <div className={classes.leftDiv}>
                                    {props.infotipKey && (
                                        <InfotipButton infotipKey={props.infotipKey}/>
                                    )}
                                  </div>
                                  <div className={classes.middleDiv}>
                                     {/*Middle */}
                                  </div>
                                  <div className={classes.rightDiv}>
                                    {props.showEdit && (
                                        <IconButton aria-label="edit"
                                                    onClick={() => handleAction('edit', item.value.id)}>
                                          <EditOutlined/>
                                        </IconButton>
                                    )}
                                    {props.showDelete && (
                                        <IconButton aria-label="delete"
                                                    onClick={() => handleAction('delete', item.value.id)}>
                                          <DeleteOutline/>
                                        </IconButton>
                                    )}
                                  </div>
                                </div>


                              }/>

                  <CardContent>
                    <props.Component model={item}/>
                  </CardContent>
                </Card>
            ))}
      </div>
  );
}

export default DashList2;