import {computed, makeAutoObservable} from "mobx";
import {IApiPerson, IApiProject} from "services/apiClients/AthenaClient";
import _ from "lodash";
import {FacetSettingsInfo} from "../../types/searchTypes";

type RefreshTimes = {
  projectDetail?: number;
  personDetail?: number;
  projectSearch?: number;
  peopleSearch?: number;
}

export class GlobalState {
  private _userRoles: string[] = [];
  expandedFacetId?: string = 'none';

  currentRecordCount = 0;

  refreshTimes: RefreshTimes;
  currentPerson: IApiPerson | null = null;
  currentProject: IApiProject | null = null;
  stakeholdersEditMode?: string;
  url?: string;
  personProjectHistoryTicks: number;
  projectStakeholdersTicks: number;
  projectStaffTicks: number;
  hiddenDiversitySections: number[] = [];

  stakeholdersListViewOn = true;
  projectStaffListViewOn = true;

  projectDetailTicks: number;
  clientInformationTicks: number;
  programInformationTicks: number;


  constructor() {
    this.refreshTimes = {
      projectDetail: undefined,
      personDetail: undefined,
      projectSearch: undefined,
      peopleSearch: undefined,
    } as RefreshTimes;

    this.personProjectHistoryTicks = 1;
    this.projectStakeholdersTicks = 1;
    this.projectStaffTicks = 1;
    this.projectDetailTicks = 1;
    this.clientInformationTicks = 1;
    this.programInformationTicks = 1;

    makeAutoObservable(this);
  }

  public setExpandedFacetId(value: string | undefined) {
    console.log('!!!gs incoming facetId');
    this.expandedFacetId = value;
  }

  setCurrentRecordCount(count: number) {
    this.currentRecordCount = count;
  }

  setCurrentPerson(person: IApiPerson) {
    this.currentPerson = person;
  }

  setCurrentProject(project: IApiProject) {
    this.currentProject = project;
  }

  setUrl(url: string) {
    this.url = url;
    // update the facets if needed.
  }

  public get userRoles(): string[] {
    return this._userRoles;
  }

  setUserRoles(roles: string[]) {
    this._userRoles = roles;
  }

  isUserAdmin(): boolean {
    return _.includes(this._userRoles ?? [], 'Admin');
  }

  isUserContributor(): boolean {
    return _.includes(this._userRoles ?? [], 'Contributor');
  }

  setStakeholdersEditMode(editMode?: string) {
    this.stakeholdersEditMode = editMode;
  }

  updateProjectDetailTicks() {
    this.projectDetailTicks = new Date().getTime();
  }

  updatePersonProjectHistoryTicks() {
    this.personProjectHistoryTicks = new Date().getTime();
  }

  updateProjectStakeholdersTicks() {
    this.projectStakeholdersTicks = new Date().getTime();
  }

  updateClientInformationTicks() {
    this.clientInformationTicks = new Date().getTime();
  }

  updateProgramInformationTicks() {
    this.programInformationTicks = new Date().getTime();
  }

  hideDiversitySection(index: number) {
    if (!this.hiddenDiversitySections.includes(index)) {
      this.hiddenDiversitySections.push(index);
    }
  }

  showDiversitySection(index: number) {
    const position = this.hiddenDiversitySections.indexOf(index);
    if (position !== -1) {
      this.hiddenDiversitySections.splice(position, 1);
    }
  }

  setStakeholdersListViewOn(value: boolean) {
    this.stakeholdersListViewOn = value;
    this.updateProjectStakeholdersTicks();
  }

  setProjectStaffListViewOn(value: boolean) {
    this.projectStaffListViewOn = value;
  }

  setDelayedStateChange(ms: number, func: () => void) {
    setTimeout(()=> {
      func();
    }, ms);
  }
}

const globalState = new GlobalState();
export default globalState;
