/* eslint-disable @typescript-eslint/no-explicit-any */
import defaultPeopleColDefs from 'constants/metadata/defaultPeopleColDefs.json';
import defaultProjectsColDefs from 'constants/metadata/defaultProjectsColDefs.json';
import moment from "moment";
import numeral from "numeral";


// field and header name
export const getDefaultColDefs = (entityType: string) => {
    let prep: {field:string,headerName:string,sortable:boolean,resizble:boolean}[] = []; 
    switch (entityType) {
        case 'people':
            prep = prepareColDefs(defaultPeopleColDefs as unknown);
            console.log('$ CASE Projects was called - prepareColDefs');
            break;
        case 'projects':            
            prep = prepareColDefs(defaultProjectsColDefs as unknown);
            console.log('$ CASE Projects was called - defaultProjectsColDefs');
            break;
        default:
            prep = [];
            break;
    }
    return prep;
};

function prepareColDefs(colDefs: any) {
    const newColDefs: { field: string, headerName: string, sortable: boolean, resizable: boolean }[] = [];
    colDefs.forEach((colDef: any) => {
        const newColDef = { field: '', headerName: '', sortable: true, resizable: true };
        newColDef.field = colDef.propertyName + '';
        newColDef.headerName = colDef.label + '';
        newColDef.sortable = true;
        newColDef.resizable = true;
        // final cleanup for projects
        if (newColDef.headerName === 'FT<sup>2</sup>') {
            newColDef.headerName = 'FT²';
        }
        newColDefs.push(newColDef);
    });
    return newColDefs;
}

export function prepareQueryResultRows(entityName: string, rows: any) {
    switch (entityName) {
        case 'people':
            return preparePeopleQueryResultRows(rows);
        case 'projects':
            return prepareProjectsQueryResultRows(rows);
        default:
            return [];
    }
}

export function preparePeopleQueryResultRows(rows: any) {
    const newRows: any[] = [];
    const today = new Date();
    console.log('preparePeopleQueryResultRows rows', rows);
    rows.forEach((item: any) => {
        const newItem = { ...item };
        newItem.location = item.city + ', ' + item.state;
        if (newItem.location === ', ') {
            newItem.location = undefined;
        }
        newItem.hpExperience = yearsAndMonthsDiff(today, item.hireDate);
        //newItem.totalProjectValue = moneyAndCommas(item.totalProjectValue);
        //newItem.totalSquareFootage = commas(item.totalSquareFootage);
        newRows.push(newItem);
    });
    return newRows;
}

export function prepareProjectsQueryResultRows(rows: any) {
    // for each rows item, add a new property called location
    // location = city + ', ' + state
    // function should use a forEach loop but spread to a new item
    // add the item ti a new array and return the new array
    //console.log("b4 prepareProjectsQueryResultRows", rows);
    const newRows: any[] = [];
    const today = new Date();
    rows.forEach((item: any) => {
        const newItem = { ...item };
        newItem.location = item.city + ', ' + item.state;
        if (newItem.location === ', ') {
            newItem.location = undefined;
        }
        // newItem.location = item.city + ', ' + item.state;
        // newItem.hpExperience = yearsAndMonthsDiff(today, item.hireDate);
        // newItem.totalProjectValue = moneyAndCommas(item.totalProjectValue);
        // newItem.totalSquareFootage = commas(item.totalSquareFootage);
        newRows.push(newItem);
    });
    return newRows;
}

// const hireDate = moment('2018-05-21T00:00:00');
// const today = moment(new Date());
// const yearsAndMonths = today.diff(hireDate, 'years') + ' yrs. ' + today.diff(hireDate, 'months') % 12 + '  mos.';
function yearsAndMonthsDiff(firstDate: Date, secondDate: Date) {
    const d1 = moment(firstDate);
    const d2 = moment(secondDate);
    return `${d1.diff(d2, 'years')} yrs. ${d1.diff(d2, 'months') % 12}  mos.`;
}

//// remove last two functions if not used after 2/1/2023
// function moneyAndCommas(num: number) {
//     return numeral(num).format('$0,0');
// }
//
// function commas(num: number) {
//     return numeral(num).format('0,0');
// }