import React, {ChangeEvent, useEffect, useState} from 'react';
import {Chip, MenuItem, TextField} from '@material-ui/core';
import {Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason} from '@material-ui/lab';
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'right',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#eee',
    color: '#555',
    // fontWeight: 'bold',
  }
}));

interface IProps {
  autocomplete?: true;
  values?: string[];
  choices?: string[];
  editMode: boolean;
  justifyChips?: "left" | "right" | "center";
  name?: string;
  hiddenInputValueFormat?: 'json' | 'csv' | undefined;
  onChange?: (values: string[]) => void;
}

export default function DashChipList(props: IProps) {
  const classes = useStyles();
  const choices = props.choices ? ['\t', ...props.choices] : [];
  const initValues: string[] = (props?.values) ? props.values : [];
  const [values, setValues] = useState<string[]>(initValues);
  const [selectedValue, setSelectedValue] = useState('\t');
  const [autoKey, setAutoKey] = useState(0);

  let autoMultiTextInput: HTMLInputElement | null;

  useEffect(() => {
    if(props.editMode === false){
      setValues(initValues);
    }
  }, [props.editMode])
  
  useEffect(() => {
    if(props.values){
      setValues(props.values);
    }
  }, [props.values])

  function alignValues(newValues: string[]) {
    if (props.name) {
      // handle hidden input
    }
    setValues(newValues);
  }

  const handleDelete = (value: string) => {
    const newValues = values.filter((v) => v !== value);
    console.log('newValues', newValues);
    alignValues(newValues);
    if (props.onChange) {
      props.onChange(newValues);
    }
  };

  function addValue(newValue: string) {
    if (values.includes(newValue) || newValue.trim() === '') {
      return;
    }

    const newValues = [...values, newValue];
    console.log('newValues', newValues);
    alignValues(newValues);
    if (props.onChange) {
      props.onChange(newValues);
    }
    setSelectedValue('\t');
  }

  const handleMenuItemClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    addValue(event.target.value);
  };

  const handlePotentialNewValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      console.log(event.currentTarget);
      // find the first text input node below the current target
      const input = event.currentTarget.querySelector('input[type="text"]') as HTMLInputElement;

      if (input) {
        const val = (input.value?.toString()?.trim() ?? '');
        //addValue(val);
      }
    }
  }

  const setAutoMultiTextInput = (textInput: HTMLInputElement) => {
    autoMultiTextInput = textInput;
  }

  useEffect(() => {
    if (autoMultiTextInput !== null && autoKey > 0) {
      autoMultiTextInput.focus();
    }

  }, [autoKey]);

  function handleAutocompleteChange(
      //event: object, value: string | string[], reason: string) {
      event: ChangeEvent<unknown>, value: string | null,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<string> | undefined) {

    if (autoMultiTextInput !== null) {
      switch (reason) {
        case 'create-option':
        case 'select-option':
          addValue(value as string);
          setAutoKey(x => x + 1);
          //autoMultiTextInput.value = '';

          break;
      }
    }
  }


  // todo: one thing out there requires not setting the data-format I believe - find it
  return (
      <>{/* to troubleshoot change type to text */}
        {props.name && props.hiddenInputValueFormat === undefined &&
            <input type="hidden" name={props.name}
                   data-format="js"
                   value={JSON.stringify(values)}/>
        }
        {props.name && props.hiddenInputValueFormat === 'json' &&
            <input type="hidden" name={props.name}
                   data-format="json"
                   value={JSON.stringify(values)}/>
        }
        {props.name && props.hiddenInputValueFormat === 'csv' &&
            <input type="hidden" name={props.name}
                   data-format="csv"
                   value={_.join(values, ',')}/>
        }
        <ul className={classes.root} style={{justifyContent: props.justifyChips}}>
          {values.map((item) => {
            return (
                <li key={item}>
                  <Chip key={item}
                        label={item}
                        className={classes.chip}
                        onDelete={!props.editMode ? undefined : () => {
                          handleDelete(item);
                          console.log(item);
                        }}
                  />

                </li>
            );
          })}
        </ul>
        {props.editMode ? getChoiceControl(props.autocomplete) : ''}
      </>
  );

  // select vs autocomplete
  function getChoiceControl(allowFreeText?: boolean) {
    if (!allowFreeText) {
      return (
          <>
            <TextField select onChange={handleMenuItemClick} value={selectedValue}
                       style={{minWidth: '300px'}} placeholder={'Search to add more...'}>
              {choices.map((item: string) => {
                return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                );
              })}
            </TextField>
            <input className="hidden"
                   data-purpose="to prevent search behavior in browser" value={''}/>
          </>);
    } else {
      return (
          <>
            <Autocomplete
                key={autoKey}
                options={choices}
                freeSolo={true}
                onChange={handleAutocompleteChange}
                onKeyDown={handlePotentialNewValue}
                getOptionLabel={(option) => option}
                //style={{ width: 300 }}
                renderInput={(params) => (
                    <TextField
                        key={autoKey}
                        inputRef={(x) => setAutoMultiTextInput(x)}
                        //InputProps={{autoFocus:true}}
                        {...params}
                        placeholder={'Search to add more. Press Enter to add a new value.'}/>
                )}/>
            <input className="hidden"
                type="text" data-purpose="to prevent search behavior in browser" />
          </>);
    }
  }
}




