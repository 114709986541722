import {GreenCheck} from 'components/dashCore/GreenCheck';
import {RedX} from 'components/dashCore/RedX';
import React, {useContext, useState} from 'react';

import {ListItemIcon, Menu, MenuItem, Toolbar, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {AssignmentReturned, Print} from '@material-ui/icons';

import {IApiPerson, IApiProject} from '../../../services/apiClients/AthenaClient';
import {formatDateForDash} from '../../../utils/dateUtil';
import {getDistrictDisplayName} from '../../../services/metadata/regionsEx';
import {commasFmt, getAge, milBilNumberFormat} from '../../../utils/numberToStringUtil';
import DashIconButton, {DashIconButtonEx} from '../../DashIconButton';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import {ExportAndSchemaContext} from "../../../features/export/ExportAndSchemaProvider";
import {useGlobalState} from "../../../app/state/useGlobalState";
import {useObserver} from "mobx-react";
import clsx from "clsx";

const CURRENT_PRINT_BASE_URL = process.env.REACT_APP_PRINTING_BASE_URL;
//'https://qa-dash-print.calmdesert-705125d4.centralus.azurecontainerapps.io';

const useStyles = makeStyles(() => ({
  detailsBar: {
    height: '64px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    //position: 'fixed',
    //borderBottom: '2px solid #999',
    //backgroundImage: 'url(/images/background/paper-1.jpg)',
  },
  nhp: {
    backgroundColor: 'rgb(242,169,0) !important',
    color: 'black !important',
    '& div': {
      color: 'black !important',
    }
  },
  detailsBarCellNormal: {
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    //flex: '0 0 auto',
    maxWidth: '300px',
    borderRight: '1px solid #333',
    height: '64px',
    padding: '0 16px',
    verticalAlign: 'baseline',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '100%',
    },
    '& > div > div:nth-child(1)': {
      display: 'block',
      color: '#999',
      fontWeight: 'bold',
      fontSize: '1.1rem',
      textTransform: 'uppercase',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& > div > div:nth-child(2)': {
      //flex: '1',
      display: 'block',
      fontSize: '0.8rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }
  },
  detailsBarCellNormal2: {
    flexGrow: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    minWidth: '220px',
    //padding: '8px 20px',
    '& div:nth-child(1)': {
      color: '#999',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      textTransform: 'uppercase',
    },
    '& div:nth-child(2)': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }
  },
  detailsBarCellEmpty: {
    flexGrow: 1,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    //backgroundColor: 'white',
    height: '64px',
    padding: '8px 20px',
  },
  controlBarContainer: {
    //backgroundColor: 'white',
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    height: "64px"
  },
  controlBar: {
    display: "flex",
    flexGrow: 1,
    //backgroundColor: "white"
  },
  controlCell: {
    display: "inline-block",
    paddingRight: "5px"
  },
  controlCellWithText: {
    display: "inline-block",
    paddingRight: "16px",
    fontSize: '10pt',
    color: '#999',
    textTransform: 'none',
    fontWeight: 'normal',
    '& span': {
      fontSize: '10pt',
    }
  },
  detailView: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  outerSideNavBar: {
    flex: '1 0 64px',
  },
  detailViewRight: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  detailViewContent: {
    flex: '1 1 auto',
    backgroundColor: 'cornflowerblue',
    overflowY: 'scroll',
    height: '100%'
  },
}));


interface IDetailSummaryBarProps {
  model: IApiPerson | IApiProject;
  entityName: string;
}

function PersonModifiedDateInfo({m}: { m: IApiPerson }) {
  return <>
    <span>Modified On: {m.erpModifiedOn ? formatDateForDash(m.erpModifiedOn) : ''}</span><br/>
    <span>Modified By: {m.erpModifiedBy ? m.erpModifiedBy : ''}</span>
  </>
}

function ProjectModifiedDateInfo({m}: { m: IApiProject }) {
  return <>
    <span>Modified On: {m.modifiedOn ? m.modifiedOn?.toLocaleDateString('en-US') : ''}</span><br/>
    <span>Modified By: {m.modifiedBy ? m.modifiedBy : ''}</span>
  </>
}

const DetailSummaryBar = (props: IDetailSummaryBarProps) => {
  const globalState = useGlobalState();

  // if (props.entityName === 'projects') {
  //   const project = props.model as IApiProject;
  //   //console.log('!!! DetailSummaryBar processed again', project);
  // }

  return useObserver(() => (
      <React.Fragment>
        <>{globalState.currentProject && props.entityName === 'project' &&
            <ProjectSummaryBar project={globalState.currentProject as IApiProject}/>
        }</>
        <>{globalState.currentPerson && props.entityName === 'people' &&
            <PersonSummaryBar person={globalState.currentPerson as IApiPerson}/>
        }
        </>
      </React.Fragment>
  ));
};

export default DetailSummaryBar;

function PersonSummaryBar({person}: { person: IApiPerson }) {
  const classes = useStyles();
  const exportAndSchemaContext = useContext(ExportAndSchemaContext);
  //const globalState = useGlobalState();

  const [showPrintMenu, setShowPrintMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);

  function handlePrintPerson(e: React.MouseEvent<HTMLElement>) {
    const el = e.target;
    setAnchorEl(el);
    setShowPrintMenu(true);
  }

  function handleMenuItemClick(e: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement>, action: string) {
    const baseUrl = process.env.REACT_APP_PRINTING_BASE_URL + '/people/';
    //console.log(e, baseUrl);
    switch (action) {
      case 'summary':
        window.open(baseUrl + String(person.id ?? '') + '/print/summary', '_blank');
        handleClose();
        break;
      case 'detail':
        window.open(baseUrl + String(person.id ?? '') + '/print/detail', '_blank');
        handleClose()
        break;
      case 'resumewizard':
        window.open(baseUrl + String(person.id ?? '') + '/resumewizard', '_blank');
        handleClose();
        break;
    }
  }

  return (
      <Toolbar className={classes.detailsBar} key={'globalState.refreshTimes.personDetail'}>
        <div className={classes.detailsBarCellNormal}>
          <div>
            <div title={person.hireDate?.toLocaleDateString('en-US') ?? '\u00A0'}>
              {getAge(person.hireDate)}
            </div>
            <div>Hensel Phelps</div>
          </div>
        </div>
        <div className={classes.detailsBarCellNormal}>
          <div>
            <div>{(person.industryStartYear) ?
                (new Date().getFullYear() - person.industryStartYear).toString() + ' Yrs' : '\u00A0'}
            </div>
            <div>Industry</div>
          </div>
        </div>
        <div className={classes.detailsBarCellNormal}>
          <div>
            <div>{milBilNumberFormat(person.totalProjectValue) ?? '\u00A0'}</div>
            <div>Value</div>
          </div>
        </div>
        <div className={classes.detailsBarCellNormal}>
          <div>
            <div>{person.district}</div>
            <div>Region</div>
          </div>
        </div>
        <div className={classes.detailsBarCellNormal}>
          <div>
            <div>{person?.status === 'Active' ? <GreenCheck/> : <RedX/>}
            </div>
            <div>{person.status}</div>
          </div>
        </div>
        <div className={classes.detailsBarCellEmpty}></div>
        <div className={classes.detailsBarCellNormal2}>
          <div className={classes.controlBarContainer}>
            {/*<div className={classes.controlBar}>*/}
            <div className={classes.controlCell}>
              <DashIconButton onClick={() => {
                exportAndSchemaContext.startTemplateDesigner();
              }} entityName={'people'} MuiIcon={AssignmentReturned}/>
            </div>
            <div className={classes.controlCell}>
              <DashIconButtonEx onClick={handlePrintPerson}
                                entityName={'people'} MuiIcon={Print}/>
            </div>
            <div className={classes.controlCellWithText}>
              <PersonModifiedDateInfo m={person}/>
            </div>
          </div>
        </div>
        {anchorEl &&
            <Menu
                anchorEl={anchorEl}
                open={showPrintMenu}
                onClick={handleClose}
                onClose={handleClose}>
              <MenuItem
                  onClick={(e) => handleMenuItemClick(e, 'summary')}>
                <ListItemIcon><Print/></ListItemIcon>
                <Typography>Print Summary View</Typography>
              </MenuItem>
              <MenuItem onClick={(e) => handleMenuItemClick(e, 'detail')}>
                <ListItemIcon><Print/></ListItemIcon>
                <Typography>Print Detail View</Typography>
              </MenuItem>
              <MenuItem onClick={(e) => handleMenuItemClick(e, 'resumewizard')}>
                <ListItemIcon><AssignmentIndIcon/></ListItemIcon>
                <Typography>Resume Wizard</Typography>
              </MenuItem>
            </Menu>
        }
      </Toolbar>
  );
}

function ProjectSummaryBar({project}: { project: IApiProject }) {
  const classes = useStyles();
  const exportAndSchemaContext = useContext(ExportAndSchemaContext);

  const [showPrintMenu, setShowPrintMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);

  function sizeIsSquareFootage(p: IApiProject): boolean {
    const linearFeet = p.linearFeet;
    const squareFootage = p.squareFootage ?? 0;
    return (
        linearFeet == null ||
        linearFeet === 0 ||
        squareFootage > 0
    );
  }

  function handlePrintProject(e: React.MouseEvent<HTMLElement>) {
    const el = e.target;
    setAnchorEl(el);
    setShowPrintMenu(true);
  }

  function handleMenuItemClick(e: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement>, action: string) {
    const baseUrl = process.env.REACT_APP_PRINTING_BASE_URL + '/projects/';
    //console.log(e, baseUrl);
    const pathMap = {
      'summary': '/print/summary',
      'detail': '/print/detail',
      'datasheet': '/datasheet'
    } as Record<string, string>;

    const path = pathMap[action];

    if (path) {
      window.open(baseUrl + project.id + path, '_blank');
      handleClose();
    }
  }

  const nhpClassName = (project: IApiProject) => {
    return project.nonHenselPhelpsProject === true
        ? classes.nhp
        : undefined;
  }

  const regionName = getDistrictDisplayName(project?.districts);

  return (
      <Toolbar className={clsx(classes.detailsBar, nhpClassName(project))}
               key={'globalState.refreshTimes.projectDetail'}>
        <div className={classes.detailsBarCellNormal}>
          <div>
            <div>{milBilNumberFormat(project?.cost)}</div>
            <div>Contract Value</div>
          </div>
        </div>
        <div className={clsx(classes.detailsBarCellNormal, nhpClassName(project))}>
          <div>
            <div>{project?.completionDate?.toLocaleDateString('en-US') ?? '\u00A0'}</div>
            <div>Completion Date</div>
          </div>
        </div>
        <div className={classes.detailsBarCellNormal}>
          <div>
            <div title={regionName}>{regionName}</div>
            {/*<div>{project?.districts}</div>*/}
            <div>Region</div>
          </div>
        </div>
        <div className={clsx(classes.detailsBarCellNormal, nhpClassName(project))}>
          <div>
            <div>{project?.deliveryMethod?.toUpperCase()}</div>
            <div>Delivery Method</div>
          </div>
        </div>
        {sizeIsSquareFootage(project) ?
            (<div className={clsx(classes.detailsBarCellNormal, nhpClassName(project))}>
              <div>
                <div>{project?.squareFootage != null ? commasFmt.format(project?.squareFootage) : ''}</div>
                {/*<div>{ typeof project?.squareFootage }</div>*/}
                <div>FT&#178;</div>
              </div>
            </div>) :
            (<div className={clsx(classes.detailsBarCellNormal, nhpClassName(project))}>
              <div>
                <div>
                  {project?.linearFeet != null ? commasFmt.format(project?.linearFeet) : ''}</div>
                <div>Linear Feet</div>
              </div>
            </div>)}
        {project && project?.nonHenselPhelpsProject === true &&
            <div className={clsx(classes.detailsBarCellNormal, nhpClassName(project))}>
              <div>
                <div>
                  Industry Project (not HP)
                </div>
                <div>&nbsp;</div>
              </div>
            </div>
        }

        <div className={classes.detailsBarCellEmpty}></div>
        <div className={classes.detailsBarCellNormal2}>
          <div className={classes.controlBarContainer}>
            <div className={classes.controlBar}>
              <div className={classes.controlCell}>
                <DashIconButton onClick={() => {
                  exportAndSchemaContext.startTemplateDesigner();
                }}
                                entityName={'projects'} MuiIcon={AssignmentReturned}/>
              </div>
              <div className={classes.controlCell}>
                <DashIconButtonEx onClick={handlePrintProject}
                                  entityName={'projects'}
                                  MuiIcon={Print}/>
              </div>
              <div className={classes.controlCellWithText}>
                <ProjectModifiedDateInfo m={project}/>
              </div>
            </div>
          </div>
        </div>
        {anchorEl &&
            <Menu
                anchorEl={anchorEl}
                open={showPrintMenu}
                onClick={handleClose}
                onClose={handleClose}>
              <MenuItem
                  onClick={(e) => handleMenuItemClick(e, 'summary')}>
                <ListItemIcon><Print/></ListItemIcon>
                <Typography>Print Summary View</Typography>
              </MenuItem>
              <MenuItem onClick={(e) => handleMenuItemClick(e, 'detail')}>
                <ListItemIcon><Print/></ListItemIcon>
                <Typography>Print Detail View</Typography>
              </MenuItem>
              <MenuItem onClick={(e) => handleMenuItemClick(e, 'datasheet')}>
                <ListItemIcon><AssignmentIndIcon/></ListItemIcon>
                <Typography>Data Sheet Wizard</Typography>
              </MenuItem>
            </Menu>
        }
      </Toolbar>
  );
}
