import {withDashCard} from '../../shared/withDashCard';
import SupplierDiversityGoalsSection from "./SupplierDiversityGoalsSection";
import NarrativeOptionsSection from "./NarrativeOptionsSection";
import SbNarrativeSection from "./SbNarrativeSection";
import WdNarrativeSection from "./WdNarrativeSection";
import CeNarrativeSection from "./CeNarrativeSection";
import SdNotesSection from "./SdNotesSection";

const SupplierDiversityCard = withDashCard('Supplier Diversity', 'supplier-diversity-module',
    // list of components to add to the card (in order they should appear)
    [
          SupplierDiversityGoalsSection,
          NarrativeOptionsSection,
          SbNarrativeSection,
          WdNarrativeSection,
          CeNarrativeSection,
          SdNotesSection,
    ]
);

export default SupplierDiversityCard;