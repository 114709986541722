import React, {useEffect, useRef, useState} from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Box, TextField, IconButton, Grid,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {GridComponent} from "@syncfusion/ej2-react-grids";
import useAthenaClient from "hooks/useAthenaClient";
import {IApiProjectReference, IQueryableContact} from "services/apiClients/AthenaClient";
import CrmContactHyperlink from "../../../dashCore/CrmContactHyperlink";
import {prepareAthenaFriendlySearchString} from "hooks/useDashUrlInfo";
import _ from "lodash";

//enableRipple(true);

const useStyles = makeStyles((theme) => ({
  topBox: {
    width: '100%',
  },
  leftBox: {
    alignSelf: 'flex-start',
  },
  rightBox: {
    alignSelf: 'flex-end',
  },
  bottomBox: {},
  activeStepIcon: {
    color: theme.palette.info.main,
  },
  completedStepIcon: {
    color: theme.palette.success.main,
  },
  // dialog: {
  //   width: '90vh',
  //   maxWidth: 'none'
  // },
}));

interface IPickerDialogProps {
  open: boolean,
  title: string,
  searchBoxLabel: string,
  dataSourceName: string,
  extraInfo?: Record<string, string>;
  onClose: (id?: string | null) => void;
}

export default function PickerDialog(props: IPickerDialogProps) {
  const classes = useStyles();
  const {athenaClient} = useAthenaClient();
  const [searchText, setSearchText] = useState('');
  const [records, setRecords] = useState([] as IQueryableContact[]);
  let gridInstance: GridComponent;

  useEffect(() => {
    async function reloadData() {
      const u = undefined;
      if (athenaClient) {
        const searchStr = prepareAthenaFriendlySearchString(searchText, true);
        const resultSet = await athenaClient.getAllContacts(searchStr, u, u, 50, u);
        if (resultSet.value) {

          setRecords(resultSet.value as IQueryableContact[]);
        }
      }
    }

    reloadData();
  }, [athenaClient, searchText]);

  function setGridInstance(comp: GridComponent | null) {
    if (comp === null) return;
    gridInstance = comp;
  }

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  const handleDataBound = () => {
    //setSelectedRow(undefined);
    _.noop();
  }

  const handleCancel = () => props.onClose();

  const handleItemSelection = (id?: string | null) => {
    //setTimeout(() => alert(id), 0);
    console.log('$$$ handleItemSelect in PickerDialog - item id:', id);
    props.onClose(id);
  }

  if (!props.open) {
    return <></>;
  }

  const title: string = (props?.extraInfo?.personFullName) ? `${props.title} for ${props.extraInfo!.personFullName!}` : props.title;

  function addReference(contact: IQueryableContact) {
    console.log('QWE addReference', contact, contact.id);
    props.onClose(contact.id);
  }

  function ActionsColumn(props: {record: unknown}) {
    const record = props.record as IQueryableContact;
    console.log('$$$ inside ActionsColumn - record:', record);
    return (
        <IconButton size="small"
                    onClick={() => addReference(record)}>
          <AddIcon/>
        </IconButton>
    );
  }

  return (
      <Dialog open={true} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle className="text-white bg-black">{title}
        </DialogTitle>

        <DialogContent>
          <div>
            <Box display="flex" justifyContent="space-between" style={{padding: '10px 5px'}}>
              <Box className={classes.leftBox}>
                {/*reserved for future use*/}
              </Box>

              <Box className={classes.rightBox}>
                <TextField
                    name="searchForExistingProjectText"
                    label={props.searchBoxLabel}
                    onChange={handleSearchTextChange}
                />
              </Box>
            </Box>

            <Box>
              <GridComponent
                  dataSource={records ?? []}
                  ref={grid => setGridInstance(grid as GridComponent)}
                  selectionSettings={{type: 'Single'}}
                  allowSorting={true}
                  allowFiltering={true}
                  allowScrolling={true}
                  filterSettings={{type: 'Excel'}}
                  dataBound={() => _.noop()}
                  columns={[
                    {
                      field: 'fullName', headerText: 'Name', template: (data: IQueryableContact) =>
                          <CrmContactHyperlink name={data.fullName} crmId={data.crmId} />
                    },
                    {field: 'jobTitle', headerText: 'Title'},
                    {field: 'companyName', headerText: 'Company'},
                    {field: 'email', headerText: 'Email'},
                    {
                      headerText: 'Actions', template: (data: IQueryableContact) => 
                        <ActionsColumn record={data} />
                    },
                  ]}
              />
            </Box>

          </div>
        </DialogContent>

        <DialogActions>
          <Box display="flex" justifyContent="space-between" style={{width: '100%', marginLeft: '16px'}}>
            <Box className={classes.leftBox}>
              {/*future use*/}
              {/*props: {JSON.stringify(props)}*/}
            </Box>
            <Box className={classes.rightBox}>
              <Button onClick={handleCancel}>Cancel</Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
  );
}