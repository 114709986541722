import {DateTime} from "luxon";

export default class CoreDate {

  static convertedToDateTimeOrNull(input: string | null | undefined): DateTime | null {
    if (!input) return null;

    // Attempt to parse input as an ISO date (e.g., 'yyyymmdd')
    let dateTime = DateTime.fromISO(input);

    // If the input is not a valid ISO date, try to parse it in the 'mm/dd/yyyy' format
    if (!dateTime.isValid) {
      dateTime = DateTime.fromFormat(input, "MM/dd/yyyy");
    }

    // Check if the Luxon DateTime object is valid and return it or null
    return dateTime.isValid ? dateTime : null;
  }

  static isValidLuxonDateTimeOrNull(possibleDateTime: any): boolean {
    if (possibleDateTime === null) return true;
    if (possibleDateTime instanceof DateTime) {
      return possibleDateTime.isValid;
    }
    return false;
  }

  static toShortDate(date?: Date | string | null) {
    if (!date) {
      return '';
    }

    let actualDate: Date;

    if (typeof date === 'string') {
      actualDate = new Date(date);
      // Check if date is invalid
      if (isNaN(actualDate.getTime())) {
        return '';
      }
    } else {
      actualDate = date;
    }

    return actualDate.toLocaleDateString();
  }

  static toShortISOString(date?: Date | null) {
    return !date ? '' : date.toISOString().substring(0, 10);
  }

  static isValidDate(possibleDate: any): boolean {
    const date = new Date(possibleDate);
    return !isNaN(date.getTime());
  }

  static isValidLuxonDateTime(possibleDateTime: any): boolean {
    if (possibleDateTime instanceof DateTime) {
      return possibleDateTime.isValid;
    }
    return false;
  }
}


