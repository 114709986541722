/* eslint-disable no-debugger */
/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import noop from 'utils/noop'
import { makeStyles } from '@material-ui/core/styles'
import ModifyColumnsDialog, {
  IModifyColumnsDialogProps,
} from '../features/export/ModifyColumnsDialog'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ViewListIcon from '@material-ui/icons/ViewList'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
  Add,
  DeleteForever,
  ExitToAppOutlined,
  HelpOutline,
  MergeType,
  SettingsOutlined,
} from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { getMsalInstance, logout } from 'config/msalConfig'
import CoreLocation from '../features/core/CoreLocation'
import CoreDebug from '../features/core/CoreDebug'
import HelpDialog from '../app/dashHelp/feature/HelpDialog'
import AthenaAuthorize from '../app/security/ui/AthenaAuthorize'
import globalState from '../app/state/GlobalState'
import _ from 'lodash'
import BrowserState from '../app/browserState/feature/BrowserState'
import searchStoreEx from '../app/SearchStoreEx'

const useStyles = makeStyles((theme) => ({
  middleAppBarContainer: {
    //backgroundColor: '#eee',
    color: 'white',
    height: '64px',
    textTransform: 'none',
    '& button.MuiButton-root': {
      minWidth: '48px',
    },
  },
  icon: {
    color: 'white',
  },
  menu: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    padding: '0',
    color: '#fff',
    textTransform: 'none',
  },
}))

export interface IListViewMenuProps {
  entityName: string
  currentView: string
  callListViewAction?: (action: string) => void
  onSearchClick?: () => void
  onViewChange: (viewName: string) => void
}

export default function SearchViewMenu(props: IListViewMenuProps) {
  const classes = useStyles()
  //const searchStore = useContext(SearchContext);
  const pathname = window.location.pathname
  const navigate = useNavigate()
  const segments = pathname.split('/').filter((part) => part !== '')
  const inSearchMode =
    segments.length === 1 &&
    (segments[0] === 'people' || segments[0] === 'projects')
  const [currentView, setCurrentView] = useState(props.currentView)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const startDeleteProject = CoreDebug.getFunc('startDeleteProject') as (
    projectId: string,
  ) => void

  const [dialogProps, setDialogProps] = React.useState({
    open: false,
    entityName: props.entityName,
    onClose: () => {
      refreshSearch()
      setDialogProps({ ...dialogProps, open: false })
    },
  } as IModifyColumnsDialogProps)

  useEffect(() => {
    setDialogProps({ ...dialogProps, entityName: props.entityName })
  }, [props.entityName])

  function refreshSearch() {
    //navigate(`${location.pathname}${location.search}`, { state: { refresh: true }, replace: true });
    searchStoreEx.refresh()
  }

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLElement>,
    selectedActionName: string,
  ) => {
    const action = selectedActionName.trim()
    switch (action) {
      case 'modifyColumns':
        setDialogProps({ ...dialogProps, open: true })
        break
      case 'toggleListCard':
        const newView = currentView === 'list' ? 'card' : 'list'
        // eslint-disable-next-line no-case-declarations
        //debugger;
        setCurrentView(newView)
        // update dash state outside in the PrimaryAppBar
        props.onViewChange(newView)
        break
      default:
        noop()
        break
    }
  }

  const handleClose = () => setAnchorEl(null)
  const showHelp = true

  function startSessionOver(): void {
    BrowserState.cleanBrowserState()

    logout()
  }

  function launchHelp(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    if (event.ctrlKey) {
      const confirmReset = confirm(
        'Do you want to start your Dash session over?',
      )
      if (confirmReset) {
        startSessionOver()
      }
    }
    setHelpDialogOpen(true)
  }

  function handleHelpClose() {
    setHelpDialogOpen(false)
  }

  return (
    <React.Fragment>
      <div className={classes.middleAppBarContainer}>
        <>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: 64 }}
          >
            <Button
              className={classes.button}
              onClick={(e) => handleSettingsClick(e)}
            >
              <MoreVertIcon className={classes.icon} />
            </Button>
          </Grid>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClick={handleClose}
            onClose={handleClose}
          >
            {inSearchMode && (
              <MenuItem
                onClick={(e) => handleMenuItemClick(e, 'toggleListCard')}
              >
                {currentView === 'list' ? (
                  <ListItemIcon>
                    <ViewModuleIcon />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <ViewListIcon />
                  </ListItemIcon>
                )}
                <Typography variant="inherit">
                  {currentView === 'list' ? 'Card View' : 'List View'}
                </Typography>
              </MenuItem>
            )}

            {inSearchMode && currentView === 'list' && (
              <MenuItem
                onClick={(e) => handleMenuItemClick(e, 'modifyColumns')}
              >
                <ListItemIcon>
                  <ViewColumnIcon />
                </ListItemIcon>
                <Typography>Change Columns</Typography>
              </MenuItem>
            )}
            {CoreLocation.getEntityName() === 'projects' &&
              !CoreLocation.getEntityId() && (
                <AthenaAuthorize allowedRoles={'Contributor'}>
                  <MenuItem onClick={() => navigate('/projects/create')}>
                    <ListItemIcon>
                      <Add />
                    </ListItemIcon>
                    <Typography>Add Project</Typography>
                  </MenuItem>
                </AthenaAuthorize>
              )}
            {CoreLocation.getEntityName() === 'projects' &&
              CoreLocation.getEntityId() && (
                <AthenaAuthorize allowedRoles={'Contributor'}>
                  <MenuItem
                    onClick={() =>
                      startDeleteProject(CoreLocation.getEntityId() as string)
                    }
                  >
                    <ListItemIcon>
                      <DeleteForever />
                    </ListItemIcon>
                    <Typography>Delete Project</Typography>
                  </MenuItem>
                </AthenaAuthorize>
              )}

            <MenuItem onClick={launchHelp} hidden={!showHelp}>
              <ListItemIcon>
                <HelpOutline />
              </ListItemIcon>
              <Typography>Help</Typography>
            </MenuItem>

            {CoreLocation.getEntityName() === 'projects' && (
              <AthenaAuthorize allowedRoles="vdc_admin">
                <MenuItem onClick={() => _.noop()}>
                  <ListItemIcon>
                    <SettingsOutlined />
                  </ListItemIcon>
                  <Typography>Admin</Typography>
                </MenuItem>
              </AthenaAuthorize>
            )}

            <MenuItem
              onClick={() => {
                //logout();
                //window.location.href='/?clear=1';
                logout(getMsalInstance(), '/?clear=1')
              }}
              hidden={false}
            >
              <ListItemIcon>
                <ExitToAppOutlined />
              </ListItemIcon>
              <Typography>Logout</Typography>
            </MenuItem>
          </Menu>
          {CoreLocation.getEntityName() === 'projects' && (
            <React.Fragment key="projects-change-columns">
              <ModifyColumnsDialog
                title={dialogProps.title}
                entityName={props.entityName}
                open={dialogProps.open}
                onClose={dialogProps.onClose}
              />
            </React.Fragment>
          )}
          {CoreLocation.getEntityName() === 'people' && (
            <React.Fragment key="people-change-columns">
              <ModifyColumnsDialog
                title={dialogProps.title}
                entityName={props.entityName}
                open={dialogProps.open}
                onClose={dialogProps.onClose}
              />
            </React.Fragment>
          )}
        </>
      </div>
      <HelpDialog open={helpDialogOpen} onClose={() => handleHelpClose()} />
    </React.Fragment>
  )
}
