export default class CoreRange {
  public static convertToSortedNumbers(keys: (string | number)[]): number[] {
    const convertedKeys: number[] = [];

    for (const key of keys) {
      const converted = Number(key);

      if (!isNaN(converted)) {
        convertedKeys.push(converted);
      }
    }

    convertedKeys.sort((a, b) => a - b);
    console.log('!@## internal CoreRange.convertToSortedNumbers convertedKeys sorted', convertedKeys);
    return convertedKeys;
  }

  public static getMinMaxNumbers(keys: (string | number)[]): [number, number] {
    const sortedKeys = this.convertToSortedNumbers(keys);
    const min = sortedKeys[0];
    const max = sortedKeys[sortedKeys.length - 1];
    return [min, max];
  }

  public static makeShortDate(value: Date | string): string {
    if (typeof value === 'string') {
      if (value.charAt(10) === 'T') {
        return value.split('T')[0];
      }
    } else if (value instanceof Date) {
      return value.toISOString().split('T')[0];
    }

    return '';
  }

  public static convertToSortedShortDates(dates: (Date | string)[]): string[] {
    const convertedDates: string[] = [];

    for (const date of dates) {
      const shortDate = this.makeShortDate(date);

      if (shortDate) {
        convertedDates.push(shortDate);
      }
    }

    convertedDates.sort();
    return convertedDates;
  }

  public static getMinMaxShortDate(dates: (Date | string)[]): [string, string] {
    const sortedDates = this.convertToSortedShortDates(dates);
    const min = sortedDates[0];
    const max = sortedDates[sortedDates.length - 1];
    return [min, max];
  }
}
