import useAthenaClient from 'hooks/useAthenaClient';
import useWizard from 'hooks/useWizard';
import React, {useEffect, useRef, useState} from 'react';
import {IApiProjectHistory, IQueryableProject} from 'services/apiClients/AthenaClient';
import PersonService from 'services/PersonService';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import {enableRipple} from '@syncfusion/ej2-base';
import {FilterSettingsModel, GridComponent,} from '@syncfusion/ej2-react-grids';
import AddOrEditProjectForPerson from './AddOrEditProjectForPerson';
import Hovertip from "../../../../features/coreComponents/Hovertip";
import {GreenCheck, RedX} from "../../../dashCore/customIcons";
import {CoreProject} from "../../../../features/core/CoreProject";
import {prepareProjectsQueryResultRows} from "../../../search/ListView/listViewUtil";
import ProgressButton from "../../../dashCore/ProgressButton";
import {current} from "immer";

//enableRipple(true);

const useStyles = makeStyles((theme) => ({
  topBox: {
    width: '100%',
  },
  leftBox: {
    alignSelf: 'flex-start',
  },
  rightBox: {
    justifyContent: 'flex-end',
  },
  bottomBox: {},
  activeStepIcon: {
    color: theme.palette.info.main,
  },
  completedStepIcon: {
    color: theme.palette.success.main,
  },
  // dialog: {
  //   width: '90vh',
  //   maxWidth: 'none'
  // },
}));

const CONFIRM_CLOSE_OR_CANCEL_TEXT = 'Are you sure you want to close? Your changes will not be saved.';
const EMPTY_GRID_AREA_MSG = 'Please search for the project or add an industry project.';

const filterOptions: FilterSettingsModel = {
  type: 'Excel'
};

const steps = {
  '1': 'Add Project',
  '2': 'Confirm Details',
  '3': 'Add Industry Project'
} as Record<string, string>;

interface IProjectForPersonWizardProps {
  mode: string;
  step: number;
  record?: object;
  personId: number;
  personGuid: string;
  personFullName: string;
  personTitle?: string;
  open: boolean;
  onDialogClose: () => void;
}

export default function AddProjectForPersonWizard(props: IProjectForPersonWizardProps) {
  const classes = useStyles();
  //const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const selectedRowRef = useRef<number | undefined>(undefined);
  const selectedProjectRef = useRef<IQueryableProject | null>(null);

  const [formValues, setFormValues] = useState({});
  const {athenaClient} = useAthenaClient();
  const [formModified, setFormModified] = useState(false);
  const [reset, setReset] = useState(false);

  console.log('!!@ AddProjectForPersonWizard props', {props});
  const [wizardState, wizardActions] = useWizard({
    id: props.personId,
    mode: props.mode ?? 'm',
    step: props.step ?? 1,
    model: null
  });
  const [pickerData, setPickerData] = useState<IQueryableProject[] | null>(null);
  const [searchText, setSearchText] = useState('');
  const personGuid = props.personGuid;
  const personFullName = props.personFullName;
  let gridInstance: GridComponent;

  //console.log('!!@ init wizard state was', {wizardState});

  function setGridInstance(comp: GridComponent | null) {
    if (comp === null) return;
    gridInstance = comp;
  }

  useEffect(() => {
    async function refreshPicker() {
      //const refreshPicker = async () => {}
      //console.log('checking refreshPicker', {wizardState});
      if (wizardActions.compareStepAndMode(1, 'new')) {
        //console.log('!!@ inside refreshPicker after compare')
        if (athenaClient) {
          console.log('!!@ inside refreshPicker after athenaClient test');
          const ps = new PersonService(athenaClient);
          const data = await ps.getProjectPickerData(searchText) as IQueryableProject[];
          const newData = prepareProjectsQueryResultRows(data) as unknown;
          setPickerData(newData as IQueryableProject[]);
        }
      }
    }

    console.log('@@before refresh picker')
    refreshPicker();
  }, [athenaClient, searchText]);

  useEffect(() => {
    if (gridInstance) {
      gridInstance.selectedRowIndex = selectedRowRef.current ?? -1;
    }
  }, [selectedRowRef]);

  const next = () => {
    if (!selectedProjectRef.current) {
      alert('You must select a project before proceeding.');
      return;
    }
    wizardActions.setMode('new-proj-history');
    wizardActions.setStep(2);
    //console.log('!!!!! setMode to edit and setStep to 2');
  }
  const back = () => wizardActions.back();

  const handleCancel = () => {
    if (formModified) {
      if (window.confirm(CONFIRM_CLOSE_OR_CANCEL_TEXT)) {
        props.onDialogClose();
      }
    }
  };

  const close = () => {
    if (formModified) {
      if (window.confirm(CONFIRM_CLOSE_OR_CANCEL_TEXT)) {
        //setActiveStep();
        props.onDialogClose();
      }
    } else {
      props.onDialogClose();
    }
  };

  const handleNavEditProject = (rowData) => {
    if (selectedProjectRef.current) {
      alert('You must select a project before proceeding.');
      return;
    }
    console.log('~~ handleNavEditProject', {'selectedRowRef.current': selectedRowRef.current});
    wizardActions.setStepAndMode(2, 'edit'); // confirm title
  };

  const handleNavEditIndustryProject = () => {
    wizardActions.setStepAndMode(2, 'edit'); // add new Industry Project
  };

  const handleNavAddNewIndustryProject = () => {
    if (gridInstance) {
      gridInstance.selectRow(-1);
    }
    selectedProjectRef.current = null;
    wizardActions.setStepAndMode(2, 'new'); // add new Industry Project
  }

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(event);
    selectedProjectRef.current = null;
    selectedRowRef.current = undefined;
    setSearchText(event.target.value);
  }

  const handleDataBound = () => {
    selectedRowRef.current = undefined;
  }

  // grid
  const handleSelectionChanged = (_args: Selection) => {
    console.log('~~ handleSelectionChanged', {'_args: Selection': _args});
    const data = gridInstance.getSelectedRecords()[0];
    const proj = data as IQueryableProject;
    selectedProjectRef.current = proj;
  }

  const rowSelected = () => {
    console.log('~~ row selected');
    const record = gridInstance.getSelectedRecords()[0];

    selectedRowRef.current = gridInstance.selectedRowIndex;
    selectedProjectRef.current = record as unknown as IQueryableProject;
  }

  const handleFormChange = (event) => {
    setFormValues({...formValues, [event.target.name]: event.target.value});
    setFormModified(true);
  };

  const save = () => {
    const button = document.querySelector('button[name="hidden-button-add-project-to-person-save"]') as HTMLButtonElement;
    button.click();
    const tmpEl = document.getElementById('valid-form') as HTMLInputElement;
    if (tmpEl.value === 'true') {
      setTimeout(() => {
        props.onDialogClose();
      }, 2000);
    } else {
      setReset(true);
    }
  }

  function handleButtonReset() {
    setReset(false);
  }

  if (!props.open) {
    return <></>;
  }

  return (
      <Dialog open={true} fullWidth={true} maxWidth={'lg'}
              scroll={'paper'}
              //PaperProps={{ style: { height: '640px', maxHeight: '640px', width: '90vw' }}}>
              PaperProps={{ style: { height: '70vh', maxHeight: '70vh', width: '90vw' }}}>
        <DialogTitle className="w-full text-white bg-black">
          {`${steps[wizardState.step]} for ${props.personFullName}`}
        </DialogTitle>

        <DialogContent>

          {wizardActions.compareStepAndMode(1, 'new') &&
              <Grid container>
                  <Grid item container style={{justifyContent:'space-between'}}>
                      <Grid item className={classes.leftBox}>
                          <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon/>}
                              endIcon={
                                <Tooltip
                                    title="A non-Hensel Phelps project that is displayed only in this person's history.">
                                  <InfoIcon/>
                                </Tooltip>
                              }
                              onClick={handleNavAddNewIndustryProject}>Add Industry Project</Button>
                      </Grid>
                      <Grid item className={classes.rightBox}>
                          <TextField
                              name="searchForExistingProjectText"
                              label="Search for project"
                              onChange={handleSearchTextChange}
                          />
                      </Grid>
                  </Grid>
                  <Grid item container style={{justifyContent:'space-between'}}>
                      <Grid item className={classes.leftBox}>
                          &nbsp;
                      </Grid>
                  </Grid>

                  <Grid item>
                    { pickerData && searchText ?
                        <GridComponent
                            allowFiltering={true}
                            allowSorting={true}
                            filterSettings={filterOptions}
                            dataSource={pickerData ?? []}
                            ref={grid => setGridInstance(grid as GridComponent)}
                            selectionSettings={{type: 'Single'}}
                            rowSelected={rowSelected}
                            dataBound={handleDataBound}
                            columns={[
                              {field: 'name', headerText: 'Name'},
                              {field: 'location', headerText: 'Location', template: (data: IQueryableProject) => {
                                  return (CoreProject.getLocation(data) ?? '');
                                },
                              },
                              {field: 'clientName', headerText: 'Client Name'},
                              {field: 'cost', headerText: 'Cost', format: 'C0'},
                              {field: 'completionDate', headerText: 'Completion Date', format: 'yMd', type: 'date'},
                            ]}
                        />: <p className="font-bold text-center">
                          Please search for the project or add an industry project.
                        </p>
                    }
                  </Grid>

              </Grid>
          }
          {wizardActions.compareStepAndMode(2, '*') &&
              <Grid container>
                  <AddOrEditProjectForPerson
                      personId={props.personId}
                      personGuid={personGuid}
                      personName={personFullName}
                      record={props.record}
                      activeProject={selectedProjectRef.current}
                      mode={wizardState.mode}/>
              </Grid>
          }
        </DialogContent>

        <DialogActions>
          <Grid container style={{ width: '100%', marginLeft: '16px', display: 'flex', justifyContent: 'space-between' }}>
              <Grid item className={classes.leftBox} style={{ display: 'flex' }}>
                {/*future use*/}
              </Grid>
            <Grid item className={classes.rightBox} style={{ display: 'flex', justifyContent: 'space-between' }}>
              {wizardActions.compareStepAndMode(1, '*') &&
                  <Button
                      onClick={next}
                      data-not-active-onClick={() => handleNavEditProject(selectedProjectRef.current)}
                  >Next</Button>
              }

              {wizardActions.compareStepAndMode(2, 'new') && <>
                  <input className={'hidden'} id={'valid-form'} value={''} />
                  <ProgressButton text="Save" onReset={handleButtonReset}
                                  reset={reset} onClick={save} />
                  </>
              }
              {wizardActions.compareStepAndMode(2, 'edit') && <>
                  <input className={'hidden'} id={'valid-form'} value={''} />
                  <ProgressButton text="Save" onReset={handleButtonReset}
                                  reset={reset} onClick={save} />
                  </>
              }
              {wizardActions.compareStepAndMode(2, 'new-proj-history') && <>
                  <input className={'hidden'} id={'valid-form'} value={''} />
                  <ProgressButton text="Save" onReset={handleButtonReset}
                                  reset={reset} onClick={save} />
                  </>
              }

              {wizardActions.compareStepAndMode(1, '*') &&
                  <Button onClick={() => close()}>Close</Button>
              }
              {wizardActions.compareStepAndMode(2, '*') &&
                  <Button onClick={() => close()}>Cancel</Button>
              }
            </Grid>
          </Grid>
        </DialogActions>

      </Dialog>
  );
}


