import { Client, ResponseType } from '@microsoft/microsoft-graph-client';
import CoreDebug from "../features/core/CoreDebug";

const getAuthenticatedClient = async (accessToken: string) => {
  return Client.init({
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });
};

const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (_) => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
};

export const getMyPhoto = async (accessToken: string | undefined) => {
  if (!accessToken) {
    return;
  }
  const client = await getAuthenticatedClient(accessToken);
  const response = (await client.api(`/me/photo/$value`).responseType(ResponseType.RAW).get()) as Response;
  if (response.status === 404 || !response.ok) {
    return;
  }
  const base64Image = await blobToBase64(await response.blob());
  return base64Image;
};

export const getMe = async (accessToken: string | undefined) => {
  if (!accessToken) {
    return null;
  }
  const client = await getAuthenticatedClient(accessToken);
  const user = await client.api('/me').get();
  return user;
};

export const getAdUserInfo = async (accessToken: string | null) => {
  if (!accessToken) {
    return null;
  }
  const me = await getMe(accessToken);
  const displayName = me.displayName;
  const userPrincipalName = me.userPrincipalName;
  const email = me.mail;
  const userPhoto = await getMyPhoto(accessToken);

  CoreDebug.publish('hpUser', me);
  CoreDebug.publish('hpUserPhoto', userPhoto);


  return { displayName, userPhoto, userPrincipalName, email };
};
