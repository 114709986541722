import {IAthenaFacet} from "services/apiClients/AthenaClient";

export interface IDropDownOptions {
  options: { [key: string]: string[] }
  optionsWithImages: { [key: string]: IOptionWithImage[] }
}

export interface IOptionWithImage {
  name: string,
  image: string,
  members: string[]
}

export function normalizeFacetValues(facets: IAthenaFacet[]) {
  if (!facets) {
    return [];
  }
  const values = facets.map(x => x.value).filter(x => typeof x === 'string') as string[];
  values.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
  return values;
}