import {AthenaQuery, DashQuery, FacetSettingsInfo} from 'types/searchTypes';
import {Location} from "react-router-dom";
import AthenaFilterTranslator from './AthenaFilterTranslator';
import AthenaOrderbyTranslator from "../athenaSpecific/AthenaOrderbyTranslator";
import {sortBegin} from "@syncfusion/ej2-react-grids";

export default class QueryConvertor {
  public createDashQuery(location: Location, urlSearchParams: URLSearchParams): DashQuery {
    const dashQuery = {} as DashQuery;
    dashQuery.entityName = getFirstPathSegment(location);
    dashQuery.filters = {} as { [key: string]: FacetSettingsInfo };
    urlSearchParams.forEach((value, key) => {
      if (key !== 'orderby' && key !== 'top' && key !== 'skip' && key !== 'search') {
        dashQuery.filters[key] = decodeDashFilterParam(key, value);
      }
    });
    // if (urlSearchParams.has('top')) {
    //   const tmp = urlSearchParams.get('top') ?? '';
    //   if (tmp !== '') dashQuery.top = parseInt(tmp);
    // }
    // if (urlSearchParams.has('skip')) {
    //   const tmp = urlSearchParams.get('skip') ?? '';
    //   if (tmp !== '') dashQuery.top = parseInt(tmp);
    // }
    if (urlSearchParams.has('search')) {
      dashQuery.search = urlSearchParams.get('search') ?? '';
    }
    console.log('@@@@ createDashQuery from QueryConvertor', dashQuery);
    return dashQuery;
  }

  public convertToAthenaQuery(dashQuery: DashQuery): AthenaQuery {
    const athenaQuery = {} as AthenaQuery;
    athenaQuery.entityName = dashQuery.entityName;
    if (dashQuery.top ?? -1 !== -1) athenaQuery.top = dashQuery.top;
    if (dashQuery.skip ?? -1 !== -1) athenaQuery.skip = dashQuery.skip;
    if (dashQuery.orderby && dashQuery.orderby.length > 0) athenaQuery.orderby = encodeForAthenaOrderby(dashQuery.orderby);
    if (Object.keys(dashQuery.filters).length > 0) athenaQuery.filters = encodeForAthenaFilters(dashQuery.filters);
    return athenaQuery;
  }
}
function getFirstPathSegment(location: Location): string {
  const path = location.pathname;
  const pathSegments = path.split('/');
  return pathSegments[1];
}

function decodeDashFilterParam(field: string, str?: string | null): FacetSettingsInfo {
  if (!str) return {field:field, mode: '', values: []} as FacetSettingsInfo;
  const tokens: string[] = [];
  let token = '';
  let inQuote = false;
  for (let i = 0; i < str.length; i++) {
    const c = str.charAt(i);
    //console.log(c);
    if (c == '"') {
      if (inQuote) {
        tokens.push(token);
        token = "";
        inQuote = false;
      } else {
        inQuote = true;
      }
    } else if (c == ',' || c == '.') {
      if (inQuote) {
        token += c;
      } else {
        if (token.length > 0) {
          tokens.push(token);
          token = "";
        }
      }
    } else {
      token += c;
    }
  }
  if (token.length > 0) {
    tokens.push(token);
  }
  //return tokens;
  return {field:field, mode: tokens[0], values: tokens.splice(1)} as FacetSettingsInfo;
}

export function encodeDashFilterParam(facetSettingsInfo: FacetSettingsInfo): string {
  const mode = facetSettingsInfo.mode;
  const values = facetSettingsInfo.values;
  if (values && values.length > 0) {
    return mode + '.' + values.map(x => `"${x}"`).join(',');
  } else {
    return '';
  }
}

export function encodeForAthenaFilters(filters: {[field: string]: FacetSettingsInfo}): string {
  return new AthenaFilterTranslator().translate(filters);
}

function encodeForAthenaOrderby(orderby: string[]) {
  // const orderbyArr = [] as string[];
  // orderby.forEach(x => {
  //   // if x starts with '-' then add 'desc ' and name to orderbyArr
  //   // else add name to orderbyArr
  //   orderby.push(x.startsWith('-') ? 'desc ' + x.substring(1) : x);
  // });
  //return orderbyArr.join(',');
  return new AthenaOrderbyTranslator().translate(orderby[0]);
}

