import {
  ApiProjectTechnologyPhase,
  IApiProject,
  IApiProjectTechnologyPhase, IProjectApproval
} from "services/apiClients/AthenaClient";
import React, {useEffect, useState} from "react";
import {getNamedData} from "../../../../utils/forms";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DashDetailsField from "../../../DashDetailsField";
import ProgressButton from "../../../dashCore/ProgressButton";
import DashDivider from "../../../DashDivider";
import CoreDebug from "../../../../features/core/CoreDebug";
import DashApproval from "../../../dashCore/DashApproval";
import {isBoolean} from "lodash";
import useCurrentUser from "../../../../hooks/useCurrentUser";

interface IDialogProps {
  mode?: string | null;
  phaseKey?: string;
  openKey?: string | undefined | null;
  model: IApiProject;
  phaseModel?: IApiProjectTechnologyPhase;
  choices: string[];
  onPhaseAction: (action: string, phase?: IApiProjectTechnologyPhase) => void;
  onCancel?: () => void;

  onAddPhaseSave?: (phaseLabel: string, phaseNarrative: string) => void;
}

type ApprovalsContainer = {
  approvals?: IProjectApproval[];
}

function createPartialApprovalGraph(key: string, isApproved: boolean) {
  const container: ApprovalsContainer = {};
  container.approvals = [];
  container.approvals.push({key, isApproved, modifiedBy: undefined, fieldModifiedOn: undefined} as IProjectApproval);
  return container;
}

export default function PhaseDialog(props: IDialogProps) {
  //const [localOpen, setLocaOpen] = useState(true);
  const currentUser = useCurrentUser();
  const [phaseLabel, setPhaseLabel] = useState<string>(props?.phaseModel?.phaseLabel ?? '');
  const [phaseNarrative, setPhaseNarrative] = useState<string>(props?.phaseModel?.narrative ?? '');
  console.log('!!! from props into dialog - phaseModel', {'props?.phaseModel':props?.phaseModel,phaseNarrative});
  const [buttonReset, setButtonReset] = useState(false);
  const [phaseLabelWarning, setPhaseLabelWarning] = useState(false);
  const [approval, setApproval] = useState<boolean | undefined>(props.phaseModel?.approval);
  const [approvalModifiedBy, setApprovalModifiedBy] = useState<string | undefined>(props.phaseModel?.approvalModifiedBy);
  const [approvalModifiedOn, setApprovalModifiedOn] = useState<Date | undefined>(props.phaseModel?.approvalModifiedOn);


  useEffect(() => {
    setPhaseNarrative(props.phaseModel?.narrative ?? '');
    setPhaseLabel(props.phaseModel?.phaseLabel ?? '');
  },[props.phaseModel, props.phaseModel?.narrative, props.phaseModel?.phaseLabel]);

  async function handleSave() {
    const data = getNamedData(document.getElementById('addOrEditPhaseDialog')) as Record<string, string>;
    console.log("!@#6 AddPhaseDialog save", {data});
    //return await new Promise(() => true);

    await new Promise(resolve => setTimeout(resolve, 500));
    if (data['phaseLabel'].trim() === '') {
      //alert('You must select a valid phase to add via the dropdown list.');
      setButtonReset(true);
      setPhaseLabelWarning(true);
      return;
    }
    const m = new ApiProjectTechnologyPhase();
    // NEW
    if (props.mode === 'new') {
      console.log('#@! seen as new')
      m.phaseLabel = data['phaseLabel'];
      m.narrative = data['phaseNarrative'];
      m.technologies = [];
      m.implementationCost = undefined;
      m.workHours = undefined;
      m.projectId = props.model.id as string;
      // m.approval = approval;
      // m.approvalModifiedBy = approvalModifiedBy;
      // m.approvalModifiedOn = approvalModifiedOn;
      //setPhaseNarrative(phaseNarrative);
      props.onPhaseAction('create', m);
    }
    // EDIT
    else if (props.mode === 'edit') {
      const container= data as ApprovalsContainer;
      console.log('#@! seen as an edit');
      m.id = data['id'];
      //m.phaseLabel = data['phaseLabel'];
      m.narrative = phaseNarrative;
      m.technologies = [];
      m.implementationCost = undefined;
      m.workHours = undefined;
      m.projectId = props.model.id as string;
      m.approvalModifiedBy = undefined;
      m.approvalModifiedOn = undefined;
      m.approval = container?.approvals?.find(x => x.approvalKey === 'fakeKey')?.isApproved ?? false;

      // m.approvalModifiedBy = data['fieldModifiedBy'];
      // m.approvalModifiedOn = new Date(data['fieldModifiedOn']);
      props.onPhaseAction('update', m);
    }
  }

  if (props.mode !== 'new' && props.mode !== 'edit') {
    return null;
  }

  function handleButtonReset() {
    setButtonReset(false);
  }
  console.log('phase dialog', {mode:props.mode,phaseModel:props.phaseModel,choices:props.choices});

  //const approvalsGraph = createPartialApprovalGraph('fakeKey', props.phaseModel?.approval ?? false);
  const isApproved = props.phaseModel?.approval ?? false;

  return (
      <Dialog id="addOrEditPhaseDialog" fullWidth={true} maxWidth={'lg'} style={{height:'500px'}}
              open={props.mode === 'new' || props.mode === 'edit'}>
        <DialogTitle className="bg-black text-white">
          {props.mode === 'new' ? 'Add': 'Edit'} Phase to {props.model.name}
        </DialogTitle>
        <DialogContent>
          <DashDetailsField mode={'hidden'} name={'id'} label="id" showLabel={false}
                            defaultValue={props.phaseModel?.id}
          />
          <DashDetailsField width={'40%'} mode={'select'} name={'phaseLabel'}
                            label={'Phase'} showLabel={true} editMode={true}
                            defaultValue={(props.mode === 'edit' && props.phaseModel?.phaseLabel) || ''}
                             choices={['',...props.choices]}
          />
          <DashDivider width={'100px'} height={'10px'} />
          { phaseLabelWarning
              ? <span className="text-hp-red font-bold">* To add a phase you must select a valid phase label from the dropdown list</span>
              : <span>Please select a phase from the dropdown list.</span>
          }
          <DashDivider width={'100px'} height={'10px'} />
          <DashDetailsField mode={'richText'}
                            name={'phaseNarrative'}
                            label={''}
                            defaultValue={phaseNarrative}
                            editMode={true}
                            onValueChange={(text) => setPhaseNarrative(text)}
                            showLabel={false}
          />
          { props.mode === 'edit' &&
              <DashDetailsField mode={'approval'} label={'Approval'} showLabel={true}
                                editMode={true}
                                    valueGraph={{approvals:[{
                                  approvalKey: 'fakeKey', isApproved: props.phaseModel?.approval,
                                  modifiedBy: props.phaseModel?.approvalModifiedBy,
                                  modifiedOn: props.phaseModel?.approvalModifiedOn
                                }]}}
                                infoMode={'minimal'}
                                approvalKey={'fakeKey'}
                   />
          }
        </DialogContent>



          <DialogActions>
            <div className="flex flex-row">
              <div>
                <ProgressButton text={'Save'} blurNeeded={false}
                                onClick={handleSave} reset={buttonReset} onReset={handleButtonReset} />
                {/*<Button onClick={handleSave}>Save</Button>*/}
              </div>
              <div>
                <Button onClick={() => props.onPhaseAction('cancel', props.phaseModel)}>Cancel</Button>
              </div>
            </div>
          </DialogActions>


      </Dialog>
  );
}
