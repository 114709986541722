import React, {useState, useEffect, useRef} from 'react';
import {
  ChangeEventArgs,
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar
} from '@syncfusion/ej2-react-richtexteditor';
import {useSharedLargeCardStyles} from "../detailViews/shared/sharedStyles";

interface IDashRichTextProps {
  value: string;
  name?: string;
  editMode?: boolean;
  onChange?: (value: string) => void;
}

export default function DashRichText(props: IDashRichTextProps) {
  const classes = useSharedLargeCardStyles();
  const [content, setContent] = useState(props.value);
  const [initialContent, setInitialContent] = useState(props.value);

  // Add ref to interact with the DOM directly
  const contentRef = useRef<HTMLDivElement>(null);

  const handleUndo = () => {
    setInitialContent(initialContent); // reset content to initial state
  };

  useEffect(() => {
    if(props.editMode === false){
      setContent(initialContent);
    }
  }, [props.editMode])

  useEffect(() => {
    setContent(props.value);
  },[props.value]);

  const handleChange = (args: ChangeEventArgs) => {
    setContent(args.value);
    if (props.onChange) {
      props.onChange(args.value);
    }
  }


  // Effect to update the innerHTML
  useEffect(() => {
    if (contentRef.current) {
      console.log('$$$$$$ setting content', content);
      contentRef.current.innerHTML = content;
    }
  }, [content, props.editMode]);


  console.log('$$$$$$ before richText', {'props.value': props.value, content});

  return (
      <>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <div style={{flex: '1 0 100%', textAlign:'left', whiteSpace:'normal'}}>
            {props.editMode ? (
                <RichTextEditorComponent value={content}
                                             change={(args)=> handleChange(args)}
                                         name={props.name ?? undefined}>
                  <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
                </RichTextEditorComponent>
            ) : (
                // Use ref instead of dangerouslySetInnerHTML
                <div style={{whiteSpace: 'normal'}} ref={contentRef} />
            )}
          </div>
        </div>
      </>
  );
}
