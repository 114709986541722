
export function normalizeValue(value?: string | string[] | number | boolean | Date | null): string | number | undefined | string[] | Date {
  if (value === undefined || value === null) {
    return undefined;
  } else {
    // test for Date
    if (value instanceof Date) {
      return value;
    } else // test for boolean
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    } else // test for number
    if (typeof value === 'number') {
      return value;
    } else // test for string[]
    if (Array.isArray(value)) {
      return value;
    } else // test for string
    {
      return value;
    }
    //throw new Error('normalizeValue: unknown type');
  }
}

export function normalizeChoices(choices?: string[] | number[] | null): string[] {
  if (choices === undefined || choices === null || choices.length === 0) {
    return [];
  } else {
    if (typeof choices[0] === 'string') {
      return choices as string[];
    } else if (typeof choices[0] === 'number')  {
      return choices.map((x) => x.toString());
    } else {
      throw new Error('string[] | number[] | null | undefined expected');
    }
  }
}

export function toShortDate(date?: Date | null) {
  return !date ? '' : date.toLocaleDateString();
}
