import {withDashCard} from '../../shared/withDashCard';
import ProjectDetailsOverviewSection from "./ProjectDetailsOverviewSection";
import ProjectDetailsMetricsSection from "./ProjectDetailsMetricsSection";
import ProjectDetailsSecondaryCategoriesSection from "./ProjectDetailsSecondaryCategoriesSection";
import ProjectDetailsConstructionTypesSection from "./ProjectDetailsConstructionTypesSection";
import ProjectDetailsSafetySection from "./ProjectDetailsSafetySection";
import SafetyNarrativeSection from "./SafetyNarrativeSection";
import ProgramInformationSection from "./ProgramInformationSection";
import { observer } from 'mobx-react';

const ProjectDetailsCard = withDashCard('Project Details', 'project-details-module',
          // list of components to add to the card (in order they should appear)
          [
            ProjectDetailsOverviewSection,
            ProgramInformationSection,
            ProjectDetailsMetricsSection,
            ProjectDetailsSecondaryCategoriesSection,
            ProjectDetailsConstructionTypesSection,
            ProjectDetailsSafetySection,
            SafetyNarrativeSection,
          ]
);

export default observer(ProjectDetailsCard);