import {DamAssetMetadata, IApiPerson} from "./AthenaClient";

export interface ISomeAsset {
  modifiedOn?: Date;
}

class BaseMod {

  protected sortAssets(someAssets?: ISomeAsset[]): void {
    if (someAssets == null) return;
    someAssets.sort((a: ISomeAsset, b: ISomeAsset) => {
      if (a.modifiedOn === undefined && b.modifiedOn === undefined) {
        return 0;
      }
      if (a.modifiedOn === undefined) {
        return 1;
      }
      if (b.modifiedOn === undefined) {
        return -1;
      }
      return b.modifiedOn.getTime() - a.modifiedOn.getTime();
    });
  }

}

export default BaseMod;
