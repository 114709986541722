import {DamAssetMetadata, IApiPerson} from "./AthenaClient";
import BaseMod from "./BaseMod";

interface ISomeAsset {
  modifiedOn?: Date;
}
// DamAssetMetadata[]

class PersonMod extends BaseMod {

  public enhancePerson(person: IApiPerson): IApiPerson {
    const p = person;
    this.sortAssets(p.assets?.images);
    this.sortAssets(p.assets?.documents);
    // this.sortAssets(p.assets?.videos); only url property on video

    return p;
  }
}

export default PersonMod;
