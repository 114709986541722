import React, {useContext} from "react";
import {InfotipContext} from "../../providers/InfotipProvider";
import {IconButton} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoIcon from '@material-ui/icons/Info';

type InfotipButtonProps = {
  infotipKey: string;
}


function InfotipButton(props: InfotipButtonProps) {
  const {openInfotip, closeInfotip} = useContext(InfotipContext);

  function handleClick(e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    openInfotip(props.infotipKey);
  }

  return (
    <IconButton onClick={(e) => handleClick(e)} size={'small'}
                data-style={{fontSize:'20px',color:'#aaaaaa'}}>
      <InfoIcon className={'infotip-icon'} /></IconButton>
  );
}

export default InfotipButton;