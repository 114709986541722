import AthenaClient, {
  ApiProject, IApiProject,
  ApiProjectHistory, DamAssetMetadata, Degree,
  IApiPerson, IApiProjectHistory, PersonAward,
  Training, IProjectAward, ProjectAward
} from "services/apiClients/AthenaClient";
import {diff} from "deep-object-diff";

export default class ProjectService {
  private athenaClient: AthenaClient;

  constructor(athenaClient: AthenaClient) {
    this.athenaClient = athenaClient;
  }


  // ADD
  public async addChild<T, R>(projectId: string, model: T, entityName: string): Promise<R> {
    // revisit ReturnType, typeof
    if (!entityName) {
      throw Error('entityName must be specified');
    }
    let result: object;
    switch (entityName) {
      case 'award':
        // createAward
        result = await this.athenaClient.createAward(projectId, model as unknown as ProjectAward);
        break;
      default:
        throw Error('a correct entityName must be specified');
    }
    return result as unknown as R;
  }

  // MODIFY
  public async modifyChild<T, R>(projectId: string, model: T, entityName: string): Promise<R> {
    if (!entityName) {
      throw Error('entityName must be specified');
    }
    let result: object;
    switch (entityName) {
        // updateAward2
      case 'award':
        result = await this.athenaClient.updateAward2(projectId,
            (model as unknown as ProjectAward).id as string,
            model as unknown as ProjectAward);
        break;
      default:
        throw Error('a correct entityName must be specified');
    }
    return result as unknown as R;
  }

  // DELETE
  public async deleteChild(projectId: string, awardId: string, entityName: string): Promise<object> {
    if (!entityName) {
      throw Error('entityName must be specified');
    }
    let result: object;
    switch (entityName) {
      case 'award':
        result = await this.athenaClient. deleteAward2(projectId, awardId);
        break;
      default:
        throw Error('a correct entityName must be specified');
    }
    return result;
  }

  public async getProject(id: string) {
    console.log('#@! b4 getProject');
    const result = await this.athenaClient.getProject(id);
    console.log('$L2 getProjectResult', {project:result})
    return result;
  }
}

