import AthenaClient, {
  IApiProject,
  IApiProjectStakeholder
} from "services/apiClients/AthenaClient";

export function isSyncedWithCRM(record: IApiProject) {
  const result = record.stakeholders &&
      record.stakeholders.some(isCrmSyncedOwner);
  return result ?? false;
}

function isCrmSyncedOwner(stakeholder: IApiProjectStakeholder) {
  const isManagedByCrm = stakeholder.isManagedByCrm;
  const hasOwnerRole = stakeholder.role === 'Owner';
  const syncResult = isManagedByCrm && hasOwnerRole;
  //console.log('!isCrmSyncedOwner', {syncResult,isManagedByCrm,hasOwnerRole});
  return syncResult;
}