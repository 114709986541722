import {ExportSchema, IExportSchema} from "services/apiClients/AthenaClient";

export function getDefaultSchemaForExport(entityName: string) {
  if (entityName === 'people') return getDefaultPeopleSchemaForExport();
  if (entityName === 'projects') return getDefaultProjectsSchemaForExport();
  throw new Error(`A non-valid entity name was used: ${entityName}`);
}

function getDefaultPeopleSchemaForExport() {
  const inner: IExportSchema = {
    "label": "Person",
    "type": "object",
    "referenceProperty": "name",
    "referencePropertyLabel": "Person",
    "properties": []
  } as IExportSchema;

  const schema: IExportSchema = {
    "label": "People",
    "type": "array",
    "items": new ExportSchema(inner),
  }
  return schema;
}

function getDefaultProjectsSchemaForExport() {
  const inner: IExportSchema = {
    "label": "Project",
      "type": "object",
      "referenceProperty": "name",
      "referencePropertyLabel": "Project",
      "properties": []
  } as IExportSchema;

  const schema: IExportSchema = {
    "label": "Projects",
    "type": "array",
    "items": new ExportSchema(inner),
  }

  return new ExportSchema(schema)
}