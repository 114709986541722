import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import {IPersonAward} from "services/apiClients/AthenaClient";

interface PeopleAwardDialogProps {
  open: boolean;
  dialogMode: string | null;
  model?: IPersonAward;
  onClose: () => void;
  onSubmit: (data: IPersonAward) => void;
  ticks: number;
}

const PeopleAwardDialog: React.FC<PeopleAwardDialogProps> = ({open, dialogMode, model, onClose, onSubmit, ticks}) => {
  const {handleSubmit, control, setValue} = useForm<IPersonAward>({
    defaultValues: model,
  });

  console.log('$$$@ from award dialog', model);

  React.useEffect(() => {
    if (model && dialogMode === 'edit') {
      setValue("id", model.id ?? '');
      setValue("title", model.title);
      setValue("subtitle", model.subtitle);
      setValue("sponsor", model.sponsor);
      setValue("date", model.date);
      setValue("citation", model.citation);
    } else {
      setValue("id", '');
      setValue("title", '');
      setValue("subtitle", '');
      setValue("sponsor", '');
      setValue("date", undefined);
      setValue("citation", '');
    }
  }, [model, setValue, dialogMode]);

  const handleFormSubmit = (data: unknown) => {
    onSubmit(data as IPersonAward);
  };

  return (<Dialog open={open} onClose={onClose}>
        {(dialogMode === 'new' || dialogMode === 'edit') &&
            <React.Fragment key={'innerDialog' + ticks}>
              <DialogTitle className={'bg-black text-white'}>{dialogMode === "new" ? "New Person Award" : "Edit Person Award"}</DialogTitle>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent>
                  <Controller
                      name="id"
                      control={control}
                      render={({field}) => <input type="hidden" {...field} />}
                  />

                  {/*<input name={'id'} value={model?.id ?? ''} type={'text'}/>*/}
                  <Controller
                      name="title"
                      control={control}
                      rules={{
                        validate: (value) => (value && value.trim().length > 0) || "The Title cannot be left blank",
                      }}
                      render={({field, fieldState}) => <TextField {...field} label="Title" fullWidth
                                                                  error={!!fieldState.error}
                                                                  helperText={fieldState.error ? fieldState.error.message : null}/>}
                  />
                  <Controller
                      name="subtitle"
                      control={control}
                      render={({field}) => <TextField {...field} label="Subtitle" fullWidth/>}
                  />
                  <Controller
                      name="sponsor"
                      control={control}
                      render={({field}) => <TextField {...field} label="Sponsor" fullWidth/>}
                  />
                  <Controller
                      name="date"
                      control={control}
                      rules={{
                        validate: (value) => (!isNaN(new Date(value ?? '').getTime()) &&
                            new Date(value ?? '').getFullYear() >= 1900) || "Invalid Date",
                      }}
                      render={({field, fieldState}) => {
                        // Format the date to "YYYY-MM-DD"
                        const formattedDate = field.value instanceof Date
                            ? `${field.value.getUTCFullYear()}-${String(field.value.getUTCMonth() + 1).padStart(2, '0')}-${String(field.value.getUTCDate()).padStart(2, '0')}`
                            : field.value;

                        return (<TextField
                            {...field}
                            value={formattedDate}
                            label="Award Date"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                        />);
                      }}
                  />
                  <Controller
                      name="citation"
                      control={control}
                      render={({field}) => <TextField {...field} label="Citation" fullWidth/>}
                  />
                </DialogContent>
                <DialogActions>
                  <Button type="submit" color="primary">Save</Button>
                  <Button onClick={onClose} color="primary">Cancel</Button>
                </DialogActions>
              </form>
            </React.Fragment>
        }
      </Dialog>
  );

}

export default PeopleAwardDialog;