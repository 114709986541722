import React, { useEffect, useState } from 'react';
import { Typography, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

import { IExportSchema } from '../../services/apiClients/AthenaClient';
import {label} from "../../utils/decorators";

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: 'calc(100% - 20px)',
    overflowY: 'auto',
  },
  root: {
    flexGrow: 1,
  },
});

interface ISchemaTreeView {
  entityName?: string;
  rootNode: IExportSchema;
  onNodePathSelected: (nodePath: string) => void;
}

export default function SchemaItemChooser(props: ISchemaTreeView) {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [filteredTree, setFilteredTree] = useState<React.ReactNode[]>([]);

  function handleNodeClick(nodePath: string) {
    props.onNodePathSelected(nodePath);
  }

  // useEffect(() => {
  //   const calculateExpandedAndFilteredNodes = (node: IExportSchema, parentId = ''): React.ReactNode => {
  //     const nodeIdPrefix = parentId === '' ? '' : `${parentId}-`;
  //     const newNodeId = `${nodeIdPrefix}${node.label}`;
  //
  //     let childrenArray: IExportSchema[] = [];
  //     if (Array.isArray(node.properties)) {
  //       childrenArray = [...childrenArray, ...node.properties];
  //     }
  //     if (node.items) {
  //       childrenArray = [...childrenArray, node.items];
  //     }
  //
  //     const label = (
  //         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
  //           <Typography>{node.label}</Typography>
  //           <IconButton onClick={() => handleNodeClick(newNodeId)}>
  //             <AddIcon />
  //           </IconButton>
  //         </div>
  //     );
  //
  //     const childrenNodes = childrenArray.map((node: IExportSchema) => calculateExpandedAndFilteredNodes(node, newNodeId));
  //     if (node.label.toLowerCase().includes(filter.toLowerCase()) || childrenNodes.some(childNode => childNode !== null)) {
  //       setExpandedNodes(prevExpandedNodes => [...prevExpandedNodes, newNodeId]);
  //       return (
  //           <TreeItem
  //               key={newNodeId}
  //               nodeId={newNodeId}
  //               label={label}>
  //             {childrenNodes}
  //           </TreeItem>
  //       );
  //     }
  //
  //     return null;
  //   };
  //
  //   setFilteredTree([calculateExpandedAndFilteredNodes(props.rootNode)]);
  // }, [props.rootNode, filter]);



  useEffect(() => {
    const calculateExpandedAndFilteredNodes = (node: IExportSchema, parentId = ''): React.ReactNode => {
      const nodeIdPrefix = parentId === '' ? '' : `${parentId}-`;
      const newNodeId = `${nodeIdPrefix}${node.label}`;

      let childrenArray: IExportSchema[] = [];
      if (Array.isArray(node.properties)) {
        childrenArray = [...childrenArray, ...node.properties];
      }
      if (node.items) {
        childrenArray = [...childrenArray, node.items];
      }

      // const label = (
      //     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      //       <Typography>{node.label}</Typography>
      //       <IconButton onClick={() => handleNodeClick(newNodeId)}>
      //         <AddIcon />
      //       </IconButton>
      //     </div>
      // );

      // Split the array into two: nodes without children (leafNodes) and nodes with children (nonLeafNodes)
      const leafNodes = childrenArray.filter(node => !node.properties && !node.items);
      const nonLeafNodes = childrenArray.filter(node => node.properties || node.items);

      // Process nodes without children first
      const childrenNodes = [...leafNodes, ...nonLeafNodes].map((node: IExportSchema) => calculateExpandedAndFilteredNodes(node, newNodeId));

      if (node.label?.toLowerCase().includes(filter.toLowerCase()) || childrenNodes.some(childNode => childNode !== null)) {
        setExpandedNodes(prevExpandedNodes => [...prevExpandedNodes, newNodeId]);
        return (
            <TreeItem
                key={newNodeId}
                label={<NodeLabel
                    label={node.label ?? ''}
                    newNodeId={newNodeId}
                    handleNodeClick={handleNodeClick}/>}
                    nodeId={newNodeId}>
                {childrenNodes}
            </TreeItem>
        );
      }

      return null;
    };

    setFilteredTree([calculateExpandedAndFilteredNodes(props.rootNode)]);
  }, [props.rootNode, filter]);



  return (
      <div style={{
        width: '100%',
        height: 'calc(60svh - 120px)',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div className="flex flex-row" style={{alignItems:'center'}}>
          <div>Choose fields:</div>
          <div style={{flex: 1}}>
            <TextField
                fullWidth
                variant="outlined"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder="Search fields..."
            />
          </div>
        </div>

        <TreeView
            className={classes.container}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expandedNodes}>
          {filteredTree}
        </TreeView>
      </div>
  );
}

interface NodeLabelProps {
  label: string;
  newNodeId: any; // Replace 'any' with the actual type
  handleNodeClick: (newNodeId: string) => void; // Replace 'any' with the actual type
}

const NodeLabel: React.FC<NodeLabelProps> = (props) => {
  // FIX: district issue
  const label = props.label === 'District' ? 'Region': props.label;

  // const fixLastEntry = (input: string) => {
  //   const parts = input.split('-');
  //   const lastPart = parts[parts.length - 1];
  //
  //   if (lastPart === 'District') {
  //     parts[parts.length - 1] = 'Region';  // Replace 'NewValue' with the value you want to swap in
  //   }
  //
  //   return parts.join('-');
  // };

  //
  // let newNodeId = '';
  // if (label === 'Region') {
  //   newNodeId = fixLastEntry(props.newNodeId);
  // } else {
  //   newNodeId = props.newNodeId;
  // }
  // if (label === 'Region') {
  //   console.log('!@#8 region thing', {props,label,newNodeId});
  // }


  return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Typography>{label}</Typography>
        <IconButton onClick={() => props.handleNodeClick(props.newNodeId)}>
          <AddIcon />
        </IconButton>
      </div>
  );
};