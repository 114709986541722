import React, {useContext, useEffect, useState} from "react";
import EditIcon from '@material-ui/icons/Edit';
import AthenaClient, {IApiPerson} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";
import CloseIcon from "@material-ui/icons/Close";
import DashRichTextEditor from "../shared/DashRichTextEditor";
import PersonDetailService from "services/PersonService";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "components/dashCore/DashItemProgress";
import EntityDialog from "../shared/EntityDialog";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import {makeStyles} from "@material-ui/core/styles";
import AthenaAuthorize from "../../../app/security/ui/AthenaAuthorize";

const useStyles = makeStyles(() => ({
  htmlContainer: {
    width: '98%',
    padding: '2px',
    whiteSpace: 'normal',
  },
}));

export default function NotesSection(props: { person: IApiPerson }) {
  //const classes = useSharedLargeCardStyles();
  const classes = useStyles();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [person, setPerson] = useState<IApiPerson | null>(null);
  const [saveCount, incr] = useSaveCount();
  const {athenaClient} = useAthenaClient();

  const toggleEditMode = () => {
    setEditMode(!editMode);
  }

  useEffect(() => {
    console.log('@@NotesSection', {saveCount, athenaClient, person})
    if (athenaClient && props.person.id) {
      const personService = new PersonDetailService(athenaClient);
      personService.getPerson(props.person.id as number)
          .then((value: IApiPerson) => {
            setPerson(value);
          });
    }
  }, [saveCount, athenaClient]);

  async function save() {
    const data = getNamedData(document.querySelector('.MuiDialogContent-root'));
    const personDetailService = new PersonDetailService(athenaClient as AthenaClient);
    await personDetailService.updatePerson(person as IApiPerson, data);
    setEditMode(false);
    setPerson(null);
    incr();
  }

  // loading
  if (person === null) {
    return <DashItemProgress />;
  }
  // end lev

  function NotesSectionDialog(props: {person: IApiPerson}) {
    const person = props.person;
    return (
        <EntityDialog entityName={'people'} model={person}
                      handleClose={toggleEditMode} handleSave={save}
                      innerComponent={<InnerNotesSection person={person}/>}/>
    );
  }

  const displaySomeWhiteSpace = `<br/><br/><br/>`;

  // without dialog
  function InnerNotesSection(props: {person:IApiPerson}) {
    return (
        <div>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div><h3 className={'sectionHeading'}>Notes</h3></div>
            <div className={classes.iconOuterContainer}>
              <div>
                <AthenaAuthorize allowedRoles={'Contributor'}>
                {(!editMode) ?
                    <EditIcon onClick={toggleEditMode} className={classes.editIcon}/> :
                    <CloseIcon onClick={toggleEditMode} className={classes.closeIcon}/>
                }
                </AthenaAuthorize>
                </div>
            </div>
          </div>
          <div>
            {(!editMode) ?
                <div className={classes.htmlContainer}
                     dangerouslySetInnerHTML={{__html: props.person?.notes ?? displaySomeWhiteSpace}}/> :
                <DashRichTextEditor
                    name={'notes'}
                    value={props.person?.notes ?? ''}/>
            }
          </div>
        </div>
    );
  }

  return (editMode)
      ? <NotesSectionDialog person={person} />
      : <InnerNotesSection person={person} />;
}
