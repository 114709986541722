import {useEffect, useRef, useState} from "react";
import useAccessToken from "hooks/useAdAccessToken/useAccessToken";
import AthenaClient from "../services/apiClients/AthenaClient";

export default function useAthenaClient(source?: string) {
    const {accessToken} = useAccessToken();

    const [athenaClient, setAthenaClient] = useState<AthenaClient | null>(null);
    const athenaClientRef = useRef<AthenaClient>();

    useEffect(() => {
        if (accessToken) {
            const client = new AthenaClient();
            client.setToken(accessToken); // for client through CORS to Dash API
            //const client = new AthenaClient(); // for local client and server work
            if (source) {
                client.setAdditionalHeaders({'X-Source': source});
            }
            setAthenaClient(client);
            athenaClientRef.current = client;
        }
    },[accessToken]);

    return {athenaClient, athenaClientRef};
}