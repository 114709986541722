import {withDashCard} from '../../shared/withDashCard';
import ServicesSection from "./ServicesSection";
import TransitionalServicesDescriptionSection from "./TransitionalServicesDescriptionSection";
import OperationsAndMaintenanceDescriptionSection from "./OperationsAndMaintenanceDescriptionSection";
import FacilityManagementDescriptionSection from "./FacilityManagementDescriptionSection";

const ServicesCard = withDashCard('Services', 'services-module',
    // list of components to add to the card (in order they should appear)
    [
      ServicesSection,
      TransitionalServicesDescriptionSection,
      OperationsAndMaintenanceDescriptionSection,
      FacilityManagementDescriptionSection,
    ]
,'servicesSection');

export default ServicesCard;