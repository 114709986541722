import React, {useEffect, useRef, useState} from "react";
import {FormControl, InputLabel, Select, makeStyles, MenuItem, MenuList} from "@material-ui/core";
import {DefaultValue} from "recoil";
import noop from "../../utils/noop";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface IDashSelector {
  label?: string;
  name?: string;
  value?: string;
  options?: Record<string, string> | string[];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>, action: IAction<ISelectedOption>) => void;
}

export interface IAction<T> {
  type: string;
  payload: T;
  tag?: unknown;
}

export interface IOption {
  label: string;
  value: string;
}

export interface ISelectedOption {
  name: string;
  label: string;
  value: string;
}

function normalizeOptions(options?: Record<string, string> | string[]): Record<string, string> {
  if (!options) {
    return {};
  }
  if (Array.isArray(options)) {
    return options.reduce((acc, curr) => ({ ...acc, [curr]: curr }), {});
  }
  return options;
}

export default function DashSelector(props: IDashSelector) {
  const classes = useStyles();

  const initOptions = {} as Record<string,string>;
  const [normOptions, setNormOptions] = useState<Record<string,string>>(initOptions);
  const selectRef = useRef<HTMLSelectElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    setNormOptions(normalizeOptions(props.options));
    setSelectedOption(props.value || '');
    //console.log('Normed Options',normalizeOptions(props.options));
  },[props.options,props.value]);

  function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    const value = event.target.value as string;
    const name = event.target.name;
    //console.log('!!!!! HANDLE CHANGE ', {name,value});
    setSelectedOption(value);
    //const label = selectRef.current?.selectedOptions.length ? selectRef.current.selectedOptions[0].label : '';
    const label = normOptions ? normOptions[value] : '';
    const payload =  { label, value, name } as ISelectedOption;
    const action: IAction<ISelectedOption> = { type: "change", payload: payload };
    if (value !== selectedOption) { // Check if the selected value has changed
      setSelectedOption(value);
      if (props?.onChange) {
        props.onChange(event as React.ChangeEvent<HTMLSelectElement>, action);
      }
    }
  }

  return (
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
        <Select
          ref={selectRef}
            // defaultValue={props.value ?? ''}
            value={selectedOption}
            onChange={handleChange}
            inputProps={{ name: props.name }}
        >
            {Object.entries(normOptions || {}).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
            ))}
        </Select>
      </FormControl>
  );
}
