type RegionInfo = {
  code: string;
  completeName: string;
  midsizeName: string;
  abbrev: string;
  codeAndAbbrev: string;
  codeAndMidsize: string;
};

type Option = 'label' | 'abbr' | 'num' | 'indexedName';

export const regionData: Record<string, RegionInfo> = {
  "100": { code: "100", completeName: "Corporate", midsizeName: "Corporate", abbrev: "CORP", codeAndAbbrev: "100 CORP", codeAndMidsize: "100 Corporate" },
  "200": { code: "200", completeName: "Mid Atlantic", midsizeName: "Mid Atlantic", abbrev: "MATL", codeAndAbbrev: "200 MATL", codeAndMidsize: "200 Mid Atlantic" },
  "250": { code: "250", completeName: "Mid South", midsizeName: "Mid South", abbrev: "MW", codeAndAbbrev: "250 MW", codeAndMidsize: "250 Mid South"},
  "300": { code: "300", completeName: "Rocky Mountain", midsizeName: "Rocky Mtn", abbrev: "RMTN", codeAndAbbrev: "300 RMTN", codeAndMidsize: "300 Rocky Mtn" },
  "350": { code: "350", completeName: "Water", midsizeName: "Water", abbrev: "WATR", codeAndAbbrev: "350 WATR", codeAndMidsize: "350 Water" },
  "400": { code: "400", completeName: "Southern California", midsizeName: "SoCal", abbrev: "SCAL", codeAndAbbrev: "400 SCAL", codeAndMidsize: "400 SoCal" },
  "500": { code: "500", completeName: "Northern California", midsizeName: "NorCal", abbrev: "NCAL", codeAndAbbrev: "500 NCAL", codeAndMidsize: "500 NorCal" },
  "590": { code: "590", completeName: "Southwest", midsizeName: "Southwest", abbrev: "SWST", codeAndAbbrev: "590 SWST", codeAndMidsize: "590 Southwest" },
  "600": { code: "600", completeName: "Pacific", midsizeName: "Pacific", abbrev: "PCFC", codeAndAbbrev: "600 PCFC", codeAndMidsize: "600 Pacific" },
  "650": { code: "650", completeName: "Pacific Northwest", midsizeName: "Pacific NW", abbrev: "PNWT", codeAndAbbrev: "650 PNWT", codeAndMidsize: "650 Pacific NW" },
  "700": { code: "700", completeName: "Southcentral", midsizeName: "Southcentral", abbrev: "SCTL", codeAndAbbrev: "700 SCTL", codeAndMidsize: "700 Southcentral" },
  "710": { code: "710", completeName: "Services", midsizeName: "Services", abbrev: "SVCS", codeAndAbbrev: "710 SVCS", codeAndMidsize: "710 Services" },
  "800": { code: "800", completeName: "Southeast", midsizeName: "Southeast", abbrev: "SEST", codeAndAbbrev: "800 SEST", codeAndMidsize: "800 Southeast" },
  "850": { code: "850", completeName: "Mission Critical", midsizeName: "MCIS", abbrev: "MCIS", codeAndAbbrev: "850 MCIS", codeAndMidsize: "850 MCIS" },
  "900": { code: "900", completeName: "Development", midsizeName: "Development", abbrev: "DEVT", codeAndAbbrev: "900 DEVT", codeAndMidsize: "900 Development" }
};

const labels: Record<string, string> = Object.fromEntries(Object.keys(regionData).map(key => [key, regionData[key].completeName]));

const abbreviations: Record<string, string> = Object.fromEntries(Object.keys(regionData).map(key => [key, regionData[key].abbrev]));

export function getAllRegionCodes(): string[] {
  return Object.keys(regionData);
}

export function getAllRegionIndexedNames(): string[] {
  return Object.keys(regionData).map(key => {
    const { completeName, code } = regionData[key];
    return `${completeName} (${code})`;
  });
}

export function getDistrictDisplayName(input: string | string[] = '', option?: Option) {
  if (input) {
    if (Array.isArray(input)) {
      input = input.join(',');
    }
    if (input.indexOf(',') > -1) {
      const noQuotes = input.replace(/"/g, '');
      return mapMultipleDistricts(noQuotes.split(','), option);
    }
    return mapSingleDistrict(input, option);
  }
}

function mapMultipleDistricts(inputs: string[], option?: Option) {
  return inputs.map(function(input) {
    return mapSingleDistrict(input, option);
  }).join(', ');
}

function mapSingleDistrict(input: string, option?: Option) {
  if (option === 'abbr') {
    return getLabel(abbreviations, input);
  } else if (option === 'num') {
    return getDistrictNumber(input);
  } else if (option === 'indexedName') {
    return getIndexedName(input)
  } else {
    return getLabel(labels, input);
  }
}

function getLabel(labels: Record<string, string>, input: string) {
  const district = getDistrictNumber(input);
  return Object.keys(labels).includes(district) ? labels[district] : district;
}

function getDistrictNumber(input: string) {
  const regex = /\d+/;
  const matches = input.match(regex);
  return matches == null ? 'NULL' : matches[0];
}

function getIndexedName(input: string) {
  const district = getDistrictNumber(input);
  if (Object.keys(regionData).includes(district)) {
    const { completeName, code } = regionData[district];
    return `${completeName} (${code})`;
  }
  return `NULL (${district})`;
}
