import {IApiPerson} from "../../services/apiClients/AthenaClient";
import {getJobTemplate} from "./resumeTemplatesHardcoded";

type TemplateVariables = Record<string, string>;
type TemplatePair = { person: IApiPerson, variables: TemplateVariables };


export default class ResumeIntroLogic {

  makeResumeIntroByProcessing(person: IApiPerson) {
    if (person?.title) {
      const jobTitle = person.title as string;
      const template = getJobTemplate(jobTitle);
      if (!template) return null;
      const tv: TemplateVariables = {};
      const variables = this.resolveVariables({person, variables: tv});
      return this.getProcessedIntro(variables, template);
    } else {
      return null;
    }
  }

  private getProcessedIntro(templateVariables: TemplateVariables, template: string) {
    for (const key in templateVariables) {
      const pattern = '{{' + key + '}}';
      // fix subtle falsy bug
      if (templateVariables[key] != null) {
        const value = templateVariables[key];
        template = template.split(pattern).join(value);
      }
    }
    return template;
  }

  private resolveVariables(templatePair: TemplatePair) {
    const p = templatePair.person;
    let v = {...templatePair.variables};
    v.firstName = p.firstName as string;
    v.lastName = p.lastName as string;
    //templatePair.variables.districtName = lookupDistrictName(p.district);
    v.districtName = p.district ?? '';
    v = this.mkGenderPronouns(v, p);
    console.log('!@#abcd gender pronouns', v);
    return v;
  }

  private mkGenderPronouns(variables: TemplateVariables, person: IApiPerson): TemplateVariables {
    const newVariables = {...variables};  // Create a new copy of variables

    if (person.gender === 'F') {
      newVariables.salutation = 'Ms.';
      newVariables.subjectPronoun = 'she';
      newVariables.objectPronoun = 'her';
      newVariables.possessivePronoun = 'her';
    } else if (person.gender === 'M') {
      newVariables.salutation = 'Mr.';
      newVariables.subjectPronoun = 'he';
      newVariables.objectPronoun = 'him';
      newVariables.possessivePronoun = 'his';
    } else {
      newVariables.salutation = '';
      newVariables.subjectPronoun = 'this person';
      newVariables.objectPronoun = 'them';
      newVariables.possessivePronoun = 'their';
    }
    newVariables.capitalizedSubjectPronoun = this.capitalizeFirstLetter(
        newVariables.subjectPronoun
    );
    newVariables.capitalizedPossessivePronoun = this.capitalizeFirstLetter(
        newVariables.possessivePronoun
    );

    return newVariables;
  }

  private capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}