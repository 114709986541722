import { useState } from 'react';

export default function useSaveCount() {
  const [saveCount, setSaveCount] = useState(0);

  const incrementSaveCount = () => {
    setSaveCount(ct => ct + 1);
  }

  return [saveCount, incrementSaveCount] as const;
}
