import {makeStyles} from "@material-ui/core/styles";

export const useSharedLargeCardStyles = makeStyles((theme) => ({
  detailView: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    height: '100%',
  },
  cardTitle: {
    '.MuiCardHeader-title': {
      fontWeight: 'bold',
    }
  },
  outerSideNavBar: {
    flex: '1 0 64px',
  },
  detailViewContent: {
    flex: '1 1 auto',
    overflowY: 'scroll',
    height: '100%'
  },
  defaultCard: {
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    '& > div.MuiCardHeader-root': {
      paddingBottom: '5px',
      borderBottom: '2px solid ' + theme.palette.primary.main,
    },
    '& > div.MuiCardContent-root': {
      paddingTop: '5px',
      flexWrap: 'wrap',
    },
  },
  titleArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fieldArea: {
    border: '0 dotted #eee',
    width: '100%',
    // maxHeight: '700px',
    // overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldAreaPoppedUp: {
    top: '100px',
    border: '0 dotted #eee',
    width: '100%',
    maxHeight: '60vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change the opacity as needed
    zIndex: 10, // Make sure the overlay appears on top of other elements
  },
  normalSection: {
    backgroundColor: '#fff',
    //width: '100%',
    margin: '5'
  },

  // remove headerButtonText - Feb 2023
  headerButtonText: {
    textTransform: 'uppercase',
  },
  iconOuterContainer: {
    paddingTop: '14px',
  },
  editIcon: {
    verticalAlign: 'text-top',
    cursor: 'pointer',
  },
  closeIcon: {
    verticalAlign: 'baseline',
    cursor: 'pointer',
  },
  disableTransition: {
    transition: 'none',
  },
  likeSyncFusionButton: {
    fontFamily: '"Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif"',
    fontSize: '14px',
    textTransform: 'none',
  },
  switch: {
    position: 'absolute',
    right: 0,
  },
  innerDialogPadding: {
    padding: '20px',
  }
  // displayNone: {
  //   display: 'none',
  // }
}));
