import React, {useEffect, useRef, useState} from "react";
import useReferenceDataForProjects from "hooks/useFieldOptions/useReferenceDataForProjects";
import {IDropDownOptions} from "hooks/useFieldOptions/localTypesAndUtil";
import {
  ApiContact,
  ApiPersonReference,
  IApiPersonReference,
  IPersonContact,
  IQueryableContact,
  PersonContact
} from "services/apiClients/AthenaClient";
import useAthenaClient from "hooks/useAthenaClient";
import {createControls} from "metaprogramming/formDSL/createControls";
import {psvToArray} from "metaprogramming/formDSL/psv";
import {psvAddReferenceForm, psvEditReferenceForm, psvViewReferenceForm} from "./referenceConfig";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import _ from "lodash";
import useSaveCount from "../../../../hooks/useSaveCount";
import {createApiPersonReference, IAddReferenceProps, usePersonReferenceStyle} from "./personReferenceUtil";
import {getCrmContactLink} from "../../../../config/microsoftDynamics";
import AthenaAuthorize from "../../../../app/security/ui/AthenaAuthorize";
import {EditOutlined} from "@material-ui/icons";
import DashDivider from "../../../DashDivider";


export default function PersonReferenceDialog(props: IAddReferenceProps) {
  const classes = usePersonReferenceStyle();
  const {athenaClient} = useAthenaClient();
  const [initValues, setInitValues] = useState<object>({});
  const [mode, setMode] = useState<object | null>(null);
  const [isSavingPersonOnProject, setIsSavingPersonOnProject] = useState(false);
  const [contactId, setContactId] = useState(props.contactId);
  const [contact, setContact] = useState<IQueryableContact | null>(null);
  const [reference, setReference] = useState<ApiPersonReference | null>(null);
  const {options}: IDropDownOptions = useReferenceDataForProjects();
  const [editMode, setEditMode] = useState(props.mode);
  const [personReference, setPersonReference] = useState<IApiPersonReference | null>(null);
  const [name, setName] = useState('_');
  const contactLoaded = useRef(false);
  const [saveCount, incr] = useSaveCount();
  const [controls, setControls] = useState<JSX.Element[]>([]);
  const [model, setModel] = useState<Record<string, any> | IApiPersonReference | null>(null);
  const detector = useRef(0);

  console.log('current editMode from AddReference.tsx', {editMode, 'props.mode': props.mode});

  let formInstance: HTMLFormElement | null = null;

  const hasSetPersonReference = useRef(false);

  useEffect(() => {
    //console.log('!@#9 - 9', props)
    if (!hasSetPersonReference.current) {
      if (props.personReference != null) {
        //console.log('!@#9 - 9b', {'props.personReference': props.personReference});
        setPersonReference(props.personReference);
        setName(props.personReference.fullName as string);
        hasSetPersonReference.current = true;
        personReferenceEditFlow(props.personReference);
      }
    }
    if (props.mode === 'add' && contactId && !props.personReference) {
      contactIdAddFlow(contactId);
    }
  }, [props.personReference, props.contactId, props.mode, athenaClient]);


  function setFormByRef(formElem: HTMLFormElement) {
    formInstance = formElem;
  }

  function extractDataFromForm(formData: FormData) {
    const data: Record<string, string | number | boolean | null | undefined> = {};
    data['contactId'] = formData.get('contactId') as string;
    data['personId'] = Number(formData.get('personId') as string);
    data['personGuid'] = formData.get('personGuid') ? formData.get('personGuid') as string : undefined;
    data['referenceType'] = formData.get('referenceType') as string;
    if (data['referenceType'] === '') data['referenceType'] = undefined;
    data['comments'] = formData.get('comments') as string;
    if (data['comments'] === '') data['comments'] = undefined;

    return data;
  }

  async function handleSave() {
    const data = extractDataFromForm(new FormData(formInstance!)) as IPersonContact;
    console.log('!@#11 data', {data})
    const contact: IPersonContact = await athenaClient!.createOrUpdatePersonContactLink2(
        data.personId!, data.contactId!, data as PersonContact);
    props.onClose();
  }

  function actAsRecord(func: (obj: Record<string, unknown>) => void) {
    return function <T>(obj: T) {
      func(obj as unknown as Record<string, unknown>);
    };
  }
// UNDO ALL WORK AFTER BACKUP AND SAVE
  function doFormWork(tmpModel: IApiPersonReference) {
    setModel(tmpModel);
    const psvSource = editMode === 'edit'
        ? psvAddReferenceForm: (editMode === 'edit' ? psvEditReferenceForm: psvViewReferenceForm);
    const psvArray = psvToArray(psvAddReferenceForm, true);
    const tmpControls = createControls(psvArray, tmpModel, undefined, options, classes);
    console.log('from personReferenceEditFlow', props.contactId, {contact, psvArray, tmpControls});
    setControls(tmpControls);
    console.log('controls before render', {tmpControls});
  }

  function contactIdAddFlow(contactId: string) {
    console.log('!@#9 - 10', contactId);
    if (athenaClient && contactId) {
      athenaClient.getSingleContact(contactId).then((apiContact) => {
        console.log('!@#9 - 12');
        const tmpModel = createApiPersonReference(apiContact, props.personId, props.personGuid);
        console.log('!@#99', tmpModel);
        setContact(apiContact);
        setName(tmpModel.fullName as string);
        setPersonReference(tmpModel);
        doFormWork(tmpModel);
      });
    }
  }

  function personReferenceEditFlow(personReferenceArg: IApiPersonReference) {
    detector.current = 4;
    if (personReferenceArg != null) {
      const tmpModel = personReferenceArg as IApiPersonReference;
      if (tmpModel.crmId && athenaClient) {
        athenaClient.getSingleContact(tmpModel.crmId).then((value: ApiContact) => {
          console.log('!@#99', value);
        });
      }
      console.log('!@#9 tmpModel', {tmpModel, 'props.personReference': props.personReference});
      doFormWork(tmpModel);
    }
  }

  const switchEditMode = (nextEditMode: string) => {
    setEditMode(nextEditMode);
  }

  useEffect(() => {
    if (editMode !== 'view') {
      const el1 = document.getElementsByName('referenceType')[0] as HTMLInputElement | null;
      if (el1) {
        el1.disabled = false;
        el1.classList.remove('Mui-disabled');
        el1.parentElement.classList.remove('Mui-disabled');
      }
      const el2 = document.getElementsByName('comments')[0] as HTMLInputElement | null;
      if (el2) {
        el2.disabled = false;
        el2.classList.remove('Mui-disabled');
        el2.parentElement.classList.remove('Mui-disabled');
      }
    }
  }, [editMode, contact, controls]);

  function switchToEditMode() {
    setEditMode('edit');
  }

  return (
      <Dialog open={props.open} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle className="text-white bg-black">
          {_.capitalize(editMode) + ` Reference for ${name ?? ''}`}
        </DialogTitle>

        <DialogContent>
          <form ref={x => setFormByRef(x as HTMLFormElement)}>
            {/*<h2>{name}</h2>*/}
            {/*<h2>{detector.current}</h2>*/}
            <div className={classes.flexContainer}>
              <div style={{width: "75%"}}>
                <h2>{name}</h2>
                {/*<h2>{personReference?.crmId}</h2>*/}
              </div>
              <div style={{width: "25%", textAlign: "right"}}>
                {personReference && personReference.crmId &&
                    <a style={{display: 'inline-block'}}
                       target={'_blank'} href={getCrmContactLink(personReference.crmId)}>View in CRM</a>
                }
                {editMode === 'view' &&
                    <AthenaAuthorize allowedRoles={'Contributor'}>
                        <DashDivider width={'20px'} height={'20px'} display={'inline-block'}/>
                        <IconButton onClick={() => switchEditMode('edit')}
                                    hidden={!editMode}>
                            <EditOutlined/>
                        </IconButton>
                    </AthenaAuthorize>
                }
              </div>
              {/*<div style={{width: '100%', textAlign: 'right'}}>*/}
              {/*  {editMode === 'view' && model?.crmId &&*/}
              {/*      <IconButton onClick={switchToEditMode}>*/}
              {/*          <EditOutlined />*/}
              {/*      </IconButton>*/}
              {/*  }*/}
              {/*</div>*/}
              {controls}
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <div className="flex flex-row">
            {editMode !== 'view' &&
                <div><Button onClick={() => handleSave()}>Save</Button></div>
            }
            <div><Button onClick={() => props.onClose()}>Cancel</Button></div>
          </div>
        </DialogActions>
      </Dialog>
  );
}
