import {IApproval, IApprovalWithKey} from "types/croneDashTypes";
import {IApiProject, IProjectApproval} from "services/apiClients/AthenaClient";
import {Switch} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import useCurrentUser from "hooks/useCurrentUser";
import {RedX} from "./RedX";
import {GreenCheck} from "./GreenCheck";
import {formatDateForDash} from "utils/dateUtil";
import _ from "lodash";


import CoreDebug from "features/core/CoreDebug";

interface IDashApprovalProps {
  valueGraph?: unknown | undefined;
  approvalKey: string;
  editMode?: boolean;
  onApprovalChange?: (approvalValue: IApprovalWithKey) => void;
  infoMode?: 'detailed' | 'minimal' | undefined;
}

interface IHasIdAndApprovals {
  id: string;
  approvals: IProjectApproval[];
}

export default function DashApproval(props: IDashApprovalProps) {
  const valueGraph = props.valueGraph as IHasIdAndApprovals;
  const currentUser = useCurrentUser();
  const initApprovalStatus: IProjectApproval | undefined = valueGraph?.approvals
      ? valueGraph.approvals.find(x => x.approvalKey === props.approvalKey)
      : undefined;
  const [fieldModifiedBy, setFieldModifiedBy] = useState(initApprovalStatus?.modifiedBy ?? undefined);
  const [fieldModifiedOn, setFieldModifiedOn] = useState(initApprovalStatus?.modifiedOn ?? undefined);
  const [isApproved, setIsApproved] = useState(initApprovalStatus?.isApproved ?? false);
  const [approval, setApproval] = useState<{approvals: IApprovalWithKey[]} | IApproval | null>(null);

  const log = CoreDebug.getConsoleLogFunc(true);

  log('!@#2 initApprovalStatus', {valueGraph, approvalKey:props.approvalKey, initApprovalStatus});

  useEffect(() => {
    if(props.editMode === false){
      setIsApproved(initApprovalStatus?.isApproved ?? false);
      setFieldModifiedBy(initApprovalStatus?.modifiedBy ?? undefined);
      setFieldModifiedOn(initApprovalStatus?.modifiedOn ?? undefined);
    }
  }, [props.editMode])

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dt = new Date();
    const displayName = currentUser?.displayName;

    const isChecked = event.target.checked ?? false;
    setIsApproved(isChecked);
    setFieldModifiedOn(dt);
    setFieldModifiedBy(displayName);
    if (props.valueGraph) {
      const tmpApprovalsWithKey = {
        approvals: [{
            id: valueGraph.id ?? '',
            isApproved: isChecked,
            approvalKey: props.approvalKey,
            fieldModifiedOn: dt,
            fieldModifiedBy: displayName
          }] as IApprovalWithKey[]
      };
      log('onSwitchChange', event.target.checked, tmpApprovalsWithKey);
      setApproval(tmpApprovalsWithKey);
    } else {
      // simple approval
      const tmpApproval = {
        isApproved: isChecked,
        fieldModifiedOn: dt,
        fieldModifiedBy: displayName
      } as IApproval;
      log('onSwitchChange', event.target.checked, tmpApproval);
      setApproval(tmpApproval);
    }
  };


    if (props?.approvalKey === 'fakeKey') {
      console.log('!@#2 valueGraph', props.valueGraph, props.approvalKey);
    }


  return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1 1 50%', textAlign: 'left' }}>
          <input name={'_json'} type="hidden"
                 value={JSON.stringify(approval)} /><br />
          { props.editMode ?
              <><b>Approval: </b><Switch color="primary" className={'switch-approval'}
                checked={_.includes(['true', true], isApproved)}
                onChange={onSwitchChange} inputProps={{ 'aria-label': '' }}
          /></>: (<>
              { isApproved ? <GreenCheck />: <RedX />
                } <span>
                  {(_.includes(['true', true], isApproved) ?
                      `Approved by ${fieldModifiedBy} on ${formatDateForDash(fieldModifiedOn)}`: 'Not Approved' )}</span>
              </>)}
        </div>
        <div style={{ flex: '1 1 50%', textAlign: 'left' }}>
          { (props.infoMode !== 'minimal') &&
          <>
            <span>{fieldModifiedBy ? `Field Modified By: ${fieldModifiedBy}`: ''}</span><br/>
            <span>{fieldModifiedOn ? `Field Modified On: ${formatDateForDash(fieldModifiedOn)}`: ''}</span>
          </>
          }
        </div>
      </div>
  );
}
