import DashDivider from 'components/DashDivider';
import DraggableList, { DraggableItem } from 'components/DraggableList';
import useAthenaClient from 'hooks/useAthenaClient';
import useCurrentUser from 'hooks/useCurrentUser';
import useSaveCount from 'hooks/useSaveCount';
import React, {useContext, useEffect, useRef, useState} from 'react';
import { ExportSchema, IExportSchema, ITemplate, Template } from 'services/apiClients/AthenaClient';
import {ExportAndSchemaContext} from "features/export/ExportAndSchemaProvider";

import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DraggableListItem from '../../components/DraggableListItem';
import EditTemplateSchema, { IEditTemplateSchemaMethods } from './EditTemplateSchema';
import SchemaItemChooser from './SchemaItemChooser';
import TemplateList from './TemplateList';
import CoreLocation from "../core/CoreLocation";
import {useRecoilValue} from "recoil";
import {flexState} from "../core/flexState";
import {getDefaultPeopleSchemaForExport, getDefaultSchemaForExport} from "./meta/defaultExportSchema";
import ProgressButton from "../../components/dashCore/ProgressButton";
import useToast from "../../hooks/useToast";
import DashToaster from "../../patterns/DashToaster";
import useDashUrlInfo from "../../hooks/useDashUrlInfo";
import globalState from "../../app/state/GlobalState";
import _ from "lodash";


interface ITemplateEditorDialog {
  open: boolean;
  mode?: string;
  onClose: () => void;
  entityName: string;
  onNamedExport: (templateId: string) => void;
  // add other properties here as needed
}

const useStyles = makeStyles(() => ({
  textFldCellLeft: {
    padding: '5px 5px 5px 0',
  },
  textFldCellRight: {
    padding: '5px 0 5px 5px',
  },
  // textField: {
  //
  // },
  select: {
    paddingRight: '20px',
  },
  lessPadding: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    }
  }
}));


export default function TemplateDesigner(props: ITemplateEditorDialog) {
  const classes = useStyles();
  const { athenaClient } = useAthenaClient();
  const [exportSchemaRoot, setExportSchemaRoot] = useState<IExportSchema>()
  const editTemplateSchemaMethodsRef = useRef<IEditTemplateSchemaMethods | null>(null);
  const user = useCurrentUser();
  const [owner, setOwner] = useState<string>();
  const [templateId, setTemplateId] = useState<string>();
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [activeTemplate, setActiveTemplate] = useState<ITemplate | null>(null);
  //const [activeTemplate, setActiveTemplate] = useState<ITemplate | null>(null);
  const [fileFormat, setFileFileFormat] = React.useState('xlsx');
  const [mode, setMode] = useState(props.mode);
  const [count, incr] = useSaveCount();
  const toaster = useToast();
  const dashToaster = new DashToaster(toaster.displayToast);
  const exportAndSchemaContext = useContext(ExportAndSchemaContext);

  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const {entityName, id} = useDashUrlInfo();
  console.log('#@!33 dash det id=', id);
  ////const recordCount = id ? 1: useRecoilValue(flexState('current-record-count'));
  const recordCount = id ? 1: globalState.currentRecordCount;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateDescription(event.target.value);
  };

  useEffect(() => {
    function loadTemplate(templateId: string) {
      if (athenaClient) {
        athenaClient.getTemplate(templateId).then((template: ITemplate) => {
          if (template.schema) {
            setActiveTemplate(template);
            console.log(template.schema);
          }
          setTemplateName(template.name ?? '');
          setTemplateDescription(template.description ?? '');

          setMode('edit');
          incr();
        });
      }
    }
    const loadReferenceSchema = async (entityName: string) => {
      if (athenaClient && user?.userPrincipalName) {
        console.log('!tvc ue entityName:', entityName);
        if (!templateId) {
          const tmpRoot = await athenaClient.getEntireSchema2(entityName);
          setExportSchemaRoot(tmpRoot);
          setOwner(user.userPrincipalName);

          // setExportSchemaRoot(getDefaultSchemaForExport(entityName));
        } else {
          loadTemplate(templateId);
        }
      }
    }

    if (props.entityName) {
      loadReferenceSchema(props.entityName);
    }
  }, [athenaClient, props.entityName, templateId, user]);

  type ExportSchema = {
    // Your ExportSchema definition goes here
  };

  function fixDistrict(thing: ExportSchema): ExportSchema {
    // Convert the object to a JSON string
    let jsonString = JSON.stringify(thing);

    // Perform the search and replace operation
    // For example, replacing all occurrences of "oldValue" with "newValue"
    //jsonString = jsonString.replace(/"oldValue"/g, '"newValue"');
    jsonString = jsonString.replace(/"label":"District"/g, '"label":"Region"');

    // Convert the modified JSON string back to the object
    const updatedThing: ExportSchema = JSON.parse(jsonString);

    return updatedThing;
  }

  function handleNodePathSelected(nodePath: string) {
    //alert(nodePath);
    if (athenaClient && props.entityName) {
      athenaClient.getEntireSchemaEx(props.entityName, nodePath)
        .then(es => {
          const fes = fixDistrict(es);
          console.log('!@#9 ExportSchema from getEntireSchemaEx API call', {es, fes});
          editTemplateSchemaMethodsRef.current?.mergeSchema(fes);
        });
    }
  }

  if (!props.open) {
    return <div></div>
  }

  if (!exportSchemaRoot) {
    return <div><CircularProgress /></div>
  }

  const handleFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFileFileFormat(event.target.value as string);
  };

  function handleNewTemplate() {
    handleAction('new', null);
  }

  async function handleAction(action: string, payload: ITemplate | unknown | string | undefined | null) {
    if (!athenaClient) return;
    //console.log('HANDLE made it past the guard - action: ' + action, { payload })
    switch (action) {
      case 'close':
        setMode('index');
        setTemplateId(undefined);
        props.onClose();
        break;
      case 'reload':
        incr();
        break;
      case 'export':
        exportAndSchemaContext.startExport(CoreLocation.getPathAndSearch(), fileFormat, undefined, tmpTemplate);
        props.onClose();
        break;
      case 'exportAdhoc': // from
        if (props.entityName === 'projects' || props.entityName === 'people') {
          const t = new Template();
          t.name = templateName;
          t.description = templateDescription;
          t.entity = props.entityName;
          t.target = 'exports';
          const tmpSchema = editTemplateSchemaMethodsRef.current.getCurrentSchema();
          console.log(tmpSchema);
          t.schema = ExportSchema.fromJS(tmpSchema);
          const tmpTemplate = new Template(t);
          exportAndSchemaContext.startExport(CoreLocation.getPathAndSearch(), fileFormat, undefined, tmpTemplate);
          props.onClose();
        }
        break;
      case 'exportFromShoppingBag':
        if (payload) {
          _.noop();
          const tmpTemplateId = (payload as Template).id;
          if(tmpTemplateId){
            const template: Template = await athenaClient.getTemplate(tmpTemplateId);
            exportAndSchemaContext.startExport(CoreLocation.getPathAndSearch(), 'xlsx', tmpTemplateId, template);//tmpTemplate);
          }
          props.onClose();
        }
        break;
      case 'new': {
        setMode('new');
        const t = new Template();
        t.name = '';
        t.description = '';
        t.entity = props.entityName;
        t.schema = new ExportSchema(getDefaultSchemaForExport(CoreLocation.getEntityName() as string));
        setTemplateName('');
        setTemplateDescription('');
        setActiveTemplate(t);
        break;
      }
      // case 'newFromCopy': {
      //   setMode('edit');
      //   const t = new Template();
      //   t.name = '';
      //   t.description = '';
      //   t.entity = props.entityName;
      //   t.schema = new ExportSchema(getDefaultSchemaForExport(CoreLocation.getEntityName() as string));
      //   setTemplateName('');
      //   setTemplateDescription('');
      //   setActiveTemplate(t);
      //   break;
      // }
      case 'edit':
        setMode('edit');
        setTemplateId(payload as string);
        break;
      case 'upsert': {
        const t = !templateId ? activeTemplate as Template : await athenaClient.getTemplate(templateId);
        t.name = templateName;
        t.description = templateDescription;
        t.entity = props.entityName;
        t.target = 'exports';
        if (t.name.trim() === '') {
          alert('The template must be giving a name?');
          return;
        }
        const tmpSchema = editTemplateSchemaMethodsRef.current.getCurrentSchema();
        if (tmpSchema.items) {
          t.schema = ExportSchema.fromJS(tmpSchema);
          //console.log('schema deserialized to ExportSchema', t.schema);

          let nextTemplate: Template | null;
          const tmpTemplate = new Template(t);
          if (t.id) {
            //console.log('updateTemplate called', { templateId: t.id, changes: tmpTemplate })
            nextTemplate = await athenaClient.updateTemplate(t.id, tmpTemplate);
            if (nextTemplate) {
              dashToaster.displaySavedToast(t.name, 'template');
            }
          } else {
            nextTemplate = await athenaClient.createTemplate(tmpTemplate);
            dashToaster.displaySavedToast(t.name, 'template');
          }
          setTemplateId(nextTemplate.id);
        }
        setSaving(false);
        setSaved(true);
        break;
      }
      case 'delete': {
        const t = payload as ITemplate;
        if (confirm(`Are you sure you want to delete template ${t.name}`)) {
          await athenaClient.deleteTemplate(t.id as string);
          dashToaster.display(`You have successfully deleted template ${t.name}`);
        }
        await handleAction('reload', null);
        break;
      }
      default:
        // Handle unknown action
        break;
    }
  }

  const title = `Exporting ${recordCount} ${props.entityName === 'projects' ? 'Project': 'People' } Records`;

  return (
    <Dialog fullWidth={true} maxWidth={'xl'} open={props.open}>
      {/* choose / browse mode */}
      {mode === 'index' && (
        <React.Fragment key={'List of Templates ' + String(count)}>
          <DialogTitle className="text-center bg-black text-white">{title}</DialogTitle>

          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                {owner &&
                  <TemplateList
                      owner={owner as string}
                      entityName={props.entityName}
                      onAction={handleAction} />
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewTemplate}>New Template</Button>
            <Button onClick={() => handleAction('close', null)}>Cancel</Button>
          </DialogActions>
        </React.Fragment>
      )}
      {/* new / edit mode */}
      {mode !== 'index' && (
        <React.Fragment key={'Editor ' + String(count)}>
          <DialogTitle className="text-center bg-black text-white">{title}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={4} className={classes.textFldCellLeft}>
                <TextField fullWidth className={classes.textField}
                  required
                  id="template-name"
                  label="Template Name"
                  value={templateName}
                  variant="outlined"
                  placeholder="Enter template name"
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </Grid>
              <Grid item xs={8} className={classes.textFldCellRight}>
                <TextField fullWidth className={classes.textField}
                  id="template-description"
                  label="Template Description"
                  value={templateDescription}
                  variant="outlined"
                  placeholder="Enter template description"
                  onChange={(e) => setTemplateDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <SchemaItemChooser entityName={props.entityName}
                  rootNode={exportSchemaRoot}
                  onNodePathSelected={handleNodePathSelected} />
              </Grid>
              <Grid item xs={6}>
                <EditTemplateSchema ref={editTemplateSchemaMethodsRef}
                                    entityName={props.entityName}
                                    schema={activeTemplate?.schema} />

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                <span className="text-white">{templateId}</span>
              </Grid>
              <Grid item container alignItems="center" xs={6} justifyContent="space-between">
                <div className={classes.lessPadding}>
                  <FormControlLabel
                      control={
                        <FormControl variant="outlined">
                          <Select
                              className={classes.select}
                              labelId="export-format-label"
                              id="export-format"
                              value={fileFormat}
                              onChange={handleFormatChange}
                          >
                            <MenuItem value={'xml'}>.xml (Adobe Indesign)</MenuItem>
                            <MenuItem value={'xlsx'}>.xlsx (Excel)</MenuItem>
                          </Select>
                        </FormControl>
                      }
                      label="Export as:"
                      labelPlacement="start"
                  />

                  <Button style={{marginLeft:'15px'}}
                      onClick={() => handleAction('exportAdhoc', null)}>Export</Button>
                  <DashDivider width={'5px'} height={'15px'} />
                </div>
                <div className={classes.lessPadding}>
                  <div>
                    <ProgressButton
                        style={{verticalAlign:'middle'}} onClick={() => {
                          setSaving(true);
                          handleAction('upsert', null);
                    }}
                        text={'Save'}
                        reset={saving}
                        onReset={() => setSaving(false)} />
                    <Button style={{verticalAlign:'middle'}} onClick={() => handleAction('close', null)}>
                      { saved ? 'Close': 'Cancel'}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>

          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
}
