import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from "react";
import AthenaClient, {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";

import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {formatInfotip, projectInfotips} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
import {commasFmt, currencyFmt} from "utils/numberToStringUtil";
// end imports

const SECTION_ID = 'changeOrderInfoSection';

const ChangeOrderInfoSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const [model, setModel] = useState<IApiProject>(props.model);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());

      if (model === null) {
        setModel(props.model);
      }

      const toggleEditMode = () => {
        props.setEditMode(!props.editMode);
      }

      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {

          function transform(data: {totalChangeOrderCount:string|number,totalChangeOrderValue:string|number}|unknown) {
            data.totalChangeOrderCount = parseInt(data.totalChangeOrderCount);
            data.totalChangeOrderValue = parseInt(data.totalChangeOrderValue);
            return data;
          }

          console.log('begin ' + SECTION_ID + ' save');
          const elem = document.querySelector('#' + SECTION_ID);
          const data = transform(getNamedData(elem));

          console.log('namedData was:', data);
          try {
            const client = (athenaClient as AthenaClient);
            const m = await client.patchProject(data);
            setModel(m as IApiProject);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));
      // end useImperativeHandle


      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (!props.model) {
        return <DashItemProgress/>;
      }

      return (
          <React.Fragment key={sectionKey}>
            {/* id */}
            <DashDetailsField mode="hidden"
                              label="Id"
                              showLabel={false}
                              editMode={props.editMode}
                              name="id"
                              defaultValue={model.id}
            />

            {/* totalChangeOrderCount */}
            <DashDetailsField mode="number"
                              label="Total Change Order Count"
                              showLabel={true}
                              width="50%"
                              editMode={props.editMode}
                              name="totalChangeOrderCount"
                              infotipKey={'_default'}
                              defaultValue={model.totalChangeOrderCount}
                              formatFn={commasFmt.format}
            />

            {/* totalChangeOrderValue */}
            <DashDetailsField mode="number"
                              label="Total Change Order Value"
                              showLabel={true}
                              width="50%"
                              editMode={props.editMode}
                              name="totalChangeOrderValue"
                              infotipKey={'_default'}
                              defaultValue={model.totalChangeOrderValue}
                              formatFn={currencyFmt.format}
            />

            <DashDivider width="80vw" height="5px"/>

          </React.Fragment>
      );
    });

export default withDashSection('Change Order Info', SECTION_ID, ChangeOrderInfoSection);
