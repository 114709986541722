import { makeAutoObservable } from "mobx";

class SearchStoreEx {
  lastRefreshDate = new Date();
  activeEntity = 'none';
  ticks: number = new Date().getTime();

  constructor() {
    makeAutoObservable(this);
  }

  refresh() {
    this.lastRefreshDate = new Date();
    this.ticks = new Date().getTime();
  }

}

const searchStoreEx = new SearchStoreEx();
export default searchStoreEx;