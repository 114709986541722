import _ from "lodash";

export function getNamedData(scopingElement: Element | null, removeIdFromData = false): object {

  if (scopingElement === null) {
    console.log('scoping element not found');
    return {};
  }

  const els = Array.from(
      scopingElement.querySelectorAll('input[name], textarea[name], select[name]')
  ) as Array<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

  const elsFiltered = els.filter(
      (elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
        return elem.name && elem.name.trim() !== '';
      }
  );

  console.log('getNamedData: elements mapped', elsFiltered.map(x => ({'name': x.name, 'value': x.value})))

  const data = elsFiltered.reduce((
          acc: { [key: string]: string | null | Record<string, unknown> | boolean },
          elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      ) => {
    console.log('!!! BEGIN get named data');

        if (elem.name !== '_json') {
          if (elem.dataset['format'] === 'json_hold_broke_something') {
            const value = JSON.parse(elem.value);
            console.log('!!! elem attrs', {'elem.name': elem.name, value, type: typeof value});
            acc[elem.name] = value;
          } else if (elem.dataset['format'] == 'js') {
            const value = JSON.parse(elem.value);
            console.log('!!! elem attrs js', {'elem.name': elem.name, value, type: typeof value});
            acc[elem.name] = JSON.parse(elem.value);
          } else {
            if (elem.name.endsWith('[s]')) {
              console.log('!!! named - ' + elem.name);
              try {
                const sel = `input[name="${elem.name}[v]"]`;
                const vel = scopingElement.querySelector(sel);
                const slicedFieldName = elem.name.slice(0, -3);

                if (vel && 'validate' in vel) {
                  const v = vel as unknown as { validate: (jsonStr: string) => boolean };
                  if (v.validate(elem.value) === true) {
                    let value = JSON.parse(elem.value);
                    if (Array.isArray(value)) {
                      value = value.map(item => item.toString());
                      acc[slicedFieldName] = value;

                      console.log('!!! sliced field name', {slicedFieldName, value});
                    }
                  } else {
                    acc['_'] = '';
                  }
                } else {
                  let value = JSON.parse(elem.value);
                  console.log('!!! sliced field name', {slicedFieldName, value});
                  if (Array.isArray(value)) {
                    value = value.map(item => item.toString());
                    acc[slicedFieldName] = value;
                  }
                }
              } catch (e) {
                console.error(`!!! Error parsing JSON value for element "${elem.name}": ${e}`);
              }
            } else if (elem.name.endsWith('[s][v]')) {
                _.noop(); // don't add validation info to form input packet
            } else if (elem.name.endsWith('[n]')) {
              try {
                let value = JSON.parse(elem.value);
                if (Array.isArray(value)) {
                  value = value.map(item => Number(item)).filter(item => !isNaN(item));
                  acc[elem.name.slice(0, -3)] = value;
                }
              } catch (e) {
                console.error(`Error parsing JSON value for element "${elem.name}": ${e}`);
              }
            } else {
              const originalValue = elem.dataset.originalValue || '';
              _.noop(originalValue);
              // boolean
              if (elem.dataset.valueType === 'boolean') {
                acc[elem.name] = elem.value === 'true';
                // Date
              } else {
                acc[elem.name] = elem.value === '' && elem.dataset.isDate === 'true'
                    ? null
                    : elem.value;
              }
            }
          }
        } else {
          try {
            const jsonValue = JSON.parse(elem.value);
            if (typeof jsonValue === 'object') {
              acc = { ...acc, ...jsonValue };
            }
          } catch (e) {
            console.error(`Error parsing JSON value for element "${elem.name}": ${e}`);
          }
        }
        return acc;
      },
      {}
  );

  if (removeIdFromData) {
    delete data['id'];
  }
  return data;
}

export function snapshotNamedData(scopingElement: Element | null): void {
  if (scopingElement === null) {
    console.log('scoping element not found');
    return;
  }

  const els = Array.from(
      scopingElement.querySelectorAll('input[name], textarea[name], select[name]')
  ) as Array<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

  const elsFiltered = els.filter(
      (elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
        return elem.name && elem.name.trim() !== '';
      }
  );

  elsFiltered.forEach((elem: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
    elem.dataset.originalValue = elem.value;
  });
}
