import {ApiPersonHistory, IApiPersonHistory, IApiPersonReference} from "services/apiClients/AthenaClient";
import React, {useRef} from "react";
import {useProjectStaffContext} from "./ProjectStaffContext";
import {
  Column,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridComponent,
  Inject,
  Resize,
  Scroll,
  Sort,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import {Excel, GreenCheck, RedX} from "../../../dashCore/customIcons";
import {Button, Card, CardContent, CardHeader, Grid, TextField, Tooltip, Typography} from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import DashIconButton from "../../../dashCore/DashIconButton";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import {mkColumn} from "../../personViewRelated/gridConfigSettings/gridUtils";
import AthenaAuthorize from "app/security/ui/AthenaAuthorize";
import {hasPermission} from "app/security/utils/securityUtil";
import {useGlobalState} from "app/state/useGlobalState";
import globalState from "../../../../app/state/GlobalState";
import {
  convertPersonHistory,
  convertStakeholders,
  IInfoCard,
  IInfoField
} from "../../../../services/infoCardConversion";
import PrimaryImage from "../../../../app/primaryImage/feature/PrimaryImage";
import {makeStyles} from "@material-ui/core/styles";
import {useObserver} from "mobx-react";
import clsx from "clsx";
import _ from "lodash";

// FUTURE: PersonId, UserName/principleUser, email, primaryImageUrl should be duplicated data
// or at least exposed via the same API call to get project staff / personHistory
// this goes for any "leaf" data, don't make the client do multiple lookups for
// additional unique identifiers #mjl

const SECTION_ID = "projectStaffAssocSection";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    flex: '1 0 100%',
    width: '100%'
  },
  cardHolder: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    rowGap: '5px',
    columnGap: '5px',
    height: '800px',
    overflowY: 'auto',
  },
  hardBreak: {
    '&:after': {
      content: '',
      display: 'block',
    },
  },
  smallCard: {
    textAlign: 'left',
    display: 'block',
    width: '300px',
    flexGrow: 0,
    flexShrink: 0,

    '& > div.MuiCardHeader-root': {
      paddingBottom: '5px'
    },
  },
  active: {
    backgroundColor: '#fff',
  },
  inactive: {
    backgroundColor: '#ddd',
  },
  cardHeader: {
    display: 'block',
    overflow: 'hidden'
  },
  cardHeaderRoot: {
    overflow: 'hidden'
  },
  cardHeaderContent: {
    overflow: 'hidden'
  }
}));

function ProjectStaffAssocGrid() {
  const classes = useStyles();
  const gs = useGlobalState();
  const ctx = useProjectStaffContext();

  const gridRef = useRef<GridComponent>(null);
  const titleOnProjectRef = useRef('');

  // grid settings
  const gridServices = [Toolbar, Sort, Edit, Filter, Scroll, Resize, CommandColumn, ExcelExport] as object[];
  const gridProps = {
    showToolbar: false,
    allowSorting: true,
    allowFiltering: false,
    allowResizing: true,
    allowExcelExport: true
  };

  const exportColumns = [
    mkColumn('name', 'Name'),
    mkColumn('personTitleOnProject', 'Title on Project'),
    mkColumn('currentTitle', 'Current Title'),
    mkColumn('district', 'Region'),
    {
      headerText: 'Active',
      field: 'active',
      valueAccessor: (_: string, data: Partial<IApiPersonHistory>): string => {
        return data.active ? 'Yes' : 'No';
      },
    } as Partial<Column>,
  ] as Column[];


  function excelExport() {
    const ps: ExcelExportProperties = {};
    ps.columns = exportColumns;
    gridRef.current.excelExport(ps);
  }

  function handlePreEdit(record: Record<string, unknown>) {
    const grid = gridRef.current as GridComponent;
    titleOnProjectRef.current = record.personTitleOnProject as string;
    //console.log('select row issue', {selectedRowIndex: gc.selectedRowIndex, recordIndex: record.index});
    if (grid.selectedRowIndex.toString() !== record.index) {
      setTimeout(() => {
        grid.selectRow(parseInt(record.index as string), false);
        grid.startEdit();
      }, 180);
      setTimeout(() => {
        const elem = document.querySelector('.e-inline-edit input.MuiInput-input') as HTMLElement;
        elem.style.backgroundColor = 'rgba(100, 149, 237, 0.2)';
        elem.focus();
      }, 300);
    }
  }

  const handleDataBound = () => {
    gridRef.current?.selectRow(-1, true);
  };

  function renderActionButtons(record: Record<string, unknown>) {
    return (<div key={Math.random().toString()}>
      <DashIconButton size="small" title="Edit"
                      onClick={() => handlePreEdit(record)}>
        <EditIcon/>
      </DashIconButton>
      <DashIconButton size="small" title="Unlink"
                      onClick={() => ctx.handleAction('delete', record)}>
        <RemoveCircleOutline/>
      </DashIconButton>
    </div>);
  }

  function renderSaveCancel(record: Record<string, unknown>) {
    return (
        <><Button onClick={() => ctx.handleAction('update',
            {...record, personTitleOnProject: titleOnProjectRef.current})}
                  variant="outlined">Save</Button>&nbsp;
          <Button onClick={() => gridRef?.current?.endEdit()} variant="outlined">Cancel</Button></>
    )
  }

  //console.log('current ctx from ProjectStaffAssocGrid', ctx);
  const infoCards = convertPersonHistory(ctx.project?.personHistory ?? []);
  const imgStyle = {width: '100px', height: '100px', margin: '10px'};

  function handleInfoCardClick(infoCard: IInfoCard) {
    const personUrl = `${window.location.protocol}//${window.location.host}/people/${infoCard.personId}`;
    window.open(personUrl, '_blank');
  }

  return useObserver(() =>
      <Grid id={SECTION_ID} container spacing={2} style={{width: '100%'}}>
        <Grid container item xs={12} alignItems={'flex-start'} justifyContent="space-between"
              style={{margin: '0 15px'}}>
          <Grid item>
            <AthenaAuthorize allowedRoles={'Contributor'}>
              <Button startIcon={<AddCircleOutline/>}
                      onClick={() => {
                        ctx.setDialogMode('pick');
                      }}>Add Project Staff</Button>
            </AthenaAuthorize>
          </Grid>
          <Grid item>
            <Button startIcon={<Excel/>}
                    onClick={() => excelExport()}>Excel Export</Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <React.Fragment key={gs.projectStaffTicks}>
            {globalState.projectStaffListViewOn ? <>
          <GridComponent dataSource={ctx.project?.personHistory ?? []}
                         height={'550px'}
                         gridLines={'Both'} ref={gridRef}
                         statelessTemplates={['directiveTemplates']}
                         {...gridProps}
                         dataBound={handleDataBound}
                         selectionSettings={{type: 'Single', mode: 'Row'}}
                         editSettings={{allowEditing: true, mode: 'Normal', allowEditOnDblClick: false}}
          >
            <ColumnsDirective>
              <ColumnDirective headerText="Name" field="name" width={'16%'} allowEditing={false}
                               template={(record: ApiPersonHistory) => {
                                 return (
                                     <a href={`${window.location.protocol}//${window.location.host}/people/${record.personId}`}
                                        target="_blank">{record.name}</a>);
                               }}
              />

              <ColumnDirective headerText="Title on Project" field="personTitleOnProject" width={'20%'}
                               editTemplate={(data: ApiPersonHistory) => (
                                   <TextField InputProps={{disableUnderline: true, style: {fontSize: '13px'}}}
                                              onChange={(e) => titleOnProjectRef.current = e.target.value}
                                              defaultValue={data.personTitleOnProject}/>
                               )}
              />
              <ColumnDirective headerText="Current Title" field="currentTitle" width={'18%'} allowEditing={false}/>
              <ColumnDirective headerText="Region" field="district" allowEditing={false} width={'15%'}/>
              <ColumnDirective headerText="Status" allowEditing={false} width={'10%'}
                               template={(record: ApiPersonHistory) => record.active ? <GreenCheck/> : <RedX/>}
                               editTemplate={(record: ApiPersonHistory) => record.active ? <GreenCheck/> : <RedX/>}
              />

              <ColumnDirective headerText="Actions" width={'20%'} allowEditing={false}
                               visible={hasPermission(['Contributor'], gs.userRoles)}
                               template={(record: Record<string, unknown>) => renderActionButtons(record)}
                               editTemplate={(record: Record<string, unknown>) => renderSaveCancel(record)}
              />
            </ColumnsDirective>
            <Inject services={gridServices}/>
          </GridComponent>
            </>
                : <div className={classes.cardHolder}>
                     {infoCards.map((infoCard: IInfoCard) =>
                         <section key={infoCard.personId} onClick={() => handleInfoCardClick(infoCard)}>
                           <Card className={clsx(classes.smallCard, infoCard.active ? {}: classes.inactive)}
                                 raised={true}
                                 style={{height: '400px'}}>
                             <CardHeader className="info-card-header"
                                         title={<Tooltip title={infoCard.title ?? ''} aria-label={infoCard.title ?? ''}>
                                           <Typography noWrap gutterBottom variant="h6" component="h6">
                                             {infoCard.title}
                                           </Typography>
                                         </Tooltip>} subheader={infoCard.subheader}
                                         classes={{
                                           root: classes.cardHeaderRoot,
                                           content: classes.cardHeaderContent
                                         }}
                             />
                             <CardContent>
                               <Typography>{infoCard.district}</Typography>
                              <PrimaryImage primaryImageSrc={infoCard.imageUrl}
                                   userName={''}
                                   entityId={infoCard.personId}
                                   entityName={'people'}
                                   style={imgStyle}/>
                               {!infoCard.active &&
                                 <div className={'w-full text-right'}>
                                   <div>INACTIVE</div>
                                 </div>
                               }
                             </CardContent>
                           </Card>
                         </section>
                     )}
                   </div>}

          </React.Fragment>
        </Grid>
      </Grid>
  );
}

export default ProjectStaffAssocGrid;
