import clsx from 'clsx';
import useAthenaClient from 'hooks/useAthenaClient';
import React, { useEffect } from 'react';
import noop from 'utils/noop';

import { makeStyles } from '@material-ui/core/styles';

import { DashItemProgress } from '../../components/dashCore/DashItemProgress';
import dateFormat from '../../patterns/dateFormat';
import { Template } from '../../services/apiClients/AthenaClient';
import {CircularProgress, IconButton, Tooltip} from "@material-ui/core";
import {AssignmentReturned, Delete, GetApp, RemoveCircle} from "@material-ui/icons";
import CoreLocation from "../core/CoreLocation";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  headerCell: {
    fontWeight: 'normal',
    textAlign: 'left',
    borderBottom: '1px solid #999',
  },
  nameColumn: {
    width: '25%',
  },
  descriptionColumn: {
    width: '30%',
  },
  createdOnColumn: {
    width: '15%',
  },
  lastModifiedColumn: {
    width: '15%',
  },
  actionsColumn: {
    width: '15%',
  }
});

interface ITemplateListProps {
  owner: string;
  entityName: string;
  onAction: (action: string, payload: null | unknown) => void;
}

const TemplateList: React.FC<ITemplateListProps> = (props: ITemplateListProps) => {
  const classes = useStyles();
  const { athenaClient } = useAthenaClient();
  const [templates, setTemplates] = React.useState<Template[] | null>(null);

  noop(props);

  useEffect(() => {
    async function getData() {
      if (athenaClient && props.owner) {
        const tmpResult = await athenaClient.getTemplates(props.owner, props.entityName, 'exports');
        if (tmpResult) setTemplates(tmpResult);
      }
    }

    getData();
  }, [athenaClient, props.owner]);

  function handleAction(action: string, payload: string | null | undefined | unknown) {
    props.onAction(action, payload);
  }

  if (!templates) {
    return <div><CircularProgress /></div>
  }
  // Component render
  return (
    <div className={classes.root}>
      { templates && templates.length > 0 &&
      <table className={classes.table}>
        <thead style={{ borderBottom: '1px solid #999' }}>
          <tr>
            <th className={clsx(classes.headerCell, classes.nameColumn)}>Template Name</th>
            <th className={clsx(classes.headerCell, classes.descriptionColumn)}>Description</th>
            <th className={clsx(classes.headerCell, classes.createdOnColumn)}>Created On</th>
            <th className={clsx(classes.headerCell, classes.lastModifiedColumn)}>Last Modified</th>
            <th className={clsx(classes.headerCell, classes.actionsColumn)}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {templates && templates.map((template, index) => (
            template &&
            <tr key={index}>
              <td className="text-left"><span
                className="cursor-pointer underline text-blue-500"
                onClick={() => handleAction('edit', template.id)}>{template.name}</span></td>
              <td className="text-left">{template.description}</td>
              <td className="text-left">{dateFormat(template.createdDate)}</td>
              <td className="text-left">{dateFormat(template.modifiedDate)}</td>
              <td className="text-center">
                  <Tooltip title={'Delete template'}>
                    <IconButton onClick={() => handleAction('delete', template)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Export current data with selected template settings'}>
                      <IconButton onClick={() => handleAction('exportFromShoppingBag', template)}>
                          <AssignmentReturned />
                      </IconButton>
                  </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      }
      { templates && templates.length === 0 &&
        <div>
            It looks like you don't have any export {props.entityName === 'projects' ? 'Project': 'People'} templates saved. <span className="cursor-pointer blue-500 underline"
        onClick={() => handleAction('new',null)}>Create a new template</span> to get started.
        </div>
      }
    </div>
  );
};

export default TemplateList;
