/* eslint-disable no-debugger */
import React, {useEffect, useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import {Grid, InputAdornment} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {SideNavChildrenProps} from 'types/searchTypes';
import ClearFacetButton from 'components/search/facets/ClearFacetButton';
import withRangeFacet, {RangeProps} from "../withRangeFacet";
import {makeStyles} from "@material-ui/core/styles";
import {useGuid} from "features/core/Guid";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import CoreDebug from "features/core/CoreDebug";
import LuxonUtils from '@date-io/luxon';
import CoreDate from "features/core/CoreDate";
import {DateTime} from "luxon";
import debounce from "lodash.debounce";
import _ from "lodash";
import {useObserver} from "mobx-react";
import {useGlobalState} from "app/state/useGlobalState";
import experienceFacetState from "../../../../app/state/ExperienceFacetState";

const useStyles = makeStyles(() => ({
  itemLabel: {
    fontSize: '14px',
    color: '#000',
  },
  itemCount: {
    fontSize: '14px',
    color: '#999',
  },
  // start accordion style
  accordion: {
    width: '275px',
    //borderTop: '1px solid #bbbbb',
    '&.Mui-expanded': {
      margin: 0,
      marginTop: '1px',
    },
  },
  accordionSummary: {
    borderBottom: '1px solid #eee',
    // '&.Mui-expanded': {
    //   borderBottom: '1px solid ' + theme.palette.primary.light,
    // },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    fontSize: '14px',
    '& > div': {
      width: '100%',
    }
  },
  somewhatHidden: {
    color: 'white',
  }
}));

// todo: cleanup entire component and HoC above it
const useDebouncedDateTime = (initialValue: DateTime | null, delay: number) => {
  const [dateTime, setDateTime] = useState(initialValue);
  const debouncedDateTime = debounce((newDateTime: DateTime | null) => setDateTime(newDateTime), delay);

  useEffect(() => {
    debouncedDateTime.cancel();
    return () => {
      debouncedDateTime.cancel();
    };
  }, []);

  return [dateTime, debouncedDateTime] as const;
};

function DateRangeFacet(props: SideNavChildrenProps & RangeProps) {
  const classes = useStyles();
  const gs = useGlobalState();
  // const bondedInit = props.field === 'hireDate' &&
  //                     !experienceFacetState.pristine &&
  //                     experienceFacetState.expMinYears !== 1;
  // const [bonded, setBonded] = useState(bondedInit);
  const [expanded, setExpanded] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [selectedMin, setSelectedMin] = useState<DateTime | null>(null);
  const [selectedMax, setSelectedMax] = useState<DateTime | null>(null);

  // Initialization code here, will only run once after the first render
  useEffect(() => {
    setSelectedMin(CoreDate.convertedToDateTimeOrNull(props.min));
    setSelectedMax(CoreDate.convertedToDateTimeOrNull(props.max));
  }, [props.min, props.max]);

  const [debouncedMin, setDebouncedMin] = useDebouncedDateTime(selectedMin, 800);
  const [debouncedMax, setDebouncedMax] = useDebouncedDateTime(selectedMax, 800);

  useEffect(() => {
    //console.log('useEffect debouncedMin changed' + props.field + ' selectedMin = ' + selectedMin)
    if (CoreDate.isValidLuxonDateTimeOrNull(debouncedMin)) {
      //console.log('Was valid min date', {date: debouncedMin})
      props.setMin(debouncedMin ? CoreDate.toShortISOString(debouncedMin.toJSDate()) : null);
      props.setIsModified(true);
    } else {
      props.setMin('');
      props.setIsModified(true);
    }
  }, [debouncedMin]);

  useEffect(() => {
    function normalFlow() {
      if (CoreDate.isValidLuxonDateTimeOrNull(debouncedMax)) {
        props.setMax(debouncedMax ? CoreDate.toShortISOString(debouncedMax.toJSDate()) : null);
        props.setIsModified(true);
      } else {
        props.setMax('');
        props.setIsModified(true);
      }
    }

    normalFlow();
  }, [debouncedMax]);

  const handleResetAndClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(false);
    // expandedFacetId_FIX
    gs.expandedFacetId = undefined;
    props.reset();
    setDebouncedMin(null);
    setDebouncedMax(null);
    setIsModified(false);
  }

  function handleAccordionChange(_event: unknown) {
    // if it is already my id then it shouldn't be me and I should close
    const nextId = (gs.expandedFacetId === props.id) ? 'none' : props.id;
    gs.setExpandedFacetId(nextId);
  }

  // todo: extract common
  const allEqual = (...args: unknown[]) => {
    console.log('eee allEqual', args);
    return args.every(val => val === args[0]);
  };

  return useObserver(() =>
      <>
        <Accordion className={classes.accordion}
                   TransitionProps={{ timeout: 50 }}
                   onChange={handleAccordionChange}
                   expanded={gs.expandedFacetId === props.id}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}>
          <ClearFacetButton visible={isModified || !allEqual(null, selectedMin, selectedMax)}
                            onClick={handleResetAndClose} />
          <Typography>{props.label}</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>

                <div style={{marginBottom: '20px'}}>
                <KeyboardDatePicker
                    autoOk
                    autoComplete={'off'}
                    id={'min'}
                    variant="inline"
                    inputVariant="outlined"
                    // label={'Earliest (' + CoreDate.toShortDate(props.defaultMin) + ')'}
                    label={'Earliest'}
                    format="MM/dd/yyyy"
                    value={selectedMin}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => {
                      setIsModified(true);
                      setDebouncedMin(date)
                    }}
                />
                </div>

                <div>
                <KeyboardDatePicker
                    autoOk
                    autoComplete={'off'}
                    id={'max'}
                    variant="inline"
                    inputVariant="outlined"
                    //label={'Latest (' + CoreDate.toShortDate(props.defaultMax) + ')'}
                    label={'Latest'}
                    format="MM/dd/yyyy"
                    value={selectedMax}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => setDebouncedMax(date)}
                />
                </div>

                <div className={classes.somewhatHidden}>{
                  CoreDate.toShortDate(props.defaultMin)
                } : {
                  CoreDate.toShortDate(props.defaultMax)
                }</div>

            </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

        </AccordionDetails>
      </Accordion>
        <div className="accordionDetailEnd"></div>
      </>
  );
}

export default withRangeFacet(DateRangeFacet);