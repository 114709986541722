import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import DashDetailsField from '../../components/DashDetailsField'
import useReferenceDataForProjects from 'hooks/useFieldOptions/useReferenceDataForProjects'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import noop from '../../utils/noop'
import useAthenaClient from '../../hooks/useAthenaClient'
import InfoIcon from '@material-ui/icons/Info'
import { IStartProject } from './newProjectUtil'
import { IApiProject } from '../../services/apiClients/AthenaClient'
import useToast from 'hooks/useToast'
import ProgressButton from '../../components/dashCore/ProgressButton'
import { hpParseInt } from '../../app/hpNumbers'
import Tracker from '../../app/Tracker/Tracker'
import _ from 'lodash'

const useStyles = makeStyles(() => ({
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formControl: {
    backgroundColor: 'white',
  },
}))

export default function NewProjectPage() {
  const classes = useStyles()
  const { athenaClient } = useAthenaClient()
  const referenceData = useReferenceDataForProjects()
  const navigate = useNavigate()
  const elementId = 'newProjectFormScope'
  const toast = useToast()

  const [hasAssignedProjectNumber, setHasAssignedProjectNumber] = useState(
    false,
  )
  const [projectName, setProjectName] = useState('')
  const [clientName, setClientName] = useState('')
  const [sector, setSector] = useState('')
  const [city, setCity] = useState('')
  const [usState, setUSState] = useState('')
  const [districts, setDistricts] = useState([] as string[])
  const [projectNumber, setProjectNumber] = useState('XXXXXXX')
  const [projectNumberAssigned, setProjectNumberAssigned] = useState('')
  const [verticalMarket, setVerticalMarket] = useState('')
  const [procurementMethod, setProcurementMethod] = useState('')
  const [deliveryMethod, setDeliveryMethod] = useState('')
  const [contractType, setContractType] = useState('')
  const [contractValue, setContractValue] = useState('')
  const [formTouched, setFormTouched] = useState<boolean>(false)
  const [reset, setReset] = useState(false)

  function clean(names: string[]) {
    if (names === undefined) return []
    names = names.filter((x) => typeof x === 'string').map((x) => x.trim())
    return names.filter((x) => !['', 'NULL'].includes(x))
  }

  useEffect(() => {
    noop()
  }, [athenaClient, referenceData])

  function handleCancel() {
    navigate({
      pathname: '/projects',
      search: '?nonHenselPhelpsProject=oneof."false"',
    })
  }

  function isValidForm(newProject: IStartProject): boolean {
    // Check if any of the properties are falsy
    for (const [key, value] of Object.entries(newProject)) {
      if (value == null || value === '') {
        console.log('$$$k what key', { key, value })
        return false
      }
    }

    // Special check for districts
    if (!newProject.districts || newProject.districts.length === 0) {
      return false
    }

    return true
  }

  function handleNewProject() {
    setFormTouched(true)
    const tmpProjectNumber = hasAssignedProjectNumber
      ? projectNumberAssigned
      : projectNumber
    const newProject = {
      city: city,
      clientName: clientName,
      clientType: sector,
      contractType: contractType,
      cost: hpParseInt(contractValue),
      finalConstructionContractValue: hpParseInt(contractValue),
      country: 'USA',
      deliveryMethod: deliveryMethod,
      districts: districts,
      name: projectName,
      nonHenselPhelpsProject: false,
      number: tmpProjectNumber,
      private: true,
      procurementMethod: procurementMethod,
      projectNumbers: [tmpProjectNumber],
      state: usState,
      verticalMarket: verticalMarket,
      autoUpdateContractValue: true,
    } as IStartProject

    if (!isValidForm(newProject)) {
      setReset(true)
      alert('Please check the form for validation errors.')
      return
    }

    if (tmpProjectNumber.length !== 7) {
      alert('The project number must be 7 digits')
      setReset(true)
      return
    }
    athenaClient
      ?.createProject((newProject as unknown) as IApiProject)
      .then((resultProject) => {
        toast.displayToast('Getting new project ready.', 'default')
        console.log({ result: resultProject })
        setTimeout(() => {
          navigate(`/projects/${resultProject.id}`)
        }, 3000)
      })
      .catch((error) => {
        console.error('Error creating project:', error)
      })
  }

  function generateProjectNumberMask(str: string | undefined): string {
    if (!str) return 'XX' + 'XXXXX'
    const regex = /(\d{2})/
    const match = str.match(regex)
    if (!match) return ''

    const firstTwoDigits = match[1]
    return firstTwoDigits + 'XXXXX'
  }

  useEffect(() => {
    let nextProjectNumber = ''
    const inputField = document.querySelector(
      'input[name="projectNumber"]',
    ) as HTMLInputElement
    if (!hasAssignedProjectNumber && inputField) {
      nextProjectNumber = generateProjectNumberMask(districts[0])
      inputField.value = nextProjectNumber
    }
    // update the state of the component with the new value
    setProjectNumber(nextProjectNumber)
  }, [districts, hasAssignedProjectNumber])

  const handleReset = () => setReset(false)

  function makeContractValue(value: string) {
    setContractValue(hpParseInt('' + value) + '')
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
      }}
    >
      <div style={{ width: '900px', textAlign: 'left' }}>
        <Card>
          <CardHeader title="New Project" />
          <CardContent>
            <Tracker featureName={'new-project-page'} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setProjectName(e.target.value as string)}
                  fullWidth
                  label="Project Name"
                  name="projectName"
                  required
                  error={formTouched && !projectName}
                  helperText={
                    formTouched && !projectName && 'Project Name is required'
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setClientName(e.target.value as string)}
                  fullWidth
                  label="Client Name"
                  name="clientName"
                  required
                  error={formTouched && !clientName}
                  helperText={
                    formTouched && !clientName && 'Client Name is required'
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="sector">Sector</InputLabel>
                  <Select
                    value={sector}
                    onChange={(e) => setSector(e.target.value as string)}
                    error={formTouched && !sector}
                    inputProps={{
                      name: 'sector',
                      id: 'sector',
                    }}
                  >
                    {(!referenceData.options['sectors']
                      ? []
                      : referenceData.options['sectors']
                    ).map((x: string) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {formTouched && !sector && 'Sector is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={7}>
                <TextField
                  fullWidth
                  onChange={(e) => setCity(e.target.value)}
                  label="City"
                  name="city"
                  required
                  error={formTouched && !city}
                  helperText={formTouched && !city && 'City is required'}
                />
              </Grid>
              <Grid item xs={5}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  required
                  style={{ width: '100%' }}
                >
                  <InputLabel htmlFor="state">State</InputLabel>
                  <Select
                    onChange={(e) => setUSState(e.target.value as string)}
                    label="State"
                    error={formTouched && !usState}
                    inputProps={{
                      name: 'state',
                      id: 'state',
                    }}
                  >
                    {referenceData.options['states']?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  {formTouched && !usState && (
                    <FormHelperText error>{'State is required'}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <DashDetailsField
                  simpleTooltip={
                    'The first region will be designated as the primary region for this project.'
                  }
                  mode={'multiAuto'}
                  label={'Region'}
                  showLabel={true}
                  editMode={true}
                  muiTooltip={'test'}
                  onValuesChange={setDistricts}
                  choices={referenceData.options['regionWithCodes']}
                />
                <div className="flex flex-row">
                  <div className="flex" style={{ width: '215px' }}>
                    &nbsp;
                  </div>
                  <FormHelperText error={formTouched && districts.length === 0}>
                    This is required. Please enter at least one region
                  </FormHelperText>
                </div>
              </Grid>
              {/*  <Grid item xs={12}>*/}
              {/*  <FormControl variant="outlined" className={classes.formControl}*/}
              {/*               required style={{width:'100%'}}>*/}
              {/*    <InputLabel htmlFor="district">Region</InputLabel>*/}
              {/*    <Select onChange={(e) => setDistrict(e.target.value as string)}*/}
              {/*            label="Region"*/}
              {/*            inputProps={{*/}
              {/*              name: 'district',*/}
              {/*              id: 'district',*/}
              {/*            }}*/}
              {/*    >*/}
              {/*      {referenceData.options['districts']?.map((x) => (*/}
              {/*          <MenuItem key={x} value={x}>{x}</MenuItem>*/}
              {/*      ))}*/}
              {/*    </Select>*/}
              {/*    <FormHelperText>Required</FormHelperText>*/}
              {/*  </FormControl>*/}
              {/*</Grid>*/}

              {hasAssignedProjectNumber ? (
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    onChange={(e) =>
                      setProjectNumberAssigned(e.target.value as string)
                    }
                    label="Project Number"
                    name="projectNumberAssigned"
                    defaultValue={''}
                    required
                    inputProps={{
                      maxLength: 7,
                    }}
                    error={
                      (formTouched && projectNumberAssigned == null) ||
                      projectNumberAssigned.length !== 7
                    }
                    helperText={
                      (formTouched &&
                        !projectNumberAssigned &&
                        'Project Number is required') ||
                      (formTouched &&
                        projectNumberAssigned.length !== 7 &&
                        'Project Number must be 7 digits.')
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={5}>
                  <TextField
                    style={{ width: '80%' }}
                    fullWidth
                    onChange={(e) => setProjectNumber(e.target.value as string)}
                    label="Project Number"
                    name="projectNumber"
                    defaultValue={'XXXXXXX'}
                    disabled={true}
                    required
                    error={formTouched && !projectNumber}
                    helperText={
                      formTouched &&
                      !projectNumber &&
                      'Project Number is required'
                    }
                  />
                  <Tooltip
                    title={
                      'Additional project numbers can be added on the project details page after creation.'
                    }
                    aria-label={
                      'Additional project numbers can be added on the project details page after creation.'
                    }
                  >
                    <InfoIcon />
                  </Tooltip>
                </Grid>
              )}

              <Grid item xs={7}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setHasAssignedProjectNumber(e.target.checked)
                        }
                        checked={hasAssignedProjectNumber}
                        name="accountingAssignedProjectNumber"
                      />
                    }
                    label={'Accounting has assigned a project number'}
                  />
                </FormControl>
                <Tooltip
                  title={
                    'Do not change unless you know the assigned number. A default number will be set for WebDam purposes.'
                  }
                  aria-label={
                    'Do not change unless you know the assigned number. A default number will be set for WebDam purposes.'
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </Grid>

              <Grid item xs={5}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  required
                  style={{ width: '100%' }}
                >
                  <InputLabel htmlFor="verticalMarket">
                    Vertical Market
                  </InputLabel>
                  <Select
                    onChange={(e) =>
                      setVerticalMarket(e.target.value as string)
                    }
                    label="Vertical Market"
                    inputProps={{
                      name: 'verticalMarket',
                      id: 'verticalMarket',
                    }}
                    error={formTouched && !verticalMarket}
                  >
                    {referenceData.options['verticalMarkets']?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {formTouched &&
                      !verticalMarket &&
                      'Vertical Market is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={7}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  required
                  style={{ width: '100%' }}
                >
                  <InputLabel htmlFor="procurementMethod">
                    Procurement Method
                  </InputLabel>
                  <Select
                    onChange={(e) =>
                      setProcurementMethod(e.target.value as string)
                    }
                    label="Procurement Method"
                    inputProps={{
                      name: 'procurementMethod',
                      id: 'procurementMethod',
                    }}
                    error={formTouched && !procurementMethod}
                  >
                    {referenceData.options['procurementMethods']?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {formTouched &&
                      !procurementMethod &&
                      'Procurement Method is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  required
                  style={{ width: '100%' }}
                >
                  <InputLabel htmlFor="deliveryMethod">
                    Delivery Method
                  </InputLabel>
                  <Select
                    onChange={(e) =>
                      setDeliveryMethod(e.target.value as string)
                    }
                    label="Delivery Method"
                    inputProps={{
                      name: 'deliveryMethod',
                      id: 'deliveryMethod',
                    }}
                    error={formTouched && !deliveryMethod}
                  >
                    {referenceData.options['deliveryMethods']?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {formTouched &&
                      !deliveryMethod &&
                      'Delivery Method is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  required
                  style={{ width: '100%' }}
                >
                  <InputLabel htmlFor="contractType">Contract Type</InputLabel>
                  <Select
                    onChange={(e) => setContractType(e.target.value as string)}
                    label="Contract Type"
                    inputProps={{
                      name: 'contractType',
                      id: 'contractType',
                    }}
                    error={formTouched && !contractType}
                  >
                    {referenceData.options['contractTypes']?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {formTouched &&
                      !contractType &&
                      'Contract Type is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={(e) => makeContractValue(e.target.value)}
                  fullWidth
                  label="Contract Value"
                  name="contractValue"
                  required
                  type={'number'}
                  error={formTouched && !contractValue}
                  helperText={
                    formTouched &&
                    !contractValue &&
                    'Contract value is required'
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.cardActions}>
            {/*  <Button type="submit"*/}
            {/*     onClick={handleNewProject}*/}
            {/*     form="newProjectFormScope" variant="contained" >*/}
            {/*    Create*/}
            {/*</Button>*/}
            <ProgressButton
              reset={reset}
              onReset={handleReset}
              onClick={handleNewProject}
              text={'Create'}
            />
            <Button onClick={handleCancel}>Cancel</Button>
          </CardActions>
        </Card>
      </div>
    </div>
  )
}
