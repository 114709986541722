
// convert a content type to a file type - return from case statement
export function convertMimeTypeToFileExt(contentType: string | undefined | null): string {
    if (contentType === undefined || contentType === null) {
        return '';
    } else {
        const contentTypeStr = contentType as string;
        switch (contentTypeStr) {
            // jpeg
            case "image/jpeg":
                return "jpg";
            // png
            case "image/png":
                return "png";
            // gif
            case "image/gif":
                return "gif";
            // bmp
            case "image/bmp":
                return "bmp";
            // tiff
            case "image/tiff":
                return "tiff";
            // pdf
            case "application/pdf":
                return "pdf";
            // doc
            case "application/msword":
                return "doc";
            // docx
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return "docx";
            // xls
            case "application/vnd.ms-excel":
                return "xls";
            // xlsx
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "xlsx";
            // ppt
            case "application/vnd.ms-powerpoint":
                return "ppt";
            // pptx
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                return "pptx";
            // dwg
            case "application/acad":
                return "dwg";
            // txt
            case "text/plain":
                return "txt";
            // csv
            case "text/csv":
                return "csv";
            // html
            case "text/html":
                return "html";
            // xml
            case "text/xml":
                return "xml";
            // json
            case "application/json":
                return "json";
            // zip
            case "application/zip":
                return "zip";
            default:
                return "@" + contentTypeStr;
        }
    }

}