export default class DashSort {
  private _direction: string;

  constructor(public field: string, direction: string) {
    this._direction = this.normalizeDirection(direction);
  }

  get direction(): string {
    return this._direction;
  }

  set direction(newDirection: string) {
    this._direction = this.normalizeDirection(newDirection);
  }

  private normalizeDirection(direction: string): string {
    const lowerCaseDirection = direction.toLowerCase();
    if (lowerCaseDirection === 'desc' || lowerCaseDirection === 'descending') {
      return 'desc';
    }
    return 'asc';
  }

  isAscending(): boolean {
    return this.direction === 'asc';
  }

  toString(): string {
    return `${this.field} ${this.direction}`;
  }

  static fromSingleString(value: string, defaultField = 'name'): DashSort {
    const parts = value.split(' ');
    if (parts.length > 2) {
      console.error('Invalid input string: too many parts');
    }

    let field = defaultField;
    let direction = 'asc';

    if (parts.length > 0) {
      const potentialField = parts[0];
      if (potentialField.length > 0 && potentialField.indexOf(',') === -1) {
        field = potentialField;
      } else {
        console.error('Invalid input string: comma detected in field name');
      }
    }

    if (parts.length === 2) {
      const potentialDirection = parts[1];
      if (potentialDirection.length > 0 && potentialDirection.indexOf(',') === -1) {
        direction = potentialDirection;
      } else {
        console.error('Invalid input string: comma detected in direction');
      }
    }

    return new DashSort(field, direction);
  }
}
