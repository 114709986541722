import React from 'react';
import { IconButton, Tooltip} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  button: {
    //margin: theme.spacing,
  },
}));

interface IFileDownloadProps {
  downloadUrl?: string,
  desiredFilename?: string,
}

export default function LoadingButton(props: IFileDownloadProps) {
  if (!props.downloadUrl) return null;

  const classes = useStyles();

  function buttonClick() {
    const anchor = document.createElement('a');
    anchor.href = props.downloadUrl as string;
    anchor.target = '_blank';
    anchor.download = props.desiredFilename ?? '';
    anchor.click();
  }

  return (
      <Tooltip title={'Download'}>
        <IconButton onClick={buttonClick}
                    component="span">
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    );
}
