import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {IPersonAward, IProjectAward} from 'services/apiClients/AthenaClient';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import {BetterProjectAward} from "../../projectViewRelated/AddProjectReference/projectAwardConvertors";

interface ProjectAwardDialogProps {
  open: boolean;
  dialogMode: string | null;
  //model?: IProjectAward;
  model?: BetterProjectAward;
  onClose: () => void;
  onSubmit: (data: BetterProjectAward) => void;
  ticks: number;
}

const ProjectAwardDialog: React.FC<ProjectAwardDialogProps> = ({ open, dialogMode, model, onClose, onSubmit, ticks }) => {
  const { handleSubmit, control, setValue } = useForm<BetterProjectAward>({
    defaultValues: model,
  });

  console.log('award dialog', model);

  React.useEffect(() => {
    if (model && dialogMode === 'edit') {
      setValue("id", model.id ?? '');
      setValue("title", model.title);
      setValue("level", model.level);
      setValue("category", model.category);
      setValue("year", model.year);
      setValue("sponsor", model.sponsor);
    } else {
      setValue("id", '');
      setValue("title", '');
      setValue("level", '');
      setValue('category', '');
      setValue("year", undefined);
      setValue("sponsor", '');
      // setValue("title", 'WebAward');
      // setValue("level", 'Marketing 1B'); // citation
      // setValue('category', '22');
      // setValue('year', 2011);
      // setValue("sponsor", 'Westinghouse');
    }
  }, [model, setValue, dialogMode]);

  const handleFormSubmit = (data: unknown) => {
    onSubmit(data as BetterProjectAward);
  };

  return (<Dialog open={open} onClose={onClose}>
    {(dialogMode === 'new' || dialogMode === 'edit') &&
      <React.Fragment key={'innerDialog' + ticks}>
        <DialogTitle className={'bg-black text-white'}>{dialogMode === "new" ? "New Project Award" : "Edit Project Award"}</DialogTitle>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent>
            <Controller
              name="id"
              control={control}
              render={({ field }) => <input type="hidden" {...field} />}
            />
            <Controller
              name="title"
              control={control}
              rules={{
                validate: (value) => ((value ?? '').length > 0) || "The Title cannot be left blank",
              }}
              render={({ field, fieldState }) => <TextField {...field} label="Title" fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null} />}
            />
            <Controller
              name="level"
              control={control}
              render={({ field }) => <TextField {...field} label="Level" fullWidth />}
            />
            <Controller
              name="category"
              control={control}
              render={({ field }) => <TextField {...field} label="Category" fullWidth />}
            />
            <Controller
              name="year"
              control={control}
              rules={{
                validate: (value) => {
                  const trimmedValue = (value ?? '');
                  if ((trimmedValue + '').trim() === '') {
                    return true;  // it's fine if the value is blank or whitespace
                  }
                  const year = parseInt(value + '', 10);
                  return (!isNaN(year) && (year >= 1900 && year <= 2100)) || "Invalid Year for a Project Award";
                }
              }}
              render={({ field, fieldState }) => <TextField {...field} label="Year" fullWidth
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null} />}
            />

            <Controller
                name="sponsor"
                control={control}
                render={({ field }) =>
                    <TextField {...field} label="Sponsor" fullWidth />}
            />

          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">Save</Button>
            <Button onClick={onClose} color="primary">Cancel</Button>
          </DialogActions>
        </form>
      </React.Fragment>
    }
  </Dialog>
  );

}

export default ProjectAwardDialog;