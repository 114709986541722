import React, { useEffect, useRef, useState } from 'react'
import useReferenceDataForProjects from 'hooks/useFieldOptions/useReferenceDataForProjects'
import { IDropDownOptions } from 'hooks/useFieldOptions/localTypesAndUtil'
import AthenaClient, {
  ApiProject,
  IApiProjectHistory,
  IProjectPersonHistory,
  IQueryableProject,
  ProjectPersonHistory,
} from 'services/apiClients/AthenaClient'
import useAthenaClient from 'hooks/useAthenaClient'
import { makeStyles } from '@material-ui/core/styles'
import { createControls } from 'metaprogramming/formDSL/createControls'
import { psvToArray } from 'metaprogramming/formDSL/psv'
import {
  addIndustryProjectFormSettings,
  addProjectFormSettings,
  editProjectFormSettings,
} from './projectHistoryConfig'
import { getNamedData } from 'utils/forms'
import CoreDebug from 'features/core/CoreDebug'
import { CircularProgress } from '@material-ui/core'
import { CoreProject } from 'features/core/CoreProject'
import Hovertip from 'features/coreComponents/Hovertip'
import _ from 'lodash'
import { useGlobalState } from 'app/state/useGlobalState'

const useStyle = makeStyles(() => ({
  flexContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start', // 'space-around',
    //alignItems: 'space-around',
  },
  textField: {
    //flexGrow: 1,
    flexBasis: '45%',
    midWidth: '400px',
    margin: '10px',
  },
}))

interface PersonToProjectProps {
  personId: number
  personGuid: string
  personName: string
  mode?: string | null
  record?: IProjectPersonHistory
  activeProject: IQueryableProject | null
}

interface IPersonOnProject {
  personId: number
  //personGuid?: string,
  personTitleOnProject: string
  projectId: string
}

interface IProjectLite {
  private: boolean
  projectId?: string
  name: string
  clientName: string
  constructionActualSubstantialCompletionDate: Date
  cost: number
  city: string
  state: string
  districts: string[]
  squareFootage: number
  verticalMarket: string
  nonHenselPhelpsProject: boolean
}

function convertApiProjectHistory(queryableProject: IApiProjectHistory) {
  const data = queryableProject as Record<string, any>
  console.log('!@#abc 2: ', data)
  if (data && 'completionDate' in data) {
    data['constructionActualSubstantialCompletionDate'] = data['completionDate']
    delete data['completionDate']
  }
  return data as Partial<IProjectLite>
}

function convertQueryableProject(queryableProject: IQueryableProject) {
  const data = queryableProject as Record<string, any>
  if (data && 'completion' in data) {
    data['constructionActualSubstantialCompletionDate'] = data['completionDate']
    delete data['completionDate']
  }
  return data as Partial<IProjectLite>
}

export default function AddOrEditProjectForPerson(props: PersonToProjectProps) {
  const classes = useStyle()
  console.log('!!@ AddOrEditProjectForPerson props', { props })
  const { athenaClient } = useAthenaClient()
  const gs = useGlobalState()

  const [project, setProject] = useState<object | null>(null)
  const [initValues, setInitValues] = useState<object>({})
  const [mode, setMode] = useState<object | null>(null)
  const [isSavingProject, setIsSavingProject] = useState(false)
  const [createProjectId, setCreatedProjectId] = useState<string | null>(null)
  const [isSavingPersonOnProject, setIsSavingPersonOnProject] = useState(false)

  const countRef = useRef<number>(0)

  const { options }: IDropDownOptions = useReferenceDataForProjects()
  const editMode = props.mode === 'edit'
  console.log('editMode', { editMode, 'props.mode': props.mode })
  let controls: JSX.Element[] = []
  let form: HTMLFormElement | null = null

  useEffect(() => {
    console.log('!@#- useEffect for fieldOptions', { options })
  }, [options.length])

  function isValidDateInput(input: any): input is string | number {
    return (
      input !== null && input !== undefined && input.toString().trim() !== ''
    )
  }

  function safeDateCreation(input: any): Date | null {
    if (isValidDateInput(input)) {
      const date = new Date(input)
      if (!isNaN(date.getTime())) {
        return date
      }
    }
    return null
  }

  function toDateISOString(input: any): string | null {
    const date = safeDateCreation(input)
    if (!date) return null
    return date && isNaN(date.getTime()) ? null : date.toISOString()
  }

  // first call
  const saveIndustryProject = async (): Promise<IPersonOnProject> => {
    const formData = getNamedData(form) as Record<
      string,
      string | string[] | number | boolean | undefined | null
    >
    const personTitleOnProject = formData['personTitleOnProject'] as string
    formData['nonHenselPhelpsProject'] = true
    formData['private'] = false
    formData['districts'] = []
    const completeDate = safeDateCreation(
      formData['constructionActualSubstantialCompletionDate'],
    )
    formData['constructionActualSubstantialCompletionDate'] = completeDate
      ? completeDate.toISOString()
      : null
    formData['cost'] = formData['cost'] ? Number(formData['cost']) : null
    formData['squareFootage'] = formData['squareFootage']
      ? Number(formData['squareFootage'])
      : null
    const proj = (formData as unknown) as IProjectLite

    const response = await athenaClient?.createProject(
      (proj as unknown) as ApiProject,
    )

    return {
      personId: props.personId,
      personTitleOnProject,
      projectId: response?.id as string,
    }
  }

  function handleRefresh() {
    setTimeout(() => {
      gs.updatePersonProjectHistoryTicks()
    }, 1000)
  }

  async function upsertPersonOnProject(
    personOnProject: IPersonOnProject,
  ): Promise<void> {
    if (athenaClient) {
      const client = athenaClient as AthenaClient
      try {
        // update
        await client.updatePersonProjectHistory(
          personOnProject.projectId,
          personOnProject.personId,
          (personOnProject as unknown) as ProjectPersonHistory,
        )
      } catch (e) {
        // on error - we need to add this person
        console.error(`Failed to update person project history: ${e}`)

        await client.addProjectToPerson(
          personOnProject.personId,
          (personOnProject as unknown) as ProjectPersonHistory,
        )
      }
    }
  }

  function handleSave(event: React.MouseEvent<HTMLButtonElement>) {
    const formEl = form as HTMLFormElement
    if (formEl.dataset['validate']) {
      if (!formEl.checkValidity()) {
        if (!formEl.reportValidity()) {
          alert('Form is not complete or has errors.')
          return
        } else {
          _.noop()
        }
      }
    }
    const validFormEl = document.getElementById(
      'valid-form',
    ) as HTMLInputElement
    validFormEl.value = 'true'
    event.preventDefault()

    if (props.mode === 'edit') {
      setIsSavingPersonOnProject(true)
      const formData = getNamedData(form)
      const personTitleOnProject = (formData as Record<string, string>)[
        'personTitleOnProject'
      ]
      const tmpPersonOnProject = {
        personId: props.personId,
        projectId: props.record?.projectId as string,
        personTitleOnProject,
      }
      upsertPersonOnProject(tmpPersonOnProject).then(() => {
        setIsSavingPersonOnProject(false)
      })
    }
    if (props.mode === 'new') {
      saveIndustryProject().then((tmpPersonOnProject) => {
        upsertPersonOnProject(tmpPersonOnProject).then((x) => {
          console.log('@tmp final result', x)
          setIsSavingPersonOnProject(false)
        })
      })
    }
    if (props.mode === 'new-proj-history') {
      const formData = getNamedData(form)
      const personTitleOnProject = (formData as Record<string, string>)[
        'personTitleOnProject'
      ]
      const projectId = props.activeProject!.id as string
      const tmpPersonOnProject = {
        personId: props.personId,
        personTitleOnProject,
        projectId: projectId,
      }
      upsertPersonOnProject(tmpPersonOnProject).then(() =>
        console.log('new-proj-history flow'),
      )
    }
    handleRefresh()
  }

  if (editMode) {
    const projectLite = convertApiProjectHistory(
      props.record as IApiProjectHistory,
    )
    console.log('!@#abc', { 'props.record': props.record, projectLite })
    controls = createControls(
      psvToArray(editProjectFormSettings, true),
      projectLite,
      undefined,
      options,
      classes,
    )
  } else {
    if (
      props.activeProject &&
      CoreProject.isHenselPhelpsProject(props.activeProject)
    ) {
      const projectLite = convertQueryableProject(
        props.activeProject as IQueryableProject,
      )
      controls = createControls(
        psvToArray(addProjectFormSettings, true),
        projectLite,
        undefined,
        options,
        classes,
      )
    } else {
      const psv = psvToArray(addIndustryProjectFormSettings, true)
      console.log('!@#-- psv', { psv })
      controls = createControls(psv, {}, undefined, options, classes)
    }
  }

  const setFormByRef = (formElem: HTMLFormElement) => (form = formElem)

  if (Object.keys(options).length === 0) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  // by the button
  // A non Hensel Phelps project that is displayed only in this person's history.
  // page 2 at the title
  // Industry projects are only displayed in a peron's history.
  // at the bottom
  // Industry projects can be edited in the same way as regular projects.

  // const formTitle = props.activeProject && CoreProject.isHenselPhelpsProject(props.activeProject)
  //               ? 'Add Person to Project': 'Add Person to Industry Project'

  const formTitle =
    props.mode !== 'new'
      ? 'Add Person to HP Project'
      : 'Add Person to Industry Project'

  return (
    <>
      <form
        ref={(x) => setFormByRef(x as HTMLFormElement)}
        data-validate={true}
      >
        <h3>
          {formTitle}&nbsp;
          <Hovertip
            title="Industry projects are only displayed in a peron's history."
            visible={
              !props.activeProject ||
              CoreProject.isIndustryProject(props.activeProject)
            }
          />
        </h3>

        <div className={classes.flexContainer}>
          {CoreDebug.passThru(
            '!@#-- AddOrEditProjectForPerson controls',
            controls,
          )}
        </div>

        <button
          name="hidden-button-add-project-to-person-save"
          onClick={handleSave}
          style={{ display: 'none' }}
        >
          Button
        </button>
      </form>
      {props.mode === 'new' && (
        <p>
          After creating an industry project, you can navigate to its details
          page by clicking on it from this person's project history.
          <Hovertip
            title={
              'Industry projects can be edited in the same way as regular projects.'
            }
          />
        </p>
      )}
    </>
  )
}
