import FacetSideNav from 'components/search/FacetSideNav';
import { serialize } from 'components/search/FacetSideNav/serdeUtil';
import SearchViewContainer from 'components/search/SearchViewContainer';
import CoreDebug from 'features/core/CoreDebug';
import CriteriaProvider, { CriteriaContext } from 'features/Criteria/CriteriaProvider';
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useAppFocusState } from 'recoil/appFocus';
import { useDashQueryState } from 'recoil/dashQuery';
import QueryConvertor from 'services/search/QueryConvertor';
import { IAppFocus } from 'types/generalTypes';
import { IFacetState } from 'types/searchTypes';
import { getYear } from 'utils/dateUtil';

import { makeStyles } from '@material-ui/core/styles';

//import MiddleMenu from "../../components/PrimaryAppBar/MiddleMenu";
//import MiddleMenuPortal from "components/PrimaryAppBar/MiddleMenu/MiddleMenuPortal";
import { useStyles } from './localStyles';
import {FacetContext} from "../../app/facetContext";
import useAthenaClient from "../../hooks/useAthenaClient";
import FacetCache from "../../app/FacetCache";
import CoreLocation from "../../features/core/CoreLocation";

interface ISearchProps {
    rand?: number,
}

export default function SearchPage(props: ISearchProps) {
    const classes = useStyles();
    const {athenaClient} = useAthenaClient();
    const [facetCache, setFacetCache] = useState<FacetCache | null>(null);
    const [, { setAppFocusState }] = useAppFocusState();
    const { entityName } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const combinedDebug = { entityName, searchParams: Object.fromEntries(searchParams), location: location };
    const { search, onSearchChange } = useContext(CriteriaContext);
    const [dashQueryState, { setDashQueryState }] = useDashQueryState();
    const log = CoreDebug.getConsoleLogFunc(true);

    if (CoreLocation.endsInSlash()) {
        navigate('/redirect/' + entityName);
    }

    // single point of dashQueryState mutations
    useEffect(() => {
        const dashQuery = new QueryConvertor().createDashQuery(location, searchParams);
        setDashQueryState(dashQuery, 'SearchPage');
    }, [location, searchParams]);

    console.log('combinedDebug from SearchPage', combinedDebug);

    useEffect(() => {
        setAppFocusState({ entityName: entityName, entityId: '' } as IAppFocus);
    }, [props.rand]);

    useEffect(() => {
        console.log('@@ combined', combinedDebug);
    }, [entityName, searchParams]);

    useEffect(() => {
        if (athenaClient) {
            setFacetCache(new FacetCache(athenaClient));
        }
    }, [athenaClient]);

    return (
        <CriteriaProvider>
            <div className={classes.searchPage}>
                <div className={classes.box}>
                    <div className={classes.innerSearchPage}>
                        <div className={classes.facetSideNavContainer}>
                            <FacetContext.Provider value={facetCache}>
                                <FacetSideNav entityName={entityName ?? ''} />
                            </FacetContext.Provider>
                        </div>
                        <div className={classes.viewContainerContainer}>

                            <SearchViewContainer />

                        </div>
                    </div>
                </div>
            </div>
        </CriteriaProvider>
    );
}

