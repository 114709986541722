import {FacetSettingsInfo} from 'types/searchTypes';

export function deserialize(field: string, str?: string | null): FacetSettingsInfo {
  if (!str) return {field:field, mode: '', values: []} as FacetSettingsInfo;
  const tokens: string[] = [];
  let token = '';
  let inQuote = false;
  for (let i = 0; i < str.length; i++) {
    const c = str.charAt(i);
    if (c == '"') {
      if (inQuote) {
        tokens.push(token);
        token = "";
        inQuote = false;
      } else {
        inQuote = true;
      }
    } else if (c == ',' || c == '.') {
      if (inQuote) {
        token += c;
      } else {
        if (token.length > 0) {
          tokens.push(token);
          token = "";
        }
      }
    } else {
      token += c;
    }
  }
  if (token.length > 0) {
    tokens.push(token);
  }
  //return tokens;
  return {field:field, mode: tokens[0], values: tokens.splice(1)} as FacetSettingsInfo;
}
export function serialize(facetSettingsInfo: FacetSettingsInfo): string {
  //export function serialize(mode: string, values: string[] | null | undefined): string {
  const mode = facetSettingsInfo.mode;
  const values = facetSettingsInfo.values;
  console.log('inside encode function', mode, values);
  if (values && values.length > 0) {
    return mode + '.' + values.map(x => `"${x}"`).join(',');
  } else {
    return '';
  }
}