export const currencyFmt = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const commasFmt = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
});

export const commasTwoDecimalsFmt = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  useGrouping: true,
});

export function milBilNumberFormat(num?: number | null) {
  if (num === null || num === undefined) {
    return '';
  }
  if (num < 1000) {
    return num;
  }
  if (num < 1000000) {
    return (num / 1000).toFixed(1) + ' K';
  }
  if (num < 1000000000) {
    return (num / 1000000).toFixed(1) + ' MIL';
  }
  return (num / 1000000000).toFixed(1) + ' BIL';
}

export function getAge(date?: Date | null) {
  if (date === null || date === undefined) {
    return '';
  }
  const now = new Date();
  let months = (now.getFullYear() - date.getFullYear()) * 12;
  months = months - date.getMonth() + now.getMonth();
  const years = Math.floor(months / 12);
  months = months % 12;
  return years + ' yrs ' + months + ' mos';
}
