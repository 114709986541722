import { useCurrentUserState } from 'recoil/currentUser';
import { CurrentUser } from 'types';
import { isNullish } from 'utils/general';

const useCurrentUser = (): CurrentUser | null => {
  const [currentUser] = useCurrentUserState();

  return isNullish(currentUser) ? null : currentUser;
};

export default useCurrentUser;
