import React from "react";
import ProjectStaffAssocGrid from "./ProjectStaffAssocGrid";
import {ProjectStaffProvider} from "./ProjectStaffProvider";
import {ApiPersonHistory, ApiProject, IApiProject, ProjectPersonHistory} from "services/apiClients/AthenaClient";
import {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
import ProjectStaffContext from "./ProjectStaffContext";

type ProjectStaffPayload = string | null | undefined | ProjectPersonHistory | ApiPersonHistory;


export default function ProjectStaffAssocSection(props: { model: IApiProject, referenceData: IReferenceData }) {
  const project = props.model as ApiProject;

  return (
      <ProjectStaffProvider model={project}>
        <ProjectStaffAssocGrid />
      </ProjectStaffProvider>
  );
}