import {shortDate} from "./dateUtil";


export function toggleItemInArray<T>(array: T[], item: T): T[] {
  if (array.includes(item)) {
    return array.filter(i => i !== item);
  } else {
    return [...array, item];
  }
}

// function to convert arg(s) to an array with them in it
export function toArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}


//remove the string key passed in property/key from object if it is not part of hasOwn
export function tryRemovingKvp(obj: any, key: any) {
  if (!Object.prototype.hasOwnProperty.call(obj, key)) {
    delete obj[key];
  }
}

// function to test for an empty object {}
export function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * A value is considered nullish if it is null, undefined, empty string, or a non-empty object/array containing only nullish values
 */
export const isNullish: { (value: any): boolean } = (value) => {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length > 0 && value.every((item) => isNullish(item));
  }

  if (typeof value === 'object') {
    const nestedValues = Object.values(value);
    return nestedValues.length > 0 && nestedValues.every((nested) => isNullish(nested));
  }

  return false;
};

export function multipleSameness(...arrays: any[]) {
  const outerArray = Array.from(arrays);
  console.log('!@!@! multipleSameness', outerArray);
  for (const array of outerArray) {
    for (let i = 0; i < array.length - 1; i++) {
      let current = array[i];
      let next = array[i + 1];

      current = current instanceof Date ? shortDate(current) : current;
      next = next instanceof Date ? shortDate(next) : next;



      if (current !== next) {
        console.log('!@!@ RIGHT HERE:', {current, next, typeofCurrent: typeof current, typeofNext: typeof next});
        return false;
      }
    }
  }

  return true;
}

export function pt(comment: string, value: any) {
  console.log(`!@!@! ${comment}`, value);
  return value;
}