

export default function dateFormat(value?: Date | null) {
  if (value === null || value === undefined) {
    return undefined;
  } else {
    const formattedDate = value.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
    // format "3/26/2023"
    return formattedDate;
  }
}
