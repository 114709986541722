import * as React from 'react';

interface Props {
  streetAddress1?: string | null | undefined;
  streetAddress2?: string | null | undefined;
  city?: string | null | undefined;
  state?: string | null | undefined;
  zip?: string | null | undefined;
}

export default function CombinedAddress({
                                          streetAddress1,
                                          streetAddress2,
                                          city,
                                          state,
                                          zip,
                                        }: Props) {
  const trimmedState = state?.trim();
  const trimmedZip = zip?.trim();
  const stateZip = trimmedState && trimmedZip ? `${trimmedState} ${trimmedZip}` : trimmedState || trimmedZip;
  const parts = [streetAddress1, streetAddress2, city, stateZip].filter((part) => part != null && part !== ''); // filter out empty or null parts
  if (parts.length === 0) {
    return null; // if there are no valid parts, return null
  }
  const address = parts.join(', ').trim(); // join valid parts with comma and space, and trim the address
  return <>{address}</>; // return the address string as JSX
}
