import DashSort from "./DashSort";

export default class AthenaSort {
  private inputField: string;
  private inputDirection: string;
  private fields: string[];
  private directions: string[];

  private constructor(field: string, direction: string) {
    this.inputField = field;
    this.inputDirection = direction;
    this.mapToAthenaEquivalent(field, direction);
  }

  private mapToAthenaEquivalent(field: string, direction: string) {
    const mapping: Record<string, string> = {
      location: 'state,city,name',
      cost: 'cost,name',
      squareFootage: 'squareFootage,name',
      completionDate: 'completionDate,name',
      hireDate: 'hireDate,name',
      totalProjectValue: 'totalProjectValue,name',
      hpExperience: 'hireDate,name',
      districts: 'district,name',
      projectNumbers: 'projectNumbersJoined,name'
    };

    if (direction !== 'asc' && direction !== 'desc') {
      console.error(`Invalid sort direction "${direction}" from class "DashSort"`);
    } else {
      this.directions = [direction];
    }

    if (field in mapping) {
      this.fields = mapping[field].split(',');
    } else {
      this.fields = [field];
    }
  }

  public toArray(): string[] {
    const result: string[] = [];
    for (let i = 0; i < this.fields.length; i++) {
      if (this.directions[i] === undefined) {
        result.push(this.fields[i]);
      } else {
        result.push(`${this.fields[i]} ${this.directions[i]}`);
      }
    }
    return result;
  }

  public toString(): string {
    return this.toArray().join(',');
  }

  public static fromDashSort(dashSort: DashSort): AthenaSort {
    return new AthenaSort(dashSort.field, dashSort.direction);
  }
}
