import { makeStyles } from '@material-ui/core';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';

const useStyles = makeStyles((theme) => ({
  actionButton: { marginLeft: theme.spacing(2) },
}));

const useToast = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dismiss = (key: SnackbarKey) => (
    <StyledButtonPrimary
      color="default"
      label={'Dismiss'}
      onClick={() => {
        closeSnackbar(key);
      }}
    />
  );

  const displayToast = (message: string, type: VariantType, Action?: any, dismissible = true) => {
    const customCallback = (key: SnackbarKey) => {
      if (dismissible) {
        return (
          <>
            <Action toastKey={key} />
            <StyledButtonPrimary
              color="default"
              className={classes.actionButton}
              label={'Dismiss'}
              onClick={() => {
                closeSnackbar(key);
              }}
            />
          </>
        );
      } else {
        return <Action />;
      }
    };

    const getAction = () => {
      if (Action) {
        return customCallback;
      } else if (type === 'error' || dismissible) {
        return dismiss;
      } else {
        return null;
      }
    };

    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      autoHideDuration: type === 'error' || type === 'info' ? null : 6000,
      persist: type === 'error' || type === 'warning' || !dismissible,
      action: getAction(),
      preventDuplicate: true,
    });
  };
  return { displayToast, closeSnackbar };
};

export default useToast;
