/* eslint-disable */

/* tslint:disable */

export class GlobalDebug {
    static hideConsole() {
        console.log = function () {
        };
        console.info = function () {
        };
        console.warn = function () {
        };
        console.error = function () {
        };
    }
}