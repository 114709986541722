import {
  Column, GridComponent,
  FilterSettingsModel,
  SortSettingsModel, EditSettingsModel, GridModel
}
  from '@syncfusion/ej2-react-grids';

export interface IDashGridConfig {
  title?: string;
  showTitle: boolean;
  allowAdd: boolean;
  allowRemove: boolean;
  columns: Partial<Column>[];
  sortingOptions: SortSettingsModel;
  filterOptions: FilterSettingsModel;
  editOptions: EditSettingsModel;
}

export class DashGridConfig implements IDashGridConfig {
  public title?: string | undefined;
  public showTitle = false;
  public allowAdd = true;
  public allowRemove = true;

  public columns: Partial<Column>[] = [];
    // {
    //   headerText: 'Title',
    //   field: 'title',
    //   width: 200,
    //   allowSorting: true,
    //   validationRules: { required: true },
    // },
    // {
    //   headerText: 'Subtitle',
    //   field: 'subtitle',
    //   width: 100,
    //   allowEditing: false,
    //   allowSorting: true,
    // },
    // {
    //   headerText: 'Sponsor',
    //   field: 'sponsor',
    //   width: 100,
    //   allowSorting: true,
    // },
    // {
    //   headerText: 'Date',
    //   field: 'date',
    //   width: 150,
    //   format: {type: 'date', skeleton:'short'},
    //   allowSorting: true,
    // },
    // {
    //   headerText: 'Citation',
    //   field: 'citation',
    //   width: 150,
    //   format: 'yMd',
    //   allowSorting: true,
    // },


  public sortingOptions: SortSettingsModel = {
    //columns:  [{ field: 'date', direction: 'Descending' }]
  };

  public filterOptions: FilterSettingsModel = {
    ignoreAccent: true,
    //columns: [{field:'title', operator:'startsWith'}]
  };

  public editOptions: EditSettingsModel = {
    // allowEditing: true,
    // allowAdding: true,
    // allowDeleting: true,
    // mode: 'Dialog',
    // allowEditOnDblClick: false,
  };

  // withGridSettings<T extends GridModel>(GridComponent: React.ComponentType<T>) {
  //   const gridSettings = {
  //     columns: this.columns,
  //     sortSettings: this.sortingOptions,
  //     filterSettings: this.filterOptions,
  //     editSettings: this.editOptions,
  //     // ...extraProps,
  //   };
  //
  //   return (props: GridModel) => <GridComponent {...gridSettings} {...props} />;
  // }

  addColumn(
      headerText: string,
      field: string,
      width: number,
      allowSorting = true,
      allowEditing = true,
      validationRules?: object,
      format?: object | string,
  ) {
    const column: Partial<Column> = {
      headerText,
      field,
      width,
      allowSorting,
      allowEditing,
    };

    if (validationRules) {
      column.validationRules = validationRules;
    }

    if (format) {
      column.format = format;
    }

    this.columns.push(column);
    return this;
  }

  addCurrencyColumn(
      headerText: string,
      field: string,
      width: number,
      allowSorting = true,
      allowEditing = true,
      validationRules?: object
  ) {
    const format = 'C0'; // 'C0' for currency format with 0 decimal places
    return this.addColumn(headerText, field, width, allowSorting, allowEditing, validationRules, format);
  }

  addDateColumn(
      headerText: string,
      field: string,
      width: number,
      allowSorting = true,
      allowEditing = true,
      formatType: 'yMd' | 'short' = 'yMd',
      validationRules?: object
  ) {
    const format = { type: 'date', skeleton: formatType };
    return this.addColumn(headerText, field, width, allowSorting, allowEditing, validationRules, format);
  }

  addTemplateColumn<T>(
      headerText: string,
      template: (record: T) => JSX.Element,
      width: number,
      allowSorting = true,
      allowEditing = true,
      validationRules?: object,
      format?: object | string,
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const column: Partial<Column> = {
      headerText,
      template,
      width,
      allowSorting,
      allowEditing,
    };

    if (validationRules) {
      column.validationRules = validationRules;
    }

    if (format) {
      column.format = format;
    }

    this.columns.push(column);
    return this;
  }

}
