import AthenaClient, {Template, ExportSchema, IExportSchema, ITemplate} from './apiClients/AthenaClient';
import {IFieldDefDefault, IPColumnDef, IPColumnSpec} from 'types/gridTypes';
import defaultPeopleColDefs from 'constants/metadata/defaultPeopleColDefs.json';
import defaultProjectsColDefs from 'constants/metadata/defaultProjectsColDefs.json';

export default class TemplateAndExportService {
    private readonly athenaClient = new AthenaClient();
    private readonly primitiveTypes = ['string','int32','int64','bool','double','date',];
    private readonly formatMaps: { [key: string]: string } = {
        'string': '',
        'int32': 'N0',
        'int64': 'N0',
        'bool': '',
        'double': 'N0',
        'date': 'MM/dd/yyyy',
    };

    constructor(athenaClient: AthenaClient) {
        this.athenaClient = athenaClient;
    }

// template and export service functions
    public isPrimitiveType(type?: string | null) {
        if (type === null || type === undefined) {
            return false;
        }
        return this.primitiveTypes.includes(type);
    }

    public wrapInExportSchemaEnvelope(fields: IFieldDefDefault[], entityName: string) {
        const data = {
            label: entityName === 'people' ? 'People': 'Projects',
            type: 'array',
            propertyName: null,
            referenceProperty: null,
            referencePropertyLabel: null,
            items: {
                label: entityName === 'people' ? 'Person': 'Project',
                type: 'object',
                propertyName: null,
                referenceProperty: 'name',
                referencePropertyLabel: entityName === 'people' ? 'Person': 'Project',
                properties: fields as unknown as ExportSchema,
            }
        };
        return data as unknown as ExportSchema;
    }


    public getDefaultSearchFields(entityName: string): IFieldDefDefault[] {
            return entityName === 'people'
                ? defaultPeopleColDefs
                : defaultProjectsColDefs;
    }

    public getSearchColumnDefsFromDefaults(entityName: string): IPColumnDef[] {
        const cs: IFieldDefDefault[] = this.getDefaultSearchFields(entityName);
        const columnDefs = this.mapColumnDefsFromDefaults(cs);
        console.log('columnDefs', { cs, columnDefs });
        return columnDefs;
    }

    public async getSearchColumnDefsFromTemplate(user: string, entityName: string, target: string): Promise<undefined | IPColumnDef[]> {
        //console.log('$ cols b4 await this.athenaClient.getTemplates(user, entityName, target)', {user, entityName, target});
        const templates: Template[] = await this.athenaClient.getTemplates(user, entityName, target);
        const preTemplate: Template | undefined = (templates && templates.length > 0) ? templates[0] : undefined;

        if (!preTemplate) {
            return undefined;
        }

        const preColDefs = (preTemplate?.schema?.items?.properties ?? []) as IExportSchema[];
        console.log('$ cols preColDefs',preColDefs);

        // for each property in the template, create a column definition
        const columnDefs = this.mappingWork(preColDefs);
        // console.log('$ columnDefs from getSearchColumnDefsFromTemplate', columnDefs);
        // console.log('$ columnDefs params that were passed in', {user,entityName,target});
        console.log('$$$ got colDefs from template', columnDefs);
        return columnDefs;
    }

    private mappingWork(preColDefs: IExportSchema[]) {
        const columnDefs = preColDefs.map((item: IExportSchema) => {
            // refactor later when more decisions have been made about Export moving forward #mjl
            const label = item.label ?? '';
            const hdrText = (label.includes(' ') ? label : label.replace(/([A-Z])/g, ' $1').trim());
            const tmpColumnDef = {
                label: hdrText,
                field: item.propertyName,
                type: item.type,
                columnSpec: {
                    field: item.propertyName,
                    headerText: hdrText,
                    textAlign: 'Left',
                    //width: 100,
                    format: (item.type) ?
                        (this.formatMaps[item.type] !== '') ? this.formatMaps[item.type] : undefined
                        : undefined,
                } as IPColumnSpec,
            };

            if (item.type === 'date') {
                tmpColumnDef.columnSpec.format = this.formatMaps[item.type];
                tmpColumnDef.columnSpec.type = item.type;
            }

            return tmpColumnDef;
        }) as IPColumnDef[];
        return columnDefs;
    }

    private mapColumnDefsFromDefaults(oldDefaultColDefs: IFieldDefDefault[]) {
        const columnDefs = oldDefaultColDefs.map((item: IFieldDefDefault) => {
            const label = item.label ?? '';
            const hdrText = (label.includes(' ') ? label : label.replace(/([A-Z])/g, ' $1').trim());
            const tmpColumnDef = {
                label: hdrText,
                field: item.propertyName,
                type: item.type,
                columnSpec: {
                    field: item.propertyName,
                    headerText: hdrText,
                    textAlign: 'Left',
                    //width: 100,
                    format: (item.type) ?
                        (this.formatMaps[item.type] !== '') ? this.formatMaps[item.type] : undefined
                        : undefined,
                } as IPColumnSpec,
            };

            if (item.type === 'date') {
                tmpColumnDef.columnSpec.format = this.formatMaps[item.type];
                tmpColumnDef.columnSpec.type = item.type;
            }

            return tmpColumnDef;
        }) as IPColumnDef[];
        return columnDefs;
    }


    public ConvertSearchColumnDefsToTemplate(entityName: string, owner: string, columnDefs: IPColumnDef[], id?: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const templateId = (id) ? id: window.crypto.randomUUID();

        const schemaRoot = {
            "label": "X",
            "type": "object",
            "propertyName": undefined,
            "referenceProperty": undefined,
            "referencePropertyLabel": undefined,
            "properties": [] as IExportSchema[],
            "items": undefined,
        } as IExportSchema;
        schemaRoot.label = (entityName === 'people') ? 'People': 'Projects';

        const t: ITemplate = {};
        t.id = templateId;
        t.owner = owner;
        t.entity = entityName;
        t.target = 'searchResults';
        t.createdDate = new Date();
        t.modifiedDate = new Date();
        t.name = t.owner + '-search-result-view';
        t.description = undefined;
        t.requiredRoles = [];
        t.sharedWith = [];
        t.messages = [];
        t.schema = new ExportSchema(schemaRoot);
        // loop through the column definitions and create a IExportSchema for each
        const schemaProperties = columnDefs.map((item: IPColumnDef) => {
            const pre = {
                label: item.label,
                propertyName: item.field,
                type: item.type,
                referenceProperty: null,
                referencePropertyLabel: null,
                properties: null,
            } as unknown as IExportSchema;
            return new ExportSchema(pre);
        });
        if (t.schema) {
            t.schema.properties = schemaProperties;
        }
        return t;
    }
}

