import _ from 'lodash';

export function hasPermission<T extends string>(roleNeeded?: T[], currentUserRoles?: T[], comment?: string): boolean {
  if (roleNeeded == null) return true;
  // Check if both arrays are present and are of type 'string'
  if (Array.isArray(roleNeeded) && Array.isArray(currentUserRoles)) {
    const loweredRoleNeeded = roleNeeded.map(role => role.toLowerCase());
    const loweredCurrentUserRoles = currentUserRoles.map(role => role.toLowerCase());
    const intersection = _.intersection(loweredRoleNeeded, loweredCurrentUserRoles);
    const result = !_.isEmpty(intersection);
    //console.log('hasPermission', { roleNeeded, currentUserRoles, result, intersection, comment });
    return result;
  }

  return false;
}

export function guaranteeRelatedPermissions(array: string[]): string[] {
  const tmpArray = [...array];
  const hasAdmin = array.includes('Administrator');
  const hasContributor = array.includes('Contributor');
  const hasVDCAdmin = array.includes('VDC_Administrator');
  const hasVDCContributor = array.includes('VDC_Contributor');
  const hasVDCReader = array.includes('VDC_Reader');

  // if (hasAdmin) {
  //   tmpArray.push('Contributor');
  // }
  // if (hasContributor) {
  //   tmpArray.push('Reader');
  // }
  // if (hasVDCAdmin) {
  //   tmpArray.push('VDC_Contributor');
  // }
  // if (hasVDCContributor) {
  //   tmpArray.push('VDC_Reader', 'Contributor');
  // }
  // if (hasVDCReader) {
  //   tmpArray.push('Reader');
  // }

  return [..._.uniq(tmpArray)];
}

export function getUserRoles() {
  const rolesJsonArray = localStorage.getItem('roles') as string;
  return JSON.parse(rolesJsonArray);
}