import React, {Fragment, useEffect, useState} from 'react';
//import DashDetailTable from '../shared/DashDetailTable'
import {getProjectHistoryConfig} from "./gridConfigSettings/projectHistory";

import {Card, CardContent, CardHeader} from "@material-ui/core";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";

import {IApiPerson, IApiProjectHistory} from "services/apiClients/AthenaClient";
import SectionWithGridAndCardList from "../shared/SectionWithGridAndCardList";
import {convertProjectHistory} from "services/infoCardConversion";
import ChangeViewButton from "../../dashCore/ChangeViewButton";
import AddProjectForPersonWizard from "./AddProjectWizard";
import useOnOff from "../../../hooks/useOnOff";
import useAthenaClient from "../../../hooks/useAthenaClient";
import useSaveCount from "../../../hooks/useSaveCount";
import {useGlobalState} from "../../../app/state/useGlobalState";
import {useObserver} from "mobx-react";
import _ from "lodash";
import useTracingList from "../../../hooks/useTracingList";

export default function ProjectHistoryCard(props: { person?: IApiPerson }) {
  const sharedClasses = useSharedLargeCardStyles();
  const {athenaClient} = useAthenaClient();
  const [listViewOn, setListViewOn] = useState(true);
  const [dialogOpen, open, close] = useOnOff();
  const [step, setStep] = useState(1);
  const [mode, setMode] = useState('menu');
  const [person, setPerson] = useState(props.person);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState<object | null>(null);
  const [saveCount, incr] = useSaveCount();
  const gs = useGlobalState();
  const { append, _} = useTracingList('[ProjectHistoryCard]', false);
  //const [ticks, setTicks] = useState(0);

  const config = getProjectHistoryConfig(gs.userRoles);
  let infoCards = convertProjectHistory(props?.person?.projectHistory ?? []);

  const changeView = () => setListViewOn(!listViewOn);

  // function updateTicks() {
  //   setTicks(new Date().getTime());
  // }

  const handleOnDialogClose = () => {
    close();
    incr();
    //gs.incrPersonProjectHistoryCount();
    setTimeout(() => {
      //updateTicks();
      gs.updatePersonProjectHistoryTicks();
    }, 1000);
  }

  useEffect(() => {
    async function reloadData() {
      if (athenaClient) {
        setLoading(true);
        const tmpPerson = await athenaClient.getPerson(props.person?.id ?? 0);
        setPerson(tmpPerson);
        setLoading(false);
        infoCards = convertProjectHistory(tmpPerson.projectHistory);
      }
    }

    reloadData();
  }, [saveCount]);


  function handleOnToolbarClick(args: any, data: object[]) {
    append({args,data});
    // data comes through as array of records
    // at this time we can only select one at a time
    // use the [0] index
    //console.log('@@ in handleOnToolbarClick args.item.text',args,data);
    const record = data.shift();
    console.log('#@!ontc', {args})
    switch (args.item.text) {
      case 'Add':
        setStep(1);
        setMode('new');
        open();
        args.cancel = true;

        break;
      case 'Edit':
        if (!record) {
          alert('You must select a record to edit first.');
        } else {
          setStep(2);
          setMode('edit');
          setRecord(record);
          //console.log({record});
          open();
        }
        args.cancel = true;
        break;
      case 'Delete':
        if (!record) {
          alert('You must select a record to delete first.');
        } else {

          if (confirm('Are you sure you want to delete this record from the Project History?')) {

            const projectId = (record as IApiProjectHistory).projectId as string;
            athenaClient?.deletePersonProjectHistory(projectId, props.person?.id ?? 0)
                .then(() => _.noop()); //setTimeout(() => incr(),1000));
          } else {
            args.cancel = true;
          }
        }
    }
  }

  const editOptions = gs.isUserContributor()
      ? config.editOptions
      : {
        ...config.editOptions,
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false
      };

  append("render");

  useEffect(() => {
    const hasProjectsHistoryModuleInURL = window.location.toString().includes('project-history-module');

    if(hasProjectsHistoryModuleInURL){
      const el = document.querySelector(`#project-history-module`);

        if (el) {
          el.scrollIntoView();
        } else {
          console.log('element not found to scollIntoView');
        }
    }
  },)
  

  return useObserver(() => <>
        <Card id="project-history-module" className={sharedClasses.defaultCard}>
          <CardHeader
              title={'Project History'} data-scroll-name={'projectHistory'}
              action={<ChangeViewButton showListIcon={listViewOn} onClick={changeView}/>}/>
          <CardContent style={{display: 'flex'}}>
            {!loading ?
                <SectionWithGridAndCardList isReadOnly={gs.isUserContributor()}
                                            listView={listViewOn}
                                            items={person?.projectHistory ?? []}
                                            columnDirectives={config.columnDirectives}
                                            exportColumns={config.exportColumns}
                                            sortingOptions={config.sortingOptions}
                                            editOptions={editOptions}
                                            showToolbar={true}
                                            onToolbarClick={handleOnToolbarClick}
                                            title={config.title}
                                            showTitle={config.showTitle}
                                            allowFiltering={true}
                                            editWithBuiltinDialog={false}
                                            infoCards={infoCards}/>

                : <div></div>
            }
          </CardContent>
        </Card>

        {dialogOpen && person !== undefined &&
            <AddProjectForPersonWizard
                personId={person?.id as number}
                personGuid={person?.guid as string}
                step={step}
                mode={mode}
                record={record ?? undefined}
                personFullName={person?.firstName + ' ' + person?.lastName}
                onDialogClose={handleOnDialogClose}
                open={dialogOpen}/>
        }
      </>
  );
}