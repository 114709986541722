import {withDashCard} from '../../shared/withDashCard';
import ProjectStaffAssocSection from "./ProjectStaffAssocSection";

const ProjectStakeholdersCard = withDashCard('Project Staff', 'project-staff-module',
    // list of components to add to the card (in order they should appear)
    [
      ProjectStaffAssocSection,
    ],
    'staff', true
);

export default ProjectStakeholdersCard;