import {IApiProjectSustainabilityCertification} from "services/apiClients/AthenaClient";
import {label} from "utils/decorators";
import DashDisplayModel from "types/DashDisplayModel";

export default class DashProjectSustainabilityCertification
    extends DashDisplayModel
    implements IApiProjectSustainabilityCertification {
  @label('Id')
  id?: string;

  @label('Certification Name')
  name?: string;

  certificationId?: string;

  @label('Version')
  version?: string;

  versionId?: string;

  @label('Level')
  level?: string;

  levelId?: string;

  @label('Type')
  type?: string;

  typeId?: string;

  @label('Status')
  status?: string;

  @label('Award Date')
  awardDate?: Date;

  @label('Assets Uploaded')
  assetsUploaded?: string;

  @label('Notes')
  notes?: string;

  constructor(data: IApiProjectSustainabilityCertification) {
    super();
    Object.assign(this, data);
  }

  createUpdatePacket(): Record<string, unknown> {    
    return {
      id: this.id,
      certificationId: this.certificationId,
      versionId: this.versionId,
      levelId: this.levelId,
      typeId: this.typeId,
      status: this.status,
      awardDate: this.awardDate,
      assetsUploaded: this.assetsUploaded,
      notes: this.notes
    };
  }

}