import React from "react";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ListIcon from "@material-ui/icons/List";
import {Button} from "@material-ui/core";

export default function ChangeViewButton(props: {showListIcon:boolean, onClick:() => void}) {
  return (
      <Button style={{textTransform: 'uppercase'}}
              endIcon={props.showListIcon ? <ListIcon />: <ViewModuleIcon/>}
              onClick={props.onClick}>Change View</Button>
  );
}


