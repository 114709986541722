// CarouselFooter.tsx

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  filename: {
    textAlign: 'center',
    color: 'white',
    marginBottom: '5px',
  },
  dots: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
  },
  dot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    margin: '5px',
  },
});

interface CarouselFooterProps {
  currentImageName: string;
  totalImages: number;
  currentIndex: number;
  onDotClick: (index: number) => void;
}

const CarouselFooter: React.FC<CarouselFooterProps> = (props) => {
  const classes = useStyles();

  return (
      <div>
        <div className={classes.filename}>
          {props.currentImageName.split('/').pop()}
        </div>
        <div className={classes.dots}>
          {Array.from({ length: props.totalImages }, (_, index) => (
              <div
                  key={index}
                  className={classes.dot}
                  onClick={() => props.onDotClick(index)}
                  style={{
                    backgroundColor: props.currentIndex === index ? '#ffffff' : '#999999',
                  }}
              />
          ))}
        </div>
      </div>
  );
};

export default CarouselFooter;
