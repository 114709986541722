import React, {useState} from "react";
import {
  Toolbar,
  ColumnDirective,
  ColumnsDirective,
  EditSettingsModel,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import {Inject} from "@syncfusion/ej2-react-grids";
import {Edit} from "@syncfusion/ej2-react-grids";

const GridDialogTesting: React.FC = () => {
  const editSettings = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    mode: 'Dialog'
  } as EditSettingsModel;

  const [yearMode, setYearMode] = useState(true);

  const handleActionBegin = (args: any) => {
    if (args.requestType === 'add' || args.requestType == 'beginEdit') {
      setYearMode(false);
    }
    if (args.requestType == 'cancel' || args.requestType == 'save') {
      setYearMode(true);
    }
    console.log('! args ' + args.requestType, args);
  }

  return (
      <div style={{width: '50%'}}>
        <GridComponent actionBegin={handleActionBegin}
                       toolbar={["Add","Edit","Delete","Update","Cancel"]}
                       editSettings={editSettings}
                        dataSource={[]}
        >
          <ColumnsDirective>
            <ColumnDirective field="OrderID" headerText="Order ID" isPrimaryKey={true} width="120"
                             textAlign="Right"></ColumnDirective>
            { yearMode ?
            <ColumnDirective
                headerText={'Award Year'}
                field="AwardDate"
                format={{type: 'date', skeleton:'y'}}
                allowEditing={false}
                width="120"
                textAlign="Right">
            </ColumnDirective>
                :(<ColumnDirective
                    headerText={'Award Date'}
                    field="AwardDate"
                    format={{type: 'date', skeleton:'yMd'}}
                    allowEditing={true}
                    editType={'datepicker'}
                    width="120">
              </ColumnDirective>)
            }
          </ColumnsDirective>
          <Inject services={[Toolbar, Edit]} />
        </GridComponent>
    </div>
  );
};

export default GridDialogTesting;