import React, {
  useState, useEffect,
  useImperativeHandle, forwardRef, useRef, ForwardedRef
} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DisplayField from "../../shared/DisplayField";
import EditIcon from '@material-ui/icons/Edit';
import AthenaClient, {
  ApiPerson, IApiPerson, IApiProject,
  IApiProjectStakeholder
} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {
  Card, CardContent, CardHeader,
  CircularProgress, Switch, Dialog
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import DashDetailsField from "components/DashDetailsField";
import numberFormatter from "services/shared/numberFormatter";
import EntityDialog from "../../shared/EntityDialog";
import {getNamedData} from "utils/forms";
import PersonDetailService from "services/PersonService";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import { v4 as uuidv4 } from 'uuid';
import {commasFmt, currencyFmt} from "utils/numberToStringUtil";
import {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
// end imports

const SECTION_ID = 'overallTechnologyNarrativeSection';

const OverallTechnologyNarrativeSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {

      const classes = useSharedLargeCardStyles();
      const [model, setModel] = useState<IApiProject | null>(null);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
  const [sectionKey,setSectionKey] = useState(uuidv4());

      if (model === null) {
        setModel(props.model);
      }

      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          // return await compSave();
          console.log('begin ' + SECTION_ID + ' save');
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);
          try {
            const m = await athenaClient?.patchProject(data);
            setModel(m as IApiProject);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));
      // end useImperativeHandle

      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (props.model === null || model === null) {
        console.log('project is null');
        return <DashItemProgress/>;
      }


      return (
          <React.Fragment>
            {/* id */}
            <DashDetailsField mode="hidden"
                              label="Id"
                              showLabel={false}
                              editMode={props.editMode}
                              name="id"
                              defaultValue={model.id}
            />

            {/* technologyExternalNarrative */}
            <DashDetailsField mode="approval"
                              label=""
                              showLabel={true}
                              width="100%"
                              editMode={props.editMode}
                              name="externalTechnologyNarrative"
                              valueGraph={model}
                              approvalKey="technologyExternalNarrative"
            />

            {/* technologyExternalNarrative */}
            <DashDetailsField mode="richText"
                              label=""
                              showLabel={true}
                              width="100%"
                              editMode={props.editMode}
                              name="technologyExternalNarrative"
                              defaultValue={model.technologyExternalNarrative}
            />

            <DashDivider width="80vw" height="5px"/>

          </React.Fragment>
      );
    });

export default withDashSection('Overall Technology Narrative', SECTION_ID, OverallTechnologyNarrativeSection,
    false, 'technologyExternalNarrative');

