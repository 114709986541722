import { HP_LIGHT_THEME } from 'constants/themes';
import { FC } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';

export type PancakeThemeProviderProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const PancakeThemeProvider: FC<PancakeThemeProviderProps> = ({ children }) => {
  return (
    <StylesProvider>
      <MuiThemeProvider theme={HP_LIGHT_THEME}>
        <CssBaseline />
        <div>{children}</div>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default PancakeThemeProvider;
