import {ReactNode, useEffect, useState} from "react";
import {hasPermission} from "../utils/securityUtil";
import {useGlobalState} from "../../state/useGlobalState";

type AthenaAuthorizeProps = {
  allowedRoles: string;
  separator?: string;
  children?: ReactNode;  // Children can be of any valid React type
}

const AthenaAuthorize: React.FC<AthenaAuthorizeProps> = (props: AthenaAuthorizeProps) => {
  const globalState = useGlobalState();
  const separator = props.separator ?? ',';
  const allowedRoles = props.allowedRoles.split(separator).map(x => x.trim());
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    async function checkRoles(currentRoles: string[]) {
      console.log('!@! perms:', {currentRoles, allowedRoles});
      if (hasPermission(currentRoles, allowedRoles)) {
        setVisible(true);
      }
    }

    checkRoles(globalState.userRoles ?? []);

  }, [allowedRoles]);

  return visible
      ? <>{props.children}</>
      : null;
}

export default AthenaAuthorize;