import {
  IApiPersonHistory,
  IApiPersonReference,
  IApiProjectHistory,
  IApiProjectStakeholder,
  IDegree,
  IDocumentMetadata,
  IImageMetadata,
  IPersonAward,
  IProjectAward,
  ITraining
} from "./apiClients/AthenaClient";
import {friendlyFileSize} from "./fileSizeUtil";
import {getImageNameFromExtension, normalizeFileExtension} from "./fileExtUtil";
import {instanceOf} from "prop-types";
import CoreDate from "../features/core/CoreDate";
import {getPersonImageUrl} from "../constants/metadata/personImages";

// Represents a card containing various pieces of information
export interface IInfoCard {
  title?: string; // The title of the card
  subheader?: string; // A subheader or subtitle
  imageUrl?: string; // URL of the image to be displayed
  fieldAndValues?: IInfoField[]; // Array of field-value pairs
  website?: string; // Website URL associated with the card
  projectId?: string; // Project ID if applicable
  personId?: string; // Person ID if applicable
  district?: string;
  active?: boolean;
}

// Represents a field-value pair in an InfoCard
export interface IInfoField {
  field: string; // Name of the field
  value?: string | number | null; // Value of the field, can be string, number, or null
}


export function convertProjectHistory(projectHistory?: IApiProjectHistory[]): IInfoCard[] {
  if (!projectHistory) return [] as IInfoCard[];
  const result = projectHistory.map(x => {
    return {
      title: x.name,
      subheader: x.personTitleOnProject,
      projectId: x.projectId,
      fieldAndValues: [{field: 'Project Number', value: x.number},
        {field: 'Client', value: x.clientName},
        {field: 'Project Value', value: x.cost},
        {
          field: 'Completion Date',
          value: (x.completionDate) ? new Date(x.completionDate).toLocaleDateString() : ''
        }] as IInfoField[],
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

export function convertAwards(awards?: IPersonAward[]): IInfoCard[] {
  if (!awards) return [] as IInfoCard[];
  const result = awards.map(x => {
    return {
      title: x.title,
      subheader: x.subtitle,
      fieldAndValues: [{field: 'Sponsor', value: x.sponsor},
        {field: 'Date', value: (x.date) ? new Date(x.date).toLocaleDateString() : ''},
        {field: 'Citation', value: x.citation}]
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

export function convertProjectAwards(awards?: IProjectAward[]): IInfoCard[] {
  if (!awards) return [] as IInfoCard[];
  const result = awards.map(x => {
    const c: IInfoCard = {};
    c.title = x.title;
    c.subheader = x.citation;
    c.fieldAndValues = [
      {field: 'Sponsor', value: x.sponsor},
      {field: 'Date', value: (x.date) ? new Date(x.date).toLocaleDateString() : ''},
      {field: 'Category', value: x.category},
    ];
    return c;
  });
  return result;
}


export function convertDegree(degrees?: IDegree[]): IInfoCard[] {
  if (!degrees) return [] as IInfoCard[];
  const result = degrees.map(x => {
    return {
      title: x.school,
      subheader: x.year,
      fieldAndValues: [{field: 'Degree', value: x.degree},
        {field: 'Major', value: x.major}] as IInfoField[],
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

export function convertTraining(training?: ITraining[]): IInfoCard[] {
  if (!training) return [] as IInfoCard[];
  const result = training.map(x => {
    return {
      title: x.course,
      subheader: x.sponsor,
      fieldAndValues: [
        {
          field: 'Start Date',
          value: (x.startDate) ? new Date(x.startDate).toLocaleDateString() : ''
        },
        {
          field: 'End Date',
          value: (x.endDate) ? new Date(x.endDate).toLocaleDateString() : ''
        }]
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

export function convertReferences(personReferences?: IApiPersonReference[]): IInfoCard[] {
  if (!personReferences) return [] as IInfoCard[];
  const result = personReferences.map(x => {
    return {
      title: x.firstName + ' ' + x.lastName,
      subheader: undefined,
      fieldAndValues: [
        {field: 'Company', value: x.company},
        {field: 'Email', value: x.email},
        {field: 'Phone', value: x.businessPhone ?? x.mobilePhone},
        // todo: check comments vs quoteCommendation
        {field: 'Quote Commendation', value: x.quoteCommendation}]
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

export function convertImage(images: IImageMetadata[]): IInfoCard[] {
  if (!images) return [] as IInfoCard[];
  const result = images.map(x => {
    return {
      title: x.name,
      subheader: '',
      imageUrl: x.thumbnailUrl,
      fieldAndValues: [
        {field: 'Date', value: (x.modifiedOn) ? new Date(x.modifiedOn).toLocaleDateString() : ''},
        {field: 'Size', value: friendlyFileSize(x.sizeInBytes)},
        {field: 'Type', value: x.contentType}]
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

export function convertDocument(docs: IDocumentMetadata[]): IInfoCard[] {
  if (!docs) return [] as IInfoCard[];
  const result = docs.map(x => {
    const fileTypeImageUrl = x.contentType ? getImageNameFromExtension(normalizeFileExtension(x.contentType ?? '')) : 'unknown-type.jpg';
    return {
      title: x.name,
      subheader: '',
      imageUrl: fileTypeImageUrl,
      fieldAndValues: [
        {field: 'Date', value: (x.modifiedOn) ? new Date(x.modifiedOn).toLocaleDateString() : ''},
        {field: 'Size', value: friendlyFileSize(x.sizeInBytes)},
        {field: 'Type', value: x.contentType}]
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

export function convertStakeholders(projectStakeholders?: IApiProjectStakeholder[]): IInfoCard[] {
  if (!projectStakeholders) return [] as IInfoCard[];
  const result = projectStakeholders.map(x => {
    return {
      title: x.name,
      subheader: x.role,
      website: x.website ?? undefined,
      fieldAndValues: [
        {field: 'Address', value: x.address},
        {field: 'City', value: x.city},
        {field: 'State', value: x.state},
        {field: 'ZIP', value: x.zip},
        {field: 'Country', value: x.country}
      ]
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}

function getUserNameFromThumbnailURL(url?: string): string {
  // If the input is undefined or null, return an empty string
  if (!url) {
    return '';
  }

  // sample
  // https://graph.microsoft.com/v1.0/users/JBJ@henselphelps.com/photo/$value?user=

  try {
    // Parse the URL and extract the pathname
    const pathname = new URL(url).pathname;
    // Split the pathname into parts using "/" as the delimiter
    const parts = pathname.split('/');

    // Check if there are enough parts to get the 3rd part
    if (parts.length < 4) {
      return '';
    }

    // Get the third part of the path
    const thirdPart = parts[3];
    // Extract the portion of the string before the "@"
    const userName = thirdPart.split('@')[0];

    return userName;
  } catch (error) {
    // If an error occurs (e.g., the URL is invalid), return an empty string
    return '';
  }
}




export function convertPersonHistory(personHistoryArray?: IApiPersonHistory[]): IInfoCard[] {
  if (!personHistoryArray) return [] as IInfoCard[];
  const result = personHistoryArray.map(x => {

    const userName = getUserNameFromThumbnailURL(x.thumbnail);
    const imageUrl = getPersonImageUrl(userName);

    return {
      title: x.name,
      subheader: x.personTitleOnProject,
      district: x.district,
      website: `/people/${x.personId}`,
      imageUrl: imageUrl,
      personId: `${x.personId}`,
      active: !(x.terminationDate instanceof Date),
      fieldAndValues: [
        {field: 'TerminationDate', value: CoreDate.toShortDate(x.terminationDate)},
      ],
    } as IInfoCard
  }) as IInfoCard[];

  return result;
}