import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Avatar from '@material-ui/core/Avatar';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

export type DraggableItem = {
  id: string;
  tag?: string;
  primary: string;
  secondary?: string;
  disallowDrag?: boolean;
};

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)'
  },
  clickable: {
    cursor: 'pointer',
  },
});

export type DraggableListItemProps = {
  item: DraggableItem;
  index: number;
  onRemoveItem: (id: string) => void;
};

const DraggableListItem = ({ item, index, onRemoveItem, disallowDrag }: DraggableListItemProps) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided: any, snapshot: any) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          <ListItemIcon>
            { !disallowDrag &&
              <DragIndicatorIcon />
            }
          </ListItemIcon>
          <ListItemText primary={item.primary} secondary={item.secondary} />
          <ListItemIcon
              className={classes.clickable}
              onClick={(e) => {
            onRemoveItem(item.id); e.preventDefault(); e.stopPropagation();
          }}>
              <RemoveCircleIcon />
          </ListItemIcon>
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
