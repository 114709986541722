import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

interface IClearFacetButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  visible?: boolean;
}

const useStyles = makeStyles(() => ({
  clearFacetButton: {
    width: '26px',
    height: '26px',
    paddingTop: '0px',
    marginRight: '10px',
    backgroundColor: '#564e4e',
    color: '#fff',
    textAlign: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
}));

export default function ClearFacetButton(props: IClearFacetButtonProps) {
  const classes = useStyles();

  if (props.visible === false) {
    return null;
  }

  return (
      <span onClick={props.onClick} className={classes.clearFacetButton}>
      <ClearIcon />
    </span>
  );
}
