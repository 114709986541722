import _ from 'lodash';
import React, { useCallback, useRef, useEffect, useState } from 'react';

interface DebouncedInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  debounceTime?: number;
  onDebouncedChange?: (value: string, target: HTMLInputElement) => void;
  comment?: string;
  value?: string;
}

export default function DebouncedInputComponent(props: DebouncedInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [localValue, setLocalValue] = useState<string | undefined>(props.value);
  //const [isModified, setIsModified] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState<string | undefined>(props.value);
  const debounceTimeout = props.debounceTime || 500;

  console.log('!@#1 props', props);

  const debouncedUpdate = useCallback(_.debounce((value: string | undefined) => {
    //if (isModified) {
      setDebouncedValue(value);
    // } else {
    //   setIsModified(true);
    // }
  }, debounceTimeout), [debounceTimeout]);

  useEffect(() => {
      debouncedUpdate(localValue);
  }, [localValue, debouncedUpdate]);

  useEffect(() => {
    if (props.onDebouncedChange && debouncedValue !== undefined) {
      props.onDebouncedChange(debouncedValue, inputRef.current as HTMLInputElement);
    }
  }, [debouncedValue, props.onDebouncedChange]);

  useEffect(() => {
    setLocalValue(props.value);
    //setDebouncedValue(props.value); // Make sure to keep both values in sync
  }, [props.value]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalValue(e.target.value);
  }

  return (
      <input ref={inputRef} {...props} value={localValue} onChange={(e) => handleChange(e)} />
  );
}
