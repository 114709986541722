import React, { FC, createContext, useState } from 'react';

export interface IDashProviderProps {
  children: React.ReactNode;
}

export interface IDashState {
  displayModeOnSearch: string;
}

// remember to switch back to list if testing with cards
// 'list' is the default
const initialState = {
  displayModeOnSearch: 'list',
} as IDashState;

export const DashInstancesContext = createContext<{
  dashState: IDashState;
  setDashState: React.Dispatch<React.SetStateAction<IDashState>>;
}>({ dashState: initialState,
      setDashState: () => null});

const DashProvider: FC<IDashProviderProps> = (props) => {
  const [dashState, setDashState] = useState<IDashState>(initialState);

  return (
      <DashInstancesContext.Provider value={{dashState, setDashState}}>
        {props.children}
      </DashInstancesContext.Provider>
  );
};

export default DashProvider;
