import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import { Box } from '@material-ui/core';
import AthenaClient, {ApiPerson, IApiPerson, IApiProject} from "services/apiClients/AthenaClient";
import DashDetailsField from "../../DashDetailsField/DashDetailsField";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import {useSharedLargeCardStyles} from "./sharedStyles";
import {IServiceInstances, ServiceInstancesContext} from "providers/ServicesProvider/ServicesProvider";
import PersonDetailService from "services/PersonService";
import {DashItemProgress} from "components/dashCore/DashItemProgress";
import {caseInsensitiveSort} from "utils/stringUtils";
import EntityDialog from "./EntityDialog";
import useSaveCount from "hooks/useSaveCount";
import useAthenaClient from "hooks/useAthenaClient";
import {makeStyles} from "@material-ui/core/styles";

export interface ISectionWithChipListProps {
  title?: string,
  showTitle: boolean,
  editMode: boolean,
  values?: string[],
  choices?: string[],
  model?: IApiPerson | IApiProject,
  fieldName?: string,
  autocomplete?: boolean,
  readOnly?: boolean,
  onValuesChange?: (valueList: string[]) => void;
  width?: string;
}

// const useStyles = makeStyles((theme) => ({
//   redChip: {
//     backgroundColor: theme.palette.primary.main;
//     color: 'white',
//     fontWeight: 'bold',
//   }
// }));

// function caseInsensitiveSort(a: string, b: string) {
//   return a.toLowerCase().localeCompare(b.toLowerCase());
// }

export default function SectionWithChipList(props: ISectionWithChipListProps) {
  //const classes = useStyles();

  const {athenaClient} = useAthenaClient();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [person, setPerson] = useState<IApiPerson | null>(null);
  //const [formModified, setFormModified] = useState(false);
  // dupe line above if ever needed for projects
  const [saveCount, saved] = useSaveCount();
  const [values, setValues] = useState(props.values ?? []);
  const choices = props.choices ?? [];

  const width = props.width ?? '100%';

  const toggleEditMode = () => {
    setEditMode(!editMode);
  }

  useEffect(() => {
    if (athenaClient && props.model && editMode) {
      if ('firstName' in props.model) {
        console.log('@@@firstName exists');
        const personDetailService = new PersonDetailService(athenaClient);
        personDetailService.getPerson(props.model.id as string | number)
            .then((value: IApiPerson) => {
              setPerson(value);
            });
      }
    } else {
      if ('firstName' in props.model) {
        setPerson(props.model);
      }
    }
  }, [saveCount, athenaClient]);

  async function save() {
    if (props.fieldName !== undefined) {
      const m = person as IApiPerson;
      const data: { [key: string]: string[] } = {};
      data[props.fieldName as string] = values as string[];
      const personService = new PersonDetailService(athenaClient as AthenaClient);
      await personService.updatePersonProperty(m.id as string | number, data);
      setEditMode(false);
      setPerson(null);
      saved();
    }
  }

  const handleValuesChange = (valueList: string[]) => {
    setValues(valueList);
  }

  if (person === null) {
    return <DashItemProgress />;
  }

  function SectionWithChipListDialog(props: ISectionWithChipListProps) {
    const person = props.model as IApiPerson;
    return (
        <EntityDialog entityName={'people'}
                      model={person}
                      handleClose={toggleEditMode}
                      handleSave={save}
                      innerComponent={<InnerSectionWithChipList {...props} />}/>
    );
  }

  function InnerSectionWithChipList(props: ISectionWithChipListProps) {
    const sharedClasses = useSharedLargeCardStyles();

    const getSectionButton = () => {
      if (props.readOnly) {
        return <></>;
      } else {
        return (!props.editMode) ?
            <EditIcon onClick={toggleEditMode} className={sharedClasses.editIcon}/> :
            <CloseIcon onClick={toggleEditMode} className={sharedClasses.closeIcon}/>
      }
    }

    return (
        <div>
          <div style={{width: '100%', display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <div style={{flex: 1, color:'#a6192e'}}>
              <h3 className="sectionHeading">{props.title}</h3>
            </div>
            <div className={sharedClasses.iconOuterContainer} style={{alignItems: 'flex-start'}}>
              <div>
                {getSectionButton()}
              </div>
            </div>
          </div>
          <div style={{border: '1px dotted #fff', width: '100%', display: 'flex',
            flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'stretch' }}>
            <DashDetailsField showLabel={true}
                              mode={props.autocomplete ? 'multiAuto': 'multi'}
                              label={''}
                              width={'100%'}
                              editMode={editMode}
                              values={caseInsensitiveSort(values)}
                              choices={choices}
                              onValuesChange={handleValuesChange}/>
          </div>
        </div>
    );
  }

  // lev pattern
  return (editMode) ?
      <SectionWithChipListDialog {...props} editMode={true} /> :
      <InnerSectionWithChipList {...props} /> ;
}

