import {withDashCard} from '../../shared/withDashCard';
import TechnologyNarrativesSection from "./TechnologyNarrativesSection";
import OverallTechnologyNarrativeSection from "./OverallTechnologyNarrativeSection";

const TechnologyNarrativesCard = withDashCard('Technology Narratives', 'technology-narratives-module',
    // list of components to add to the card (in order they should appear)
    [
      TechnologyNarrativesSection,
      OverallTechnologyNarrativeSection,
    ]
,'technologySection');

export default TechnologyNarrativesCard;