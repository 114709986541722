import AthenaClient, {
  ApiProject, IApiProject,
  ApiProjectHistory, DamAssetMetadata, Degree,
  IApiPerson, IApiProjectHistory, PersonAward,
  Training
} from "services/apiClients/AthenaClient";
import {diff} from "deep-object-diff";

export default class PersonService {
  private athenaClient: AthenaClient;

  constructor(athenaClient: AthenaClient) {
    this.athenaClient = athenaClient;
  }

  public async getPerson(id: string | number) {
    const cachedPerson = localStorage.getItem('person:' + id);

    return await this.athenaClient.getPerson(Number(id));
  }

  public async updatePerson(originalPerson: IApiPerson, updatedPerson: IApiPerson | object) {
    //const deep = cloneDeep(updatedPerson) as IApiPerson;
    const d = diff(originalPerson, updatedPerson);
    return await this.athenaClient.createOrUpdatePerson(originalPerson.id as number, d);
  }

  public async updatePersonProperty(personId: string | number, data: object) {
    //const deep = cloneDeep(updatedPerson) as IApiPerson;
    //const d = diff(originalPerson, updatedPerson);
    return await this.athenaClient.createOrUpdatePerson(Number(personId), data);
  }

  //#region CHILD Level

  // public async getDegrees(personId: string | number) {
  //   const result = await this.athenaClient.
  // }


  // ADD
  public async addChild<T, R>(personId: string | number, model: T, entityName: string): Promise<R> {
    // revisit ReturnType, typeof
    if (!entityName) {
      throw Error('entityName must be specified');
    }
    let result: object;
    switch (entityName) {
      case 'projectHistory': {
        result = await this.athenaClient.addPersonToProject(String(personId),
            model as unknown as ApiProjectHistory);
        break;
      }
      case 'training':
        result = await this.athenaClient.createPersonTraining(Number(personId),
            model as unknown as Training);
        break;
      case 'degree':
        result = await this.athenaClient.createDegree(Number(personId),
            model as unknown as Degree);
        break;
      case 'award':
        result = await this.athenaClient.createPersonAward(Number(personId),
          model as unknown as PersonAward);
        break;
      default:
        throw Error('a correct entityName must be specified');
    }
    return result as unknown as R;
  }

  // MODIFY
  public async modifyChild<T, R>(personId: string | number, model: T, entityName: string): Promise<R> {
    // revisit ReturnType, typeof
    console.log('@tmp modifyChild call', {personId, entityName, model});
    if (!entityName) {
      throw Error('entityName must be specified');
    }
    let result: object;
    switch (entityName) {
      case 'projectHistory': {
        result = await this.athenaClient.updatePersonProjectHistory(
            (model as unknown as IApiProjectHistory).projectId as string,
            Number(personId),
            model as unknown as ApiProjectHistory);
        break;
      }
      case 'training':
        result = await this.athenaClient.updateTraining(Number(personId),
            (model as unknown as Training).id as string,
            model as unknown as Training);
        break;
      case 'degree':
        result = await this.athenaClient.updateDegree(Number(personId),
            (model as unknown as Degree).id as string,
            model as unknown as Degree);
        break;
      case 'award':
        result = await this.athenaClient.updateAward(Number(personId),
          (model as unknown as Degree).id as string,
          model as unknown as PersonAward);
        break;
      default:
        throw Error('a correct entityName must be specified');
    }
    return result as unknown as R;
  }

  // DELETE
  public async deleteChild(personId: string | number,
                           assocEntityId: string | number,
                           entityName: string): Promise<object> {
    // revisit ReturnType, typeof
    console.log('deleteChild called with', {personId,assocEntityId,entityName});
    if (!entityName) {
      throw Error('entityName must be specified');
    }
    let result: object;
    switch (entityName) {
      case 'projectHistory': {
        result = await this.athenaClient.deletePersonProjectHistory(
            String(assocEntityId), Number(personId));
        break;
      }
      case 'training':
        result = await this.athenaClient.deleteTraining(Number(personId), String(assocEntityId));
        break;
      case 'degree':
        result = await this.athenaClient.deleteDegree(Number(personId), String(assocEntityId));
        break;
      case 'award':
        result = await this.athenaClient. deleteAward(Number(personId), String(assocEntityId));
        break;
      default:
        throw Error('a correct entityName must be specified');
    }
    return result;
  }

  //#endregion

  //#region Assets

  public async getDamAssets(id: string | number): Promise<DamAssetMetadata[]> {
    const result = await this.athenaClient.getDamAssets('people', String(id));
    return result;
  }

  public async getProject(id: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const result = await this.athenaClient.getProject(id) as object;
    console.log('$L2 getProjectResult', {project:result})
    return result;
  }


  //#endregion


  //
  // public async addProjectHistory(personId: string | number,
  //                                projectId: string,
  //                                personTitleOnProject: string) {
  //   const personIdNumber = Number(personId);
  //   const history = ProjectPersonHistory.fromJS({
  //     person: personIdNumber, projectId, personTitleOnProject
  //   });
  //   return await this.athenaClient.addPersonToProject(projectId, history);
  // }
  //
  // public async deleteProjectHistory(personId: string | number, projectId: string) {
  //   // potential API improvement v3 - ids as strings where possible
  //   return await this.athenaClient.deletePersonProjectHistory(projectId, Number(personId));
  // }
  //
  // public async addTraining() {
  //   return await this.athenaClient.createPersonTraining(Number(personId), Training)
  // }
  //
  // public async updateTraining() {
  //   return await this.athenaClient.updateTraining()
  // }
  //
  // public async deleteTraining() {
  //   return await this.athenaClient.updateTraining()
  //}

  public async getProjectPickerData(search: string) {
    const result = await this.athenaClient.getAllProjectsV2(search, undefined, 0, 20, undefined);
    return result.value;
  }

  public async getContactPickerData(search: string) {
    const result = await this.athenaClient.getAllContacts(search, undefined, 0, 20, undefined);
    return result.value;
  }

}

