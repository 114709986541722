import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from "react";
import {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";

import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {formatInfotip, projectInfotips} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
import PassThru from "../../../../patterns/PassThru";
import globalState from "../../../../app/state/GlobalState";

// end imports

const SECTION_ID = 'narrativeOptionsSection';

const NarrativeOptionsSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
        const classes = useSharedLargeCardStyles();

        const [model, setModel] = useState<IApiProject>(props.model);
        const [saveCount, saved] = useSaveCount();
        const {athenaClient} = useAthenaClient();
        const [showInfotipModal, setShowInfotipModal] = useState(false);
        const [content, setContent] = useState('');
        const [sectionKey, setSectionKey] = useState(uuidv4());


        if (model === null) {
            setModel(props.model);
        }

        // v2
        useImperativeHandle(forwardRef, () => ({
            save: async () => {
                // return await compSave();
                console.log('begin ' + SECTION_ID + ' save');
                const elem = document.querySelector('#' + SECTION_ID);
                const data = getNamedData(elem);
                console.log('namedData was:', data);

                try {
                    const m = await athenaClient?.patchProject(data);
                    const p = m as IApiProject;
                  const tmpHiddenSections = [];
                  if (p.communityEngagementEnabled) tmpHiddenSections.push(4);
                  if (p.smallBusinessParticipationEnabled) tmpHiddenSections.push(2);
                  if (p.workforceDevelopmentEnabled) tmpHiddenSections.push(3);
                  globalState.hiddenDiversitySections = tmpHiddenSections;

                   //'smallBusinessParticipationEnabled'
                   //'workforceDevelopmentEnabled'
                   //'communityEngagementEnabled'
                    setModel(m as IApiProject);
                    props.setEditMode(false);
                    setSectionKey(uuidv4());
                    return true;
                } catch (err) {
                    console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
                    return false;
                }
            }
        }));
        // end useImperativeHandle


        const handleInfotipClick = (key: string) => {
            const infotip = projectInfotips[key];
            setContent(formatInfotip(infotip));
            setShowInfotipModal(true);
        }

        if (!props.model) {
            return <DashItemProgress/>;
        }

        return (
            <React.Fragment key={sectionKey}>
                {/* id */}
                <DashDetailsField mode="hidden"
                                  label="Id"
                                  showLabel={false}
                                  editMode={props.editMode}
                                  name="id"
                                  defaultValue={model.id}
                />

                {/* smallBusinessParticipationEnabled */}
                <DashDetailsField mode="checkSwitch"
                                  label="Small Business Participation"
                                  showLabel={true}
                                  width="47%"
                                  editMode={props.editMode}
                                  name="smallBusinessParticipationEnabled"
                                  infotipKey={'supplierDiversitySmallBusinessParticipationToggle'}
                                  value={model.smallBusinessParticipationEnabled}
                />

                {/* workforceDevelopmentEnabled */}
                <DashDetailsField mode="checkSwitch"
                                  label="Workforce Development"
                                  showLabel={true}
                                  width="47%"
                                  editMode={props.editMode}
                                  name="workforceDevelopmentEnabled"
                                  infotipKey={'supplierDiversityWorkforceDevelopmentToggle'}
                                  value={model.workforceDevelopmentEnabled}
                />

                {/* communityEngagementEnabled */}
                <DashDetailsField mode="checkSwitch"
                                  label="Community Engagement"
                                  showLabel={true}
                                  width="47%"
                                  editMode={props.editMode}
                                  name="communityEngagementEnabled"
                                  infotipKey={'supplierDiversityCommunityEngagementToggle'}
                                  value={PassThru.log('#@! ce', model.communityEngagementEnabled)}
                />

                <DashDivider width="80vw" height="5px"/>

            </React.Fragment>
        );
    });

export default withDashSection('Plans', SECTION_ID, NarrativeOptionsSection,
    false, 'supplierDiversityPlans');
