import {
  IApiProject,
  ProjectChallengeSolution,
  ProjectChallengeSolutionApproval
} from "services/apiClients/AthenaClient";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  GridSize,
  Switch
} from "@material-ui/core";
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import dateFormat from "patterns/dateFormat";
import DataRow from "../../../../patterns/DataRow";
import ProgressButton from "../../../dashCore/ProgressButton";


interface IAddEditCasDialogProps {
  open?: boolean;
  mode?: string | null;
  id?: string;
  model: DataRow<ProjectChallengeSolution>;
  parentModel: IApiProject;
  choices: string[];
  onInfotipClick: (key: string) => void;
  onRequestFromChild: (action: string, payload?: {id: string} | DataRow<ProjectChallengeSolution>) => void;
}

function AddEditCasDialog(props: IAddEditCasDialogProps) {
  const [title, setTitle] = useState(props.model.value.title ?? '');
  const [challenge, setChallenge] = useState(props.model.value.challenge ?? '');
  const [solution, setSolution] = useState(props.model.value.solution ?? '');
  const [approved, setApproved] = useState(props.model.value.approval?.isApproved ?? false);

  //console.log(props);
  function createTextField(name: string, value: string,
                           placeholder?: string,
                           required?: boolean,
                           sizeProps?: { xs?: GridSize, sm?: GridSize, md?: GridSize } | undefined,
                           onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
                           infotipKey?: string) {
    const comp = (
        <Grid item {...sizeProps} style={{margin:'20px 5px'}}>
          <TextField
              name={name}
              value={value}
              placeholder={placeholder}
              required={required}
              onChange={onChange}
              fullWidth/>
        </Grid>);
    //console.log('createTextField', comp);
    return comp;
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = e.target;
    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'challenge':
        setChallenge(value);
        break;
      case 'solution':
        setSolution(value);
        break;
      default:
        break;
    }
  }

  function handleSave() {
    const pcs = new ProjectChallengeSolution();
    if (props.mode === 'edit') {
      pcs.id = props.model.value.id;
      pcs.approval = new ProjectChallengeSolutionApproval();
      pcs.approval.isApproved = approved;
    }
    pcs.title = title;
    pcs.challenge = challenge;
    pcs.solution = solution;
    const dataRow = new DataRow(pcs, props.mode === 'new' ? 'added': 'modified');
    props.onRequestFromChild('process', dataRow);
  }

  const prefix = props.mode === 'new' ? 'Add': 'Edit';

  function handleApprovalChange(e: React.ChangeEvent<HTMLInputElement>) {
    setApproved(e.target.checked);
    console.log(e.target.checked);
  }

  return (
  <Dialog id="addOrEditChallengeSolutionDialog" fullWidth={true} maxWidth={'lg'}
          open={props.open ?? false}>
    <DialogTitle className="bg-black text-white">
      {prefix} Challenge &amp; Solution to {props.parentModel.name}</DialogTitle>
    <DialogContent>
      <div style={{
        border: '1px dotted #eee', width: '100%', display: 'flex',
        flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'stretch'
      }}>
  <Grid container style={{width: 'calc(100% - 40px)'}}>
    {createTextField('title', title, 'Title', true, {xs: 12},
        handleInputChange, 'challengeTitle')}
    {createTextField('challenge', challenge, 'Challenge', true, {xs: 12},
        handleInputChange, 'challenge')}
    {createTextField('solution', solution, 'Solution', true, {xs: 12},
        handleInputChange, 'solution')}
    { props.mode === 'edit' &&
      <><Grid item xs={12}>
          <FormControlLabel label={'Approval'}
          control={<Switch onChange={handleApprovalChange}
                           color={'primary'}
                           checked={approved} />}
          labelPlacement="start" />
        </Grid>
      <>
          <Grid item xs={12}><b>Challenge &amp; Solution Edited by:</b>&nbsp;{props.model.value.modifiedBy}</Grid>
          <Grid item xs={12}><b>Challenge & Solution Edited on:</b>&nbsp;{dateFormat(props.model.value.modifiedOn)}</Grid>
        {(approved && props.model.value.approval?.isApproved === true) && (
                <>
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item xs={12}>
                    <b>Approved By:&nbsp;</b>
                    {[
                      props.model.value.approval?.modifiedBy,
                      '\u00a0' + 'on' + '\u00a0',
                      dateFormat(props.model.value.approval?.modifiedOn),
                    ]}
                  </Grid>
                </>
            )
        }
      </>
      </>
    }
    </Grid>
      </div>
    </DialogContent>
    <DialogActions>
      <div className="flex flex-row" data-example="ability to reverse order later with css">
        <div>
          {/*<Button onClick={handleSave}>Save</Button>*/}
          <ProgressButton onClick={handleSave} text={'Save'} />
        </div>
        <div>
          <Button onClick={() => props.onRequestFromChild('close')}>Cancel</Button>
        </div>
      </div>
    </DialogActions>
  </Dialog>
  );
}

export default AddEditCasDialog;