import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {IApiProject} from "services/apiClients/AthenaClient";
import {makeStyles} from "@material-ui/core/styles";
import {useAppFocusState} from "recoil/appFocus";
import {IAppFocus} from "types/generalTypes";
import DetailSummaryBar from "./shared/DetailSummaryBar";
import useAthenaClient from "../../hooks/useAthenaClient";
import ProjectService from "services/ProjectService";
import DetailSideNavBarEx from "./shared/DetailSideNavBar/DetailSideNavBarEx";
import ClientInformationCard from "./projectViewRelated/ClientInformationCard";
import ServicesCard from "./projectViewRelated/ServicesCard";
import useReferenceDataForProjects from "hooks/useFieldOptions/useReferenceDataForProjects";
import ProjectDetailsCard from "./projectViewRelated/ProjectDetailsCard";
import ScheduleCard from "./projectViewRelated/ScheduleCard";
import ProjectCostCard from "./projectViewRelated/ProjectCostCard";
import ProjectDescriptionsCard from "./projectViewRelated/ProjectDescriptionsCard";
import ProjectAssetCard from "./shared/AssetCard";
import ProjectReferenceCard from "./projectViewRelated/ProjectReferenceCard/ProjectReferenceCard";
import ProjectAwardsCard from "./projectViewRelated/ProjectAwardsCard";
import ProjectStakeholdersCard from "./projectViewRelated/ProjectStakeholdersCard";
import ProjectSustainabilityCard from "./projectViewRelated/ProjectSustainabilityCard";
import TechnologyNarrativesCard from "./projectViewRelated/TechnologyNarrativesCard";
import ProjectChallengesAndSolutionsCard from "./projectViewRelated/ProjectChallengesAndSolutionsCard";
import {ProjectLocationCard} from "./sharedViewRelated/LocationCard";
import ProjectStaffCard from "./projectViewRelated/ProjectStaffCard/ProjectStaffCard";
import PBMTechnologyCard from "./projectViewRelated/PBMTechnologyCard/PBMTechnologyCard";
import SupplierDiversityCard from "./projectViewRelated/SupplierDiversityCard";
import globalState from "../../app/state/GlobalState";
import {useObserver} from "mobx-react";
import ownerState from "../../app/state/OwnerState";

const useStyles = makeStyles(() => ({
  detailsBar: {
    height: '64px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    backgroundColor: 'white',
  },
  detailsBarCellNormal: {
    flexGrow: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    borderRight: '1px solid #333',
    height: '64px',
    padding: '8px 20px',
    '& div:nth-child(1)': {
      color: '#666',
      fontWeight: 'bold',
      fontSize: '10pt',
      textTransform: 'uppercase',
    },
    '& div:nth-child(2)': {
      fontSize: '8pt',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }
  },
  detailsBarCellNormal2: {
    flexGrow: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    minWidth: '220px',
    //padding: '8px 20px',
    '& div:nth-child(1)': {
      color: '#666',
      fontWeight: 'bold',
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    '& div:nth-child(2)': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }
  },
  detailsBarCellEmpty: {
    flexGrow: 1,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    backgroundColor: 'white',
    height: '64px',
    padding: '8px 20px',
  },
  controlBarContainer: {
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    height: "64px"
  },
  controlBar: {
    flexGrow: 1,
    backgroundColor: "white"
  },
  controlCell: {
    display: "inline-block",
    paddingRight: "12px"
  },
  controlCellWithText: {
    display: "inline-block",
    paddingRight: "16px",
    fontSize: '10pt',
    color: '#999',
    textTransform: 'none',
    fontWeight: 'normal',
  },
  detailView: {
    position: 'fixed',
    left: 0,
    width: '100%',
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'row',
  },
  detailViewLeftColumn: {
    width: '62px',
    height: '100%',
    overflow: 'hidden',
    borderRight: '1px solid #999',
  },
  // container for summary bar and main detail content
  detailViewRightColumn: {
    flex: 1,
    left: 0,
    top: 200,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px'
  },
  // first part of detailViewRightColumn
  detailSummaryBarRow: {
    position: 'absolute',
    top: 0,
    left: '62px',
    width: 'calc(100% - 62px)',
    borderBottom: '3px solid #999',
  },
  // second part of detailViewRightColumn
  detailViewContentRow: {
    position: 'fixed',
    flex: 1,
    width: 'calc(100% - 64px)',
    top: '132px',
    height: 'calc(100% - 132px)',
    overflowY: 'auto',
  },
  defaultCard: {
    textAlign: 'left',
  },
}))

const SOLO_MODE_ON = false;

export default function ProjectView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const {id} = useParams();
  if (!id) {
    const newPath = location.pathname.slice(0, -1);
    navigate(newPath);
  }
  const [, {setAppFocusState}] = useAppFocusState();
  const [project, setProject] = useState<IApiProject>();
  const {entityName} = useParams();
  const {athenaClient} = useAthenaClient();
  const referenceData = useReferenceDataForProjects();
  const projectId = String(id ?? 0);

  useEffect(() => {
    if (athenaClient && projectId) {
      const personService = new ProjectService(athenaClient);
      personService.getProject(projectId)
          .then((p: IApiProject) => {
            setProject(p);
            globalState.setCurrentProject(p);
            ownerState.checkForOwner(p.stakeholders ?? []);
            const tmpHiddenSections = [];
            if (p.communityEngagementEnabled) tmpHiddenSections.push(4);
            if (p.smallBusinessParticipationEnabled) tmpHiddenSections.push(2);
            if (p.workforceDevelopmentEnabled) tmpHiddenSections.push(3);
            globalState.hiddenDiversitySections = tmpHiddenSections;

            //console.log('globalState.currentProject', globalState.currentProject);
            setAppFocusState({entityName: entityName, entityId: projectId, entityDisplayName: p.name} as IAppFocus);
          });
    } else {
      console.log('$$$pid projectId was 0 / false');
    }
  }, [athenaClient]);

  const summaryBarOn = true;

  if (project && project?.id) {
    return (
        <div className={classes.detailView}>
          <div className={classes.detailViewLeftColumn}>
            <DetailSideNavBarEx entityName={'projects'}
                                primaryImageUrl={project.primaryImageUrl}
                                primaryThumbnail={project.thumbnail}
                                name={project.name}
                                id={project?.id?.toString() ?? ''}
            />
          </div>
          <div className={classes.detailViewRightColumn}>
            {summaryBarOn &&
                <div className={classes.detailSummaryBarRow}>
                  {project && project.id &&
                      <DetailSummaryBar model={project} entityName={'project'}/>
                  }
                </div>
            }

            <div id="detail-container" className={classes.detailViewContentRow}>
              {SOLO_MODE_ON && <>
                {/*<ProjectDetailsCard model={project} referenceData={referenceData}/>*/}
                {/*<ScheduleCard model={project} referenceData={referenceData}/>*/}
                <ProjectAwardsCard model={project}/>
                {/*<ProjectReferenceCard model={project}/>*/}
              </>
              }
              {!SOLO_MODE_ON && <>
                <ProjectLocationCard model={project} referenceData={referenceData}/>
                <ClientInformationCard model={project} referenceData={referenceData}/>
                <ProjectDetailsCard model={project} referenceData={referenceData}/>
                <ProjectReferenceCard model={project}/>
                <ProjectStakeholdersCard model={project} referenceData={referenceData}/>
                <ScheduleCard model={project} referenceData={referenceData}/>
                <ProjectCostCard model={project} referenceData={referenceData}/>
                <ProjectStaffCard model={project} referenceData={referenceData}/>
                <ProjectAwardsCard model={project}/>
                <ProjectDescriptionsCard model={project} referenceData={referenceData}/>
                <ProjectChallengesAndSolutionsCard model={project} referenceData={referenceData}/>
                <TechnologyNarrativesCard model={project} referenceData={referenceData}/>
                <PBMTechnologyCard model={project} referenceData={referenceData}/>
                <ProjectSustainabilityCard model={project} referenceData={referenceData}/>
                <SupplierDiversityCard model={project} referenceData={referenceData}/>
                <ServicesCard model={project} referenceData={referenceData}/>
                <ProjectAssetCard model={project} entityName={'projects'}/>
              </>
              }
            </div>
          </div>
        </div>
    );
  } else {
    return (
        <div>loading details ...</div>
    );
  }
}
