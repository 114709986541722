import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import AddEditPBMTechDialog from "./AddEditPBMTechDialog";
import AthenaClient, {
  ApiPhaseVdcTechnology,
  ApiProject,
  IApiProjectTechnologyPhase, IApiProjectVdcTechnologyPhase,
  IApiVdcTechnology
} from "services/apiClients/AthenaClient";
import useAthenaClient from "hooks/useAthenaClient";
import {ApiProjectVdcTechnologyPhase, ApiVdcTechnology, IApiPhaseVdcTechnology} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import {
  Column,
  ColumnDirective, ColumnsDirective,
  CommandColumn,
  Edit,
  Filter, FilterSettingsModel,
  GridComponent,
  Inject, Resize,
  Sort,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import {DashGridConfig} from "patterns/DashGridConfig";
import DataRow from "patterns/DataRow";
import DashIconButton from "../../../dashCore/DashIconButton";
import EditIcon from "@material-ui/icons/Edit";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import {AddCircleOutline, EditOutlined, FileCopyOutlined, Visibility, VisibilityOutlined} from "@material-ui/icons";
import PreviewVdcTechDialog from "./PreviewVdcTechDialog";
import {useDashResolverContext} from "providers/DashResolver/DashResolver";
import _ from "lodash";
import AthenaAuthorize from "../../../../app/security/ui/AthenaAuthorize";


interface IVdcPhaseBlock {
  model: DataRow<ApiProjectVdcTechnologyPhase>;
  sendActionToParent: (action: string, payload?: unknown | null) => void;
}

function VdcPhaseBlock(props: IVdcPhaseBlock) {
  const {athenaClient, athenaClientRef} = useAthenaClient();
  const [dialogMode, setDialogMode] = useState<string | null>();
  const [model, setModel] = useState<ApiProjectVdcTechnologyPhase>(props.model.value);
  const [projectId, setProjectId] = useState<string>(props.model.tag as string);
  const [dialogTechModel, setDialogTechModel] = useState<IApiPhaseVdcTechnology | Record<string, unknown> | null>(null);
  const dashResolverContext = useDashResolverContext();

  function handleDialogAction(action: string, payload?: IApiVdcTechnology | string | null | Record<string, unknown>) {
    console.log(`handleDialogAction: ${action}`, payload);
    switch (action) {
      case 'close':
        setDialogMode(null);
        break;
      case 'refresh':
        setTimeout(() => {
          dashResolverContext.forceReset();
          // athenaClient?.getProject(projectId).then(project => {
          //   const projectVdcTechnologyPhaseId = props.model.value.id;
          //   const techPhases = project.vdcTechnologyPhases ?? [];
          //   const phase = techPhases.find(x => x.id === projectVdcTechnologyPhaseId);
          //   setModel(phase as ApiProjectVdcTechnologyPhase);
        },300);
        break;
      case 'show':
        setDialogTechModel(payload as IApiVdcTechnology);
        setDialogMode('show');

        break;
      case 'new':
        setDialogMode('new');
        break;
      case 'edit':
        console.log('!@! what was the payload', payload);
        setDialogTechModel(payload as Record<string, unknown>);
        setDialogMode('edit');
        break;
      case 'copy':
        if (payload) {
          const tmpPayload = _.cloneDeep(payload) as Record<string, unknown>;
          delete tmpPayload['id'];
          setDialogTechModel(tmpPayload as Record<string, unknown>);
          setDialogMode('edit');
        }
        break;
      case 'create':
        changeSingleItemInVdcTechPhase(payload as IApiProjectVdcTechnologyPhase).then(() => {
          setDialogMode(null);
          handleDialogAction('refresh');
        });
        break;
      case 'update':
        changeSingleItemInVdcTechPhase(payload as IApiProjectVdcTechnologyPhase).then(() => {
          setDialogMode(null);
          handleDialogAction('refresh');
        });
        break;
      case 'delete':
        changeSingleItemInVdcTechPhase(payload as IApiProjectVdcTechnologyPhase).then(() => {
          setDialogMode(null);
          handleDialogAction('refresh');
        });
        break;
    }
  }

  async function changeSingleItemInVdcTechPhase(changedItem: IApiPhaseVdcTechnology) {
    //const project = await athenaClientRef.current?.getProject(projectId) as ApiProject;
    const phase = props.model.value;
    //const phases = project.vdcTechnologyPhases ?? [];
    //const phase = phases.find(ph => ph.id === phaseId) as ApiProjectVdcTechnologyPhase;
    if (phase) {
      if (changedItem.id && changedItem.path === '[delete]') {
        phase.phaseVdcTechnologies = (phase.phaseVdcTechnologies ?? []).filter(
            tech => tech.id !== changedItem.id
        );
      } else {
        // Add or update item
        if (!changedItem.id) {
          phase.phaseVdcTechnologies = [
            ...(phase.phaseVdcTechnologies ?? []),
            changedItem as ApiPhaseVdcTechnology,
          ];
        } else {
          phase.phaseVdcTechnologies = (phase.phaseVdcTechnologies ?? []).map(
              tech => tech.id === changedItem.id ? changedItem as ApiPhaseVdcTechnology : tech
          );
        }
      }

      const tmpPhase = Object.assign({}, phase);
      // todo: can the API just ignore certain key/values on PUT/"PATCH" process
      delete tmpPhase['phaseLabel'];
      // todo: side effect of this block saving method is child "technology" items get new GUIDs on save.
      // re-assigns there identity for no reason??
      //console.log('instance test', tmpPhase instanceof ApiProjectVdcTechnologyPhase)
      const result = await athenaClientRef.current?.updateProjectVdcTechnologyPhase(tmpPhase,
                                                      phase.id as string,
          props.model?.value.projectId as string);
      // todo: fix in API the response of this is not OpenAPI standard in attribute
      console.log(result);
    }

  }
  //phase.phaseVdcTechnologies
  function renderActionButtons(record: Record<string,unknown> | ApiPhaseVdcTechnology) {
    return (<div key={Math.random().toString()}>
      <AthenaAuthorize allowedRoles={'VDC_Contributor'}>
        <DashIconButton size="small"
                        onClick={() => handleDialogAction('show', record)}><VisibilityOutlined />
        </DashIconButton>
        <DashIconButton size="small"
                        onClick={() => handleDialogAction('edit', record)}><EditOutlined />
        </DashIconButton>
        <DashIconButton size="small"
                        onClick={() => handleDialogAction('copy', record)}><FileCopyOutlined />
        </DashIconButton>
        <DashIconButton size="small"
                        onClick={() => {
                          const tmpRecord = _.cloneDeep(record);
                          tmpRecord['path'] = '[delete]';
                          handleDialogAction('delete', tmpRecord);
                        }}><RemoveCircleOutline/>
        </DashIconButton>
      </AthenaAuthorize>

    </div>);
  }

  const columns: Partial<Column>[] = [
    <ColumnDirective field='name' headerText='PBM Technology Name' width='140' />,
    <ColumnDirective field='workHours' headerText='Work Hours' width='120' />,
    // <ColumnDirective field='duration' headerText='Duration (Days)' width='140' />,
    // <ColumnDirective field='cost' headerText='Cost' width='100' format='C2' />,
    // <ColumnDirective field='numPeople' headerText='Number of People' width='140' />,
    <ColumnDirective headerText='SME' width='140' template={(r: ApiPhaseVdcTechnology) => {
          return <>{r.smeFirstName} {r.smeLastName}</>
        }} />,
    <ColumnDirective field='smeCompany' headerText='SME Company' width='140' />,
    <ColumnDirective field='companyCategory' headerText='Company Category' width='80' />,
    <ColumnDirective headerText="Actions" width={140}
              template={(r: ApiPhaseVdcTechnology) => renderActionButtons(r)}
              editTemplate={(r: ApiPhaseVdcTechnology) => renderActionButtons(r)}
      />
  ];

  const gridConfig = new DashGridConfig();

  const filterOptions:  FilterSettingsModel = {
    ignoreAccent: true,
  }
  const gridServices = [Toolbar, Sort, Edit, Filter, CommandColumn, Resize];

  return (
      <>
      <div style={{ width: '100%' }}>
        {/*<Button variant="contained" onClick={() => setDialogMode('new')}>*/}
        {/*  Add New Technology*/}
        {/*</Button>*/}
        <AthenaAuthorize allowedRoles={'VDC_Contributor'}>
          <Button startIcon={<AddCircleOutline />}
                  onClick={() => setDialogMode('new')}>Add New Technology
          </Button>
        </AthenaAuthorize>
      </div>
        <br />
        <div>
          <GridComponent
              dataSource={props.model.value.phaseVdcTechnologies ?? []}
              statelessTemplates={['directiveTemplates']}
              allowSorting={true}
              allowFiltering={false}
              allowResizing={true}
              //columns={columns}
              gridLines={'Both'}
              filterSettings={filterOptions}
              allowExcelExport={false}>
              {/*//actionComplete={handleActionComplete}>*/}
            <Inject services={gridServices} />
            <ColumnsDirective>
              {columns}
            </ColumnsDirective>
          </GridComponent>
        </div>

        { dialogMode && dialogMode === 'new' &&
          <AddEditPBMTechDialog dialogMode={dialogMode}
                            onAction={handleDialogAction} />
        }
        { dialogMode && dialogMode === 'edit' &&
            <AddEditPBMTechDialog dialogMode={dialogMode} model={dialogTechModel}
                                  onAction={handleDialogAction} />
        }
        {
          dialogMode && (dialogMode === 'show') &&
            <PreviewVdcTechDialog dialogMode={dialogMode} model={dialogTechModel}
                                  onAction={handleDialogAction} />
        }
        </>
  );
}

export default VdcPhaseBlock;
