import AthenaClient, {
  IQueryablePerson,
  SearchResultSetOfQueryablePerson,
  SearchResultSetOfQueryableProject
} from "../services/apiClients/AthenaClient";
import _ from "lodash";

type CacheEntry = {
  data: SearchResultSetOfQueryablePerson | SearchResultSetOfQueryableProject;
}

export default class FacetCache {
  private cache: Map<string, CacheEntry>;
  private athenaClient: AthenaClient;

  constructor(athenaClient: AthenaClient) {
    this.athenaClient = athenaClient;
    console.log('athenaClient in cache', {athenaClient});
    this.cache = new Map<string, CacheEntry>();
  }

  public getPeopleFacets(search: string | null | undefined, filter: string | null | undefined): Promise<SearchResultSetOfQueryablePerson> {
    if (!search) search = '';
    if (!filter) filter = '';
    const key = this.makeKey('people', search, filter);
    const existingEntry = this.cache.get(key);
    console.log('!@# inside getPeopleFacets', key);

    if (existingEntry) {
      console.log('!@# cache hit', key);
      return Promise.resolve(existingEntry.data as SearchResultSetOfQueryablePerson);
    } else {
      console.log('!@# cache miss', key);
      return this.getJson(key).then(data => {
        this.cache.set(key, {data: data as SearchResultSetOfQueryablePerson});
        const timeoutId = setTimeout(() => {
          this.cache.delete(key);
          _.noop(timeoutId);
        }, 60 * 1000); // 1 minute
        this.cache.set(key, { data: data as SearchResultSetOfQueryablePerson });
        return data as unknown as SearchResultSetOfQueryablePerson;
      });
    }
  }

  public getProjectFacets(search: string | null | undefined, filter: string | null | undefined): Promise<SearchResultSetOfQueryableProject> {
    if (!search) search = '';
    if (!filter) filter = '';
    const key = this.makeKey('projects', search, filter);
    const existingEntry = this.cache.get(key);

    if (existingEntry) {
      return Promise.resolve(existingEntry.data as SearchResultSetOfQueryableProject);
    } else {
      return this.getJson(key).then(data => {
        this.cache.set(key, {data: data as SearchResultSetOfQueryableProject});
        const timeoutId = setTimeout(() => {
          this.cache.delete(key);
          _.noop(timeoutId);
        }, 5000); // 5 seconds
        this.cache.set(key, { data: data as SearchResultSetOfQueryableProject });
        return data as unknown as SearchResultSetOfQueryableProject;
      });
    }
  }

  private getJson(key: string): Promise<unknown> {
    const [entityName,search,filter] = key.split('::');

    if (entityName === 'people') {
      return this.athenaClient.getAllPeople(search, filter,0,0, undefined)
          .then(data => {
            return data;
          })
          .catch(error => {
            console.error("Error getting people data: ", error);
            throw error;
          });
    } else {
      return this.athenaClient.getAllProjectsV2(search, filter,0,0, undefined)
          .then(data => {
            return data;
          })
          .catch(error => {
            console.error("Error getting projects data: ", error);
            throw error;
          });
    }
  }


  private makeKey(...args: Array<string | null | undefined>): string {
    return args.map(arg => arg ?? '').join('::');
  }

}
