interface HelpChild {
  id: string;
  label: string;
  templateUrl: string;
}

interface HelpSection {
  id: string;
  label: string;
  children: HelpChild[];
}

interface HelpRoot {
  defaultUrl: string;
  sections: HelpSection[];
}

const dashHelpConfig: HelpRoot = {
  "defaultUrl": "/dash-help/DetailsView/general.html",
  "sections": [
    {
      "id": "searchResults",
      "label": "Search Results",
      "children": [
        {
          "id": "general",
          "label": "Navigation",
          "templateUrl": "/dash-help/SearchResults/general.html"
        },
        {
          "id": "statistics",
          "label": "Statistics",
          "templateUrl": "/dash-help/SearchResults/statistics.html"
        },
        {
          "id": "filteringFacets",
          "label": "Filtering",
          "templateUrl": "/dash-help/SearchResults/filteringFacets.html"
        },
        {
          "id": "sorting",
          "label": "Sorting",
          "templateUrl": "/dash-help/SearchResults/sorting.html"
        },
        {
          "id": "changingColumns",
          "label": "Changing columns",
          "templateUrl": "/dash-help/SearchResults/changingColumns.html"
        },
        {
          "id": "quickExport",
          "label": "Quick export",
          "templateUrl": "/dash-help/SearchResults/quickExport.html"
        }
      ]
    },
    {
      "id": "exportWizard",
      "label": "Export Wizard",
      "children": [
        {
          "id": "createTemplate",
          "label": "Creating templates",
          "templateUrl": "/dash-help/ExportWizard/createTemplate.html"
        },
        {
          "id": "editTemplate",
          "label": "Editing templates",
          "templateUrl": "/dash-help/ExportWizard/editTemplate.html"
        },
        {
          "id": "runTemplate",
          "label": "Exporting templates",
          "templateUrl": "/dash-help/ExportWizard/runTemplate.html"
        },
        {
          "id": "deleteTemplate",
          "label": "Deleting templates",
          "templateUrl": "/dash-help/ExportWizard/deleteTemplate.html"
        }
      ]
    },
    {
      "id": "detailsView",
      "label": "Project and People Details",
      "children": [
        {
          "id": "general",
          "label": "General",
          "templateUrl": "/dash-help/DetailsView/general.html"
        },
        {
          "id": "primaryImage",
          "label": "Primary Image",
          "templateUrl": "/dash-help/DetailsView/primaryImage.html"
        },
        {
          "id": "editing",
          "label": "Editing",
          "templateUrl": "/dash-help/DetailsView/editing.html"
        },
        {
          "id": "relatedData",
          "label": "Related Data",
          "templateUrl": "/dash-help/DetailsView/relatedData.html"
        },
        {
          "id": "exporting",
          "label": "Exporting",
          "templateUrl": "/dash-help/DetailsView/exporting.html"
        }
      ]
    },
    {
      "id": "printWizard",
      "label": "Printing Resumes & Data Sheets",
      "children": [
        {
          "id": "printSummary",
          "label": "Print Summary",
          "templateUrl": "/dash-help/PrintWizard/summary.html"
        },
        {
          "id": "printDetails",
          "label": "Print Details",
          "templateUrl": "/dash-help/PrintWizard/details.html"
        },
        {
          "id": "printWizard",
          "label": "Print Wizard",
          "templateUrl": "/dash-help/PrintWizard/wizard.html"
        },
        {
          "id": "printSettings",
          "label": "Recommended Print Settings",
          "templateUrl": "/dash-help/PrintWizard/settings.html"
        }
      ]
    },
    {
      "id": "securityRoles",
      "label": "Security Roles",
      "children": [
        {
          "id": "dashRoles",
          "label": "DASH Roles",
          "templateUrl": "/dash-help/SecurityRoles/dashRoles.html"
        }
      ]
    },
    {
      "id": "dataQuality",
      "label": "Data Quality Dashboard",
      "children": [
        {
          "id": "gettingThere",
          "label": "Getting There",
          "templateUrl": "/dash-help/DataQuality/gettingThere.html"
        },
        {
          "id": "overview",
          "label": "Overview",
          "templateUrl": "/dash-help/DataQuality/overview.html"
        },
        {
          "id": "understandingClean",
          "label": "Understanding \"% Clean\"",
          "templateUrl": "/dash-help/DataQuality/understandingClean.html"
        },
        {
          "id": "filtering",
          "label": "Filtering",
          "templateUrl": "/dash-help/DataQuality/filtering.html"
        },
        {
          "id": "sorting",
          "label": "Sorting",
          "templateUrl": "/dash-help/DataQuality/sorting.html"
        },
        {
          "id": "dataRefresh",
          "label": "Data Refresh",
          "templateUrl": "/dash-help/DataQuality/dataRefresh.html"
        }
      ]
    },
    {
      "id": "powerApps",
      "label": "Power Apps",
      "children": [
        {
          "id": "marketingForm",
          "label": "Marketing Form",
          "templateUrl": "/dash-help/PowerApps/marketingForm.html"
        }
      ]
    },
    {
      "id": "whatsNew",
      "label": "What's New",
      "children": [
        // {
        //   "id": "dashUpdates",
        //   "label": "Check for Updates",
        //   "templateUrl": "/dash-help/WhatsNew/dashUpdates.html"
        // },
        // {
        //   "id": "changeLog",
        //   "label": "Recent Updates",
        //   "templateUrl": "/dash-help/WhatsNew/changeLog.html"
        // },
        // {
        //   "id": "backlog",
        //   "label": "Backlog",
        //   "templateUrl": "/dash-help/WhatsNew/backlog.html"
        // }
      ]
    },
    {
      "id": "contactUs",
      "label": "Contact Us",
      "children": [
        {
          "id": "serviceNow",
          "label": "Get Help or Submit Feedback",
          "templateUrl": "/dash-help/ContactUs/serviceNow.html"
        }
      ]
    }
  ]
}

export default dashHelpConfig;