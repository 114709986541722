import _ from 'lodash'
// eslint-disable-next-line @typescript-eslint/ban-types
import { IExportSchema, ITemplate } from 'services/apiClients/AthenaClient'

export type SimpleDict = { [key: string]: unknown | null | undefined }

// util
export function firstOrValue(val: SimpleDict | SimpleDict[]) {
  if (_.isArray(val)) {
    return _.head(val)
  } else {
    return val
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function getDefaultPersonTemplate(): SimpleDict[] | null {
  console.log('hello')
  // eslint-disable-next-line @typescript-eslint/ban-types
  const templateDoc: SimpleDict = {
    id: 'e1478015-94d9-4571-9607-2353baaf766b',
    owner: 'MJL4@henselphelps.com',
    entity: 'people',
    target: 'exports',
    name: 'First People Export',
    description: 'First Export Template for Person',
    createdDate: '2022-12-08T16:53:59.0439247+00:00',
    modifiedDate: null,
    requiredRoles: [],
    sharedWith: [],
    messages: [],
    schema: {
      label: 'People',
      type: 'array',
      propertyName: null,
      referenceProperty: null,
      referencePropertyLabel: null,
      items: {
        label: 'Person',
        type: 'object',
        propertyName: null,
        referenceProperty: 'name',
        referencePropertyLabel: 'Person',
        items: null,
        properties: [
          {
            label: 'ProjectHistory',
            type: 'array',
            propertyName: 'projectHistory',
            referenceProperty: null,
            referencePropertyLabel: null,
            items: {
              label: 'Project',
              type: 'object',
              propertyName: null,
              referenceProperty: null,
              referencePropertyLabel: null,
              items: null,
              properties: [
                {
                  label: 'Name',
                  type: 'string',
                  propertyName: 'name',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Number',
                  type: 'string',
                  propertyName: 'number',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'City',
                  type: 'string',
                  propertyName: 'city',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'State',
                  type: 'string',
                  propertyName: 'state',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Cost',
                  type: 'int64',
                  propertyName: 'cost',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SquareFootage',
                  type: 'int64',
                  propertyName: 'squareFootage',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'VerticalMarket',
                  type: 'string',
                  propertyName: 'verticalMarket',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ClientName',
                  type: 'string',
                  propertyName: 'clientName',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DeliveryMethod',
                  type: 'string',
                  propertyName: 'deliveryMethod',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'CompletionDate',
                  type: 'date',
                  propertyName: 'completionDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ResumeDescription',
                  type: 'string',
                  propertyName: 'resumeDescription',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SecondaryCategories',
                  type: 'string',
                  propertyName: 'secondaryCategories',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ConstructionTypes',
                  type: 'string',
                  propertyName: 'constructionTypes',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'District',
                  type: 'string',
                  propertyName: 'district',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DisplayName',
                  type: 'string',
                  propertyName: 'displayName',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Zip',
                  type: 'string',
                  propertyName: 'zip',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ClientType',
                  type: 'string',
                  propertyName: 'clientType',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ContractType',
                  type: 'string',
                  propertyName: 'contractType',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ProcurementMethod',
                  type: 'string',
                  propertyName: 'procurementMethod',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ScheduledCompletionDate',
                  type: 'date',
                  propertyName: 'scheduledCompletionDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'StartDate',
                  type: 'date',
                  propertyName: 'startDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'StreetAddress',
                  type: 'string',
                  propertyName: 'streetAddress',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Private',
                  type: 'bool',
                  propertyName: 'private',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Active',
                  type: 'bool',
                  propertyName: 'active',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Usable',
                  type: 'bool',
                  propertyName: 'usable',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Auto Update Contract Value',
                  type: 'bool',
                  propertyName: 'autoUpdateContractValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Leed',
                  type: 'bool',
                  propertyName: 'leed',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ClientLocation',
                  type: 'string',
                  propertyName: 'clientLocation',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'RfiClosedCount',
                  type: 'int32',
                  propertyName: 'rfiClosedCount',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'RfiPendingCount',
                  type: 'int32',
                  propertyName: 'rfiPendingCount',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SmallBusinessStory',
                  type: 'string',
                  propertyName: 'smallBusinessStory',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DoNotUse',
                  type: 'bool',
                  propertyName: 'doNotUse',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'HbcuMinorityGoalsAchieved',
                  type: 'double',
                  propertyName: 'hbcuMinorityGoalsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'HbcuMinorityGoalsPercent',
                  type: 'double',
                  propertyName: 'hbcuMinorityGoalsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'HubZoneSmallBusinessGoalsAchieved',
                  type: 'double',
                  propertyName: 'hubZoneSmallBusinessGoalsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'HubZoneSmallBusinessGoalsPercent',
                  type: 'double',
                  propertyName: 'hubZoneSmallBusinessGoalsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'LargeBusinessConcernsAchieved',
                  type: 'double',
                  propertyName: 'largeBusinessConcernsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DavSmallBusinessGoalsPercent',
                  type: 'double',
                  propertyName: 'davSmallBusinessGoalsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SmallBusinessConcernsAchieved',
                  type: 'double',
                  propertyName: 'smallBusinessConcernsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SmallBusinessConcernsPercent',
                  type: 'double',
                  propertyName: 'smallBusinessConcernsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SmallDisadvantagedBusinessGoalsAchieved',
                  type: 'double',
                  propertyName: 'smallDisadvantagedBusinessGoalsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SmallDisadvantagedBusinessGoalsPercent',
                  type: 'double',
                  propertyName: 'smallDisadvantagedBusinessGoalsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'VeteranOwnedSmallBusinessGoalsAchieved',
                  type: 'double',
                  propertyName: 'veteranOwnedSmallBusinessGoalsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'VeteranOwnedSmallBusinessGoalsPercent',
                  type: 'double',
                  propertyName: 'veteranOwnedSmallBusinessGoalsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'WomenOwnedSmallBusinessGoalsAchieved',
                  type: 'double',
                  propertyName: 'womenOwnedSmallBusinessGoalsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'WomenOwnedSmallBusinessGoalsPercent',
                  type: 'double',
                  propertyName: 'womenOwnedSmallBusinessGoalsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'MinorityOwnedSmallBusinessGoalsAchieved',
                  type: 'double',
                  propertyName: 'minorityOwnedSmallBusinessGoalsAchieved',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'MinorityOwnedSmallBusinessGoalsPercent',
                  type: 'double',
                  propertyName: 'minorityOwnedSmallBusinessGoalsPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'JointVenture',
                  type: 'bool',
                  propertyName: 'jointVenture',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DesignAspectsDescription',
                  type: 'string',
                  propertyName: 'designAspectsDescription',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Notes',
                  type: 'string',
                  propertyName: 'notes',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Kudos',
                  type: 'string',
                  propertyName: 'kudosNotes',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SignedRelease',
                  type: 'boolean',
                  propertyName: 'signedRelease',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SpecialProjectsDescription',
                  type: 'string',
                  propertyName: 'specialProjectsDescription',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DesignPreconScheduledStartDate',
                  type: 'date',
                  propertyName: 'designPreconScheduledStartDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DesignPreconActualStartDate',
                  type: 'date',
                  propertyName: 'designPreconActualStartDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DesignPreconScheduledCompletionDate',
                  type: 'date',
                  propertyName: 'designPreconScheduledCompletionDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DesignPreconActualCompletionDate',
                  type: 'date',
                  propertyName: 'designPreconActualCompletionDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ConstructionScheduledStartDate',
                  type: 'date',
                  propertyName: 'constructionScheduledStartDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ConstructionActualStartDate',
                  type: 'date',
                  propertyName: 'constructionActualStartDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ConstructionScheduledSubstantialCompletionDate',
                  type: 'date',
                  propertyName:
                    'constructionScheduledSubstantialCompletionDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ConstructionModifiedSubstantialCompletionDate',
                  type: 'date',
                  propertyName: 'constructionModifiedSubstantialCompletionDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ConstructionActualSubstantialCompletionDate',
                  type: 'date',
                  propertyName: 'constructionActualSubstantialCompletionDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ScheduleDifferenceReasons',
                  type: 'string',
                  propertyName: 'scheduleDifferenceReasons',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PreconInContract',
                  type: 'string',
                  propertyName: 'preconInContract',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PreconCost',
                  type: 'int64',
                  propertyName: 'preconCost',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PreconCostModified',
                  type: 'int64',
                  propertyName: 'preconCostModified',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'OriginalConstructionContractValue',
                  type: 'int64',
                  propertyName: 'originalConstructionContractValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'FinalConstructionContractValue',
                  type: 'int64',
                  propertyName: 'finalConstructionContractValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Confidential',
                  type: 'bool',
                  propertyName: 'confidential',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SafetyManHoursWorked',
                  type: 'int64',
                  propertyName: 'safetyManHoursWorked',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SafetyEmr',
                  type: 'string',
                  propertyName: 'safetyEmr',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'NumLostTimeAccidents',
                  type: 'int32',
                  propertyName: 'numLostTimeAccidents',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'NumOshaRecordables',
                  type: 'int32',
                  propertyName: 'numOshaRecordables',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DartRate',
                  type: 'double',
                  propertyName: 'dartRate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SelfWorkNotes',
                  type: 'string',
                  propertyName: 'selfWorkNotes',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SelfWorkPercent',
                  type: 'double',
                  propertyName: 'selfWorkPercent',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SelfWorkType',
                  type: 'string',
                  propertyName: 'selfWorkType',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'LeedStatus',
                  type: 'string',
                  propertyName: 'leedStatus',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'LeedVersion',
                  type: 'string',
                  propertyName: 'leedVersion',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'LeedRating',
                  type: 'string',
                  propertyName: 'leedRating',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'FinalCparsRating',
                  type: 'string',
                  propertyName: 'finalCparsRating',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ValueEngineering',
                  type: 'string',
                  propertyName: 'valueEngineering',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Quality',
                  type: 'string',
                  propertyName: 'quality',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'BimUsage',
                  type: 'string',
                  propertyName: 'bimUsage',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'CollaborativeEfforts',
                  type: 'string',
                  propertyName: 'collaborativeEfforts',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PreconEfforts',
                  type: 'string',
                  propertyName: 'preconEfforts',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'CommissioningEfforts',
                  type: 'string',
                  propertyName: 'commissioningEfforts',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'LessonsLearned',
                  type: 'string',
                  propertyName: 'lessonsLearned',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ProjectInnovations',
                  type: 'string',
                  propertyName: 'projectInnovations',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ProposalDate',
                  type: 'date',
                  propertyName: 'proposalDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'AwardDate',
                  type: 'date',
                  propertyName: 'awardDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'StandardChangeOrderCount',
                  type: 'int32',
                  propertyName: 'standardChangeOrderCount',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'StandardChangeOrderValue',
                  type: 'int64',
                  propertyName: 'standardChangeOrderValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'GmpChangeOrderCount',
                  type: 'int32',
                  propertyName: 'gmpChangeOrderCount',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'GmpChangeOrderValue',
                  type: 'int64',
                  propertyName: 'gmpChangeOrderValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PreconChangeOrderCount',
                  type: 'int32',
                  propertyName: 'preconChangeOrderCount',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PreconChangeOrderValue',
                  type: 'int64',
                  propertyName: 'preconChangeOrderValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'TotalChangeOrderValue',
                  type: 'int64',
                  propertyName: 'totalChangeOrderValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'TotalChangeOrderCount',
                  type: 'int32',
                  propertyName: 'totalChangeOrderCount',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ChangeOrderReasons',
                  type: 'string',
                  propertyName: 'changeOrderReasons',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'WarrantyExpirationDate',
                  type: 'date',
                  propertyName: 'warrantyExpirationDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'OwnerTotalDevelopmentCost',
                  type: 'int64',
                  propertyName: 'ownerTotalDevelopmentCost',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PrimaryImageUrl',
                  type: 'string',
                  propertyName: 'primaryImageUrl',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'OverallSiteAcreage',
                  type: 'double',
                  propertyName: 'overallSiteAcreage',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'NumFloorsBelowGrade',
                  type: 'int32',
                  propertyName: 'numFloorsBelowGrade',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'NumOnGradeParkingSpaces',
                  type: 'int32',
                  propertyName: 'numOnGradeParkingSpaces',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'CentralUtilityPlantSquareFootage',
                  type: 'int32',
                  propertyName: 'centralUtilityPlantSquareFootage',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'DataCenterTierLevel',
                  type: 'string',
                  propertyName: 'dataCenterTierLevel',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'TotalBuildMegawatts',
                  type: 'double',
                  propertyName: 'totalBuildMegawatts',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ScifSquareFootage',
                  type: 'int32',
                  propertyName: 'scifSquareFootage',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'AtfpLevel',
                  type: 'string',
                  propertyName: 'atfpLevel',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SafetyNarrative',
                  type: 'string',
                  propertyName: 'safetyNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Community',
                  type: 'string',
                  propertyName: 'community',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Quotes',
                  type: 'string',
                  propertyName: 'quotes',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SecurityNarrative',
                  type: 'string',
                  propertyName: 'securityNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ProgramContractValue',
                  type: 'int64',
                  propertyName: 'programContractValue',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ProgramSquareFootage',
                  type: 'int64',
                  propertyName: 'programSquareFootage',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ProgramDescription',
                  type: 'string',
                  propertyName: 'programDescription',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ProgramName',
                  type: 'string',
                  propertyName: 'programName',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'TechnologyExternalNarrative',
                  type: 'string',
                  propertyName: 'technologyExternalNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'TechnologyInternalNarrative',
                  type: 'string',
                  propertyName: 'technologyInternalNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SustainabilityNarrative',
                  type: 'string',
                  propertyName: 'sustainabilityNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SmallBusinessParticipationNarrative',
                  type: 'string',
                  propertyName: 'smallBusinessParticipationNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SmallBusinessParticipationEnabled',
                  type: 'bool',
                  propertyName: 'smallBusinessParticipationEnabled',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'CommunityEngagementNarrative',
                  type: 'string',
                  propertyName: 'communityEngagementNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'CommunityEngagementEnabled',
                  type: 'bool',
                  propertyName: 'communityEngagementEnabled',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'WorkforceDevelopmentNarrative',
                  type: 'string',
                  propertyName: 'workforceDevelopmentNarrative',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'WorkforceDevelopmentEnabled',
                  type: 'bool',
                  propertyName: 'workforceDevelopmentEnabled',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'SupplierDiversityNotes',
                  type: 'string',
                  propertyName: 'supplierDiversityNotes',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'FinalPaymentDate',
                  type: 'date',
                  propertyName: 'finalPaymentDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ServicesFteCount',
                  type: 'double',
                  propertyName: 'servicesFteCount',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ServicesBusinessUnit',
                  type: 'string',
                  propertyName: 'servicesBusinessUnit',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'GroundbreakingDate',
                  type: 'date',
                  propertyName: 'groundbreakingDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ToppingOutDate',
                  type: 'date',
                  propertyName: 'toppingOutDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'BuildingEnclosedDate',
                  type: 'date',
                  propertyName: 'buildingEnclosedDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'RibbonCuttingDate',
                  type: 'date',
                  propertyName: 'ribbonCuttingDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'LinearFeet',
                  type: 'int64',
                  propertyName: 'linearFeet',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'MillionGallonsPerDay',
                  type: 'double',
                  propertyName: 'millionGallonsPerDay',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'IncludeSquareFootageInProgram',
                  type: 'bool',
                  propertyName: 'includeSquareFootageInProgram',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'IncludeContractValueInProgram',
                  type: 'bool',
                  propertyName: 'includeContractValueInProgram',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PersonId',
                  type: 'int32',
                  propertyName: 'personId',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'PersonTitleOnProject',
                  type: 'string',
                  propertyName: 'personTitleOnProject',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedOn',
                  type: 'date',
                  propertyName: 'modifiedOn',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedBy',
                  type: 'string',
                  propertyName: 'modifiedBy',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
              ],
            },
            properties: null,
          },
          {
            label: 'PersonDegrees',
            type: 'array',
            propertyName: 'degrees',
            referenceProperty: null,
            referencePropertyLabel: null,
            items: {
              label: 'Degree',
              type: 'object',
              propertyName: null,
              referenceProperty: null,
              referencePropertyLabel: null,
              items: null,
              properties: [
                {
                  label: 'Degree',
                  type: 'string',
                  propertyName: 'degree',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Major',
                  type: 'string',
                  propertyName: 'major',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'School',
                  type: 'string',
                  propertyName: 'school',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedBy',
                  type: 'string',
                  propertyName: 'modifiedBy',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Year',
                  type: 'int32',
                  propertyName: 'year',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedOn',
                  type: 'date',
                  propertyName: 'modifiedOn',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
              ],
            },
            properties: null,
          },
          {
            label: 'PersonAwards',
            type: 'array',
            propertyName: 'awards',
            referenceProperty: null,
            referencePropertyLabel: null,
            items: {
              label: 'Award',
              type: 'object',
              propertyName: null,
              referenceProperty: null,
              referencePropertyLabel: null,
              items: null,
              properties: [
                {
                  label: 'Title',
                  type: 'string',
                  propertyName: 'title',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Citation',
                  type: 'string',
                  propertyName: 'citation',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Sponsor',
                  type: 'string',
                  propertyName: 'sponsor',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Subtitle',
                  type: 'string',
                  propertyName: 'subtitle',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedBy',
                  type: 'string',
                  propertyName: 'modifiedBy',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Date',
                  type: 'date',
                  propertyName: 'date',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedOn',
                  type: 'date',
                  propertyName: 'modifiedOn',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
              ],
            },
            properties: null,
          },
          {
            label: 'Trainings',
            type: 'array',
            propertyName: 'trainings',
            referenceProperty: null,
            referencePropertyLabel: null,
            items: {
              label: 'Training',
              type: 'object',
              propertyName: null,
              referenceProperty: null,
              referencePropertyLabel: null,
              items: null,
              properties: [
                {
                  label: 'Course',
                  type: 'string',
                  propertyName: 'course',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Goals',
                  type: 'string',
                  propertyName: 'goals',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Sponsor',
                  type: 'string',
                  propertyName: 'sponsor',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedBy',
                  type: 'string',
                  propertyName: 'modifiedBy',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'StartDate',
                  type: 'date',
                  propertyName: 'startDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'EndDate',
                  type: 'date',
                  propertyName: 'endDate',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ModifiedOn',
                  type: 'date',
                  propertyName: 'modifiedOn',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
              ],
            },
            properties: null,
          },
          {
            label: 'PersonReferences',
            type: 'array',
            propertyName: 'references',
            referenceProperty: null,
            referencePropertyLabel: null,
            items: {
              label: 'Reference',
              type: 'object',
              propertyName: null,
              referenceProperty: null,
              referencePropertyLabel: null,
              items: null,
              properties: [
                {
                  label: 'FirstName',
                  type: 'string',
                  propertyName: 'firstName',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'LastName',
                  type: 'string',
                  propertyName: 'lastName',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Company',
                  type: 'string',
                  propertyName: 'company',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'ReferenceType',
                  type: 'string',
                  propertyName: 'referenceType',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'Email',
                  type: 'string',
                  propertyName: 'email',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'BusinessPhone',
                  type: 'string',
                  propertyName: 'businessPhone',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
                {
                  label: 'QuoteCommendation',
                  type: 'string',
                  propertyName: 'quoteCommendation',
                  referenceProperty: null,
                  referencePropertyLabel: null,
                  items: null,
                  properties: null,
                },
              ],
            },
            properties: null,
          },
          {
            label: 'Accreditations',
            type: 'array',
            propertyName: 'accreditations',
            referenceProperty: null,
            referencePropertyLabel: null,
            items: {
              label: 'Accreditations',
              type: 'string',
              propertyName: null,
              referenceProperty: null,
              referencePropertyLabel: null,
              items: null,
              properties: null,
            },
            properties: null,
          },
        ],
      },
      properties: null,
    },
  }

  // eslint-disable-next-line prefer-const
  const schemaObj = _.cloneDeep([templateDoc.schema]) as SimpleDict[]
  const schema = addGuidsAndChildren(schemaObj)
  schema.forEach((nd) => removeProps(nd, ['guid', 'label', 'things']))
  // eslint-disable-next-line @typescript-eslint/ban-types
  const result = schema as SimpleDict[]
  console.log('result', result)
  return result
}

export function convertTemplateIntoSimpleDict(inputTemplate: ITemplate) {
  const templateObj = _.cloneDeep(inputTemplate)
  // let schema = [templateObj.schema] as unknown as SimpleDict[];
  // schema = addGuidsAndAddChildren(schema);
  // //schema.forEach((nd) => removeProps(nd, ['guid', 'label', 'things']));
  // const result = schema as SimpleDict[];
  // //console.log('result', result);
  return convertSchemaIntoSimpleDict([templateObj.schema] as IExportSchema[])
}

export function convertSchemaIntoSimpleDict(
  inputSchema: IExportSchema | IExportSchema[],
) {
  const inputSchemas = Array.isArray(inputSchema) ? inputSchema : [inputSchema]
  const schemaObj = _.cloneDeep(inputSchemas) as SimpleDict[]
  const schema = addGuidsAndChildren(schemaObj)
  const result = schema as SimpleDict[]
  return result
}

function addGuidsAndChildren(nodes: SimpleDict[]): SimpleDict[] {
  return nodes.map((node) => {
    if (!node) {
      return node
    }

    let newChildren: SimpleDict[] | undefined
    let isPrimitive: boolean

    if (node.items) {
      // items is a single object
      newChildren = addGuidsAndChildren([{ ...(node.items as SimpleDict) }])
      node.items = [] //null; // setting items to null
      isPrimitive = false
    }
    if (node.properties) {
      // properties is an array
      newChildren = addGuidsAndChildren(
        (node.properties as SimpleDict[]).map((item) => ({ ...item })),
      )
      node.properties = null // setting properties to null
      isPrimitive = true
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newNode: SimpleDict = {
      ...node,
      guid: newGuid(),
      isPrimitive: isPrimitive,
    }

    if (newChildren !== undefined) {
      newNode.children = newChildren
    }

    return newNode
  })
}

export function reverseGuidsAndChildren(nodes: SimpleDict[]): SimpleDict[] {
  const removableKvpList = ['guid', 'children', 'isPrimitive', 'expanded'] // Added 'expanded' to the list

  return nodes.map((node) => {
    if (!node) {
      return node
    }

    let newItems: SimpleDict | undefined
    let newProperties: SimpleDict[] | undefined

    if (node.children && node.isPrimitive === false) {
      // children should be moved back to items
      newItems = reverseGuidsAndChildren(node.children)[0]
    }
    if (node.children && node.isPrimitive === true) {
      // children should be moved back to properties
      newProperties = reverseGuidsAndChildren(node.children)
    }

    // Build the new node, excluding the keys from the removableKvpList
    const newNode: SimpleDict = Object.keys(node).reduce((result, key) => {
      if (!removableKvpList.includes(key)) {
        result[key] = node[key]
      }
      return result
    }, {} as SimpleDict)

    if (newItems !== undefined) {
      newNode.items = newItems
    }

    if (newProperties !== undefined) {
      newNode.properties = newProperties
    }

    return newNode
  })
}

function removeProps(node: SimpleDict, keepTheseKeys: string[]) {
  _.noop(node, keepTheseKeys)
  // Object.keys(node ?? []).forEach(key => {
  //     if (!keepTheseKeys.includes(key)) {
  //         delete node[key];
  //     }
  // });
  //
  // if (node['things'] !== undefined && node['things'] !== null) {
  //     if (node['things'] instanceof Array) {
  //         for (const thing of node['things']) {
  //             removeProps(thing, keepTheseKeys);
  //         }
  //     } else {
  //         //console.log('about to make array', node['things']);
  //         removeProps(node['things'] as SimpleDict, keepTheseKeys);
  //         //node['things'] = JSON.parse(JSON.stringify(node['things'])) as SimpleDict[];
  //         node['things'] = _.cloneDeep(node['things']) as SimpleDict[];
  //     }
  // }
}

function newGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function other() {
  return [
    {
      label: 'People',
      guid: '3d1d0355-89fb-4a54-8d67-b11b4de60a02',
      expanded: true,
      things: [
        {
          label: 'Person',
          guid: '30712fb6-ec93-4f28-8cd8-33c4838169c2',
        },
      ],
    },
  ]
}
