import { SnackbarProvider } from 'notistack';
import { FC } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  success: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem',
  },
  error: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem',
  },
  warning: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem',
  },
  info: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem',
  },
}));

export type ToastProviderProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={2}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
