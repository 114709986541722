import { useRef, useEffect } from 'react';

//type AddFunction = (item: unknown) => void;

const useTracingList = (prefix: string, active?: boolean): { append: (item: unknown) => void, list: unknown[] } => {
  const title = prefix.trim();
  const tracingListRef = useRef<unknown[]>([title]);

  //console.log(`Hook initialized with title: ${title}`);


  const append = (item: unknown) => {
    tracingListRef.current.push(item);
    if (active === undefined || active === true) {
      console.log(...tracingListRef.current);
    }
  };

  return { append, list: tracingListRef.current };
};

export default useTracingList;
