export interface ISearchableFacetItem {
    // built-in properties from the server API definition
    count: number;
    value: string;    
    // updated properties specific to the autocomplete facet
    display: string;
    displayWithCount?: string;
    searchValues: string[];
}

export function orderByDisplay(items: ISearchableFacetItem[]) {
    return items.sort((a: { display: string }, b: { display: string }) => {
        return a.display.toLowerCase().localeCompare(b.display.toLowerCase());
    });
}

// may need this at later date - save until 1/2024 #mjl
export function orderByCount(items: ISearchableFacetItem[], asc = true) {
    return (asc) ?
        items.sort((a: {count:number}, b: {count:number}) => {
            return a.count - b.count;
        }) :
        items.sort((a: {count:number}, b: {count:number}) => {
            return b.count - a.count;
        });
}