import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { IApiPerson, IApiProject } from "services/apiClients/AthenaClient";
import { makeStyles } from "@material-ui/core/styles";
import { useAppFocusState } from "recoil/appFocus";
import { IAppFocus } from "types/generalTypes";
import DetailSummaryBar from "./shared/DetailSummaryBar";
import useAthenaClient from "../../hooks/useAthenaClient";
import DetailSideNavBarEx from "./shared/DetailSideNavBar/DetailSideNavBarEx";
import useReferenceDataForProjects, {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
import {PersonLocationCard} from "./sharedViewRelated/LocationCard";
import PersonService from "../../services/PersonService";
import DetailsCard from "./personViewRelated/DetailsCard";
import ProjectHistoryCard from "./personViewRelated/ProjectHistoryCard";
import EducationAndTrainingCard from "./personViewRelated/EducationAndTrainingCard";
import PersonAwardsCard from "./personViewRelated/PersonAwardsCard";
import PersonReferenceCard from "./personViewRelated/PersonReferenceCard";
import PersonAssetCard from "./personViewRelated/PersonAssetCard";
import {CircularProgress} from "@material-ui/core";
import {useGlobalState} from "../../app/state/useGlobalState";

const useStyles = makeStyles(() => ({
  detailsBar: {
    height: '64px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    backgroundColor: 'white',
  },
  detailsBarCellNormal: {
    flexGrow: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    borderRight: '1px solid #333',
    height: '64px',
    padding: '8px 20px',
    '& div:nth-child(1)': {
      color: '#666',
      fontWeight: 'bold',
      fontSize: '10pt',
      textTransform: 'uppercase',
    },
    '& div:nth-child(2)': {
      fontSize: '8pt',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }
  },
  detailsBarCellNormal2: {
    flexGrow: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    minWidth: '220px',
    //padding: '8px 20px',
    '& div:nth-child(1)': {
      color: '#666',
      fontWeight: 'bold',
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    '& div:nth-child(2)': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }
  },
  detailsBarCellEmpty: {
    flexGrow: 1,
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'block',
    backgroundColor: 'white',
    height: '64px',
    padding: '8px 20px',
  },
  controlBarContainer: {
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    height: "64px"
  },
  controlBar: {
    flexGrow: 1,
    backgroundColor: "white"
  },
  controlCell: {
    display: "inline-block",
    paddingRight: "12px"
  },
  controlCellWithText: {
    display: "inline-block",
    paddingRight: "16px",
    fontSize: '10pt',
    color: '#999',
    textTransform: 'none',
    fontWeight: 'normal',
  },
  detailView: {
    position: 'fixed',
    left: 0,
    width: '100%',
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'row',
  },
  detailViewLeftColumn: {
    width: '62px',
    height: '100%',
    overflow: 'hidden',
    borderRight: '1px solid #999',
  },
  // container for summary bar and main detail content
  detailViewRightColumn: {
    flex: 1,
    //position: 'absolute',
    left: 0,
    top: 200,
    //width: 'calc(100% - 1px)',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px'
  },
  // first part of detailViewRightColumn
  detailSummaryBarRow: {
    position: 'absolute',
    top: 0,
    left: '62px',
    width: 'calc(100% - 62px)',
    borderBottom: '3px solid #999',
  },
  // second part of detailViewRightColumn
  detailViewContentRow: {
    position: 'fixed',
    flex: 1,
    width: 'calc(100% - 64px)',
    top: '132px',
    height: 'calc(100% - 132px)',
    overflowY: 'auto',
  },
  defaultCard: {
    textAlign: 'left',
  },
}))

const MODULES_ON = true;

export default function PersonView() {
  //const peopleHome = { search: '?status=oneof."Active"', replace: true};
  const classes = useStyles();
  const globalState = useGlobalState();

  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate('/people');
  }
  const [, { setAppFocusState }] = useAppFocusState();
  const [person, setPerson] = useState<IApiPerson>();
  const { entityName } = useParams();
  const { athenaClient } = useAthenaClient();
  const referenceData = useReferenceDataForProjects();

  console.log(id);
  const modelId = String(id ?? 0);

  useEffect(() => {
    console.log('details page', { athenaClient, modelId });

    if (athenaClient && modelId) {
      const personService = new PersonService(athenaClient);
      personService.getPerson(modelId)
          .then((value: IApiPerson) => {
            setPerson(value);
            globalState.setCurrentPerson(value);
            setAppFocusState({ entityName: entityName, entityId: modelId, entityDisplayName: value.name } as IAppFocus);
          });
    } else {
      console.log('personId was 0 / false');
    }
  }, [athenaClient]);

  const summaryBarOn = true;

  if (person?.id) {
    console.log('person - userName', {userName: person.userName});
    return (
        <div className={classes.detailView}>
          <div className={classes.detailViewLeftColumn}>
            <DetailSideNavBarEx entityName={'people'}
                                primaryImageUrl={person.primaryImageUrl}
                                id={person?.id?.toString() ?? ''}
                                userName={person?.userName}
                                primaryThumbnail={person?.thumbnail}
                                name={person?.name}
            />
          </div>
          <div className={classes.detailViewRightColumn}>
            {summaryBarOn &&
                <div className={classes.detailSummaryBarRow}>
                  {person && person.id &&
                    <DetailSummaryBar model={person} entityName={'people'} />
                  }
                </div>
            }

            <div id="detail-container" className={classes.detailViewContentRow}>
              {/*<div style={{wordWrap: 'break-word', width: '400px'}}>*/}
              {/*{ scrollTest && generateRandomString(5000) }*/}
              {/*</div>*/}

              {!MODULES_ON && <>
                  {/*<LocationCard model={person} />*/}
                  {/*<ClientInformationCard model={person} referenceData={referenceData} />*/}
                {/*<ProjectDetailsCard model={project} referenceData={referenceData} />*/}
                    <ProjectHistoryCard person={person}/>
                  </>
              }

              {MODULES_ON && <>
                  <DetailsCard person={person}/>
                  <PersonLocationCard model={person} referenceData={{} as IReferenceData} />
                  <ProjectHistoryCard person={person}/>
                  <EducationAndTrainingCard person={person}/>
                  {/*todo: convert Date back to Year in the grid with solution from Syncfusion*/}
                  <PersonAwardsCard person={person}/>

                  <PersonReferenceCard person={person}/>
                  <PersonAssetCard person={person} />
              </>
              }
            </div>

          </div>
        </div>
    );
  } else {
    return (
        <div><CircularProgress /></div>
    );
  }
}
