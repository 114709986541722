import React, {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import useAthenaClient from "hooks/useAthenaClient";
import {IApiProject} from "services/apiClients/AthenaClient";
import CoreDebug from "features/core/CoreDebug";
import {useNavigate} from "react-router-dom";
import ProgressButton from "../dashCore/ProgressButton"; // update this path to the correct one

const ProjectDeletionDialog = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const {athenaClient} = useAthenaClient();
  const divRef = useRef<HTMLDivElement>(null);
  const projectNumberInputRef = useRef<HTMLInputElement>(null);
  const [project, setProject] = useState<IApiProject | null>(null);
  const navigate = useNavigate();
  const [reset,setReset] = useState(false);

  const loadProject = async (projectId: string) => {
    const project = await athenaClient.getProject(projectId);
    setProject(project);
    setDialogOpen(true);
  };

  const startDeleteProject = (projectId: string) => {
    loadProject(projectId);
  };

  const handleDelete = () => {
    if (project && project.id && inputValue === project.name) {
      console.log('project and input value match');
      if (athenaClient) {
        console.log('athenaClient is not null');
        athenaClient.deleteProject(project.id)
            .then(() => {
              console.log('project deleted');
              setDialogOpen(false);
              setTimeout(() => {
                alert(`${project.name} has been deleted. You'll be redirected to the Project Search screen after hitting OK`);
                navigate('/projects');
              },100);
            });
      }
    } else {
      alert('The project name provided does not match.');
      setReset(true);
    }
  };

  useEffect(() => {
    CoreDebug.publishFunc('startDeleteProject', startDeleteProject);
    // loadProject();
  }, [athenaClient]);

  function handleReset() {
    setReset(false);
  }

  return (
      <div ref={divRef}>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="project-deletion-dialog-title">
          <DialogTitle id="project-deletion-dialog-title"
                       className="w-full text-white bg-black"
          >Project Deletion</DialogTitle>

          <DialogContent style={{textAlign: 'center'}}>
            <p>
              Performing this action will permanently delete this project and any links to the related data.
            </p>
            <p>
              Please type the name of the project to delete.
            </p>

            <input type="hidden" value="" ref={projectNumberInputRef}/>
            <TextField
                required
                placeholder="Project Name"
                value={inputValue}
                className="w-full"
                onChange={(e) => setInputValue(e.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <div className="flex flex-row" data-example="ability to reverse order later with css">
              <div>
                <ProgressButton onClick={() => handleDelete()} text={'Delete'}
                    reset={reset} onReset={handleReset}/>
              </div>
              <div>
                <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default ProjectDeletionDialog;
