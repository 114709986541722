import React from 'react';
import {TableCell, TableHead, TableRow} from '@material-ui/core';
import InfotipButton from "../../../../InfotipButton/InfotipButton";
import {h} from "app/reactUtils";

interface GoalsTableHeadProps {
    editMode: boolean;
}

const GoalsTableHead: React.FC<GoalsTableHeadProps> = ({editMode}) => {
    const [classificationWidth, setClassificationWidth] = React.useState('22%');
    const [lastColumnWidth, setLastColumnWidth] = React.useState('22%');

    React.useEffect(() => {
        // todo: figure out if I need to preserve this - does nothing now
        // do I still have any use for adjusting width here
        if (editMode) {
            setClassificationWidth('22%');
            //setLastColumnWidth('27%');
        } else {
            setClassificationWidth('22%');
            //setLastColumnWidth('22%');
        }
    }, [editMode]);

    function mkTableCell(width: string, textHeading: string, infotipKey: string) {
        return h(TableCell, {style: {width: width}},
            textHeading,
            h(InfotipButton, {infotipKey: infotipKey})
        );
    }

    const range = _.range(0, 7);

    const headers = [
        "Classification",
        "In Contract?",
        "Goal ($)",
        "Achieved ($)",
        "Goal (%)",
        "Achieved (%)",
        "Percentage Classification"
    ];

    const widths = [
        classificationWidth, // 22%
        "10%",
        "20%",
        "8%",
        "10%",
        "10%",
        "20%"
    ];

    const infotipKeys = [
        "supplierDiversityGoalClassifications",
        "supplierDiversityInContract",
        "supplierDiversityGoalDollars",
        "supplierDiversityAchievedDollars",
        "supplierDiversityGoalPercent",
        "supplierDiversityAchievedPercent",
        "supplierDiversityPercentContext"
    ];

    return (
        <TableHead>
            <TableRow>
                {range.map((i) =>
                    <>{mkTableCell(widths[i], headers[i], infotipKeys[i])}</>
                )}
            </TableRow>
        </TableHead>
    );
};

export default GoalsTableHead;
