import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface BackButtonProps {
  visible?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({ visible }) => {
  const navigate = useNavigate();

  const handleBackClick = () => navigate(-1);

  if (visible === false) return null;

  return (
      <div>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon className={'text-white'} />
        </IconButton>
      </div>
  );
};

export default BackButton;
