import React from 'react';
import {
  TreeViewComponent,
  NodeCheckEventArgs,
  DragAndDropEventArgs,
} from '@syncfusion/ej2-react-navigations';
import {enableRipple} from '@syncfusion/ej2-base';
import noop from "utils/noop";
import {getDefaultPersonTemplate} from 'features/export/defaultTemplateFuncs';

//enableRipple(true);

interface IExportDetailDialogProps {
  entityName: string;
}

export default function ExportDetailDialog2(props: IExportDetailDialogProps) {

  noop(props);


  const template = getDefaultPersonTemplate();
  console.log('template', template);


  // eslint-disable-next-line @typescript-eslint/ban-types
  //const dataMapping: object = { dataSource: template, id:'guid', text: 'label', child: 'things'};

    return (
        <textarea>
            {JSON.stringify(template,null,4)}
        </textarea>
    )
}