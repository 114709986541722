import { useState } from 'react';

export class Guid {
  public static newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
  }
}

export function useGuid() {
  const [myGuid, setMyGuid] = useState<string>(Guid.newGuid());

  const newGuid = (): string => {
    const guid = Guid.newGuid();
    setMyGuid(guid);
    return guid;
  };

  return [myGuid, newGuid] as const;
}