import React from 'react';
import {Chip, ChipProps, Tooltip} from '@material-ui/core';

interface ITaggedChipProps extends ChipProps {
  tag?: unknown | null;
  onDelete?: (tag: unknown) => void;
  showTooltip?: boolean;
}

function TaggedChip(props: ITaggedChipProps) {
  const { tag, showTooltip, onDelete, ...other } = props;

  let tmpChip = (
      <Chip
          {...other}
          style={{ maxWidth: '210px', margin: '3px' }}
          onDelete={() => onDelete && onDelete(tag)}
      />
  );

  if (showTooltip) {
    tmpChip = (
        <Tooltip title={props.label || ''} placement="top">
          {tmpChip}
        </Tooltip>
    );
  }

  return tmpChip;
}

export default TaggedChip;
