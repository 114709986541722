import { VariantType } from 'notistack';

class DashToaster {
  private displayToast: (message: string, type: VariantType, Action?: any, dismissible?: boolean) => void;

  constructor(displayToast: (message: string, type: VariantType, Action?: any, dismissible?: boolean) => void) {
    this.displayToast = displayToast;
  }

  display(completeMessage: string) {
    this.displayToast(completeMessage, 'default');
  }

  displaySavedToast(title: string, itemType?: string) {
    if (itemType) {
      this.displayToast(`The "${title}" ${itemType} was saved successfully.`, 'default');
    } else {
      this.displayToast(`The "${title}" was saved successfully.`, 'default');
    }
  }

  /**
   * Displays a notification about a successful update.
   *
   * @param title - The title of the item being updated.
   * @param useQuotes - Determines whether to include quotes around the title in the message. Default is false (no quotes).
   * @param useSavedWording - Controls whether to use "saved" or "updated" wording in the toast message. Default is false (use "updated").
   */
  displayUpdatedToast(title: string, useQuotes = false, useSavedWording = false) {
    const term = useSavedWording ? 'saved': 'updated';
    // term may change but store action in tracking as an update
    const msg = useQuotes
        ? `The "${title}" was ${term} successfully.`
        : `The ${title} was ${term} successfully.`;
    this.displayToast(msg, 'default');
  }

  displayDeletedToast(title: string) {
    this.displayToast(`The "${title}" was deleted successfully.`, 'default');
  }

  displayLinkedToast(title: string, entityTitle: string) {
    this.displayToast(`The "${title}" has been linked with ${entityTitle} successfully.`, 'default');
  }

  displayUnlinkedToast(title: string) {
    this.displayToast(`The "${title}" was unlinked successfully.`, 'default');
  }
}

export default DashToaster;