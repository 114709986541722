import _ from "lodash";

export function multiReplace(str: string, findArray: string[], replaceArray: string[]): string {
  if (findArray.length !== replaceArray.length) {
    throw new Error("Find and replace arrays must have the same length.");
  }

  for (let i = 0; i < findArray.length; i++) {
    str = str.split(findArray[i]).join(replaceArray[i]);
  }

  return str;
}

export function createUrlVariants(url: string): string[] {
  const prefixes = ['https://dev-', 'https://qa-', 'https://prod-'];
  const regex = /^(https:\/\/(dev-|qa-|prod-))/;

  if (!regex.test(url)) {
    //throw new Error("URL must start with 'https://dev-', 'https://qa-', or 'https://prod-'.");
    _.noop();
  }

  const urlVariants = prefixes.map(prefix => {
    if (url.startsWith(prefix)) return url;
    return url.replace(regex, prefix);
  });

  return urlVariants;
}

function appendURLs(baseURL: string, urls: string[]): string {
  const jsonString = JSON.stringify(urls);
  const encodedString = encodeURIComponent(jsonString);
  const newURL = `${baseURL}?urls=${encodedString}`;
  return newURL;
}

