import {Column, ColumnDirective, EditSettingsModel, SortSettingsModel} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardList, ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {IApiPersonHistory, IApiPersonReference, IApiProjectHistory} from "services/apiClients/AthenaClient";
import {GreenCheck, RedX} from "components/dashCore/customIcons";
import _ from "lodash";
import {hasPermission} from "../../../../app/security/utils/securityUtil";


export function getProjectHistoryConfig(userRoles: string[]) {
  const columns = [
      {
        headerText: 'Name',
        field: 'name',
        allowSorting: true,
        allowEditing: false,
        template: (data: IApiProjectHistory) => {
          return (<a href={`${window.location.protocol}//${window.location.host}/projects/${data.projectId}`} target="_blank">{data.name}</a>);
        }
      } as unknown as Partial<Column>,
      {
        headerText: 'Title on Project',
        field: 'personTitleOnProject',
        //width: 100,
        editable: true,
        allowSorting: true,
        allowEditing: true,
      } as Partial<Column>,
      {
        headerText: 'Client Name',
        field: 'clientName',
        //width: 100,
        allowSorting: true,
        allowEditing: false,
      } as Partial<Column>,
      {
        headerText: 'Delivery Method',
        field: 'deliveryMethod',
        allowSorting: true,
        allowEditing: false,
      } as Partial<Column>,
      {
        headerText: 'Cost',
        field: 'cost',
        //width: 75,
        format: 'C0',
        allowSorting: true,
        allowEditing: false,
      } as Partial<Column>,
      {
        headerText: 'FT²',
        field: 'squareFootage',
        //width: 75,
        format: 'N',
        allowSorting: true,
        allowEditing: false,
      } as Partial<Column>,
      {
        headerText: 'Completion Date',
        field: 'completionDate',
        //width: 75,
        format: 'yMd',
        allowSorting: true,
        allowEditing: false,
      } as Partial<Column>,
      {
        headerText: 'Hensel Phelps Project',
        template: (data: IApiProjectHistory) => {
          return data.nonHenselPhelpsProject ? <RedX />: <GreenCheck />;
        },
      } as unknown as Partial<Column>,
    ] as Column[];

  const exportColumns = _.cloneDeep(columns);
  exportColumns.pop();
  const yesNoColumn = {
    headerText: 'Hensel Phelps Project',
    field: 'nonHenselPhelpsProject',
    valueAccessor: (_: string, data: Partial<IApiProjectHistory>): string => {
      return data.nonHenselPhelpsProject ? 'No' : 'Yes';
    },
  } as unknown as Partial<Column>;
  exportColumns.push(yesNoColumn as Column);

  const sortingOptions = {
    columns:  [{ field: 'completionDate', direction: 'Descending' }]
  } as SortSettingsModel;

  let editOptions: EditSettingsModel = {
    allowEditing: false, allowAdding: false, allowDeleting: false,
    mode: 'Dialog',
    allowEditOnDblClick: false,
  };

  if (hasPermission(['Contributor'],userRoles)) {
    const changes = { allowEditing: true, allowAdding: true, allowDeleting: true };
    editOptions = {...editOptions, ...changes}
  }

  const config = {
    columnDirectives: columns,
    exportColumns: exportColumns,
    editOptions: editOptions,
    sortingOptions: sortingOptions,
    title: 'Project History',
    showTitle: false,
  } as ISectionWithGridAndCardListConfig;
  return config;
}

function reverseBoolean(value: boolean): boolean {
  return !(value);
}