import {makeAutoObservable} from "mobx";
import {IApiProjectStakeholder} from "services/apiClients/AthenaClient";

export class OwnerState {
  // projectId: string | null;
  owner: IApiProjectStakeholder | null;

  constructor() {
    //this.projectId = null;
    this.owner = null;
    makeAutoObservable(this);
  }

  clear() {
    this.owner = null;
  }

  checkForOwner(stakeholders: IApiProjectStakeholder[]) {
    this.owner = stakeholders.find(stakeholder => stakeholder.role === 'Owner') ?? null;
  }
}

const ownerState = new OwnerState();
export default ownerState;
