import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";

import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {formatInfotip, projectInfotips} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
import {commasFmt} from "utils/numberToStringUtil";
import _ from "lodash";
// end imports

const SECTION_ID = 'servicesSection';

const ServicesSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {

      const classes = useSharedLargeCardStyles();

      const [model, setModel] = useState<IApiProject>(props.model);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());

      if (model === null) {
        setModel(props.model);
      }

      const toggleEditMode = () => {
        props.setEditMode(!props.editMode);
      }

      useEffect(() => {
        _.noop();
        console.log('!@# reference data from services', props.referenceData.options);
        console.log('!@# model. sbu', model.servicesBusinessUnit);
      }, [props.referenceData]);

      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          // return await compSave();
          console.log('begin ' + SECTION_ID + ' save');
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);
          try {
            const m = await athenaClient?.patchProject(data);
            setModel(m as IApiProject);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));
      // end useImperativeHandle

      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (!props.model) {
        return <DashItemProgress/>;
      }

      return (
          <React.Fragment key={sectionKey}>
            {/* id */}
            <DashDetailsField mode="hidden"
                              label="Id"
                              showLabel={false}
                              editMode={props.editMode}
                              name="id"
                              defaultValue={model.id}
            />

            {/* servicesBusinessUnit */}
            <DashDetailsField mode="select"
                              label="Business Unit"
                              showLabel={true}
                              width="50%"
                              editMode={props.editMode}
                              name="servicesBusinessUnit"
                              defaultValue={model.servicesBusinessUnit}
                              infotipKey={'_default'}
                              choices={props.referenceData.options['serviceBusinessUnits']}
            />

            {/* servicesFteCount */}
            <DashDetailsField mode="number"
                              label="FTE Count"
                              showLabel={true}
                              width="50%"
                              editMode={props.editMode}
                              name="servicesFteCount"
                              infotipKey={'_default'}
                              defaultValue={model.servicesFteCount}
                              formatFn={commasFmt.format}
            />

            <DashDivider width="80vw" height="5px"/>

            {/*<Dialog*/}
            {/*  open={showInfotipModal}*/}
            {/*  onClose={() => setShowInfotipModal(false)}>*/}
            {/*  <div dangerouslySetInnerHTML={{ __html: content }} />*/}
            {/*</Dialog>*/}

          </React.Fragment>
      );
    });

export default withDashSection('Services', SECTION_ID, ServicesSection);
