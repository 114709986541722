import {useEffect, useState} from 'react';
import AthenaClient, {
  IPeopleSearchStatistics, IProjectSearchStatistics,
  IQueryablePerson, IQueryableProject, ISearchResultSetOfQueryablePerson, ISearchResultSetOfQueryableProject,
} from "./apiClients/AthenaClient";

export interface ICursorPos {
  skip: number,
  take: number,
}

export interface ICriteria {
  entityName: string,
  search: string,
  filter: string,
  orderby: string,
}

export interface IGridData {
  result: IQueryablePerson[] | IQueryableProject[],
  count: number,
}

class SearchService<T> {
  private readonly entityName: string;
  private athenaClient: AthenaClient;
  //private pageSize: number;
  private top: number;
  private skip: number;
  private search: string | undefined;
  private filter: string;
  private orderby: string;
  public lastResults: T[] | null | undefined;
  public lastStatistics: IPeopleSearchStatistics | IProjectSearchStatistics | null | undefined;

  constructor(athenaClient: AthenaClient, entityName: string) {
    this.entityName = entityName;
    this.athenaClient = athenaClient; //new AthenaClient();
    //this.pageSize = 50;
    this.top = 0;
    this.skip = 0;
    this.search = '';
    this.filter = '';
    this.orderby = '';
    this.lastResults = null;
  }

  private async getResults(search?: string | null,
                           filter?: string | null,
                           skip?: number | null,
                           top?: number | null,
                           orderby?: string | null) {

    console.log('!@#- test of athenaClient in SearchService().getResults()', {athenaClient: this.athenaClient});

    // new default search orderby / sort
    // only if search variable is '' or it overrides the weighted boost scoring
    if (!orderby && !search) {
      orderby = 'name';
    }

    const results = (this.entityName === 'people') ?
        await this.athenaClient.getAllPeople(search, filter, skip, top, orderby) :
        await this.athenaClient.getAllProjectsV2(search, filter, skip, top, orderby);
    return results;
  }

  private async getSearchStatistics(search?: string | null,
                                    filter?: string | null) {
    //console.log('test of athenaClient in SearchService().getSearchStatistics()', {athenaClient: this.athenaClient});

    return (this.entityName === 'people') ?
        await this.athenaClient.getPeopleSearchStatistics(search, filter) :
        await this.athenaClient.getProjectSearchStatistics(search, filter);
  }

  public async entitySearch(criteria: ICriteria, skip: number, take: number): Promise<IGridData> {
    //this.pageSize = pageSize;
    this.top = take;
    this.skip = skip;
    this.search = criteria.search;
    this.filter = criteria.filter;
    this.orderby = criteria.orderby;

    const searchResultSet: ISearchResultSetOfQueryablePerson | ISearchResultSetOfQueryableProject =
        await this.getResults(this.search, this.filter,
            this.skip,
            this.top,
            this.orderby);
    //console.log('FROM inside entitySearch', searchResultSet);

    //console.log('searchResultSet.count', this.lastResults.count);
    return {result: searchResultSet.value ?? [], count: searchResultSet.count ?? 0 };
  }

  public async getSearchStats(criteria: ICriteria): Promise<IPeopleSearchStatistics | IProjectSearchStatistics> {
    console.log('!!!! criteria from SearchService', criteria);
    console.log('!!!! this', this);
    console.log('!!!! this.search', this.search);
    this.search = criteria.search;
    this.filter = criteria.filter;
    this.orderby = criteria.orderby;

    const stats = await this.getSearchStatistics(this.search, this.filter);

    this.lastStatistics = stats as IPeopleSearchStatistics | IProjectSearchStatistics;
    //console.log('searchResultSet.count', this.lastResults.count);
    return stats;
  }
}

export class PeopleSearch extends SearchService<IQueryablePerson> {
  constructor(athenaClient: AthenaClient) {
    super(athenaClient,'people');
  }
}

export class ProjectSearch extends SearchService<IQueryableProject> {
  constructor(athenaClient: AthenaClient) {
    super(athenaClient,'projects');
  }
}