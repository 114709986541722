import * as React from 'react';
import DraggableListItem, { DraggableItem } from './DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import {makeStyles} from "@material-ui/core/styles";




export type DraggableListProps = {
  items: DraggableItem[];
  onDragEnd: OnDragEndResponder;
  onRemoveItem: (id: string) => void;
  className?: string;
};

const DraggableList = React.memo(({ items, onDragEnd, onRemoveItem, className }: DraggableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={className}>
            {items.map((item, index) => (
              <DraggableListItem item={item} index={index} key={item.id} onRemoveItem={onRemoveItem}/>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
export type {DraggableItem};
