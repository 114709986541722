import React, {useEffect, useState} from "react";
import useReferenceDataForProjects from "hooks/useFieldOptions/useReferenceDataForProjects";
import {IDropDownOptions} from "hooks/useFieldOptions/localTypesAndUtil";
import {IApiProject, IApiProjectReference, IQueryableContact, ProjectContact} from "services/apiClients/AthenaClient";
import useAthenaClient from "hooks/useAthenaClient";
import {makeStyles} from "@material-ui/core/styles";
import {createControls} from "metaprogramming/formDSL/createControls";
import {psvToArray} from "metaprogramming/formDSL/psv";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import {getNamedData} from "utils/forms";
import _ from "lodash";
import {
  psvAddProjectReference,
  psvEditProjectReference,
  psvViewProjectReference,
  psvOldProjectReference
} from "components/detailViews/projectViewRelated/AddProjectReference/projectReferenceConfig";

import ProgressButton from "../../../dashCore/ProgressButton";
import {createProjectContactForDisplayModelEx, createProjectReferenceForDisplayModelEx} from "./correctReferences";
import {getCrmContactLink} from "config/microsoftDynamics";
import AthenaAuthorize from "app/security/ui/AthenaAuthorize";
import {EditOutlined} from "@material-ui/icons";
import useSaveCount from "hooks/useSaveCount";
import CoreDebug from "../../../../features/core/CoreDebug";
import DashDivider from "../../../DashDivider";

const FILENAME = 'AddEditProjectReference.tsx';

const useStyle = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    width: '100%',
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "flex-start",
  },
  textField: {
    flexBasis: '45%',
    midWidth: '400px',
    margin: '10px',
  }
}));


interface IAddProjectReferenceProps {
  personFullName?: string,
  contactId?: string,
  projectId: string,
  isManagedByCRM?: boolean,
  model?: IApiProjectReference,
  projectModel?: IApiProject,
  mode: string,
  open: boolean,
  onClose: () => void,
  instanceName?: string,
}

export default function AddEditProjectReference(props: IAddProjectReferenceProps) {
  const classes = useStyle();
  const {athenaClient} = useAthenaClient();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const [initValues, setInitValues] = useState<object>({});
  const [mode, setMode] = useState<string>(props.mode);
  const [isSavingPersonOnProject, setIsSavingPersonOnProject] = useState(false);
  const [contact, setContact] = useState<IQueryableContact | null>();
  const [contactId, setContactId] = useState(props.contactId);
  const {options}: IDropDownOptions = useReferenceDataForProjects();
  const [reset, setReset] = useState(false);
  const [model, setModel] = useState(props.model ?? null);
  const [isLoading, setIsLoading] = useState(false);
  //const editMode = props.mode === 'new';
  let controls: JSX.Element[] = [];
  const [saveCount, incr] = useSaveCount();

  // form ref not needed in this one because it has id attribute

  // console.log('$$$ contactIds at first load', {'props.contactId': props.contactId, contactId});
  // console.log('$$$ dialog starting modes', {'props.mode': props.mode, mode});
  // console.log('$$$ instanceName', )
  useEffect(() => {
    //if (model) console.log('model is truthy', model);
    setModel(model);
  }, [props.model]);

  useEffect(() => {
    async function getContact(contactId: string) {
      if (athenaClient && contactId) {
        setIsLoading(true);
        if (mode !== 'x') {
          let tmpContact = null;
          if (props.isManagedByCRM) {
            tmpContact = await athenaClient.getSingleContact(contactId);
            setContact(tmpContact);
          }
          const displayModel = props.isManagedByCRM
              ? createProjectContactForDisplayModelEx(tmpContact ?? {}, props.projectId, props.projectModel)
              : createProjectReferenceForDisplayModelEx(contactId, props.projectId, props.projectModel as IApiProject);
          setModel(displayModel);
        }
        setIsLoading(false);
      }
    }

    getContact(contactId as string);
  }, [athenaClient, contactId]);

  function switchToEditMode() {
    //gs.setStakeholdersEditMode('edit');
    setMode('edit');
    incr();
  }

  useEffect(() => {
    _.noop();
  },[saveCount]);

  useEffect(() => {
    _.noop();
  }, [contact]);

  useEffect(() => {
    setIsLoading(false);
  }, [model]);

  async function handleSave() {
    const data = getNamedData(document.querySelector('#frm-add-reference-to-project')) as ProjectContact;
    const contactId = data.contactId as string;
    if (athenaClient) {
      const contact = await athenaClient.createOrUpdateProjectContactLink(props.projectId, contactId, data);
      console.log({contact});
      setTimeout(() => {
        props.onClose();
      }, 2000);
    }
  }

  const handleButtonReset = () => setReset(false);

  // is not open, or is loading is truthy, or model is not truthy be empty <></>
  if (!props.open || isLoading || !model) {
     return <></>;
  }

  let psvSource;
  switch (mode) {
    case 'add':
      psvSource = psvAddProjectReference;
      break;
    case 'edit':
      psvSource = psvEditProjectReference;
      break;
    default:
      psvSource = (!props.isManagedByCRM) ? psvOldProjectReference: psvViewProjectReference;
      break;
  }

  const psvArray = psvToArray(psvSource, true);
  controls = createControls(psvArray, model, undefined, options, classes);
  //console.log('lastMinute before return from AddProjectReference', {contact, controls, model, psvArray});

  return (
      <Dialog open={true} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle className="text-white bg-black">
          {
            (() => {
              if (mode === 'new') return `Add Reference for ${props?.personFullName ?? ''}`;
              if (mode === 'edit') return 'Edit Project Reference Details';
              return 'Project Reference Details';
            })()
          }
        </DialogTitle>

        <DialogContent>
          <form id={'frm-add-reference-to-project'}>
            <div className="flex flex-row">
              <div style={{flex: 1}}>
                <h3 style={{color: "#a6192e"}}>Details</h3>
              </div>
              <div style={{textAlign: "right"}}>
                {model?.crmId &&
                    <a href={getCrmContactLink(model?.crmId)} target={'_blank'}>View in CRM</a>
                }
              </div>

              <DashDivider width={'20px'} height={'20px'} display={'inline-block'}/>
              <AthenaAuthorize allowedRoles={'Contributor'}>
                {props.mode === 'view' && props.isManagedByCRM &&
                    <IconButton onClick={switchToEditMode}>
                        <EditOutlined />
                    </IconButton>
                }
              </AthenaAuthorize>

            </div>
            <div className={classes.flexContainer} key={'holderOfControls' + saveCount}>
              {controls}
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <ProgressButton hidden={mode === 'view'}
                          onClick={() => handleSave()}
                          text={'Save'}
                          reset={reset}
                          onReset={handleButtonReset}/>
          <Button onClick={() => props.onClose()}>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
