import AthenaAuthorize from "../../security/ui/AthenaAuthorize";
import {ExitToApp} from "@material-ui/icons";
import React from "react";

function LinkToWebDAM(props: {webdamFolderId?: number}) {
  const webdamFolderTmpl = process.env.REACT_APP_WEBDAM_URL_TMPL ?? '';

  return (
      <AthenaAuthorize allowedRoles={'contributor'}>
        <a href={webdamFolderTmpl.replace('{id}',
            String(props.webdamFolderId ?? 0))}
           target={'_blank'}><ExitToApp style={{color: '#999'}}/>
          <img style={{height: '22px'}} src="/images/webdam/webdam.png" alt="webdam logo"/>
        </a>
      </AthenaAuthorize>
  );
}

export default LinkToWebDAM;