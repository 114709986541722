export function psvToArray(psv: string, useTypeSuffix = false): any[] {
  const rows = psv.split('\n')
      .map(row => row.trim())
      .filter(row => row && !row.startsWith('#'));

  if (!rows.length) {
    return [];
  }
  const fieldNames = rows[0].split('|').map(field => {
    if (useTypeSuffix) {
      const parts = field.split('_');
      const fn = parts[0];
      const suffix = parts.length === 2 ? parts[1]: '';
      if (suffix === '') {
        // no suffix
        return { fieldName: field, type: 'string' };
      } else {
        switch (suffix) {
          case 's':
            return { fieldName: fn, type: 'string' };
          case 'n':
            return { fieldName: fn, type: 'number' };
          case 'b':
            return { fieldName: fn, type: 'boolean' };
          default:
            console.log(`Error: Invalid suffix "${suffix}" for field "${fn}"`);
            throw new Error(`Invalid suffix "${suffix}" for field "${fn}"`);
        }
      }
    }
  });

  return rows
      .slice(1) // remove the first row with field names
      .map(row => {
        const fields = row.split('|');
        return fields.reduce((obj: Record<string, any>, field, index) => {
          const { fieldName, type } = fieldNames[index];
          switch (type) {
            case 'string':
              obj[fieldName] = field;
              break;
            case 'number':
              obj[fieldName] = Number(field);
              break;
            case 'boolean':
              switch (field.toLowerCase()) {
                case 't':
                case 'true':
                case 'yes':
                case '1':
                  obj[fieldName] = true;
                  break;
                case 'f':
                case 'false':
                case 'no':
                case '0':
                  obj[fieldName] = false;
                  break;
                default:
                  console.log(`Error: Invalid value "${field}" for field "${fieldName}"`);
                  throw new Error(`Invalid value "${field}" for field "${fieldName}"`);
              }
              break;
          }
          return obj;
        }, {});
      });
}