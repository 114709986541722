import { atom, useRecoilState } from 'recoil';

//import {ISearchResultSetOfQueryablePerson} from "../../services/apiClients/AthenaClient";
import {IAppFocus} from 'types/generalTypes';

const defaultValue = {} as IAppFocus;

const appFocusStateAtom = atom({
  key: 'appFocus',
  default: defaultValue,
});

export const useAppFocusState = () => {
  const [appFocusState, setAppFocusState] = useRecoilState(appFocusStateAtom);

  return [
    appFocusState,
    {
      setAppFocusState,
    },
  ] as const;
};

export default appFocusStateAtom;
