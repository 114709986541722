import ISpecification from "./ISpecification";

export default class ValidDistrictChoice implements ISpecification<string> {
  isSatisfiedBy(object: string): boolean {
    if (!object || object.trim() === '' || object.includes(',')) {
      return false;
    }

    return !(object.toLowerCase() === 'null' || object.toLowerCase() === 'undefined');
  }
}
