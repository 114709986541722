import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useAthenaClient from "../hooks/useAthenaClient";


function Bounce() {
  const navigate = useNavigate();
  const projectsHome = {pathname: '/projects', search: '?nonHenselPhelpsProject=oneof."false"'};

  const {athenaClient} = useAthenaClient();

  useEffect(() => {
    try {
      if (athenaClient) {
        navigate(projectsHome);
      }
    } catch (_e) {
      navigate(projectsHome);
    }
  }, [athenaClient]);

  return (
      <div></div>
  );
}

export default Bounce;