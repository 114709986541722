import {
  Column,
  ColumnDirective,
  EditSettingsModel,
  FilterSettingsModel,
  SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardList, ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {IApiProjectHistory, IProjectAward} from "services/apiClients/AthenaClient";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import InfotipButton from "../../../InfotipButton/InfotipButton";
import _ from "lodash";

const HeaderTemplateWithInfotip = (props: {headerText:string,infotipKey:string}) => {
  return (
      <span>{props.headerText}
        <InfotipButton infotipKey={props.infotipKey}/></span>
  )
};

export function getProjectAwardConfig(userRoles: string[]) {
  const columns = [
    {
      headerText: 'Title',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'awardTitle'}/></>
      ),
      field: 'title',
      width: 200,
      allowSorting: true,
      validationRules: { required: true },
    },
    {
      headerText: 'Level',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'awardCitation'}/></>
      ),
      field: 'citation',
      width: 100,
      editable: false,
      allowSorting: true,
    },
    {
      headerText: 'Year',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'awardDate'}/></>
      ),
      field: 'date',
      template: (data: IProjectAward) => {
        return (<>{data.date?.getFullYear()}</>);
      },
      width: 150,
      edit: {},
      format: {type: 'number'},
      allowSorting: true,
    },
    {
      headerText: 'Sponsor',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'awardSponsor'}/></>
      ),
      field: 'sponsor',
      width: 100,
      allowSorting: true,
    },
    {
      headerText: 'Category',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'awardCategory'}/></>
      ),
      field: 'category',
      width: 150,
      format: 'yMd',
      allowSorting: true,
    } as unknown as Partial<Column>,
  ] as Column[];

  const exportColumns = _.cloneDeep(columns);
  exportColumns.splice(2, 1, {
    headerText: 'Year',
    field: 'year',
    format: {type: 'number'},
  } as Partial<Column> as Column);


  const sortingOptions = {
    columns:  [{ field: 'date', direction: 'Descending' }]
  } as SortSettingsModel;

  const filterOptions = {
    ignoreAccent: true,
    columns: [{field:'title', operator:'startsWith'}]
  } as FilterSettingsModel;

  let editOptions = {
    allowEditing: false, allowAdding: false, allowDeleting: false,
    mode: 'Dialog',
    allowEditOnDblClick: false,
  } as EditSettingsModel;

  if (hasPermission(['Contributor'],userRoles)) {
    const changes = { allowEditing: true, allowAdding: true, allowDeleting: true };
    editOptions = {...editOptions, ...changes}
  }

  const config = {
    columnDirectives: columns,
    exportColumns: exportColumns,
    sortingOptions: sortingOptions,
    editOptions: editOptions,
    filterOptions: filterOptions,
    title: 'Awards',
    showTitle: false,
    allowAdd: true,
    //allowEdit: true,
    allowRemove: true,
  } as ISectionWithGridAndCardListConfig;
  return config;
}
