import {
    ApiProjectSustainabilityCertification,
    IApiProject, IApiProjectSustainabilityCertification,
    IApiSustainabilityCertification,
    ISustainabilityCertificationLevel,
    ISustainabilityCertificationType,
    ISustainabilityCertificationVersion,
} from "services/apiClients/AthenaClient";
import React, { useEffect, useRef, useState } from "react";
import 'reflect-metadata';
import { getNamedData } from "utils/forms";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel
} from "@material-ui/core";
import DashDetailsField from "components/DashDetailsField";
import useAthenaClient from "hooks/useAthenaClient";
import DashSelector, { IAction, IOption, ISelectedOption } from "../../../dashCore/DashSelector";
import _ from "lodash";
import noop from "../../../../utils/noop";
import DateFnsUtils from "@date-io/luxon";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTime } from "luxon";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { parse } from 'date-fns';
import TextField from "@material-ui/core/TextField";
import debounce from "lodash.debounce";
import useDebounce from "hooks/useDebounce";
import useSaveCount from "hooks/useSaveCount";
import ProgressButton from "../../../dashCore/ProgressButton";
import {isNullish} from "../../../../utils/general";

function isFalsy(value: { a?: string }): boolean {
    const result = !!value &&
        (Array.isArray(value) && value.length === 0) &&
        (Object.keys(value).length === 0);
    return result;
}



interface IDialogProps {
    open?: boolean;
    mode?: string | null;
    id?: string;
    parentModel: IApiProject;
    choices: string[];
    onInfotipClick: (key: string) => void;
    onRequestFromChild: (action: string, payload?: {id: string}) => void;
}

export default function AddSustainabilityCertification(props: IDialogProps) {
    const { athenaClient } = useAthenaClient();
    const [savedCount, incr] = useSaveCount();
    const [reset, setReset] = useState(false);
    const [hasBeenAchieved, setHasBeenAchieved] = useState(false);
    const certTreeRef = useRef<{ certifications: IApiSustainabilityCertification[] }>({ certifications: [] });
    //const [certTree, setCertTree] = useState<{certifications: IApiSustainabilityCertification[]}>({certifications: []});
    const [selectedCertName, setSelectedCertName] = useState<string | null>(null);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    // const [certOptions, setCertOptions] = useState<Record<string, string> | undefined>(undefined);
    // const [versionOptions, setVersionOptions] = useState<Record<string, string> | undefined>(undefined);
    // const [levelOptions, setLevelOptions] = useState<Record<string, string> | undefined>(undefined);
    // const [typeOptions, setTypeOptions] = useState<Record<string, string> | undefined>(undefined);
    const certOptionsRef = useRef<Record<string, string> | undefined>(undefined);
    const versionOptionsRef = useRef<Record<string, string> | undefined>(undefined);
    const levelOptionsRef = useRef<Record<string, string> | undefined>(undefined);
    const typeOptionsRef = useRef<Record<string, string> | undefined>(undefined);

    // const [selectedCert, setSelectedCert] = useState<IApiSustainabilityCertification | null>(null);
    // const [selectedVersion, setSelectedVersion] = useState<ISustainabilityCertificationVersion | null>(null);
    // const [selectedLevel, setSelectedLevel] = useState<ISustainabilityCertificationLevel | null>(null);
    // const [selectedType, setSelectedType] = useState<ISustainabilityCertificationType | null>(null);
    const selectedCertRef = useRef<IApiSustainabilityCertification | null>(null);
    const selectedVersionRef = useRef<ISustainabilityCertificationVersion | null>(null);
    const selectedLevelRef = useRef<ISustainabilityCertificationLevel | null>(null);
    const selectedTypeRef = useRef<ISustainabilityCertificationType | null>(null);

    const [selectedCertId, setSelectedCertId] = useState<string | undefined>(undefined);
    const [selectedVersionId, setSelectedVersionId] = useState<string | undefined>(undefined);
    const [selectedLevelId, setSelectedLevelId] = useState<string | undefined>(undefined);
    const [selectedTypeId, setSelectedTypeId] = useState<string | undefined>(undefined);

    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

    const [selectedAssetsUploaded, setSelectedAssetsUploaded] = useState<string | null>(null);
    const [selectedAwardDate, setSelectedAwardDate] = useState<Date | null>(null);
    const [notes, setNotes] = useState('');

    const [id, setId] = useState<string>();

    // const initStatusOptions= {
    //   '1': 'Achieved',
    //   '2': 'Design Equivalent',
    //   '3': 'Documenting Assets',
    // }

    // todo: 8 helper set fns - refactor these out if the fixes work
  const setCertOptions = (options: Record<string, string> | undefined) => {
    certOptionsRef.current = options;
  };

  const setVersionOptions = (options: Record<string, string> | undefined) => {
    versionOptionsRef.current = options;
  };

  const setLevelOptions = (options: Record<string, string> | undefined) => {
    levelOptionsRef.current = options;
  };

  const setTypeOptions = (options: Record<string, string> | undefined) => {
    typeOptionsRef.current = options;
  };

  const setSelectedCert = (selectedCert: IApiSustainabilityCertification | null) => {
    selectedCertRef.current = selectedCert;
  };

  const setSelectedVersion = (selectedVersion: ISustainabilityCertificationVersion | null) => {
    selectedVersionRef.current = selectedVersion;
  };

  const setSelectedLevel = (selectedLevel: ISustainabilityCertificationLevel | null) => {
    selectedLevelRef.current = selectedLevel;
  };

  const setSelectedType = (selectedType: ISustainabilityCertificationType | null) => {
    selectedTypeRef.current = selectedType;
  };

    useEffect(() => {
        if (athenaClient && props.open && (props.mode === 'new' || props.mode === 'edit')) {
            //getSustainabilityCertifications
            athenaClient.getSustainabilityCertifications()
                .then(function (resultCerts: IApiSustainabilityCertification[]) {
                    console.log('!@@@! inside - getSustainabilityCertifications result', resultCerts,
                        mapToOptions(resultCerts as { id: string, name: string }[]));
                    certTreeRef.current = { certifications: resultCerts };
                    setCertOptions(mapToOptions(certTreeRef.current.certifications as { id: string, name: string }[]));
                    incr();
                });
        }
    }, [athenaClient, props.open]);

    useEffect(() => {
        setCertOptions(mapToOptions(certTreeRef.current.certifications as { id: string, name: string }[]));
        setId(props.id);
    }, [certTreeRef]);

    useEffect(() => {
        console.log('!@@@ useEffect-3',
            certOptionsRef,
            certOptionsRef.current,
            athenaClient, props);
        if (certOptionsRef.current && athenaClient) {
            // EDIT path
            if (props.mode === 'edit' && props.id && props.parentModel.id) {
                console.log('!@@@ useEffect EDIT PATH');
                athenaClient.getProject(props.parentModel.id).then(proj => {
                    if (proj.sustainabilityCertifications) {
                        console.log('!@@@ Project', proj);
                        const cm = proj.sustainabilityCertifications.find(x => x.id === props.id);
                        console.log('!@@@ HAL9000', cm);
                        if (cm) {
                            const cert = certTreeRef.current.certifications.find(x => x.id === cm.certificationId);
                            // cert
                            setSelectedCertId(cm.certificationId);
                            handleDropDownChangeEx2('name', cert?.id as string);
                            // version and level
                            setSelectedVersionId(cm.versionId);
                            setSelectedLevelId(cm.levelId);
                            handleDropDownChangeEx2('version', cm.versionId as string);
                            handleDropDownChangeEx2('level', cm.levelId as string);
                            // type
                            setSelectedTypeId(cm.typeId);
                            handleDropDownChangeEx2('type', cm.typeId as string);
                            // status, awardDate, assetsUploaded and notes
                            setSelectedStatus(cm.status ?? null);
                            handleDropDownChangeEx2('status', cm.status as string);
                            setSelectedAwardDate(cm.awardDate ?? null);
                            setSelectedAssetsUploaded(cm.assetsUploaded ?? null);
                            handleDropDownChangeEx2('assetsUploaded', cm.assetsUploaded as string);
                            console.log('!@@@! cn.notes', cm.notes);
                            setNotes(cm.notes as string);
                            incr();
                        }
                    }
                });
            }
            // NEW path
            if (props.mode === 'new' && props.parentModel.id) {
                //const cert = certTree.certifications.find(x => x.id === cm.certificationId);
                // cert
                //setSelectedCertId(cm.certificationId);
                //handleDropDownChangeEx2('name', cert?.id as string);
            }
        }

    }, [athenaClient, id, certTreeRef.current]);



    function mapToOptions(objs: { id: string, name: string }[]) {
        const tmp = objs.filter(x => x.id != null && x.name != null);
        const result = tmp.reduce(
            (acc, obj) => {
                return { ...acc, [obj.id]: obj.name };
            }, {});

        return result;
    }

    function mapToOptionsFromArray(input: string[]) {
        const dict = {} as Record<string, string>
        for (let i = 0; i < input.length; i++) {
            dict[input[i]] = input[i];
        }
        return dict;
    }

    async function handleSave() {
      if (isNullish(selectedCertRef.current) ||
          isNullish(selectedLevelRef.current) ||
          isNullish(selectedVersionRef.current) ||
          isNullish(selectedTypeRef.current) ||
          isNullish(selectedStatus) ||
          isNullish(selectedAssetsUploaded)) {
        setValidationMessage('You must select a value for each one of the dropdowns in this form');
        setReset(true);
        return;
      }

      if (selectedCertRef.current
          && selectedLevelRef.current
          && selectedVersionRef.current
          && selectedTypeRef.current
          && selectedStatus
          && selectedAssetsUploaded
          && typeof props.parentModel.id === 'string') {
        //console.log('made inside');
        setValidationMessage(null);
        const projectId = props.parentModel.id;

        const cert = {} as IApiProjectSustainabilityCertification;
        if (props.mode === 'edit') {
          cert.id = props.id;
        }
        console.log('save mode ', props.mode);
        if (props.mode === 'new' || props.mode === 'edit') {
          cert.certificationId = selectedCertRef.current.id;
          cert.versionId = selectedVersionRef.current.id;
          cert.levelId = selectedLevelRef.current.id;
          cert.typeId = selectedTypeRef.current.id;
          cert.status = selectedStatus;
          cert.awardDate = selectedAwardDate ?? undefined;
          cert.assetsUploaded = selectedAssetsUploaded;
          cert.notes = notes;
          if (cert.id) {
            const certImpl = cert as ApiProjectSustainabilityCertification;
            const result = await athenaClient?.updateProjectSustainabilityCertification(certImpl, projectId, cert.id);
            console.log(result);
            await new Promise(resolve => setTimeout(resolve, 1000));
            props.onRequestFromChild('close-refresh');
          }
          if (props.mode === 'new') {
            cert.version = selectedVersionRef.current.name;
            cert.level = selectedLevelRef.current.name;
            cert.type = selectedTypeRef.current.name;
            const certImpl = cert as ApiProjectSustainabilityCertification;
            const result = await athenaClient?.createProjectSustainabilityCertification(certImpl, projectId);
            console.log(result);
            await new Promise(resolve => setTimeout(resolve, 1000));
            props.onRequestFromChild('close-refresh');
          }
        }
      }
    }

    function handleDropDownChange(_event: React.ChangeEvent<HTMLSelectElement>, action: IAction<ISelectedOption>) {
        handleDropDownChangeEx(action);
    }

    function handleDropDownChangeEx(action: IAction<ISelectedOption>) {
        if (action.payload.name === 'name') {
            const tmpCert = certTreeRef.current.certifications.find(x => x.id === action.payload.value) as IApiSustainabilityCertification;
            setSelectedCert(tmpCert as IApiSustainabilityCertification);
            setVersionOptions(mapToOptions(tmpCert.versions as { id: string, name: string }[]));
            setLevelOptions(mapToOptions(tmpCert.levels as { id: string, name: string }[]));
            incr();
        } else if (action.payload.name === 'version') {
            const tmpVersions = (selectedCertRef.current as IApiSustainabilityCertification).versions;
            const tmpVersion = (tmpVersions as ISustainabilityCertificationVersion[])
                .find(x => x.id === action.payload.value) as ISustainabilityCertificationVersion;
            setTypeOptions(mapToOptions(tmpVersion.types as { id: string, name: string }[]));
            setSelectedVersion(tmpVersion);
            incr();
        } else if (action.payload.name === 'level') {
            const tmpLevels = (selectedCertRef.current as IApiSustainabilityCertification).levels;
            const tmpLevel = (tmpLevels as ISustainabilityCertificationLevel[])
                .find(x => x.id === action.payload.value) as ISustainabilityCertificationLevel;
            setSelectedLevel(tmpLevel);
            incr();
        } else if (action.payload.name === 'type') {
            const tmpTypes = (selectedVersionRef.current as ISustainabilityCertificationVersion).types;
            if (tmpTypes) {
                const tmpType = tmpTypes.find(x => x.id === action.payload.value) as ISustainabilityCertificationType;
                setSelectedType(tmpType);
                incr();
            }
        } else if (action.payload.name === 'status') {
            console.log('@@@ payload', action.payload);
            setSelectedStatus(action.payload.value);
            incr();
        } else if (action.payload.name === 'assetsUploaded') {
            setSelectedAssetsUploaded(action.payload.value);
            incr();
        }
    }


    function handleAwardDateChange(_date: MaterialUiPickersDate, value?: string | null | undefined): void {
        if (value) {
            const selectedDate = parse(value, 'MM/dd/yyyy', new Date());
            setSelectedAwardDate(selectedDate);
        } else {
            setSelectedAwardDate(null);
        }
    }


    function handleDropDownChangeEx2(action: string, id: string) {
        if (action === 'name') {
            const tmpCerts = certTreeRef.current.certifications as IApiSustainabilityCertification[] ?? [];
            const tmpCert = tmpCerts.find(x => x.id === id);
            setSelectedCert(tmpCert as IApiSustainabilityCertification);
            console.log('!@@@! tmpCert', { certTreeRef, tmpCert });
            if (tmpCert) {
                const versionOptions = tmpCert.versions as { id: string, name: string }[];
                console.log('!@@@! version options', versionOptions);
                setVersionOptions(mapToOptions(versionOptions));
                const levelOptions = tmpCert.levels as { id: string, name: string }[];
                console.log('!@@@! level options', levelOptions);
                setLevelOptions(mapToOptions(levelOptions));
            }
        } else if (action === 'version') {
            const tmpVersions = (selectedCertRef.current as IApiSustainabilityCertification).versions ?? [];
            const tmpVersion = tmpVersions.find(x => x.id === id) as ISustainabilityCertificationVersion;
            console.log('!@@@! tmpVersion', tmpVersion);
            const typeOptions = tmpVersion.types as { id: string, name: string }[];
            setTypeOptions(mapToOptions(typeOptions));
            console.log('!@@@! type options', typeOptions);
            setSelectedVersion(tmpVersion);
        } else if (action === 'level') {
            const tmpLevels = (selectedCertRef.current as IApiSustainabilityCertification).levels ?? [];
            const tmpLevel = tmpLevels.find(x => x.id === id) as ISustainabilityCertificationLevel;
            setSelectedLevel(tmpLevel);
        } else if (action === 'type') {
            const tmpTypes = (selectedVersionRef.current as ISustainabilityCertificationVersion).types ?? [];
            console.log('!@@@! tmpTypes', tmpTypes, id);
            const tmpType = tmpTypes.find(x => x.id === id) as ISustainabilityCertificationType;
            setSelectedType(tmpType);
        } else if (action === 'status') {
            console.log('!@@@! payload', id);
            //setSelectedStatus(action.payload.value);
        } else if (action === 'assetsUploaded') {
            //setSelectedAssetsUploaded(action.payload.value);
        }
    }


  function handleReset() {
    setReset(false);
  }

  return (
        <Dialog id="addOrEditPhaseDialog" fullWidth={true} maxWidth={'lg'}
            open={props.open ?? false}>
            {/*data-PaperProps={{ style: { height: '700px' } }}*/}
            <DialogTitle className="bg-black text-white">Add Certification to {props.parentModel.name}</DialogTitle>
            <DialogContent>
                <div style={{
                    border: '1px dotted #eee', width: '100%', display: 'flex',
                    flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'stretch'
                }}>
                    <Grid container spacing={2} className={'w-full'}>
                      <Grid itemxs={12} sm={12}>
                        <span style={{color:'#900'}}>{validationMessage}</span>
                      </Grid>
                        <Grid item xs={12} sm={6}>
                            <DashSelector
                                name={'name'} label={'Certification'} value={selectedCertId}
                                options={certOptionsRef.current} onChange={handleDropDownChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DashSelector
                                name={'version'} label={'Version'} value={selectedVersionId}
                                options={versionOptionsRef.current} onChange={handleDropDownChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DashSelector
                                name={'level'} label={'Level'} value={selectedLevelId}
                                options={levelOptionsRef.current} onChange={handleDropDownChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DashSelector
                                name={'type'} label={'Type'} value={selectedTypeId}
                                options={typeOptionsRef.current} onChange={handleDropDownChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DashSelector
                                name={'status'} label={'Status'}
                                value={selectedStatus ?? undefined}
                                options={mapToOptionsFromArray(['Achieved', 'Design Equivalent', 'Documenting Assets'])}
                                // options={initStatusOptions}
                                onChange={handleDropDownChange} />
                        </Grid>  <Grid container item xs={12} sm={6} alignItems={'center'}>
                            {selectedStatus === 'Achieved' &&
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker style={{ width: '60%' }}
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="awardDate"
                                        label="Date Awarded"
                                        value={selectedAwardDate ? selectedAwardDate : null}
                                        onChange={handleAwardDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                    />
                                </MuiPickersUtilsProvider>

                            } </Grid>
                        <Grid item xs={12} sm={6}>
                            <DashSelector
                                name={'assetsUploaded'} label={'Documenting Assets'}
                                value={selectedAssetsUploaded ?? undefined}
                                options={mapToOptionsFromArray(['Not Applicable', 'Upload'])}
                                onChange={handleDropDownChange} />
                        </Grid>

                        <Grid container item xs={12} alignItems={'center'}>
                            <div style={{ width: '100%', margin: '25px 10px' }}>
                                <FormControl fullWidth>
                                    <TextField id="notes"
                                        name="notes"
                                        key="notes"
                                        value={notes}
                                        placeholder={'Notes'} onChange={(e) => {
                                            console.log('text field change:', e.target.value);
                                            //debouncedHandleNotesChange(e);
                                            setNotes(e.target.value);
                                        }} />
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>


                </div>
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={handleSave}>Save</Button>*/}
                <ProgressButton text={'Save'} onClick={handleSave} reset={reset} onReset={handleReset} />
                <Button onClick={() => props.onRequestFromChild('cancel')}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
