import React, {useEffect, useState} from "react";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { makeStyles } from '@material-ui/core/styles';
import {CloudDownload, GetApp, Assignment, History} from "@material-ui/icons";
import useCurrentUser from "hooks/useCurrentUser";
import { CurrentUser } from "types";
import _ from "lodash";


const useStyles = makeStyles(() => ({
  speedDial: {
    //backgroundColor: theme.palette.primary.dark,
    position: 'fixed',
    bottom: '40px',
    right: '30px',
    zIndex: 1000,
  },
  action: {
    color: '#999',
    backgroundColor: 'white',
  },
}));

export interface IExportSpeedDialProps {
  onExportWizard?: () => void;
  onQuickExport?: () => void;
  onCheckExports?: () => void;
}

export default function ExportSpeedDial(props?: IExportSpeedDialProps) {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const [open, setOpen] = useState(false);
  let user = '';

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (currentUser && currentUser.userPrincipalName) {
      user = currentUser.userPrincipalName as string;
    }
  }, [currentUser]);


  return (
      <SpeedDial
          ariaLabel="Export Speed Dial"
          className={classes.speedDial}
          direction="up"
          icon={<Assignment style={{color:'white'}} />}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
      >
        <SpeedDialAction
            className={classes.action}
            icon={<GetApp />}
            tooltipTitle="Quick Export"
            onClick={props?.onQuickExport}
        />
        <SpeedDialAction
            className={classes.action}
            icon={<Assignment />}
            tooltipTitle="Export Wizard"
            onClick={props?.onExportWizard}
        />
        { currentUser &&
            _.includes(['mjl4@henselphelps.com','jdd@henselphelps.com'],currentUser?.userPrincipalName?.toLowerCase()) &&
          <SpeedDialAction
              className={classes.action}
              icon={<History />}
              tooltipTitle={'Check Last Export Status'}
              onClick={props?.onCheckExports}
          />
        }
      </SpeedDial>
  );
}