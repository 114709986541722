

import useAthenaClient from 'hooks/useAthenaClient';
import React, { useEffect, useState } from 'react';
import { IQueryableContact } from 'services/apiClients/AthenaClient';
import noop from 'utils/noop';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent } from '@syncfusion/ej2-react-grids';

import CrmContactHyperlink from '../../../dashCore/CrmContactHyperlink';

//enableRipple(true);
const useStyles = makeStyles((theme) => ({
  topBox: {
    width: '100%',
  },
  leftBox: {
    alignSelf: 'flex-start',
  },
  rightBox: {
    alignSelf: 'flex-end',
  },
  bottomBox: {},
  activeStepIcon: {
    color: theme.palette.info.main,
  },
  completedStepIcon: {
    color: theme.palette.success.main,
  },
  // dialog: {
  //   width: '90vh',
  //   maxWidth: 'none'
  // },
}));

interface IPickerDialogProps {
  open: boolean,
  title: string,
  searchBoxLabel: string,

  dataSourceName: string,
  extraInfo?: Record<string, string>;
  //onClose: (id?: string | null) => void;
  onClose: (payload: unknown) => void;
}



export default function PickerDialog(props: IPickerDialogProps) {
  const classes = useStyles();
  const { athenaClient } = useAthenaClient();
  const [searchText, setSearchText] = useState('');
  const [records, setRecords] = useState([] as IQueryableContact[]);
  let gridInstance: GridComponent;

  useEffect(() => {
    async function reloadData() {
      // API sample
      // /v2/contacts?search=terry*&filter=(isActive%20eq%20true)
      if (athenaClient) {
        const filter = '(isActive eq true)';
        const resultSet = await athenaClient.getAllContacts(searchText, filter,
            undefined, undefined, undefined);
        if (resultSet.value) {
          setRecords(resultSet.value as IQueryableContact[]);
        }
      }
    }

    reloadData();
  }, [athenaClient, searchText]);

  function setGridInstance(comp: GridComponent | null) {
    if (comp === null) return;
    gridInstance = comp;
  }

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {

    setSearchText(e.target.value);
  }

  const handleDataBound = () => {
    //setSelectedRow(undefined);
    noop();
  }

  const handleCancel = () => {
    props.onClose();
  }

  const handleItemSelection = (data: unknown) => {
    // (id?: string | null) => {
    console.log('$$$ this is the selected', data);

    props.onClose(data);
    //setTimeout(() => alert(id), 500);
  }

  if (!props.open) {
    return <></>;
  }

  const title: string = (props?.extraInfo?.personFullName) ? `${props.title} for ${props.extraInfo!.personFullName!}` : props.title;

  return (
    <Dialog open={true} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle className="bg-black text-white">{title}</DialogTitle>

      <DialogContent style={{ overflow: 'hidden' }}>

        <Grid container direction="column" style={{ height: 'calc(100%-5px)' }}>

          <Grid item xs={12}>

            <Grid container justifyContent="space-between" style={{ padding: '10px 5px' }}>

              <Grid item className={classes.leftBox}>

                {/*reserved for future use*/}

              </Grid>

              <Grid item className={classes.rightBox}>

                <TextField
                  name="searchForExistingProjectText"
                  label={props.searchBoxLabel}
                  onChange={handleSearchTextChange}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}
              style={{ height: '600px' }}>
              <GridComponent height={'550px'}
                dataSource={records ?? []}
                //allowScrolling={true}
                ref={grid => setGridInstance(grid as GridComponent)}
                selectionSettings={{ type: 'Single' }}
                //rowSelected={rowSelected}
                allowResizing={true}

                allowSorting={true}

                allowFiltering={true}
                allowScrolling={true}
                filterSettings={{ type: 'Excel' }}
                dataBound={handleDataBound}
                columns={[
                  {
                    field: 'fullName', headerText: 'Name', template: (data: IQueryableContact) =>
                      <CrmContactHyperlink name={data.fullName} crmId={data.crmId} />
                  },
                  { field: 'jobTitle', headerText: 'Title' },
                  { field: 'companyName', headerText: 'Company' },
                  { field: 'email', headerText: 'Email' },
                  {
                    headerText: 'Actions', template: (data: IQueryableContact) =>
                      <IconButton size="small" onClick={() => handleItemSelection(data)}>
                        <AddIcon />
                      </IconButton>
                  },
                ]}
              />
            </Grid>

          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between" style={{ width: '100%', marginLeft: '16px' }}>

          <Grid item className={classes.leftBox}>
            {/*future use*/}
          </Grid>
          <Grid item className={classes.rightBox}>
            <Button onClick={handleCancel}>Cancel</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}