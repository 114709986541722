import React, {useEffect, useRef, useState} from 'react';
import useAthenaClient from "hooks/useAthenaClient";
import {Button, IconButton} from "@material-ui/core";
import {GetApp} from "@material-ui/icons";
import CoreFile from "../core/CoreFile";
import CoreDebug from "../core/CoreDebug";
import CopySpan from "../../components/dashCore/CopySpan";
import _ from "lodash";
import {useDashResolverContext} from "../../providers/DashResolver/DashResolver";
import {IExportProgressGraphicWC} from "../wc/ExportProgressGraphicWC";
import ExportProgressGraphic from "./ExportProgressGraphic";

interface ExportBuildingProgressProps {
  visible: boolean;
  exportId?: string | null;
  fileName: string;
  fileData: string;
  fileType: string;
  pollingInterval: number; // seconds
  onDownloadReady?: () => void;
  autoDownload?: boolean;
  entity: string | null;
}

enum ExportState {
  ERROR = -1,
  INITIAL_STATE = 0,
  BEFORE_PROCESSING = 1,
  PROCESSING_RECORDS = 2,
  COMPLETE = 3,
}


const ExportBuildingProgress = (props: ExportBuildingProgressProps) => {
  const {athenaClient} = useAthenaClient('ExportBuildingProgress');
  const [progress, setProgress] = useState(ExportState.INITIAL_STATE);
  const [exportId, setExportId] = useState(props.exportId);
  const [downloadURL, setDownloadURL] = useState<string | null>(null);
  const [tokenState, setTokenState] = useState<string>();

  const [currentCount, setCurrentCount] = useState<number | undefined | null>(null);
  const [totalCount, setTotalCount] = useState<number | undefined | null>(null);
  const [errorMessage, setErrorMessage] = useState<number | undefined | null>(null);
  const log = CoreDebug.getConsoleLogFunc(true);

  if (props.exportId !== exportId) {
    setExportId(props.exportId);
    log('called setExportId(props.exportId);', props.exportId)
  }

  useEffect(() => {
    let timeoutId: unknown = null;

    const getExport = async () => {
      if (athenaClient !== null && exportId && typeof exportId === 'string') {
        const result = await athenaClient.getExport(exportId);
        const token = athenaClient.getToken();
        setTokenState(token);
        if (progress === ExportState.INITIAL_STATE) {
          setProgress(ExportState.BEFORE_PROCESSING);
        }
        if (result.totalCount) {
          setProgress(ExportState.PROCESSING_RECORDS)
          setCurrentCount(result.currentCount);
          setTotalCount(result.totalCount);

        }
        if (result && result.timeFinished !== undefined && result.location) {
          setProgress(ExportState.COMPLETE);
          setCurrentCount(result.currentCount);
          setTotalCount(result.totalCount);
          setDownloadURL(result.location ?? null);

          if (props.onDownloadReady) props.onDownloadReady();

          log('right before download');
          if (props.autoDownload) {
            // File.downloadFile(result.location, props.fileName, token);
            // startDownload(token);
          }

        } else {
          timeoutId = setTimeout(() => {
            getExport();
          }, props.pollingInterval)
        }
      }
    }
    if (athenaClient && typeof exportId === 'string') {
      getExport();
      console.log('called getExport()', props.exportId)
    }

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    }
  }, [athenaClient, props.exportId]);

  function startDownload() {
    if(props?.entity === 'projects'){

      CoreFile.downloadFile(props.fileData, props.fileName, tokenState, props.fileType, props.entity).then(() => log('download started'));
    }
    else if(props?.entity === 'people'){
    CoreFile.downloadFile(downloadURL as string, 'DASH-file-' + CoreFile.getFilenameSafeDateTime(), tokenState, props.fileType, props.entity)
        .then(() => log('download started'));
    }
  }

  function handleDownloadClick() {
    // leave this function for a point of indirection in case we do something slightly different before or after
    startDownload();
  }

  if (!props.exportId && props.entity === 'project') {
    return <div></div>
  }

  const k = "123"; //new Date().getTime().toString();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
      <div className="text-center text-bold">
        {props.entity !== 'projects' && progress === ExportState.BEFORE_PROCESSING &&
            <React.Fragment key={k}>
                <img src={'/images/gifs/loading_truck.gif'}
                     style={{height: '300px'}}
                     alt="Building..."/>
                <p>
                    Please hold while we prepare your record(s) for exporting.<br/>
                    This may take a few minutes.
                </p>
            </React.Fragment>
        }
        {(props.entity !== 'projects' ? progress === ExportState.PROCESSING_RECORDS : !props.fileData) &&
            <React.Fragment key={k}>
            <ExportProgressGraphic amountComplete={currentCount as number / (totalCount as number)} />

                <p className="text-bold">
                  {currentCount && totalCount ?
                    <>Records: {currentCount}/{totalCount}<br/></>
                      : <br/>
                  }
                    <br/>
                    Your file is being built.
                    This may take a few minutes.
                </p>
            </React.Fragment>
        }
        {(props.entity !== 'projects' ? progress === ExportState.COMPLETE : props.fileData)&&
            <React.Fragment key={k}>
                <ExportProgressGraphic amountComplete={1} />
                <p>
                    Your export is ready! Click the button below to download.
                </p>

                <div>
                    <Button endIcon={<GetApp/>}
                            style={{color: 'black'}}
                            onClick={handleDownloadClick}>Download</Button>
                </div>
            </React.Fragment>
        }
        {props.entity !== 'projects' && progress === ExportState.ERROR &&
            <React.Fragment key={k}>
                <p>There was an error during the export.</p>
                <br/>
                <p>{errorMessage}</p>
                <p className="text-white">
                    Export ID: <CopySpan text={(exportId as string)}/>
                </p>
            </React.Fragment>
        }
      </div>
  );
}

export default ExportBuildingProgress;