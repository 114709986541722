import ExportBuildingProgress from 'features/export/ExportBuildingProgress';
import useAthenaClient from 'hooks/useAthenaClient';
import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import AthenaClient, {
  ExportRequestBody,
  ExportSchema,
  IExport,
  IExportSchema,
  ITemplate,
  Template,
} from 'services/apiClients/AthenaClient';
import QueryConvertor, {encodeForAthenaFilters} from 'services/search/QueryConvertor';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';

import {flexState} from '../core/flexState';
import {ExportAndSchemaContext} from './ExportAndSchemaProvider';
import {makeExportRequestBody, onlyDefaultPeopleSchema, onlyDefaultProjectSchema} from './exportUtil';
import CoreWindow from "../core/CoreWindow";
import CoreLocation from "../core/CoreLocation";
import CoreTime from "../core/CoreTime";
import {remapSchemaForServer} from "./remapSchemaForServer";
import PreloadImages from "../../components/dashCore/PreloadImages";
import {prepareAthenaFriendlySearchString} from "../../hooks/useDashUrlInfo";
import globalState from "../../app/state/GlobalState";
import Axios from "axios";
import { makeSimpleTemplate } from './templateUtil';

const EXPORT_STATUS_POLLING_INTERVAL = 10000; // 10 seconds
// TODO: get this from env file

type LastExport = {
  exportId: string;
  expires: Date;
}

interface ExportingDialogProps {
  open: boolean;
  mode?: string;
  onClose: () => void;
  pathAndSearch?: string;
  recordCount?: number;
  entity: string | null;
  entityId?: string | null;
  templateId?: string | null;
  adhocTemplate?: ITemplate | null;
  fileType?: string | null;
}

export default function ExportingDialog(props: ExportingDialogProps) {
  const exportAndSchemaContext = useContext(ExportAndSchemaContext);
  const {athenaClient} = useAthenaClient();
  const location = useLocation();
  const [urlSearchParams,] = useSearchParams();
  const [exportId, setExportId] = useState<string | null | undefined>(null);
  const queryConvertor = new QueryConvertor();
  const dashQuery = queryConvertor.createDashQuery(location, urlSearchParams);
  const [hasProcessCompleted, setHasProcessCompleted] = useState(false);
  const [azureFunctionResultFileName, setAzureFunctionResultFileName] = useState<string>('')
  const [azureFunctionResultFileData, setazureFunctionResultFileData] = useState<string>('');
  //// const recordCount = props.entityId ? 1 : useRecoilValue(flexState('current-record-count'));
  const recordCount = props.entityId ? 1: globalState.currentRecordCount;

  console.log('!!! props', props);

  useEffect(() => {
    console.log('useEffect', {props});

    async function createExport() {
      console.log('!@@! Create Export')
      let exportSchema: ExportSchema | undefined;
      const fetchTemplates = async (entity: string) => {
        const client = athenaClient as AthenaClient;
        let template: ITemplate | undefined;
        let templateIdForAzureFunction: string | undefined;

        if (props.templateId) {
          //console.log('!!! 1')
          template = await client.getTemplate(props.templateId);
          exportSchema = template.schema;
          exportSchema = remapSchemaForServer(exportSchema as ExportSchema);
          if(entity === 'projects'){
            templateIdForAzureFunction = props.templateId;
          }
        } else if (props.adhocTemplate) {
          // from unsaved template
          //console.log('!!! 2')
          exportSchema = props.adhocTemplate.schema;
          exportSchema = remapSchemaForServer(exportSchema as ExportSchema);
        } else {
          //console.log('!!! 3')
          const data = await client.getTemplates(exportAndSchemaContext.currentUser.userPrincipalName ?? '', entity, 'searchResults');

          if (data && data.length < 1) {
            const schema = await client.getQueryableSchema(entity);
            const limitedSchema = entity === 'projects'
                ? onlyDefaultProjectSchema(schema)
                : onlyDefaultPeopleSchema(schema);
            //console.log('!@@! limitedSchema', limitedSchema)
            const limitedSchemaAfterRemap = remapSchemaForServer(limitedSchema as IExportSchema, entity === 'projects');
            //console.log('!@@! limitedSchema after remapSchemaForServer', {limitedSchema, limitedSchemaAfterRemap});
            exportSchema = limitedSchemaAfterRemap as ExportSchema;
            if(entity === 'projects'){
              template = makeSimpleTemplate(exportAndSchemaContext.currentUser.userPrincipalName ?? '', entity, 'searchResults', `${exportAndSchemaContext.currentUser.userPrincipalName}-search-result-view`, exportSchema);
              const newTemplate: ITemplate | undefined = await athenaClient?.createTemplate(template as Template);
              templateIdForAzureFunction = newTemplate?.id;
            }
          } else {
            //console.log('!aad - from saved template', (data[0].schema as IExportSchema).items?.properties);
            exportSchema = remapSchemaForServer(data[0].schema as IExportSchema);
            if(entity === 'projects'){
              templateIdForAzureFunction = data[0].id;
            }
            //console.log('!aae - from saved template', exportSchema.items?.properties);
          }
        }

        // note file card * after search
        //const searchSyntax = `search=${dashQuery.search ?? ''}*`;
        // FIX
        let encodedSyntax = '';
        if (props.entityId) {
          encodedSyntax = `${entity}/${props.entityId}`;
        } else {
          const searchTweaked = prepareAthenaFriendlySearchString(dashQuery.search);
          const searchSyntax= `search=${searchTweaked ?? ''}`;
          console.log('!@#99 searchSyntax', searchSyntax);
          let filterEncodedStr = encodeForAthenaFilters(dashQuery.filters);
          filterEncodedStr = encodeURIComponent(filterEncodedStr);
          const filterSyntax = `filter=${filterEncodedStr}`;
          //const encodedSyntax = `${entity}?` + encodeURIComponent(searchSyntax) + '&' + encodeURIComponent(filterSyntax);
          encodedSyntax = `${entity}?` + searchSyntax + '&' + filterSyntax;
          console.log('records syntax from export', { searchSyntax, filterSyntax, encodedSyntax });
        }
        if(entity === 'people'){
          const exportRequestBody = makeExportRequestBody(new ExportSchema(exportSchema), encodedSyntax, props.fileType);
          const result = await athenaClient?.createExport(new ExportRequestBody(exportRequestBody));

          if (result) {
            setExportId(result.exportId as string);
            localStorage['lastExport'] = JSON.stringify({
              exportId: result.exportId,
              expires: CoreTime.addMinutesToTime(new Date(), 20)
            });
          }
        }
        if(entity === 'projects'){
          try{
            const mimeType = (props.fileType === 'xlsx')
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'application/xml';

            const headers = {'Access-Control-Allow-Origin' : '*'};
            const templateForAzureFunction = exportSchema;

            const exportServiceURL = process.env.REACT_APP_ENV === 'prod' ? 'https://prod-dashexportapi.azurewebsites.net' : 'https://dev-dashexportapi.azurewebsites.net';
            const exportServiceCode = process.env.REACT_APP_ENV === 'prod' ? 'MZGwYdc7c-fTJDLn3G2tpuMlcFlAa-pb2gJcN0CgjcUbAzFuLPwZ_A==' : '85MrrKShIX3p6sYlSo5Yd6hD5ZoVd_XVhi6MCXJD0ZASAzFuSZOpHQ==';
            const dashExportURL = exportServiceURL + '/api/ExportProjects?code=' + exportServiceCode
            const azureFunctionResult = await Axios.post(dashExportURL,
            {
              templateId: templateIdForAzureFunction,
              searchString: encodedSyntax,
              fileType: mimeType,
              requestingUser: exportAndSchemaContext.currentUser.userPrincipalName,
              template: templateForAzureFunction,
            },
            {
              headers: headers,
            });

            setAzureFunctionResultFileName(azureFunctionResult.data.FileName);
            setazureFunctionResultFileData(azureFunctionResult.data.FileData);

          }catch(error){
            console.log(error);
          }
        }
      }

      if (props.entity) {
        fetchTemplates(props.entity);
      } else {
        throw Error('Entity should never be null');
      }
    }

    function recoverLastExport() {
      const msg = 'Could not find your last export. It may have already expired.';
      const data = localStorage.getItem('lastExport');
      if (!data) {
        props.onClose();
        alert(msg);
        return;
      } else {
        const lastExport = JSON.parse(data) as LastExport;
        console.log('!@@! did it come back as a Date', typeof lastExport.expires);
        if (new Date() > lastExport.expires) {
          props.onClose();
          alert(msg);
          return;
        } else {
          setExportId(lastExport.exportId);
        }
      }
    }

    if (athenaClient) {
      if (props?.mode === 'recover') {
        recoverLastExport();
      } else {
        createExport();
      }
    }
  }, [athenaClient]);

  function handleDownloadReady() {
    setHasProcessCompleted(true);
  }

  function handleClose() {
    props.onClose();
  }

  function handleWithNewTab() {
    if (exportId) {
      const baseUrl = CoreLocation.getBaseUrl();
      CoreWindow.openUrlInNewTab(`${baseUrl}/exports`, {exportId: exportId});
      props.onClose();
    }
  }

  const images = [
    "building_0.png",
    "building_100.png",
    "building_20.png",
    "building_40.png",
    "building_60.png",
    "building_80.png",
    "crane-old.gif",
    "crane_v2.gif",
    "loading_truck.gif"
  ].map(filename => `/images/gifs/${filename}`);

  return (
      <>
        <div>
          <PreloadImages imageSources={images}/>
        </div>

        <Dialog open={props.open} onClose={props.onClose}>
          <DialogTitle className="bg-black text-white">
            Exporting {recordCount} {props.entity === 'projects' ? 'Project': 'People' } Records
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>

                <ExportBuildingProgress exportId={exportId}
                                        fileName={azureFunctionResultFileName}
                                        fileData={azureFunctionResultFileData}
                                        fileType={props.fileType ?? ''}
                                        pollingInterval={EXPORT_STATUS_POLLING_INTERVAL}
                                        onDownloadReady={handleDownloadReady}
                                        visible={true}
                                        entity={props.entity}/>

              </Grid>
            </Grid>
            <p className="text-white">
              {props.templateId}
            </p>
          </DialogContent>
          <DialogActions>
            {/*{!hasProcessCompleted &&*/}
            {/*    <span className="text-blue-300 underline pointer"*/}
            {/*          onClick={handleWithNewTab}*/}
            {/*    >Use new tab to wait instead.</span>*/}
            {/*}*/}
            <Button onClick={handleClose}>
              {hasProcessCompleted ? 'Close' : 'Cancel'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );

}