import React from "react";
export default function DashDivider(props: {
    width: string | number,
    height: string | number,
    title?: string,
    backgroundColor?: string,
    display?: string}
  ) {

  const display = props.display ?? 'block';

  return (
    <div style={{width:props.width, height:props.height, backgroundColor: props.backgroundColor, display}}
         title={props.title ?? undefined}>
    </div>
  );
}