import AutoCompleteSearcher from "./AutocompleteSearcher";
import {ISearchableFacetItem} from "./localTypes";

function filterSortMethod(filter?: string) {
  if (!filter) {
    return (a: ISearchableFacetItem, b: ISearchableFacetItem) => {
      return a.value.localeCompare(b.value, undefined, { sensitivity: 'base' });
    };
  }
  return (a: ISearchableFacetItem, b: ISearchableFacetItem) => {
    return b.count - a.count;
  };
}

// export function adjustCountAndOrder(acs: AutoCompleteSearcher, myMemberCounts: CaseInsensitiveDictionary<number>, filter?: string) {
//   console.log('%% filter',filter);
//   const filtered = acs
//       .search(filter ? filter.toLowerCase() : '')
//       .map(item => {
//         const count = myMemberCounts.get(item.value.toLowerCase()) ?? 0;
//         const displayWithCount = (count) ? `${item.display} (${count})`: `${item.display}`;
//         return {
//           ...item,
//           count,
//           displayWithCount,
//         };
//       })
//       .sort(filterSortMethod(filter));
//   return filtered;
// }

function lowerCaseCountRecord(input: Record<string, number>): Record<string, number> {
  const output: Record<string, number> = {};

  for (const key of Object.keys(input)) {
    const lowerCasedKey = key.toLowerCase();

    if (output[lowerCasedKey]) {
      output[lowerCasedKey] += input[key];
    } else {
      output[lowerCasedKey] = input[key];
    }
  }

  return output;
}


export function adjustCountAndOrder2(acs: AutoCompleteSearcher, memberCounts: Record<string, number>, filter?: string) {
  console.log('!@@ Autocomplete util adjustCountAndOrder2 filter', {acs,memberCounts,filter});
  memberCounts = lowerCaseCountRecord(memberCounts);
  const filtered = acs
      .search(filter ? filter.toLowerCase() : '')
      .map(item => {
        const count = memberCounts[item.value.toLowerCase()] || 0;
        const displayWithCount = count ? `${item.display} (${count})` : `${item.display}`;
        return {
          ...item,
          count,
          displayWithCount
        };
      })
      .sort(filterSortMethod(filter));

  return filtered;
}


















