import ReloadWindowButton from 'components/dashCore/ReloadWindowButton';
import useLatestVersion from 'hooks/useLatestVersion';
import useToast from 'hooks/useToast';
import { FC, useEffect } from 'react';
import packageJson from '../../../package.json';

//declare global {
//let APP_VERSION: string;
//}

const APP_VERSION = packageJson.version;

const semverGreaterThan = (latestVersion: string, currentVersion: string) => {
  if (latestVersion && currentVersion) {
    const versionsA = latestVersion.split(/\./g);

    const versionsB = currentVersion.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());

      if (a === b) continue;

      return a > b || isNaN(b);
    }
  }

  return false;
};

const Reloader = () => {
  const refreshCacheAndReload = () => {
    sessionStorage.setItem('recentlyUpdated', 'true');
    sessionStorage.setItem('showWhatsNewNotification', 'true');
    if ('caches' in window && caches) {
      caches.keys().then(function (names) {
        for (const name of names) caches.delete(name);
      });
    }
    window.location.reload();
  };
  return <ReloadWindowButton callback={refreshCacheAndReload} />;
};

export type CacheBusterProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const CacheBuster: FC<CacheBusterProps> = ({ children }) => {
  const { data, loading: versionCheckLoading } = useLatestVersion();
  const { displayToast } = useToast();

  const latestVersion = data?.version ?? null;

  useEffect(() => {
    const currentVersion = APP_VERSION;

    const shouldReload =
      !versionCheckLoading && latestVersion && currentVersion && semverGreaterThan(latestVersion, currentVersion);

    if (shouldReload) {
      displayToast(
        'An updated version of the app is available. Please reload the page to update.',
        'info',
        Reloader,
        false,
      );
    }
  }, [data, displayToast, versionCheckLoading, latestVersion]);

  useEffect(() => {
    // Check if recently updated
    const recentlyUpdated = sessionStorage.getItem('recentlyUpdated');
    if (recentlyUpdated) {
      sessionStorage.removeItem('recentlyUpdated');
      displayToast('Pancake was updated successfully.', 'info');
    }
  }, [displayToast]);

  return <>{children}</>;
};

export default CacheBuster;
