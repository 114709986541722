import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Switch} from "@material-ui/core";
import useCurrentUser from "hooks/useCurrentUser";
import {FacetSettingsInfo, SideNavChildrenProps} from "types/searchTypes";
import DashDivider from "components/DashDivider";
import experienceFacetState from "app/state/ExperienceFacetState";
import {useObserver} from "mobx-react";
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {serialize} from "../../FacetSideNav/serdeUtil";
import useFacetSelections from "features/Facets/hooks/useFacetSelections";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  switchControl: {},
  textFieldControl: {}
}));

const Experience: React.FC<SideNavChildrenProps> = (props) => {
  const classes = useStyles();
  //const currentUser = useCurrentUser();
  const [mounted, setMounted] = useState(false);
  const searchParams = useSearchParams();

  const setPristine = (value: boolean) => {
    if (props.setPristine) {
      props.setPristine(value);
    }
  }

  useEffect(() => {
    function resetExternalState() {
      experienceFacetState.reset();
    }

    if (!mounted) {
      resetExternalState();
      setMounted(true);
    }
  }, [mounted]);

  useEffect(() => {
    if (props.pristine === true) {
      experienceFacetState.reset();
    }
  }, [props.pristine]);

  useEffect(() => {
    if (!experienceFacetState.pristine) {
      if (experienceFacetState.expMode === 'hp') {
        const hds = experienceFacetState.latestHireDateShort;
      //  sp['hireDate'] =
      }
    }
  }, [experienceFacetState.expMode, experienceFacetState.expMinYears]);


  // function updateHireDate(max: string) {
  //   const sp = Object.fromEntries(searchParams.entries());
  //   const newValues = [min.replace(',', ''), max.replace(',', '')];
  //   // get current search params to manipulate to change state of filtering
  //   const fsi: FacetSettingsInfo = {field: props.field, mode: 'between', values: newValues};
  //   sp[props.field] = serialize(fsi);
  //   const newSearchParams = new URLSearchParams(sp);
  //   //remove by key if the range is completely devoid of information
  //   if (newValues[0] === '' && newValues[1] === '') {
  //     newSearchParams.delete(props.field);
  //   }
  //   setSearchParams(newSearchParams, { replace: true, state: { source: props.field } });
  //   console.log('!@#! how updateMySearchParams sees things leaving func', {min,max, sp, newSearchParams});
  // }

  // const [internalChecked, setInternalChecked] = React.useState(true);
  // const [minYears, setMinYears] = React.useState<string>('1');
  // const [pristine, setPristine] = React.useState<boolean>(true);
  // const currentUser = useCurrentUser();
  //
  // useEffect(() => {
  //   if (props.values && props.values.length > 0 && props.values[0] === 'external') {
  //     setInternalChecked(true);
  //   }
  //   if (props.values && props.values.length > 1) {
  //     const minYearsFromProps = typeof props.values[1] === 'string' ? props.values[1]: props.values[1].toString();
  //     if (minYearsFromProps !== minYears) {
  //       setMinYears(minYearsFromProps);
  //     }
  //   }
  // },[props.values]);
  //

  //
  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const intValue = parseInt(event.target.value);
    experienceFacetState.setExpMinYears(intValue);
    setPristine(false);
  };

  const getInternalChecked = () => {
    return experienceFacetState.expMode !== 'industry';
  };

  const setInternalChecked = (checked: boolean) => {
    experienceFacetState.expMode = checked ? 'hp': 'industry';
    setPristine(false);
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalChecked(event.target.checked);
  };

  //console.log(currentUser);
  if (experienceFacetState.pristine) {
    setPristine(experienceFacetState.pristine);
  }

  return useObserver(() =>
      <>
        <div className="bg-white">
          <div className="flex flex-row items-center" style={{border: '1px dotted #c00', padding: '10px'}}>
            <div>Industry</div>
            <div><Switch
                checked={getInternalChecked()}
                onChange={handleSwitchChange}
                name="checked"
                color="primary"
            /></div>
            <div>Hensel Phelps</div>
          </div>
          <DashDivider width={'10px'} height={'20px'} />
          <TextField
              id="minimum-years"
              label="Minimum Years"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={experienceFacetState.expMinYears}
              onChange={handleMinChange}
              className={classes.textFieldControl}
          />
        </div>
      </>
  );
};

export default Experience;
