import React from "react";
import {CircularProgress} from "@material-ui/core";

export function DashItemProgress() {
  return (
      <CircularProgress style={{color: '#86ad8a'}} thickness={5} />
                        // data-color="secondary"
                        // size="60"
                        // thickness={5}
                        // style={{color: '#1975A6'}}/>
  );

}
