import React from 'react';
import './App.css';
import 'features/Facets/debounced-input';
import PrimaryAppBar from 'components/PrimaryAppBar';
import useCurrentUser from 'hooks/useCurrentUser';
import useSetCurrentUser from 'hooks/useSetCurrentUser';
import {Routes, Route, Navigate} from "react-router-dom";
import SearchPage from "pages/SearchPage";
import NewProjectPage from "pages/NewProjectPage";
import {registerLicense} from '@syncfusion/ej2-base';
import ExportDetailDialog2 from "../detailViews/shared/ExportDetailDialog2";
import SearchGrid from "../search/SearchGrid";
import PersonView from "../detailViews/PersonView";
import Loader from "../dashCore/Loader";
import {isEmptyObject} from "../../utils/general";
import ClearShortcut from "../dashCore/ShortcutsComponent";
import ProjectView from "../detailViews/ProjectView";
import ProjectDeletionDialog from "../Top/ProjectDeletionDialog";
import Redirect from "../../features/coreComponents/Redirect";
import GridDialogTesting from "../../app/GridDialogTesting";
import Testing from "../../pages/Testing";
import {GlobalDebug} from "../../utils/removeConsole";
import Bounce from "../../pages/Bounce";
import ClearPage from "../../pages/ClearPage";

function App() {
  useSetCurrentUser();
  const currentUser = useCurrentUser();
  console.log('currentUser: ', currentUser);

  // Registering Syncfusion license key
  registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiUH5Wc3NUR2JeWUc=');

  if (process.env.REACT_APP_ENV !== 'local') {
    GlobalDebug.hideConsole();
  }

  //const peopleHome = { pathname: '/people', search: '?status=oneof."Active"'};
  const projectsHome = { pathname: '/projects', search: '?nonHenselPhelpsProject=oneof."false"'};

  //if (isNullish(currentUser)) {
  if (isEmptyObject(currentUser)) {
    return <Loader />;
  }

  return (
      <div className="App">
        <div id="appContainer">

          <div id="appBarBlock">
            <PrimaryAppBar appName="DASH" />
            <ProjectDeletionDialog />
          </div>

          <div id="mainContentBlock">
            <Routes>
              <Route path="/" element={<Navigate to={projectsHome} replace />} />
              <Route path="/bounce" element={<Bounce />} />
              <Route path="/clear" element={<ClearPage />} />
              <Route path="/people/:id" element={<PersonView />} />
              <Route path="/projects/create" element={<NewProjectPage />} />
              <Route path="/projects/:id" element={<ProjectView />} />
              {/*<Route path="/:entityName/" element={RedirectToEntity}/>*/}
              <Route path="/:entityName" element={<SearchPage />} />
              <Route path="/tree2" element={<ExportDetailDialog2 entityName={'people'}/>} />
              <Route path="/search" element={<SearchGrid />} />
              <Route path="/dialog" element={<GridDialogTesting />} />
              <Route path="/test" element={<Testing />} />
              <Route path="/redirect/:entityName" element={<Redirect />}/>
            </Routes>
          </div>
        </div>

        <ClearShortcut />
      </div>
  );
}
export default App;
