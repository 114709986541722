import {ApiProjectReference, IApiContact, IApiProject} from "services/apiClients/AthenaClient";

export function createProjectContactForDisplayModelEx(contact: IApiContact, projectId: string, project?: IApiProject) {
  const p = project;

  const rs = project?.references;
  const r = rs?.find(x => x.contactId === contact.id);

  if (!contact.fullName) {
    contact.fullName = contact.firstName + ' ' + contact.lastName;
  }

  // from angularJS-dash
  // reference.contactId = contact.id;
  // reference.company = contact.companyName; // default to current companyName
  // reference.emailAddress = contact.email;
  // reference.officePhone = contact.businessPhone;
  // reference.name = contact.firstName + ' ' + contact.lastName;
  // reference.crmUrl = vm.getCrmContactUrl(contact.crmId);
  // reference.jobTitle = contact.jobTitle; // current title
  //
  // // project specific
  // reference.title = contact.jobTitle; // default to current title
  // reference.companyOnProject = contact.companyName;

  // FOR NEW STYLE PROJECT REFERENCES - CONTACTS / PROJECT CONTACTS
  const obj = {
    projectId: projectId,
    contactId: contact.id,
    crmId: contact.crmId,
    isManagedByCrm: !!contact.crmId,
    fullName: contact.fullName,
    name: contact.firstName + ' ' + contact.lastName,
    jobTitle: contact.jobTitle,
    companyName: contact.company?.name ?? '',
    company: contact.company?.name ?? '',
    emailAddress: contact.email,
    officePhone: contact.businessPhone,
    companyId: contact.companyId,
    email: contact.email,
    email2: contact.email2,
    businessPhone: contact.businessPhone,
    mobilePhone: contact.mobilePhone,
    homePhone: contact.homePhone,
    streetAddress: contact.streetAddress,
    streetAddress2: contact.streetAddress2,
    city: contact.city,
    state: contact.state,
    zip: contact.zip,
    country: contact.country,
    crmModifiedBy: contact.crmModifiedBy,
    crmModifiedOn: contact.crmModifiedOn,
    quoteCommendation: r?.quoteCommendation,
    roleOnProject: r?.roleOnProject,
    referenceType: r?.referenceType,
    comments: r?.comments,
    companyOnProject: r?.companyOnProject,
    //commentsOnProject: r?.comments,
  };

  // if (project) {
  //   const ref = project?.references?.find(x =>
  //       x.contactId === .id);
  //   if (ref) {
  //     obj.roleOnProject =
  //   }
  // }

  return obj;
}

// FOR OLD STYLE PROJECT REFERENCES
export function createProjectReferenceForDisplayModelEx(projectRefId: string, projectId: string, project: IApiProject) {
  console.log('$$$$ projectvalue is', project)
  const rs = project.references as ApiProjectReference[];
  const r = rs?.find(x => x.contactId === projectRefId);
  console.log('$$$$ x.contactId === projectRefId', {r, rs, projectRefId, contactIds: rs?.map(x => x.contactId) })
  const firstName = r?.firstName;
  const lastName = r?.lastName;
  const fullName = `${firstName} ${lastName}` ?? (r as unknown as {name: string})?.name;


  const obj = {
    projectId: projectId,
    contactId: projectRefId,
    crmId: undefined,
    isManagedByCrm: false,
    fullName: fullName,

    firstName: firstName,
    lastName: lastName,
    jobTitle: r?.jobTitle,
    roleOnProject: r?.jobTitle,
    companyName: r?.company, // .company?.name ?? '',
    companyOnProject: r?.company,
    //companyId: contact.companyId,
    email: r?.emailAddress,
    email2: r?.email2,
    businessPhone: r?.officePhone,
    // mobilePhone: contact.mobilePhone,
    // homePhone: contact.homePhone,
    // streetAddress: contact.streetAddress,
    // streetAddress2: contact.streetAddress2,
    // city: contact.city,
    // state: contact.state,
    // zip: contact.zip,
    // country: contact.country,
    referenceType: r?.referenceType,
    comments: r?.quoteCommendation,
  };

  // if (project) {
  //   const ref = project?.references?.find(x =>
  //       x.contactId === .id);
  //   if (ref) {
  //     obj.roleOnProject =
  //   }
  // }

  return obj;
}