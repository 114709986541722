import useCurrentUser from "../../hooks/useCurrentUser";
import {useEffect, useState} from "react";

const Tracker = (props: { featureName?: string }) => {
  // note: feature name could be sectionName or other things
  const user = useCurrentUser();
  const [shortName, setShortName] = useState<string>()
  const upn = user?.userPrincipalName;

  useEffect(() => {
    setShortName(createShortName(user?.userPrincipalName));
  }, [user]);

  const createShortName = (name?: string) => {
    if (!name) return name;
    return name.replace('@henselphelps.com', '');
  }

  if (!shortName) {
    return null;
  }
  const ticks = new Date().getTime();
  const url = process.env.REACT_APP_PRINTING_BASE_URL + '/beacon/' + props.featureName + '/' + shortName;

  return (
      <img src={url} style={{width:'0',height:'0'}} />
  );
}

export default Tracker;