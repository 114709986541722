import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import CoreLocation from "features/core/CoreLocation";

interface HomeButtonProps {
  visible?: boolean;
}

const HomeButton: React.FC<HomeButtonProps> = ({ visible }) => {
  const navigate = useNavigate();

  const entityName = CoreLocation.getEntityName();

  const entityHome = entityName === 'people'
      ? { pathname: '/people', search: '?status=oneof."Active"'}
      : { pathname: '/projects', search: '?nonHenselPhelpsProject=oneof."false"'};

  if (visible === false) {
    return null;
  }

  return (
      <IconButton onClick={() => navigate(entityHome)}>
        <Home className={'text-white'} />
      </IconButton>
  );
};

export default HomeButton;
