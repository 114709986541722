export default class AthenaOrderbyTranslator {
  // private orderbys: { [key: string]: string } = {
  //   name: 'name',
  //   location: 'state,city,name',
  //   cost: 'cost desc,name',
  //   squareFootage: 'squareFootage desc,name',
  //   completionDate: 'completionDate desc,name',
  //   hireDate: 'hireDate desc,name',
  //   totalProjectValue: 'totalProjectValue desc,name',
  //   hpExperience: 'hireDate,name',
  //   districts: 'district,name',
  //   projectNumbers: 'projectNumbersJoined,name'
  // };
  private orderbys: { [key: string]: string } = {
    name: 'name',
    location: 'state,city,name',
    cost: 'cost,name',
    squareFootage: 'squareFootage,name',
    completionDate: 'completionDate,name',
    hireDate: 'hireDate,name',
    totalProjectValue: 'totalProjectValue,name',
    hpExperience: 'hireDate,name',
    districts: 'district,name',
    projectNumbers: 'projectNumbersJoined,name'
  };


  translate(sortName?: string | null) {
    if (!sortName || sortName.length === 0) return '';
    if (this.shouldBeReversed(sortName)) {
      return this.getReverseOrderby(sortName.slice(1));
    } else {
      return this.getOrderBy(sortName);
    }
  }

  private shouldBeReversed(sortName: string) {
    return sortName[0] === '-';
  }

  private getReverseOrderby(sortName: string) {
    if (sortName in this.orderbys) {
      return this.reverse(this.orderbys[sortName]);
    } else {
      return this.reverse(this.appendDefaults(sortName));
    }
  }

  private getOrderBy(sortName: string) {
    if (sortName in this.orderbys) {
      return this.orderbys[sortName];
    } else {
      return this.appendDefaults(sortName);
    }
  }

  private appendDefaults(sortName: string) {
    return sortName + ',name';
  }

  private reverse(orderby: string) {
    const reversed = orderby.split(',');
    reversed[0] = this.addOrRemoveDesc(reversed[0]);
    return reversed.join(',');
  }

  private addOrRemoveDesc(orderby: string) {
    const desc = ' desc';
    const descIndex = orderby.indexOf(desc);
    if (descIndex >= 0) {
      return orderby.slice(0, descIndex);
    } else {
      return orderby + desc;
    }
  }
}
