import React from 'react';


function mkCrmLinkedName(name: string, contactId: string) {
  const baseUrl = 'https://henselphelps.crm.dynamics.com';
  const pathAndSearch = `/main.aspx?appid=6bc1724a-eb95-ea11-a811-000d3a3be645&pagetype=entityrecord&etn=contact&id=${contactId}`;
  return <a href={baseUrl + pathAndSearch} target="_blank">{name}</a>;
}

export default function CrmContactHyperlink(props: {name?: string, crmId?: string}) {
  return mkCrmLinkedName(props.name ?? '', props.crmId ?? '');
}