/* eslint-disable @typescript-eslint/no-explicit-any */
export default class CoreDebug {
  static getConsoleLogFunc(showOutput?: boolean): ((...args: any[]) => void) {
    if (showOutput === undefined) {
      showOutput = true;
    }
    if (showOutput) {
      return (...args: any[]) => {
        console.log(...args);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return (..._args: any[]): void => {
        // No-op function that does nothing with the arguments
      };
    }
  }

  static passThru<T>(prefixAndComment: string | unknown, value: T) {
    console.log(prefixAndComment, value);
    return value;
  }
  static passThruIf<T>(test: boolean, prefixAndComment: string | unknown, value: T) {
    if (test) {
      console.log(prefixAndComment, value);
    }
    return value;
  }

  static publish(key: string, value: unknown) {
    const w = window as unknown as Record<string, unknown>;
    w[key] = value;
  }

  static publishFunc(key: string, value: unknown) {
    const w = window as unknown as Record<string, unknown>;
    if (!('fns' in w)) {
      w['fns'] = {} as Record<string, unknown>;
    }
    const fns = w['fns'] as Record<string, unknown>;
    fns[key] = value;
  }

  static getFunc(key: string) {
    const w = window as unknown as Record<string, unknown>;
    if ('fns' in w) {
      const fns = w['fns'] as Record<string, unknown>;
      return fns[key];
    }
  }
}
