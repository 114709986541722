import { atom, useRecoilState } from 'recoil';
import {DashQuery, FacetSettingsInfo} from 'types/searchTypes';

const defaultState: DashQuery = {
  entityName: 'people',
  search: '',
  filters: {} as {[field: string]: FacetSettingsInfo},
  skip: 0,
  top: 10,
  orderby: [] as string[]
} as DashQuery;

const dashQueryStateAtom = atom({
  key: 'dashQuery',
  default: defaultState,
});

export function useDashQueryState() {
  const [dashQueryState, setDashQueryStateProper] = useRecoilState<DashQuery>(dashQueryStateAtom);

  const setDashQueryState = (dashQueryState: DashQuery, from?: string) => {
    console.log(`@@@@@ DashQueryState update from ${from}`, dashQueryState);
    if (!from) {
      throw new Error("Illegal DashQueryState Update")
    }
    setDashQueryStateProper(dashQueryState);
  }

  return [
    dashQueryState,
    {
      setDashQueryState,
    },
  ] as const;
}

export default dashQueryStateAtom;
