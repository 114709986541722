
export class Ticker {
  public ticks: number;

  constructor() {
    this.ticks = new Date().getTime();
  }

  // update(): number {
  //   this.ticks = new Date().getTime();
  //   return this.ticks;
  // }

  toString(): string {
    return this.ticks.toString();
  }
}
