import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  searchPage: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    backgroundColor: 'gainsboro',
    width: '100%',
    height: '100%',
    marginBottom: 0,
  },
  box: {
    display: 'block',
    flex: '1 1 auto',
  },
  innerSearchPage: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%',
    height: '100%',
  },
  facetSideNavContainer: {
    width: '300px',
    height: 'calc(100vh - 96px)',
    backgroundColor: '#eee',
  },
  viewContainerContainer: {
    flex: '1',
    // backgroundColor: 'fuchsia',
  }
}));

export {
  useStyles
};