import React, { useEffect, useRef } from 'react';
import useAthenaClient from "../../hooks/useAthenaClient";
import _ from 'lodash';

const KeepWarm = () => {
  const { athenaClient } = useAthenaClient();
  const tick = useRef(0);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const handleError = () => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
    }
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      try {
        const newTick = (tick.current + 1) % 4;
        const peopleFilter = `(status eq 'Active')`;
        const projectFilter = `(nonHenselPhelpsProject eq false)`;

        switch (newTick) {
          case 0:
            athenaClient?.getAllPeople('', peopleFilter, 0, 10, 'name');
            break;
          case 1:
            athenaClient?.getAllProjectsV2('', projectFilter, 0, 10, 'name');
            break;
          case 2:
            athenaClient?.getPeopleSearchStatistics('', peopleFilter);
            break;
          case 3:
            athenaClient?.getProjectSearchStatistics('', projectFilter);
            break;
          default:
            break;
        }
        tick.current = newTick;
      } catch (error) {
        console.error('An error occurred:', error);
        handleError();
      }
    }, 60 * 1000);

    return () => {
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
      }
    };
  }, [athenaClient]);

  return <div style={{ display: 'none' }}></div>;
};

export default KeepWarm;
