import React from "react";
import {Button, Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import {DeleteOutline, EditOutlined} from "@material-ui/icons";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import {makeStyles} from "@material-ui/core/styles";

type ComponentType<T> = React.ComponentType<{ model: T }>;

type DashListProps<T extends { id: string }, C extends ComponentType<T>> = {
  list: T[] | undefined;
  showEdit?: boolean;
  showDelete?: boolean;
  showAdd?: boolean;
  addButtonText?: string;
  emptyText?: string;
  titleExpression?: (model: T) => string;
  tag?: string | number;
  onAction: (action: string, payload?: { id: string }) => void;
  Component: C;
};

const useStyles = makeStyles(() => ({
  gradientBackground: {
    background: 'linear-gradient(to right, #a1c8ff, white)',
  },
  cardHeader: {
    background: 'linear-gradient(to top, white 98%, #A6192E)',
    margin: '0 0',
    padding: '8px 12px',
    '& .MuiCardHeader-title': {
      fontSize: '1.25rem',
    }
  },
}));

function DashList<T extends { id?: string } | undefined, C extends ComponentType<T>>(props: DashListProps<T, C>) {
  const classes = useStyles();

  function handleAction(action: string, payload: { id: string } | null) {
    props.onAction(action, payload);
  }

  console.log('!@@@! DashList', props.list);

  if (props.list == null) {
    return (
        <span>{props.emptyText}</span>
    );
  }

  return (
      <div style={{width: '100%'}}>
        <div style={{width: '100%', paddingBottom: '5px'}}>
          {props.showAdd &&
              <Button startIcon={<AddCircleOutline/>} onClick={() => handleAction('new', null)}>
                {props.addButtonText}
              </Button>
          }
        </div>
        {props.list && props.list.map((model: T, index) =>
            model != null &&
            (<Card key={index} data-model-id={model.id}
                   style={{marginBottom: '15px'}}>
                  <CardHeader title={props.titleExpression && props.titleExpression(model)}
                              className={classes.cardHeader}
                              action={<>
                                {props.showEdit && (
                                    <IconButton aria-label="edit"
                                                onClick={() => handleAction('edit', {id: model.id as string})}>
                                      <EditOutlined/>
                                    </IconButton>
                                )}
                                {props.showDelete && (
                                    <IconButton aria-label="delete"
                                                onClick={() => handleAction('delete', {id: model.id as string})}>
                                      <DeleteOutline/>
                                    </IconButton>
                                )}</>}/>

                  <CardContent>
                    <props.Component model={model}/>
                  </CardContent>
                </Card>
            ))}
      </div>
  );
}

export default DashList;