import React  from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  loadingIndicator: {
    color: 'blue',
  },
}));

function FunctionalComponent(){
  const classes = useStyles();

  return (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingIndicator} />
      </div>
  );
}

export default FunctionalComponent;