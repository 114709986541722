class DebouncedInput extends HTMLElement {
  private inputElement: HTMLInputElement;
  private debounceTimeout: number | null = null;
  private _wait = 500;

  constructor() {
    super();
    this.inputElement = document.createElement('input');
    this.attachShadow({ mode: 'open' });
    this.shadowRoot?.appendChild(this.inputElement);
  }

  connectedCallback() {
    this.inputElement.addEventListener('input', this.debouncedOnChange.bind(this));
    this.copyAttributes();
  }

  disconnectedCallback() {
    this.inputElement.removeEventListener('input', this.debouncedOnChange.bind(this));
  }

  static get observedAttributes() {
    return ['wait','value','type'];
  }

  attributeChangedCallback(name: string, _oldValue: string, newValue: string) {
    const self = this as Record<string, unknown>;
    if (name === 'wait') {
      this._wait = Number(newValue);
    } else {
      console.log('@@@ attributeChangedCallback', {name, newValue});
      self[name] = newValue;
    }
    this.copyAttributes(); // Ensure all attributes are synchronized with the input element
  }

  get wait() {
    return this._wait;
  }

  set wait(value: number) {
    this._wait = value;
  }

  private copyAttributes() {
    // Convert attributes to an array and then iterate over it
    const attributesArray = Array.prototype.slice.call(this.attributes);
    for (const attr of attributesArray) {
      if (attr.name !== 'wait') {
        this.inputElement.setAttribute(attr.name, attr.value);
        console.log('!@@@ after setAttribute', {name:attr.name, value:attr.value});
      }
    }
    console.log('!@@@ before exit copyAttributes', this.inputElement);
  }


// Inside your DebouncedInput class definition
  private debouncedOnChange(event: Event) {
    if (this.debounceTimeout !== null) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      const changeEvent = new CustomEvent('debounced-change', { // <- Updated event name
        detail: {
          value: this.inputElement.value,
          target: this.inputElement
        }
      });
      this.dispatchEvent(changeEvent);
    }, this.wait);
  }

}

customElements.define('debounced-input', DebouncedInput);

export {};