import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import {FacetSettingsInfo} from "../types/searchTypes";
import {deserialize} from "../components/search/FacetSideNav/serdeUtil";
import {useState} from "react";
import {ICriteria} from "../services/SearchService";
import {encodeForAthenaFilters} from "../services/search/QueryConvertor";
import {omit} from "lodash";

function getCriteriaUtil(entityName: string, search: string, filters: {[p:string]:FacetSettingsInfo}) {
  const criteria: ICriteria = {
    entityName: entityName,
    search: search,
    filter: encodeForAthenaFilters(filters),
    orderby:''}
  return criteria;
}

export default function useDashUrlInfo() {
  const params = useParams<{ entityName?: string; id?: string }>();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchParamsDict = Object.fromEntries(searchParams.entries());
  const [stableFilters, setStableFilters] =  useState<{ [p: string]: FacetSettingsInfo }>({});

  let entityName = '';
  let id: string | undefined;

  if (params.entityName) {
    entityName = params.entityName.toLowerCase().trim();
  } else {
    entityName = location.pathname.split('/')[1].toLowerCase().trim();
  }

  if (params.id) {
    id = params.id;
  } else {
    id = location.pathname.split('/')[2];
  }

  const filters: { [p: string]: FacetSettingsInfo } = {};
  let search = '';

  const ignoredKeys = ['search', 'orderby', 'top', 'skip'];
  for (const key of Object.keys(searchParamsDict)) {
    if (!ignoredKeys.includes(key)) {
      filters[key] = deserialize(key, searchParamsDict[key]);
    }
  }

  // protect against changing object reference
  if (JSON.stringify(filters) !== JSON.stringify(stableFilters)) {
    setStableFilters(filters);
  }

  if (Object.prototype.hasOwnProperty.call(searchParamsDict, 'search')) {
    search = searchParamsDict['search'].trim();
    search = prepareAthenaFriendlySearchString(search);
  }

  const getCriteria = (excludedField?: string) => {
    const filters = excludedField ? omit(stableFilters, excludedField) : stableFilters;
    return getCriteriaUtil(entityName, search, filters);
  }

  return {
    entityName,
    id,
    filters: stableFilters,
    search,
    getCriteria };
}

// change for export consistent behavior with search
export function prepareAthenaFriendlySearchString(search: string | null | undefined, includeRemovingQuotes?: boolean): string {
  if (search === null || search === undefined) {
    return '';
  }
  const searchIncludesSpaces = (search: string): boolean => search.includes(' ');
  const searchEndsWithStar = (search: string): boolean => search.endsWith('*');
  const searchIncludesQuotes = (search: string): boolean => search.includes('"');

  if (includeRemovingQuotes) {
    search = search.replaceAll('"', '');
  }

  if (searchIncludesSpaces(search) && !searchIncludesQuotes(search)) {
    return `"${search}"`;
  } else if (searchEndsWithStar(search) || searchIncludesQuotes(search)) {
    return search;
  } else {
    return `${search}*`;
  }
}

