import React, {useEffect, useState} from "react";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {useSharedLargeCardStyles} from "./sharedStyles";
import {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
import {v4 as uuidv4} from "uuid";
import InfotipButton from "../../InfotipButton/InfotipButton";
import globalState from "app/state/GlobalState";
import {useObserver} from "mobx-react";
import ChangeViewButton from "../../dashCore/ChangeViewButton";
import _ from "lodash";

export interface IWithDashCardProps<T> {
  //id: string,
  model: T; //IApiProject;
  referenceData: IReferenceData;
}

const ObservedComponent = ({Component, index, props}) => useObserver(() => (
    globalState.hiddenDiversitySections.includes(index) ? <Component {...props} /> : null
));

const renderComponent = (Component, index, props, sectionId) => {
  if (sectionId === 'supplier-diversity-module' && [2, 3, 4].includes(index)) {
    return <ObservedComponent key={index} Component={Component} index={index} props={props}/>;
  }
  return <Component key={index} {...props} />;
};

export function withDashCard<P extends object>(
    cardName: string,
    id: string,
    Components: void[],
    infotipKey?: string,
    showSpecialChangeViewForModule?: boolean,
) {
  return function WithDashCard<T>(props: P & IWithDashCardProps<T>) {
    const classes = useSharedLargeCardStyles();
    const [cardKey, setCardKey] = useState(uuidv4());
    const sectionId = id;
    //const gs = useGlobalState();

    useEffect(() => {
      if (props.id === 'project-stakeholders-module') {
        globalState.setStakeholdersListViewOn(true);
      }
      if (props.id === 'project-staff-module') {
        globalState.setProjectStaffListViewOn(true);
      }
    }, []);

    function flipListView() {
      //console.log('flipListView', {sectionId});
      switch(sectionId) {
        case 'project-stakeholders-module':
          globalState.setStakeholdersListViewOn(!globalState.stakeholdersListViewOn);
          break;
        case 'project-staff-module':
          console.log('!!! flipListView project staff')
          globalState.setProjectStaffListViewOn(!globalState.projectStaffListViewOn);
          break;
        default:
          _.noop();
          break;
      }
    }

    const showListIcon = (sectionId === 'project-stakeholders-module' && globalState.stakeholdersListViewOn)
        || (sectionId === 'project-staff-module' && globalState.projectStaffListViewOn);

    return (
        <Card id={sectionId} className={classes.defaultCard}>
          <CardHeader title={<>{cardName}&nbsp;{infotipKey && <InfotipButton infotipKey={infotipKey}/>}</>}
                      action={showSpecialChangeViewForModule &&
                          <ChangeViewButton showListIcon={showListIcon}
                                            onClick={() => flipListView()}/>}/>

          <CardContent>
            {Components.map((Component, index) => (
                // renderComponent - contains specific business logic tied to specific state
                renderComponent(Component, index, props, sectionId))
            )}
          </CardContent>
        </Card>
    );
  };
}
