import React, {
  useState, useEffect,
  useImperativeHandle, forwardRef, useRef, ForwardedRef
} from "react";
import AthenaClient, {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {Dialog, Button} from "@material-ui/core";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import { v4 as uuidv4 } from 'uuid';
import AddSustainabilityCertification from "./AddSustainabilityCertification";
import CertificationBlock from "./CertificationBlock";
import DashList from "../DashList";
import useToast from "hooks/useToast";
import DashToaster from "patterns/DashToaster";
import {confirmDelete} from "patterns/confirmations";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import {useGlobalState} from "../../../../app/state/useGlobalState";
// end imports

const SECTION_ID = 'sustainabilityCertificationsSection';

const CertificationsSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {

      const classes = useSharedLargeCardStyles();
      const gs = useGlobalState();
      const [model, setModel] = useState<IApiProject | null>(null);
      const [saveCount, incr] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const toaster = useToast();
      const dashToaster = new DashToaster(toaster.displayToast);
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
  const [sectionKey,setSectionKey] = useState(uuidv4());
      const [dialogOpen, setDialogOpen] = useState(false);
      const [dialogMode, setDialogMode] = useState<string>();
      const [dialogId, setDialogId] = useState<string>();

      if (model === null) {
        setModel(props.model);
      }

      if (props.model === null || model === null) {
        console.log('project is null');
        return <DashItemProgress/>;
      }

      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      function handleAction(action: string, payload?: { id: string }) {
        // IApiProjectSustainabilityCertification
        switch (action) {
          case 'cancel':
            setDialogOpen(false);
            break;
          case 'edit':
            if (payload?.id) {
              setDialogMode('edit');
              setDialogId(payload.id);
              setDialogOpen(true);
            }
            break;
          case 'new':
            setDialogMode('new');
            setDialogId(undefined);
            setDialogOpen(true);
            break;
          case 'delete':
            setDialogId(undefined);
            setDialogOpen(false);
            if (confirm('Are you you want to delete this Certification?')) {
              athenaClient?.deleteSustainabilityCertification(props.model.id as string,
                  payload?.id as string).then(
                  async () => {
                    const tmpProject = await athenaClient?.getProject(props.model.id as string);
                    setModel(tmpProject);
                  }
              );
            }
            break;
          case 'close-refresh':
            athenaClient?.getProject(props.model.id as string).then(project => {
              setModel(project);
              setDialogId(undefined);
              setDialogOpen(false);
            });
            break;
        }
      }

      return (
          <>
            <DashList list={model.sustainabilityCertifications}
                      Component={CertificationBlock}
                      showEdit={hasPermission(['Contributor'],gs.userRoles)}
                      showDelete={hasPermission(['Contributor'],gs.userRoles)}
                      onAction={handleAction}
                      addButtonText={'Add Certification'}
                      showAdd={hasPermission(['Contributor'],gs.userRoles)}
                      titleExpression={(m) => m.name}
            />

            <DashDivider width="80vw" height="1px"/>
            {/*DIALOGS*/}

            {dialogOpen &&
                <AddSustainabilityCertification open={dialogOpen}
                                                parentModel={model}
                                                mode={dialogMode}
                                                id={dialogId}
                                                choices={[]}
                                                onRequestFromChild={handleAction}
                                                onInfotipClick={handleInfotipClick}
                />
            }

            <Dialog
                open={showInfotipModal}
                onClose={() => setShowInfotipModal(false)}>
              <div className={classes.innerDialogPadding}>
                <div dangerouslySetInnerHTML={{__html: content}}/>
              </div>
            </Dialog>

          </>
      );
    });

export default withDashSection('Certifications', SECTION_ID, CertificationsSection, true);

