import React, {useEffect, useState} from 'react';
import {AppBar, Button, Dialog, makeStyles, Toolbar} from '@material-ui/core';
import CarouselFooter from "./CarouselFooter";

interface CarouselProps {
  images: (string | undefined)[];
  imageNames: (string | undefined)[];
  onClose?: () => void;
}

const useStyles = makeStyles({
  transparentDialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',  // 90% opacity
  },
  appBar: {
    position: 'relative',
    backgroundColor: 'black',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
  },
  backButton: {
    fontWeight: 'bold',
  },
  forwardButton: {
    fontWeight: 'bold',
  },
  imageContainer: {
    // backgroundColor: 'black',
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navigationArea: {
    width: '15%',  // existing width setting
    height: '100%',  // new height setting
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainImageArea: {
    position: 'relative',
    flexDirection: 'column',
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    height: '100%',
    flexGrow: 1,  // Add this
  },
  imageStyle: {
    objectFit: 'scale-down',
    // Removed position: "absolute",
    width: 'auto',
    maxWidth: '70vw',
    height: '80vh',
    //border: '1px solid #ff0000',
    flexShrink: 0,
  },
  imageAndFooterWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',  // Add this
  },
  footerStyle: {
    position: 'absolute',
    bottom: '30px',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  buffer: {
    width: '100%',
    height: '10vh',
  }
});

const Carousel: React.FC<CarouselProps> = (props) => {
  const images = props.images.filter(Boolean);
  const imageNames = props.imageNames.filter(Boolean)
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();
  const [selfOpen, setSelfOpen] = useState(false);

  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image as string;
    });
    console.log('!@#91 images', images)
  }, [images]);

  const goBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goForward = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleClose = () => {
    setSelfOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleLaunchClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const url = e.currentTarget.value;

    if (url === '') {
      return; // Exit if the value is empty
    }

    const index = images.indexOf(url);

    if (index !== -1) {
      setCurrentIndex(index);
      // Logic to open the dialog, if not already open
    }
    setSelfOpen(true);
  };

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
      <>
        <div style={{display: 'block'}}>
          <input type="button" id="launch-carousel" name="launch-carousel" value={''}
                 className={'hidden'}
                 onClick={handleLaunchClick}/>
        </div>
        <Dialog fullScreen open={selfOpen} onClose={handleClose}
                PaperProps={{className: classes.transparentDialog}}>


              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Button onClick={handleClose} className={classes.closeButton}>
                    <span className={'text-white'} style={{fontSize: '2em'}}>X</span>
                  </Button>
                </Toolbar>
              </AppBar>

            <div className={classes.imageContainer}>

            <div className={classes.navigationArea} onClick={goBack}>
              {currentIndex > 0 &&
                  <Button className={classes.backButton}>
                      <span className={'text-white'} style={{fontSize: '3em'}}>&lt;</span>
                  </Button>
              }
            </div>
            <div className={classes.mainImageArea}>
              <div className={classes.imageAndFooterWrapper}>
                <img src={images[currentIndex]} className={classes.imageStyle} alt="carousel-item"/>
              </div>
            </div>
            <div className={classes.navigationArea} onClick={goForward}>
              {currentIndex < images.length - 1 &&
                  <Button className={classes.forwardButton}>
                      <span className={'text-white'} style={{fontSize: '3em'}}>&gt;</span>
                  </Button>
              }
            </div>
          </div>
          <div className={'buffer'}>
            &nbsp;
          </div>
          <div className={classes.footerStyle}>
            <CarouselFooter
                currentImageName={imageNames[currentIndex] as string}
                totalImages={images.length}
                currentIndex={currentIndex}
                onDotClick={handleDotClick}
            />
          </div>
        </Dialog>
      </>
  );
};

export default Carousel;
