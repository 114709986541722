import React, {useContext} from 'react';
import {DashInstancesContext} from 'providers/DashProvider';
import ListView from "components/search/ListView";
import {CardView} from "./cardView/CardView";
//import {SearchContext} from "app/searchContext";
import CoreLocation from "features/core/CoreLocation";
import {useObserver} from "mobx-react";
import searchStoreEx from "../../app/SearchStoreEx";

const SearchViewContainer: React.FC = () => {
  //const searchStore = useContext(SearchContext);
  const {dashState} = useContext(DashInstancesContext);
  const listOrCards = dashState?.displayModeOnSearch ?? 'list';

  return useObserver(() => (
      <React.Fragment key={searchStoreEx.lastRefreshDate.toISOString()}>
        <React.Fragment key={'search-view-container-' + CoreLocation.getEntityName()}>
          <div className={'hidden'} data-ticks={searchStoreEx.ticks}>{searchStoreEx.ticks}</div>
          {listOrCards === 'list'
              ? <ListView/>
              : <CardView/>}
        </React.Fragment>
      </React.Fragment>

  ));
}

export default SearchViewContainer;
