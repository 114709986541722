import classNames from 'classnames';

import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: theme.spacing(10),
    width: 'auto',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    borderRadius: 16,
  },
}));

type StyledButtonPrimaryProps = {
  label: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  icon?: React.ReactNode;
};

const StyledButtonPrimary: React.FC<StyledButtonPrimaryProps & Record<string, any>> = ({
  label,
  color = 'primary',
  variant = 'contained',
  onClick,
  className,
  icon,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      data-testid="button-primary"
      color={color}
      variant={variant}
      onClick={onClick}
      startIcon={icon ?? undefined}
      className={classNames(classes.button, className)}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default StyledButtonPrimary;
