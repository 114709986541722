import { atom, useRecoilState } from 'recoil';
import { CurrentUser } from 'types';

const defaultState: CurrentUser = {};

const currentUserStateAtom = atom({
  key: 'currentUserState',
  default: defaultState,
});

export const useCurrentUserState = () => {
  const [currentUserState, setCurrentUserState] = useRecoilState(currentUserStateAtom);

  return [
    currentUserState,
    {
      setCurrentUserState,
    },
  ] as const;
};

export default currentUserStateAtom;
