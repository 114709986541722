import apple from 'assets/images/unsupportedBrowserImages/appleOSLogo.png';
import chrome from 'assets/images/unsupportedBrowserImages/chromeBrowserLogoColor.png';
import firefox from 'assets/images/unsupportedBrowserImages/firefoxBrowserLogoColor.png';
import meo from 'assets/images/unsupportedBrowserImages/hpMeoTechnologyEmblem.png';
import linux from 'assets/images/unsupportedBrowserImages/linuxOSLogo.png';
import edge from 'assets/images/unsupportedBrowserImages/microsoftEdgeBrowserLogoColor.png';
import safari from 'assets/images/unsupportedBrowserImages/safariBrowserLogoColor.png';
import mWindows from 'assets/images/unsupportedBrowserImages/windowsOSLogo.png';
import { SERVICENOW_URL } from 'constants/externalLinks';

import { Button, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    textAlign: 'center',
    backgroundColor: '#F2F2F2',
    minHeight: '100vh',
    height: '100%',
  },
  meoImg: { maxWidth: 200, margin: '80px 0 32px 0' },
  unsupportedBrowserText: { fontWeight: 'bold', fontSize: '2rem' },
  browserItemContainer: {
    boxShadow: '2px 2px 10px gray',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    border: '8px solid #A6192E',
    borderRadius: 4,
  },
  browserText: {
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    paddingBottom: 24,
  },
  mainTextContainer: { padding: 24, color: '#5C5C5C' },
  mainTextEmphasized: { fontWeight: 'bold' },
  mainText: { fontWeight: 'normal' },
  browserImg: { maxWidth: theme.spacing(8), marginBottom: '2rem' },
  osText: { fontSize: '0.625rem' },
  osImg: { height: theme.spacing(2), width: 'auto' },
  helpBtn: {
    margin: '32px 0',
    backgroundColor: '#A6192E',
    color: '#FFFFFF',
    '&:hover': { backgroundColor: '#700006' },
  },
  helpBtnText: {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    '&:first-letter': { textTransform: 'uppercase' },
  },
}));

export const UnsupportedBrowserDisclaimerPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" alignContent="center" justifyContent="center">
        <Grid item>
          <img src={meo} alt={'Hensel Phelps Meo Technology banner'} className={classes.meoImg} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.unsupportedBrowserText}>Unsupported Browser</Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainTextContainer}>
          <Typography gutterBottom className={classes.mainTextEmphasized}>
            Oops! We're sorry but …
            <span className={classes.mainText}>
              {' '}
              the browser you are using is not currently supported by this application. Please select a supported
              browser below and follow the links to download.
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>Google Chrome</Typography>
          <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
            <img src={chrome} alt={'Google Chrome Logo'} className={classes.browserImg} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>Operating System</Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={mWindows} alt={'Windows Operating System Logo'} className={classes.osImg} />
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={linux} alt={'Linux Operating System Logo'} className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={apple} alt={' Apple Operating System Logo'} className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>Microsoft Edge</Typography>
          <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noopener noreferrer">
            <img src={edge} alt={'Microsoft Edge Logo'} className={classes.browserImg} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>Operating System</Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={mWindows} alt="Windows Logo" className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={apple} alt={'Apple Logo'} className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>Apple Safari</Typography>
          <a href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener noreferrer">
            <img src={safari} alt={'Apple Safari Logo'} className={classes.browserImg} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>Operating System</Typography>
            </Grid>
            <Grid item>
              <img src={apple} alt={'Apple Logo'} className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={1} className={classes.browserItemContainer}>
          <Typography className={classes.browserText}>Mozilla Firefox</Typography>
          <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">
            <img src={firefox} alt={'Firefox Logo'} className={classes.browserImg} />
          </a>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.osText}>Operating System</Typography>
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={mWindows} alt={'Windows Logo'} className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={linux} alt={'Linux Logo'} className={classes.osImg}></img>
            </Grid>
            <Grid item md={1} lg={4}>
              <img src={apple} alt={'Apple Logo'} className={classes.osImg}></img>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              size="small"
              variant="contained"
              className={classes.helpBtn}
              onClick={() => window.open(SERVICENOW_URL, '_blank')}
            >
              <Typography className={classes.helpBtnText}>Need Help?</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default UnsupportedBrowserDisclaimerPage;
