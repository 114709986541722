import React, { useState, useContext, useEffect } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import AthenaClient, {
  ApiPerson,
  IApiPerson,
} from 'services/apiClients/AthenaClient'
import { useSharedLargeCardStyles } from '../shared/sharedStyles'
import CloseIcon from '@material-ui/icons/Close'
import DashDetailsField from '../../DashDetailsField/DashDetailsField'
import numberFormatter from 'services/shared/numberFormatter'
import EntityDialog from '../shared/EntityDialog'
import { getNamedData } from '../../../utils/forms'
import PersonDetailService from '../../../services/PersonService'
import { DashItemProgress } from '../../dashCore/DashItemProgress'
import useAthenaClient from '../../../hooks/useAthenaClient'
import useSaveCount from '../../../hooks/useSaveCount'
import { useGlobalState } from '../../../app/state/useGlobalState'
import { hasPermission } from '../../../app/security/utils/securityUtil'
import AthenaAuthorize from '../../../app/security/ui/AthenaAuthorize'

export default function AboutSection(props: { person: IApiPerson }) {
  const classes = useSharedLargeCardStyles()
  const globalState = useGlobalState()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [person, setPerson] = useState<IApiPerson | null>(null)
  const [saveCount, saved] = useSaveCount()
  const { athenaClient } = useAthenaClient()

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  useEffect(() => {
    if (athenaClient && props.person.id) {
      const personService = new PersonDetailService(athenaClient)
      personService
        .getPerson(props.person.id as number)
        .then((value: IApiPerson) => {
          setPerson(value)
          globalState.setCurrentPerson(value)
        })
    }
  }, [saveCount, athenaClient])

  async function save() {
    const data = getNamedData(document.querySelector('.MuiDialogContent-root'))
    const personDetailService = new PersonDetailService(
      athenaClient as AthenaClient,
    )
    await personDetailService.updatePerson(person as IApiPerson, data)
    setEditMode(false)
    setPerson(null)
    saved()
  }

  // loading
  if (person === null) {
    return <DashItemProgress />
  }
  // end lev

  // with dialog
  function AboutSectionDialog(props: { person: IApiPerson }) {
    const person = props.person
    return (
      <EntityDialog
        entityName={'people'}
        model={person}
        handleClose={toggleEditMode}
        handleSave={save}
        innerComponent={<InnerAboutSection person={person} />}
      />
    )
  }

  // without dialog
  function InnerAboutSection(props: { person: IApiPerson }) {
    const person = props.person
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div data-style={{ flex: '1 0 50%' }}>
            <h3 className="sectionHeading">About</h3>
          </div>
          <div className={classes.iconOuterContainer}>
            <div>
              <AthenaAuthorize allowedRoles={'Contributor'}>
                {!editMode ? (
                  <EditIcon
                    onClick={toggleEditMode}
                    className={classes.editIcon}
                  />
                ) : (
                  <CloseIcon
                    onClick={toggleEditMode}
                    className={classes.closeIcon}
                  />
                )}
              </AthenaAuthorize>
            </div>
          </div>
        </div>
        <div
          style={{
            border: '1px dotted #fff',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            label={'Title'}
            defaultValue={person.title}
          />
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            muiTooltip={'from Accounting'}
            label={'Region'}
            defaultValue={person.district}
          />
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            muiTooltip={'from Accounting'}
            label={'Status'}
            defaultValue={person.status}
          />
          <DashDetailsField
            width="45%"
            mode={'date'}
            editMode={false}
            showLabel={true}
            muiTooltip={'from Accounting'}
            label={'Hire Date'}
            defaultValue={person.hireDate}
          />
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            label={'First Name'}
            defaultValue={person.firstName}
          />
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            label={'Last Name'}
            defaultValue={person.lastName}
          />
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            label={'Middle Initial'}
            defaultValue={person.middleInitial}
          />
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            label={'Preferred Name'}
            defaultValue={person.preferredFirstName}
          />
          <DashDetailsField
            width="45%"
            mode={'text'}
            editMode={false}
            showLabel={true}
            label={'Salary/Craft'}
            defaultValue={
              person.isSalary !== null
                ? person.isSalary
                  ? 'Salary'
                  : 'Craft'
                : ''
            }
          />
          <DashDetailsField
            width="45%"
            mode={'date'}
            editMode={false}
            showLabel={true}
            muiTooltip={'from Accounting'}
            label={'Termination Date'}
            defaultValue={person.terminationDate}
          />
          {/*named field*/}
          <DashDetailsField
            width="45%"
            mode={'number'}
            editMode={editMode}
            showLabel={true}
            label={'Industry Start Year'}
            defaultValue={person.industryStartYear}
            name={'industryStartYear'}
          />
          <DashDetailsField
            width="45%"
            mode={'number'}
            editMode={false}
            showLabel={true}
            muiTooltip={
              "Sum of all square footage in this person's project history"
            }
            label={'Total SQ FT'}
            defaultValue={person.totalSquareFootage}
            formatFn={numberFormatter('#,0')}
          />
          <DashDetailsField
            width="45%"
            mode={'number'}
            editMode={false}
            showLabel={true}
            muiTooltip={
              "Sum of all published costs in this person's project history"
            }
            label={'Total Project Value'}
            defaultValue={person.totalProjectValue}
            formatFn={numberFormatter('$#,0')}
          />
          <DashDetailsField
            width="45%"
            mode={'signedReleaseCheck'}
            editMode={false}
            showLabel={true}
            label={'Photo Release Allowed'}
            value={
              person.signedRelease === null
                ? null
                : person.signedRelease
                ? true
                : false
            }
          />
          <div style={{ width: '45%' }}></div>
        </div>
      </div>
    )
  }

  // lev pattern
  return editMode ? (
    <AboutSectionDialog person={person} />
  ) : (
    <InnerAboutSection person={person} />
  )
}
