import React, {useState} from "react";
import {IApiPerson} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";
import {Dialog} from "@material-ui/core";
import ResumeIntro from "components/detailViews/personViewRelated/ResumeIntro";

export default function ResumeSection(props: {person: IApiPerson}) {
  const classes = useSharedLargeCardStyles();
  const [editMode, setEditMode] = useState<boolean>(false);

  const person = props.person;

  const toggleEditMode = () => {
    setEditMode(!editMode);
  }

  const nonDialogVersion = (
      <>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <div data-style={{flex: '1 0 50%'}}><h3 className="sectionHeading">Resume Introduction</h3></div>
          <div data-style={{flex: '1 0 50%'}}>&nbsp;</div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <div style={{flex: '1'}}>
            <ResumeIntro person={person} />
          </div>
        </div>
      </>);

  const dialogVersion = (
      <Dialog open={true}>
        {nonDialogVersion}
      </Dialog>);

  return (editMode) ? dialogVersion : nonDialogVersion;
}
