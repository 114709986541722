/* eslint-disable @typescript-eslint/no-empty-function */
import React, {createContext, useState } from "react";
import {IFacetState} from "../../types/searchTypes";
import {ICriteria} from "../../services/SearchService";


export interface ICriteriaContext {
  search: string;
  onSearchChange: (search: string) => void;
  filter: Record<string, IFacetState>;
  onFilterChange: (filter: Record<string, IFacetState>) => void;
}

export interface ICriteriaState {
  entityName: string,
  search: string,
  filter: Record<string, IFacetState>,
  orderby: string,
}

export const CriteriaContext = createContext<ICriteriaContext>({
  search: '',
  filter: {},
      orderby: '',
  onSearchChange: (search: string) => {},
  onFilterChange: (filter: Record<string, IFacetState>) => {},
  onOrderByChange: (orderby: string) => {},
});

export default function CriteriaProvider({children}: { children: React.ReactNode }) {
  const [criteriaState, setCriteriaState] = useState<ICriteriaState>({entityName:'', search:'', filter:{}, orderby:''});

  const onSearchChange = (search: string) => {
    setCriteriaState( criteriaState => {
      return {...criteriaState, search: search};
    });
    console.log('@@context onSearchChange', search);
  }
  const onFilterChange = (filter: Record<string, IFacetState>) => {
    setCriteriaState( criteriaState => {
      return {...criteriaState, filter: filter};
    });
    console.log('@@context onFilterChange', filter);
  }
  const onOrderByChange = (orderby: string) => {
    setCriteriaState( criteriaState => {
      return {...criteriaState, orderby: orderby};
    });
    console.log('@@context onOrderByChange', orderby);
  }

  return (
      <CriteriaContext.Provider value={{...criteriaState, onSearchChange, onFilterChange}}>
        {children}
      </CriteriaContext.Provider>
  );

}