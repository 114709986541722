

class CoreLocation {
  static getBaseUrl(): string {
    const scheme = window.location.protocol.replace(':', '');
    const host = window.location.hostname;
    const port = window.location.port || '';

    return `${scheme}://${host}${port ? ':' + port : ''}`;
  }

  static getPathAndSearch(): string {
    return window.location.pathname + window.location.search;
  }

  static getEntityName(): string | null {
    const entityName = window.location.pathname.split('/')[1];
    if (!entityName) return null;
    return entityName;
  }

  static getEntityId(): string | null {
    const id = window.location.pathname.split('/')[2];
    if (!id) return null;
    return id;
  }

  static isProject(): boolean {
    const validEntities = ['projects', 'people'];
    const entityName = this.getEntityName();
    if (!entityName || !validEntities.includes(entityName)) {
      throw new Error('isProject() was not called while in a valid entity state.');
    }
    return entityName === 'project';
  }

  static endsInSlash(): boolean {
    return window.location.pathname.endsWith('/');
  }

}

export default CoreLocation;