import React, {useContext} from 'react';
import {useSearchParams} from "react-router-dom";
import {useDashQueryState} from "recoil/dashQuery";
import {makeStyles} from "@material-ui/core/styles";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import StatsBar from "./StatsBar";
import SearchGrid from "../SearchGrid";
import SearchBox from "../SearchBox";
import useDashUrlInfo from "hooks/useDashUrlInfo";
import ExportSpeedDial from "../ExportSpeedDial";
import {ExportAndSchemaContext} from "features/export/ExportAndSchemaProvider";
import CoreLocation from "../../../features/core/CoreLocation";

const useStyles = makeStyles(() => ({
  listViewWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  statsBarBlock: {
    display: 'block',
    background: 'linear-gradient(#eee,#ccc)',
  },
  searchGridBlock: {
    display: 'block',
    height: 'calc(100vh - 190px)'
  },
  statsBarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  searchGridContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'nowrap' as const,
    justifyContent: 'flex-start',
    height: '100%',
    backgroundColor: 'peachpuff',
  }
}));


export default function ListView() {
  const classes = useStyles();
  const {entityName} = useDashUrlInfo();
  const [dashQueryState, {setDashQueryState}] = useDashQueryState();
  const [searchParams, setSearchParams] = useSearchParams();
  const exportAndSchemaContext = useContext(ExportAndSchemaContext);

  function handleQuickExport() {
    exportAndSchemaContext.startExport(CoreLocation.getPathAndSearch(), undefined, undefined, null);
  }

  function handleExportWizard() {
    exportAndSchemaContext.startTemplateDesigner();
  }

  function handleCheckExports() {
    exportAndSchemaContext.checkExport();
  }

  return (
      <div className={classes.listViewWrap} data-feature="List View">
        <div className={classes.statsBarBlock}>
          <SearchBox/>
          <div className={classes.statsBarContainer}>
            <StatsBar/>
          </div>
        </div>
        <div className={classes.searchGridBlock}>
          <SearchGrid/>
          <ExportSpeedDial
              onExportWizard={() => handleExportWizard()}
              onQuickExport={() => handleQuickExport()}
              onCheckExports={() => handleCheckExports()}
          />
        </div>
      </div>
  );
}
