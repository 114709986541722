import { makeAutoObservable} from "mobx";
import {Ticker} from "app/state/Ticker";

export class ProgramInformationState {
  programInformationTicker = new Ticker();
  programProjectListTicker = new Ticker();

  constructor() {
    makeAutoObservable(this);
  }

  public updateProgramInformation() {
    this.programInformationTicker = new Ticker();
  }

  public updateProgramProjectList() {
    this.programProjectListTicker = new Ticker();
  }
}

const programInformationState = new ProgramInformationState();
export default programInformationState;
