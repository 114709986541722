import classNames from 'classnames';
import { ClimbingBoxLoader } from 'react-spinners';

import { Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loadingSpinner: {
    backgroundColor: theme.palette.background.default,
    height: 'calc(100vh - 160px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 152px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 104px)',
    },
  },
  fullScreenLoadingSpinner: {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
}));

function LoadingSpinner({ isFullScreen = false }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      data-testid="loading-spinner"
      className={classNames({
        [`${classes.loadingSpinner}`]: !isFullScreen,
        [`${classes.fullScreenLoadingSpinner}`]: isFullScreen,
      })}
    >
      <Grid item>
        <ClimbingBoxLoader color={theme.palette.primary.main} loading={true} size={10} />
      </Grid>
      <Grid item>
        <Typography color="primary">Loading...</Typography>
      </Grid>
    </Grid>
  );
}

export default LoadingSpinner;
