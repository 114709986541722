/* tslint:disable */
/* eslint-disable */

// ReSharper disable InconsistentNaming

// sometimes if it is a create with a POST instead of a PUT
// you'll need to
// add this } else if (status !== 200 && status !== 204 && status !== 201) {
// need to allow for the 201 sometimes

import _ from 'lodash'
import { fetchWithCache } from 'utils/http'
import PersonMod from './PersonMod'
import WebDamMod from './WebDamMod'

const __token = `bad-token`

export default class AthenaClient {
  private http: {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response>
  }

  readonly baseUrl: string
  private token: string

  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined
  private additionalHeaders: Record<string, string> = {}

  constructor(
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> },
  ) {
    this.http = http ? http : <any>window
    //this.baseUrl = baseUrl ? baseUrl : "https://qa-dash-api.azurewebsites.net";
    //this.baseUrl = baseUrl ? baseUrl : "http://localhost:7652"
    this.baseUrl = baseUrl
      ? baseUrl
      : (process.env.REACT_APP_API_BASE_URL as string)
  }

  getToken() {
    return this.token
  }

  setToken(token: string) {
    this.token = token
    return this
  }

  setAdditionalHeaders(headers: Record<string, string>) {
    this.additionalHeaders = headers
  }

  getHeaders(includeContentType?: boolean) {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.token,
      'X-App-Name': 'dash-react',
    }
    return includeContentType
      ? { ...headers, 'Content-Type': 'application/json' }
      : { ...headers }
  }

  // used for scaffolding
  noop(): Promise<unknown> {
    return Promise.resolve({})
  }

  /**
   * @return OK
   */
  getSingleAsset(
    entity: string,
    id: string,
    assetType: string,
    filename: string,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/{entity}/{id}/{assetType}/{filename}'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    if (assetType === undefined || assetType === null)
      throw new Error("The parameter 'assetType' must be defined.")
    url_ = url_.replace('{assetType}', encodeURIComponent('' + assetType))
    if (filename === undefined || filename === null)
      throw new Error("The parameter 'filename' must be defined.")
    url_ = url_.replace('{filename}', encodeURIComponent('' + filename))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetSingleAsset(_response)
    })
  }

  protected processGetSingleAsset(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete an asset from a record
   * @param entity The entity of the record.
   * @param id Id of the record from which to delete the asset.
   * @param assetType The type of file for the asset (e.g., images or documents).
   * @param filename The name of the asset to be deleted.
   * @return Successfully deleted the asset. Also 204 if record or asset doesn't exist.
   */
  deleteSingleAsset(
    entity: string,
    id: string,
    assetType: string,
    filename: string,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/{entity}/{id}/{assetType}/{filename}'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    if (assetType === undefined || assetType === null)
      throw new Error("The parameter 'assetType' must be defined.")
    url_ = url_.replace('{assetType}', encodeURIComponent('' + assetType))
    if (filename === undefined || filename === null)
      throw new Error("The parameter 'filename' must be defined.")
    url_ = url_.replace('{filename}', encodeURIComponent('' + filename))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSingleAsset(_response)
    })
  }

  protected processDeleteSingleAsset(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * @return OK
   */
  getDamAssets(entity: string, id: string): Promise<DamAssetMetadata[]> {
    let url_ = this.baseUrl + '/v2/{entity}/{id}/assets/dam'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetDamAssets(_response)
    })
  }

  protected processGetDamAssets(
    response: Response,
  ): Promise<DamAssetMetadata[]> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(DamAssetMetadata.fromJS(item))
        }
        const webDamMod = new WebDamMod()
        result200 = webDamMod.enhanceWebDamAssets(result200)

        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<DamAssetMetadata[]>(<any>null)
  }

  /**
   * @return OK
   */
  getDamAssetSeparatedFiles(entity: string, id: string): Promise<DamAssets> {
    let url_ = this.baseUrl + '/v2/{entity}/{id}/assets'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetDamAssetSeparatedFiles(_response)
    })
  }

  protected processGetDamAssetSeparatedFiles(
    response: Response,
  ): Promise<DamAssets> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = DamAssets.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<DamAssets>(<any>null)
  }

  /**
   * @return OK
   */
  // uploadAssets(entity: string, id: string, assetType: string): Promise<AthenaFileMetadata[]> {
  //     let url_ = this.baseUrl + "/v2/{entity}/{id}/{assetType}";
  //     if (entity === undefined || entity === null)
  //         throw new Error("The parameter 'entity' must be defined.");
  //     url_ = url_.replace("{entity}", encodeURIComponent("" + entity));
  //     if (id === undefined || id === null)
  //         throw new Error("The parameter 'id' must be defined.");
  //     url_ = url_.replace("{id}", encodeURIComponent("" + id));
  //     if (assetType === undefined || assetType === null)
  //         throw new Error("The parameter 'assetType' must be defined.");
  //     url_ = url_.replace("{assetType}", encodeURIComponent("" + assetType));
  //     url_ = url_.replace(/[?&]$/, "");
  //
  //     let options_ = <RequestInit>{
  //         method: "POST",
  //         headers: {
  //             "Accept": "application/json", "Authorization": "Bearer " + this.token,
  //         }
  //     };
  //
  //     return this.http.fetch(url_, options_).then((_response: Response) => {
  //         return this.processUploadAssets(_response);
  //     });
  // }

  uploadAssets(
    entity: string,
    id: string,
    assetType: string,
    files: File[],
  ): Promise<AthenaFileMetadata[]> {
    let url_ = this.baseUrl + '/v2/{entity}/{id}/{assetType}'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    if (assetType === undefined || assetType === null)
      throw new Error("The parameter 'assetType' must be defined.")
    url_ = url_.replace('{assetType}', encodeURIComponent('' + assetType))
    url_ = url_.replace(/[?&]$/, '')

    // Create a FormData object and append files
    const formData = new FormData()
    for (const file of files) {
      formData.append('file', file)
    }
    console.log('!!! uploadAssets: ', { files, formData })

    let options_: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
      body: formData, // Include the FormData object as the body
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUploadAssets(_response)
    })
  }

  protected processUploadAssets(
    response: Response,
  ): Promise<AthenaFileMetadata[]> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200 || status === 201) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(AthenaFileMetadata.fromJS(item))
        }
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
        //_.noop();
        //return throwException("")
      })
    }
    return Promise.resolve<AthenaFileMetadata[]>(<any>null)
  }

  /**
   * Get a company
   * @return OK
   */
  getSingleCompany(id: string): Promise<ApiCompany> {
    let url_ = this.baseUrl + '/v2/companies/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetSingleCompany(_response)
    })
  }

  protected processGetSingleCompany(response: Response): Promise<ApiCompany> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiCompany.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiCompany>(<any>null)
  }

  async isCompanyNode(id: string): Promise<boolean> {
    try {
      const company = await this.getSingleCompany(id)
      if (company) {
        return true
      }
      return false // This would be an odd case; if it's null, then it's not a company.
    } catch (error) {
      if (error instanceof Error) {
        // && error.message.includes('404')) {
        return false
      }
      // Re-throw other errors; these aren't simply "not a company" situations.
      throw error
    }
  }

  /**
     * Create or update a company
     * @param company Object with fields to be updated as specified. Fields not specified will be set
     to null. The 'id' field cannot be modified and will be ignored if specified.
     * @return OK
     */
  createOrUpdateCompany(id: string, company: Company): Promise<ApiCompany> {
    let url_ = this.baseUrl + '/v2/companies/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(company)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateCompany(_response)
    })
  }

  protected processCreateOrUpdateCompany(
    response: Response,
  ): Promise<ApiCompany> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiCompany.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiCompany>(<any>null)
  }

  /**
   * Delete a company
   * @return OK
   */
  deleteCompany(id: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/companies/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteCompany(_response)
    })
  }

  protected processDeleteCompany(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
     * Search and filter companies.
     * @param search (optional) Free text search phrase.
     * @param filter (optional) OData-style <code>$filter</code> param
     * @param skip (optional) Number of results to skip.
     * @param top (optional) Number of results to show.
     * @param orderby (optional) OData-style sort order. If search param is used, defaults to the
     search score.
     * @return OK
     */
  getAllCompanies(
    search: string | null | undefined,
    filter: string | null | undefined,
    skip: number | null | undefined,
    top: number | null | undefined,
    orderby: string | null | undefined,
  ): Promise<SearchResultSetOfQueryableCompany> {
    let url_ = this.baseUrl + '/v2/companies?'
    if (search !== undefined)
      url_ += 'search=' + encodeURIComponent('' + search) + '&'
    if (filter !== undefined)
      url_ += 'filter=' + encodeURIComponent('' + filter) + '&'
    if (skip !== undefined)
      url_ += 'skip=' + encodeURIComponent('' + skip) + '&'
    if (top !== undefined) url_ += 'top=' + encodeURIComponent('' + top) + '&'
    if (orderby !== undefined)
      url_ += 'orderby=' + encodeURIComponent('' + orderby) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetAllCompanies(_response)
    })
  }

  protected processGetAllCompanies(
    response: Response,
  ): Promise<SearchResultSetOfQueryableCompany> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = SearchResultSetOfQueryableCompany.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<SearchResultSetOfQueryableCompany>(<any>null)
  }

  /**
   * Get a contact
   * @return OK
   */
  getSingleContact(id: string): Promise<ApiContact> {
    let url_ = this.baseUrl + '/v2/contacts/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetSingleContact(_response)
    })
  }

  protected processGetSingleContact(response: Response): Promise<ApiContact> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiContact.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiContact>(<any>null)
  }

  /**
     * Create or update a contact
     * @param contact Object with fields to be updated as specified. Fields not specified will be set
     to null. The 'id' field cannot be modified and will be ignored if specified.
     * @return OK
     */
  createOrUpdateContact(id: string, contact: Contact): Promise<ApiContact> {
    let url_ = this.baseUrl + '/v2/contacts/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(contact)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateContact(_response)
    })
  }

  protected processCreateOrUpdateContact(
    response: Response,
  ): Promise<ApiContact> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiContact.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiContact>(<any>null)
  }

  /**
   * Delete a contact
   * @return OK
   */
  deleteContact(id: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/contacts/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteContact(_response)
    })
  }

  protected processDeleteContact(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
     * Search and filter contacts.
     * @param search (optional) Free text search phrase.
     * @param filter (optional) OData-style <code>$filter</code> param
     * @param skip (optional) Number of results to skip.
     * @param top (optional) Number of results to show.
     * @param orderby (optional) OData-style sort order. If search param is used, defaults to the
     search score.
     * @return OK
     */
  getAllContacts(
    search: string | null | undefined,
    filter: string | null | undefined,
    skip: number | null | undefined,
    top: number | null | undefined,
    orderby: string | null | undefined,
  ): Promise<SearchResultSetOfQueryableContact> {
    let url_ = this.baseUrl + '/v2/contacts?'
    if (search !== undefined)
      url_ += 'search=' + encodeURIComponent('' + search) + '&'
    if (filter !== undefined)
      url_ += 'filter=' + encodeURIComponent('' + filter) + '&'
    if (skip !== undefined)
      url_ += 'skip=' + encodeURIComponent('' + skip) + '&'
    if (top !== undefined) url_ += 'top=' + encodeURIComponent('' + top) + '&'
    if (orderby !== undefined)
      url_ += 'orderby=' + encodeURIComponent('' + orderby) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetAllContacts(_response)
    })
  }

  protected processGetAllContacts(
    response: Response,
  ): Promise<SearchResultSetOfQueryableContact> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = SearchResultSetOfQueryableContact.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<SearchResultSetOfQueryableContact>(<any>null)
  }

  /**
   * Create a new degree for a person.
   * @param personId Id of the person for whom to create a degree.
   * @param degree Degree object to create.
   * @return Successfully created the degree.
   */
  createDegree(personId: number, degree: Degree): Promise<Degree> {
    let url_ = this.baseUrl + '/v2/people/{personId}/degrees'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(degree)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateDegree(_response)
    })
  }

  protected processCreateDegree(response: Response): Promise<Degree> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Degree.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          'Person does not exist.',
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Degree>(<any>null)
  }

  /**
     * Update a degree.
     * @param personId Id of the person with the degree.
     * @param degreeId Id of the degree to update.
     * @param degree Object with fields to updated specified. Fields not specified will remain
     unchanged. The 'id' field cannot be modified and will be ignored if specified.
     * @return Successfully updated degree, returns updated version.
     */
  updateDegree(
    personId: number,
    degreeId: string,
    degree: Degree,
  ): Promise<Degree> {
    let url_ = this.baseUrl + '/v2/people/{personId}/degrees/{degreeId}'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (degreeId === undefined || degreeId === null)
      throw new Error("The parameter 'degreeId' must be defined.")
    url_ = url_.replace('{degreeId}', encodeURIComponent('' + degreeId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(degree)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateDegree(_response)
    })
  }

  protected processUpdateDegree(response: Response): Promise<Degree> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Degree.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Person or degree doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Degree>(<any>null)
  }

  /**
   * Delete a degree.
   * @param personId Id of the person from whom to delete a degree.
   * @param degreeId Id of the degree to delete
   * @return Successfully deleted the degree. Also 204 if degree and/or person doesn't exist.
   */
  deleteDegree(personId: number, degreeId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/people/{personId}/degrees/{degreeId}'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (degreeId === undefined || degreeId === null)
      throw new Error("The parameter 'degreeId' must be defined.")
    url_ = url_.replace('{degreeId}', encodeURIComponent('' + degreeId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteDegree(_response)
    })
  }

  protected processDeleteDegree(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * @return OK
   */
  createExport(exportRequest: ExportRequestBody): Promise<Export> {
    let url_ = this.baseUrl + '/v2/exports'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(exportRequest)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateExport(_response)
    })
  }

  protected processCreateExport(response: Response): Promise<Export> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200 || status === 201) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Export.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Export>(<any>null)
  }

  /**
   * @return OK
   */
  getExport(exportId: string): Promise<Export> {
    let url_ = this.baseUrl + '/v2/exports/{exportId}'
    if (exportId === undefined || exportId === null)
      throw new Error("The parameter 'exportId' must be defined.")
    url_ = url_.replace('{exportId}', encodeURIComponent('' + exportId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetExport(_response)
    })
  }

  protected processGetExport(response: Response): Promise<Export> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Export.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Export>(<any>null)
  }

  /**
   * @return OK
   */
  downloadExportFile(id: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/exports/{id}/$value'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDownloadExportFile(_response)
    })
  }

  protected processDownloadExportFile(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * @return OK
   */
  getPerson(id: number): Promise<ApiPerson> {
    let url_ = this.baseUrl + '/v2/people/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetPerson(_response)
    })
  }

  protected processGetPerson(response: Response): Promise<ApiPerson> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiPerson.fromJS(resultData200)

        // todo: fix backend API to do slight easy mods to the Person before sending
        // mod step
        const personMod = new PersonMod()
        result200 = personMod.enhancePerson((result200 as unknown) as ApiPerson)

        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiPerson>(<any>null)
  }

  /**
   * Create or update a person
   * @return OK
   */
  createOrUpdatePerson(
    id: number,
    person: ApiPerson | object,
  ): Promise<ApiPerson> {
    //createOrUpdatePerson(id: number, person: ApiPerson): Promise<ApiPerson> {
    let url_ = this.baseUrl + '/v2/people/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(person)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdatePerson(_response)
    })
  }

  protected processCreateOrUpdatePerson(
    response: Response,
  ): Promise<ApiPerson> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiPerson.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiPerson>(<any>null)
  }

  /**
   * @return OK
   */
  deletePerson(id: number): Promise<Object> {
    let url_ = this.baseUrl + '/v2/people/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeletePerson(_response)
    })
  }

  protected processDeletePerson(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  //
  // CUSTOM
  //
  //
  getAllEntities(
    entityName: string,
    search: string | null | undefined,
    filter: string | null | undefined,
    skip: number | null | undefined,
    top: number | null | undefined,
    orderby: string | null | undefined,
    useCommonFilter?: boolean,
  ): Promise<
    | SearchResultSetOfQueryablePerson
    | SearchResultSetOfQueryableProject
    | SearchResultSetOfQueryableCompany
    | SearchResultSetOfQueryableContact
  > {
    entityName = normalizeEntityName(entityName)

    if (useCommonFilter && entityName === 'people') {
      filter = `(status eq 'Active')`
    }

    switch (entityName) {
      case 'people':
        return this.getAllPeople(search, filter, skip, top, orderby)
      case 'projects':
        return this.getAllProjectsV2(search, filter, skip, top, orderby)
      case 'contacts':
        return this.getAllContacts(search, filter, skip, top, orderby)
      case 'companies':
        return this.getAllCompanies(search, filter, skip, top, orderby)
      default:
        throw new Error(`Invalid entity name: ${entityName}`)
    }
  }

  // Promise<SearchResultSetOfQueryablePerson |
  // SearchResultSetOfQueryableProject |
  // SearchResultSetOfQueryableCompany |
  // SearchResultSetOfQueryableContact>

  async getAllEntities2<T extends { new (...args: any[]): T }>(
    ctor: new (...args: any[]) => T,
    search: string | null | undefined,
    filter: string | null | undefined,
    skip: number | null | undefined,
    top: number | null | undefined,
    orderby: string | null | undefined,
  ): Promise<T> {
    const instance = new ctor()
    const typeName = Reflect.get(instance.constructor, 'name')
    switch (typeName) {
      case 'SearchResultSetOfQueryablePerson':
        return ((await this.getAllPeople(
          search,
          filter,
          skip,
          top,
          orderby,
        )) as unknown) as T
      case 'SearchResultSetOfQueryableProject':
        return ((await this.getAllProjectsV2(
          search,
          filter,
          skip,
          top,
          orderby,
        )) as unknown) as T
      case 'SearchResultSetOfQueryableContact':
        return ((await this.getAllContacts(
          search,
          filter,
          skip,
          top,
          orderby,
        )) as unknown) as T
      case 'SearchResultSetOfQueryableCompany':
        return ((await this.getAllCompanies(
          search,
          filter,
          skip,
          top,
          orderby,
        )) as unknown) as T
      default:
        throw new Error(`typeName / type not found: ${typeName}`)
    }
  }

  getAllEntities3<T extends { new (...args: any[]): T }>(
    ctor: new (...args: any[]) => T,
    search: string | null | undefined,
    filter: string | null | undefined,
    skip: number | null | undefined,
    top: number | null | undefined,
    orderby: string | null | undefined,
  ): Promise<T> {
    const instance = new ctor()
    const typeName = Reflect.get(instance.constructor, 'name')
    let result: Promise<T> | undefined

    switch (typeName) {
      case 'SearchResultSetOfQueryablePerson':
        result = (this.getAllPeople(
          search,
          filter,
          skip,
          top,
          orderby,
        ) as unknown) as Promise<T>
        break
      case 'SearchResultSetOfQueryableProject':
        result = (this.getAllProjectsV2(
          search,
          filter,
          skip,
          top,
          orderby,
        ) as unknown) as Promise<T>
        break
      case 'SearchResultSetOfQueryableContact':
        result = (this.getAllContacts(
          search,
          filter,
          skip,
          top,
          orderby,
        ) as unknown) as Promise<T>
        break
      case 'SearchResultSetOfQueryableCompany':
        result = (this.getAllCompanies(
          search,
          filter,
          skip,
          top,
          orderby,
        ) as unknown) as Promise<T>
        break
      default:
        throw new Error(`typeName not found: ${typeName}`)
    }

    return result
  }

  // async getAllPeople(search?: string | null, filter?: string | null, skip?: number | null, top?: number | null, orderby?: string | null): Promise<SearchResultSetOfQueryablePerson> {
  //     const queryParams: Record<string, string | number | null> = {};
  //
  //     let url_ = this.baseUrl + "/v2/people?";
  //     if (search !== undefined)
  //         url_ += "search=" + encodeURIComponent("" + search) + "&";
  //     if (filter !== undefined)
  //         url_ += "filter=" + encodeURIComponent("" + filter) + "&";
  //     if (skip !== undefined)
  //         url_ += "skip=" + encodeURIComponent("" + skip) + "&";
  //     if (top !== undefined)
  //         url_ += "top=" + encodeURIComponent("" + top) + "&";
  //     if (orderby !== undefined)
  //         url_ += "orderby=" + encodeURIComponent("" + orderby) + "&";
  //     url_ = url_.replace(/[?&]$/, "");
  //
  //     // Check for cached data
  //     if (rcs.cache[url_]) {
  //         return rcs.cache[url_];
  //     }
  //
  //     // Check for pending requests
  //     if (rcs.pendingRequests[url_]) {
  //         return rcs.pendingRequests[url_];
  //     }
  //
  //     const options: RequestInit = {
  //         method: 'GET',
  //         headers: {
  //             'Accept': 'application/json',
  //             'Authorization': `Bearer ${this.token}`,
  //         },
  //     };
  //
  //     // Save the pending request and execute it
  //     rcs.pendingRequests[url_] = this.http.fetch(url_, options)
  //         .then(this.processGetAllPeople)
  //         .then(data => {
  //             // Cache the data
  //             rcs.cache[url_] = data;
  //
  //             // Invalidate cache and pending request after 5 seconds (or your debounce time)
  //             setTimeout(() => {
  //                 delete rcs.cache[url_];
  //                 delete rcs.pendingRequests[url_];
  //             }, 5000);
  //
  //             return data;
  //         });
  //
  //     return rcs.pendingRequests[url_];
  // }

  /**
     * Search and filter people.
     * @param search (optional) Free text search phrase.
     * @param filter (optional) OData-style <code>$filter</code> param
     * @param skip (optional) Number of results to skip.
     * @param top (optional) Number of results to show.
     * @param orderby (optional) OData-style sort order. If search param is used, defaults to the
     search score. Otherwise, defaults to <code>name asc</code>.
     * @return OK
     */
  getAllPeople(
    search: string | null | undefined,
    filter: string | null | undefined,
    skip: number | null | undefined,
    top: number | null | undefined,
    orderby: string | null | undefined,
  ): Promise<SearchResultSetOfQueryablePerson> {
    let url_ = this.baseUrl + '/v2/people?'
    if (search !== undefined)
      url_ += 'search=' + encodeURIComponent('' + search) + '&'
    if (filter !== undefined)
      url_ += 'filter=' + encodeURIComponent('' + filter) + '&'
    if (skip !== undefined)
      url_ += 'skip=' + encodeURIComponent('' + skip) + '&'
    if (top !== undefined) url_ += 'top=' + encodeURIComponent('' + top) + '&'
    if (orderby !== undefined)
      url_ += 'orderby=' + encodeURIComponent('' + orderby) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetAllPeople(_response)
    })
  }

  protected processGetAllPeople(
    response: Response,
  ): Promise<SearchResultSetOfQueryablePerson> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = SearchResultSetOfQueryablePerson.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<SearchResultSetOfQueryablePerson>(<any>null)
  }

  /**
   * Get calculated totals for people queries.
   * @param search (optional) Free text search phrase.
   * @param filter (optional) OData-style <code>$filter</code> param
   * @return OK
   */
  getPeopleSearchStatistics(
    search: string | null | undefined,
    filter: string | null | undefined,
  ): Promise<PeopleSearchStatistics> {
    let url_ = this.baseUrl + '/v2/people/statistics?'
    if (search !== undefined)
      url_ += 'search=' + encodeURIComponent('' + search) + '&'
    if (filter !== undefined)
      url_ += 'filter=' + encodeURIComponent('' + filter) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetPeopleSearchStatistics(_response)
    })

    // if (localStorage.getItem("cacheExpires:" + url_) && new Date().getTime() <= localStorage.getItem("cacheExpires:" + url_)) {
    //     // return the cached value
    //     return PeopleSearchStatistics.fromJS(localStorage.getItem("cache:" + url_));
    // } else {
    //     return this.http.fetch(url_, options_).then((_response: Response) => {
    //         return this.processGetPeopleSearchStatistics(_response);
    //     });
    // }
  }

  protected processGetPeopleSearchStatistics(
    response: Response,
  ): Promise<PeopleSearchStatistics> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = PeopleSearchStatistics.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<PeopleSearchStatistics>(<any>null)
  }

  /**
   * Create or update a person and contact link
   * @param personId Id of person to link.
   * @param contactId Id of contact to link.
   * @param personContact Person's and contact's ids in the body are ignored.
   */
  createOrUpdatePersonContactLink(
    personId: number,
    contactId: string,
    personContact: PersonContact,
  ): Promise<PersonContact> {
    let url_ =
      this.baseUrl +
      '/v2/people/{personId}/contacts/{contactId}/__referencePath'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(personContact)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdatePersonContactLink(_response)
    })
  }

  protected processCreateOrUpdatePersonContactLink(
    response: Response,
  ): Promise<PersonContact> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = PersonContact.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Contact doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<PersonContact>(<any>null)
  }

  /**
   * Unlink a person and contact.
   * @param personId Id of the person to unlink.
   * @param contactId Id of the contact to unlink.
   * @return Should always respond with a 204 No Content, including when contact, person, or link does not exist
   */
  deletePersonContactLink(
    personId: number,
    contactId: string,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/people/{personId}/contacts/{contactId}/$ref'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeletePersonContactLink(_response)
    })
  }

  protected processDeletePersonContactLink(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create or update a person and contact link
   * @param personId Id of person to link.
   * @param contactId Id of contact to link.
   * @param personContact Person's and contact's ids in the body are ignored.
   */
  createOrUpdatePersonContactLink2(
    personId: number,
    contactId: string,
    personContact: PersonContact,
  ): Promise<PersonContact> {
    //let url_ = this.baseUrl + "/v2/contacts/{contactId}/people/{personId}/__referencePath";
    let url_ = this.baseUrl + '/v2/contacts/{contactId}/people/{personId}/$ref'

    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(personContact)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdatePersonContactLink2(_response)
    })
  }

  protected processCreateOrUpdatePersonContactLink2(
    response: Response,
  ): Promise<PersonContact> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = PersonContact.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Contact doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<PersonContact>(<any>null)
  }

  /**
   * Unlink a person and contact.
   * @param personId Id of the person to unlink.
   * @param contactId Id of the contact to unlink.
   * @return Should always respond with a 204 No Content, including when contact, person, or link does not exist
   */
  deletePersonContactLink2(
    personId: number,
    contactId: string,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/contacts/{contactId}/people/{personId}/$ref'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeletePersonContactLink2(_response)
    })
  }

  protected processDeletePersonContactLink2(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Unlink a person and reference.
   * @param personId Id of the person to unlink.
   * @param referenceId Id of the reference to unlink.
   * @return Should always respond with a 204 No Content, including when reference, person, or link does not exist
   */
  deletePersonReferenceLink(
    personId: number,
    referenceId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/people/{personId}/references/{referenceId}/$ref'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (referenceId === undefined || referenceId === null)
      throw new Error("The parameter 'referenceId' must be defined.")
    url_ = url_.replace('{referenceId}', encodeURIComponent('' + referenceId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeletePersonReferenceLink(_response)
    })
  }

  protected processDeletePersonReferenceLink(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create a new person award.
   * @param personId Id of the person that the award is for.
   * @param award The award to create.
   * @return Successfully created award, with generated id
   */
  createPersonAward(
    personId: number,
    award: PersonAward,
  ): Promise<PersonAward> {
    let url_ = this.baseUrl + '/v2/people/{personId}/awards'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(award)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreatePersonAward(_response)
    })
  }

  protected processCreatePersonAward(response: Response): Promise<PersonAward> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = PersonAward.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Person doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<PersonAward>(<any>null)
  }

  /**
     * Update a person award
     * @param personId Id of the person the award is on
     * @param awardId Id of the award to update
     * @param award Object with fields to update specified. Fields not specified will remain unchanged.
     The 'id' field cannot be modified and will be ignored if specified.
     * @return Successfully updated award, returns newly updated version.
     */
  updateAward(
    personId: number,
    awardId: string,
    award: PersonAward,
  ): Promise<PersonAward> {
    console.log('@@@@@updateAward', personId, awardId, award)
    let url_ = this.baseUrl + '/v2/people/{personId}/awards/{awardId}'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (awardId === undefined || awardId === null)
      throw new Error("The parameter 'awardId' must be defined.")
    url_ = url_.replace('{awardId}', encodeURIComponent('' + awardId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(award)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateAward(_response)
    })
  }

  protected processUpdateAward(response: Response): Promise<PersonAward> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = PersonAward.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Person or award doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<PersonAward>(<any>null)
  }

  /**
   * Delete a person award
   * @param personId Id of the person from which to delete the award.
   * @param awardId Id of the award to delete.
   * @return Successfully deleted the award. Also 204 if person or award doesn't exist.
   */
  deleteAward(personId: number, awardId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/people/{personId}/awards/{awardId}'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (awardId === undefined || awardId === null)
      throw new Error("The parameter 'awardId' must be defined.")
    url_ = url_.replace('{awardId}', encodeURIComponent('' + awardId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteAward(_response)
    })
  }

  protected processDeleteAward(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create a new person training.
   * @param personId Id of the person that the training is for.
   * @param training The training to create.
   * @return Successfully created the training, with generated id
   */
  createPersonTraining(
    personId: number,
    training: Training,
  ): Promise<Training> {
    let url_ = this.baseUrl + '/v2/people/{personId}/trainings'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(training)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreatePersonTraining(_response)
    })
  }

  protected processCreatePersonTraining(response: Response): Promise<Training> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Training.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Person doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Training>(<any>null)
  }

  /**
     * Update a person's training
     * @param personId Id of the person the training is on
     * @param trainingId Id of the training to update
     * @param training Object with fields to update specified. Fields not specified will remain unchanged.
     The 'id' field cannot be modified and will be ignored if specified.
     * @return Successfully updated training, returns newly updated version.
     */
  updateTraining(
    personId: number,
    trainingId: string,
    training: Training,
  ): Promise<Training> {
    let url_ = this.baseUrl + '/v2/people/{personId}/trainings/{trainingId}'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (trainingId === undefined || trainingId === null)
      throw new Error("The parameter 'trainingId' must be defined.")
    url_ = url_.replace('{trainingId}', encodeURIComponent('' + trainingId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(training)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateTraining(_response)
    })
  }

  protected processUpdateTraining(response: Response): Promise<Training> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Training.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Person or training doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Training>(<any>null)
  }

  /**
   * Delete a person training
   * @param personId Id of the person from which to delete the training.
   * @param trainingId Id of the training to delete.
   * @return Successfully deleted the training. Also 204 if person or training doesn't exist.
   */
  deleteTraining(personId: number, trainingId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/people/{personId}/trainings/{trainingId}'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    if (trainingId === undefined || trainingId === null)
      throw new Error("The parameter 'trainingId' must be defined.")
    url_ = url_.replace('{trainingId}', encodeURIComponent('' + trainingId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteTraining(_response)
    })
  }

  protected processDeleteTraining(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create a program
   * @return OK
   */
  createProgram(program: ApiProgram): Promise<ApiProgram> {
    let url_ = this.baseUrl + '/v2/programs'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(program)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateProgram(_response)
    })
  }

  protected processCreateProgram(response: Response): Promise<ApiProgram> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200 || status === 201) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProgram.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      // console.log('!@@@! 20! createProgram', status, _responseText);
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProgram>(<any>null)
  }

  /**
   * Get a Program
   * @return OK
   */
  getProgram(programId: string): Promise<ApiProgram> {
    let url_ = this.baseUrl + '/v2/programs/{programId}'
    if (programId === undefined || programId === null)
      throw new Error("The parameter 'programId' must be defined.")
    url_ = url_.replace('{programId}', encodeURIComponent('' + programId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetProgram(_response)
    })
  }

  protected processGetProgram(response: Response): Promise<ApiProgram> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProgram.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProgram>(<any>null)
  }

  /**
   * Update a program
   * @return OK
   */
  updateProgram(program: ApiProgram, programId: string): Promise<ApiProgram> {
    // TODO: at some point the DASH API should be fixed this call is weird
    // Should be labelled as an upsert and then should probably do a POST
    // and not send a 201 if it ends up just being an update

    let url_ = this.baseUrl + '/v2/programs/{programId}'
    if (programId === undefined || programId === null)
      throw new Error("The parameter 'programId' must be defined.")
    url_ = url_.replace('{programId}', encodeURIComponent('' + programId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(program)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateProgram(_response)
    })
  }

  protected processUpdateProgram(response: Response): Promise<ApiProgram> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200 || status === 201) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        //console.log('#@! odd update - the json', {resultData200});
        result200 = ApiProgram.fromJS(resultData200)
        //console.log('#@! odd update - the ApiProgram', {result200});
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProgram>(<any>null)
  }

  /**
   * Delete a program
   * @return OK
   */
  deleteProgram(programId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/programs/{programId}'
    if (programId === undefined || programId === null)
      throw new Error("The parameter 'programId' must be defined.")
    url_ = url_.replace('{programId}', encodeURIComponent('' + programId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProgram(_response)
    })
  }

  protected processDeleteProgram(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Link a project to a program
   * @return OK
   */
  linkProjectToProgram(
    programId: string,
    projectId: string,
    programProject: ProgramProject,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/programs/{programId}/projects/{projectId}/$ref'
    if (programId === undefined || programId === null)
      throw new Error("The parameter 'programId' must be defined.")
    url_ = url_.replace('{programId}', encodeURIComponent('' + programId))
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(programProject)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processLinkProjectToProgram(_response)
    })
  }

  protected processLinkProjectToProgram(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Unlink a project from a program
   * @return OK
   */
  unlinkProjectFromProgram(
    programId: string,
    projectId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/programs/{programId}/projects/{projectId}/$ref'
    if (programId === undefined || programId === null)
      throw new Error("The parameter 'programId' must be defined.")
    url_ = url_.replace('{programId}', encodeURIComponent('' + programId))
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUnlinkProjectFromProgram(_response)
    })
  }

  protected processUnlinkProjectFromProgram(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create a new project award.
   * @param projectId Id of the project that the award is for.
   * @param award The award to create.
   * @return Successfully created award, with generated id
   */
  createAward(projectId: string, award: ProjectAward): Promise<ProjectAward> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/awards'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(award)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateAward(_response)
    })
  }

  protected processCreateAward(response: Response): Promise<ProjectAward> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectAward.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Project doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectAward>(<any>null)
  }

  /**
     * Update a project award
     * @param projectId Id of the project the award is on
     * @param awardId Id of the award to update
     * @param award Object with fields to update specified. Fields not specified will remain unchanged.
     The 'id' field cannot be modified and will be ignored if specified.
     * @return Successfully updated award, returns newly updated version.
     */
  updateAward2(
    projectId: string,
    awardId: string,
    award: ProjectAward,
  ): Promise<ProjectAward> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/awards/{awardId}'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (awardId === undefined || awardId === null)
      throw new Error("The parameter 'awardId' must be defined.")
    url_ = url_.replace('{awardId}', encodeURIComponent('' + awardId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(award)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateAward2(_response)
    })
  }

  protected processUpdateAward2(response: Response): Promise<ProjectAward> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectAward.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Project or award doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectAward>(<any>null)
  }

  /**
   * Delete a project award
   * @param projectId Id of the project from which to delete the award.
   * @param awardId Id of the award to delete.
   * @return Successfully deleted the award. Also 204 if project or award doesn't exist.
   */
  deleteAward2(projectId: string, awardId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/awards/{awardId}'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (awardId === undefined || awardId === null)
      throw new Error("The parameter 'awardId' must be defined.")
    url_ = url_.replace('{awardId}', encodeURIComponent('' + awardId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteAward2(_response)
    })
  }

  protected processDeleteAward2(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create a challenge/solution on a project.
   * @param projectId Id of the project which had the challenge/solution.
   * @param challengeSolution The challenge/solution to add.
   * @return Successfully created challenge/solution, with generated id
   */
  createChallengeSolution(
    projectId: string,
    challengeSolution: ProjectChallengeSolution,
  ): Promise<ProjectChallengeSolution> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/challengeSolutions'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(challengeSolution)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateChallengeSolution(_response)
    })
  }

  protected processCreateChallengeSolution(
    response: Response,
  ): Promise<ProjectChallengeSolution> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectChallengeSolution.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Project doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectChallengeSolution>(<any>null)
  }

  /**
     * Update the fields on a project's challenge/solution
     * @param projectId Id of the project with the challenge/solution to update.
     * @param challengeSolutionId Id of the challenge/solution to update.
     * @param challengeSolution Object with fields to update specified. Fields not specified will remain unchanged.
     The 'id' field cannot be modified and will be ignored if specified.
     * @return Successfully updated challenge/solution, returns newly updated version.
     */
  updateChallengeSolution(
    projectId: string,
    challengeSolutionId: string,
    challengeSolution: ProjectChallengeSolution,
  ): Promise<ProjectChallengeSolution> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/challengeSolutions/{challengeSolutionId}'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (challengeSolutionId === undefined || challengeSolutionId === null)
      throw new Error("The parameter 'challengeSolutionId' must be defined.")
    url_ = url_.replace(
      '{challengeSolutionId}',
      encodeURIComponent('' + challengeSolutionId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(challengeSolution)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateChallengeSolution(_response)
    })
  }

  protected processUpdateChallengeSolution(
    response: Response,
  ): Promise<ProjectChallengeSolution> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectChallengeSolution.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Project or award doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectChallengeSolution>(<any>null)
  }

  /**
   * Delete a project's challenge/solution.
   * @param projectId Id of the project from which to delete the challenge/solution.
   * @param challengeSolutionId Id of the challenge/solution to delete.
   * @return Successfully deleted the challenge/solution. Also 204 if project or challenge/solution doesn't exist.
   */
  deleteChallengeSolution(
    projectId: string,
    challengeSolutionId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/challengeSolutions/{challengeSolutionId}'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (challengeSolutionId === undefined || challengeSolutionId === null)
      throw new Error("The parameter 'challengeSolutionId' must be defined.")
    url_ = url_.replace(
      '{challengeSolutionId}',
      encodeURIComponent('' + challengeSolutionId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteChallengeSolution(_response)
    })
  }

  protected processDeleteChallengeSolution(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Get a project phase label
   * @return OK
   */
  getProjectPhaseLabels(): Promise<ApiProjectPhaseLabel> {
    let url_ = this.baseUrl + '/v2/projectPhaseLabels'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetProjectPhaseLabels(_response)
    })
  }

  protected processGetProjectPhaseLabels(
    response: Response,
  ): Promise<ApiProjectPhaseLabel> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProjectPhaseLabel.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProjectPhaseLabel>(<any>null)
  }

  /**
   * Create a project phase label
   * @return OK
   */
  createProjectPhaseLabel(
    phaseLabel: ApiProjectPhaseLabel,
  ): Promise<ApiProjectPhaseLabel> {
    let url_ = this.baseUrl + '/v2/projectPhaseLabels'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(phaseLabel)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateProjectPhaseLabel(_response)
    })
  }

  protected processCreateProjectPhaseLabel(
    response: Response,
  ): Promise<ApiProjectPhaseLabel> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProjectPhaseLabel.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProjectPhaseLabel>(<any>null)
  }

  /**
   * Update a project phase label
   * @return OK
   */
  updateProjectPhaseLabel(
    projectPhaseLabel: ApiProjectPhaseLabel,
    projectPhaseLabelId: string,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/projectPhaseLabels/{projectPhaseLabelId}'
    if (projectPhaseLabelId === undefined || projectPhaseLabelId === null)
      throw new Error("The parameter 'projectPhaseLabelId' must be defined.")
    url_ = url_.replace(
      '{projectPhaseLabelId}',
      encodeURIComponent('' + projectPhaseLabelId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectPhaseLabel)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateProjectPhaseLabel(_response)
    })
  }

  protected processUpdateProjectPhaseLabel(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a project phase label
   * @return OK
   */
  deleteProjectPhaseLabel(projectPhaseLabelId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/projectPhaseLabels/{projectPhaseLabelId}'
    if (projectPhaseLabelId === undefined || projectPhaseLabelId === null)
      throw new Error("The parameter 'projectPhaseLabelId' must be defined.")
    url_ = url_.replace(
      '{projectPhaseLabelId}',
      encodeURIComponent('' + projectPhaseLabelId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectPhaseLabel(_response)
    })
  }

  protected processDeleteProjectPhaseLabel(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
     * Search and filter projects.
     * @param search (optional) Free text search phrase.
     * @param filter (optional) OData-style <code>$filter</code> param
     * @param skip (optional) Number of results to skip.
     * @param top (optional) Number of results to show.
     * @param orderby (optional) OData-style sort order. If search param is used, defaults to the
     search score. Otherwise, defaults to <code>name asc</code>.
     * @return OK
     */
  getAllProjectsV2(
    search: string | null | undefined,
    filter: string | null | undefined,
    skip: number | null | undefined,
    top: number | null | undefined,
    orderby: string | null | undefined,
  ): Promise<SearchResultSetOfQueryableProject> {
    let url_ = this.baseUrl + '/v2/projects?'
    if (search !== undefined)
      url_ += 'search=' + encodeURIComponent('' + search) + '&'
    if (filter !== undefined)
      url_ += 'filter=' + encodeURIComponent('' + filter) + '&'
    if (skip !== undefined)
      url_ += 'skip=' + encodeURIComponent('' + skip) + '&'
    if (top !== undefined) url_ += 'top=' + encodeURIComponent('' + top) + '&'
    if (orderby !== undefined)
      url_ += 'orderby=' + encodeURIComponent('' + orderby) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetAllProjectsV2(_response)
    })
  }

  protected processGetAllProjectsV2(
    response: Response,
  ): Promise<SearchResultSetOfQueryableProject> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = SearchResultSetOfQueryableProject.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<SearchResultSetOfQueryableProject>(<any>null)
  }

  /**
   * Create a project
   * @return OK
   */
  createProject(project: ApiProject): Promise<ApiProject> {
    let url_ = this.baseUrl + '/v2/projects'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(project)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateProject(_response)
    })
  }

  protected processCreateProject(response: Response): Promise<ApiProject> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200 || status === 201) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProject.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProject>(<any>null)
  }

  /**
   * @return OK
   */
  getProject(id: string): Promise<ApiProject> {
    let url_ = this.baseUrl + '/v2/projects/{id}'
    //console.log('#@! url: ' + id);
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetProject(_response)
    })
  }

  protected processGetProject(response: Response): Promise<ApiProject> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        //let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        //@@@@ watch for issues
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProject.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProject>(<any>null)
  }

  patchProject(project: unknown): Promise<ApiProject> {
    let url_ = this.baseUrl + '/v2/projects/{id}'
    if (project.id === undefined || project.id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + project.id))
    url_ = url_.replace(/[?&]$/, '')
    console.log('_____', { project })
    // Remove the 'id' property from the project object
    const { id: removedId, ...newProject } = project

    const content_ = JSON.stringify(newProject)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processPatchProject(_response)
    })
  }

  protected processPatchProject(response: Response): Promise<ApiProject> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProject.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProject>(<any>null)
  }

  /**
   * Update a project
   * @return OK
   */
  updateProject(
    id: string,
    project: ApiProject | unknown | { id?: string },
  ): Promise<ApiProject> {
    let url_ = this.baseUrl + '/v2/projects/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(project)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateProject(_response)
    })
  }

  protected processUpdateProject(response: Response): Promise<ApiProject> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProject.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProject>(<any>null)
  }

  /**
   * DANGER WILL ROBINSON
   * @return OK
   */
  deleteProject(id: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/projects/{id}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProject(_response)
    })
  }

  protected processDeleteProject(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Get calculated totals for project queries.
   * @param search (optional) Free text search phrase.
   * @param filter (optional) OData-style <code>$filter</code> param
   * @return OK
   */
  getProjectSearchStatistics(
    search: string | null | undefined,
    filter: string | null | undefined,
  ): Promise<ProjectSearchStatistics> {
    let url_ = this.baseUrl + '/v2/projects/statistics?'
    if (search !== undefined)
      url_ += 'search=' + encodeURIComponent('' + search) + '&'
    if (filter !== undefined)
      url_ += 'filter=' + encodeURIComponent('' + filter) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetProjectSearchStatistics(_response)
    })
  }

  protected processGetProjectSearchStatistics(
    response: Response,
  ): Promise<ProjectSearchStatistics> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectSearchStatistics.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectSearchStatistics>(<any>null)
  }

  /**
   * Create or update a project and company link
   * @param projectId Id of project to link.
   * @param companyId Id of company to link.
   * @param projectCompany Project's and company's ids in the body are ignored.
   */
  createOrUpdateProjectCompanyLink(
    projectId: string,
    companyId: string,
    projectCompany: ProjectCompany,
  ): Promise<ProjectCompany> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/companies/{companyId}/__referencePath'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (companyId === undefined || companyId === null)
      throw new Error("The parameter 'companyId' must be defined.")
    url_ = url_.replace('{companyId}', encodeURIComponent('' + companyId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectCompany)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateProjectCompanyLink(_response)
    })
  }

  protected processCreateOrUpdateProjectCompanyLink(
    response: Response,
  ): Promise<ProjectCompany> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectCompany.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Company doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectCompany>(<any>null)
  }

  /**
   * Unlink a project and company.
   * @param projectId Id of the project to unlink.
   * @param companyId Id of the company to unlink.
   * @return Should always respond with a 204 No Content, including when company, project, or link does not exist
   */
  deleteProjectCompanyLink(
    projectId: string,
    companyId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/projects/{projectId}/companies/{companyId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (companyId === undefined || companyId === null)
      throw new Error("The parameter 'companyId' must be defined.")
    url_ = url_.replace('{companyId}', encodeURIComponent('' + companyId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectCompanyLink(_response)
    })
  }

  protected processDeleteProjectCompanyLink(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create or update a project and company link
   * @param projectId Id of project to link.
   * @param companyId Id of company to link.
   * @param projectCompany Project's and company's ids in the body are ignored.
   */
  createOrUpdateProjectCompanyLink2(
    projectId: string,
    companyId: string,
    projectCompany: ProjectCompany,
  ): Promise<ProjectCompany> {
    let url_ =
      this.baseUrl + '/v2/companies/{companyId}/projects/{projectId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (companyId === undefined || companyId === null)
      throw new Error("The parameter 'companyId' must be defined.")
    url_ = url_.replace('{companyId}', encodeURIComponent('' + companyId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectCompany)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateProjectCompanyLink2(_response)
    })
  }

  protected processCreateOrUpdateProjectCompanyLink2(
    response: Response,
  ): Promise<ProjectCompany> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectCompany.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Company doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectCompany>(<any>null)
  }

  /**
   * Unlink a project and company.
   * @param projectId Id of the project to unlink.
   * @param companyId Id of the company to unlink.
   * @return Should always respond with a 204 No Content, including when company, project, or link does not exist
   */
  deleteProjectCompanyLink2(
    projectId: string,
    companyId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/companies/{companyId}/projects/{projectId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (companyId === undefined || companyId === null)
      throw new Error("The parameter 'companyId' must be defined.")
    url_ = url_.replace('{companyId}', encodeURIComponent('' + companyId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectCompanyLink2(_response)
    })
  }

  protected processDeleteProjectCompanyLink2(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create or update a project and contact link
   * @param projectId Id of project to link.
   * @param contactId Id of contact to link.
   * @param projectContact Project's and contact's ids in the body are ignored.
   */
  createOrUpdateProjectContactLink(
    projectId: string,
    contactId: string,
    projectContact: ProjectContact,
  ): Promise<ProjectContact> {
    let url_ =
      this.baseUrl + '/v2/projects/{projectId}/contacts/{contactId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectContact)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateProjectContactLink(_response)
    })
  }

  protected processCreateOrUpdateProjectContactLink(
    response: Response,
  ): Promise<ProjectContact> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectContact.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Contact doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectContact>(<any>null)
  }

  /**
   * Unlink a project and contact.
   * @param projectId Id of the project to unlink.
   * @param contactId Id of the contact to unlink.
   * @return Should always respond with a 204 No Content, including when contact, project, or link does not exist
   */
  deleteProjectContactLink(
    projectId: string,
    contactId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/projects/{projectId}/contacts/{contactId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectContactLink(_response)
    })
  }

  protected processDeleteProjectContactLink(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create or update a project and contact link
   * @param projectId Id of project to link.
   * @param contactId Id of contact to link.
   * @param projectContact Project's and contact's ids in the body are ignored.
   */
  createOrUpdateProjectContactLink2(
    projectId: string,
    contactId: string,
    projectContact: ProjectContact,
  ): Promise<ProjectContact> {
    let url_ =
      this.baseUrl + '/v2/contacts/{contactId}/projects/{projectId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectContact)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateProjectContactLink2(_response)
    })
  }

  protected processCreateOrUpdateProjectContactLink2(
    response: Response,
  ): Promise<ProjectContact> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectContact.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Contact doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectContact>(<any>null)
  }

  /**
   * Unlink a project and contact.
   * @param projectId Id of the project to unlink.
   * @param contactId Id of the contact to unlink.
   * @return Should always respond with a 204 No Content, including when contact, project, or link does not exist
   */
  deleteProjectContactLink2(
    projectId: string,
    contactId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/contacts/{contactId}/projects/{projectId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (contactId === undefined || contactId === null)
      throw new Error("The parameter 'contactId' must be defined.")
    url_ = url_.replace('{contactId}', encodeURIComponent('' + contactId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectContactLink2(_response)
    })
  }

  protected processDeleteProjectContactLink2(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
     * Link a person to a project. Creates a two-way link.
     * @param projectId Id of project to link.
     * @param history Object containing person's id and person's title on that project.
     Project's id in the body is ignored.
     * @return Contains person's id, project's id, and the person's title for that project.
     */
  addPersonToProject(
    projectId: string,
    history: ProjectPersonHistory,
  ): Promise<ProjectPersonHistory> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/people/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(history)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processAddPersonToProject(_response)
    })
  }

  protected processAddPersonToProject(
    response: Response,
  ): Promise<ProjectPersonHistory> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectPersonHistory.fromJS(resultData200)
        return result200
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        return throwException(
          "Person doesn't exist, or project and person are already linked.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Project doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectPersonHistory>(<any>null)
  }

  /**
     * Link a project to a person. Creates a two-way link.
     * @param personId Id of person to link.
     * @param history Object containing projectId and person's title on that project.
     Person's id in the body is ignored.
     * @return Contains person's id, project's id, and the person's title for that project.
     */
  addProjectToPerson(
    personId: number,
    history: ProjectPersonHistory,
  ): Promise<ProjectPersonHistory> {
    let url_ = this.baseUrl + '/v2/people/{personId}/projects/$ref'
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(history)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processAddProjectToPerson(_response)
    })
  }

  protected processAddProjectToPerson(
    response: Response,
  ): Promise<ProjectPersonHistory> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectPersonHistory.fromJS(resultData200)
        return result200
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        return throwException(
          "Project doesn't exist, or project and person are already linked.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          "Person doesn't exist.",
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectPersonHistory>(<any>null)
  }

  /**
     * Update a person's title on a given project.
     * @param projectId Id of the project the person is/was on.
     * @param personId Id of the person whose project-specific title to update
     * @param history JSON object with "personTitleOnProject" set to the new title. Project and person IDs
     in the body are ignored.
     * @return Contains a history object with the updated title
     */
  updatePersonProjectHistory(
    projectId: string,
    personId: number,
    history: ProjectPersonHistory,
  ): Promise<ProjectPersonHistory> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/people/{personId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(history)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdatePersonProjectHistory(_response)
    })
  }

  protected processUpdatePersonProjectHistory(
    response: Response,
  ): Promise<ProjectPersonHistory> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectPersonHistory.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          'Project, person, or previous history does not exist.',
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectPersonHistory>(<any>null)
  }

  /**
   * Unlink a project and person.
   * @param projectId Id of the person to unlink.
   * @param personId Id of the project to unlink.
   * @return Should always respond with a 204 No Content, including when person, project, or link does not exist
   */
  deletePersonProjectHistory(
    projectId: string,
    personId: number,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/people/{personId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeletePersonProjectHistory(_response)
    })
  }

  protected processDeletePersonProjectHistory(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
     * Update a person's title on a given project.
     * @param projectId Id of the project the person is/was on.
     * @param personId Id of the person whose project-specific title to update
     * @param history JSON object with "personTitleOnProject" set to the new title. Project and person IDs
     in the body are ignored.
     * @return Contains a history object with the updated title
     */
  updatePersonProjectHistory2(
    projectId: string,
    personId: number,
    history: ProjectPersonHistory,
  ): Promise<ProjectPersonHistory> {
    let url_ =
      this.baseUrl +
      '/v2/people/{personId}/projects/{projectId}/__referencePath'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(history)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdatePersonProjectHistory2(_response)
    })
  }

  protected processUpdatePersonProjectHistory2(
    response: Response,
  ): Promise<ProjectPersonHistory> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ProjectPersonHistory.fromJS(resultData200)
        return result200
      })
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException(
          'Project, person, or previous history does not exist.',
          status,
          _responseText,
          _headers,
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ProjectPersonHistory>(<any>null)
  }

  /**
   * Unlink a project and person.
   * @param projectId Id of the person to unlink.
   * @param personId Id of the project to unlink.
   * @return Should always respond with a 204 No Content, including when person, project, or link does not exist
   */
  deletePersonProjectHistory2(
    projectId: string,
    personId: number,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/people/{personId}/projects/{projectId}/__referencePath'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (personId === undefined || personId === null)
      throw new Error("The parameter 'personId' must be defined.")
    url_ = url_.replace('{personId}', encodeURIComponent('' + personId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeletePersonProjectHistory2(_response)
    })
  }

  protected processDeletePersonProjectHistory2(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Unlink a project and reference.
   * @param projectId Id of the project to unlink.
   * @param referenceId Id of the reference to unlink.
   * @return Should always respond with a 204 No Content, including when reference, project, or link does not exist
   */
  deleteProjectReferenceLink(
    projectId: string,
    referenceId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl + '/v2/projects/{projectId}/references/{referenceId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (referenceId === undefined || referenceId === null)
      throw new Error("The parameter 'referenceId' must be defined.")
    url_ = url_.replace('{referenceId}', encodeURIComponent('' + referenceId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectReferenceLink(_response)
    })
  }

  protected processDeleteProjectReferenceLink(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Unlink a project and stakeholder.
   * @param projectId Id of the project to unlink.
   * @param stakeholderId Id of the stakeholder to unlink.
   * @return Should always respond with a 204 No Content, including when stakeholder, project, or link does not exist
   */
  deleteProjectStakeholderLink(
    projectId: string,
    stakeholderId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/stakeholders/{stakeholderId}/$ref'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (stakeholderId === undefined || stakeholderId === null)
      throw new Error("The parameter 'stakeholderId' must be defined.")
    url_ = url_.replace(
      '{stakeholderId}',
      encodeURIComponent('' + stakeholderId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectStakeholderLink(_response)
    })
  }

  protected processDeleteProjectStakeholderLink(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then((_responseText) => {
        let result204: any = null
        let resultData204 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result204 = Object.fromJS(resultData204)
        return result204
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Link a SustainabilityCertification to a Project
   * @return OK
   */
  createProjectSustainabilityCertification(
    projectSustainabilityCertification: ApiProjectSustainabilityCertification,
    projectId: string,
  ): Promise<ApiProjectSustainabilityCertification> {
    let url_ = this.baseUrl + '/v2/projects/{id}/sustainabilityCertifications'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectSustainabilityCertification)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateProjectSustainabilityCertification(_response)
    })
  }

  protected processCreateProjectSustainabilityCertification(
    response: Response,
  ): Promise<ApiProjectSustainabilityCertification> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProjectSustainabilityCertification.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProjectSustainabilityCertification>(<any>null)
  }

  /**
   * Update a Project's SustainabilityCertification
   * @return OK
   */
  updateProjectSustainabilityCertification(
    projectSustainabilityCertification: ApiProjectSustainabilityCertification,
    projectId: string,
    projectSustainabilityCertificationId: string,
  ): Promise<ApiProjectSustainabilityCertification> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{id}/sustainabilityCertifications/{projectSustainabilityCertificationId}'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + projectId))
    if (
      projectSustainabilityCertificationId === undefined ||
      projectSustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'projectSustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{projectSustainabilityCertificationId}',
      encodeURIComponent('' + projectSustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectSustainabilityCertification)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateProjectSustainabilityCertification(_response)
    })
  }

  protected processUpdateProjectSustainabilityCertification(
    response: Response,
  ): Promise<ApiProjectSustainabilityCertification> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProjectSustainabilityCertification.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProjectSustainabilityCertification>(<any>null)
  }

  /**
   * Delete a Project's Sustainability Certification
   * @return OK
   */
  deleteSustainabilityCertification(
    id: string,
    projectSustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{id}/sustainabilityCertifications/{projectSustainabilityCertificationId}'
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    if (
      projectSustainabilityCertificationId === undefined ||
      projectSustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'projectSustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{projectSustainabilityCertificationId}',
      encodeURIComponent('' + projectSustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSustainabilityCertification(_response)
    })
  }

  protected processDeleteSustainabilityCertification(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create a Project Technology Phase
   * @return OK
   */
  createProjectTechnologyPhase(
    projectTechnologyPhase: ApiProjectTechnologyPhase,
    projectId: string,
  ): Promise<ApiProjectTechnologyPhase> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/phases'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectTechnologyPhase)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateProjectTechnologyPhase(_response)
    })
  }

  protected processCreateProjectTechnologyPhase(
    response: Response,
  ): Promise<ApiProjectTechnologyPhase> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProjectTechnologyPhase.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProjectTechnologyPhase>(<any>null)
  }

  /**
   * Update a Project Technology Phase
   * @return OK
   */
  updateProjectTechnologyPhase(
    projectTechnologyPhase:
      | ApiProjectTechnologyPhase
      | IApiProjectTechnologyPhase,
    projectTechnologyPhaseId: string,
    projectId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/phases/{projectTechnologyPhaseId}'
    if (
      projectTechnologyPhaseId === undefined ||
      projectTechnologyPhaseId === null
    )
      throw new Error(
        "The parameter 'projectTechnologyPhaseId' must be defined.",
      )
    url_ = url_.replace(
      '{projectTechnologyPhaseId}',
      encodeURIComponent('' + projectTechnologyPhaseId),
    )
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectTechnologyPhase)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateProjectTechnologyPhase(_response)
    })
  }

  protected processUpdateProjectTechnologyPhase(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a Project Technology Phase
   * @return OK
   */
  deleteProjectTechnologyPhase(
    projectTechnologyPhaseId: string,
    projectId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/phases/{projectTechnologyPhaseId}'
    if (
      projectTechnologyPhaseId === undefined ||
      projectTechnologyPhaseId === null
    )
      throw new Error(
        "The parameter 'projectTechnologyPhaseId' must be defined.",
      )
    url_ = url_.replace(
      '{projectTechnologyPhaseId}',
      encodeURIComponent('' + projectTechnologyPhaseId),
    )
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectTechnologyPhase(_response)
    })
  }

  protected processDeleteProjectTechnologyPhase(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create a Project VDC Technology Phase
   * @return OK
   */
  createProjectVdcTechnologyPhase(
    projectVdcTechnologyPhase: ApiProjectVdcTechnologyPhase,
    projectId: string,
  ): Promise<ApiProjectVdcTechnologyPhase> {
    let url_ = this.baseUrl + '/v2/projects/{projectId}/vdcPhases'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(projectVdcTechnologyPhase)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateProjectVdcTechnologyPhase(_response)
    })
  }

  protected processCreateProjectVdcTechnologyPhase(
    response: Response,
  ): Promise<ApiProjectVdcTechnologyPhase> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiProjectVdcTechnologyPhase.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiProjectVdcTechnologyPhase>(<any>null)
  }

  /**
   * Fake call because it is not provided by the API
   */
  // getProjectVdcTechnologyPhase(projectId: string, projectVdcTechnologyPhaseId: string) {
  //     this.getProject(projectId).then(project => {
  //
  //     })
  // }

  /**
   * Update a Project VDC Technology Phase
   * @return OK
   */
  updateProjectVdcTechnologyPhase(
    projectVdcTechnologyPhase:
      | ApiProjectVdcTechnologyPhase
      | { id?: string }
      | IApiProjectTechnologyPhase,
    projectVdcTechnologyPhaseId: string,
    projectId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/vdcPhases/{projectVdcTechnologyPhaseId}'
    if (
      projectVdcTechnologyPhaseId === undefined ||
      projectVdcTechnologyPhaseId === null
    )
      throw new Error(
        "The parameter 'projectVdcTechnologyPhaseId' must be defined.",
      )
    url_ = url_.replace(
      '{projectVdcTechnologyPhaseId}',
      encodeURIComponent('' + projectVdcTechnologyPhaseId),
    )
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    //console.log('!@! instance test', projectVdcTechnologyPhase instanceof ApiProjectVdcTechnologyPhase)
    const content_ = JSON.stringify(projectVdcTechnologyPhase)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateProjectVdcTechnologyPhase(_response)
    })
  }

  protected processUpdateProjectVdcTechnologyPhase(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a Project VDC Technology Phase
   * @return OK
   */
  deleteProjectVdcTechnologyPhase(
    projectVdcTechnologyPhaseId: string,
    projectId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/vdcPhases/{projectVdcTechnologyPhaseId}'
    if (
      projectVdcTechnologyPhaseId === undefined ||
      projectVdcTechnologyPhaseId === null
    )
      throw new Error(
        "The parameter 'projectVdcTechnologyPhaseId' must be defined.",
      )
    url_ = url_.replace(
      '{projectVdcTechnologyPhaseId}',
      encodeURIComponent('' + projectVdcTechnologyPhaseId),
    )
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteProjectVdcTechnologyPhase(_response)
    })
  }

  protected processDeleteProjectVdcTechnologyPhase(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * @return OK
   */
  getEntireSchema(entity: string): Promise<ExportSchema> {
    let url_ = this.baseUrl + '/v2/schemas/{entity}'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetEntireSchema(_response)
    })
  }

  protected processGetEntireSchema(response: Response): Promise<ExportSchema> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ExportSchema.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ExportSchema>(<any>null)
  }

  /**
   * @return OK
   */
  getEntireSchema2(entity: string): Promise<ExportSchema> {
    let url_ = this.baseUrl + '/v2/schemas/exportables/{entity}'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetEntireSchema2(_response)
    })
  }

  protected processGetEntireSchema2(response: Response): Promise<ExportSchema> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ExportSchema.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ExportSchema>(<any>null)
  }

  /**
   * @return OK
   */
  getEntireSchemaEx(entity: string, selector: string): Promise<ExportSchema> {
    let url_ =
      this.baseUrl + '/v2/schemas/exportables/{entity}/slice/{selector}'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    if (selector === undefined || selector === null)
      throw new Error("The parameter 'selector' must be defined.")
    url_ = url_.replace('{selector}', encodeURIComponent('' + selector))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetEntireSchemaEx(_response)
    })
  }

  protected processGetEntireSchemaEx(
    response: Response,
  ): Promise<ExportSchema> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ExportSchema.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ExportSchema>(<any>null)
  }

  /**
   * @return OK
   */
  getQueryableSchema(entity: string): Promise<ExportSchema> {
    let url_ = this.baseUrl + '/v2/schemas/queryables/{entity}'
    if (entity === undefined || entity === null)
      throw new Error("The parameter 'entity' must be defined.")
    url_ = url_.replace('{entity}', encodeURIComponent('' + entity))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetQueryableSchema(_response)
    })
  }

  protected processGetQueryableSchema(
    response: Response,
  ): Promise<ExportSchema> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ExportSchema.fromJS(resultData200)
        //console.log('!@#- inside athenaClient', result200);
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ExportSchema>(<any>null)
  }

  /**
   * Get all Supplier Diversity Classification
   * @return OK
   */
  getSupplierDiversityClassifications(): Promise<
    ApiSupplierDiversityClassification[]
  > {
    let url_ = this.baseUrl + '/v2/supplierDiversityClassifications'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetSupplierDiversityClassifications(_response)
    })
  }

  // protected processGetSupplierDiversityClassifications(response: Response): Promise<ApiSupplierDiversityClassification> {
  //     const status = response.status;
  //     let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
  //     if (status === 200) {
  //         return response.text().then((_responseText) => {
  //         let result200: any = null;
  //         let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
  //         result200 = ApiSupplierDiversityClassification.fromJS(resultData200);
  //         return result200;
  //         });
  //     } else if (status !== 200 && status !== 204) {
  //         return response.text().then((_responseText) => {
  //         return throwException("An unexpected server error occurred.", status, _responseText, _headers);
  //         });
  //     }
  //     return Promise.resolve<ApiSupplierDiversityClassification>(<any>null);
  // }

  protected processGetSupplierDiversityClassifications(
    response: Response,
  ): Promise<ApiSupplierDiversityClassification[]> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(ApiSupplierDiversityClassification.fromJS(item))
        }
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSupplierDiversityClassification[]>(<any>null)
  }

  /**
   * Create a Supplier Diversity Classification
   * @return OK
   */
  createSupplierDiversityClassification(
    supplierDiversityClassification: ApiSupplierDiversityClassification,
  ): Promise<ApiSupplierDiversityClassification> {
    let url_ = this.baseUrl + '/v2/supplierDiversityClassifications'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(supplierDiversityClassification)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateSupplierDiversityClassification(_response)
    })
  }

  protected processCreateSupplierDiversityClassification(
    response: Response,
  ): Promise<ApiSupplierDiversityClassification> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiSupplierDiversityClassification.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSupplierDiversityClassification>(<any>null)
  }

  /**
   * Get a single Supplier Diversity Classifications
   * @return OK
   */
  getSupplierDiversityClassification(
    supplierDiversityClassificationId: string,
  ): Promise<ApiSupplierDiversityClassification> {
    let url_ =
      this.baseUrl +
      '/v2/supplierDiversityClassifications/{supplierDiversityClassificationId}'
    if (
      supplierDiversityClassificationId === undefined ||
      supplierDiversityClassificationId === null
    )
      throw new Error(
        "The parameter 'supplierDiversityClassificationId' must be defined.",
      )
    url_ = url_.replace(
      '{supplierDiversityClassificationId}',
      encodeURIComponent('' + supplierDiversityClassificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetSupplierDiversityClassification(_response)
    })
  }

  protected processGetSupplierDiversityClassification(
    response: Response,
  ): Promise<ApiSupplierDiversityClassification> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiSupplierDiversityClassification.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSupplierDiversityClassification>(<any>null)
  }

  /**
   * Update a Supplier Diversity Classification
   * @return OK
   */
  updateSupplierDiversityClassification(
    supplierDiversityClassification: ApiSupplierDiversityClassification,
    supplierDiversityClassificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/supplierDiversityClassifications/{supplierDiversityClassificationId}'
    if (
      supplierDiversityClassificationId === undefined ||
      supplierDiversityClassificationId === null
    )
      throw new Error(
        "The parameter 'supplierDiversityClassificationId' must be defined.",
      )
    url_ = url_.replace(
      '{supplierDiversityClassificationId}',
      encodeURIComponent('' + supplierDiversityClassificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(supplierDiversityClassification)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateSupplierDiversityClassification(_response)
    })
  }

  protected processUpdateSupplierDiversityClassification(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a Supplier Diversity Classification
   * @return OK
   */
  deleteSupplierDiversityClassification(
    supplierDiversityClassificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/supplierDiversityClassifications/{supplierDiversityClassificationId}'
    if (
      supplierDiversityClassificationId === undefined ||
      supplierDiversityClassificationId === null
    )
      throw new Error(
        "The parameter 'supplierDiversityClassificationId' must be defined.",
      )
    url_ = url_.replace(
      '{supplierDiversityClassificationId}',
      encodeURIComponent('' + supplierDiversityClassificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSupplierDiversityClassification(_response)
    })
  }

  protected processDeleteSupplierDiversityClassification(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Create or Update a Supplier Diversity Goal
   * @return OK
   */
  createOrUpdateSupplierDiversityGoal(
    supplierDiversityGoal: ApiSupplierDiversityGoal,
    projectId: string,
    supplierDiversityClassificationId: string,
  ): Promise<ApiSupplierDiversityGoal> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/supplierDiversityGoals/{supplierDiversityClassificationId}/__referencePath'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (
      supplierDiversityClassificationId === undefined ||
      supplierDiversityClassificationId === null
    )
      throw new Error(
        "The parameter 'supplierDiversityClassificationId' must be defined.",
      )
    url_ = url_.replace(
      '{supplierDiversityClassificationId}',
      encodeURIComponent('' + supplierDiversityClassificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(supplierDiversityGoal)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateSupplierDiversityGoal(_response)
    })
  }

  protected processCreateOrUpdateSupplierDiversityGoal(
    response: Response,
  ): Promise<ApiSupplierDiversityGoal> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiSupplierDiversityGoal.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSupplierDiversityGoal>(<any>null)
  }

  /**
   * Delete a Supplier Diversity Goal
   * @return OK
   */
  deleteSupplierDiversityGoal(
    projectId: string,
    supplierDiversityClassificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/projects/{projectId}/supplierDiversityGoals/{supplierDiversityClassificationId}'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    if (
      supplierDiversityClassificationId === undefined ||
      supplierDiversityClassificationId === null
    )
      throw new Error(
        "The parameter 'supplierDiversityClassificationId' must be defined.",
      )
    url_ = url_.replace(
      '{supplierDiversityClassificationId}',
      encodeURIComponent('' + supplierDiversityClassificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSupplierDiversityGoal(_response)
    })
  }

  protected processDeleteSupplierDiversityGoal(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * @return OK
   */
  syncSupplierDiversityGoalsBulk(
    projectId: string,
    incomingSupplierDiversityGoals: ApiSupplierDiversityGoal[],
  ): Promise<ApiSupplierDiversityGoal[]> {
    let url_ =
      this.baseUrl + '/v2/projects/{projectId}/supplierDiversityGoalsBulk'
    if (projectId === undefined || projectId === null)
      throw new Error("The parameter 'projectId' must be defined.")
    url_ = url_.replace('{projectId}', encodeURIComponent('' + projectId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(incomingSupplierDiversityGoals)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processSyncSupplierDiversityGoalsBulk(_response)
    })
  }

  protected processSyncSupplierDiversityGoalsBulk(
    response: Response,
  ): Promise<ApiSupplierDiversityGoal[]> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(ApiSupplierDiversityGoal.fromJS(item))
        }
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSupplierDiversityGoal[]>(<any>null)
  }

  /**
   * Get Sustainability Certifications
   * @return OK
   */
  getSustainabilityCertification(
    sustainabilityCertificationId: string,
  ): Promise<ApiSustainabilityCertification> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}'
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetSustainabilityCertification(_response)
    })
  }

  protected processGetSustainabilityCertification(
    response: Response,
  ): Promise<ApiSustainabilityCertification> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiSustainabilityCertification.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSustainabilityCertification>(<any>null)
  }

  /**
   * Update a Sustainability Certification
   * @return OK
   */
  updateSustainabilityCertification(
    sustainabilityCertification: ApiSustainabilityCertification,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}'
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertification)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateSustainabilityCertification(_response)
    })
  }

  protected processUpdateSustainabilityCertification(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a Sustainability Certification
   * @return OK
   */
  deleteSustainabilityCertification2(
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}'
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSustainabilityCertification2(_response)
    })
  }

  protected processDeleteSustainabilityCertification2(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Get a single Sustainability Certification
   * @return OK
   */
  getSustainabilityCertifications(): Promise<ApiSustainabilityCertification[]> {
    let url_ = this.baseUrl + '/v2/sustainabilityCertifications'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetSustainabilityCertifications(_response)
    })
  }

  // protected processGetSustainabilityCertifications(response: Response): Promise<ApiSustainabilityCertification[]> {
  //     const status = response.status;
  //     let _headers: any = {};
  //     if (response.headers && response.headers.forEach) {
  //         response.headers.forEach((v: any, k: any) => _headers[k] = v);
  //     }
  //
  //     if (status === 200) {
  //         return response.text().then((_responseText) => {
  //             let result200: any = null;
  //             let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
  //             result200 = ApiSustainabilityCertification.fromJS(resultData200);
  //             return result200;
  //         });
  //     } else if (status !== 200 && status !== 204) {
  //         return response.text().then((_responseText) => {
  //             return throwException("An unexpected server error occurred.", status, _responseText, _headers);
  //         });
  //     }
  //     return Promise.resolve<ApiSustainabilityCertification[]>(<any>null);
  // }

  protected processGetSustainabilityCertifications(
    response: Response,
  ): Promise<ApiSustainabilityCertification[]> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(ApiSustainabilityCertification.fromJS(item))
        }
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSustainabilityCertification[]>(<any>null)
  }

  /**
   * Create a Sustainability Certification
   * @return OK
   */
  createSustainabilityCertification(
    sustainabilityCertification: ApiSustainabilityCertification,
  ): Promise<ApiSustainabilityCertification> {
    let url_ = this.baseUrl + '/v2/sustainabilityCertifications'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertification)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateSustainabilityCertification(_response)
    })
  }

  protected processCreateSustainabilityCertification(
    response: Response,
  ): Promise<ApiSustainabilityCertification> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiSustainabilityCertification.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiSustainabilityCertification>(<any>null)
  }

  /**
   * Create a Sustainability Certification Version
   * @return OK
   */
  createSustainabilityCertificationVersion(
    sustainabilityCertificationVersion: SustainabilityCertificationVersion,
    sustainabilityCertificationId: string,
  ): Promise<SustainabilityCertificationVersion> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/versions'
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertificationVersion)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateSustainabilityCertificationVersion(_response)
    })
  }

  protected processCreateSustainabilityCertificationVersion(
    response: Response,
  ): Promise<SustainabilityCertificationVersion> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = SustainabilityCertificationVersion.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<SustainabilityCertificationVersion>(<any>null)
  }

  /**
   * Create a Sustainability Certification Type
   * @return OK
   */
  createSustainabilityCertificationType(
    sustainabilityCertificationType: SustainabilityCertificationType,
    sustainabilityCertificationId: string,
  ): Promise<SustainabilityCertificationType> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/types'
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertificationType)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateSustainabilityCertificationType(_response)
    })
  }

  protected processCreateSustainabilityCertificationType(
    response: Response,
  ): Promise<SustainabilityCertificationType> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = SustainabilityCertificationType.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<SustainabilityCertificationType>(<any>null)
  }

  /**
   * Create a Sustainability Certification Level
   * @return OK
   */
  createSustainabilityCertificationLevel(
    sustainabilityCertificationLevel: SustainabilityCertificationLevel,
    sustainabilityCertificationId: string,
  ): Promise<SustainabilityCertificationVersion> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/levels'
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertificationLevel)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateSustainabilityCertificationLevel(_response)
    })
  }

  protected processCreateSustainabilityCertificationLevel(
    response: Response,
  ): Promise<SustainabilityCertificationVersion> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = SustainabilityCertificationVersion.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<SustainabilityCertificationVersion>(<any>null)
  }

  /**
   * Update a Sustainability Certification Version
   * @return OK
   */
  updateSustainabilityCertificationVersion(
    sustainabilityCertificationVersion: SustainabilityCertificationVersion,
    sustainabilityCertificationVersionId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/versions/{sustainabilityCertificationVersionId}'
    if (
      sustainabilityCertificationVersionId === undefined ||
      sustainabilityCertificationVersionId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationVersionId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationVersionId}',
      encodeURIComponent('' + sustainabilityCertificationVersionId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertificationVersion)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateSustainabilityCertificationVersion(_response)
    })
  }

  protected processUpdateSustainabilityCertificationVersion(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a Sustainability Certification Version
   * @return OK
   */
  deleteSustainabilityCertificationVersion(
    sustainabilityCertificationVersionId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/versions/{sustainabilityCertificationVersionId}'
    if (
      sustainabilityCertificationVersionId === undefined ||
      sustainabilityCertificationVersionId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationVersionId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationVersionId}',
      encodeURIComponent('' + sustainabilityCertificationVersionId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSustainabilityCertificationVersion(_response)
    })
  }

  protected processDeleteSustainabilityCertificationVersion(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Update a Sustainability Certification Type
   * @return OK
   */
  updateSustainabilityCertificationType(
    sustainabilityCertificationType: SustainabilityCertificationType,
    sustainabilityCertificationTypeId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/types/{sustainabilityCertificationTypeId}'
    if (
      sustainabilityCertificationTypeId === undefined ||
      sustainabilityCertificationTypeId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationTypeId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationTypeId}',
      encodeURIComponent('' + sustainabilityCertificationTypeId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertificationType)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateSustainabilityCertificationType(_response)
    })
  }

  protected processUpdateSustainabilityCertificationType(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a Sustainability Certification Type
   * @return OK
   */
  deleteSustainabilityCertificationType(
    sustainabilityCertificationTypeId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/types/{sustainabilityCertificationTypeId}'
    if (
      sustainabilityCertificationTypeId === undefined ||
      sustainabilityCertificationTypeId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationTypeId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationTypeId}',
      encodeURIComponent('' + sustainabilityCertificationTypeId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSustainabilityCertificationType(_response)
    })
  }

  protected processDeleteSustainabilityCertificationType(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Update a Sustainability Certification Level
   * @return OK
   */
  updateSustainabilityCertificationLevel(
    sustainabilityCertificationLevel: SustainabilityCertificationLevel,
    sustainabilityCertificationLevelId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/levels/{sustainabilityCertificationLevelId}'
    if (
      sustainabilityCertificationLevelId === undefined ||
      sustainabilityCertificationLevelId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationLevelId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationLevelId}',
      encodeURIComponent('' + sustainabilityCertificationLevelId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(sustainabilityCertificationLevel)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateSustainabilityCertificationLevel(_response)
    })
  }

  protected processUpdateSustainabilityCertificationLevel(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a Sustainability Certification Level
   * @return OK
   */
  deleteSustainabilityCertificationLevel(
    sustainabilityCertificationLevelId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/levels/{sustainabilityCertificationLevelId}'
    if (
      sustainabilityCertificationLevelId === undefined ||
      sustainabilityCertificationLevelId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationLevelId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationLevelId}',
      encodeURIComponent('' + sustainabilityCertificationLevelId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteSustainabilityCertificationLevel(_response)
    })
  }

  protected processDeleteSustainabilityCertificationLevel(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Link a Sustainability Certification Type to Sustainability Certification Version
   * @return OK
   */
  linkSustainabilityCertificationTypeToSustainabilityCertificationVersion(
    sustainabilityCertificationTypeId: string,
    sustainabilityCertificationVersionId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/versions/{sustainabilityCertificationVersionId}/types/{sustainabilityCertificationTypeId}/__referencePath'
    if (
      sustainabilityCertificationTypeId === undefined ||
      sustainabilityCertificationTypeId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationTypeId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationTypeId}',
      encodeURIComponent('' + sustainabilityCertificationTypeId),
    )
    if (
      sustainabilityCertificationVersionId === undefined ||
      sustainabilityCertificationVersionId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationVersionId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationVersionId}',
      encodeURIComponent('' + sustainabilityCertificationVersionId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processLinkSustainabilityCertificationTypeToSustainabilityCertificationVersion(
        _response,
      )
    })
  }

  protected processLinkSustainabilityCertificationTypeToSustainabilityCertificationVersion(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * UnLink a Sustainability Certification Type from Sustainability Certification Version
   * @return OK
   */
  unlinkSustainabilityCertificationTypeToSustainabilityCertificationVersion(
    sustainabilityCertificationTypeId: string,
    sustainabilityCertificationVersionId: string,
    sustainabilityCertificationId: string,
  ): Promise<Object> {
    let url_ =
      this.baseUrl +
      '/v2/sustainabilityCertifications/{sustainabilityCertificationId}/versions/{sustainabilityCertificationVersionId}/types/{sustainabilityCertificationTypeId}/__referencePath'
    if (
      sustainabilityCertificationTypeId === undefined ||
      sustainabilityCertificationTypeId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationTypeId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationTypeId}',
      encodeURIComponent('' + sustainabilityCertificationTypeId),
    )
    if (
      sustainabilityCertificationVersionId === undefined ||
      sustainabilityCertificationVersionId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationVersionId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationVersionId}',
      encodeURIComponent('' + sustainabilityCertificationVersionId),
    )
    if (
      sustainabilityCertificationId === undefined ||
      sustainabilityCertificationId === null
    )
      throw new Error(
        "The parameter 'sustainabilityCertificationId' must be defined.",
      )
    url_ = url_.replace(
      '{sustainabilityCertificationId}',
      encodeURIComponent('' + sustainabilityCertificationId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUnlinkSustainabilityCertificationTypeToSustainabilityCertificationVersion(
        _response,
      )
    })
  }

  protected processUnlinkSustainabilityCertificationTypeToSustainabilityCertificationVersion(
    response: Response,
  ): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Get a technology
   * @return OK
   */
  getTechnologies(): Promise<ApiTechnology> {
    let url_ = this.baseUrl + '/v2/technologies'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetTechnologies(_response)
    })
  }

  protected processGetTechnologies(response: Response): Promise<ApiTechnology> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiTechnology.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiTechnology>(<any>null)
  }

  /**
   * Create a Technology
   * @return OK
   */
  createTechnology(technology: ApiTechnology): Promise<ApiTechnology> {
    let url_ = this.baseUrl + '/v2/technologies'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(technology)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateTechnology(_response)
    })
  }

  protected processCreateTechnology(
    response: Response,
  ): Promise<ApiTechnology> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiTechnology.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiTechnology>(<any>null)
  }

  /**
   * Update a technology
   * @return OK
   */
  updateTechnology(
    technology: ApiTechnology,
    technologyId: string,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/technologies/{technologyId}'
    if (technologyId === undefined || technologyId === null)
      throw new Error("The parameter 'technologyId' must be defined.")
    url_ = url_.replace('{technologyId}', encodeURIComponent('' + technologyId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(technology)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateTechnology(_response)
    })
  }

  protected processUpdateTechnology(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a technology
   * @return OK
   */
  deleteTechnology(technologyId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/technologies/{technologyId}'
    if (technologyId === undefined || technologyId === null)
      throw new Error("The parameter 'technologyId' must be defined.")
    url_ = url_.replace('{technologyId}', encodeURIComponent('' + technologyId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteTechnology(_response)
    })
  }

  protected processDeleteTechnology(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * @return OK
   */
  getTemplate(templateId: string): Promise<Template> {
    let url_ = this.baseUrl + '/v2/templates/{templateId}'
    if (templateId === undefined || templateId === null)
      throw new Error("The parameter 'templateId' must be defined.")
    url_ = url_.replace('{templateId}', encodeURIComponent('' + templateId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetTemplate(_response)
    })
  }

  protected processGetTemplate(response: Response): Promise<Template> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Template.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Template>(<any>null)
  }

  /**
   * @return OK
   */
  updateTemplate(templateId: string, changes: Template): Promise<Template> {
    let url_ = this.baseUrl + '/v2/templates/{templateId}'
    if (templateId === undefined || templateId === null)
      throw new Error("The parameter 'templateId' must be defined.")
    url_ = url_.replace('{templateId}', encodeURIComponent('' + templateId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(changes)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateTemplate(_response)
    })
  }

  protected processUpdateTemplate(response: Response): Promise<Template> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Template.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Template>(<any>null)
  }

  /**
   * @return OK
   */
  deleteTemplate(templateId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/templates/{templateId}'
    if (templateId === undefined || templateId === null)
      throw new Error("The parameter 'templateId' must be defined.")
    url_ = url_.replace('{templateId}', encodeURIComponent('' + templateId))
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteTemplate(_response)
    })
  }

  protected processDeleteTemplate(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Filter templates by owner, entity, and target. All three are required.
   * @param owner Owner (UPN) of the templates.
   * @param entity Entity the template applies to, e.g. "projects", "people".
   * @param target Target the template is intended for, e.g. "exports", "searchResults".
   * @return OK
   */
  getTemplates(
    owner: string,
    entity: string,
    target: string,
  ): Promise<Template[]> {
    let url_ = this.baseUrl + '/v2/templates?'
    if (owner === undefined || owner === null)
      throw new Error(
        "The parameter 'owner' must be defined and cannot be null.",
      )
    // hack: fix on server later - it must accept encoded info correctly
    //url_ += "owner=" + encodeURIComponent("" + owner) + "&";
    else url_ += 'owner=' + owner + '&'
    if (entity === undefined || entity === null)
      throw new Error(
        "The parameter 'entity' must be defined and cannot be null.",
      )
    else url_ += 'entity=' + encodeURIComponent('' + entity) + '&'
    if (target === undefined || target === null)
      throw new Error(
        "The parameter 'target' must be defined and cannot be null.",
      )
    else url_ += 'target=' + encodeURIComponent('' + target) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetTemplates(_response)
    })
  }

  protected processGetTemplates(response: Response): Promise<Template[]> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200) result200!.push(Template.fromJS(item))
        }
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Template[]>(<any>null)
  }

  /**
   * @return OK
   */
  createTemplate(template: Template): Promise<Template> {
    let url_ = this.baseUrl + '/v2/templates'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(template)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateTemplate(_response)
    })
  }

  protected processCreateTemplate(response: Response): Promise<Template> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Template.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Template>(<any>null)
  }

  /**
   * Get a VDC technology
   * @return OK
   */
  getVdcTechnologies(): Promise<ApiVdcTechnology[]> {
    let url_ = this.baseUrl + '/v2/vdcTechnologies'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetVdcTechnologies(_response)
    })
  }

  protected processGetVdcTechnologies(
    response: Response,
  ): Promise<ApiVdcTechnology[]> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        if (Array.isArray(resultData200)) {
          result200 = [] as any
          for (let item of resultData200)
            result200!.push(ApiVdcTechnology.fromJS(item))
        }
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiVdcTechnology[]>(<any>null)
  }

  /**
   * Create a VDC Technology
   * @return OK
   */
  createVdcTechnology(
    vdcTechnology: ApiVdcTechnology,
  ): Promise<ApiVdcTechnology> {
    let url_ = this.baseUrl + '/v2/vdcTechnologies'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(vdcTechnology)

    let options_ = <RequestInit>{
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateVdcTechnology(_response)
    })
  }

  protected processCreateVdcTechnology(
    response: Response,
  ): Promise<ApiVdcTechnology> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiVdcTechnology.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiVdcTechnology>(<any>null)
  }

  /**
   * Update a VDC technology
   * @return OK
   */
  updateVdcTechnology(
    vdcTechnology: ApiVdcTechnology,
    vdcTechnologyId: string,
  ): Promise<Object> {
    let url_ = this.baseUrl + '/v2/vdcTechnologies/{vdcTechnologyId}'
    if (vdcTechnologyId === undefined || vdcTechnologyId === null)
      throw new Error("The parameter 'vdcTechnologyId' must be defined.")
    url_ = url_.replace(
      '{vdcTechnologyId}',
      encodeURIComponent('' + vdcTechnologyId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(vdcTechnology)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processUpdateVdcTechnology(_response)
    })
  }

  protected processUpdateVdcTechnology(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Delete a VDC technology
   * @return OK
   */
  deleteVdcTechnology(vdcTechnologyId: string): Promise<Object> {
    let url_ = this.baseUrl + '/v2/vdcTechnologies/{vdcTechnologyId}'
    if (vdcTechnologyId === undefined || vdcTechnologyId === null)
      throw new Error("The parameter 'vdcTechnologyId' must be defined.")
    url_ = url_.replace(
      '{vdcTechnologyId}',
      encodeURIComponent('' + vdcTechnologyId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteVdcTechnology(_response)
    })
  }

  protected processDeleteVdcTechnology(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }

  /**
   * Get all Vertical Markets
   * @return OK
   */
  getVerticalMarkets(): Promise<ApiVerticalMarket> {
    let url_ = this.baseUrl + '/v2/verticalMarkets'
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetVerticalMarkets(_response)
    })
  }

  protected processGetVerticalMarkets(
    response: Response,
  ): Promise<ApiVerticalMarket> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiVerticalMarket.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiVerticalMarket>(<any>null)
  }

  /**
   * Create or update a vertical market
   * @param verticalMarket JSON document with 'name' and 'id' in the body
   * @param verticalMarketId Int id you would like the vertical market to have. Typically will correspond to what's in CRM
   * @return OK
   */
  createOrUpdateVerticalMarket(
    verticalMarket: ApiVerticalMarket,
    verticalMarketId: number,
  ): Promise<ApiVerticalMarket> {
    let url_ = this.baseUrl + '/v2/verticalMarkets/{verticalMarketId}'
    if (verticalMarketId === undefined || verticalMarketId === null)
      throw new Error("The parameter 'verticalMarketId' must be defined.")
    url_ = url_.replace(
      '{verticalMarketId}',
      encodeURIComponent('' + verticalMarketId),
    )
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(verticalMarket)

    let options_ = <RequestInit>{
      body: content_,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processCreateOrUpdateVerticalMarket(_response)
    })
  }

  protected processCreateOrUpdateVerticalMarket(
    response: Response,
  ): Promise<ApiVerticalMarket> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = ApiVerticalMarket.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<ApiVerticalMarket>(<any>null)
  }

  /**
   * Delete a vertical market. Will remove from all linked accounts and projects.
   * @return OK
   */
  deleteVerticalMarket(verticalMarketId: number): Promise<Object> {
    let url_ = this.baseUrl + '/v2/verticalMarkets/{verticalMarketId}'
    if (verticalMarketId === undefined || verticalMarketId === null)
      throw new Error("The parameter 'verticalMarketId' must be defined.")
    url_ = url_.replace(
      '{verticalMarketId}',
      encodeURIComponent('' + verticalMarketId),
    )
    url_ = url_.replace(/[?&]$/, '')

    let options_ = <RequestInit>{
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
    }

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteVerticalMarket(_response)
    })
  }

  protected processDeleteVerticalMarket(response: Response): Promise<Object> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = Object.fromJS(resultData200)
        return result200
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers,
        )
      })
    }
    return Promise.resolve<Object>(<any>null)
  }
}

export class Object implements IObject {
  constructor(data?: IObject) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    _.noop(data)
  }

  static fromJS(data: any): Object {
    data = typeof data === 'object' ? data : {}
    let result = new Object()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    return data
  }
}

export interface IObject {}

export class DamAssetMetadata implements IDamAssetMetadata {
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  thumbnailUrl?: string | undefined

  constructor(data?: IDamAssetMetadata) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name']
      this.url = data['url']
      this.sizeInBytes = data['sizeInBytes']
      this.contentType = data['contentType']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.thumbnailUrl = data['thumbnailUrl']
    }
  }

  static fromJS(data: any): DamAssetMetadata {
    data = typeof data === 'object' ? data : {}
    let result = new DamAssetMetadata()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['name'] = this.name
    data['url'] = this.url
    data['sizeInBytes'] = this.sizeInBytes
    data['contentType'] = this.contentType
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['thumbnailUrl'] = this.thumbnailUrl
    return data
  }
}

export interface IDamAssetMetadata {
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  thumbnailUrl?: string | undefined
}

export class DamAssets implements IDamAssets {
  documents?: DamAssetMetadata[] | undefined
  images?: DamAssetMetadata[] | undefined
  videos?: DamAssetMetadata[] | undefined

  constructor(data?: IDamAssets) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['documents'])) {
        this.documents = [] as any
        for (let item of data['documents'])
          this.documents!.push(DamAssetMetadata.fromJS(item))
      }
      if (Array.isArray(data['images'])) {
        this.images = [] as any
        for (let item of data['images'])
          this.images!.push(DamAssetMetadata.fromJS(item))
      }
      if (Array.isArray(data['videos'])) {
        this.videos = [] as any
        for (let item of data['videos'])
          this.videos!.push(DamAssetMetadata.fromJS(item))
      }
    }
  }

  static fromJS(data: any): DamAssets {
    data = typeof data === 'object' ? data : {}
    let result = new DamAssets()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    if (Array.isArray(this.documents)) {
      data['documents'] = []
      for (let item of this.documents) data['documents'].push(item.toJSON())
    }
    if (Array.isArray(this.images)) {
      data['images'] = []
      for (let item of this.images) data['images'].push(item.toJSON())
    }
    if (Array.isArray(this.videos)) {
      data['videos'] = []
      for (let item of this.videos) data['videos'].push(item.toJSON())
    }
    return data
  }
}

export interface IDamAssets {
  documents?: DamAssetMetadata[] | undefined
  images?: DamAssetMetadata[] | undefined
  videos?: DamAssetMetadata[] | undefined
}

export class AthenaFileMetadata implements IAthenaFileMetadata {
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IAthenaFileMetadata) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name']
      this.url = data['url']
      this.sizeInBytes = data['sizeInBytes']
      this.contentType = data['contentType']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): AthenaFileMetadata {
    data = typeof data === 'object' ? data : {}
    let result = new AthenaFileMetadata()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['name'] = this.name
    data['url'] = this.url
    data['sizeInBytes'] = this.sizeInBytes
    data['contentType'] = this.contentType
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IAthenaFileMetadata {
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ApiCompany implements IApiCompany {
  primaryContact?: Contact | undefined
  parentCompany?: Company | undefined
  contacts?: Contact[] | undefined
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined
  id?: string | undefined
  name?: string | undefined
  acronyms?: string | undefined
  phone?: string | undefined
  otherPhone?: string | undefined
  website?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  primaryContactId?: string | undefined
  parentCompanyId?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined

  constructor(data?: IApiCompany) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.primaryContact = data['primaryContact']
        ? Contact.fromJS(data['primaryContact'])
        : <any>undefined
      this.parentCompany = data['parentCompany']
        ? Company.fromJS(data['parentCompany'])
        : <any>undefined
      if (Array.isArray(data['contacts'])) {
        this.contacts = [] as any
        for (let item of data['contacts'])
          this.contacts!.push(Contact.fromJS(item))
      }
      if (Array.isArray(data['businessRoles'])) {
        this.businessRoles = [] as any
        for (let item of data['businessRoles']) this.businessRoles!.push(item)
      }
      if (Array.isArray(data['businessLines'])) {
        this.businessLines = [] as any
        for (let item of data['businessLines']) this.businessLines!.push(item)
      }
      if (Array.isArray(data['districts'])) {
        this.districts = [] as any
        for (let item of data['districts']) this.districts!.push(item)
      }
      if (Array.isArray(data['verticalMarkets'])) {
        this.verticalMarkets = [] as any
        for (let item of data['verticalMarkets'])
          this.verticalMarkets!.push(item)
      }
      this.id = data['id']
      this.name = data['name']
      this.acronyms = data['acronyms']
      this.phone = data['phone']
      this.otherPhone = data['otherPhone']
      this.website = data['website']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.primaryContactId = data['primaryContactId']
      this.parentCompanyId = data['parentCompanyId']
      this.isActive = data['isActive']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmSyncCount = data['crmSyncCount']
      this.crmId = data['crmId']
    }
  }

  static fromJS(data: any): ApiCompany {
    data = typeof data === 'object' ? data : {}
    let result = new ApiCompany()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['primaryContact'] = this.primaryContact
      ? this.primaryContact.toJSON()
      : <any>undefined
    data['parentCompany'] = this.parentCompany
      ? this.parentCompany.toJSON()
      : <any>undefined
    if (Array.isArray(this.contacts)) {
      data['contacts'] = []
      for (let item of this.contacts) data['contacts'].push(item.toJSON())
    }
    if (Array.isArray(this.businessRoles)) {
      data['businessRoles'] = []
      for (let item of this.businessRoles) data['businessRoles'].push(item)
    }
    if (Array.isArray(this.businessLines)) {
      data['businessLines'] = []
      for (let item of this.businessLines) data['businessLines'].push(item)
    }
    if (Array.isArray(this.districts)) {
      data['districts'] = []
      for (let item of this.districts) data['districts'].push(item)
    }
    if (Array.isArray(this.verticalMarkets)) {
      data['verticalMarkets'] = []
      for (let item of this.verticalMarkets) data['verticalMarkets'].push(item)
    }
    data['id'] = this.id
    data['name'] = this.name
    data['acronyms'] = this.acronyms
    data['phone'] = this.phone
    data['otherPhone'] = this.otherPhone
    data['website'] = this.website
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['primaryContactId'] = this.primaryContactId
    data['parentCompanyId'] = this.parentCompanyId
    data['isActive'] = this.isActive
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmSyncCount'] = this.crmSyncCount
    data['crmId'] = this.crmId
    return data
  }
}

export interface IApiCompany {
  primaryContact?: Contact | undefined
  parentCompany?: Company | undefined
  contacts?: Contact[] | undefined
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined
  id?: string | undefined
  name?: string | undefined
  acronyms?: string | undefined
  phone?: string | undefined
  otherPhone?: string | undefined
  website?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  primaryContactId?: string | undefined
  parentCompanyId?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined
}

export class Contact implements IContact {
  id?: string | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  email?: string | undefined
  email2?: string | undefined
  businessPhone?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined

  constructor(data?: IContact) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.salutation = data['salutation']
      this.fullName = data['fullName']
      this.firstName = data['firstName']
      this.middleName = data['middleName']
      this.lastName = data['lastName']
      this.jobTitle = data['jobTitle']
      this.companyId = data['companyId']
      this.email = data['email']
      this.email2 = data['email2']
      this.businessPhone = data['businessPhone']
      this.mobilePhone = data['mobilePhone']
      this.homePhone = data['homePhone']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.isActive = data['isActive']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmSyncCount = data['crmSyncCount']
      this.crmId = data['crmId']
    }
  }

  static fromJS(data: any): Contact {
    data = typeof data === 'object' ? data : {}
    let result = new Contact()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['salutation'] = this.salutation
    data['fullName'] = this.fullName
    data['firstName'] = this.firstName
    data['middleName'] = this.middleName
    data['lastName'] = this.lastName
    data['jobTitle'] = this.jobTitle
    data['companyId'] = this.companyId
    data['email'] = this.email
    data['email2'] = this.email2
    data['businessPhone'] = this.businessPhone
    data['mobilePhone'] = this.mobilePhone
    data['homePhone'] = this.homePhone
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['isActive'] = this.isActive
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmSyncCount'] = this.crmSyncCount
    data['crmId'] = this.crmId
    return data
  }
}

export interface IContact {
  id?: string | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  email?: string | undefined
  email2?: string | undefined
  businessPhone?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined
}

export class Company implements ICompany {
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined
  id?: string | undefined
  name?: string | undefined
  acronyms?: string | undefined
  phone?: string | undefined
  otherPhone?: string | undefined
  website?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  primaryContactId?: string | undefined
  parentCompanyId?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined

  constructor(data?: ICompany) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['businessRoles'])) {
        this.businessRoles = [] as any
        for (let item of data['businessRoles']) this.businessRoles!.push(item)
      }
      if (Array.isArray(data['businessLines'])) {
        this.businessLines = [] as any
        for (let item of data['businessLines']) this.businessLines!.push(item)
      }
      if (Array.isArray(data['districts'])) {
        this.districts = [] as any
        for (let item of data['districts']) this.districts!.push(item)
      }
      if (Array.isArray(data['verticalMarkets'])) {
        this.verticalMarkets = [] as any
        for (let item of data['verticalMarkets'])
          this.verticalMarkets!.push(item)
      }
      this.id = data['id']
      this.name = data['name']
      this.acronyms = data['acronyms']
      this.phone = data['phone']
      this.otherPhone = data['otherPhone']
      this.website = data['website']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.primaryContactId = data['primaryContactId']
      this.parentCompanyId = data['parentCompanyId']
      this.isActive = data['isActive']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmSyncCount = data['crmSyncCount']
      this.crmId = data['crmId']
    }
  }

  static fromJS(data: any): Company {
    data = typeof data === 'object' ? data : {}
    let result = new Company()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    if (Array.isArray(this.businessRoles)) {
      data['businessRoles'] = []
      for (let item of this.businessRoles) data['businessRoles'].push(item)
    }
    if (Array.isArray(this.businessLines)) {
      data['businessLines'] = []
      for (let item of this.businessLines) data['businessLines'].push(item)
    }
    if (Array.isArray(this.districts)) {
      data['districts'] = []
      for (let item of this.districts) data['districts'].push(item)
    }
    if (Array.isArray(this.verticalMarkets)) {
      data['verticalMarkets'] = []
      for (let item of this.verticalMarkets) data['verticalMarkets'].push(item)
    }
    data['id'] = this.id
    data['name'] = this.name
    data['acronyms'] = this.acronyms
    data['phone'] = this.phone
    data['otherPhone'] = this.otherPhone
    data['website'] = this.website
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['primaryContactId'] = this.primaryContactId
    data['parentCompanyId'] = this.parentCompanyId
    data['isActive'] = this.isActive
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmSyncCount'] = this.crmSyncCount
    data['crmId'] = this.crmId
    return data
  }
}

export interface ICompany {
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined
  id?: string | undefined
  name?: string | undefined
  acronyms?: string | undefined
  phone?: string | undefined
  otherPhone?: string | undefined
  website?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  primaryContactId?: string | undefined
  parentCompanyId?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined
}

export class SearchResultSetOfQueryableCompany
  implements ISearchResultSetOfQueryableCompany {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryableCompany[] | undefined
  nextLink?: string | undefined

  constructor(data?: ISearchResultSetOfQueryableCompany) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.count = data['count']
      if (data['facets']) {
        this.facets = {} as any
        for (let key in data['facets']) {
          if (data['facets'].hasOwnProperty(key))
            this.facets![key] = data['facets'][key]
              ? data['facets'][key].map((i: any) => AthenaFacet.fromJS(i))
              : []
        }
      }
      if (Array.isArray(data['value'])) {
        this.value = [] as any
        for (let item of data['value'])
          this.value!.push(QueryableCompany.fromJS(item))
      }
      this.nextLink = data['nextLink']
    }
  }

  static fromJS(data: any): SearchResultSetOfQueryableCompany {
    data = typeof data === 'object' ? data : {}
    let result = new SearchResultSetOfQueryableCompany()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['count'] = this.count
    if (this.facets) {
      data['facets'] = {}
      for (let key in this.facets) {
        if (this.facets.hasOwnProperty(key))
          data['facets'][key] = this.facets[key]
      }
    }
    if (Array.isArray(this.value)) {
      data['value'] = []
      for (let item of this.value) data['value'].push(item.toJSON())
    }
    data['nextLink'] = this.nextLink
    return data
  }
}

export interface ISearchResultSetOfQueryableCompany {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryableCompany[] | undefined
  nextLink?: string | undefined
}

export class AthenaFacet implements IAthenaFacet {
  // TODO: why is value? an Object and not a string - mistake in API?
  //value?: Object | undefined;
  value?: string | undefined
  count?: number | undefined

  constructor(data?: IAthenaFacet) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      //this.value = data["value"] ? Object.fromJS(data["value"]) : <any>undefined;
      this.value = data['value']
      this.count = data['count']
    }
  }

  static fromJS(data: any): AthenaFacet {
    data = typeof data === 'object' ? data : {}
    let result = new AthenaFacet()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    //data["value"] = this.value ? this.value.toJSON() : <any>undefined;
    data['value'] = this.value
    data['count'] = this.count
    return data
  }
}

export interface IAthenaFacet {
  value?: string | undefined
  count?: number | undefined
}

export class QueryableCompany implements IQueryableCompany {
  id?: string | undefined
  name?: string | undefined
  acronyms?: string | undefined
  phone?: string | undefined
  otherPhone?: string | undefined
  website?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined
  parentCompanyId?: string | undefined
  parentCompanyName?: string | undefined
  primaryContactId?: string | undefined
  primaryContactName?: string | undefined
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined

  // bc not provided by server
  get address(): string {
    const { streetAddress, streetAddress2, city, state, zip } = this
    const addressParts = [streetAddress, streetAddress2, city, state]
    const filteredParts = addressParts.filter(
      (part) => !!part && part.trim() !== '',
    )
    const tmpAddress = filteredParts.join(', ')
    const zipWithSpace = zip ? ` ${zip}` : ''
    return `${tmpAddress}${zipWithSpace}`
  }

  constructor(data?: IQueryableCompany) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.acronyms = data['acronyms']
      this.phone = data['phone']
      this.otherPhone = data['otherPhone']
      this.website = data['website']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.isActive = data['isActive']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmSyncCount = data['crmSyncCount']
      this.crmId = data['crmId']
      this.parentCompanyId = data['parentCompanyId']
      this.parentCompanyName = data['parentCompanyName']
      this.primaryContactId = data['primaryContactId']
      this.primaryContactName = data['primaryContactName']
      if (Array.isArray(data['businessRoles'])) {
        this.businessRoles = [] as any
        for (let item of data['businessRoles']) this.businessRoles!.push(item)
      }
      if (Array.isArray(data['businessLines'])) {
        this.businessLines = [] as any
        for (let item of data['businessLines']) this.businessLines!.push(item)
      }
      if (Array.isArray(data['districts'])) {
        this.districts = [] as any
        for (let item of data['districts']) this.districts!.push(item)
      }
      if (Array.isArray(data['verticalMarkets'])) {
        this.verticalMarkets = [] as any
        for (let item of data['verticalMarkets'])
          this.verticalMarkets!.push(item)
      }
    }
  }

  static fromJS(data: any): QueryableCompany {
    data = typeof data === 'object' ? data : {}
    let result = new QueryableCompany()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['acronyms'] = this.acronyms
    data['phone'] = this.phone
    data['otherPhone'] = this.otherPhone
    data['website'] = this.website
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['isActive'] = this.isActive
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmSyncCount'] = this.crmSyncCount
    data['crmId'] = this.crmId
    data['parentCompanyId'] = this.parentCompanyId
    data['parentCompanyName'] = this.parentCompanyName
    data['primaryContactId'] = this.primaryContactId
    data['primaryContactName'] = this.primaryContactName
    if (Array.isArray(this.businessRoles)) {
      data['businessRoles'] = []
      for (let item of this.businessRoles) data['businessRoles'].push(item)
    }
    if (Array.isArray(this.businessLines)) {
      data['businessLines'] = []
      for (let item of this.businessLines) data['businessLines'].push(item)
    }
    if (Array.isArray(this.districts)) {
      data['districts'] = []
      for (let item of this.districts) data['districts'].push(item)
    }
    if (Array.isArray(this.verticalMarkets)) {
      data['verticalMarkets'] = []
      for (let item of this.verticalMarkets) data['verticalMarkets'].push(item)
    }
    return data
  }
}

export interface IQueryableCompany {
  id?: string | undefined
  name?: string | undefined
  acronyms?: string | undefined
  phone?: string | undefined
  otherPhone?: string | undefined
  website?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined
  parentCompanyId?: string | undefined
  parentCompanyName?: string | undefined
  primaryContactId?: string | undefined
  primaryContactName?: string | undefined
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined
}

export class ApiContact implements IApiContact {
  company?: Company | undefined
  id?: string | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  email?: string | undefined
  email2?: string | undefined
  businessPhone?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined

  constructor(data?: IApiContact) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.company = data['company']
        ? Company.fromJS(data['company'])
        : <any>undefined
      this.id = data['id']
      this.salutation = data['salutation']
      this.fullName = data['fullName']
      this.firstName = data['firstName']
      this.middleName = data['middleName']
      this.lastName = data['lastName']
      this.jobTitle = data['jobTitle']
      this.companyId = data['companyId']
      this.email = data['email']
      this.email2 = data['email2']
      this.businessPhone = data['businessPhone']
      this.mobilePhone = data['mobilePhone']
      this.homePhone = data['homePhone']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.isActive = data['isActive']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmSyncCount = data['crmSyncCount']
      this.crmId = data['crmId']
    }
  }

  static fromJS(data: any): ApiContact {
    data = typeof data === 'object' ? data : {}
    let result = new ApiContact()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['company'] = this.company ? this.company.toJSON() : <any>undefined
    data['id'] = this.id
    data['salutation'] = this.salutation
    data['fullName'] = this.fullName
    data['firstName'] = this.firstName
    data['middleName'] = this.middleName
    data['lastName'] = this.lastName
    data['jobTitle'] = this.jobTitle
    data['companyId'] = this.companyId
    data['email'] = this.email
    data['email2'] = this.email2
    data['businessPhone'] = this.businessPhone
    data['mobilePhone'] = this.mobilePhone
    data['homePhone'] = this.homePhone
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['isActive'] = this.isActive
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmSyncCount'] = this.crmSyncCount
    data['crmId'] = this.crmId
    return data
  }
}

export interface IApiContact {
  company?: Company | undefined
  id?: string | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  email?: string | undefined
  email2?: string | undefined
  businessPhone?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined
}

export class SearchResultSetOfQueryableContact
  implements ISearchResultSetOfQueryableContact {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryableContact[] | undefined
  nextLink?: string | undefined

  constructor(data?: ISearchResultSetOfQueryableContact) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.count = data['count']
      if (data['facets']) {
        this.facets = {} as any
        for (let key in data['facets']) {
          if (data['facets'].hasOwnProperty(key))
            this.facets![key] = data['facets'][key]
              ? data['facets'][key].map((i: any) => AthenaFacet.fromJS(i))
              : []
        }
      }
      if (Array.isArray(data['value'])) {
        this.value = [] as any
        for (let item of data['value'])
          this.value!.push(QueryableContact.fromJS(item))
      }
      this.nextLink = data['nextLink']
    }
  }

  static fromJS(data: any): SearchResultSetOfQueryableContact {
    data = typeof data === 'object' ? data : {}
    let result = new SearchResultSetOfQueryableContact()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['count'] = this.count
    if (this.facets) {
      data['facets'] = {}
      for (let key in this.facets) {
        if (this.facets.hasOwnProperty(key))
          data['facets'][key] = this.facets[key]
      }
    }
    if (Array.isArray(this.value)) {
      data['value'] = []
      for (let item of this.value) data['value'].push(item.toJSON())
    }
    data['nextLink'] = this.nextLink
    return data
  }
}

export interface ISearchResultSetOfQueryableContact {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryableContact[] | undefined
  nextLink?: string | undefined
}

export class QueryableContact implements IQueryableContact {
  id?: string | undefined
  fullName?: string | undefined
  salutation?: string | undefined
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  companyName?: string | undefined
  email?: string | undefined
  email2?: string | undefined
  businessPhone?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined

  constructor(data?: IQueryableContact) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.fullName = data['fullName']
      this.salutation = data['salutation']
      this.firstName = data['firstName']
      this.middleName = data['middleName']
      this.lastName = data['lastName']
      this.jobTitle = data['jobTitle']
      this.companyId = data['companyId']
      this.companyName = data['companyName']
      this.email = data['email']
      this.email2 = data['email2']
      this.businessPhone = data['businessPhone']
      this.mobilePhone = data['mobilePhone']
      this.homePhone = data['homePhone']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.isActive = data['isActive']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmSyncCount = data['crmSyncCount']
      this.crmId = data['crmId']
    }
  }

  static fromJS(data: any): QueryableContact {
    data = typeof data === 'object' ? data : {}
    let result = new QueryableContact()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['fullName'] = this.fullName
    data['salutation'] = this.salutation
    data['firstName'] = this.firstName
    data['middleName'] = this.middleName
    data['lastName'] = this.lastName
    data['jobTitle'] = this.jobTitle
    data['companyId'] = this.companyId
    data['companyName'] = this.companyName
    data['email'] = this.email
    data['email2'] = this.email2
    data['businessPhone'] = this.businessPhone
    data['mobilePhone'] = this.mobilePhone
    data['homePhone'] = this.homePhone
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['isActive'] = this.isActive
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmSyncCount'] = this.crmSyncCount
    data['crmId'] = this.crmId
    return data
  }
}

export interface IQueryableContact {
  id?: string | undefined
  fullName?: string | undefined
  salutation?: string | undefined
  firstName?: string | undefined
  middleName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  companyName?: string | undefined
  email?: string | undefined
  email2?: string | undefined
  businessPhone?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  isActive?: boolean | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmSyncCount?: number | undefined
  crmId?: string | undefined
}

export class Degree implements IDegree {
  degree?: string | undefined
  major?: string | undefined
  school?: string | undefined
  year?: number | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IDegree) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.degree = data['degree']
      this.major = data['major']
      this.school = data['school']
      this.year = data['year']
      this.id = data['id']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): Degree {
    data = typeof data === 'object' ? data : {}
    let result = new Degree()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['degree'] = this.degree
    data['major'] = this.major
    data['school'] = this.school
    data['year'] = this.year
    data['id'] = this.id
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IDegree {
  degree?: string | undefined
  major?: string | undefined
  school?: string | undefined
  year?: number | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ExportRequestBody implements IExportRequestBody {
  records?: string | undefined
  fileType?: string | undefined
  schema?: ExportSchema | undefined

  constructor(data?: IExportRequestBody) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.records = data['records']
      this.fileType = data['fileType']
      this.schema = data['schema']
        ? ExportSchema.fromJS(data['schema'])
        : <any>undefined
    }
  }

  static fromJS(data: any): ExportRequestBody {
    data = typeof data === 'object' ? data : {}
    let result = new ExportRequestBody()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['records'] = this.records
    data['fileType'] = this.fileType
    data['schema'] = this.schema ? this.schema.toJSON() : <any>undefined
    return data
  }
}

export interface IExportRequestBody {
  records?: string | undefined
  fileType?: string | undefined
  schema?: ExportSchema | undefined
}

export class ExportSchema implements IExportSchema {
  label?: string | undefined
  type?: string | undefined
  propertyName?: string | undefined
  referenceProperty?: string | undefined
  referencePropertyLabel?: string | undefined
  items?: ExportSchema | undefined
  properties?: ExportSchema[] | undefined

  constructor(data?: IExportSchema) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.label = data['label']
      this.type = data['type']
      this.propertyName = data['propertyName']
      this.referenceProperty = data['referenceProperty']
      this.referencePropertyLabel = data['referencePropertyLabel']
      this.items = data['items']
        ? ExportSchema.fromJS(data['items'])
        : <any>undefined
      if (Array.isArray(data['properties'])) {
        this.properties = [] as any
        for (let item of data['properties'])
          this.properties!.push(ExportSchema.fromJS(item))
      }
    }
  }

  static fromJS(data: any): ExportSchema {
    data = typeof data === 'object' ? data : {}
    let result = new ExportSchema()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['label'] = this.label
    data['type'] = this.type
    data['propertyName'] = this.propertyName
    data['referenceProperty'] = this.referenceProperty
    data['referencePropertyLabel'] = this.referencePropertyLabel
    data['items'] = this.items ? this.items.toJSON() : <any>undefined
    if (Array.isArray(this.properties)) {
      data['properties'] = []
      for (let item of this.properties) data['properties'].push(item.toJSON())
    }
    return data
  }
}

export interface IExportSchema {
  label?: string | undefined
  type?: string | undefined
  propertyName?: string | undefined
  referenceProperty?: string | undefined
  referencePropertyLabel?: string | undefined
  items?: ExportSchema | undefined
  properties?: ExportSchema[] | undefined
}

export class Export implements IExport {
  exportId?: string | undefined
  records?: string | undefined
  fileType?: string | undefined
  totalCount?: number | undefined
  currentCount?: number | undefined
  status?: string | undefined
  fileSize?: number | undefined
  timeStarted?: Date | undefined
  timeFinished?: Date | undefined
  location?: string | undefined
  errorMessage?: string | undefined

  constructor(data?: IExport) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.exportId = data['exportId']
      this.records = data['records']
      this.fileType = data['fileType']
      this.totalCount = data['totalCount']
      this.currentCount = data['currentCount']
      this.status = data['status']
      this.fileSize = data['fileSize']
      this.timeStarted = data['timeStarted']
        ? new Date(data['timeStarted'].toString())
        : <any>undefined
      this.timeFinished = data['timeFinished']
        ? new Date(data['timeFinished'].toString())
        : <any>undefined
      this.location = data['location']
      this.errorMessage = data['errorMessage']
    }
  }

  static fromJS(data: any): Export {
    data = typeof data === 'object' ? data : {}
    let result = new Export()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['exportId'] = this.exportId
    data['records'] = this.records
    data['fileType'] = this.fileType
    data['totalCount'] = this.totalCount
    data['currentCount'] = this.currentCount
    data['status'] = this.status
    data['fileSize'] = this.fileSize
    data['timeStarted'] = this.timeStarted
      ? this.timeStarted.toISOString()
      : <any>undefined
    data['timeFinished'] = this.timeFinished
      ? this.timeFinished.toISOString()
      : <any>undefined
    data['location'] = this.location
    data['errorMessage'] = this.errorMessage
    return data
  }
}

export interface IExport {
  exportId?: string | undefined
  records?: string | undefined
  fileType?: string | undefined
  totalCount?: number | undefined
  currentCount?: number | undefined
  status?: string | undefined
  fileSize?: number | undefined
  timeStarted?: Date | undefined
  timeFinished?: Date | undefined
  location?: string | undefined
  errorMessage?: string | undefined
}

export class ApiPerson implements IApiPerson {
  accreditation?: string[] | undefined
  degrees?: Degree[] | undefined
  awards?: PersonAward[] | undefined
  trainings?: Training[] | undefined
  references?: ApiPersonReference[] | undefined
  verticalMarkets?: string[] | undefined
  clientTypes?: string[] | undefined
  contractTypes?: string[] | undefined
  deliveryMethods?: string[] | undefined
  procurementMethods?: string[] | undefined
  secondaryCategories?: string[] | undefined
  constructionTypes?: string[] | undefined
  stakeholders?: string[] | undefined
  erpModifiedOn?: Date | undefined
  erpModifiedBy?: string | undefined
  projectHistory?: ApiProjectHistory[] | undefined
  attachments?: AthenaFileMetadata[] | undefined
  assets?: Assets | undefined
  thumbnail?: string | undefined
  recordAndRelatedModifiedOn?: Date | undefined
  recordAndRelatedModifiedBy?: string | undefined
  webdamFolderId?: number | undefined
  id?: number | undefined
  guid?: string | undefined
  name?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  middleInitial?: string | undefined
  preferredFirstName?: string | undefined
  isSalary?: boolean | undefined
  title?: string | undefined
  district?: string | undefined
  status?: string | undefined
  hireDate?: Date | undefined
  terminationDate?: Date | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  industryStartYear?: number | undefined
  userName?: string | undefined
  totalProjectValue?: number | undefined
  totalSquareFootage?: number | undefined
  gender?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  primaryImageUrl?: string | undefined
  notes?: string | undefined
  kudosNotes?: string | undefined
  signedRelease?: boolean | undefined

  constructor(data?: IApiPerson) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['accreditation'])) {
        this.accreditation = [] as any
        for (let item of data['accreditation']) this.accreditation!.push(item)
      }
      if (Array.isArray(data['degrees'])) {
        this.degrees = [] as any
        for (let item of data['degrees'])
          this.degrees!.push(Degree.fromJS(item))
      }
      if (Array.isArray(data['awards'])) {
        this.awards = [] as any
        for (let item of data['awards'])
          this.awards!.push(PersonAward.fromJS(item))
      }
      if (Array.isArray(data['trainings'])) {
        this.trainings = [] as any
        for (let item of data['trainings'])
          this.trainings!.push(Training.fromJS(item))
      }
      if (Array.isArray(data['references'])) {
        this.references = [] as any
        for (let item of data['references'])
          this.references!.push(ApiPersonReference.fromJS(item))
      }
      if (Array.isArray(data['verticalMarkets'])) {
        this.verticalMarkets = [] as any
        for (let item of data['verticalMarkets'])
          this.verticalMarkets!.push(item)
      }
      if (Array.isArray(data['clientTypes'])) {
        this.clientTypes = [] as any
        for (let item of data['clientTypes']) this.clientTypes!.push(item)
      }
      if (Array.isArray(data['contractTypes'])) {
        this.contractTypes = [] as any
        for (let item of data['contractTypes']) this.contractTypes!.push(item)
      }
      if (Array.isArray(data['deliveryMethods'])) {
        this.deliveryMethods = [] as any
        for (let item of data['deliveryMethods'])
          this.deliveryMethods!.push(item)
      }
      if (Array.isArray(data['procurementMethods'])) {
        this.procurementMethods = [] as any
        for (let item of data['procurementMethods'])
          this.procurementMethods!.push(item)
      }
      if (Array.isArray(data['secondaryCategories'])) {
        this.secondaryCategories = [] as any
        for (let item of data['secondaryCategories'])
          this.secondaryCategories!.push(item)
      }
      if (Array.isArray(data['constructionTypes'])) {
        this.constructionTypes = [] as any
        for (let item of data['constructionTypes'])
          this.constructionTypes!.push(item)
      }
      if (Array.isArray(data['stakeholders'])) {
        this.stakeholders = [] as any
        for (let item of data['stakeholders']) this.stakeholders!.push(item)
      }
      this.erpModifiedOn = data['erpModifiedOn']
        ? new Date(data['erpModifiedOn'].toString())
        : <any>undefined
      this.erpModifiedBy = data['erpModifiedBy']
      if (Array.isArray(data['projectHistory'])) {
        this.projectHistory = [] as any
        for (let item of data['projectHistory'])
          this.projectHistory!.push(ApiProjectHistory.fromJS(item))
      }
      if (Array.isArray(data['attachments'])) {
        this.attachments = [] as any
        for (let item of data['attachments'])
          this.attachments!.push(AthenaFileMetadata.fromJS(item))
      }
      this.assets = data['assets']
        ? Assets.fromJS(data['assets'])
        : <any>undefined
      this.thumbnail = data['thumbnail']
      this.recordAndRelatedModifiedOn = data['recordAndRelatedModifiedOn']
        ? new Date(data['recordAndRelatedModifiedOn'].toString())
        : <any>undefined
      this.recordAndRelatedModifiedBy = data['recordAndRelatedModifiedBy']
      this.webdamFolderId = data['webdamFolderId']
      this.id = data['id']
      this.guid = data['guid']
      this.name = data['name']
      this.firstName = data['firstName']
      this.lastName = data['lastName']
      this.middleInitial = data['middleInitial']
      this.preferredFirstName = data['preferredFirstName']
      this.isSalary = data['isSalary']
      this.title = data['title']
      this.district = data['district']
      this.status = data['status']
      this.hireDate = data['hireDate']
        ? new Date(data['hireDate'].toString())
        : <any>undefined
      this.terminationDate = data['terminationDate']
        ? new Date(data['terminationDate'].toString())
        : <any>undefined
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.industryStartYear = data['industryStartYear']
      this.userName = data['userName']
      this.totalProjectValue = data['totalProjectValue']
      this.totalSquareFootage = data['totalSquareFootage']
      this.gender = data['gender']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.primaryImageUrl = data['primaryImageUrl']
      this.notes = data['notes']
      this.kudosNotes = data['kudosNotes']
      this.signedRelease = data['signedRelease']
    }
  }

  static fromJS(data: any): ApiPerson {
    data = typeof data === 'object' ? data : {}
    let result = new ApiPerson()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    if (Array.isArray(this.accreditation)) {
      data['accreditation'] = []
      for (let item of this.accreditation) data['accreditation'].push(item)
    }
    if (Array.isArray(this.degrees)) {
      data['degrees'] = []
      for (let item of this.degrees) data['degrees'].push(item.toJSON())
    }
    if (Array.isArray(this.awards)) {
      data['awards'] = []
      for (let item of this.awards) data['awards'].push(item.toJSON())
    }
    if (Array.isArray(this.trainings)) {
      data['trainings'] = []
      for (let item of this.trainings) data['trainings'].push(item.toJSON())
    }
    if (Array.isArray(this.references)) {
      data['references'] = []
      for (let item of this.references) data['references'].push(item.toJSON())
    }
    if (Array.isArray(this.verticalMarkets)) {
      data['verticalMarkets'] = []
      for (let item of this.verticalMarkets) data['verticalMarkets'].push(item)
    }
    if (Array.isArray(this.clientTypes)) {
      data['clientTypes'] = []
      for (let item of this.clientTypes) data['clientTypes'].push(item)
    }
    if (Array.isArray(this.contractTypes)) {
      data['contractTypes'] = []
      for (let item of this.contractTypes) data['contractTypes'].push(item)
    }
    if (Array.isArray(this.deliveryMethods)) {
      data['deliveryMethods'] = []
      for (let item of this.deliveryMethods) data['deliveryMethods'].push(item)
    }
    if (Array.isArray(this.procurementMethods)) {
      data['procurementMethods'] = []
      for (let item of this.procurementMethods)
        data['procurementMethods'].push(item)
    }
    if (Array.isArray(this.secondaryCategories)) {
      data['secondaryCategories'] = []
      for (let item of this.secondaryCategories)
        data['secondaryCategories'].push(item)
    }
    if (Array.isArray(this.constructionTypes)) {
      data['constructionTypes'] = []
      for (let item of this.constructionTypes)
        data['constructionTypes'].push(item)
    }
    if (Array.isArray(this.stakeholders)) {
      data['stakeholders'] = []
      for (let item of this.stakeholders) data['stakeholders'].push(item)
    }
    data['erpModifiedOn'] = this.erpModifiedOn
      ? this.erpModifiedOn.toISOString()
      : <any>undefined
    data['erpModifiedBy'] = this.erpModifiedBy
    if (Array.isArray(this.projectHistory)) {
      data['projectHistory'] = []
      for (let item of this.projectHistory)
        data['projectHistory'].push(item.toJSON())
    }
    if (Array.isArray(this.attachments)) {
      data['attachments'] = []
      for (let item of this.attachments) data['attachments'].push(item.toJSON())
    }
    data['assets'] = this.assets ? this.assets.toJSON() : <any>undefined
    data['thumbnail'] = this.thumbnail
    data['recordAndRelatedModifiedOn'] = this.recordAndRelatedModifiedOn
      ? this.recordAndRelatedModifiedOn.toISOString()
      : <any>undefined
    data['recordAndRelatedModifiedBy'] = this.recordAndRelatedModifiedBy
    data['webdamFolderId'] = this.webdamFolderId
    data['id'] = this.id
    data['guid'] = this.guid
    data['name'] = this.name
    data['firstName'] = this.firstName
    data['lastName'] = this.lastName
    data['middleInitial'] = this.middleInitial
    data['preferredFirstName'] = this.preferredFirstName
    data['isSalary'] = this.isSalary
    data['title'] = this.title
    data['district'] = this.district
    data['status'] = this.status
    data['hireDate'] = this.hireDate
      ? this.hireDate.toISOString()
      : <any>undefined
    data['terminationDate'] = this.terminationDate
      ? this.terminationDate.toISOString()
      : <any>undefined
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['industryStartYear'] = this.industryStartYear
    data['userName'] = this.userName
    data['totalProjectValue'] = this.totalProjectValue
    data['totalSquareFootage'] = this.totalSquareFootage
    data['gender'] = this.gender
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['primaryImageUrl'] = this.primaryImageUrl
    data['notes'] = this.notes
    data['kudosNotes'] = this.kudosNotes
    data['signedRelease'] = this.signedRelease
    return data
  }
}

export interface IApiPerson {
  accreditation?: string[] | undefined
  degrees?: Degree[] | undefined
  awards?: PersonAward[] | undefined
  trainings?: Training[] | undefined
  references?: ApiPersonReference[] | undefined
  verticalMarkets?: string[] | undefined
  clientTypes?: string[] | undefined
  contractTypes?: string[] | undefined
  deliveryMethods?: string[] | undefined
  procurementMethods?: string[] | undefined
  secondaryCategories?: string[] | undefined
  constructionTypes?: string[] | undefined
  stakeholders?: string[] | undefined
  erpModifiedOn?: Date | undefined
  erpModifiedBy?: string | undefined
  projectHistory?: ApiProjectHistory[] | undefined
  attachments?: AthenaFileMetadata[] | undefined
  assets?: Assets | undefined
  thumbnail?: string | undefined
  recordAndRelatedModifiedOn?: Date | undefined
  recordAndRelatedModifiedBy?: string | undefined
  webdamFolderId?: number | undefined
  id?: number | undefined
  guid?: string | undefined
  name?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  middleInitial?: string | undefined
  preferredFirstName?: string | undefined
  isSalary?: boolean | undefined
  title?: string | undefined
  district?: string | undefined
  status?: string | undefined
  hireDate?: Date | undefined
  terminationDate?: Date | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  industryStartYear?: number | undefined
  userName?: string | undefined
  totalProjectValue?: number | undefined
  totalSquareFootage?: number | undefined
  gender?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  primaryImageUrl?: string | undefined
  notes?: string | undefined
  kudosNotes?: string | undefined
  signedRelease?: boolean | undefined
}

export class PersonAward implements IPersonAward {
  title?: string | undefined
  citation?: string | undefined
  date?: Date | undefined
  sponsor?: string | undefined
  subtitle?: string | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IPersonAward) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.title = data['title']
      this.citation = data['citation']
      this.date = data['date']
        ? new Date(data['date'].toString())
        : <any>undefined
      this.sponsor = data['sponsor']
      this.subtitle = data['subtitle']
      this.id = data['id']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): PersonAward {
    data = typeof data === 'object' ? data : {}
    let result = new PersonAward()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['title'] = this.title
    data['citation'] = this.citation
    data['date'] = this.date ? this.date.toISOString() : <any>undefined
    data['sponsor'] = this.sponsor
    data['subtitle'] = this.subtitle
    data['id'] = this.id
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IPersonAward {
  title?: string | undefined
  citation?: string | undefined
  date?: Date | undefined
  sponsor?: string | undefined
  subtitle?: string | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class Training implements ITraining {
  course?: string | undefined
  goals?: string | undefined
  sponsor?: string | undefined
  startDate?: Date | undefined
  endDate?: Date | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: ITraining) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.course = data['course']
      this.goals = data['goals']
      this.sponsor = data['sponsor']
      this.startDate = data['startDate']
        ? new Date(data['startDate'].toString())
        : <any>undefined
      this.endDate = data['endDate']
        ? new Date(data['endDate'].toString())
        : <any>undefined
      this.id = data['id']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): Training {
    data = typeof data === 'object' ? data : {}
    let result = new Training()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['course'] = this.course
    data['goals'] = this.goals
    data['sponsor'] = this.sponsor
    data['startDate'] = this.startDate
      ? this.startDate.toISOString()
      : <any>undefined
    data['endDate'] = this.endDate ? this.endDate.toISOString() : <any>undefined
    data['id'] = this.id
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface ITraining {
  course?: string | undefined
  goals?: string | undefined
  sponsor?: string | undefined
  startDate?: Date | undefined
  endDate?: Date | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ApiPersonReference implements IApiPersonReference {
  contactId?: string | undefined
  personGuid?: string | undefined
  personId?: number | undefined
  isManagedByCrm?: boolean | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  middleName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  email2?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmId?: string | undefined
  isActive?: boolean | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  company?: string | undefined
  referenceType?: string | undefined
  email?: string | undefined
  businessPhone?: string | undefined
  quoteCommendation?: string | undefined

  constructor(data?: IApiPersonReference) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.contactId = data['contactId']
      this.personGuid = data['personGuid']
      this.personId = data['personId']
      this.isManagedByCrm = data['isManagedByCrm']
      this.comments = data['comments']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.salutation = data['salutation']
      this.fullName = data['fullName']
      this.middleName = data['middleName']
      this.jobTitle = data['jobTitle']
      this.companyId = data['companyId']
      this.email2 = data['email2']
      this.mobilePhone = data['mobilePhone']
      this.homePhone = data['homePhone']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmId = data['crmId']
      this.isActive = data['isActive']
      this.firstName = data['firstName']
      this.lastName = data['lastName']
      this.company = data['company']
      this.referenceType = data['referenceType']
      this.email = data['email']
      this.businessPhone = data['businessPhone']
      this.quoteCommendation = data['quoteCommendation']
    }
  }

  static fromJS(data: any): ApiPersonReference {
    data = typeof data === 'object' ? data : {}
    let result = new ApiPersonReference()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['contactId'] = this.contactId
    data['personGuid'] = this.personGuid
    data['personId'] = this.personId
    data['isManagedByCrm'] = this.isManagedByCrm
    data['comments'] = this.comments
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['salutation'] = this.salutation
    data['fullName'] = this.fullName
    data['middleName'] = this.middleName
    data['jobTitle'] = this.jobTitle
    data['companyId'] = this.companyId
    data['email2'] = this.email2
    data['mobilePhone'] = this.mobilePhone
    data['homePhone'] = this.homePhone
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmId'] = this.crmId
    data['isActive'] = this.isActive
    data['firstName'] = this.firstName
    data['lastName'] = this.lastName
    data['company'] = this.company
    data['referenceType'] = this.referenceType
    data['email'] = this.email
    data['businessPhone'] = this.businessPhone
    data['quoteCommendation'] = this.quoteCommendation
    return data
  }
}

export interface IApiPersonReference {
  contactId?: string | undefined
  personGuid?: string | undefined
  personId?: number | undefined
  isManagedByCrm?: boolean | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  middleName?: string | undefined
  jobTitle?: string | undefined
  companyId?: string | undefined
  email2?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmId?: string | undefined
  isActive?: boolean | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  company?: string | undefined
  referenceType?: string | undefined
  email?: string | undefined
  businessPhone?: string | undefined
  quoteCommendation?: string | undefined
}

export class ApiProjectHistory implements IApiProjectHistory {
  projectId?: string | undefined
  personTitleOnProject?: string | undefined
  name?: string | undefined
  number?: string | undefined
  clientName?: string | undefined
  cost?: number | undefined
  city?: string | undefined
  state?: string | undefined
  district?: string | undefined
  deliveryMethod?: string | undefined
  squareFootage?: number | undefined
  verticalMarket?: string | undefined
  completionDate?: Date | undefined
  thumbnail?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  projectResumeDescription?: string | undefined
  projectStandardDescription?: string | undefined
  nonHenselPhelpsProject?: boolean | undefined

  constructor(data?: IApiProjectHistory) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.projectId = data['projectId']
      this.personTitleOnProject = data['personTitleOnProject']
      this.name = data['name']
      this.number = data['number']
      this.clientName = data['clientName']
      this.cost = data['cost']
      this.city = data['city']
      this.state = data['state']
      this.district = data['district']
      this.deliveryMethod = data['deliveryMethod']
      this.squareFootage = data['squareFootage']
      this.verticalMarket = data['verticalMarket']
      this.completionDate = data['completionDate']
        ? new Date(data['completionDate'].toString())
        : <any>undefined
      this.thumbnail = data['thumbnail']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.projectResumeDescription = data['projectResumeDescription']
      this.projectStandardDescription = data['projectStandardDescription']
      this.nonHenselPhelpsProject = data['nonHenselPhelpsProject']
    }
  }

  static fromJS(data: any): ApiProjectHistory {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProjectHistory()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['projectId'] = this.projectId
    data['personTitleOnProject'] = this.personTitleOnProject
    data['name'] = this.name
    data['number'] = this.number
    data['clientName'] = this.clientName
    data['cost'] = this.cost
    data['city'] = this.city
    data['state'] = this.state
    data['district'] = this.district
    data['deliveryMethod'] = this.deliveryMethod
    data['squareFootage'] = this.squareFootage
    data['verticalMarket'] = this.verticalMarket
    data['completionDate'] = this.completionDate
      ? this.completionDate.toISOString()
      : <any>undefined
    data['thumbnail'] = this.thumbnail
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['projectResumeDescription'] = this.projectResumeDescription
    data['projectStandardDescription'] = this.projectStandardDescription
    data['nonHenselPhelpsProject'] = this.nonHenselPhelpsProject
    return data
  }
}

export interface IApiProjectHistory {
  projectId?: string | undefined
  personTitleOnProject?: string | undefined
  name?: string | undefined
  number?: string | undefined
  clientName?: string | undefined
  cost?: number | undefined
  city?: string | undefined
  state?: string | undefined
  district?: string | undefined
  deliveryMethod?: string | undefined
  squareFootage?: number | undefined
  verticalMarket?: string | undefined
  completionDate?: Date | undefined
  thumbnail?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  projectResumeDescription?: string | undefined
  projectStandardDescription?: string | undefined
  nonHenselPhelpsProject?: boolean | undefined
}

export class Assets implements IAssets {
  documents?: DocumentMetadata[] | undefined
  images?: ImageMetadata[] | undefined
  videos?: VideoMetadata[] | undefined

  constructor(data?: IAssets) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['documents'])) {
        this.documents = [] as any
        for (let item of data['documents'])
          this.documents!.push(DocumentMetadata.fromJS(item))
      }
      if (Array.isArray(data['images'])) {
        this.images = [] as any
        for (let item of data['images'])
          this.images!.push(ImageMetadata.fromJS(item))
      }
      if (Array.isArray(data['videos'])) {
        this.videos = [] as any
        for (let item of data['videos'])
          this.videos!.push(VideoMetadata.fromJS(item))
      }
    }
  }

  static fromJS(data: any): Assets {
    data = typeof data === 'object' ? data : {}
    let result = new Assets()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    if (Array.isArray(this.documents)) {
      data['documents'] = []
      for (let item of this.documents) data['documents'].push(item.toJSON())
    }
    if (Array.isArray(this.images)) {
      data['images'] = []
      for (let item of this.images) data['images'].push(item.toJSON())
    }
    if (Array.isArray(this.videos)) {
      data['videos'] = []
      for (let item of this.videos) data['videos'].push(item.toJSON())
    }
    return data
  }
}

export interface IAssets {
  documents?: DocumentMetadata[] | undefined
  images?: ImageMetadata[] | undefined
  videos?: VideoMetadata[] | undefined
}

export class DocumentMetadata implements IDocumentMetadata {
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IDocumentMetadata) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name']
      this.url = data['url']
      this.sizeInBytes = data['sizeInBytes']
      this.contentType = data['contentType']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): DocumentMetadata {
    data = typeof data === 'object' ? data : {}
    let result = new DocumentMetadata()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['name'] = this.name
    data['url'] = this.url
    data['sizeInBytes'] = this.sizeInBytes
    data['contentType'] = this.contentType
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IDocumentMetadata {
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ImageMetadata implements IImageMetadata {
  height?: number | undefined
  width?: number | undefined
  thumbnailUrl?: string | undefined
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IImageMetadata) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.height = data['height']
      this.width = data['width']
      this.thumbnailUrl = data['thumbnailUrl']
      this.name = data['name']
      this.url = data['url']
      this.sizeInBytes = data['sizeInBytes']
      this.contentType = data['contentType']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): ImageMetadata {
    data = typeof data === 'object' ? data : {}
    let result = new ImageMetadata()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['height'] = this.height
    data['width'] = this.width
    data['thumbnailUrl'] = this.thumbnailUrl
    data['name'] = this.name
    data['url'] = this.url
    data['sizeInBytes'] = this.sizeInBytes
    data['contentType'] = this.contentType
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IImageMetadata {
  height?: number | undefined
  width?: number | undefined
  thumbnailUrl?: string | undefined
  name?: string | undefined
  url?: string | undefined
  sizeInBytes?: number | undefined
  contentType?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class VideoMetadata implements IVideoMetadata {
  url?: string | undefined

  constructor(data?: IVideoMetadata) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.url = data['url']
    }
  }

  static fromJS(data: any): VideoMetadata {
    data = typeof data === 'object' ? data : {}
    let result = new VideoMetadata()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['url'] = this.url
    return data
  }
}

export interface IVideoMetadata {
  url?: string | undefined
}

export class SearchResultSetOfQueryablePerson
  implements ISearchResultSetOfQueryablePerson {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryablePerson[] | undefined
  nextLink?: string | undefined

  constructor(data?: ISearchResultSetOfQueryablePerson) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.count = data['count']
      if (data['facets']) {
        this.facets = {} as any
        for (let key in data['facets']) {
          if (data['facets'].hasOwnProperty(key))
            this.facets![key] = data['facets'][key]
              ? data['facets'][key].map((i: any) => AthenaFacet.fromJS(i))
              : []
        }
      }
      if (Array.isArray(data['value'])) {
        this.value = [] as any
        for (let item of data['value'])
          this.value!.push(QueryablePerson.fromJS(item))
      }
      this.nextLink = data['nextLink']
    }
  }

  static fromJS(data: any): SearchResultSetOfQueryablePerson {
    data = typeof data === 'object' ? data : {}
    let result = new SearchResultSetOfQueryablePerson()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['count'] = this.count
    if (this.facets) {
      data['facets'] = {}
      for (let key in this.facets) {
        if (this.facets.hasOwnProperty(key))
          data['facets'][key] = this.facets[key]
      }
    }
    if (Array.isArray(this.value)) {
      data['value'] = []
      for (let item of this.value) data['value'].push(item.toJSON())
    }
    data['nextLink'] = this.nextLink
    return data
  }
}

export interface ISearchResultSetOfQueryablePerson {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryablePerson[] | undefined
  nextLink?: string | undefined
}

export class QueryablePerson implements IQueryablePerson {
  accreditations?: string[] | undefined
  schools?: string[] | undefined
  verticalMarkets?: string[] | undefined
  clientTypes?: string[] | undefined
  contractTypes?: string[] | undefined
  deliveryMethods?: string[] | undefined
  procurementMethods?: string[] | undefined
  constructionTypes?: string[] | undefined
  thumbnail?: string | undefined
  id?: number | undefined
  guid?: string | undefined
  name?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  middleInitial?: string | undefined
  preferredFirstName?: string | undefined
  isSalary?: boolean | undefined
  title?: string | undefined
  district?: string | undefined
  status?: string | undefined
  hireDate?: Date | undefined
  terminationDate?: Date | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  industryStartYear?: number | undefined
  userName?: string | undefined
  totalProjectValue?: number | undefined
  totalSquareFootage?: number | undefined
  gender?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  primaryImageUrl?: string | undefined
  notes?: string | undefined
  kudosNotes?: string | undefined
  signedRelease?: boolean | undefined

  constructor(data?: IQueryablePerson) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['accreditations'])) {
        this.accreditations = [] as any
        for (let item of data['accreditations']) this.accreditations!.push(item)
      }
      if (Array.isArray(data['schools'])) {
        this.schools = [] as any
        for (let item of data['schools']) this.schools!.push(item)
      }
      if (Array.isArray(data['verticalMarkets'])) {
        this.verticalMarkets = [] as any
        for (let item of data['verticalMarkets'])
          this.verticalMarkets!.push(item)
      }
      if (Array.isArray(data['clientTypes'])) {
        this.clientTypes = [] as any
        for (let item of data['clientTypes']) this.clientTypes!.push(item)
      }
      if (Array.isArray(data['contractTypes'])) {
        this.contractTypes = [] as any
        for (let item of data['contractTypes']) this.contractTypes!.push(item)
      }
      if (Array.isArray(data['deliveryMethods'])) {
        this.deliveryMethods = [] as any
        for (let item of data['deliveryMethods'])
          this.deliveryMethods!.push(item)
      }
      if (Array.isArray(data['procurementMethods'])) {
        this.procurementMethods = [] as any
        for (let item of data['procurementMethods'])
          this.procurementMethods!.push(item)
      }
      if (Array.isArray(data['constructionTypes'])) {
        this.constructionTypes = [] as any
        for (let item of data['constructionTypes'])
          this.constructionTypes!.push(item)
      }
      this.thumbnail = data['thumbnail']
      this.id = data['id']
      this.guid = data['guid']
      this.name = data['name']
      this.firstName = data['firstName']
      this.lastName = data['lastName']
      this.middleInitial = data['middleInitial']
      this.preferredFirstName = data['preferredFirstName']
      this.isSalary = data['isSalary']
      this.title = data['title']
      this.district = data['district']
      this.status = data['status']
      this.hireDate = data['hireDate']
        ? new Date(data['hireDate'].toString())
        : <any>undefined
      this.terminationDate = data['terminationDate']
        ? new Date(data['terminationDate'].toString())
        : <any>undefined
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.industryStartYear = data['industryStartYear']
      this.userName = data['userName']
      this.totalProjectValue = data['totalProjectValue']
      this.totalSquareFootage = data['totalSquareFootage']
      this.gender = data['gender']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.primaryImageUrl = data['primaryImageUrl']
      this.notes = data['notes']
      this.kudosNotes = data['kudosNotes']
      this.signedRelease = data['signedRelease']
    }
  }

  static fromJS(data: any): QueryablePerson {
    data = typeof data === 'object' ? data : {}
    let result = new QueryablePerson()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    if (Array.isArray(this.accreditations)) {
      data['accreditations'] = []
      for (let item of this.accreditations) data['accreditations'].push(item)
    }
    if (Array.isArray(this.schools)) {
      data['schools'] = []
      for (let item of this.schools) data['schools'].push(item)
    }
    if (Array.isArray(this.verticalMarkets)) {
      data['verticalMarkets'] = []
      for (let item of this.verticalMarkets) data['verticalMarkets'].push(item)
    }
    if (Array.isArray(this.clientTypes)) {
      data['clientTypes'] = []
      for (let item of this.clientTypes) data['clientTypes'].push(item)
    }
    if (Array.isArray(this.contractTypes)) {
      data['contractTypes'] = []
      for (let item of this.contractTypes) data['contractTypes'].push(item)
    }
    if (Array.isArray(this.deliveryMethods)) {
      data['deliveryMethods'] = []
      for (let item of this.deliveryMethods) data['deliveryMethods'].push(item)
    }
    if (Array.isArray(this.procurementMethods)) {
      data['procurementMethods'] = []
      for (let item of this.procurementMethods)
        data['procurementMethods'].push(item)
    }
    if (Array.isArray(this.constructionTypes)) {
      data['constructionTypes'] = []
      for (let item of this.constructionTypes)
        data['constructionTypes'].push(item)
    }
    data['thumbnail'] = this.thumbnail
    data['id'] = this.id
    data['guid'] = this.guid
    data['name'] = this.name
    data['firstName'] = this.firstName
    data['lastName'] = this.lastName
    data['middleInitial'] = this.middleInitial
    data['preferredFirstName'] = this.preferredFirstName
    data['isSalary'] = this.isSalary
    data['title'] = this.title
    data['district'] = this.district
    data['status'] = this.status
    data['hireDate'] = this.hireDate
      ? this.hireDate.toISOString()
      : <any>undefined
    data['terminationDate'] = this.terminationDate
      ? this.terminationDate.toISOString()
      : <any>undefined
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['industryStartYear'] = this.industryStartYear
    data['userName'] = this.userName
    data['totalProjectValue'] = this.totalProjectValue
    data['totalSquareFootage'] = this.totalSquareFootage
    data['gender'] = this.gender
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['primaryImageUrl'] = this.primaryImageUrl
    data['notes'] = this.notes
    data['kudosNotes'] = this.kudosNotes
    data['signedRelease'] = this.signedRelease
    return data
  }
}

export interface IQueryablePerson {
  accreditations?: string[] | undefined
  schools?: string[] | undefined
  verticalMarkets?: string[] | undefined
  clientTypes?: string[] | undefined
  contractTypes?: string[] | undefined
  deliveryMethods?: string[] | undefined
  procurementMethods?: string[] | undefined
  constructionTypes?: string[] | undefined
  thumbnail?: string | undefined
  id?: number | undefined
  guid?: string | undefined
  name?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  middleInitial?: string | undefined
  preferredFirstName?: string | undefined
  isSalary?: boolean | undefined
  title?: string | undefined
  district?: string | undefined
  status?: string | undefined
  hireDate?: Date | undefined
  terminationDate?: Date | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  industryStartYear?: number | undefined
  userName?: string | undefined
  totalProjectValue?: number | undefined
  totalSquareFootage?: number | undefined
  gender?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  primaryImageUrl?: string | undefined
  notes?: string | undefined
  kudosNotes?: string | undefined
  signedRelease?: boolean | undefined
}

export class PeopleSearchStatistics implements IPeopleSearchStatistics {
  totalIndustryYears?: number | undefined
  totalHPYears?: number | undefined
  recordCount?: number | undefined
  totalValue?: number | undefined
  totalSquareFootage?: number | undefined

  constructor(data?: IPeopleSearchStatistics) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalIndustryYears = data['totalIndustryYears']
      this.totalHPYears = data['totalHPYears']
      this.recordCount = data['recordCount']
      this.totalValue = data['totalValue']
      this.totalSquareFootage = data['totalSquareFootage']
    }
  }

  static fromJS(data: any): PeopleSearchStatistics {
    data = typeof data === 'object' ? data : {}
    let result = new PeopleSearchStatistics()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['totalIndustryYears'] = this.totalIndustryYears
    data['totalHPYears'] = this.totalHPYears
    data['recordCount'] = this.recordCount
    data['totalValue'] = this.totalValue
    data['totalSquareFootage'] = this.totalSquareFootage
    return data
  }
}

export interface IPeopleSearchStatistics {
  totalIndustryYears?: number | undefined
  totalHPYears?: number | undefined
  recordCount?: number | undefined
  totalValue?: number | undefined
  totalSquareFootage?: number | undefined
}

export class PersonContact implements IPersonContact {
  personGuid?: string | undefined
  personId?: number | undefined
  contactId?: string | undefined
  referenceType?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IPersonContact) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.personGuid = data['personGuid']
      this.personId = data['personId']
      this.contactId = data['contactId']
      this.referenceType = data['referenceType']
      this.comments = data['comments']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): PersonContact {
    data = typeof data === 'object' ? data : {}
    let result = new PersonContact()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['personGuid'] = this.personGuid
    data['personId'] = this.personId
    data['contactId'] = this.contactId
    data['referenceType'] = this.referenceType
    data['comments'] = this.comments
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IPersonContact {
  personGuid?: string | undefined
  personId?: number | undefined
  contactId?: string | undefined
  referenceType?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ApiProgram implements IApiProgram {
  id?: string | undefined
  name?: string | undefined
  description?: string | undefined
  value?: number | undefined
  squareFootage?: number | undefined
  approval?: boolean | undefined
  approvalModifiedBy?: string | undefined
  approvalModifiedOn?: Date | undefined
  projects?: ProgramProject[] | undefined

  constructor(data?: IApiProgram) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.description = data['description']
      this.value = data['value']
      this.squareFootage = data['squareFootage']
      this.approval = data['approval']
      this.approvalModifiedBy = data['approvalModifiedBy']
      this.approvalModifiedOn = data['approvalModifiedOn']
        ? new Date(data['approvalModifiedOn'].toString())
        : <any>undefined
      if (Array.isArray(data['projects'])) {
        this.projects = [] as any
        for (let item of data['projects'])
          this.projects!.push(ProgramProject.fromJS(item))
      }
    }
  }

  static fromJS(data: any): ApiProgram {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProgram()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['description'] = this.description
    data['value'] = this.value
    data['squareFootage'] = this.squareFootage
    data['approval'] = this.approval
    data['approvalModifiedBy'] = this.approvalModifiedBy
    data['approvalModifiedOn'] = this.approvalModifiedOn
      ? this.approvalModifiedOn.toISOString()
      : <any>undefined
    if (Array.isArray(this.projects)) {
      data['projects'] = []
      for (let item of this.projects) data['projects'].push(item.toJSON())
    }
    return data
  }
}

export interface IApiProgram {
  id?: string | undefined
  name?: string | undefined
  description?: string | undefined
  value?: number | undefined
  squareFootage?: number | undefined
  approval?: boolean | undefined
  approvalModifiedBy?: string | undefined
  approvalModifiedOn?: Date | undefined
  projects?: ProgramProject[] | undefined
}

export class ProgramProject implements IProgramProject {
  id?: string | undefined
  name?: string | undefined
  projectNumbers?: string[] | undefined
  value?: number | undefined
  squareFootage?: number | undefined
  includeSquareFootageInProgram?: boolean | undefined
  includeContractValueInProgram?: boolean | undefined

  constructor(data?: IProgramProject) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      if (Array.isArray(data['projectNumbers'])) {
        this.projectNumbers = [] as any
        for (let item of data['projectNumbers']) this.projectNumbers!.push(item)
      }
      this.value = data['value']
      this.squareFootage = data['squareFootage']
      this.includeSquareFootageInProgram = data['includeSquareFootageInProgram']
      this.includeContractValueInProgram = data['includeContractValueInProgram']
    }
  }

  static fromJS(data: any): ProgramProject {
    data = typeof data === 'object' ? data : {}
    let result = new ProgramProject()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    if (Array.isArray(this.projectNumbers)) {
      data['projectNumbers'] = []
      for (let item of this.projectNumbers) data['projectNumbers'].push(item)
    }
    data['value'] = this.value
    data['squareFootage'] = this.squareFootage
    data['includeSquareFootageInProgram'] = this.includeSquareFootageInProgram
    data['includeContractValueInProgram'] = this.includeContractValueInProgram
    return data
  }
}

export interface IProgramProject {
  id?: string | undefined
  name?: string | undefined
  projectNumbers?: string[] | undefined
  value?: number | undefined
  squareFootage?: number | undefined
  includeSquareFootageInProgram?: boolean | undefined
  includeContractValueInProgram?: boolean | undefined
}

export class ProjectAward implements IProjectAward {
  title?: string | undefined
  citation?: string | undefined
  date?: Date | undefined
  sponsor?: string | undefined
  category?: string | undefined
  isPreffered?: string | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IProjectAward) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.title = data['title']
      this.citation = data['citation']
      this.date = data['date']
        ? new Date(data['date'].toString())
        : <any>undefined
      this.sponsor = data['sponsor']
      this.category = data['category']
      this.isPreffered = data['isPreffered']
      this.id = data['id']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): ProjectAward {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectAward()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['title'] = this.title
    data['citation'] = this.citation
    data['date'] = this.date ? this.date.toISOString() : <any>undefined
    data['sponsor'] = this.sponsor
    data['category'] = this.category
    data['isPreffered'] = this.isPreffered
    data['id'] = this.id
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IProjectAward {
  title?: string | undefined
  citation?: string | undefined
  date?: Date | undefined
  sponsor?: string | undefined
  category?: string | undefined
  isPreffered?: string | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ProjectChallengeSolution implements IProjectChallengeSolution {
  title?: string | undefined
  challenge?: string | undefined
  solution?: string | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  approval?: ProjectChallengeSolutionApproval | undefined

  constructor(data?: IProjectChallengeSolution) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.title = data['title']
      this.challenge = data['challenge']
      this.solution = data['solution']
      this.id = data['id']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.approval = data['approval']
        ? ProjectChallengeSolutionApproval.fromJS(data['approval'])
        : <any>undefined
    }
  }

  static fromJS(data: any): ProjectChallengeSolution {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectChallengeSolution()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['title'] = this.title
    data['challenge'] = this.challenge
    data['solution'] = this.solution
    data['id'] = this.id
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['approval'] = this.approval ? this.approval.toJSON() : <any>undefined
    return data
  }
}

export interface IProjectChallengeSolution {
  title?: string | undefined
  challenge?: string | undefined
  solution?: string | undefined
  id?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  approval?: ProjectChallengeSolutionApproval | undefined
}

export class ProjectChallengeSolutionApproval
  implements IProjectChallengeSolutionApproval {
  isApproved?: boolean | undefined
  modifiedBy?: string | undefined
  modifiedOn?: Date | undefined

  constructor(data?: IProjectChallengeSolutionApproval) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isApproved = data['isApproved']
      this.modifiedBy = data['modifiedBy']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
    }
  }

  static fromJS(data: any): ProjectChallengeSolutionApproval {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectChallengeSolutionApproval()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['isApproved'] = this.isApproved
    data['modifiedBy'] = this.modifiedBy
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    return data
  }
}

export interface IProjectChallengeSolutionApproval {
  isApproved?: boolean | undefined
  modifiedBy?: string | undefined
  modifiedOn?: Date | undefined
}

export class ApiProjectPhaseLabel implements IApiProjectPhaseLabel {
  id?: string | undefined
  label?: string | undefined
  order?: number | undefined

  constructor(data?: IApiProjectPhaseLabel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.label = data['label']
      this.order = data['order']
    }
  }

  static fromJS(data: any): ApiProjectPhaseLabel {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProjectPhaseLabel()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['label'] = this.label
    data['order'] = this.order
    return data
  }
}

export interface IApiProjectPhaseLabel {
  id?: string | undefined
  label?: string | undefined
  order?: number | undefined
}

export class ApiProject implements IApiProject {
  secondaryCategory?: string[] | undefined
  constructionType?: string[] | undefined
  clientVerticalMarkets?: string[] | undefined
  references?: ApiProjectReference[] | undefined
  stakeholders?: ApiProjectStakeholder[] | undefined
  challengesAndSolutions?: ProjectChallengeSolution[] | undefined
  awards?: ProjectAward[] | undefined
  personHistory?: ApiPersonHistory[] | undefined
  attachments?: AthenaFileMetadata[] | undefined
  approvals?: ProjectApproval[] | undefined
  projectNumbers?: string[] | undefined
  programAssociatedProjects?: ProgramProject[] | undefined
  technologyPhases?: ApiProjectTechnologyPhase[] | undefined
  sustainabilityCertifications?:
    | ApiProjectSustainabilityCertification[]
    | undefined
  supplierDiversityGoals?: ApiSupplierDiversityGoal[] | undefined
  vdcTechnologyPhases?: ApiProjectVdcTechnologyPhase[] | undefined
  assets?: Assets | undefined
  thumbnail?: string | undefined
  districts?: string[] | undefined
  recordAndRelatedModifiedOn?: Date | undefined
  recordAndRelatedModifiedBy?: string | undefined
  webdamFolderId?: number | undefined
  programApproval?: boolean | undefined
  programApprovalModifiedBy?: string | undefined
  programApprovalModifiedOn?: Date | undefined
  id?: string | undefined
  name?: string | undefined
  displayName?: string | undefined
  number?: string | undefined
  verticalMarket?: string | undefined
  squareFootage?: number | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  cost?: number | undefined
  clientType?: string | undefined
  deliveryMethod?: string | undefined
  contractType?: string | undefined
  procurementMethod?: string | undefined
  country?: string | undefined
  completionDate?: Date | undefined
  scheduledCompletionDate?: Date | undefined
  startDate?: Date | undefined
  standardDescription?: string | undefined
  clientName?: string | undefined
  streetAddress?: string | undefined
  private?: boolean | undefined
  active?: boolean | undefined
  usable?: boolean | undefined
  autoUpdateContractValue?: boolean | undefined
  leed?: boolean | undefined
  clientLocation?: string | undefined
  insuranceType?: string | undefined
  rfiTotalCount?: number | undefined
  rfiClosedCount?: number | undefined
  rfiPendingCount?: number | undefined
  smallBusinessStory?: string | undefined
  clientContractProjectNumber?: string | undefined
  clientNotes?: string | undefined
  doNotUse?: boolean | undefined
  hbcuMinorityGoalsAchieved?: number | undefined
  hbcuMinorityGoalsPercent?: number | undefined
  hubZoneSmallBusinessGoalsAchieved?: number | undefined
  hubZoneSmallBusinessGoalsPercent?: number | undefined
  largeBusinessConcernsAchieved?: number | undefined
  largeBusinessConcernsPercent?: number | undefined
  davSmallBusinessGoalsAchieved?: number | undefined
  davSmallBusinessGoalsPercent?: number | undefined
  smallBusinessConcernsAchieved?: number | undefined
  smallBusinessConcernsPercent?: number | undefined
  smallDisadvantagedBusinessGoalsAchieved?: number | undefined
  smallDisadvantagedBusinessGoalsPercent?: number | undefined
  veteranOwnedSmallBusinessGoalsAchieved?: number | undefined
  veteranOwnedSmallBusinessGoalsPercent?: number | undefined
  womenOwnedSmallBusinessGoalsAchieved?: number | undefined
  womenOwnedSmallBusinessGoalsPercent?: number | undefined
  minorityOwnedSmallBusinessGoalsAchieved?: number | undefined
  minorityOwnedSmallBusinessGoalsPercent?: number | undefined
  jointVenture?: boolean | undefined
  numBeds?: number | undefined
  numHousingUnits?: number | undefined
  numFloors?: number | undefined
  numKeysHotel?: number | undefined
  numParkingSpaces?: number | undefined
  parkingGarageSquareFootage?: number | undefined
  nonHenselPhelpsProject?: boolean | undefined
  resumeDescription?: string | undefined
  detailedDescription?: string | undefined
  designAspectsDescription?: string | undefined
  technicalAspectsDescription?: string | undefined
  notes?: string | undefined
  transitionalServicesDescription?: string | undefined
  operationsAndMaintenanceDescription?: string | undefined
  facilityManagementDescription?: string | undefined
  specialProjectsDescription?: string | undefined
  designPreconScheduledStartDate?: Date | undefined
  designPreconActualStartDate?: Date | undefined
  designPreconScheduledCompletionDate?: Date | undefined
  designPreconActualCompletionDate?: Date | undefined
  constructionScheduledStartDate?: Date | undefined
  constructionActualStartDate?: Date | undefined
  constructionScheduledSubstantialCompletionDate?: Date | undefined
  constructionModifiedSubstantialCompletionDate?: Date | undefined
  constructionActualSubstantialCompletionDate?: Date | undefined
  scheduleDifferenceReasons?: string | undefined
  preconInContract?: string | undefined
  preconCost?: number | undefined
  preconCostModified?: number | undefined
  originalConstructionContractValue?: number | undefined
  finalConstructionContractValue?: number | undefined
  confidential?: boolean | undefined
  safetyManHoursWorked?: number | undefined
  safetyEmr?: string | undefined
  numLostTimeAccidents?: number | undefined
  numOshaRecordables?: number | undefined
  dartRate?: number | undefined
  selfWorkNotes?: string | undefined
  selfWorkPercent?: number | undefined
  selfWorkType?: string | undefined
  leedStatus?: string | undefined
  leedVersion?: string | undefined
  leedRating?: string | undefined
  finalCparsRating?: string | undefined
  valueEngineering?: string | undefined
  quality?: string | undefined
  bimUsage?: string | undefined
  collaborativeEfforts?: string | undefined
  preconEfforts?: string | undefined
  commissioningEfforts?: string | undefined
  lessonsLearned?: string | undefined
  projectInnovations?: string | undefined
  proposalDate?: Date | undefined
  awardDate?: Date | undefined
  standardChangeOrderCount?: number | undefined
  standardChangeOrderValue?: number | undefined
  gmpChangeOrderCount?: number | undefined
  gmpChangeOrderValue?: number | undefined
  preconChangeOrderCount?: number | undefined
  preconChangeOrderValue?: number | undefined
  totalChangeOrderValue?: number | undefined
  totalChangeOrderCount?: number | undefined
  changeOrderReasons?: string | undefined
  warrantyExpirationDate?: Date | undefined
  ownerTotalDevelopmentCost?: number | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  primaryImageUrl?: string | undefined
  overallSiteAcreage?: number | undefined
  numFloorsBelowGrade?: number | undefined
  numOnGradeParkingSpaces?: number | undefined
  centralUtilityPlantSquareFootage?: number | undefined
  dataCenterTierLevel?: string | undefined
  totalBuildMegawatts?: number | undefined
  scifSquareFootage?: number | undefined
  atfpLevel?: string | undefined
  safetyNarrative?: string | undefined
  community?: string | undefined
  quotes?: string | undefined
  securityNarrative?: string | undefined
  programId?: string | undefined
  programName?: string | undefined
  programDescription?: string | undefined
  programContractValue?: number | undefined
  programSquareFootage?: number | undefined
  technologyInternalNarrative?: string | undefined
  technologyExternalNarrative?: string | undefined
  sustainabilityNarrative?: string | undefined
  smallBusinessParticipationNarrative?: string | undefined
  smallBusinessParticipationEnabled?: boolean | undefined
  communityEngagementNarrative?: string | undefined
  communityEngagementEnabled?: boolean | undefined
  workforceDevelopmentNarrative?: string | undefined
  workforceDevelopmentEnabled?: boolean | undefined
  supplierDiversityNotes?: string | undefined
  finalPaymentDate?: Date | undefined
  servicesFteCount?: number | undefined
  servicesBusinessUnit?: string | undefined
  groundbreakingDate?: Date | undefined
  toppingOutDate?: Date | undefined
  buildingEnclosedDate?: Date | undefined
  ribbonCuttingDate?: Date | undefined
  linearFeet?: number | undefined
  millionGallonsPerDay?: number | undefined
  includeSquareFootageInProgram?: boolean | undefined
  includeContractValueInProgram?: boolean | undefined

  constructor(data?: IApiProject) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['secondaryCategory'])) {
        this.secondaryCategory = [] as any
        for (let item of data['secondaryCategory'])
          this.secondaryCategory!.push(item)
      }
      if (Array.isArray(data['constructionType'])) {
        this.constructionType = [] as any
        for (let item of data['constructionType'])
          this.constructionType!.push(item)
      }
      if (Array.isArray(data['clientVerticalMarkets'])) {
        this.clientVerticalMarkets = [] as any
        for (let item of data['clientVerticalMarkets'])
          this.clientVerticalMarkets!.push(item)
      }
      if (Array.isArray(data['references'])) {
        this.references = [] as any
        for (let item of data['references'])
          this.references!.push(ApiProjectReference.fromJS(item))
      }
      if (Array.isArray(data['stakeholders'])) {
        this.stakeholders = [] as any
        for (let item of data['stakeholders'])
          this.stakeholders!.push(ApiProjectStakeholder.fromJS(item))
      }
      if (Array.isArray(data['challengesAndSolutions'])) {
        this.challengesAndSolutions = [] as any
        for (let item of data['challengesAndSolutions'])
          this.challengesAndSolutions!.push(
            ProjectChallengeSolution.fromJS(item),
          )
      }
      if (Array.isArray(data['awards'])) {
        this.awards = [] as any
        for (let item of data['awards'])
          this.awards!.push(ProjectAward.fromJS(item))
      }
      if (Array.isArray(data['personHistory'])) {
        this.personHistory = [] as any
        for (let item of data['personHistory'])
          this.personHistory!.push(ApiPersonHistory.fromJS(item))
      }
      if (Array.isArray(data['attachments'])) {
        this.attachments = [] as any
        for (let item of data['attachments'])
          this.attachments!.push(AthenaFileMetadata.fromJS(item))
      }
      if (Array.isArray(data['approvals'])) {
        this.approvals = [] as any
        for (let item of data['approvals'])
          this.approvals!.push(ProjectApproval.fromJS(item))
      }
      if (Array.isArray(data['projectNumbers'])) {
        this.projectNumbers = [] as any
        for (let item of data['projectNumbers']) this.projectNumbers!.push(item)
      }
      if (Array.isArray(data['programAssociatedProjects'])) {
        this.programAssociatedProjects = [] as any
        for (let item of data['programAssociatedProjects'])
          this.programAssociatedProjects!.push(ProgramProject.fromJS(item))
      }
      if (Array.isArray(data['technologyPhases'])) {
        this.technologyPhases = [] as any
        for (let item of data['technologyPhases'])
          this.technologyPhases!.push(ApiProjectTechnologyPhase.fromJS(item))
      }
      if (Array.isArray(data['sustainabilityCertifications'])) {
        this.sustainabilityCertifications = [] as any
        for (let item of data['sustainabilityCertifications'])
          this.sustainabilityCertifications!.push(
            ApiProjectSustainabilityCertification.fromJS(item),
          )
      }
      if (Array.isArray(data['supplierDiversityGoals'])) {
        this.supplierDiversityGoals = [] as any
        for (let item of data['supplierDiversityGoals'])
          this.supplierDiversityGoals!.push(
            ApiSupplierDiversityGoal.fromJS(item),
          )
      }
      if (Array.isArray(data['vdcTechnologyPhases'])) {
        this.vdcTechnologyPhases = [] as any
        for (let item of data['vdcTechnologyPhases'])
          this.vdcTechnologyPhases!.push(
            ApiProjectVdcTechnologyPhase.fromJS(item),
          )
      }
      this.assets = data['assets']
        ? Assets.fromJS(data['assets'])
        : <any>undefined
      this.thumbnail = data['thumbnail']
      if (Array.isArray(data['districts'])) {
        this.districts = [] as any
        for (let item of data['districts']) this.districts!.push(item)
      }
      this.recordAndRelatedModifiedOn = data['recordAndRelatedModifiedOn']
        ? new Date(data['recordAndRelatedModifiedOn'].toString())
        : <any>undefined
      this.recordAndRelatedModifiedBy = data['recordAndRelatedModifiedBy']
      this.webdamFolderId = data['webdamFolderId']
      this.programApproval = data['programApproval']
      this.programApprovalModifiedBy = data['programApprovalModifiedBy']
      this.programApprovalModifiedOn = data['programApprovalModifiedOn']
        ? new Date(data['programApprovalModifiedOn'].toString())
        : <any>undefined
      this.id = data['id']
      this.name = data['name']
      this.displayName = data['displayName']
      this.number = data['number']
      this.verticalMarket = data['verticalMarket']
      this.squareFootage = data['squareFootage']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.cost = data['cost']
      this.clientType = data['clientType']
      this.deliveryMethod = data['deliveryMethod']
      this.contractType = data['contractType']
      this.procurementMethod = data['procurementMethod']
      this.country = data['country']
      this.completionDate = data['completionDate']
        ? new Date(data['completionDate'].toString())
        : <any>undefined
      this.scheduledCompletionDate = data['scheduledCompletionDate']
        ? new Date(data['scheduledCompletionDate'].toString())
        : <any>undefined
      this.startDate = data['startDate']
        ? new Date(data['startDate'].toString())
        : <any>undefined
      this.standardDescription = data['standardDescription']
      this.clientName = data['clientName']
      this.streetAddress = data['streetAddress']
      this.private = data['private']
      this.active = data['active']
      this.usable = data['usable']
      this.autoUpdateContractValue = data['autoUpdateContractValue']
      this.leed = data['leed']
      this.clientLocation = data['clientLocation']
      this.insuranceType = data['insuranceType']
      this.rfiTotalCount = data['rfiTotalCount']
      this.rfiClosedCount = data['rfiClosedCount']
      this.rfiPendingCount = data['rfiPendingCount']
      this.smallBusinessStory = data['smallBusinessStory']
      this.clientContractProjectNumber = data['clientContractProjectNumber']
      this.clientNotes = data['clientNotes']
      this.doNotUse = data['doNotUse']
      this.hbcuMinorityGoalsAchieved = data['hbcuMinorityGoalsAchieved']
      this.hbcuMinorityGoalsPercent = data['hbcuMinorityGoalsPercent']
      this.hubZoneSmallBusinessGoalsAchieved =
        data['hubZoneSmallBusinessGoalsAchieved']
      this.hubZoneSmallBusinessGoalsPercent =
        data['hubZoneSmallBusinessGoalsPercent']
      this.largeBusinessConcernsAchieved = data['largeBusinessConcernsAchieved']
      this.largeBusinessConcernsPercent = data['largeBusinessConcernsPercent']
      this.davSmallBusinessGoalsAchieved = data['davSmallBusinessGoalsAchieved']
      this.davSmallBusinessGoalsPercent = data['davSmallBusinessGoalsPercent']
      this.smallBusinessConcernsAchieved = data['smallBusinessConcernsAchieved']
      this.smallBusinessConcernsPercent = data['smallBusinessConcernsPercent']
      this.smallDisadvantagedBusinessGoalsAchieved =
        data['smallDisadvantagedBusinessGoalsAchieved']
      this.smallDisadvantagedBusinessGoalsPercent =
        data['smallDisadvantagedBusinessGoalsPercent']
      this.veteranOwnedSmallBusinessGoalsAchieved =
        data['veteranOwnedSmallBusinessGoalsAchieved']
      this.veteranOwnedSmallBusinessGoalsPercent =
        data['veteranOwnedSmallBusinessGoalsPercent']
      this.womenOwnedSmallBusinessGoalsAchieved =
        data['womenOwnedSmallBusinessGoalsAchieved']
      this.womenOwnedSmallBusinessGoalsPercent =
        data['womenOwnedSmallBusinessGoalsPercent']
      this.minorityOwnedSmallBusinessGoalsAchieved =
        data['minorityOwnedSmallBusinessGoalsAchieved']
      this.minorityOwnedSmallBusinessGoalsPercent =
        data['minorityOwnedSmallBusinessGoalsPercent']
      this.jointVenture = data['jointVenture']
      this.numBeds = data['numBeds']
      this.numHousingUnits = data['numHousingUnits']
      this.numFloors = data['numFloors']
      this.numKeysHotel = data['numKeysHotel']
      this.numParkingSpaces = data['numParkingSpaces']
      this.parkingGarageSquareFootage = data['parkingGarageSquareFootage']
      this.nonHenselPhelpsProject = data['nonHenselPhelpsProject']
      this.resumeDescription = data['resumeDescription']
      this.detailedDescription = data['detailedDescription']
      this.designAspectsDescription = data['designAspectsDescription']
      this.technicalAspectsDescription = data['technicalAspectsDescription']
      this.notes = data['notes']
      this.transitionalServicesDescription =
        data['transitionalServicesDescription']
      this.operationsAndMaintenanceDescription =
        data['operationsAndMaintenanceDescription']
      this.facilityManagementDescription = data['facilityManagementDescription']
      this.specialProjectsDescription = data['specialProjectsDescription']
      this.designPreconScheduledStartDate = data[
        'designPreconScheduledStartDate'
      ]
        ? new Date(data['designPreconScheduledStartDate'].toString())
        : <any>undefined
      this.designPreconActualStartDate = data['designPreconActualStartDate']
        ? new Date(data['designPreconActualStartDate'].toString())
        : <any>undefined
      this.designPreconScheduledCompletionDate = data[
        'designPreconScheduledCompletionDate'
      ]
        ? new Date(data['designPreconScheduledCompletionDate'].toString())
        : <any>undefined
      this.designPreconActualCompletionDate = data[
        'designPreconActualCompletionDate'
      ]
        ? new Date(data['designPreconActualCompletionDate'].toString())
        : <any>undefined
      this.constructionScheduledStartDate = data[
        'constructionScheduledStartDate'
      ]
        ? new Date(data['constructionScheduledStartDate'].toString())
        : <any>undefined
      this.constructionActualStartDate = data['constructionActualStartDate']
        ? new Date(data['constructionActualStartDate'].toString())
        : <any>undefined
      this.constructionScheduledSubstantialCompletionDate = data[
        'constructionScheduledSubstantialCompletionDate'
      ]
        ? new Date(
            data['constructionScheduledSubstantialCompletionDate'].toString(),
          )
        : <any>undefined
      this.constructionModifiedSubstantialCompletionDate = data[
        'constructionModifiedSubstantialCompletionDate'
      ]
        ? new Date(
            data['constructionModifiedSubstantialCompletionDate'].toString(),
          )
        : <any>undefined
      this.constructionActualSubstantialCompletionDate = data[
        'constructionActualSubstantialCompletionDate'
      ]
        ? new Date(
            data['constructionActualSubstantialCompletionDate'].toString(),
          )
        : <any>undefined
      this.scheduleDifferenceReasons = data['scheduleDifferenceReasons']
      this.preconInContract = data['preconInContract']
      this.preconCost = data['preconCost']
      this.preconCostModified = data['preconCostModified']
      this.originalConstructionContractValue =
        data['originalConstructionContractValue']
      this.finalConstructionContractValue =
        data['finalConstructionContractValue']
      this.confidential = data['confidential']
      this.safetyManHoursWorked = data['safetyManHoursWorked']
      this.safetyEmr = data['safetyEmr']
      this.numLostTimeAccidents = data['numLostTimeAccidents']
      this.numOshaRecordables = data['numOshaRecordables']
      this.dartRate = data['dartRate']
      this.selfWorkNotes = data['selfWorkNotes']
      this.selfWorkPercent = data['selfWorkPercent']
      this.selfWorkType = data['selfWorkType']
      this.leedStatus = data['leedStatus']
      this.leedVersion = data['leedVersion']
      this.leedRating = data['leedRating']
      this.finalCparsRating = data['finalCparsRating']
      this.valueEngineering = data['valueEngineering']
      this.quality = data['quality']
      this.bimUsage = data['bimUsage']
      this.collaborativeEfforts = data['collaborativeEfforts']
      this.preconEfforts = data['preconEfforts']
      this.commissioningEfforts = data['commissioningEfforts']
      this.lessonsLearned = data['lessonsLearned']
      this.projectInnovations = data['projectInnovations']
      this.proposalDate = data['proposalDate']
        ? new Date(data['proposalDate'].toString())
        : <any>undefined
      this.awardDate = data['awardDate']
        ? new Date(data['awardDate'].toString())
        : <any>undefined
      this.standardChangeOrderCount = data['standardChangeOrderCount']
      this.standardChangeOrderValue = data['standardChangeOrderValue']
      this.gmpChangeOrderCount = data['gmpChangeOrderCount']
      this.gmpChangeOrderValue = data['gmpChangeOrderValue']
      this.preconChangeOrderCount = data['preconChangeOrderCount']
      this.preconChangeOrderValue = data['preconChangeOrderValue']
      this.totalChangeOrderValue = data['totalChangeOrderValue']
      this.totalChangeOrderCount = data['totalChangeOrderCount']
      this.changeOrderReasons = data['changeOrderReasons']
      this.warrantyExpirationDate = data['warrantyExpirationDate']
        ? new Date(data['warrantyExpirationDate'].toString())
        : <any>undefined
      this.ownerTotalDevelopmentCost = data['ownerTotalDevelopmentCost']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.primaryImageUrl = data['primaryImageUrl']
      this.overallSiteAcreage = data['overallSiteAcreage']
      this.numFloorsBelowGrade = data['numFloorsBelowGrade']
      this.numOnGradeParkingSpaces = data['numOnGradeParkingSpaces']
      this.centralUtilityPlantSquareFootage =
        data['centralUtilityPlantSquareFootage']
      this.dataCenterTierLevel = data['dataCenterTierLevel']
      this.totalBuildMegawatts = data['totalBuildMegawatts']
      this.scifSquareFootage = data['scifSquareFootage']
      this.atfpLevel = data['atfpLevel']
      this.safetyNarrative = data['safetyNarrative']
      this.community = data['community']
      this.quotes = data['quotes']
      this.securityNarrative = data['securityNarrative']
      this.programId = data['programId']
      this.programName = data['programName']
      this.programDescription = data['programDescription']
      this.programContractValue = data['programContractValue']
      this.programSquareFootage = data['programSquareFootage']
      this.technologyInternalNarrative = data['technologyInternalNarrative']
      this.technologyExternalNarrative = data['technologyExternalNarrative']
      this.sustainabilityNarrative = data['sustainabilityNarrative']
      this.smallBusinessParticipationNarrative =
        data['smallBusinessParticipationNarrative']
      this.smallBusinessParticipationEnabled =
        data['smallBusinessParticipationEnabled']
      this.communityEngagementNarrative = data['communityEngagementNarrative']
      this.communityEngagementEnabled = data['communityEngagementEnabled']
      this.workforceDevelopmentNarrative = data['workforceDevelopmentNarrative']
      this.workforceDevelopmentEnabled = data['workforceDevelopmentEnabled']
      this.supplierDiversityNotes = data['supplierDiversityNotes']
      this.finalPaymentDate = data['finalPaymentDate']
        ? new Date(data['finalPaymentDate'].toString())
        : <any>undefined
      this.servicesFteCount = data['servicesFteCount']
      this.servicesBusinessUnit = data['servicesBusinessUnit']
      this.groundbreakingDate = data['groundbreakingDate']
        ? new Date(data['groundbreakingDate'].toString())
        : <any>undefined
      this.toppingOutDate = data['toppingOutDate']
        ? new Date(data['toppingOutDate'].toString())
        : <any>undefined
      this.buildingEnclosedDate = data['buildingEnclosedDate']
        ? new Date(data['buildingEnclosedDate'].toString())
        : <any>undefined
      this.ribbonCuttingDate = data['ribbonCuttingDate']
        ? new Date(data['ribbonCuttingDate'].toString())
        : <any>undefined
      this.linearFeet = data['linearFeet']
      this.millionGallonsPerDay = data['millionGallonsPerDay']
      this.includeSquareFootageInProgram = data['includeSquareFootageInProgram']
      this.includeContractValueInProgram = data['includeContractValueInProgram']
    }
  }

  static fromJS(data: any): ApiProject {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProject()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    if (Array.isArray(this.secondaryCategory)) {
      data['secondaryCategory'] = []
      for (let item of this.secondaryCategory)
        data['secondaryCategory'].push(item)
    }
    if (Array.isArray(this.constructionType)) {
      data['constructionType'] = []
      for (let item of this.constructionType)
        data['constructionType'].push(item)
    }
    if (Array.isArray(this.clientVerticalMarkets)) {
      data['clientVerticalMarkets'] = []
      for (let item of this.clientVerticalMarkets)
        data['clientVerticalMarkets'].push(item)
    }
    if (Array.isArray(this.references)) {
      data['references'] = []
      for (let item of this.references) data['references'].push(item.toJSON())
    }
    if (Array.isArray(this.stakeholders)) {
      data['stakeholders'] = []
      for (let item of this.stakeholders)
        data['stakeholders'].push(item.toJSON())
    }
    if (Array.isArray(this.challengesAndSolutions)) {
      data['challengesAndSolutions'] = []
      for (let item of this.challengesAndSolutions)
        data['challengesAndSolutions'].push(item.toJSON())
    }
    if (Array.isArray(this.awards)) {
      data['awards'] = []
      for (let item of this.awards) data['awards'].push(item.toJSON())
    }
    if (Array.isArray(this.personHistory)) {
      data['personHistory'] = []
      for (let item of this.personHistory)
        data['personHistory'].push(item.toJSON())
    }
    if (Array.isArray(this.attachments)) {
      data['attachments'] = []
      for (let item of this.attachments) data['attachments'].push(item.toJSON())
    }
    if (Array.isArray(this.approvals)) {
      data['approvals'] = []
      for (let item of this.approvals) data['approvals'].push(item.toJSON())
    }
    if (Array.isArray(this.projectNumbers)) {
      data['projectNumbers'] = []
      for (let item of this.projectNumbers) data['projectNumbers'].push(item)
    }
    if (Array.isArray(this.programAssociatedProjects)) {
      data['programAssociatedProjects'] = []
      for (let item of this.programAssociatedProjects)
        data['programAssociatedProjects'].push(item.toJSON())
    }
    if (Array.isArray(this.technologyPhases)) {
      data['technologyPhases'] = []
      for (let item of this.technologyPhases)
        data['technologyPhases'].push(item.toJSON())
    }
    if (Array.isArray(this.sustainabilityCertifications)) {
      data['sustainabilityCertifications'] = []
      for (let item of this.sustainabilityCertifications)
        data['sustainabilityCertifications'].push(item.toJSON())
    }
    if (Array.isArray(this.supplierDiversityGoals)) {
      data['supplierDiversityGoals'] = []
      for (let item of this.supplierDiversityGoals)
        data['supplierDiversityGoals'].push(item.toJSON())
    }
    if (Array.isArray(this.vdcTechnologyPhases)) {
      data['vdcTechnologyPhases'] = []
      for (let item of this.vdcTechnologyPhases)
        data['vdcTechnologyPhases'].push(item.toJSON())
    }
    data['assets'] = this.assets ? this.assets.toJSON() : <any>undefined
    data['thumbnail'] = this.thumbnail
    if (Array.isArray(this.districts)) {
      data['districts'] = []
      for (let item of this.districts) data['districts'].push(item)
    }
    data['recordAndRelatedModifiedOn'] = this.recordAndRelatedModifiedOn
      ? this.recordAndRelatedModifiedOn.toISOString()
      : <any>undefined
    data['recordAndRelatedModifiedBy'] = this.recordAndRelatedModifiedBy
    data['webdamFolderId'] = this.webdamFolderId
    data['programApproval'] = this.programApproval
    data['programApprovalModifiedBy'] = this.programApprovalModifiedBy
    data['programApprovalModifiedOn'] = this.programApprovalModifiedOn
      ? this.programApprovalModifiedOn.toISOString()
      : <any>undefined
    data['id'] = this.id
    data['name'] = this.name
    data['displayName'] = this.displayName
    data['number'] = this.number
    data['verticalMarket'] = this.verticalMarket
    data['squareFootage'] = this.squareFootage
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['cost'] = this.cost
    data['clientType'] = this.clientType
    data['deliveryMethod'] = this.deliveryMethod
    data['contractType'] = this.contractType
    data['procurementMethod'] = this.procurementMethod
    data['country'] = this.country
    data['completionDate'] = this.completionDate
      ? this.completionDate.toISOString()
      : <any>undefined
    data['scheduledCompletionDate'] = this.scheduledCompletionDate
      ? this.scheduledCompletionDate.toISOString()
      : <any>undefined
    data['startDate'] = this.startDate
      ? this.startDate.toISOString()
      : <any>undefined
    data['standardDescription'] = this.standardDescription
    data['clientName'] = this.clientName
    data['streetAddress'] = this.streetAddress
    data['private'] = this.private
    data['active'] = this.active
    data['usable'] = this.usable
    data['autoUpdateContractValue'] = this.autoUpdateContractValue
    data['leed'] = this.leed
    data['clientLocation'] = this.clientLocation
    data['insuranceType'] = this.insuranceType
    data['rfiTotalCount'] = this.rfiTotalCount
    data['rfiClosedCount'] = this.rfiClosedCount
    data['rfiPendingCount'] = this.rfiPendingCount
    data['smallBusinessStory'] = this.smallBusinessStory
    data['clientContractProjectNumber'] = this.clientContractProjectNumber
    data['clientNotes'] = this.clientNotes
    data['doNotUse'] = this.doNotUse
    data['hbcuMinorityGoalsAchieved'] = this.hbcuMinorityGoalsAchieved
    data['hbcuMinorityGoalsPercent'] = this.hbcuMinorityGoalsPercent
    data[
      'hubZoneSmallBusinessGoalsAchieved'
    ] = this.hubZoneSmallBusinessGoalsAchieved
    data[
      'hubZoneSmallBusinessGoalsPercent'
    ] = this.hubZoneSmallBusinessGoalsPercent
    data['largeBusinessConcernsAchieved'] = this.largeBusinessConcernsAchieved
    data['largeBusinessConcernsPercent'] = this.largeBusinessConcernsPercent
    data['davSmallBusinessGoalsAchieved'] = this.davSmallBusinessGoalsAchieved
    data['davSmallBusinessGoalsPercent'] = this.davSmallBusinessGoalsPercent
    data['smallBusinessConcernsAchieved'] = this.smallBusinessConcernsAchieved
    data['smallBusinessConcernsPercent'] = this.smallBusinessConcernsPercent
    data[
      'smallDisadvantagedBusinessGoalsAchieved'
    ] = this.smallDisadvantagedBusinessGoalsAchieved
    data[
      'smallDisadvantagedBusinessGoalsPercent'
    ] = this.smallDisadvantagedBusinessGoalsPercent
    data[
      'veteranOwnedSmallBusinessGoalsAchieved'
    ] = this.veteranOwnedSmallBusinessGoalsAchieved
    data[
      'veteranOwnedSmallBusinessGoalsPercent'
    ] = this.veteranOwnedSmallBusinessGoalsPercent
    data[
      'womenOwnedSmallBusinessGoalsAchieved'
    ] = this.womenOwnedSmallBusinessGoalsAchieved
    data[
      'womenOwnedSmallBusinessGoalsPercent'
    ] = this.womenOwnedSmallBusinessGoalsPercent
    data[
      'minorityOwnedSmallBusinessGoalsAchieved'
    ] = this.minorityOwnedSmallBusinessGoalsAchieved
    data[
      'minorityOwnedSmallBusinessGoalsPercent'
    ] = this.minorityOwnedSmallBusinessGoalsPercent
    data['jointVenture'] = this.jointVenture
    data['numBeds'] = this.numBeds
    data['numHousingUnits'] = this.numHousingUnits
    data['numFloors'] = this.numFloors
    data['numKeysHotel'] = this.numKeysHotel
    data['numParkingSpaces'] = this.numParkingSpaces
    data['parkingGarageSquareFootage'] = this.parkingGarageSquareFootage
    data['nonHenselPhelpsProject'] = this.nonHenselPhelpsProject
    data['resumeDescription'] = this.resumeDescription
    data['detailedDescription'] = this.detailedDescription
    data['designAspectsDescription'] = this.designAspectsDescription
    data['technicalAspectsDescription'] = this.technicalAspectsDescription
    data['notes'] = this.notes
    data[
      'transitionalServicesDescription'
    ] = this.transitionalServicesDescription
    data[
      'operationsAndMaintenanceDescription'
    ] = this.operationsAndMaintenanceDescription
    data['facilityManagementDescription'] = this.facilityManagementDescription
    data['specialProjectsDescription'] = this.specialProjectsDescription
    data['designPreconScheduledStartDate'] = this.designPreconScheduledStartDate
      ? this.designPreconScheduledStartDate.toISOString()
      : <any>undefined
    data['designPreconActualStartDate'] = this.designPreconActualStartDate
      ? this.designPreconActualStartDate.toISOString()
      : <any>undefined
    data['designPreconScheduledCompletionDate'] = this
      .designPreconScheduledCompletionDate
      ? this.designPreconScheduledCompletionDate.toISOString()
      : <any>undefined
    data['designPreconActualCompletionDate'] = this
      .designPreconActualCompletionDate
      ? this.designPreconActualCompletionDate.toISOString()
      : <any>undefined
    data['constructionScheduledStartDate'] = this.constructionScheduledStartDate
      ? this.constructionScheduledStartDate.toISOString()
      : <any>undefined
    data['constructionActualStartDate'] = this.constructionActualStartDate
      ? this.constructionActualStartDate.toISOString()
      : <any>undefined
    data['constructionScheduledSubstantialCompletionDate'] = this
      .constructionScheduledSubstantialCompletionDate
      ? this.constructionScheduledSubstantialCompletionDate.toISOString()
      : <any>undefined
    data['constructionModifiedSubstantialCompletionDate'] = this
      .constructionModifiedSubstantialCompletionDate
      ? this.constructionModifiedSubstantialCompletionDate.toISOString()
      : <any>undefined
    data['constructionActualSubstantialCompletionDate'] = this
      .constructionActualSubstantialCompletionDate
      ? this.constructionActualSubstantialCompletionDate.toISOString()
      : <any>undefined
    data['scheduleDifferenceReasons'] = this.scheduleDifferenceReasons
    data['preconInContract'] = this.preconInContract
    data['preconCost'] = this.preconCost
    data['preconCostModified'] = this.preconCostModified
    data[
      'originalConstructionContractValue'
    ] = this.originalConstructionContractValue
    data['finalConstructionContractValue'] = this.finalConstructionContractValue
    data['confidential'] = this.confidential
    data['safetyManHoursWorked'] = this.safetyManHoursWorked
    data['safetyEmr'] = this.safetyEmr
    data['numLostTimeAccidents'] = this.numLostTimeAccidents
    data['numOshaRecordables'] = this.numOshaRecordables
    data['dartRate'] = this.dartRate
    data['selfWorkNotes'] = this.selfWorkNotes
    data['selfWorkPercent'] = this.selfWorkPercent
    data['selfWorkType'] = this.selfWorkType
    data['leedStatus'] = this.leedStatus
    data['leedVersion'] = this.leedVersion
    data['leedRating'] = this.leedRating
    data['finalCparsRating'] = this.finalCparsRating
    data['valueEngineering'] = this.valueEngineering
    data['quality'] = this.quality
    data['bimUsage'] = this.bimUsage
    data['collaborativeEfforts'] = this.collaborativeEfforts
    data['preconEfforts'] = this.preconEfforts
    data['commissioningEfforts'] = this.commissioningEfforts
    data['lessonsLearned'] = this.lessonsLearned
    data['projectInnovations'] = this.projectInnovations
    data['proposalDate'] = this.proposalDate
      ? this.proposalDate.toISOString()
      : <any>undefined
    data['awardDate'] = this.awardDate
      ? this.awardDate.toISOString()
      : <any>undefined
    data['standardChangeOrderCount'] = this.standardChangeOrderCount
    data['standardChangeOrderValue'] = this.standardChangeOrderValue
    data['gmpChangeOrderCount'] = this.gmpChangeOrderCount
    data['gmpChangeOrderValue'] = this.gmpChangeOrderValue
    data['preconChangeOrderCount'] = this.preconChangeOrderCount
    data['preconChangeOrderValue'] = this.preconChangeOrderValue
    data['totalChangeOrderValue'] = this.totalChangeOrderValue
    data['totalChangeOrderCount'] = this.totalChangeOrderCount
    data['changeOrderReasons'] = this.changeOrderReasons
    data['warrantyExpirationDate'] = this.warrantyExpirationDate
      ? this.warrantyExpirationDate.toISOString()
      : <any>undefined
    data['ownerTotalDevelopmentCost'] = this.ownerTotalDevelopmentCost
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['primaryImageUrl'] = this.primaryImageUrl
    data['overallSiteAcreage'] = this.overallSiteAcreage
    data['numFloorsBelowGrade'] = this.numFloorsBelowGrade
    data['numOnGradeParkingSpaces'] = this.numOnGradeParkingSpaces
    data[
      'centralUtilityPlantSquareFootage'
    ] = this.centralUtilityPlantSquareFootage
    data['dataCenterTierLevel'] = this.dataCenterTierLevel
    data['totalBuildMegawatts'] = this.totalBuildMegawatts
    data['scifSquareFootage'] = this.scifSquareFootage
    data['atfpLevel'] = this.atfpLevel
    data['safetyNarrative'] = this.safetyNarrative
    data['community'] = this.community
    data['quotes'] = this.quotes
    data['securityNarrative'] = this.securityNarrative
    data['programId'] = this.programId
    data['programName'] = this.programName
    data['programDescription'] = this.programDescription
    data['programContractValue'] = this.programContractValue
    data['programSquareFootage'] = this.programSquareFootage
    data['technologyInternalNarrative'] = this.technologyInternalNarrative
    data['technologyExternalNarrative'] = this.technologyExternalNarrative
    data['sustainabilityNarrative'] = this.sustainabilityNarrative
    data[
      'smallBusinessParticipationNarrative'
    ] = this.smallBusinessParticipationNarrative
    data[
      'smallBusinessParticipationEnabled'
    ] = this.smallBusinessParticipationEnabled
    data['communityEngagementNarrative'] = this.communityEngagementNarrative
    data['communityEngagementEnabled'] = this.communityEngagementEnabled
    data['workforceDevelopmentNarrative'] = this.workforceDevelopmentNarrative
    data['workforceDevelopmentEnabled'] = this.workforceDevelopmentEnabled
    data['supplierDiversityNotes'] = this.supplierDiversityNotes
    data['finalPaymentDate'] = this.finalPaymentDate
      ? this.finalPaymentDate.toISOString()
      : <any>undefined
    data['servicesFteCount'] = this.servicesFteCount
    data['servicesBusinessUnit'] = this.servicesBusinessUnit
    data['groundbreakingDate'] = this.groundbreakingDate
      ? this.groundbreakingDate.toISOString()
      : <any>undefined
    data['toppingOutDate'] = this.toppingOutDate
      ? this.toppingOutDate.toISOString()
      : <any>undefined
    data['buildingEnclosedDate'] = this.buildingEnclosedDate
      ? this.buildingEnclosedDate.toISOString()
      : <any>undefined
    data['ribbonCuttingDate'] = this.ribbonCuttingDate
      ? this.ribbonCuttingDate.toISOString()
      : <any>undefined
    data['linearFeet'] = this.linearFeet
    data['millionGallonsPerDay'] = this.millionGallonsPerDay
    data['includeSquareFootageInProgram'] = this.includeSquareFootageInProgram
    data['includeContractValueInProgram'] = this.includeContractValueInProgram
    return data
  }
}

export interface IApiProject {
  secondaryCategory?: string[] | undefined
  constructionType?: string[] | undefined
  clientVerticalMarkets?: string[] | undefined
  references?: ApiProjectReference[] | undefined
  stakeholders?: ApiProjectStakeholder[] | undefined
  challengesAndSolutions?: ProjectChallengeSolution[] | undefined
  awards?: ProjectAward[] | undefined
  personHistory?: ApiPersonHistory[] | undefined
  attachments?: AthenaFileMetadata[] | undefined
  approvals?: ProjectApproval[] | undefined
  projectNumbers?: string[] | undefined
  programAssociatedProjects?: ProgramProject[] | undefined
  technologyPhases?: ApiProjectTechnologyPhase[] | undefined
  sustainabilityCertifications?:
    | ApiProjectSustainabilityCertification[]
    | undefined
  supplierDiversityGoals?: ApiSupplierDiversityGoal[] | undefined
  vdcTechnologyPhases?: ApiProjectVdcTechnologyPhase[] | undefined
  assets?: Assets | undefined
  thumbnail?: string | undefined
  districts?: string[] | undefined
  recordAndRelatedModifiedOn?: Date | undefined
  recordAndRelatedModifiedBy?: string | undefined
  webdamFolderId?: number | undefined
  programApproval?: boolean | undefined
  programApprovalModifiedBy?: string | undefined
  programApprovalModifiedOn?: Date | undefined
  id?: string | undefined
  name?: string | undefined
  displayName?: string | undefined
  number?: string | undefined
  verticalMarket?: string | undefined
  squareFootage?: number | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  cost?: number | undefined
  clientType?: string | undefined
  deliveryMethod?: string | undefined
  contractType?: string | undefined
  procurementMethod?: string | undefined
  country?: string | undefined
  completionDate?: Date | undefined
  scheduledCompletionDate?: Date | undefined
  startDate?: Date | undefined
  standardDescription?: string | undefined
  clientName?: string | undefined
  streetAddress?: string | undefined
  private?: boolean | undefined
  active?: boolean | undefined
  usable?: boolean | undefined
  autoUpdateContractValue?: boolean | undefined
  leed?: boolean | undefined
  clientLocation?: string | undefined
  insuranceType?: string | undefined
  rfiTotalCount?: number | undefined
  rfiClosedCount?: number | undefined
  rfiPendingCount?: number | undefined
  smallBusinessStory?: string | undefined
  clientContractProjectNumber?: string | undefined
  clientNotes?: string | undefined
  doNotUse?: boolean | undefined
  hbcuMinorityGoalsAchieved?: number | undefined
  hbcuMinorityGoalsPercent?: number | undefined
  hubZoneSmallBusinessGoalsAchieved?: number | undefined
  hubZoneSmallBusinessGoalsPercent?: number | undefined
  largeBusinessConcernsAchieved?: number | undefined
  largeBusinessConcernsPercent?: number | undefined
  davSmallBusinessGoalsAchieved?: number | undefined
  davSmallBusinessGoalsPercent?: number | undefined
  smallBusinessConcernsAchieved?: number | undefined
  smallBusinessConcernsPercent?: number | undefined
  smallDisadvantagedBusinessGoalsAchieved?: number | undefined
  smallDisadvantagedBusinessGoalsPercent?: number | undefined
  veteranOwnedSmallBusinessGoalsAchieved?: number | undefined
  veteranOwnedSmallBusinessGoalsPercent?: number | undefined
  womenOwnedSmallBusinessGoalsAchieved?: number | undefined
  womenOwnedSmallBusinessGoalsPercent?: number | undefined
  minorityOwnedSmallBusinessGoalsAchieved?: number | undefined
  minorityOwnedSmallBusinessGoalsPercent?: number | undefined
  jointVenture?: boolean | undefined
  numBeds?: number | undefined
  numHousingUnits?: number | undefined
  numFloors?: number | undefined
  numKeysHotel?: number | undefined
  numParkingSpaces?: number | undefined
  parkingGarageSquareFootage?: number | undefined
  nonHenselPhelpsProject?: boolean | undefined
  resumeDescription?: string | undefined
  detailedDescription?: string | undefined
  designAspectsDescription?: string | undefined
  technicalAspectsDescription?: string | undefined
  notes?: string | undefined
  transitionalServicesDescription?: string | undefined
  operationsAndMaintenanceDescription?: string | undefined
  facilityManagementDescription?: string | undefined
  specialProjectsDescription?: string | undefined
  designPreconScheduledStartDate?: Date | undefined
  designPreconActualStartDate?: Date | undefined
  designPreconScheduledCompletionDate?: Date | undefined
  designPreconActualCompletionDate?: Date | undefined
  constructionScheduledStartDate?: Date | undefined
  constructionActualStartDate?: Date | undefined
  constructionScheduledSubstantialCompletionDate?: Date | undefined
  constructionModifiedSubstantialCompletionDate?: Date | undefined
  constructionActualSubstantialCompletionDate?: Date | undefined
  scheduleDifferenceReasons?: string | undefined
  preconInContract?: string | undefined
  preconCost?: number | undefined
  preconCostModified?: number | undefined
  originalConstructionContractValue?: number | undefined
  finalConstructionContractValue?: number | undefined
  confidential?: boolean | undefined
  safetyManHoursWorked?: number | undefined
  safetyEmr?: string | undefined
  numLostTimeAccidents?: number | undefined
  numOshaRecordables?: number | undefined
  dartRate?: number | undefined
  selfWorkNotes?: string | undefined
  selfWorkPercent?: number | undefined
  selfWorkType?: string | undefined
  leedStatus?: string | undefined
  leedVersion?: string | undefined
  leedRating?: string | undefined
  finalCparsRating?: string | undefined
  valueEngineering?: string | undefined
  quality?: string | undefined
  bimUsage?: string | undefined
  collaborativeEfforts?: string | undefined
  preconEfforts?: string | undefined
  commissioningEfforts?: string | undefined
  lessonsLearned?: string | undefined
  projectInnovations?: string | undefined
  proposalDate?: Date | undefined
  awardDate?: Date | undefined
  standardChangeOrderCount?: number | undefined
  standardChangeOrderValue?: number | undefined
  gmpChangeOrderCount?: number | undefined
  gmpChangeOrderValue?: number | undefined
  preconChangeOrderCount?: number | undefined
  preconChangeOrderValue?: number | undefined
  totalChangeOrderValue?: number | undefined
  totalChangeOrderCount?: number | undefined
  changeOrderReasons?: string | undefined
  warrantyExpirationDate?: Date | undefined
  ownerTotalDevelopmentCost?: number | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  primaryImageUrl?: string | undefined
  overallSiteAcreage?: number | undefined
  numFloorsBelowGrade?: number | undefined
  numOnGradeParkingSpaces?: number | undefined
  centralUtilityPlantSquareFootage?: number | undefined
  dataCenterTierLevel?: string | undefined
  totalBuildMegawatts?: number | undefined
  scifSquareFootage?: number | undefined
  atfpLevel?: string | undefined
  safetyNarrative?: string | undefined
  community?: string | undefined
  quotes?: string | undefined
  securityNarrative?: string | undefined
  programId?: string | undefined
  programName?: string | undefined
  programDescription?: string | undefined
  programContractValue?: number | undefined
  programSquareFootage?: number | undefined
  technologyInternalNarrative?: string | undefined
  technologyExternalNarrative?: string | undefined
  sustainabilityNarrative?: string | undefined
  smallBusinessParticipationNarrative?: string | undefined
  smallBusinessParticipationEnabled?: boolean | undefined
  communityEngagementNarrative?: string | undefined
  communityEngagementEnabled?: boolean | undefined
  workforceDevelopmentNarrative?: string | undefined
  workforceDevelopmentEnabled?: boolean | undefined
  supplierDiversityNotes?: string | undefined
  finalPaymentDate?: Date | undefined
  servicesFteCount?: number | undefined
  servicesBusinessUnit?: string | undefined
  groundbreakingDate?: Date | undefined
  toppingOutDate?: Date | undefined
  buildingEnclosedDate?: Date | undefined
  ribbonCuttingDate?: Date | undefined
  linearFeet?: number | undefined
  millionGallonsPerDay?: number | undefined
  includeSquareFootageInProgram?: boolean | undefined
  includeContractValueInProgram?: boolean | undefined
}

export class ApiProjectReference implements IApiProjectReference {
  contactId?: string | undefined
  projectId?: string | undefined
  isManagedByCrm?: boolean | undefined
  companyOnProject?: string | undefined
  roleOnProject?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  approvedForUse?: boolean | undefined
  approvedForUseBy?: string | undefined
  approvedForUseOn?: Date | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  middleName?: string | undefined
  companyId?: string | undefined
  email2?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmId?: string | undefined
  isActive?: boolean | undefined
  company?: string | undefined
  emailAddress?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  officePhone?: string | undefined
  quoteCommendation?: string | undefined
  referenceType?: string | undefined
  title?: string | undefined

  constructor(data?: IApiProjectReference) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.contactId = data['contactId']
      this.projectId = data['projectId']
      this.isManagedByCrm = data['isManagedByCrm']
      this.companyOnProject = data['companyOnProject']
      this.roleOnProject = data['roleOnProject']
      this.comments = data['comments']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.approvedForUse = data['approvedForUse']
      this.approvedForUseBy = data['approvedForUseBy']
      this.approvedForUseOn = data['approvedForUseOn']
        ? new Date(data['approvedForUseOn'].toString())
        : <any>undefined
      this.salutation = data['salutation']
      this.fullName = data['fullName']
      this.middleName = data['middleName']
      this.companyId = data['companyId']
      this.email2 = data['email2']
      this.mobilePhone = data['mobilePhone']
      this.homePhone = data['homePhone']
      this.streetAddress = data['streetAddress']
      this.streetAddress2 = data['streetAddress2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmId = data['crmId']
      this.isActive = data['isActive']
      this.company = data['company']
      this.emailAddress = data['emailAddress']
      this.firstName = data['firstName']
      this.lastName = data['lastName']
      this.jobTitle = data['jobTitle']
      this.officePhone = data['officePhone']
      this.quoteCommendation = data['quoteCommendation']
      this.referenceType = data['referenceType']
      this.title = data['title']
    }
  }

  static fromJS(data: any): ApiProjectReference {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProjectReference()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['contactId'] = this.contactId
    data['projectId'] = this.projectId
    data['isManagedByCrm'] = this.isManagedByCrm
    data['companyOnProject'] = this.companyOnProject
    data['roleOnProject'] = this.roleOnProject
    data['comments'] = this.comments
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['approvedForUse'] = this.approvedForUse
    data['approvedForUseBy'] = this.approvedForUseBy
    data['approvedForUseOn'] = this.approvedForUseOn
      ? this.approvedForUseOn.toISOString()
      : <any>undefined
    data['salutation'] = this.salutation
    data['fullName'] = this.fullName
    data['middleName'] = this.middleName
    data['companyId'] = this.companyId
    data['email2'] = this.email2
    data['mobilePhone'] = this.mobilePhone
    data['homePhone'] = this.homePhone
    data['streetAddress'] = this.streetAddress
    data['streetAddress2'] = this.streetAddress2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmId'] = this.crmId
    data['isActive'] = this.isActive
    data['company'] = this.company
    data['emailAddress'] = this.emailAddress
    data['firstName'] = this.firstName
    data['lastName'] = this.lastName
    data['jobTitle'] = this.jobTitle
    data['officePhone'] = this.officePhone
    data['quoteCommendation'] = this.quoteCommendation
    data['referenceType'] = this.referenceType
    data['title'] = this.title
    return data
  }
}

export interface IApiProjectReference {
  contactId?: string | undefined
  projectId?: string | undefined
  isManagedByCrm?: boolean | undefined
  companyOnProject?: string | undefined
  roleOnProject?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  approvedForUse?: boolean | undefined
  approvedForUseBy?: string | undefined
  approvedForUseOn?: Date | undefined
  salutation?: string | undefined
  fullName?: string | undefined
  middleName?: string | undefined
  companyId?: string | undefined
  email2?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  streetAddress?: string | undefined
  streetAddress2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmId?: string | undefined
  isActive?: boolean | undefined
  company?: string | undefined
  emailAddress?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  jobTitle?: string | undefined
  officePhone?: string | undefined
  quoteCommendation?: string | undefined
  referenceType?: string | undefined
  title?: string | undefined
}

export class ApiProjectStakeholder implements IApiProjectStakeholder {
  companyId?: string | undefined
  isManagedByCrm?: boolean | undefined
  projectId?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  acronyms?: string | undefined
  otherPhone?: string | undefined
  primaryContactId?: string | undefined
  primaryContactName?: string | undefined
  parentCompanyId?: string | undefined
  parentCompanyName?: string | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmId?: string | undefined
  isActive?: boolean | undefined
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined
  name?: string | undefined
  address?: string | undefined
  address2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  comments?: string | undefined
  website?: string | undefined
  role?: string | undefined
  commentsOnProject?: string | undefined
  subcontractorType?: string | undefined
  businessPhone?: string | undefined
  displayAddress?: string | undefined

  constructor(data?: IApiProjectStakeholder) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.companyId = data['companyId']
      this.isManagedByCrm = data['isManagedByCrm']
      this.projectId = data['projectId']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.acronyms = data['acronyms']
      this.otherPhone = data['otherPhone']
      this.primaryContactId = data['primaryContactId']
      this.primaryContactName = data['primaryContactName']
      this.parentCompanyId = data['parentCompanyId']
      this.parentCompanyName = data['parentCompanyName']
      this.crmModifiedOn = data['crmModifiedOn']
        ? new Date(data['crmModifiedOn'].toString())
        : <any>undefined
      this.crmModifiedBy = data['crmModifiedBy']
      this.crmId = data['crmId']
      this.isActive = data['isActive']
      if (Array.isArray(data['businessRoles'])) {
        this.businessRoles = [] as any
        for (let item of data['businessRoles']) this.businessRoles!.push(item)
      }
      if (Array.isArray(data['businessLines'])) {
        this.businessLines = [] as any
        for (let item of data['businessLines']) this.businessLines!.push(item)
      }
      if (Array.isArray(data['districts'])) {
        this.districts = [] as any
        for (let item of data['districts']) this.districts!.push(item)
      }
      if (Array.isArray(data['verticalMarkets'])) {
        this.verticalMarkets = [] as any
        for (let item of data['verticalMarkets'])
          this.verticalMarkets!.push(item)
      }
      this.name = data['name']
      this.address = data['address']
      this.address2 = data['address2']
      this.city = data['city']
      this.state = data['state']
      this.zip = data['zip']
      this.country = data['country']
      this.comments = data['comments']
      this.website = data['website']
      this.role = data['role']
      this.commentsOnProject = data['commentsOnProject']
      this.subcontractorType = data['subcontractorType']
      this.businessPhone = data['businessPhone']
      this.displayAddress = data['displayAddress']
    }
  }

  static fromJS(data: any): ApiProjectStakeholder {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProjectStakeholder()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['companyId'] = this.companyId
    data['isManagedByCrm'] = this.isManagedByCrm
    data['projectId'] = this.projectId
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['acronyms'] = this.acronyms
    data['otherPhone'] = this.otherPhone
    data['primaryContactId'] = this.primaryContactId
    data['primaryContactName'] = this.primaryContactName
    data['parentCompanyId'] = this.parentCompanyId
    data['parentCompanyName'] = this.parentCompanyName
    data['crmModifiedOn'] = this.crmModifiedOn
      ? this.crmModifiedOn.toISOString()
      : <any>undefined
    data['crmModifiedBy'] = this.crmModifiedBy
    data['crmId'] = this.crmId
    data['isActive'] = this.isActive
    if (Array.isArray(this.businessRoles)) {
      data['businessRoles'] = []
      for (let item of this.businessRoles) data['businessRoles'].push(item)
    }
    if (Array.isArray(this.businessLines)) {
      data['businessLines'] = []
      for (let item of this.businessLines) data['businessLines'].push(item)
    }
    if (Array.isArray(this.districts)) {
      data['districts'] = []
      for (let item of this.districts) data['districts'].push(item)
    }
    if (Array.isArray(this.verticalMarkets)) {
      data['verticalMarkets'] = []
      for (let item of this.verticalMarkets) data['verticalMarkets'].push(item)
    }
    data['name'] = this.name
    data['address'] = this.address
    data['address2'] = this.address2
    data['city'] = this.city
    data['state'] = this.state
    data['zip'] = this.zip
    data['country'] = this.country
    data['comments'] = this.comments
    data['website'] = this.website
    data['role'] = this.role
    data['commentsOnProject'] = this.commentsOnProject
    data['subcontractorType'] = this.subcontractorType
    data['businessPhone'] = this.businessPhone
    data['displayAddress'] = this.displayAddress
    return data
  }
}

export interface IApiProjectStakeholder {
  companyId?: string | undefined
  isManagedByCrm?: boolean | undefined
  projectId?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  acronyms?: string | undefined
  otherPhone?: string | undefined
  primaryContactId?: string | undefined
  primaryContactName?: string | undefined
  parentCompanyId?: string | undefined
  parentCompanyName?: string | undefined
  crmModifiedOn?: Date | undefined
  crmModifiedBy?: string | undefined
  crmId?: string | undefined
  isActive?: boolean | undefined
  businessRoles?: string[] | undefined
  businessLines?: string[] | undefined
  districts?: string[] | undefined
  verticalMarkets?: string[] | undefined
  name?: string | undefined
  address?: string | undefined
  address2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  zip?: string | undefined
  country?: string | undefined
  comments?: string | undefined
  website?: string | undefined
  role?: string | undefined
  commentsOnProject?: string | undefined
  subcontractorType?: string | undefined
  businessPhone?: string | undefined
  displayAddress?: string | undefined
}

export class ApiPersonHistory implements IApiPersonHistory {
  personGuid?: string | undefined
  personId?: number | undefined
  personTitleOnProject?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  name?: string | undefined
  currentTitle?: string | undefined
  city?: string | undefined
  state?: string | undefined
  district?: string | undefined
  industryStartYear?: number | undefined
  hireDate?: Date | undefined
  terminationDate?: Date | undefined
  active?: boolean | undefined
  thumbnail?: string | undefined

  constructor(data?: IApiPersonHistory) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.personGuid = data['personGuid']
      this.personId = data['personId']
      this.personTitleOnProject = data['personTitleOnProject']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.name = data['name']
      this.currentTitle = data['currentTitle']
      this.city = data['city']
      this.state = data['state']
      this.district = data['district']
      this.industryStartYear = data['industryStartYear']
      this.hireDate = data['hireDate']
        ? new Date(data['hireDate'].toString())
        : <any>undefined
      this.terminationDate = data['terminationDate']
        ? new Date(data['terminationDate'].toString())
        : <any>undefined
      this.active = data['active']
      this.thumbnail = data['thumbnail']
    }
  }

  static fromJS(data: any): ApiPersonHistory {
    data = typeof data === 'object' ? data : {}
    let result = new ApiPersonHistory()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['personGuid'] = this.personGuid
    data['personId'] = this.personId
    data['personTitleOnProject'] = this.personTitleOnProject
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['name'] = this.name
    data['currentTitle'] = this.currentTitle
    data['city'] = this.city
    data['state'] = this.state
    data['district'] = this.district
    data['industryStartYear'] = this.industryStartYear
    data['hireDate'] = this.hireDate
      ? this.hireDate.toISOString()
      : <any>undefined
    data['terminationDate'] = this.terminationDate
      ? this.terminationDate.toISOString()
      : <any>undefined
    data['active'] = this.active
    data['thumbnail'] = this.thumbnail
    return data
  }
}

export interface IApiPersonHistory {
  personGuid?: string | undefined
  personId?: number | undefined
  personTitleOnProject?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  name?: string | undefined
  currentTitle?: string | undefined
  city?: string | undefined
  state?: string | undefined
  district?: string | undefined
  industryStartYear?: number | undefined
  hireDate?: Date | undefined
  terminationDate?: Date | undefined
  active?: boolean | undefined
  thumbnail?: string | undefined
}

export class ProjectApproval implements IProjectApproval {
  approvalKey?: string | undefined
  isApproved?: boolean | undefined
  modifiedBy?: string | undefined
  modifiedOn?: Date | undefined
  fieldModifiedBy?: string | undefined
  fieldModifiedOn?: Date | undefined
  id?: string | undefined

  constructor(data?: IProjectApproval) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.approvalKey = data['approvalKey']
      this.isApproved = data['isApproved']
      this.modifiedBy = data['modifiedBy']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.fieldModifiedBy = data['fieldModifiedBy']
      this.fieldModifiedOn = data['fieldModifiedOn']
        ? new Date(data['fieldModifiedOn'].toString())
        : <any>undefined
      this.id = data['id']
    }
  }

  static fromJS(data: any): ProjectApproval {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectApproval()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['approvalKey'] = this.approvalKey
    data['isApproved'] = this.isApproved
    data['modifiedBy'] = this.modifiedBy
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['fieldModifiedBy'] = this.fieldModifiedBy
    data['fieldModifiedOn'] = this.fieldModifiedOn
      ? this.fieldModifiedOn.toISOString()
      : <any>undefined
    data['id'] = this.id
    return data
  }
}

export interface IProjectApproval {
  approvalKey?: string | undefined
  isApproved?: boolean | undefined
  modifiedBy?: string | undefined
  modifiedOn?: Date | undefined
  fieldModifiedBy?: string | undefined
  fieldModifiedOn?: Date | undefined
  id?: string | undefined
}

export class ApiProjectTechnologyPhase implements IApiProjectTechnologyPhase {
  id?: string | undefined
  projectId?: string | undefined
  phaseLabel?: string | undefined
  workHours?: number | undefined
  implementationCost?: number | undefined
  narrative?: string | undefined
  approval?: boolean | undefined
  approvalModifiedBy?: string | undefined
  approvalModifiedOn?: Date | undefined
  technologies?: ApiTechnology[] | undefined

  constructor(data?: IApiProjectTechnologyPhase) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.projectId = data['projectId']
      this.phaseLabel = data['phaseLabel']
      this.workHours = data['workHours']
      this.implementationCost = data['implementationCost']
      this.narrative = data['narrative']
      this.approval = data['approval']
      this.approvalModifiedBy = data['approvalModifiedBy']
      this.approvalModifiedOn = data['approvalModifiedOn']
        ? new Date(data['approvalModifiedOn'].toString())
        : <any>undefined
      if (Array.isArray(data['technologies'])) {
        this.technologies = [] as any
        for (let item of data['technologies'])
          this.technologies!.push(ApiTechnology.fromJS(item))
      }
    }
  }

  static fromJS(data: any): ApiProjectTechnologyPhase {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProjectTechnologyPhase()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['projectId'] = this.projectId
    data['phaseLabel'] = this.phaseLabel
    data['workHours'] = this.workHours
    data['implementationCost'] = this.implementationCost
    data['narrative'] = this.narrative
    data['approval'] = this.approval
    data['approvalModifiedBy'] = this.approvalModifiedBy
    data['approvalModifiedOn'] = this.approvalModifiedOn
      ? this.approvalModifiedOn.toISOString()
      : <any>undefined
    if (Array.isArray(this.technologies)) {
      data['technologies'] = []
      for (let item of this.technologies)
        data['technologies'].push(item.toJSON())
    }
    return data
  }
}

export interface IApiProjectTechnologyPhase {
  id?: string | undefined
  projectId?: string | undefined
  phaseLabel?: string | undefined
  workHours?: number | undefined
  implementationCost?: number | undefined
  narrative?: string | undefined
  approval?: boolean | undefined
  approvalModifiedBy?: string | undefined
  approvalModifiedOn?: Date | undefined
  technologies?: ApiTechnology[] | undefined
}

export class ApiProjectSustainabilityCertification
  implements IApiProjectSustainabilityCertification {
  id?: string | undefined
  name?: string | undefined
  certificationId?: string | undefined
  version?: string | undefined
  versionId?: string | undefined
  level?: string | undefined
  levelId?: string | undefined
  type?: string | undefined
  typeId?: string | undefined
  status?: string | undefined
  awardDate?: Date | undefined
  assetsUploaded?: string | undefined
  notes?: string | undefined

  constructor(data?: IApiProjectSustainabilityCertification) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.certificationId = data['certificationId']
      this.version = data['version']
      this.versionId = data['versionId']
      this.level = data['level']
      this.levelId = data['levelId']
      this.type = data['type']
      this.typeId = data['typeId']
      this.status = data['status']
      this.awardDate = data['awardDate']
        ? new Date(data['awardDate'].toString())
        : <any>undefined
      this.assetsUploaded = data['assetsUploaded']
      this.notes = data['notes']
    }
  }

  static fromJS(data: any): ApiProjectSustainabilityCertification {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProjectSustainabilityCertification()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['certificationId'] = this.certificationId
    data['version'] = this.version
    data['versionId'] = this.versionId
    data['level'] = this.level
    data['levelId'] = this.levelId
    data['type'] = this.type
    data['typeId'] = this.typeId
    data['status'] = this.status
    data['awardDate'] = this.awardDate
      ? this.awardDate.toISOString()
      : <any>undefined
    data['assetsUploaded'] = this.assetsUploaded
    data['notes'] = this.notes
    return data
  }
}

export interface IApiProjectSustainabilityCertification {
  id?: string | undefined
  name?: string | undefined
  certificationId?: string | undefined
  version?: string | undefined
  versionId?: string | undefined
  level?: string | undefined
  levelId?: string | undefined
  type?: string | undefined
  typeId?: string | undefined
  status?: string | undefined
  awardDate?: Date | undefined
  assetsUploaded?: string | undefined
  notes?: string | undefined
}

export class ApiSupplierDiversityGoal implements IApiSupplierDiversityGoal {
  classificationId?: string | undefined
  classificationName?: string | undefined
  goalDollars?: number | undefined
  achievedDollars?: number | undefined
  goalPercent?: number | undefined
  achievedPercent?: number | undefined
  inContract?: boolean | undefined
  percentContext?: string | undefined

  constructor(data?: IApiSupplierDiversityGoal) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.classificationId = data['classificationId']
      this.classificationName = data['classificationName']
      this.goalDollars = data['goalDollars']
      this.achievedDollars = data['achievedDollars']
      this.goalPercent = data['goalPercent']
      this.achievedPercent = data['achievedPercent']
      this.inContract = data['inContract']
      this.percentContext = data['percentContext']
    }
  }

  static fromJS(data: any): ApiSupplierDiversityGoal {
    data = typeof data === 'object' ? data : {}
    let result = new ApiSupplierDiversityGoal()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['classificationId'] = this.classificationId
    data['classificationName'] = this.classificationName
    data['goalDollars'] = this.goalDollars
    data['achievedDollars'] = this.achievedDollars
    data['goalPercent'] = this.goalPercent
    data['achievedPercent'] = this.achievedPercent
    data['inContract'] = this.inContract
    data['percentContext'] = this.percentContext
    return data
  }
}

export interface IApiSupplierDiversityGoal {
  classificationId?: string | undefined
  classificationName?: string | undefined
  goalDollars?: number | undefined
  achievedDollars?: number | undefined
  goalPercent?: number | undefined
  achievedPercent?: number | undefined
  inContract?: boolean | undefined
  percentContext?: string | undefined
}

export class ApiProjectVdcTechnologyPhase
  implements IApiProjectVdcTechnologyPhase {
  id?: string | undefined
  projectId?: string | undefined
  phaseLabel?: string | undefined
  workHours?: number | undefined
  implementationCost?: number | undefined
  narrative?: string | undefined
  approval?: boolean | undefined
  approvalModifiedBy?: string | undefined
  approvalModifiedOn?: Date | undefined
  phaseVdcTechnologies?: ApiPhaseVdcTechnology[] | undefined

  constructor(data?: IApiProjectVdcTechnologyPhase) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.projectId = data['projectId']
      this.phaseLabel = data['phaseLabel']
      this.workHours = data['workHours']
      this.implementationCost = data['implementationCost']
      this.narrative = data['narrative']
      this.approval = data['approval']
      this.approvalModifiedBy = data['approvalModifiedBy']
      this.approvalModifiedOn = data['approvalModifiedOn']
        ? new Date(data['approvalModifiedOn'].toString())
        : <any>undefined
      if (Array.isArray(data['phaseVdcTechnologies'])) {
        this.phaseVdcTechnologies = [] as any
        for (let item of data['phaseVdcTechnologies'])
          this.phaseVdcTechnologies!.push(ApiPhaseVdcTechnology.fromJS(item))
      }
    }
  }

  static fromJS(data: any): ApiProjectVdcTechnologyPhase {
    data = typeof data === 'object' ? data : {}
    let result = new ApiProjectVdcTechnologyPhase()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['projectId'] = this.projectId
    data['phaseLabel'] = this.phaseLabel
    data['workHours'] = this.workHours
    data['implementationCost'] = this.implementationCost
    data['narrative'] = this.narrative
    data['approval'] = this.approval
    data['approvalModifiedBy'] = this.approvalModifiedBy
    data['approvalModifiedOn'] = this.approvalModifiedOn
      ? this.approvalModifiedOn.toISOString()
      : <any>undefined
    if (Array.isArray(this.phaseVdcTechnologies)) {
      data['phaseVdcTechnologies'] = []
      for (let item of this.phaseVdcTechnologies)
        data['phaseVdcTechnologies'].push(item.toJSON())
    }
    return data
  }
}

export interface IApiProjectVdcTechnologyPhase {
  id?: string | undefined
  projectId?: string | undefined
  phaseLabel?: string | undefined
  workHours?: number | undefined
  implementationCost?: number | undefined
  narrative?: string | undefined
  approval?: boolean | undefined
  approvalModifiedBy?: string | undefined
  approvalModifiedOn?: Date | undefined
  phaseVdcTechnologies?: ApiPhaseVdcTechnology[] | undefined
}

export class ApiTechnology implements IApiTechnology {
  id?: string | undefined
  name?: string | undefined
  path?: string | undefined
  parentTechnologyId?: string | undefined
  childTechnologies?: ApiTechnology[] | undefined

  constructor(data?: IApiTechnology) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.path = data['path']
      this.parentTechnologyId = data['parentTechnologyId']
      if (Array.isArray(data['childTechnologies'])) {
        this.childTechnologies = [] as any
        for (let item of data['childTechnologies'])
          this.childTechnologies!.push(ApiTechnology.fromJS(item))
      }
    }
  }

  static fromJS(data: any): ApiTechnology {
    data = typeof data === 'object' ? data : {}
    let result = new ApiTechnology()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['path'] = this.path
    data['parentTechnologyId'] = this.parentTechnologyId
    if (Array.isArray(this.childTechnologies)) {
      data['childTechnologies'] = []
      for (let item of this.childTechnologies)
        data['childTechnologies'].push(item.toJSON())
    }
    return data
  }
}

export interface IApiTechnology {
  id?: string | undefined
  name?: string | undefined
  path?: string | undefined
  parentTechnologyId?: string | undefined
  childTechnologies?: ApiTechnology[] | undefined
}

export class ApiPhaseVdcTechnology implements IApiPhaseVdcTechnology {
  id?: string | undefined
  vdcTechnologyId?: string | undefined
  projectVdcTechnologyPhaseId?: string | undefined
  name?: string | undefined
  path?: string | undefined
  workHours?: number | undefined
  duration?: number | undefined
  cost?: number | undefined
  numPeople?: number | undefined
  companyCategory?: string | undefined
  smeCompany?: string | undefined
  smePosition?: string | undefined
  smeFirstName?: string | undefined
  smeLastName?: string | undefined
  smePhone?: string | undefined
  smeEmail?: string | undefined
  notes?: string | undefined
  starRating?: number | undefined

  constructor(data?: IApiPhaseVdcTechnology) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.vdcTechnologyId = data['vdcTechnologyId']
      this.projectVdcTechnologyPhaseId = data['projectVdcTechnologyPhaseId']
      this.name = data['name']
      this.path = data['path']
      this.workHours = data['workHours']
      this.duration = data['duration']
      this.cost = data['cost']
      this.numPeople = data['numPeople']
      this.companyCategory = data['companyCategory']
      this.smeCompany = data['smeCompany']
      this.smePosition = data['smePosition']
      this.smeFirstName = data['smeFirstName']
      this.smeLastName = data['smeLastName']
      this.smePhone = data['smePhone']
      this.smeEmail = data['smeEmail']
      this.notes = data['notes']
      this.starRating = data['starRating']
    }
  }

  static fromJS(data: any): ApiPhaseVdcTechnology {
    data = typeof data === 'object' ? data : {}
    let result = new ApiPhaseVdcTechnology()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['vdcTechnologyId'] = this.vdcTechnologyId
    data['projectVdcTechnologyPhaseId'] = this.projectVdcTechnologyPhaseId
    data['name'] = this.name
    data['path'] = this.path
    data['workHours'] = this.workHours
    data['duration'] = this.duration
    data['cost'] = this.cost
    data['numPeople'] = this.numPeople
    data['companyCategory'] = this.companyCategory
    data['smeCompany'] = this.smeCompany
    data['smePosition'] = this.smePosition
    data['smeFirstName'] = this.smeFirstName
    data['smeLastName'] = this.smeLastName
    data['smePhone'] = this.smePhone
    data['smeEmail'] = this.smeEmail
    data['notes'] = this.notes
    data['starRating'] = this.starRating
    return data
  }
}

export interface IApiPhaseVdcTechnology {
  id?: string | undefined
  vdcTechnologyId?: string | undefined
  projectVdcTechnologyPhaseId?: string | undefined
  name?: string | undefined
  path?: string | undefined
  workHours?: number | undefined
  duration?: number | undefined
  cost?: number | undefined
  numPeople?: number | undefined
  companyCategory?: string | undefined
  smeCompany?: string | undefined
  smePosition?: string | undefined
  smeFirstName?: string | undefined
  smeLastName?: string | undefined
  smePhone?: string | undefined
  smeEmail?: string | undefined
  notes?: string | undefined
  starRating?: number | undefined
}

export class SearchResultSetOfQueryableProject
  implements ISearchResultSetOfQueryableProject {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryableProject[] | undefined
  nextLink?: string | undefined

  constructor(data?: ISearchResultSetOfQueryableProject) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.count = data['count']
      if (data['facets']) {
        this.facets = {} as any
        for (let key in data['facets']) {
          if (data['facets'].hasOwnProperty(key))
            this.facets![key] = data['facets'][key]
              ? data['facets'][key].map((i: any) => AthenaFacet.fromJS(i))
              : []
        }
      }
      if (Array.isArray(data['value'])) {
        this.value = [] as any
        for (let item of data['value'])
          this.value!.push(QueryableProject.fromJS(item))
      }
      this.nextLink = data['nextLink']
    }
  }

  static fromJS(data: any): SearchResultSetOfQueryableProject {
    data = typeof data === 'object' ? data : {}
    let result = new SearchResultSetOfQueryableProject()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['count'] = this.count
    if (this.facets) {
      data['facets'] = {}
      for (let key in this.facets) {
        if (this.facets.hasOwnProperty(key))
          data['facets'][key] = this.facets[key]
      }
    }
    if (Array.isArray(this.value)) {
      data['value'] = []
      for (let item of this.value) data['value'].push(item.toJSON())
    }
    data['nextLink'] = this.nextLink
    return data
  }
}

export interface ISearchResultSetOfQueryableProject {
  count?: number | undefined
  facets?: { [key: string]: AthenaFacet[] } | undefined
  value?: QueryableProject[] | undefined
  nextLink?: string | undefined
}

export class QueryableProject implements IQueryableProject {
  id?: string | undefined
  name?: string | undefined
  city?: string | undefined
  state?: string | undefined
  country?: string | undefined
  completionDate?: Date | undefined
  cost?: number | undefined
  squareFootage?: number | undefined
  verticalMarket?: string | undefined
  primaryImageUrl?: string | undefined
  thumbnail?: string | undefined
  districts?: string[] | undefined
  displayName?: string | undefined
  number?: string | undefined
  clientType?: string | undefined
  deliveryMethod?: string | undefined
  contractType?: string | undefined
  procurementMethod?: string | undefined
  scheduledCompletionDate?: Date | undefined
  startDate?: Date | undefined
  clientName?: string | undefined
  clientVerticalMarkets?: string[] | undefined
  streetAddress?: string | undefined
  private?: boolean | undefined
  active?: boolean | undefined
  usable?: boolean | undefined
  autoUpdateContractValue?: boolean | undefined
  leed?: boolean | undefined
  clientLocation?: string | undefined
  insuranceType?: string | undefined
  rfiTotalCount?: number | undefined
  clientContractProjectNumber?: string | undefined
  clientNotes?: string | undefined
  doNotUse?: boolean | undefined
  jointVenture?: boolean | undefined
  numBeds?: number | undefined
  numHousingUnits?: number | undefined
  numFloors?: number | undefined
  numKeysHotel?: number | undefined
  numParkingSpaces?: number | undefined
  parkingGarageSquareFootage?: number | undefined
  nonHenselPhelpsProject?: boolean | undefined
  preconInContract?: string | undefined
  preconCost?: number | undefined
  preconCostModified?: number | undefined
  originalConstructionContractValue?: number | undefined
  finalConstructionContractValue?: number | undefined
  confidential?: boolean | undefined
  safetyManHoursWorked?: number | undefined
  safetyEmr?: string | undefined
  numLostTimeAccidents?: number | undefined
  numOshaRecordables?: number | undefined
  dartRate?: number | undefined
  selfWorkPercent?: number | undefined
  selfWorkType?: string | undefined
  leedStatus?: string | undefined
  leedVersion?: string | undefined
  leedRating?: string | undefined
  finalCparsRating?: string | undefined
  proposalDate?: Date | undefined
  awardDate?: Date | undefined
  totalChangeOrderValue?: number | undefined
  totalChangeOrderCount?: number | undefined
  warrantyExpirationDate?: Date | undefined
  ownerTotalDevelopmentCost?: number | undefined
  overallSiteAcreage?: number | undefined
  numFloorsBelowGrade?: number | undefined
  numOnGradeParkingSpaces?: number | undefined
  centralUtilityPlantSquareFootage?: number | undefined
  dataCenterTierLevel?: string | undefined
  totalBuildMegawatts?: number | undefined
  scifSquareFootage?: number | undefined
  atfpLevel?: string | undefined
  safetyNarrative?: string | undefined
  community?: string | undefined
  quotes?: string | undefined
  securityNarrative?: string | undefined
  projectNumbers?: string[] | undefined
  programId?: string | undefined
  programName?: string | undefined
  programSquareFootage?: number | undefined
  programContractValue?: number | undefined
  supplierDiversityGoals?: string[] | undefined
  linearFeet?: number | undefined
  millionGallonsPerDay?: number | undefined

  constructor(data?: IQueryableProject) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.city = data['city']
      this.state = data['state']
      this.country = data['country']
      this.completionDate = data['completionDate']
        ? new Date(data['completionDate'].toString())
        : <any>undefined
      this.cost = data['cost']
      this.squareFootage = data['squareFootage']
      this.verticalMarket = data['verticalMarket']
      this.primaryImageUrl = data['primaryImageUrl']
      this.thumbnail = data['thumbnail']
      if (Array.isArray(data['districts'])) {
        this.districts = [] as any
        for (let item of data['districts']) this.districts!.push(item)
      }
      this.displayName = data['displayName']
      this.number = data['number']
      this.clientType = data['clientType']
      this.deliveryMethod = data['deliveryMethod']
      this.contractType = data['contractType']
      this.procurementMethod = data['procurementMethod']
      this.scheduledCompletionDate = data['scheduledCompletionDate']
        ? new Date(data['scheduledCompletionDate'].toString())
        : <any>undefined
      this.startDate = data['startDate']
        ? new Date(data['startDate'].toString())
        : <any>undefined
      this.clientName = data['clientName']
      if (Array.isArray(data['clientVerticalMarkets'])) {
        this.clientVerticalMarkets = [] as any
        for (let item of data['clientVerticalMarkets'])
          this.clientVerticalMarkets!.push(item)
      }
      this.streetAddress = data['streetAddress']
      this.private = data['private']
      this.active = data['active']
      this.usable = data['usable']
      this.autoUpdateContractValue = data['autoUpdateContractValue']
      this.leed = data['leed']
      this.clientLocation = data['clientLocation']
      this.insuranceType = data['insuranceType']
      this.rfiTotalCount = data['rfiTotalCount']
      this.clientContractProjectNumber = data['clientContractProjectNumber']
      this.clientNotes = data['clientNotes']
      this.doNotUse = data['doNotUse']
      this.jointVenture = data['jointVenture']
      this.numBeds = data['numBeds']
      this.numHousingUnits = data['numHousingUnits']
      this.numFloors = data['numFloors']
      this.numKeysHotel = data['numKeysHotel']
      this.numParkingSpaces = data['numParkingSpaces']
      this.parkingGarageSquareFootage = data['parkingGarageSquareFootage']
      this.nonHenselPhelpsProject = data['nonHenselPhelpsProject']
      this.preconInContract = data['preconInContract']
      this.preconCost = data['preconCost']
      this.preconCostModified = data['preconCostModified']
      this.originalConstructionContractValue =
        data['originalConstructionContractValue']
      this.finalConstructionContractValue =
        data['finalConstructionContractValue']
      this.confidential = data['confidential']
      this.safetyManHoursWorked = data['safetyManHoursWorked']
      this.safetyEmr = data['safetyEmr']
      this.numLostTimeAccidents = data['numLostTimeAccidents']
      this.numOshaRecordables = data['numOshaRecordables']
      this.dartRate = data['dartRate']
      this.selfWorkPercent = data['selfWorkPercent']
      this.selfWorkType = data['selfWorkType']
      this.leedStatus = data['leedStatus']
      this.leedVersion = data['leedVersion']
      this.leedRating = data['leedRating']
      this.finalCparsRating = data['finalCparsRating']
      this.proposalDate = data['proposalDate']
        ? new Date(data['proposalDate'].toString())
        : <any>undefined
      this.awardDate = data['awardDate']
        ? new Date(data['awardDate'].toString())
        : <any>undefined
      this.totalChangeOrderValue = data['totalChangeOrderValue']
      this.totalChangeOrderCount = data['totalChangeOrderCount']
      this.warrantyExpirationDate = data['warrantyExpirationDate']
        ? new Date(data['warrantyExpirationDate'].toString())
        : <any>undefined
      this.ownerTotalDevelopmentCost = data['ownerTotalDevelopmentCost']
      this.overallSiteAcreage = data['overallSiteAcreage']
      this.numFloorsBelowGrade = data['numFloorsBelowGrade']
      this.numOnGradeParkingSpaces = data['numOnGradeParkingSpaces']
      this.centralUtilityPlantSquareFootage =
        data['centralUtilityPlantSquareFootage']
      this.dataCenterTierLevel = data['dataCenterTierLevel']
      this.totalBuildMegawatts = data['totalBuildMegawatts']
      this.scifSquareFootage = data['scifSquareFootage']
      this.atfpLevel = data['atfpLevel']
      this.safetyNarrative = data['safetyNarrative']
      this.community = data['community']
      this.quotes = data['quotes']
      this.securityNarrative = data['securityNarrative']
      if (Array.isArray(data['projectNumbers'])) {
        this.projectNumbers = [] as any
        for (let item of data['projectNumbers']) this.projectNumbers!.push(item)
      }
      this.programId = data['programId']
      this.programName = data['programName']
      this.programSquareFootage = data['programSquareFootage']
      this.programContractValue = data['programContractValue']
      if (Array.isArray(data['supplierDiversityGoals'])) {
        this.supplierDiversityGoals = [] as any
        for (let item of data['supplierDiversityGoals'])
          this.supplierDiversityGoals!.push(item)
      }
      this.linearFeet = data['linearFeet']
      this.millionGallonsPerDay = data['millionGallonsPerDay']
    }
  }

  static fromJS(data: any): QueryableProject {
    data = typeof data === 'object' ? data : {}
    let result = new QueryableProject()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['city'] = this.city
    data['state'] = this.state
    data['country'] = this.country
    data['completionDate'] = this.completionDate
      ? this.completionDate.toISOString()
      : <any>undefined
    data['cost'] = this.cost
    data['squareFootage'] = this.squareFootage
    data['verticalMarket'] = this.verticalMarket
    data['primaryImageUrl'] = this.primaryImageUrl
    data['thumbnail'] = this.thumbnail
    if (Array.isArray(this.districts)) {
      data['districts'] = []
      for (let item of this.districts) data['districts'].push(item)
    }
    data['displayName'] = this.displayName
    data['number'] = this.number
    data['clientType'] = this.clientType
    data['deliveryMethod'] = this.deliveryMethod
    data['contractType'] = this.contractType
    data['procurementMethod'] = this.procurementMethod
    data['scheduledCompletionDate'] = this.scheduledCompletionDate
      ? this.scheduledCompletionDate.toISOString()
      : <any>undefined
    data['startDate'] = this.startDate
      ? this.startDate.toISOString()
      : <any>undefined
    data['clientName'] = this.clientName
    if (Array.isArray(this.clientVerticalMarkets)) {
      data['clientVerticalMarkets'] = []
      for (let item of this.clientVerticalMarkets)
        data['clientVerticalMarkets'].push(item)
    }
    data['streetAddress'] = this.streetAddress
    data['private'] = this.private
    data['active'] = this.active
    data['usable'] = this.usable
    data['autoUpdateContractValue'] = this.autoUpdateContractValue
    data['leed'] = this.leed
    data['clientLocation'] = this.clientLocation
    data['insuranceType'] = this.insuranceType
    data['rfiTotalCount'] = this.rfiTotalCount
    data['clientContractProjectNumber'] = this.clientContractProjectNumber
    data['clientNotes'] = this.clientNotes
    data['doNotUse'] = this.doNotUse
    data['jointVenture'] = this.jointVenture
    data['numBeds'] = this.numBeds
    data['numHousingUnits'] = this.numHousingUnits
    data['numFloors'] = this.numFloors
    data['numKeysHotel'] = this.numKeysHotel
    data['numParkingSpaces'] = this.numParkingSpaces
    data['parkingGarageSquareFootage'] = this.parkingGarageSquareFootage
    data['nonHenselPhelpsProject'] = this.nonHenselPhelpsProject
    data['preconInContract'] = this.preconInContract
    data['preconCost'] = this.preconCost
    data['preconCostModified'] = this.preconCostModified
    data[
      'originalConstructionContractValue'
    ] = this.originalConstructionContractValue
    data['finalConstructionContractValue'] = this.finalConstructionContractValue
    data['confidential'] = this.confidential
    data['safetyManHoursWorked'] = this.safetyManHoursWorked
    data['safetyEmr'] = this.safetyEmr
    data['numLostTimeAccidents'] = this.numLostTimeAccidents
    data['numOshaRecordables'] = this.numOshaRecordables
    data['dartRate'] = this.dartRate
    data['selfWorkPercent'] = this.selfWorkPercent
    data['selfWorkType'] = this.selfWorkType
    data['leedStatus'] = this.leedStatus
    data['leedVersion'] = this.leedVersion
    data['leedRating'] = this.leedRating
    data['finalCparsRating'] = this.finalCparsRating
    data['proposalDate'] = this.proposalDate
      ? this.proposalDate.toISOString()
      : <any>undefined
    data['awardDate'] = this.awardDate
      ? this.awardDate.toISOString()
      : <any>undefined
    data['totalChangeOrderValue'] = this.totalChangeOrderValue
    data['totalChangeOrderCount'] = this.totalChangeOrderCount
    data['warrantyExpirationDate'] = this.warrantyExpirationDate
      ? this.warrantyExpirationDate.toISOString()
      : <any>undefined
    data['ownerTotalDevelopmentCost'] = this.ownerTotalDevelopmentCost
    data['overallSiteAcreage'] = this.overallSiteAcreage
    data['numFloorsBelowGrade'] = this.numFloorsBelowGrade
    data['numOnGradeParkingSpaces'] = this.numOnGradeParkingSpaces
    data[
      'centralUtilityPlantSquareFootage'
    ] = this.centralUtilityPlantSquareFootage
    data['dataCenterTierLevel'] = this.dataCenterTierLevel
    data['totalBuildMegawatts'] = this.totalBuildMegawatts
    data['scifSquareFootage'] = this.scifSquareFootage
    data['atfpLevel'] = this.atfpLevel
    data['safetyNarrative'] = this.safetyNarrative
    data['community'] = this.community
    data['quotes'] = this.quotes
    data['securityNarrative'] = this.securityNarrative
    if (Array.isArray(this.projectNumbers)) {
      data['projectNumbers'] = []
      for (let item of this.projectNumbers) data['projectNumbers'].push(item)
    }
    data['programId'] = this.programId
    data['programName'] = this.programName
    data['programSquareFootage'] = this.programSquareFootage
    data['programContractValue'] = this.programContractValue
    if (Array.isArray(this.supplierDiversityGoals)) {
      data['supplierDiversityGoals'] = []
      for (let item of this.supplierDiversityGoals)
        data['supplierDiversityGoals'].push(item)
    }
    data['linearFeet'] = this.linearFeet
    data['millionGallonsPerDay'] = this.millionGallonsPerDay
    return data
  }
}

export interface IQueryableProject {
  id?: string | undefined
  name?: string | undefined
  city?: string | undefined
  state?: string | undefined
  country?: string | undefined
  completionDate?: Date | undefined
  cost?: number | undefined
  squareFootage?: number | undefined
  verticalMarket?: string | undefined
  primaryImageUrl?: string | undefined
  thumbnail?: string | undefined
  districts?: string[] | undefined
  displayName?: string | undefined
  number?: string | undefined
  clientType?: string | undefined
  deliveryMethod?: string | undefined
  contractType?: string | undefined
  procurementMethod?: string | undefined
  scheduledCompletionDate?: Date | undefined
  startDate?: Date | undefined
  clientName?: string | undefined
  clientVerticalMarkets?: string[] | undefined
  streetAddress?: string | undefined
  private?: boolean | undefined
  active?: boolean | undefined
  usable?: boolean | undefined
  autoUpdateContractValue?: boolean | undefined
  leed?: boolean | undefined
  clientLocation?: string | undefined
  insuranceType?: string | undefined
  rfiTotalCount?: number | undefined
  clientContractProjectNumber?: string | undefined
  clientNotes?: string | undefined
  doNotUse?: boolean | undefined
  jointVenture?: boolean | undefined
  numBeds?: number | undefined
  numHousingUnits?: number | undefined
  numFloors?: number | undefined
  numKeysHotel?: number | undefined
  numParkingSpaces?: number | undefined
  parkingGarageSquareFootage?: number | undefined
  nonHenselPhelpsProject?: boolean | undefined
  preconInContract?: string | undefined
  preconCost?: number | undefined
  preconCostModified?: number | undefined
  originalConstructionContractValue?: number | undefined
  finalConstructionContractValue?: number | undefined
  confidential?: boolean | undefined
  safetyManHoursWorked?: number | undefined
  safetyEmr?: string | undefined
  numLostTimeAccidents?: number | undefined
  numOshaRecordables?: number | undefined
  dartRate?: number | undefined
  selfWorkPercent?: number | undefined
  selfWorkType?: string | undefined
  leedStatus?: string | undefined
  leedVersion?: string | undefined
  leedRating?: string | undefined
  finalCparsRating?: string | undefined
  proposalDate?: Date | undefined
  awardDate?: Date | undefined
  totalChangeOrderValue?: number | undefined
  totalChangeOrderCount?: number | undefined
  warrantyExpirationDate?: Date | undefined
  ownerTotalDevelopmentCost?: number | undefined
  overallSiteAcreage?: number | undefined
  numFloorsBelowGrade?: number | undefined
  numOnGradeParkingSpaces?: number | undefined
  centralUtilityPlantSquareFootage?: number | undefined
  dataCenterTierLevel?: string | undefined
  totalBuildMegawatts?: number | undefined
  scifSquareFootage?: number | undefined
  atfpLevel?: string | undefined
  safetyNarrative?: string | undefined
  community?: string | undefined
  quotes?: string | undefined
  securityNarrative?: string | undefined
  projectNumbers?: string[] | undefined
  programId?: string | undefined
  programName?: string | undefined
  programSquareFootage?: number | undefined
  programContractValue?: number | undefined
  supplierDiversityGoals?: string[] | undefined
  linearFeet?: number | undefined
  millionGallonsPerDay?: number | undefined
}

export class ProjectSearchStatistics implements IProjectSearchStatistics {
  recordCount?: number | undefined
  totalValue?: number | undefined
  totalSquareFootage?: number | undefined

  constructor(data?: IProjectSearchStatistics) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.recordCount = data['recordCount']
      this.totalValue = data['totalValue']
      this.totalSquareFootage = data['totalSquareFootage']
    }
  }

  static fromJS(data: any): ProjectSearchStatistics {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectSearchStatistics()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['recordCount'] = this.recordCount
    data['totalValue'] = this.totalValue
    data['totalSquareFootage'] = this.totalSquareFootage
    return data
  }
}

export interface IProjectSearchStatistics {
  recordCount?: number | undefined
  totalValue?: number | undefined
  totalSquareFootage?: number | undefined
}

export class ProjectCompany implements IProjectCompany {
  projectId?: string | undefined
  companyId?: string | undefined
  roleOnProject?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IProjectCompany) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.projectId = data['projectId']
      this.companyId = data['companyId']
      this.roleOnProject = data['roleOnProject']
      this.comments = data['comments']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): ProjectCompany {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectCompany()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['projectId'] = this.projectId
    data['companyId'] = this.companyId
    data['roleOnProject'] = this.roleOnProject
    data['comments'] = this.comments
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IProjectCompany {
  projectId?: string | undefined
  companyId?: string | undefined
  roleOnProject?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ProjectContact implements IProjectContact {
  projectId?: string | undefined
  contactId?: string | undefined
  companyOnProject?: string | undefined
  roleOnProject?: string | undefined
  referenceType?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  approvedForUse?: boolean | undefined
  approvedForUseBy?: string | undefined
  approvedForUseOn?: Date | undefined

  constructor(data?: IProjectContact) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.projectId = data['projectId']
      this.contactId = data['contactId']
      this.companyOnProject = data['companyOnProject']
      this.roleOnProject = data['roleOnProject']
      this.referenceType = data['referenceType']
      this.comments = data['comments']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
      this.approvedForUse = data['approvedForUse']
      this.approvedForUseBy = data['approvedForUseBy']
      this.approvedForUseOn = data['approvedForUseOn']
        ? new Date(data['approvedForUseOn'].toString())
        : <any>undefined
    }
  }

  static fromJS(data: any): ProjectContact {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectContact()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['projectId'] = this.projectId
    data['contactId'] = this.contactId
    data['companyOnProject'] = this.companyOnProject
    data['roleOnProject'] = this.roleOnProject
    data['referenceType'] = this.referenceType
    data['comments'] = this.comments
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    data['approvedForUse'] = this.approvedForUse
    data['approvedForUseBy'] = this.approvedForUseBy
    data['approvedForUseOn'] = this.approvedForUseOn
      ? this.approvedForUseOn.toISOString()
      : <any>undefined
    return data
  }
}

export interface IProjectContact {
  projectId?: string | undefined
  contactId?: string | undefined
  companyOnProject?: string | undefined
  roleOnProject?: string | undefined
  referenceType?: string | undefined
  comments?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
  approvedForUse?: boolean | undefined
  approvedForUseBy?: string | undefined
  approvedForUseOn?: Date | undefined
}

export class ProjectPersonHistory implements IProjectPersonHistory {
  projectId?: string | undefined
  personGuid?: string | undefined
  personId?: number | undefined
  personTitleOnProject?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined

  constructor(data?: IProjectPersonHistory) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.projectId = data['projectId']
      this.personGuid = data['personGuid']
      this.personId = data['personId']
      this.personTitleOnProject = data['personTitleOnProject']
      this.modifiedOn = data['modifiedOn']
        ? new Date(data['modifiedOn'].toString())
        : <any>undefined
      this.modifiedBy = data['modifiedBy']
    }
  }

  static fromJS(data: any): ProjectPersonHistory {
    data = typeof data === 'object' ? data : {}
    let result = new ProjectPersonHistory()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['projectId'] = this.projectId
    data['personGuid'] = this.personGuid
    data['personId'] = this.personId
    data['personTitleOnProject'] = this.personTitleOnProject
    data['modifiedOn'] = this.modifiedOn
      ? this.modifiedOn.toISOString()
      : <any>undefined
    data['modifiedBy'] = this.modifiedBy
    return data
  }
}

export interface IProjectPersonHistory {
  projectId?: string | undefined
  personGuid?: string | undefined
  personId?: number | undefined
  personTitleOnProject?: string | undefined
  modifiedOn?: Date | undefined
  modifiedBy?: string | undefined
}

export class ApiSupplierDiversityClassification
  implements IApiSupplierDiversityClassification {
  id?: string | undefined
  name?: string | undefined

  constructor(data?: IApiSupplierDiversityClassification) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
    }
  }

  static fromJS(data: any): ApiSupplierDiversityClassification {
    data = typeof data === 'object' ? data : {}
    let result = new ApiSupplierDiversityClassification()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    return data
  }
}

export interface IApiSupplierDiversityClassification {
  id?: string | undefined
  name?: string | undefined
}

export class ApiSustainabilityCertification
  implements IApiSustainabilityCertification {
  id?: string | undefined
  name?: string | undefined
  versions?: SustainabilityCertificationVersion[] | undefined
  levels?: SustainabilityCertificationLevel[] | undefined

  constructor(data?: IApiSustainabilityCertification) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      if (Array.isArray(data['versions'])) {
        this.versions = [] as any
        for (let item of data['versions'])
          this.versions!.push(SustainabilityCertificationVersion.fromJS(item))
      }
      if (Array.isArray(data['levels'])) {
        this.levels = [] as any
        for (let item of data['levels'])
          this.levels!.push(SustainabilityCertificationLevel.fromJS(item))
      }
    }
  }

  static fromJS(data: any): ApiSustainabilityCertification {
    data = typeof data === 'object' ? data : {}
    let result = new ApiSustainabilityCertification()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    if (Array.isArray(this.versions)) {
      data['versions'] = []
      for (let item of this.versions) data['versions'].push(item.toJSON())
    }
    if (Array.isArray(this.levels)) {
      data['levels'] = []
      for (let item of this.levels) data['levels'].push(item.toJSON())
    }
    return data
  }
}

export interface IApiSustainabilityCertification {
  id?: string | undefined
  name?: string | undefined
  versions?: SustainabilityCertificationVersion[] | undefined
  levels?: SustainabilityCertificationLevel[] | undefined
}

export class SustainabilityCertificationVersion
  implements ISustainabilityCertificationVersion {
  id?: string | undefined
  name?: string | undefined
  certificationId?: string | undefined
  types?: SustainabilityCertificationType[] | undefined

  constructor(data?: ISustainabilityCertificationVersion) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.certificationId = data['certificationId']
      if (Array.isArray(data['types'])) {
        this.types = [] as any
        for (let item of data['types'])
          this.types!.push(SustainabilityCertificationType.fromJS(item))
      }
    }
  }

  static fromJS(data: any): SustainabilityCertificationVersion {
    data = typeof data === 'object' ? data : {}
    let result = new SustainabilityCertificationVersion()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['certificationId'] = this.certificationId
    if (Array.isArray(this.types)) {
      data['types'] = []
      for (let item of this.types) data['types'].push(item.toJSON())
    }
    return data
  }
}

export interface ISustainabilityCertificationVersion {
  id?: string | undefined
  name?: string | undefined
  certificationId?: string | undefined
  types?: SustainabilityCertificationType[] | undefined
}

export class SustainabilityCertificationLevel
  implements ISustainabilityCertificationLevel {
  id?: string | undefined
  name?: string | undefined

  constructor(data?: ISustainabilityCertificationLevel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
    }
  }

  static fromJS(data: any): SustainabilityCertificationLevel {
    data = typeof data === 'object' ? data : {}
    let result = new SustainabilityCertificationLevel()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    return data
  }
}

export interface ISustainabilityCertificationLevel {
  id?: string | undefined
  name?: string | undefined
}

export class SustainabilityCertificationType
  implements ISustainabilityCertificationType {
  id?: string | undefined
  name?: string | undefined
  certificationId?: string | undefined

  constructor(data?: ISustainabilityCertificationType) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.certificationId = data['certificationId']
    }
  }

  static fromJS(data: any): SustainabilityCertificationType {
    data = typeof data === 'object' ? data : {}
    let result = new SustainabilityCertificationType()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['certificationId'] = this.certificationId
    return data
  }
}

export interface ISustainabilityCertificationType {
  id?: string | undefined
  name?: string | undefined
  certificationId?: string | undefined
}

export class Template implements ITemplate {
  id?: string | undefined
  owner?: string | undefined
  entity?: string | undefined
  target?: string | undefined
  name?: string | undefined
  description?: string | undefined
  createdDate?: Date | undefined
  modifiedDate?: Date | undefined
  requiredRoles?: string[] | undefined
  sharedWith?: string[] | undefined
  messages?: Message[] | undefined
  schema?: ExportSchema | undefined

  constructor(data?: ITemplate) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.owner = data['owner']
      this.entity = data['entity']
      this.target = data['target']
      this.name = data['name']
      this.description = data['description']
      this.createdDate = data['createdDate']
        ? new Date(data['createdDate'].toString())
        : <any>undefined
      this.modifiedDate = data['modifiedDate']
        ? new Date(data['modifiedDate'].toString())
        : <any>undefined
      if (Array.isArray(data['requiredRoles'])) {
        this.requiredRoles = [] as any
        for (let item of data['requiredRoles']) this.requiredRoles!.push(item)
      }
      if (Array.isArray(data['sharedWith'])) {
        this.sharedWith = [] as any
        for (let item of data['sharedWith']) this.sharedWith!.push(item)
      }
      if (Array.isArray(data['messages'])) {
        this.messages = [] as any
        for (let item of data['messages'])
          this.messages!.push(Message.fromJS(item))
      }
      this.schema = data['schema']
        ? ExportSchema.fromJS(data['schema'])
        : <any>undefined
    }
  }

  static fromJS(data: any): Template {
    data = typeof data === 'object' ? data : {}
    let result = new Template()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['owner'] = this.owner
    data['entity'] = this.entity
    data['target'] = this.target
    data['name'] = this.name
    data['description'] = this.description
    data['createdDate'] = this.createdDate
      ? this.createdDate.toISOString()
      : <any>undefined
    data['modifiedDate'] = this.modifiedDate
      ? this.modifiedDate.toISOString()
      : <any>undefined
    if (Array.isArray(this.requiredRoles)) {
      data['requiredRoles'] = []
      for (let item of this.requiredRoles) data['requiredRoles'].push(item)
    }
    if (Array.isArray(this.sharedWith)) {
      data['sharedWith'] = []
      for (let item of this.sharedWith) data['sharedWith'].push(item)
    }
    if (Array.isArray(this.messages)) {
      data['messages'] = []
      for (let item of this.messages) data['messages'].push(item.toJSON())
    }
    data['schema'] = this.schema ? this.schema.toJSON() : <any>undefined
    return data
  }
}

export interface ITemplate {
  id?: string | undefined
  owner?: string | undefined
  entity?: string | undefined
  target?: string | undefined
  name?: string | undefined
  description?: string | undefined
  createdDate?: Date | undefined
  modifiedDate?: Date | undefined
  requiredRoles?: string[] | undefined
  sharedWith?: string[] | undefined
  messages?: Message[] | undefined
  schema?: ExportSchema | undefined
}

export class Message implements IMessage {
  subject?: string | undefined
  message?: string | undefined

  constructor(data?: IMessage) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.subject = data['subject']
      this.message = data['message']
    }
  }

  static fromJS(data: any): Message {
    data = typeof data === 'object' ? data : {}
    let result = new Message()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['subject'] = this.subject
    data['message'] = this.message
    return data
  }
}

export interface IMessage {
  subject?: string | undefined
  message?: string | undefined
}

export class ApiVdcTechnology implements IApiVdcTechnology {
  id?: string | undefined
  name?: string | undefined
  path?: string | undefined
  parentVdcTechnologyId?: string | undefined
  childVdcTechnologies?: ApiVdcTechnology[] | undefined

  constructor(data?: IApiVdcTechnology) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
      this.path = data['path']
      this.parentVdcTechnologyId = data['parentVdcTechnologyId']
      if (Array.isArray(data['childVdcTechnologies'])) {
        this.childVdcTechnologies = [] as any
        for (let item of data['childVdcTechnologies'])
          this.childVdcTechnologies!.push(ApiVdcTechnology.fromJS(item))
      }
    }
  }

  static fromJS(data: any): ApiVdcTechnology {
    data = typeof data === 'object' ? data : {}
    let result = new ApiVdcTechnology()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    data['path'] = this.path
    data['parentVdcTechnologyId'] = this.parentVdcTechnologyId
    if (Array.isArray(this.childVdcTechnologies)) {
      data['childVdcTechnologies'] = []
      for (let item of this.childVdcTechnologies)
        data['childVdcTechnologies'].push(item.toJSON())
    }
    return data
  }
}

export interface IApiVdcTechnology {
  id?: string | undefined
  name?: string | undefined
  path?: string | undefined
  parentVdcTechnologyId?: string | undefined
  childVdcTechnologies?: ApiVdcTechnology[] | undefined
}

export class ApiVerticalMarket implements IApiVerticalMarket {
  id?: number | undefined
  name?: string | undefined

  constructor(data?: IApiVerticalMarket) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.name = data['name']
    }
  }

  static fromJS(data: any): ApiVerticalMarket {
    data = typeof data === 'object' ? data : {}
    let result = new ApiVerticalMarket()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data['id'] = this.id
    data['name'] = this.name
    return data
  }
}

export interface IApiVerticalMarket {
  id?: number | undefined
  name?: string | undefined
}

export class ApiException extends Error {
  message: string
  status: number
  response: string
  headers: { [key: string]: any }
  result: any

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any,
  ) {
    super()

    this.message = message
    this.status = status
    this.response = response
    this.headers = headers
    this.result = result
  }

  protected isApiException = true

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any,
): any {
  if (result !== null && result !== undefined) throw result
  else throw new ApiException(message, status, response, headers, null)
}

export function normalizeEntityName(entityName: string) {
  switch (entityName) {
    case 'people':
    case 'person':
      return 'people'
    case 'projects':
    case 'project':
      return 'projects'
    case 'contacts':
    case 'contact':
      return 'contacts'
    case 'companies':
    case 'company':
      return 'companies'
    default:
      throw new Error(`Invalid entity name: ${entityName}`)
  }
}

export type SearchResultSet =
  | SearchResultSetOfQueryablePerson
  | SearchResultSetOfQueryableProject
  | SearchResultSetOfQueryableCompany
  | SearchResultSetOfQueryableContact

export function getAllEntitiesValue<T>(searchResultSet: SearchResultSet): T[] {
  return (searchResultSet.value as unknown) as T[]
}
