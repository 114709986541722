import {Column} from "@syncfusion/ej2-react-grids";


export function greenCheck(): string {
  return `<span style="color: green;"><svg class="MuiSvgIcon-root" 
                focusable="false" viewBox="0 0 24 24" 
                aria-hidden="true"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z">
      </path></svg></span>`;
}

export function  redX(): string {
  return `<span style="color: red;"><svg class="MuiSvgIcon-root" 
                focusable="false" viewBox="0 0 24 24" 
                aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
      </path></svg></span>`;
}

export function mkColumn(field: string, headerText: string) {
  return {field,headerText, allowSorting: true} as Partial<Column>;
}