import {DateTime} from "luxon";
import {cloneDeep} from "lodash";
import {number} from "prop-types";

export function formatDateForDash(date: Date | undefined | null) {
    // todo:
    // test later
    // toLocaleDateString('en-US', {month: 'numeric', day: 'numeric', year: 'numeric'});
    if (!date) {
        return '';
    }

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}

export function getYear() {
    return new Date().getFullYear();
}

export function shortDate(potentialDate?: Date | string | null) {
    if (!potentialDate) return '';
    const date = typeof potentialDate === 'string' ?
        DateTime.fromISO(potentialDate as string, { setZone: true }).startOf('day').toJSDate() :
        potentialDate;

    return date.toISOString().substring(0, 10);
}


// may not need
function shortDatesAreDifferent(date?: Date | null, testDate?: Date | null) {
    if (!date || !testDate) return false;
    return (shortDate(date) !== shortDate(testDate));
}

export function plainDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function shortDateIfDate(value: string) {
    if (likelyDate(value)) {
        return shortDate(value);
    }
    return value;
}

export function likelyDate(value: any) {
    if (value === null) return false;
    if (value === undefined) return false;

    if (typeof value === 'string') {
        if (value.length < 10) return false;
        const regexTest = /^\d{4}-\d{2}-\d{2}-\d{2}/.test(value);
        if (!regexTest) return false;

        value = new Date(value);
        if (isNaN(value as any)) {
            return false;
        }
    }

    return (value instanceof Date && !isNaN(value as any));
}

export function shortIsoDateIfIsoDate(value: string | number | undefined | null) {
    if (typeof value === 'number') {
        return String(number);
    }
    if (typeof value === 'string') {
        if (value.charAt(10) === 'T') {
            return value.split('T')[0];
        }
    }
    return value;
}