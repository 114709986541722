import React from 'react';
import {IQueryableProject} from "services/apiClients/AthenaClient";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {getDistrictDisplayName} from "services/metadata/regionsEx";
import {commasFmt, currencyFmt } from "utils/numberToStringUtil";
import PrimaryImage from "../../../app/primaryImage/feature/PrimaryImage";

const useStyles = makeStyles(() => ({
  card: {
    width: '415px',
    minWidth: '415px',
    maxWidth: '415px',
    height: '100%',
    margin: '10px',
    backgroundColor: '#fff',
  },
  summaryArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
  },
  marked: {
    flex: '1',
    margin: '5px',
  },
}));

function ProjectCard(props: { model: IQueryableProject, onNavDetails: (entityName: string, id: string) => void}) {
  const classes = useStyles();
  const model = props.model;
  const idStr = props.model?.id ? props.model.id: '';

  const squareFootageDisplay = model.squareFootage
      ? commasFmt.format(model.squareFootage) + ' ft²'
      : '';
  const costDisplay = model.cost
      ? currencyFmt.format(model.cost)
      : '';

  function handleClick() {
    props.onNavDetails('projects', idStr);
  }

  return (
      <div className={classes.card} key={'projectCard:' + model.id}>
        <div className={classes.summaryArea} style={{textAlign:'left'}} onClick={handleClick}>
          <div className={classes.marked}>
            <Typography variant="h4">{model.name}</Typography>
            <Typography variant="h6">{model.city}, {model.state} - {getDistrictDisplayName(model.districts)}</Typography>
            <Typography variant="h6">{model.verticalMarket}</Typography>
            <Typography variant="body2" color="textSecondary">
              {/* something */}
            </Typography>
          </div>
          <div className="flex flex-row justify-between flex-nowrap">
            <div className="flex">
              <PrimaryImage primaryImageSrc={model.primaryImageUrl}
                            userName={undefined}
                            entityId={String(model.id)}
                            entityName={'projects'}
                            style={{ width: '150px', height: '150px', margin: '15px' }} />
            </div>
            <div className="flex flex-col flex-wrap">
              <div className="flex" style={{marginRight:'20px'}}>{squareFootageDisplay}</div>
              <div className="flex" style={{marginRight:'20px', marginTop:'10px'}}>{costDisplay}</div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ProjectCard;
