import CoreDebug from 'features/core/CoreDebug';
import useAthenaClient from 'hooks/useAthenaClient';
import useDashUrlInfo from 'hooks/useDashUrlInfo';
import React, {useEffect, useRef, useState} from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  IAthenaFacet,
} from 'services/apiClients/AthenaClient';
import { encodeForAthenaFilters } from 'services/search/QueryConvertor';
import { ICriteria } from 'services/SearchService';
import { FacetSettingsInfo, IFacetState, SideNavChildrenProps } from 'types/searchTypes';
import { deserialize, serialize } from '../FacetSideNav/serdeUtil';
import {useGuid} from "../../../features/core/Guid";



interface IState {
  entityName: string,
  searchParams: URLSearchParams,
  field: string,
  mode: string,
  values: string[],
  min?: string | null,
  max?: string | null,
  defaultMin?: string | null,
  defaultMax?: string | null,
}

export interface RangeProps extends SideNavChildrenProps {
  setMode: React.Dispatch<React.SetStateAction<string>>;
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  min?: string | undefined;
  max?: string | undefined;
  setMin: React.Dispatch<React.SetStateAction<string | null>>;
  setMax: React.Dispatch<React.SetStateAction<string | null>>;
  setIsModified: (isModified: boolean) => void;
  reset: () => void;
}

function convertToDict(facets: IAthenaFacet[]): Record<string, number> {
  const dict: Record<string, number> = {};

  for (let i = 0; i < facets.length; i++) {
    const facet = facets[i];
    if (facet.value !== undefined && facet.count !== undefined) {
      dict[facet.value] = facet.count;
    }
  }

  return dict;
}


// both functions actually need to check for nulls
function firstOrNull(values?: string[] | null): string | null {
  let result: string | null = null;
  if (values && values.length > 0) {
    result = values[0].toString() ?? '';
  }
  //console.log('#@! inside firstOrNull', result, typeof result);
  return result;
}

function secondOrNull(values?: string[] | null): string | null {
  let result: string | null = null;
  //console.log('!_secondOrNull', { values })
  if (values && values.length > 1) {
    result = values[1] ?? null;
  }
  //console.log('#@! inside secondOrNull', result, typeof result);
  return result;
}

const TSHOOT_FIELD = 'hireDate';

const withRangeFacet = (WrappedComponent: React.FC<RangeProps>) => {
  return (props: SideNavChildrenProps) => {
    //const { athenaClient } = useAthenaClient();
    const { entityName: entityNameParam } = useParams<{ entityName: string }>();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const {entityName, filters, search, getCriteria} = useDashUrlInfo();
    const ranOnce = useRef(false);
    // todo: pass search params into next component
    const [guidKey, newGuid] = useGuid();

    const [mode, setMode] = useState<string>('');
    const fsiValues = (props.field in filters) ? filters[props.field]?.values?.map(x => x.toString()) : [];
    const incomingMinValue = firstOrNull(fsiValues);
    const incomingMaxValue = secondOrNull(fsiValues);
    const [values, setValues] = useState<(string | null)[]>([incomingMinValue, incomingMaxValue]);

    const [min, setMin] = useState<string | null>(incomingMinValue);
    const [max, setMax] = useState<string | null>(incomingMaxValue);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [defaultMin, setDefaultMin] = useState<string | null>(null);
    const [defaultMax, setDefaultMax] = useState<string | null>(null);

    const log = CoreDebug.getConsoleLogFunc();
    if (props.field === 'startDate') {
      //log('#@! my IState', state);
      console.log('#@! facetSettingsInfo', props.facetSettingsInfo);
      console.log('#@! my location.state', location.state);
    }

    const criteria = getCriteria();

    useEffect(() => {
      setMin(incomingMinValue);
      setMax(incomingMaxValue);
      console.log('#@! min and max from withRangeFacet ' + props.field,
          {oldMin:min,oldMax:max,fsi: fsiValues, initMinValue: incomingMinValue, initMaxValue: incomingMaxValue});
    }, [incomingMinValue, incomingMaxValue]);

    useEffect(() => {
      console.log('!@@! useEffect triggered:', {min, max, defaultMin, defaultMax, isModified});
      if (props.field === TSHOOT_FIELD) {
        console.log('!@@! ' + TSHOOT_FIELD + ' facet');
        console.log('!@@! ' + TSHOOT_FIELD + ' test details', min, defaultMin, max, defaultMax);
        console.log('!@@! ' + TSHOOT_FIELD + ' two test', min !== defaultMin, max !== defaultMax);
      }

      if (isModified) {
        const tmpMin = min ?? '';
        const tmpMax = max ?? '';

        if (ranOnce.current) {
          updateMySearchParams(tmpMin ?? '', tmpMax ?? '');
        }
      }
    }, [min, max, defaultMin, defaultMax]); //, isModified]);

    function updateMySearchParams(min: string, max: string) {
      console.log('!@#! updateMySearchParams was called!')
      const sp = Object.fromEntries(searchParams.entries());
      console.log('!@#! how updateMySearchParams sees min and max', {min,max});
      const newValues = [min.replace(',', ''), max.replace(',', '')];
      // get current search params to manipulate to change state of filtering

      const fsi: FacetSettingsInfo = {field: props.field, mode: 'between', values: newValues};
      sp[props.field] = serialize(fsi);

      const newSearchParams = new URLSearchParams(sp);

      //remove by key if the range is completely devoid of information
          if (newValues[0] === '' && newValues[1] === '') {
        newSearchParams.delete(props.field);
      }
      setSearchParams(newSearchParams, { replace: true, state: { source: props.field } });
      console.log('!@#! how updateMySearchParams sees things leaving func', {min,max, sp, newSearchParams});
    }

    function getMyFacetSelections() {
      const facetStr = searchParams.get(props.field);
      if (facetStr) {
        const facetSettingsInfo = deserialize(props.field, facetStr);
        return { ...facetSettingsInfo, entityName } as IFacetState;
      } else {
        return { entityName, field: props.field, mode: 'between', values: [] };
      }
    }

    function clearMySearchParams() {
      const sp = Object.fromEntries(searchParams.entries());
      delete sp[props.field];
      const newSearchParams = new URLSearchParams(sp);
      setSearchParams(newSearchParams, { replace: true });
      console.log('!@#1 clearMySearchParams', newSearchParams);
    }

    function handleReset() {
      log(min, defaultMin, max, defaultMax);
      log(min, defaultMin, max, defaultMax);
      // setMin(defaultMin);
      // setMax(defaultMax);
      setMin(null);
      setMax(null);
      clearMySearchParams();
      setIsModified(false);
      //newGuid();
    }

    console.log('!@# ranOnce.current', ranOnce.current);
    console.log('!@# min from props/url?', {'props.values':props.values, min});
    console.log('!@# max from props/url?', {'props.values':props.values, max});

    ranOnce.current = true;

    return <WrappedComponent {...props} key={guidKey}
        entityName={entityName}
                             mode={'between'}
                             field={props.field}
        min={min ?? ''}
        max={max ?? ''}
        values={[min ?? '',max ?? '']}
      setMode={setMode}
      setMin={setMin}
      setMax={setMax}
      reset={handleReset}
      setIsModified={setIsModified}
     // setValues={setValues}
    />;
  };
};

export default withRangeFacet;
