import React, {useState, useEffect} from 'react';
import {TextField, IconButton, InputAdornment, Grid} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import Clear from "@material-ui/icons/Clear";
import {useSearchParams} from "react-router-dom";
import debounce from "lodash.debounce";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    background: 'linear-gradient(to bottom, #fff, #f7f7f7 95%, #333 100%)',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover, & fieldset': {
        borderColor: theme.palette.secondary.light,
      },
    },
  },
  focus: {
    '& .MuiOutlinedInput-root': {
      '&:hover, & fieldset': {
        borderColor: theme.palette.secondary.light,
      },
    },
  },
}));

const useDebouncedText = (initialValue: string, delay: number) => {
  const [text, setText] = useState(initialValue);
  const debouncedText = debounce((newText: string) => setText(newText), delay);

  useEffect(() => {
    // test improvement - commented out cancel at start
    // debouncedText.cancel();
    return () => {
      debouncedText.cancel();
    };
  }, []);

  return [text, debouncedText] as const;
};

export default function SearchBox() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get('search') || '');
  const [areaKey, setAreaKey] = useState(uuidv4());
  const [text, debouncedText] = useDebouncedText(searchParams.get('search') || '', 1200);

  useEffect(() => {
    _.noop();
  }, [searchText, searchParams]);

  // if they want to go back to keydown in the future
  //function handleKeyDown(_event: React.KeyboardEvent<HTMLInputElement>) {
    // if (event.key === 'Enter') {
    //   console.log({event});
    //   processSearchChange(searchText);
    // }
  //}

  function handleClear() {
    debouncedText('');
    setSearchText('');
    setAreaKey(uuidv4());
  }

  function removeQuotes(value?: string) {
    return value == null ? value : value.replaceAll('"', '');
  }

  useEffect(()=> {
    const sp = Object.fromEntries(searchParams.entries());
    //console.log('handleSearchTextChange searchParams', searchParams.toString());
    if (text === '') {
      delete sp.search;
    } else {
      const t = removeQuotes(text);
      sp['search'] = t ?? '';
    }
    const newSearchParams = new URLSearchParams(sp);
    setSearchParams(newSearchParams, { replace: true });
  },[text]);

  const showClear = text !== '';

  return (
      <Grid className={classes.box} container
            justifyContent="center"
            alignItems="center">
        <Grid item xs={12}>
          <React.Fragment key={areaKey}>
          <TextField
              fullWidth
              defaultValue={searchText}
              onChange={(e) => debouncedText(e.target.value)}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.focus
                },
                disableUnderline: true,
                startAdornment: (
                    <SearchIcon/>
                ),
                endAdornment: showClear ? (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear}>
                        <Clear/>
                      </IconButton>
                    </InputAdornment>
                ) : null,
              }}
          />
          </React.Fragment>
        </Grid>
      </Grid>
  );
}