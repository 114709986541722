import React, {useContext, useEffect, useRef, useState} from "react";
import {useSharedLargeCardStyles} from "./sharedStyles";
import {IApiPerson, IApiProject} from "services/apiClients/AthenaClient";
import {Button, IconButton} from "@material-ui/core";
import {snapshotNamedData} from "utils/forms";
import {IDropDownOptions} from "hooks/useFieldOptions/localTypesAndUtil";
import {EditOutlined} from "@material-ui/icons";
import ProgressButton from "../../dashCore/ProgressButton";
import _ from "lodash";
import DashToaster from "patterns/DashToaster";
import useToast from "hooks/useToast";
import InfoIcon from "@material-ui/icons/Info";
import {InfotipContext} from "../../../providers/InfotipProvider";
import {useGlobalState} from "app/state/useGlobalState";
import AthenaAuthorize from "app/security/ui/AthenaAuthorize";
import Tracker from "app/Tracker/Tracker";

export interface IWithDashSectionMethods {
  save: () => Promise<boolean | undefined>;
}

export interface IWithDashSectionProps {
  model: IApiProject | IApiPerson;
  referenceData: IDropDownOptions;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  onSave: () => void;
  readOnly?: boolean;
}

const withDashSection = <P extends { id: string }>(
    sectionName: string,
    id: string,
    SomeComponent: React.ComponentType<P & IWithDashSectionProps>,
    readOnly?: boolean,
    infotipKey?: string,
) => {
  const WithDashSection: React.FC<P & IWithDashSectionProps> = ({...props}) => {
    const ref = useRef<IWithDashSectionMethods>(null);
    const gs = useGlobalState();
    const classes = useSharedLargeCardStyles();
    const globalState = useGlobalState();
    const {openInfotip} = useContext(InfotipContext);
    const {displayToast} = useToast();
    const dashToaster = new DashToaster(displayToast);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonReset, setButtonReset] = useState(false);

    let sectionInstance: HTMLDivElement | null = null;

    function setSectionInstance(el: HTMLDivElement | null) {
      sectionInstance = el;
    }

    useEffect(() => {
      if (props.model) {
        if (props.id === 'NOT-programInformationSection') {
          _.noop();
        } else {
          snapshotNamedData(document.getElementById(props.id));
        }
      }
    }, [props.model, props.id]);

    useEffect(() => {
      const page = document.getElementById('detail-container');
      if (page) {
        if (editMode && sectionInstance) {
          //console.log('WithDashSection props', props, id);
          sectionInstance.scrollIntoView({
            behavior: 'smooth',
            duration: 500,
          } as ScrollIntoViewOptions);
          // set this so the user cannot scroll
          page.style.overflow = 'hidden';
          sectionInstance.classList.add('lifted');
        } else {
          page.style.overflow = 'auto';
          sectionInstance?.classList.remove('lifted');
        }
      }
    }, [editMode]);

    const handleSave = async () => {
      //console.log('handleSave is being called', {childRef:ref,current:ref.current});
      if (ref?.current) {
        try {
          const result = await ref.current.save();
          console.log('!@#- result is ', result, typeof result);
          if (result === true) {
            setEditMode(false);
            dashToaster.displayUpdatedToast('record', false, true);
          } else if (result === false) {
            setButtonReset(true);
          } else {
            throw new Error('Result should never be non-boolean');
          }
        } catch (err) {
          console.error(err);
          // Depending on the error, you might want to do something else here.
          setButtonReset(true);
        }
      }
    };


    const handleCancel = () => {
      setEditMode(false);
    }

    function handleButtonReset() {
      setButtonReset(false);
    }

    return (
        <>
          <div id={id} className={classes.normalSection}
               ref={(section) => setSectionInstance(section)}>
            <div className={classes.titleArea}>
              <div>
                <h3 className="sectionHeading">{sectionName}
                  {infotipKey &&
                      <IconButton aria-label="info" size="small"
                                  onClick={() => openInfotip(infotipKey ?? '')}>
                          <InfoIcon fontSize="small" className="infotip-icon"/>
                      </IconButton>
                  }</h3>
              </div>
              <div className={classes.iconOuterContainer}>
                {!readOnly && <>
                  {editMode ?
                      (
                          <span style={{color: 'red'}}>Editing</span>
                      ) :
                      <><AthenaAuthorize allowedRoles={'Contributor'}>
                        <EditOutlined
                            onClick={() => setEditMode(true)}
                            className={classes.editIcon}/>
                      </AthenaAuthorize>
                     </>
                  }
                </>
                }
              </div>
            </div>
            <div data-style={editMode ? {border: '1px dotted cornflower'} : {}}>
              <form className={editMode ? classes.fieldAreaPoppedUp : classes.fieldArea}>
                <SomeComponent {...props}
                               editMode={editMode}
                               setEditMode={setEditMode}
                               ref={ref}/>
              </form>

              {editMode && (
                  <div style={{textAlign: 'right', paddingTop: '10px'}}>
                    <ProgressButton text={'Save'}
                                    reset={buttonReset}
                                    onReset={handleButtonReset}
                                    onClick={() => handleSave()}/>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </div>
              )}
            </div>
          </div>
          {editMode &&
            <Tracker featureName={sectionName} />
          }
          {editMode &&
              (<div className={classes.overlay}>
                  </div>
              )}

        </>
    );
  };

  return WithDashSection;
}

export {
  withDashSection
};