import { makeAutoObservable } from "mobx";
import CoreRange from "features/core/CoreRange";

class ExperienceFacetState {
  expMode: string | null = null;
  expMinYears: number | null = null;
  latestHireDate: Date | null = null;
  latestHireDateShort: string | null = null;
  pristine = true;

  constructor() {
    makeAutoObservable(this);
  }

  setExpMode(expMode: string | null) {
    this.expMode = expMode;
    this.pristine = false;
  }

  setExpMinYears(expMinYear: number | null) {
    console.log('!!! fired: ' + expMinYear || 'null');
    this.expMinYears = expMinYear;
    if (!this.expMode) {
      this.expMode = 'hp';
    }
    if (this.expMode === 'hp') {
      this.latestHireDate = calculateMaxDateValue(this.expMinYears);
      this.latestHireDateShort = CoreRange.makeShortDate(this.latestHireDate);
    }
    this.pristine = false;
  }

  setExpLatestHireDate(expLatestHireDate: Date | null) {
    this.latestHireDate = expLatestHireDate;
    this.expMinYears = calculateMinYearValue(expLatestHireDate);
    if (!expLatestHireDate) {
      this.expMode = null;
    }
    this.pristine = false;
  }

  reset() {
    this.expMinYears = 0;
    this.expMode = null;
    if (!this.pristine) {
      this.pristine = true;
      this.latestHireDate = null;
      this.latestHireDateShort = null;
    }
  }
}

function calculateMinYearValue(inputDate: Date | null): number {
  if (!inputDate) return 0;

  const today = new Date();
  const inputDateTime = inputDate.getTime();
  const todayTime = today.getTime();
  // Set the time to midnight for accurate year difference calculation
  inputDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  // If the input date is today or in the future, the year value is 0
  if (inputDateTime >= todayTime) {
    return 0;
  }

  // Calculate the year difference
  const yearDifference = today.getFullYear() - inputDate.getFullYear();
  // Check if the input date has passed for this year
  const hasPassedThisYear = (today.getMonth() > inputDate.getMonth()) ||
      (today.getMonth() === inputDate.getMonth() && today.getDate() >= inputDate.getDate());
  const result =  hasPassedThisYear ? yearDifference : yearDifference - 1;
  console.log(`!!! result exp yd: ${result}`);
  return result;
}

function calculateMaxDateValue(yearsValue: number | null) {
  // Calculate yesterday's date and handle Feb 29 issue
  const years = yearsValue == null ? 0: yearsValue - 1;
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.getDate() === 29 && yesterday.getMonth() === 1) {
    yesterday.setDate(28);
  }

  return new Date(yesterday.getFullYear() - years,
      yesterday.getMonth(),
      yesterday.getDate()
  );
}

const experienceFacetState = new ExperienceFacetState();
export default experienceFacetState;
