import React, {useState, useImperativeHandle, forwardRef, ForwardedRef} from "react";
import {IApiProject, ProjectChallengeSolution} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {Dialog} from "@material-ui/core";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import { v4 as uuidv4 } from 'uuid';
import AddEditCasDialog from "./AddEditCasDialog";
import ChallengeAndSolutionBlock from "./ChallengeAndSolutionBlock";
import DashList2 from "../DashList2";
import {confirmDelete} from "patterns/confirmations";
import useToast from "hooks/useToast";
import DashToaster from "patterns/DashToaster";
import DataRow from "../../../../patterns/DataRow";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import {useGlobalState} from "../../../../app/state/useGlobalState";
// end imports

const SECTION_ID = 'challengesSolutionsSection';

const ChallengeSolutionSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const gs = useGlobalState();
      const [saveCount, incr] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const toaster = useToast();
      const dashToaster = new DashToaster(toaster.displayToast);
      const [model, setModel] = useState<IApiProject | null>(null);
      const [dataRows, setDataRows] = useState<DataRow<ProjectChallengeSolution>[]>();
      const [showInfotipModal, setShowInfotipModal] = useState(false);

      const [content, setContent] = useState('');
  const [sectionKey,setSectionKey] = useState(uuidv4());
      const [dialogOpen, setDialogOpen] = useState(false);
      const [dialogEditMode, setDialogEditMode] = useState<string>();
      const [dialogId, setDialogId] = useState<string>();
      const [dialogModel, setDialogModel] = useState<DataRow<ProjectChallengeSolution>>();



      if (model === null) {
        setModel(props.model);
        const tmpCollection = props.model.challengesAndSolutions?.map(x => new DataRow(x)) || [];
        setDataRows(tmpCollection);
      }
      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      function getTitleIfPossible(itemId?: string) {
        if (!itemId) return '';
        if (model?.challengesAndSolutions) {
          const tmpItem = model.challengesAndSolutions.find(x => x.id === itemId);
          return tmpItem?.title ?? '';
        }
        return '';
      }

      function handleAction(action: string, payload?: string | DataRow<ProjectChallengeSolution>) {
        if (payload === undefined || typeof payload === 'string') {
          const parentId = model?.id as string;
          const id = payload as string;
          const title = getTitleIfPossible(payload);
          switch (action) {
            case 'edit':
              if (payload) {
                setDialogEditMode('edit');
                setDialogId(payload);
                if (model && model.challengesAndSolutions) {
                  const tmpDialogModel = model.challengesAndSolutions.find(x => x.id === id) as ProjectChallengeSolution;
                  const tmpDataRow = new DataRow(tmpDialogModel, 'unchanged');
                  setDialogModel(tmpDataRow);
                }
                setDialogOpen(true);
              }
              break;
            case 'new':
              setDialogEditMode('new');
              setDialogId(undefined);
              setDialogOpen(true);
              setDialogModel(new DataRow(new ProjectChallengeSolution(), 'unchanged'));
              break;
            case 'delete':
              if (confirmDelete(title)) {
                athenaClient?.deleteChallengeSolution(parentId, id)
                    .then(result => {
                      console.log(result);
                      handleAction('close-refresh');
                      dashToaster.displayDeletedToast(title);
                    });
              }
              handleAction('refresh','');
              break;
            case 'close':
              setDialogOpen(false);
              setDialogId(undefined);
              setDialogModel(undefined);
              break;
            case 'refresh':
              setDialogOpen(false);
              setDialogId(undefined);
              setDialogModel(undefined);
              athenaClient?.getProject(props.model.id as string).then(project => {
                setModel(project);
                const tmpCollection = project.challengesAndSolutions?.map(x => new DataRow(x)) || [];
                setDataRows(tmpCollection);
              });
              break;

          }
        }
        if (typeof payload !== 'string') {
          console.log('payload dump', payload);
        }
        // note - delete does not use a data row to trigger
        if (payload instanceof DataRow) {
          console.log('process like a DataRow', {payload});
          processDataRow(payload as DataRow<ProjectChallengeSolution>);
        }
      }

      function processDataRow(dataRow: DataRow<ProjectChallengeSolution>) {

        const parentId = model?.id as string;
        const id = dataRow.value.id as string;
        switch (dataRow.status) {
          case 'added':
            athenaClient?.createChallengeSolution(parentId, dataRow.value)
                .then(result => {
                  console.log(result);
                  handleAction('close');
                  dashToaster.displaySavedToast(dataRow.value.title ?? '');
                  handleAction('refresh');
                });
            break;
          case 'modified':
            athenaClient?.updateChallengeSolution(parentId, id, dataRow.value)
                .then(result => {
                  console.log(result);
                  handleAction('close');
                  dashToaster.displaySavedToast(dataRow.value.title ?? '');
                  handleAction('refresh');
                });
            break;
        }
      }

      if (props.model === null || model === null) {
        console.log('project is null');
        return <DashItemProgress/>;
      }

      return (
          <>
            <DashList2 list={dataRows}
                       Component={ChallengeAndSolutionBlock}
                       showEdit={hasPermission(['Contributor'],gs.userRoles)}
                       showDelete={hasPermission(['Contributor'],gs.userRoles)}
                       onAction={handleAction}
                       addButtonText={'Add Challenge & Solution'}
                       showAdd={hasPermission(['Contributor'],gs.userRoles)}
                       titleExpression={(m) => m.value.title ?? ''}
            />

            <DashDivider width="80vw" height="1px"/>
            {/*

            DIALOGS below

            */}

            {dialogOpen &&
                <AddEditCasDialog open={dialogOpen} parentModel={model} mode={dialogEditMode}
                                  model={dialogModel}
                                  choices={[]}
                                  onRequestFromChild={handleAction}
                                  onInfotipClick={handleInfotipClick} />
            }

            <Dialog
                open={showInfotipModal}
                onClose={() => setShowInfotipModal(false)}>
              <div className={classes.innerDialogPadding}>
                <div dangerouslySetInnerHTML={{__html: content}}/>
              </div>
            </Dialog>

          </>
      )
    });


export default withDashSection('Project Challenges and Solutions', SECTION_ID, ChallengeSolutionSection,
    true, 'challengesAndSolutions');

