import React from 'react';
import {
  HtmlEditor, Image, Inject,
  Link, QuickToolbar, RichTextEditorComponent,
  Toolbar
} from '@syncfusion/ej2-react-richtexteditor';

export default function DashRichTextEditor(props: { name?: string, value: string }) {
  console.log('DashRichTextEditor value', props.value);

  return (
      <div style={{width: '100%', textAlign: 'left'}}>
        <RichTextEditorComponent
            name={props.name}
            style={{width: '100%'}} height={200} value={props.value}>
          <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}/>
        </RichTextEditorComponent>
      </div>
  );

}