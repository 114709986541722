import {useEffect, useState} from 'react';

type WizardState<T> = {
  id: string | number | null,
  step: number,
  mode: string | null | undefined,
  model: T | null
}

export default function useWizard<T>(initialState: WizardState<T>) {
  const [state, setState] = useState<WizardState<T>>(initialState);

  useEffect(() => {
    console.log('!!!!! from inside useWizard', state);
  }, [state.mode, state.step, state.id]);

  const update = (newState: Partial<WizardState<T>>) => {
    console.log('!!!!! updating state', {state:state,newState:newState});
    //setState({...state, ...newState});
    //setState((x) => {...x as any, ...newState});
    setState(prevState => Object.assign({}, prevState, newState));
  };

  const next = () => {
    update({ step: state.step + 1 });
  };

  const back = () => {
    update({ step: state.step - 1 });
  };

  const setId = (id: string | number) => {
    update({ id: id });
  };

  const setMode = (mode: string) => {
    update({mode: mode});
  }

  const setStep = (step: number) => {
    update({step: step});
  }

  const setStepAndMode = (step: number, mode: string) => {
    update({step: step, mode: mode});
  }

  function compareSpecialLocal(obj: any, step: number, mode: string): boolean {
    for (const key of Object.keys(obj)) {
      if (obj['step'] === step && (obj['mode'] === mode || mode === '*')) {
        return true;
      }
    }
    return false;
  }

  function compareStepAndMode(step: number, mode: string) {
    return compareSpecialLocal(state, step, mode);
  }

  return [state, { update, next, back, setId, setMode, setStep, setStepAndMode, compareStepAndMode }] as const;
}