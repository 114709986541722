import React, {useEffect, useState} from 'react';
import {Link, scroller} from 'react-scroll';
import {
  List, ListItem, ListItemText, ListItemIcon, Tooltip, SvgIconProps, IconButton, Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash";
import {getProjectNavBarData, projectIcons} from "./projectNavData";
import {getPeopleNavBarData, peopleIcons} from "./peopleNavData";
import {DetailNavItemModel} from "./navTypes";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import {getPersonImageUrl} from "../../../../constants/metadata/personImages";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import {useGlobalState} from "../../../../app/state/useGlobalState";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import PrimaryImage from "../../../../app/primaryImage/feature/PrimaryImage";
import CoreDebug from "../../../../features/core/CoreDebug";

const useStyles = makeStyles(() => ({
  sideNavBarInner: {
    width: '62px',
    backgroundColor: 'white',
    '& a.active svg path': {
      // color: 'red',
      // backgroundColor: 'red',
      fill: '#990000',
    },
  },

  // detailsNav: {
  //   '& a.active svg': {
  //     color: 'red',
  //     backgroundColor: 'red',
  //     border: '1px solid blue',
  //   },
  // },

}));

export type DetailSideNavBarPropsEx = {
    entityName: string;
    id: string;
    userName?: string;
    name?: string;
    primaryImageUrl?: string;
    primaryThumbnail?: string;
}

function ListNavItem(props: {icon: JSX.Element, text: string,  to?: string}) {
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const normTo = _.kebabCase(props.to);

  // function errorHandler(error, targetId) {
  //   throw new Error(`Error navigating to target "${targetId}": ${error}`);
  // }

  const handleSetActive = (linkId: string) => {
    setActiveLink(linkId);
  };


  return (
        <Tooltip title={props.text} placement="right">
        <Link to={normTo ?? ''}
              spy={true}
              smooth={true}
              duration={200}
              containerId={'detail-container'}
              onSetActive={handleSetActive}
              activeClass={'active'}>
        <ListItem className={'cursor-pointer'}>
          <ListItemIcon>{props.icon}</ListItemIcon>
        </ListItem>
        </Link>
      </Tooltip>
  );
}

export default function DetailSideNavBarEx(props: DetailSideNavBarPropsEx) {
    const classes = useStyles();
    const user = useCurrentUser();
    const sideNavBarData: DetailNavItemModel[] = (props.entityName === 'projects')
            ? getProjectNavBarData(): getPeopleNavBarData();
    const icons = (props.entityName === 'projects') ? projectIcons: peopleIcons;

  const scrollToElement = (elementId) => {
    scroller.scrollTo(elementId, {
      duration: 500,
      smooth: true,
    });
  };

  const id = (props.id ?? '');

  return (
        <nav className={classes.sideNavBarInner}>
          <PrimaryImage
              primaryImageSrc={CoreDebug.passThru('primaryImage',
                  props.primaryImageUrl)}
              userName={props.userName ?? undefined}
              entityName={props.entityName}
              entityId={id}
              allowEditing={true}
              alt={props.name}
              style={{width:'64px', height: '62px'}}
          />

          <List>
            {sideNavBarData.map((item: DetailNavItemModel) => {
              return (
                    <ListNavItem to={item.to}
                         icon={icons[item.iconName]}
                         text={item.text}
                         key={item.text}
                        />
                  );
              })
            }
        </List>
          <div className="nav-blend">

          </div>
        </nav>
   );
}
