import {Box, Button, Grid, IconButton} from '@material-ui/core';
import {SortByAlpha, AttachMoney, CropFree,
  KeyboardArrowDown, KeyboardArrowUp, History, LocationOn } from '@material-ui/icons';
import SearchViewMenu from "../../SearchViewMenu";
import useDashUrlInfo from "hooks/useDashUrlInfo";
import { makeStyles } from '@material-ui/core/styles';
import {ClassNames} from "@syncfusion/ej2-react-buttons";
import {Fragment} from "react";


const useStyles = makeStyles({
  cardViewSortToolbar: {
    padding: 0,
    border: '1px solid #ccc',
    backgroundColor: '#eee',
    height: '64px',
    width: '100%',
  },
  sortCell: {
    width: "100%",
    height: '100%',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    height: '100%',
  },
});


export default function CardViewSortBar(props: { sort?: string,
  sortClick: (field:string, directions: string) => void}){

  const classes = useStyles();
  const {entityName} = useDashUrlInfo();

  //console.log(`typeof props.sort ${typeof props.sort}`, {props_sort: props.sort});
  const parts = props.sort?.split(' ') ?? ['name', 'asc'];
  parts[1] = parts[1] !== 'desc' ? 'asc' : 'desc';
  const sort = parts[0];
  const direction = parts[1];

  function generateCell(field: string, direction: string, icon: JSX.Element) {
    return (
        <div className={classes.sortCell} style={{flexBasis: 0, flexGrow: 1}}>
          <Button aria-label={'sort by ' + field} className={classes.button}
                  onClick={() => props.sortClick(field, direction)}>
            {icon}
            {sort === field ? (direction === 'desc' ? <KeyboardArrowUp/> : <KeyboardArrowDown/>) : null}
          </Button>
        </div>
    );
  }

  return (
      <div className={classes.cardViewSortToolbar}>
        <div style={{display: 'flex'}}>
          <div style={{flexGrow: 1, display: 'flex'}}>
            {entityName === 'projects' ? (
                [generateCell('name', direction, <SortByAlpha/>),
                  generateCell('cost', direction, <AttachMoney/>),
                  generateCell('squareFootage', direction, <CropFree/>)]
            ) : (
                [generateCell('name', direction, <SortByAlpha/>),
                  generateCell('location', direction, <LocationOn/>),
                  generateCell('hpExperience', direction, <History/>)]
            )}
          </div>
        </div>
      </div>
  );
}