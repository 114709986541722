import _ from "lodash";

export function addSuffix(name: string, suffix: string): string {
    const str = name ?? '[[error]]';
    if (_.endsWith(str, suffix)) {
        return str;
    } else {
        return str + suffix;
    }
}

export function removeSuffix(name: string, suffix: string): string {
    const str = name ?? '[[error]]';
    if (_.endsWith(str, suffix)) {
        return _.trimEnd(str, suffix);
    } else {
        return str;
    }
}

export const escapeSingleQuotes: { (str: string): string } = (str) => {
    const escapedStr = str.replaceAll(`'`, `''`);
    // earlier ts
    // const escapedStr = qv.replace(/'/g, '\'\'');
    return escapedStr;
}

export const stringIsBoolean: { (str: string): boolean } = (str) => {
    return str in ['true', 'false'];
}

export function caseInsensitiveSort(values?: string[]) {
    function caseInsensitiveCompare(a: string, b: string) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }

    if (values === undefined) {
        return undefined;
    }

    return values.sort(caseInsensitiveCompare);
}

export function splitOnCaseChange(input: string): string[] {
    // Split the input string on every case change using a regular expression
    return input.split(/(?=[A-Z])/);
}

export function buildUrlFromTemplate(template: string, values: { [key: string]: string }): string {
    let result = template;
    for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
            const value = values[key];
            const placeholder = `{${key}}`;
            result = result.replace(placeholder, value);
        }
    }
    return result;
}

// export function buildStringFromTemplate(template: string, args: string[]): string {
//     let result = template;
//     const pattern = /{([^{}]+)}/g;
//     let match;
//     while ((match = pattern.exec(template)) !== null) {
//         const placeholder = match[0];
//         const index = parseInt(match[1], 10);
//         if (index < args.length) {
//             const value = args[index];
//             result = result.replace(placeholder, value);
//         }
//     }
//     return result;
// }

export function buildStringFromTemplate(template: string, args: string[]): string {
    let result = template;
    const pattern = /{([^{}]+)}/g;
    let match;
    while ((match = pattern.exec(template)) !== null) {
        const placeholder = match[0];
        const key = match[1];
        const value = args[key];
        if (value != null) {
            result = result.replace(placeholder, value);
        }
    }
    return result;
}
