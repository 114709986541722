import { loginRequest } from 'config/msalConfig';
import { FC } from 'react';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';

type AzureAdProtectedProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const AzureAdProtected: FC<AzureAdProtectedProps> = ({ children }) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      //errorComponent={ErrorComponent}
      //loadingComponent={LoadingComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

export default AzureAdProtected;
