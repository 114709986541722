import {useEffect, useState} from 'react';
import AthenaClient, {
  IAthenaFacet, ISearchResultSetOfQueryablePerson,
} from "services/apiClients/AthenaClient";
import MetaClient from "services/apiClients/MetaClient";
import {IOptionWithImage, IDropDownOptions, normalizeFacetValues} from './localTypesAndUtil';
import useAthenaClient from "../useAthenaClient";

const initOptions: { [key: string]: string[] } = {};

export default function useFieldOptionsForPeople() {
  const [options, setOptions] = useState<{ [key: string]: string[] }>(initOptions);
  const {athenaClient} = useAthenaClient();
  const newState = {} as { [key: string]: string[] };

  useEffect(() => {
    async function getPeopleDropDownLists() {
      const {facets}: ISearchResultSetOfQueryablePerson =
          await (athenaClient as AthenaClient).getAllPeople(undefined, undefined, undefined, 0, undefined);
      if (facets) {
        newState['accreditations'] = normalizeFacetValues(facets['accreditation']);
        newState['schools'] = normalizeFacetValues(facets['schools']);
      }
      setOptions(newState);
    }

    if (athenaClient) {
      getPeopleDropDownLists()
          .catch((reason) => console.log('error in useFieldOptionsForPeople:', reason));
    }
  }, [athenaClient]);

  return {options} as IDropDownOptions;


}

