import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {CircularProgress} from "@material-ui/core";

export default function Redirect() {
  const navigate = useNavigate();
  const {entityName} = useParams();

  // function splitThenNavigate(pathAndSearch: string) {
  //   const [pathname, search] = pathAndSearch.split('?');
  //   navigate(pathname, {search});
  // }

  useEffect(() => {
    console.log('stuff', process.env.REACT_APP_DEFAULT_PROJECT_SEARCH_URL);

      if (entityName?.toLowerCase() === 'projects') {
        navigate(process.env.REACT_APP_DEFAULT_PROJECT_SEARCH_URL, {replace:true});
      }
      if (entityName?.toLowerCase() === 'people') {
        navigate(process.env.REACT_APP_DEFAULT_PEOPLE_SEARCH_URL, {replace:true});
      }

  }, [navigate]);

  return <CircularProgress />
}