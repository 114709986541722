import React from 'react';
import { IQueryablePerson } from 'services/apiClients/AthenaClient';
import { getDistrictDisplayName } from 'services/metadata/regionsEx';
import { currencyFmt, getAge } from 'utils/numberToStringUtil';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrimaryImage from "../../../app/primaryImage/feature/PrimaryImage";
import CoreDebug from "../../../features/core/CoreDebug";

const useStyles = makeStyles(() => ({
  card: {
    display: 'block',
    width: '415px',
    minWidth: '415px',
    maxWidth: '415px',
    height: '100%',
    margin: '10px',
    backgroundColor: '#fff',
  },
  summaryArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
  },
  typo: {
    fontFamily: 'Helvetica',
  },
  marked: {
    flex: '1',
    margin: '5px',
  },
}));

function PersonCard(props: { model: IQueryablePerson, onNavDetails: (entityName: string, id: string) => void }) {
  const classes = useStyles();
  const person = props.model;
  const idStr = props.model?.id ? props.model.id.toString() : '';

  function handleClick() {
    props.onNavDetails('people', idStr);
  }

  return (
      <div className={classes.card}
           key={'person:' + String(props.model.id)}>
        <div className={classes.summaryArea} style={{textAlign: 'left'}} onClick={handleClick}>
          <div className={classes.marked}>
            <div className="flex flex-row justify-between">
              <div>
                <Typography variant="h5" className={classes.typo}>{person.name}</Typography>
              </div>
            </div>
            <Typography variant="h6">{person.title}</Typography>
            <Typography variant="h6">{getDistrictDisplayName(person.district)}</Typography>
            <div style={{display: 'flex'}}>
              <div style={{flexBasis: 'auto', textAlign: 'left'}}>
                <PrimaryImage primaryImageSrc={person.primaryImageUrl}
                              userName={person.userName}
                              entityId={String(person.id)}
                              entityName={'people'}
                              style={{width: '150px', height: '150px', margin: '15px'}}/>
              </div>
              <div style={{flexGrow: 1, textAlign: 'left'}}>
                <Typography variant="body2">{person.city && person.state &&
                    `Lives in ${person.city}, ${person.state}`}</Typography>
                <Typography variant="body2">{person.hireDate &&
                    `With Hensel Phelps: ${getAge(person.hireDate)}`}
                </Typography>
                <Typography variant="body2">{person.totalProjectValue != null && person.totalProjectValue > 0 &&
                    `Total Project Value: ${currencyFmt.format(person.totalProjectValue)}`}</Typography>
              </div>
            </div>
            <Typography variant="body2" color="textSecondary">
              {/* future use */}
            </Typography>
          </div>
        </div>
      </div>
  );
}

export default PersonCard;
