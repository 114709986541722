import {makeStyles} from "@material-ui/core/styles";
import {
  ApiContact,
  ApiPersonReference,
  IApiContact,
  IApiPersonReference,
  IPersonContact
} from "../../../../services/apiClients/AthenaClient";

export const usePersonReferenceStyle = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    width: '100%',
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "flex-start", // 'space-around',
    //alignItems: 'space-around',
  },
  textField: {
    //flexGrow: 1,
    flexBasis: '45%',
    midWidth: '400px',
    margin: '10px',
  }
}));

export function createPersonContactForDisplayModel(contact: IApiContact | Record<string, unknown>, personId: number, personGuid: string) {
  let companyName = '';
  let comments = '';

  if ('company' in contact) {
    companyName = ''; // contact.company['name'] ?? '';
  } else {
    companyName = ((contact as Record<string, unknown>)['companyName'] ?? '') as string;
    comments = ((contact as Record<string, unknown>)['comments'] ?? '') as string;
  }
  const obj = {
    personGuid: personGuid,
    personId: personId,
    contactId: contact.id,
    crmId: contact.crmId,
    fullName: contact.fullName,
    jobTitle: contact.jobTitle,
    companyName: companyName,
    comments: comments,
    referenceType: '',
    //companyId: apiContact.companyId,
    email: contact.email,
    email2: contact.email2,
    businessPhone: contact.businessPhone,
    mobilePhone: contact.mobilePhone,
    homePhone: contact.homePhone,
    streetAddress: contact.streetAddress,
    streetAddress2: contact.streetAddress2,
    city: contact.city,
    state: contact.state,
    zip: contact.zip,
    country: contact.country,
    isManagedByCrm: !!contact.crmId,
    crmModifiedBy: contact.crmModifiedBy,
    crmModifiedOn: contact.crmModifiedOn,
  };
  console.log('!@#! critical model map to form', obj);
  return obj;
}

export function createPersonContact(personId: number, personGuid: string, contactId: string, referenceType?: string, comments?: string) {
  return {personId, personGuid, contactId, referenceType, comments} as IPersonContact;
}

export interface IAddReferenceProps {
  personFullName?: string,
  contactId: string,
  personId: number,
  personGuid: string,
  personReference?: IApiPersonReference | null;
  id?: string, // referenceId
  mode: string,
  open: boolean,
  onClose: () => void
}

export const createApiPersonReference = (apiContact: ApiContact, personId: number, personGuid: string): ApiPersonReference => {
  // Initialize a new ApiPersonReference object
  const apiPersonReference = new ApiPersonReference();

  // Fields that match by name and type
  apiPersonReference.personId = personId;
  apiPersonReference.personGuid = personGuid;
  apiPersonReference.contactId = apiContact.id;
  apiPersonReference.salutation = apiContact.salutation;
  apiPersonReference.fullName = `${apiContact.firstName} ${apiContact.lastName}`;
  apiPersonReference.middleName = apiContact.middleName;
  apiPersonReference.jobTitle = apiContact.jobTitle;
  apiPersonReference.companyId = apiContact.companyId;
  apiPersonReference.email = apiContact.email;
  apiPersonReference.email2 = apiContact.email2;
  apiPersonReference.mobilePhone = apiContact.mobilePhone;
  apiPersonReference.homePhone = apiContact.homePhone;
  apiPersonReference.streetAddress = apiContact.streetAddress;
  apiPersonReference.streetAddress2 = apiContact.streetAddress2;
  apiPersonReference.city = apiContact.city;
  apiPersonReference.state = apiContact.state;
  apiPersonReference.zip = apiContact.zip;
  apiPersonReference.country = apiContact.country;
  apiPersonReference.crmModifiedOn = apiContact.crmModifiedOn;
  apiPersonReference.crmModifiedBy = apiContact.crmModifiedBy;
  apiPersonReference.crmId = apiContact.crmId;
  apiPersonReference.isActive = apiContact.isActive;
  apiPersonReference.firstName = apiContact.firstName;
  apiPersonReference.lastName = apiContact.lastName;
  apiPersonReference.businessPhone = apiContact.businessPhone;

  // Fields that need further consideration
  // company (Company)
  // How to map this field depends on the structure and need
  // apiPersonReference.company = ???

  // Fields in ApiPersonReference but not in ApiContact
  // contactId (string)
  // personGuid (string)
  // personId (number)
  // isManagedByCrm (boolean)
  // comments (string)
  // referenceType (string)
  // quoteCommendation (string)
  // These fields would need to be populated based on specific business logic or additional data sources

  return apiPersonReference;
};
