import {withDashCard} from '../../shared/withDashCard';
import ProjectStakeholderApprovalSection from "./ProjectStakeholderApprovalSection";
import ProjectStakeholderAssocSection from "./ProjectStakeholderAssocSection";

const ProjectStakeholdersCard = withDashCard('Project Stakeholders', 'project-stakeholders-module',
    // list of components to add to the card (in order they should appear)
    [
      ProjectStakeholderApprovalSection,
      ProjectStakeholderAssocSection,
    ], 'stakeholders', true
);

export default ProjectStakeholdersCard;