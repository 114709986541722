import React, {FC, useState} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  GridSize,
  MenuItem,
  Switch
} from "@material-ui/core";
import {ApiPhaseVdcTechnology, ApiVdcTechnology, IApiPhaseVdcTechnology} from "services/apiClients/AthenaClient";
import {currencyFmt} from "utils/numberToStringUtil";


const useStyles = makeStyles((theme: Theme) =>
createStyles({
  cellTitle: {
    fontWeight: 'bold',
    backgroundColor: '#eee',
    padding: theme.spacing(2),
  },
  cell: {
    fontWeight: 'bold',
    padding: theme.spacing(2),
    '& span': {
      fontWeight: 'normal',
    },
  },
}));


interface IAddEditPBMTechDialogProps {
  dialogMode?: string | null;
  model?: IApiPhaseVdcTechnology | null;
  onAction: (action: string, payload?: ApiPhaseVdcTechnology | string | null | Record<string, unknown>) => void;
}


function PreviewVdcTechDialog(props: IAddEditPBMTechDialogProps) {
  const classes = useStyles();

  return (
      <>
        {!!props.dialogMode &&
            <Dialog id="previewVdcTechDialog" fullWidth={true} maxWidth={'lg'}
                    open={!!props.dialogMode}>
                <DialogTitle>Technology</DialogTitle>
                <DialogContent>
                  <div style={{paddingBottom:'30px'}}>
                      <Grid container spacing={5} style={{width: '100%'}}>
                          <Grid item xs={12}>
                              <b>PBM Technology</b><br/>
                            { props.model?.path }
                          </Grid>
                          <Grid item xs={4} container>
                              <Grid item className={classes.cell} xs={12}>
                                  Work Hours:&nbsp;<span>{props.model?.workHours}</span>
                              </Grid>
                              <Grid item className={classes.cell} xs={12}>
                                  Duration:&nbsp;<span>{props.model?.workHours}</span>
                              </Grid>
                              <Grid item className={classes.cell} xs={12}>
                                  Cost:&nbsp;<span>{props.model?.cost ? currencyFmt.format(props.model.cost): ''}</span>
                              </Grid>
                              <Grid item className={classes.cell} xs={12}>
                                  Number of People:&nbsp;<span>{props.model?.numPeople}</span>
                              </Grid>
                              <Grid item className={classes.cell} xs={12}>
                                  Star Rating:&nbsp;<span>{props.model?.starRating}</span>
                              </Grid>
                          </Grid>
                          <Grid item xs={8} container style={{border:'1px solid #eee'}}>
                              <Grid item xs={12} className={classes.cellTitle}>
                                  SME
                              </Grid>
                              <Grid item xs={6} className={classes.cell}>
                                  Company:&nbsp;<span>{ props.model?.smeCompany }</span>
                              </Grid>
                              <Grid item xs={6} className={classes.cell}>
                                  Company Category&nbsp;<span>{ props.model?.companyCategory }</span>
                              </Grid>
                              <Grid item xs={6} className={classes.cell}>
                                  First Name:&nbsp;<span>{ props.model?.smeFirstName }</span>
                              </Grid>
                              <Grid item xs={6} className={classes.cell}>
                                  Last Name:&nbsp;<span>{ props.model?.smeLastName }</span>
                              </Grid>
                              <Grid item xs={6} className={classes.cell}>
                                  Position:&nbsp;<span>{ props.model?.smePosition }</span>
                              </Grid>
                              <Grid item xs={6} className={classes.cell}>
                                  Phone Number:&nbsp;<span>{ props.model?.smePhone }</span>
                              </Grid>
                              <Grid item xs={6} className={classes.cell}>
                                  Email:&nbsp;<span>{ props.model?.smeEmail }</span>
                              </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            Notes:<br/>
                                  <div dangerouslySetInnerHTML={{ __html: props.model?.notes ?? '' }} />
                          </Grid>
                      </Grid>
                  </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onAction('close')}>Close</Button>
                </DialogActions>
            </Dialog>
        }
      </>
  );
}

export default PreviewVdcTechDialog;
