import React, { useState, useEffect,
  useImperativeHandle, forwardRef, useRef, ForwardedRef
} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DisplayField from "../../shared/DisplayField";
import EditIcon from '@material-ui/icons/Edit';
import AthenaClient, {
  ApiPerson, ApiProject,
  ApiProjectPhaseLabel,
  ApiProjectTechnologyPhase,
  ApiTechnology,
  IApiPerson,
  IApiProject,
  IApiProjectPhaseLabel,
  IApiProjectStakeholder,
  IApiProjectTechnologyPhase,
  IApiTechnology
} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {
  Card, CardContent, CardHeader,
  CircularProgress, Switch, Dialog, Button, IconButton, Typography, DialogTitle, DialogContent, DialogActions
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import DashDetailsField from "components/DashDetailsField";
import numberFormatter from "services/shared/numberFormatter";
import EntityDialog from "../../shared/EntityDialog";
import {getNamedData} from "utils/forms";
import PersonDetailService from "services/PersonService";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import { v4 as uuidv4 } from 'uuid';
import {commasFmt, currencyFmt } from "utils/numberToStringUtil";
import {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {AddCircleOutline} from "@material-ui/icons";
import PhaseList from "./PhaseList";
import PhaseDialog from "./PhaseDialog";
import {DataRow} from "../DashList2";
import {confirmDelete} from "patterns/confirmations";
import useToast from "../../../../hooks/useToast";
import DashToaster from "../../../../patterns/DashToaster";
import {useGlobalState} from "../../../../app/state/useGlobalState";
import AthenaAuthorize from "../../../../app/security/ui/AthenaAuthorize";
// END IMPORTS

const SECTION_ID = 'technologyNarrativesSection';

const TechnologyNarrativesSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & {onSave?: ()=> void}>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const gs = useGlobalState();
      const [model, setModel] = useState<IApiProject | null>(null);
      const [saveCount, saved] = useSaveCount();
      const {displayToast} = useToast();
      const dashToaster = new DashToaster(displayToast);
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey,setSectionKey] = useState(uuidv4());

      const [dialogMode, setDialogMode] = useState<string | null | undefined>(null);
      const [phaseModel, setPhaseModel] = useState<IApiProjectTechnologyPhase | null>(null);
      const [currentDialog, setCurrentDialog] = useState<string | null>(null);
      const [usedChoices, setUsedChoices] = useState<string[]>([]);

      const phaseChoiceObjs = [{"id":"e65670e1-f4e4-4798-bb28-418074b2798b","label":"Procurement","order":0},
                            {"id":"04ec05d9-24de-44f4-91d3-ca35769d53c1","label":"Preconstruction","order":1},
                            {"id":"fc2bd28d-0882-41d3-9ca8-2a7dd18b4b07","label":"Construction","order":2},
                            {"id":"6953af87-5c27-4af5-84d3-a2ada11efcda","label":"Operations","order":3}] as IApiProjectPhaseLabel[];
      const phaseChoices = phaseChoiceObjs.map(x => x.label) as string[];

      if (model === null) {
        setModel(props.model);
      }

      const toggleEditMode = () => {
        props.setEditMode(!props.editMode);
      }

      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);

          const resultModel = await athenaClient?.patchProject(data);
          setModel(resultModel as ApiProject);
          await new Promise(resolve => setTimeout(resolve, 1200));
          props.setEditMode(false);
          saved();
          return true;
        }
      }));

      if (props.model === null || model === null) {
        console.log('project is null');
        return <DashItemProgress/>;
      }

      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      // CUSTOM TYPESCRIPT
      function getAvailablePhaseChoices(project: IApiProject) {
        if (!project.technologyPhases) {
          return phaseChoices;
        }
        const usedPhases = project.technologyPhases.map(x => x.phaseLabel ?? '');
        const availablePhases = phaseChoices.filter(p => !usedChoices.includes(p));
        return availablePhases;
      }

      function handleAddPhase() {
        setCurrentDialog(null);
        setDialogMode('new');
      }

      function handleAddPhaseCancel() {
        setDialogMode(null);
        saved();
      }

      function handleAddPhaseSave(phaseLabel: string, phaseNarrative: string) {
        // TODO: fix from the server
        // Add new methods Get Technology Phases and Upsert Technology phase
        console.log('!!!! coming back from dialog', {phaseLabel, phaseNarrative});
        const tmpPhase = {
          phaseLabel: phaseLabel,
          narrative: phaseNarrative,
          technologies: [] as IApiTechnology[],
        } as ApiProjectTechnologyPhase;
      }

      function handlePhaseAction(action: string, phase?: IApiProjectTechnologyPhase) {
        //console.log('!!! phase edit model', phaseModel);
        const id = phase?.id as string;
        const friendlyName = phase?.phaseLabel as string;
        switch (action) {
          case 'cancel':
            setDialogMode(null);
            break;
          case 'edit':
            setDialogMode('edit');
            setPhaseModel(phase ?? null);
            console.log('!!! edit trigger - phaseModel', phase);
            break;
          case 'create':
            athenaClient?.createProjectTechnologyPhase(phase as ApiProjectTechnologyPhase, model?.id ?? '')
                .then(project => {
                  console.log(project);
                  setDialogMode(null);
                  dashToaster.display('The record has successfully been updated');
                  saved();
                  handlePhaseAction('refresh');
                });
            break;
          case 'update':
            athenaClient?.updateProjectTechnologyPhase(phase as ApiProjectTechnologyPhase,
                phase?.id as string,
                props.model.id as string).then(() => {
                  setDialogMode(null);
                  dashToaster.display('The record has successfully been updated');
                  saved();
              handlePhaseAction('refresh');
            });
            break;
          case 'delete':
            if(confirmDelete(friendlyName)) {
              athenaClient?.deleteProjectTechnologyPhase(id, props.model.id as string).then(() => {
                dashToaster.display('The record has successfully been updated');
                saved();
                handlePhaseAction('refresh');
              });
            }
            break;
          case 'close':
            setDialogMode(null);
            break;
          case 'refresh':
            setDialogMode(null);
            athenaClient?.getProject(props.model.id as string).then(project => {
              setModel(project);
            });
            break;
          default:
            console.log(`action is not known to ${SECTION_ID}: `, action);
            break;
        }
      }

      // END CUSTOM EVENT HANDLING
      console.log('model.technologyPhases', model.technologyPhases);

      return (
          <React.Fragment>
            <div style={{width:'100%',paddingBottom:'5px'}}>
              <AthenaAuthorize allowedRoles={'Contributor'}>
                <Button startIcon={<AddCircleOutline />} onClick={handleAddPhase}>Add Phase</Button>
              </AthenaAuthorize>
            </div>
            <PhaseList modelList={model.technologyPhases ?? []}
                         onPhaseAction={handlePhaseAction} />
            <DashDivider width="80vw" height="5px" />

            <React.Fragment key={'phaseDialog' + saveCount}>
              <PhaseDialog model={props.model as IApiProject}
                           phaseModel={phaseModel ?? undefined}
                           choices={getAvailablePhaseChoices(model)}
                           mode={dialogMode}
                           onPhaseAction={handlePhaseAction}
              />
            </React.Fragment>


          </React.Fragment>
      );
    });

export default withDashSection('Technology Narratives', SECTION_ID, TechnologyNarrativesSection, true);

