import React, {useEffect, useState} from 'react';
import {NavigateOptions, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {Button, Menu, MenuItem, Typography} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import noop from 'utils/noop';
import {makeStyles} from '@material-ui/core/styles';
import {useAppFocusState} from "recoil/appFocus";

const useStyles = makeStyles((theme) => ({
  middleAppBarContainer: {
    textTransform: 'none'
  },
  icon: {
    color: '#fff',
  },
  menu: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main
  },
  button: {
    color: '#fff',
    textTransform: 'none',
  },
}));

interface IMiddleMenuProps {
  showMenu?: boolean;
  alternateText?: string;
}

import { useLocation } from 'react-router-dom';
import CoreLocation from "../../../features/core/CoreLocation";

export default function MiddleMenu(props: IMiddleMenuProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [middleAppBarName, setMiddleAppBarName] = useState<string>('x');
  const [appFocusState, {setAppFocusState}] = useAppFocusState();

  let displayName = '';

  useEffect(() => {
    console.log('middleMenu useEffect appFocusState',appFocusState);
    if (appFocusState.entityId) {
      displayName = appFocusState.entityDisplayName ?? 'missing';
    } else {
      // search mode
      switch (location.pathname.slice(1)) {
        case 'people':
          displayName = 'DASH - People';
          break;
        case 'projects':
          displayName = 'DASH - Projects';
          break;
      }
    }
    console.log('displayName', displayName);
    setMiddleAppBarName(displayName);
  },[appFocusState]);

  noop(props);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (selectedEntityName: string) => {
    const entity = selectedEntityName.toLowerCase().trim();
    setAppFocusState({entityName:entity});
    const peopleHome = '/people?status=oneof."Active"';
    const projectsHome = '/projects?nonHenselPhelpsProject=oneof."false"';
    navigate(entity === 'people' ? peopleHome: projectsHome, {state: { rand: Math.random() }});
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <div className={classes.middleAppBarContainer}>
        { ['DASH - Projects', 'DASH - People'].includes(middleAppBarName) ?
            (<Button className={classes.button} onClick={(e) => handleClick(e)}>
              <Typography>{middleAppBarName}</Typography>
              <ArrowDropDown className={classes.icon}/>
            </Button>)
            :(<Typography>{middleAppBarName}</Typography>)}
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
          <MenuItem onClick={() => handleMenuItemClick('projects')}>Projects</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('people')}>People</MenuItem>
        </Menu>
      </div>
  );
}
