import React from "react";
import {IApiProjectTechnologyPhase, IProjectApproval} from "services/apiClients/AthenaClient";
import DashDetailsField from "../../../DashDetailsField";
import {Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import {Delete} from "@material-ui/icons";
import AthenaAuthorize from "../../../../app/security/ui/AthenaAuthorize";


interface IPhaseListProps {
  modelList?: IApiProjectTechnologyPhase[];
  onPhaseAction: (action: string, phase: IApiProjectTechnologyPhase) => void;
}

interface IPhaseCardProps {
  phase: IApiProjectTechnologyPhase;
  onPhaseAction: (action: string, phase: IApiProjectTechnologyPhase) => void;
}


function PhaseList(props: IPhaseListProps) {

  const randomMaxInt = () => {
    return Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 1000000)) + 1;
  };

  const r = randomMaxInt();

  return (
      <React.Fragment>
        { (props.modelList ?? []).map((phase, index) => (
            <Card style={{width:'100%',marginTop:'5px'}} key={index}>
              <CardHeader title={phase.phaseLabel} action={
                <>
                  <AthenaAuthorize allowedRoles={'Contributor'}>
                    <IconButton aria-label="edit"
                                onClick={() => props.onPhaseAction('edit', phase)}>
                      <Edit />
                    </IconButton>
                    <IconButton aria-label="delete"
                                onClick={() => props.onPhaseAction('delete', phase)}>
                      <Delete />
                    </IconButton>
                  </AthenaAuthorize>
                </>
              } />
              <CardContent>
                <DashDetailsField mode={'richText'} label={''} showLabel={false}
                                  editMode={false} defaultValue={phase.narrative} />
                {/*<b>{phase.approval?.toString()}</b>*/}
                <DashDetailsField mode={'approval'} label={''} showLabel={false} key={r + index}
                                  editMode={false}
                                  approvalKey={'fakeKey'}
                                  infoMode={'minimal'}
                                  valueGraph={{approvals:[{
                                      approvalKey: 'fakeKey',
                                      isApproved: phase.approval,
                                      modifiedBy: phase.approvalModifiedBy,
                                      modifiedOn: phase.approvalModifiedOn
                                    }]}} />
              </CardContent>
            </Card>
        ))
        }
      </React.Fragment>
  )
}

export default PhaseList;
