import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  copySpan: {
    // Add your styles here
  },
  hiddenSpan: {
    color: 'white',
    backgroundColor: 'white',
  },
});

interface ICopySpanProps {
  text: string;
  visible?: boolean;
  hidden?: boolean;
}

const CopySpan: React.FC<ICopySpanProps> = (props) => {
  const classes = useStyles();

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(props.text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };

  if (props.visible === false) {
    return null;
  }

  return (
      <span
          className={`${classes.copySpan} ${props.hidden ? classes.hiddenSpan : ''}`}
          style={{cursor:'pointer'}}
          onClick={handleCopyClick}
      >{props.text}
      </span>
  );
};

export default CopySpan;
