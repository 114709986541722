import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import AthenaClient, {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";

import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {v4 as uuidv4} from 'uuid';
import * as util from './clientSectionUtil';
import useInfotipModal from "../../../../hooks/useInfotipModal";
import CoreDebug from "../../../../features/core/CoreDebug";
import globalState from "../../../../app/state/GlobalState";
import {useObserver} from "mobx-react";
import globalEventTarget from "../../../../app/events/globalEventTaget";
import {currencyFmt} from "../../../../utils/numberToStringUtil";
// end imports

const SECTION_ID = 'clientSection';

const ClientSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const [model, setModel] = useState<IApiProject>(props.model as IApiProject);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient, athenaClientRef} = useAthenaClient();
      //const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());
      const {showInfotipModal, InfotipModal} = useInfotipModal();

      if (model === null) setModel(props.model as IApiProject);

      useEffect(() => {
        async function reload() {
          if (athenaClientRef.current && props.model.id) {
            const m = await athenaClientRef.current.getProject(props?.model?.id as string);
            setModel(m);
            console.log('$$$@ model in ci', m)
          }
        }

        reload();
      }, [athenaClientRef.current, globalState.clientInformationTicks, props?.model?.id]);

      const toggleEditMode = () => props.setEditMode(!props.editMode);

      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);
          try {
            const client = (athenaClientRef.current as AthenaClient);
            const m = await client.patchProject(data);
            setModel(m as IApiProject);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));

      useEffect(() => {
        const handleEvent = async (event: Event) => {
          const customEvent = event as CustomEvent;
          const data = customEvent.detail;
          const client = athenaClientRef.current as AthenaClient;
          console.log('$x$ checkForClientChange 3 - customEvent + client', {data, client});
          const m = await client.updateProject(props?.model?.id as string, data);
          console.log('$x$ checkForClientChange 4 - new model', m);
          setModel(m as IApiProject);
          setSectionKey(uuidv4());
        };

        globalEventTarget.addEventListener('externalUpdateClientInfo', handleEvent);

        return () => {
          globalEventTarget.removeEventListener('externalUpdateClientInfo', handleEvent);
        };
      }, []);

      const handleInfotipClick = (key: string) => {
        showInfotipModal(key);
      }

      // if (!props.model) {
      //   return <DashItemProgress/>;
      // }
      const stakeholderOwner = model.stakeholders?.find((stakeholder) => stakeholder.role === 'Owner')
      const stakeholderName = stakeholderOwner?.name as string;
      const stakeholderCity = stakeholderOwner?.city as string;
      const stakeholderState = stakeholderOwner?.state as string;
      const stakeholderCityAndState = stakeholderCity && stakeholderState 
                                        ? stakeholderCity + ', ' + stakeholderState 
                                        : stakeholderCity && !stakeholderState 
                                          ? stakeholderCity 
                                            : !stakeholderCity && stakeholderState 
                                              ? stakeholderState 
                                              : null;
      const stakeholderVerticalMarkets = stakeholderOwner?.verticalMarkets;

      return useObserver(() =>
            <React.Fragment key={sectionKey + globalState.clientInformationTicks.toString()}>

              {/* id */}
              <DashDetailsField mode="hidden"
                                label="Id"
                                showLabel={false}
                                editMode={props.editMode}
                                name="id"
                                defaultValue={model.id}
              />

              {/* clientName */}
              <DashDetailsField mode="textWrap"
                                label="Client Name"
                                showLabel={true}
                                infotipKey="clientName"
                                width="47%"
                                editMode={false}
                                defaultValue={stakeholderName}
              />

              {/* clientLocation */}
              <DashDetailsField mode="text"
                                label="Client Location"
                                showLabel={true}
                                infotipKey="clientLocation"
                                width="47%"
                                editMode={false}
                                defaultValue={stakeholderCityAndState}
                                value={stakeholderCityAndState}
              />

              {/* clientContractProjectNumber */}
              <DashDetailsField mode="text"
                                label="Client Contract Number"
                                showLabel={true}
                                infotipKey="clientContractProjectNumber"
                                width="47%"
                                editMode={props.editMode}
                                name="clientContractProjectNumber"
                                defaultValue={model.clientContractProjectNumber}
              />

              {/* clientInfoSyncedWithCrm */}
              <DashDetailsField mode="checkSwitch_v2"
                                label="Synced With CRM"
                                showLabel={true}
                                infotipKey="clientInfoSyncedWithCrm"
                                width="47%"
                                editMode={false}
                                value={util.isSyncedWithCRM(model)}
              />

              {/* clientVerticalMarkets */}
              <DashDetailsField mode="multiAuto"
                                label="Client Vertical Markets"
                                showLabel={true}
                                infotipKey="clientVerticalMarkets"
                                width="47%"
                                editMode={false}
                                values={stakeholderVerticalMarkets}
                                choices={props.referenceData.options['clientVerticalMarkets']}
              />

              <DashDivider width="80vw" height="5px"/>
            </React.Fragment>
          );
    });

export default withDashSection('Client', SECTION_ID, ClientSection);
