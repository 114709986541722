import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from "react";
import {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {Dialog} from "@material-ui/core";

import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {formatInfotip, projectInfotips} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
// end imports

const SECTION_ID = 'securityNarrativeSection';

const SecurityNarrativeSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {

      const classes = useSharedLargeCardStyles();

      const [model, setModel] = useState<IApiProject>(props.model);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());

      if (model === null) {
        setModel(props.model);
      }


      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          // return await compSave();
          console.log('begin ' + SECTION_ID + ' save');
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);
          try {
            const m = await athenaClient?.patchProject(data);
            setModel(m as IApiProject);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));
      // end useImperativeHandle


      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (!props.model) {
        return <DashItemProgress/>;
      }

      return (
          <React.Fragment key={sectionKey}>
            {/* id */}
            <DashDetailsField mode="hidden"
                              label="Id"
                              showLabel={false}
                              editMode={props.editMode}
                              name="id"
                              defaultValue={model.id}
            />

            {/* securityNarrative */}
            <DashDetailsField mode="approval"
                              label=""
                              showLabel={true}
                              width="100%"
                              editMode={props.editMode}
                              name="securityNarrative"
                              valueGraph={model}
                              approvalKey="securityNarrative"
            />

            {/* securityNarrative */}
            <DashDetailsField mode="richText"
                              label=""
                              showLabel={true}
                              width="100%"
                              editMode={props.editMode}
                              name="securityNarrative"
                              defaultValue={model.securityNarrative}
            />

            <DashDivider width="80vw" height="5px"/>

          </React.Fragment>
      );
    });

export default withDashSection('Security Narrative', SECTION_ID, SecurityNarrativeSection,
    false, 'securityNarrative');
