import React, {useState, useEffect} from 'react';
import {
  TabAnimationSettingsModel,
  TabComponent,
  TabItemDirective,
  TabItemsDirective
} from '@syncfusion/ej2-react-navigations';
import {Button, Box, Card, CardContent, CardHeader, IconButton, Typography} from "@material-ui/core";
import {useSharedLargeCardStyles} from "../sharedStyles";

import {
  ApiProject,
  DamAssetMetadata,
  DocumentMetadata,
  IApiPerson, IApiProject,
  IApiProjectHistory,
  IAssets, IDamAssetMetadata,
  ImageMetadata, VideoMetadata
} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import SectionWithGridAndCardList from "../SectionWithGridAndCardList";
import {
  convertDegree, convertDocument, convertImage,
  convertProjectHistory,
  convertTraining,
  IInfoCard
} from "services/infoCardConversion";
import {
  getBaseConfig,
  getDocumentAssetConfig,
  getImageAssetConfig,
  getVideoAssetConfig
} from "../../personViewRelated/gridConfigSettings/asset";
import ChangeViewButton from "../../../dashCore/ChangeViewButton";
import {IServiceInstances, ServiceInstancesContext} from "providers/ServicesProvider/ServicesProvider";
import PersonDetailService from "../../../../services/PersonService";
import useAthenaClient from "../../../../hooks/useAthenaClient";
import includes from "lodash.includes";
import Carousel from "app/Carousel/Carousel";
import _ from "lodash";
import LinkToWebDAM from "../../../../app/webdam/ui/LinkToWebDAM";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import {useGlobalState} from "../../../../app/state/useGlobalState";
import ProjectAssetChecklistApprovalSection from "./ProjectAssetChecklistApprovalSection";
import useReferenceDataForProjects from "../../../../hooks/useFieldOptions/useReferenceDataForProjects";

export default function ProjectAssetCard(props: {model?: IApiPerson | IApiProject, entityName?: string}) {
  const {athenaClient} = useAthenaClient();
  const sharedClasses = useSharedLargeCardStyles();
  const referenceData = useReferenceDataForProjects();
  const gs = useGlobalState();
  const [listViewOn, setListViewOn] = useState<boolean>(true);
  const [refreshCount,setRefreshCount] = useState(0);

  const [images, setImages]= useState<ImageMetadata[]>([]);
  const [documents,setDocuments] = useState<DocumentMetadata[]>([]);
  const [videos,setVideos] = useState<VideoMetadata[]>([]);

  let imageInfoCards: IInfoCard[] = [];
  let docImageCards: IInfoCard[] = [];
  const modelId = props.model?.id?.toString() ?? '';

  let tabCtrl: TabComponent | null;
  //const [showGrid, setShowGrid] = useState(true);

  const [webdamFolderId, setWebdamFolderId] = useState(String(props?.model?.webdamFolderId ?? 0));
  const webdamFolderTmpl = process.env.REACT_APP_WEBDAM_URL_TMPL ?? '';

  function setTabCtrl(instance: TabComponent) {
    tabCtrl = instance;
    if (tabCtrl !== null) {
      tabCtrl.refreshActiveTab();
    }
  }

  useEffect(() => {
    if (athenaClient && modelId) {
      const personService = new PersonDetailService(athenaClient);
      const entityName = ('cost' in props.model) ? 'projects': 'people';

      athenaClient.getDamAssets(entityName, String(modelId))
            .then((assets: IDamAssetMetadata[]) => {
              const images = assets.filter(x => isImage(x)) as ImageMetadata[];
              setImages(images);
              const documents = assets.filter(x => !isImage(x) && !isVideo(x)) as DocumentMetadata[];
              setDocuments(documents);
              const videos = assets.filter(x => isVideo(x)) as VideoMetadata[];
              setVideos(videos);
              imageInfoCards = convertImage(images) as  IInfoCard[];
              docImageCards = convertDocument(documents) as IInfoCard[];
              //videoCards = convertVideo(videos) as IInfoCard[];
            })
      }
  }, [athenaClient, refreshCount]);

  function isImage(asset: IDamAssetMetadata) {
    if (asset.contentType === undefined) return false;
    const index = asset.contentType.toLowerCase()?.indexOf('image/');
    return index === 0;
  }

  function isVideo(asset: IDamAssetMetadata) {
    const fileExtension = asset.name!.substring(asset.name!.lastIndexOf('.') + 1);
    return includes(['mp4', 'avi', 'mov'], fileExtension.toLowerCase());
  }

  function changeView() {
    setListViewOn(!listViewOn);
  }

  const imageCards = [] as IInfoCard[]; //convertProjectHistory(items);
  const docCards = [] as IInfoCard[];
  const videoCards = [] as IInfoCard[];
  const imageConfig = getImageAssetConfig();
  const docConfig = getDocumentAssetConfig();
  const videoConfig = getVideoAssetConfig();

  console.log('images', images);
  const animation = {'next':{'effect': 'None'},
                      'prev':{'effect': 'None'}} as TabAnimationSettingsModel;

  const imagesTabContent = () =>
      (<SectionWithGridAndCardList listView={listViewOn}
                                  items={images}
                                  columnDirectives={imageConfig.columnDirectives}
                                  title={imageConfig.title}
                                  showTitle={imageConfig.showTitle}
                                  allowAdd={imageConfig.allowAdd}
                                  allowRemove={imageConfig.allowRemove}
                                  allowFiltering={true}
                                  allowExcelExport={false}
                                  toolbarOptions={imageConfig.toolbarOptions}
                                  infoCards={imageCards}/>);

  const documentTabContent = () =>
      (<SectionWithGridAndCardList listView={listViewOn}
                                   items={documents}
                                   columnDirectives={docConfig.columnDirectives}
                                   title={docConfig.title}
                                   showTitle={docConfig.showTitle}
                                   allowAdd={docConfig.allowAdd}
                                   allowRemove={docConfig.allowRemove}
                                   allowFiltering={true}
                                   allowExcelExport={false}
                                   toolbarOptions={docConfig.toolbarOptions}
                                   infoCards={docCards}/>);

  const videoTabContent = () =>
      (<SectionWithGridAndCardList listView={listViewOn}
                                   items={videos}
                                   columnDirectives={videoConfig.columnDirectives}
                                   title={videoConfig.title}
                                   showTitle={videoConfig.showTitle}
                                   allowAdd={videoConfig.allowAdd}
                                   allowRemove={videoConfig.allowRemove}
                                   allowFiltering={true}
                                   allowExcelExport={false}
                                   toolbarOptions={videoConfig.toolbarOptions}
                                   infoCards={docCards}/>);

  const canContrib = hasPermission(['Contributor'], gs.userRoles);

  function handleCarouselClose() {
    _.noop();
  }

  return (
      <>
        <Card id={'assets-module'} className={sharedClasses.defaultCard}>
          <CardHeader
              title={props.entityName === 'projects' ? 'Project Assets': 'Assets'}
              action={<ChangeViewButton showListIcon={listViewOn} onClick={changeView}/>} />
          <CardContent style={{display:'flex'}}>
            { props.model instanceof ApiProject &&
            <ProjectAssetChecklistApprovalSection id={'projectAssetChecklistApprovalSection'}
                                                  model={props.model as IApiProject}
                                                  referenceData={referenceData} />
            }

            <div className="w-full text-right">
              <LinkToWebDAM webdamFolderId={props.model?.webdamFolderId} />
            </div>

            <Carousel images={images.map(x => x.url as string)}
                      imageNames={images.map(x => x.name as string)}
                      onClose={handleCarouselClose} />

            <TabComponent ref={(r) => setTabCtrl(r as TabComponent)}
                  width={'100%'} animation={animation}
                          heightAdjustMode={'Content'}>
              <TabItemsDirective>
                <TabItemDirective header={{text:'Images'}} content={imagesTabContent} />
                <TabItemDirective header={{text:'Documents'}} content={documentTabContent} visible={canContrib}/>
                <TabItemDirective header={{text:'Videos'}} content={videoTabContent} />
              </TabItemsDirective>
            </TabComponent>

          </CardContent>
        </Card>
      </>

  )
}