import {ExportSchema, ITemplate} from "services/apiClients/AthenaClient";

export function makeSimpleTemplate(
    owner: string,
    entity: string,
    target: string,
    name: string,
    schema?: ExportSchema
): ITemplate {
  return {
    owner: owner,
    entity: entity,
    target: target,
    name: name,
    schema: schema,
  };
}
