import MetaClient from 'services/apiClients/MetaClient';
import {ISearchableFacetItem} from '../localTypes';
import {IAutocompleteHelper} from "./IAutocompleteHelper";

export default class LocationAutocompleteHelper implements IAutocompleteHelper {
  readonly SUBLOCATION_DELIMITER = '|';
  private usStates: { [key: string]: string };

  constructor() {
    const metaClient = new MetaClient();
    this.usStates = metaClient.getUSStateDictionary() as { [key: string]: string };
  }

  public updateSearchValues(facetItems: ISearchableFacetItem[]): ISearchableFacetItem[] {
    const newFacetItems = [] as ISearchableFacetItem[];
    for (let i = 0; i < facetItems.length; i++) {
      facetItems[i].searchValues = this.getSearchableValues(
          facetItems[i].value.split(this.SUBLOCATION_DELIMITER)
              .map(x => x.toLowerCase()));
      newFacetItems.push(facetItems[i]);
    }
    return newFacetItems;
  }

  // private getSearchableValues(searchValues: string[]): string[] {
  //     const firstSearchValue = searchValues[0];
  //     if (searchValues.length === 2
  //           && firstSearchValue in this.usStates
  //           && searchValues[searchValues.length - 1] === 'usa') {
  //         const alternate = this.usStates[firstSearchValue];
  //         return [firstSearchValue, alternate];
  //     } else {
  //         return [firstSearchValue];
  //     }
  // }

  private getSearchableValues(searchValues: string[]): string[] {
    const firstSearchValue = searchValues[0].toLowerCase(); // Convert to lower case
    if (searchValues.length === 2
        && firstSearchValue in Object.keys(this.usStates).map(k => k.toLowerCase()) // Convert keys to lower case
        && searchValues[searchValues.length - 1].toLowerCase() === 'usa') {
      const alternate = this.usStates[firstSearchValue];
      return [firstSearchValue, alternate];
    } else {
      return [firstSearchValue];
    }
  }

  // new
  private preFilterFacets(facetItems: ISearchableFacetItem[]): ISearchableFacetItem[] {
    const newFacetItems = [] as ISearchableFacetItem[];

    for (let i = 0; i < facetItems.length; i++) {
      if (i > 0) {
        if (facetItems[i - 1].value.toLowerCase() === facetItems[i].value.toLowerCase()) {
          newFacetItems.pop();
        }
        newFacetItems.push(facetItems[i])
      }
    }
    return newFacetItems;
  }

  public updateDisplayNames(facetItems: ISearchableFacetItem[]): ISearchableFacetItem[] {
    const newFacetItems = [] as ISearchableFacetItem[];
    const items = this.preFilterFacets(facetItems);
    for (let i = 0; i < items.length; i++) {
      // update the display name of the facet item based on the .value property
      items[i].display = items[i].value.split(this.SUBLOCATION_DELIMITER).join(', ');

      newFacetItems.push(items[i])
    }
    return newFacetItems;
  }

  public matchesString(facetItem: ISearchableFacetItem, searchValue: string): boolean {
    return facetItem.value === searchValue;
  }
}