import CoreDebug from "./CoreDebug";

export default class CoreFile {

  static async downloadFile(url: string, fileName?: string, bearerToken?: string, fileType?: string, entity?: string,): Promise<void> {

    if(entity === 'projects'){
    try {
      const mimeType = (fileType === 'xlsx')
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'application/xml';

      const binaryData = atob(url);
      const byteArray = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], {type: mimeType});

      const blobUrl = window.URL.createObjectURL(blob);
      const linkElement = document.createElement('a');
      linkElement.href = blobUrl;
      linkElement.download = fileName || 'DASH-file';
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    } catch (ex) {
      //this.log('!@# Error downloading file: ', ex);
    }
  }
  else if(entity === 'people'){
    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + bearerToken,
            'X-App-Name': 'dash-react',
            'X-Source': 'downloadFile',
        }
      });
      const blob = await response.blob();
      const contentDisposition = response.headers.get('content-disposition');
      //const contentType = response.headers.get('content-type');
      // log started download have gotten response and blob
      // just include the content-disposition header in the log stmt
      //console.log('downloadFile() response: ', response, 'blob: ', blob, 'contentDisposition: ', contentDisposition);
      const finalFileName = fileName || this.getFileName(contentDisposition) || 'DASH-file';
      const blobUrl = window.URL.createObjectURL(blob);
      const linkElement = document.createElement('a');
      linkElement.href = blobUrl;
      linkElement.download = finalFileName;
      linkElement.target = "_blank";
      linkElement.appendChild(document.createTextNode('Click here if your download does not start automatically'));
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
      } catch (ex) {
        console.log('Error downloading file: ', ex);
      }
    }
  }

  private static getFileName(contentDisposition: string | null): string | null {
    if (!contentDisposition) {
      return null;
    }
    const fileNameToken = contentDisposition.split(';').find((part) => part.includes('filename'));
    if (!fileNameToken) {
      return null;
    }
    const fileName = fileNameToken.split('=')[1];
    if (!fileName) {
      return null;
    }
    return fileName.trim().replace(/"/g, '');
  }

  static getFilenameSafeDateTime(): string {
    let dateTime = new Date().toISOString();
    dateTime = dateTime.split('.')[0];
    const filenameSafe = dateTime.replace(/:/g, '-');
    //console.log('filenameSafe:' + filenameSafe);
    return filenameSafe;
  }
}
