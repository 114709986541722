
class BrowserState {

  public cleanBrowserState(includeCookies = true) {
    localStorage.clear();
    sessionStorage.clear();
    if (includeCookies) {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c.replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    }
    console.log('cleanBrowserState');
  }

}

export default new BrowserState();
