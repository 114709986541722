import React, {useContext, useEffect, useState} from 'react';
import {ApiPerson, ApiProject, IApiPerson, IApiProject} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@material-ui/core";

import { cloneDeep, diff } from "utils/objGraph";
import LoadingButton from "../../dashCore/LoadingButton";
import ProgressButton from "../../dashCore/ProgressButton";

export default function EntityDialog(props: {
  entityName: string,
  model: IApiPerson | IApiProject,
  // cancelConfirmMessage: string,
  // saveConfirmMessage: string,
  handleClose: () => void,
  handleSave: () => void,
  //children: React.ReactNode,
  innerComponent: React.ReactNode,
}) {
  const [loading, setLoading] = useState(false);
  const [saveCount,setSaveCount] = useState(0);

  useEffect(() => {
    if (saveCount !== 0) {
      props.handleSave();
      console.log('!@@! handled save from Ent Dialog');
    }
  },[saveCount]);

  const handleCancel = () => {
    if (new Date().getTime() > 0 || confirm('Are you sure you want to cancel? Any changes will not be saved.')) {
      console.log('!!cancelled');
      props.handleClose();
    }
  }

  const save = () => {
    setSaveCount(x => x + 1);
  }

  return (
      <>
        {props.innerComponent}
      <Dialog open={true} fullWidth={true} maxWidth={'lg'}>
        <DialogContent>
          {props.innerComponent}
        </DialogContent>

        <DialogActions>
          <div className="flex flex-row">
            <div>
              <ProgressButton onClick={save} text={'Save'}></ProgressButton>
            </div>
            <div>
              <Button onClick={handleCancel}>Cancel</Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      </>
  )



}