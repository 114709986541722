import {Column, SortSettingsModel} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {Company, IApiPersonReference} from "services/apiClients/AthenaClient";
import {GreenCheck} from "../../../dashCore/GreenCheck";
import {RedX} from "../../../dashCore/RedX";
import {mkColumn} from 'components/detailViews/personViewRelated/gridConfigSettings/gridUtils';
import {hasPermission} from "../../../../app/security/utils/securityUtil";


export function getReferenceConfig(userRoles: string[]) {
  const columns = [
    mkColumn('fullName', 'Name'),
    mkColumn('referenceType', 'Reference Type'),
    mkColumn('company', 'Company'),
    {
      headerText: 'Synced with CRM',
      template: (data: IApiPersonReference) => {
        return data.isManagedByCrm ? <GreenCheck/> : <RedX/>;
      },
    } as unknown as Partial<Column>,
  ] as Column[];

  const exportColumns = [
    mkColumn('fullName', 'Name'),
    mkColumn('referenceType', 'Reference Type'),
    mkColumn('company', 'Company'),
    {
      headerText: 'Synced with CRM',
      field: 'isManagedByCrm',
      //displayAsColumnType: 'boolean',
      valueAccessor: (_: string, data: Partial<IApiPersonReference>): string => {
        return data.isManagedByCrm ? 'Yes' : 'No';
      },
    } as Partial<Column>,
  ] as Column[];

  const sortingOptions = {
    columns: [{field: 'fullName', direction: 'Ascending'}]
  } as SortSettingsModel;

  let toolbarOptions = [
    {text: 'View', prefixIcon: 'e-display-for-review', id: 'ViewRef'},
    {text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'},
  ];

  if (hasPermission(['Contributor'], userRoles)) {
    toolbarOptions = [
      {text: 'Add Reference', prefixIcon: 'e-circle-add', id: 'AddRef'},
      {text: 'View', prefixIcon: 'e-display-for-review', id: 'ViewRef'},
      {text: 'Unlink Reference', prefixIcon: 'e-circle-remove', id: 'UnlinkRef'},
      {text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'},
    ];
  }


  const config = {
    columnDirectives: columns,
    exportColumns: exportColumns,
    sortingOptions: sortingOptions,
    title: 'Awards',
    showTitle: false,
    allowAdd: true,
    allowRemove: true,
    allowFiltering: true,
    toolbarOptions: toolbarOptions,
  } as ISectionWithGridAndCardListConfig;
  return config;
}

// function handleExcelExport(args: ExcelExportEventArgs) {
//   const workbook: Workbook = args.workbook;
//   const worksheet: Worksheet = workbook.worksheets[0]; // Assuming only one worksheet is exported
//
//   // Modify the worksheet data as per your requirements
//   const headerRow: Row = worksheet.rows[0];
//   const dataRows: Row[] = worksheet.rows.slice(1);
//
//   // Custom logic for each column
//   for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
//     const column: Column = columns[columnIndex];
//
//     // Modify the cell values for the respective column
//     for (let rowIndex = 0; rowIndex < dataRows.length; rowIndex++) {
//       const cell: CellModel = dataRows[rowIndex].cells[columnIndex];
//
//       // Custom logic to get the appropriate value for the cell
//       const cellValue: string = getCellValue(column, cell.value);
//
//       // Set the modified cell value
//       cell.value = cellValue;
//     }
//
//     // Modify the header text for the respective column
//     const headerCell: CellModel = headerRow.cells[columnIndex];
//     headerCell.value = column.headerText;
//   }
// }

// function getCellValue(column: Column, originalValue: any): string {
//   if (column.valueAccessor) {
//     // If a custom valueAccessor is provided for the column, use it to get the cell value
//     return column.valueAccessor('', originalValue);
//   } else {
//     // Use the original cell value as-is
//     return originalValue;
//   }
// }

