import {IApiPerson} from "services/apiClients/AthenaClient";
import ResumeIntroLogic from "features/resume/ResumeIntroLogic";
import useAthenaClient from "hooks/useAthenaClient";
import {useEffect} from "react";

export default function ResumeIntro(props: {person: IApiPerson}) {
  const {athenaClient} = useAthenaClient();

  const logic = new ResumeIntroLogic();
  const resumeIntro = logic.makeResumeIntroByProcessing(props.person);

  useEffect(() => {
    if (resumeIntro === null) {
      const element = document.querySelector('.sectionHeading');
      if (element && element.textContent === 'Resume Introduction') {
        element.remove();
      }
    }
  }, []);

  if (resumeIntro === null) {
    return <div data-no-content={true}></div>;
  }

  return (
      <div className="w-full">
        <p className="break-normal whitespace-normal">
          {resumeIntro}
        </p>
      </div>
  );

}