import {Tooltip} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

interface IHovertipProps {
  title: string;
  visible?: boolean;
}

export default function Hovertip(props: IHovertipProps) {
  if (props.visible === true || props.visible === undefined) {
    return (
        <Tooltip title={props.title}>
          <InfoIcon fontSize={'small'} className="hovertip" />
        </Tooltip>
    )
  }
  return null;
}