import React from 'react';

const ChangeFragment: React.FC = ({ children }) => {
  return (
      <React.Fragment key={ new Date().getTime()}>
        {children}
      </React.Fragment>
  );
};

export default ChangeFragment;
