import React, {useEffect, useRef, useState} from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/luxon";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {DateTime} from "luxon";
import {toShortDate} from "./dashDetailFieldUtil";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import dateFormat from "../../patterns/dateFormat";
import {Button, IconButton} from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    '& .MuiInput-input': {
      color: 'black',
      textAlign: 'right',
    },
  },
  textFieldEdit: {
    width: '100%',
    '& .MuiInput-input': {
      color: 'black',
      textAlign: 'right',
      border: '1px solid #999'
    },
  },
}));

function parseDateTime(value: string | null | undefined | string[] | boolean | number | Date | DateTime | unknown): DateTime | null {
  if (value === undefined || value === null || typeof value === 'boolean') {
    return null;
  }

  // If the value is already a DateTime object, return it as is
  if (value instanceof DateTime) {
    return value;
  }

  // If the value is a Date object, convert it to a DateTime object
  if (value instanceof Date) {
    return DateTime.fromJSDate(value);
  }

  // Try parsing the value as a string using DateTime.fromISO
  const parsedDateTime = DateTime.fromISO(value as string);

  // If DateTime.fromISO returns an invalid date, return null
  if (!parsedDateTime.isValid) {
    return null;
  }

  // Return the parsed date as a DateTime object
  return parsedDateTime;
}



export default function DashDetailsDate(props: {value?: string | undefined | null | Date | DateTime | boolean | number | string[],
        editMode?: boolean,
        name?: string,
        label?: string,
        onChange?: (dateValue: Date | null) => void,
    }) {
  const classes = useStyles();
  //const valueStrRef = useRef<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(parseDateTime(props.value));
  const initValue: string | undefined | null | Date | DateTime | boolean | number | string[] = (props?.value) ? props.value : null;

  useEffect(() => {
    if(props.editMode === false){
      setSelectedDate(parseDateTime(initValue));
    }
  }, [props.editMode])

  function handleChange(nextValue: DateTime | null) {
    console.log('!@@@! nextValue = ', nextValue)
    console.log('typeof nextValue', typeof nextValue);
    console.log('parseDateTime(nextValue)', parseDateTime(nextValue));
    console.log('typeof parseDateTime(nextValue)', typeof parseDateTime(nextValue));
    const nextDateValue = parseDateTime(nextValue);
    setSelectedDate(nextDateValue);
    if (props.onChange) {

      props.onChange(selectedDate?.toJSDate() ?? null);
    }
    //props.onChange && props.onChange(nextValue);
  }

  const InputProps = { readOnly: true, disableUnderline: false };

  const handleClear = () => {
    setSelectedDate(null);
  };

  return props.editMode ?
      (<><MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            autoOk={true} disableToolbar variant="inline"
            clearable
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline" label="" name={props.name}
            // take controlled value approach
            value={selectedDate}
            onChange={handleChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputProps={{
              'data-is-date': true,
              'autocomplete': 'off',
            }}
        />
      </MuiPickersUtilsProvider>
      <IconButton onClick={() => handleClear()}><Clear/></IconButton>
      </>) : (
          <TextField className={classes.textField}
                     InputLabelProps={{ shrink: false }}
                     InputProps={InputProps}
                     disabled={true}
                     defaultValue={selectedDate ? selectedDate.toFormat('MM/dd/yyyy'): ''}
                     value={selectedDate ? selectedDate.toFormat('MM/dd/yyyy'): ''}>
          </TextField>);
}