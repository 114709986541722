import { DEFAULT, ENTERPRISE_ADMIN, SUPPORT } from 'constants/appRoles';
import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

type IdTokenClaims = {
  roles: string[];
};

const hasRole = (roles: string[], role: string): boolean => {
  const downgradedRolesValue = window.sessionStorage.getItem('downgradedRoles') ?? '';
  const downgradedRoles = downgradedRolesValue.split(',');
  return roles?.includes(role) && !downgradedRoles.includes(role);
};

const useRoles = () => {
  const { instance } = useMsal();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    const idTokenClaims = instance.getActiveAccount()?.idTokenClaims as IdTokenClaims;
    const allAccounts = instance.getAllAccounts();
    console.log('!@# idTokenClaims', idTokenClaims, instance, allAccounts);
    const roles = idTokenClaims?.roles ?? [];
    setRoles(roles);
  }, [instance]);

  const isEnterpriseAdmin = hasRole(roles, ENTERPRISE_ADMIN);
  const isDefaultRole = hasRole(roles, DEFAULT);
  const isSupportRole = hasRole(roles, SUPPORT);
  const isSupportRoleForTesting = roles?.includes(SUPPORT) ?? false;



  return {
    isEnterpriseAdmin,
    isDefaultRole,
    isSupportRole,
    isSupportRoleForTesting,
    roles,
  };
};

export default useRoles;
