import {withDashCard} from '../../shared/withDashCard';
import DesignPreconScheduleSection from "./DesignPreconScheduleSection";
import ConstructionScheduleSection from "./ConstructionScheduleSection";
import MilestonesSection from "./MilestonesSection";
import PreconEffortsSection from "./PreconEffortsSection";
import ScheduleDifferenceReasonsSection from "./ScheduleDifferenceReasonsSection";

const ScheduleCard = withDashCard('Schedule Information', 'schedule-information-module',
    // list of components to add to the card (in order they should appear)
    [
      DesignPreconScheduleSection,
      ConstructionScheduleSection,
      MilestonesSection,
      PreconEffortsSection,
      ScheduleDifferenceReasonsSection,
    ]
);

export default ScheduleCard;