import React, {useEffect, useState, FunctionComponent} from 'react';
import { useSearchParams } from 'react-router-dom';
import {AutocompleteFacet, DateRangeFacet,NumberRangeFacet} from '../facets';
import CategoryFacet from "../facets/CategoryFacet/CategoryFacet";
import BasicFacet from "../facets/BasicFacet";
import { makeStyles } from '@material-ui/core/styles';
import FacetService from 'services/search/FacetService';
import {IFacetFieldInfo, IFacetState, SideNavChildrenProps} from 'types/searchTypes';
import peopleFacets from 'constants/metadata/peopleFacets.json';
import projectsFacets from 'constants/metadata/projectsFacets.json';
import { serialize, deserialize } from "./serdeUtil";
import {Button, CircularProgress} from "@material-ui/core";
import useAthenaClient from "hooks/useAthenaClient";
import {ICriteria} from "services/SearchService";
import {encodeForAthenaFilters} from "services/search/QueryConvertor";
import useDashUrlInfo from "hooks/useDashUrlInfo";
import includes from "lodash.includes";
import CoreDebug from "../../../features/core/CoreDebug";
import {ExperienceFacet} from "../facets/ExperienceFacet/ExperienceFacet";

const useStyles = makeStyles(() => ({
  facetSideNavComp: {
    width: '293px',
    minWidth: '293px',
    height: '100%',
    overflowY: 'scroll',
  },
  innerFacetSideNav: {
    height: '100%',
    background: '#eee',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  loadingIndicator: {
    color: 'blue',
  },
}));

const facetTypes: { [key: string]:
      (sideNavChildrenProps: SideNavChildrenProps, key: string) => React.ReactNode } = {
  autocomplete: AutocompleteFacet,
  category: CategoryFacet,
  multiselect: CategoryFacet,
  numberRange: NumberRangeFacet,
  dateRange: DateRangeFacet,
  experience: ExperienceFacet,
  'default': BasicFacet
}

interface IFacetSideNavProps {
  entityName: string
}

export const getFacetComponent = (sideNavChildrenProps: SideNavChildrenProps, key: string | number) => {
  if (includes(['category','multiselect'],sideNavChildrenProps.type)) {
    sideNavChildrenProps.mode = sideNavChildrenProps.defaultMode as string;
  }
  const facetType = facetTypes[sideNavChildrenProps.type] || facetTypes.default;
  return React.createElement(facetType as FunctionComponent<SideNavChildrenProps>, sideNavChildrenProps, key);
}

// main component
function FacetSideNav(props: IFacetSideNavProps) {
  const classes = useStyles();
  const {athenaClient} = useAthenaClient();
  const [loading, setLoading] = useState(false);
  const [childrenState, setChildrenState] = useState([] as IFacetFieldInfo[]);
  const {entityName, filters, search} = useDashUrlInfo();
  const [searchParams, setSearchParams] = useSearchParams();


  function getCriteria() {
    const criteria: ICriteria = {
      entityName: entityName,
      search: search,
      filter: encodeForAthenaFilters(filters),
      orderby:''}
    return criteria;
  }

  // make dictionary of fieldName => IFacetFieldInfo based on props.entityName
  const entityFacets: { [field: string]: IFacetFieldInfo } = {};

  const facetsArray = props.entityName === 'people' ? peopleFacets : projectsFacets;

  for (let i = 0; i < facetsArray.length; i++) {
    const curr = facetsArray[i] as IFacetFieldInfo;
    entityFacets[curr.field] = curr;
  }


  console.log('!@# entityFacets after map/reduce to dict', entityFacets);


  // attempt to get all the facet info (names & member counts) for people or projects
  // when props.entityName changes or athenaClient becomes available (non-null)
  useEffect(() => {
    if (athenaClient) {
      const getFacetFieldInfos = async () => {
        setLoading(true);
        const facetService = new FacetService(athenaClient);
        const facetMethod = (props.entityName === 'projects') ?
            facetService.getProjectFacets.bind(facetService) :
            facetService.getPeopleFacets.bind(facetService);
        const infos = await facetMethod();
        const paramDict = filters;

        for (let i = 0; i < infos.length; i++) {
          const info = infos[i];
          if (Object.keys(paramDict).includes(info.field)) {
            info.facetSettingsInfo = paramDict[info.field];
          }
        }
        setChildrenState(infos);
        setLoading(false);
      };

      getFacetFieldInfos()
          .catch(e => {
            console.log('FacetSideNav getFacetFieldInfos error', e);
          });
    }
  },[props.entityName, athenaClient]);

  // TODO: get updates from the React components via callback/handle
  const handleFacetBubbleUp = (f: IFacetState) => {
    console.log('@tmp HANDLE BUBBLE UP - IFacetState', f);
    if (!f.mode) {
      f.mode = entityFacets[f.field].defaultMode ?? 'missing';
    }

    if (f.values && f.values.length > 0 && f.mode) {
      // facetSetting with relevant info
      const newParams = { ...Object.fromEntries(searchParams), [f.field]: serialize(f) };
      console.log('//navCB oldParams', Object.fromEntries(searchParams));
      console.log('//navCB newParams', newParams);
    } else {
      const newParams2 = { ...Object.fromEntries(searchParams) };
      // delete entry by key
      delete newParams2[f.field];

      setSearchParams(newParams2);
    }
  };

  const makeFacetId = (facetFieldInfo: IFacetFieldInfo) => {
    // these serve React/UI purposes only
    return `${props.entityName}-${facetFieldInfo.field}`;
  }

  return (
      <div id="facetSideNav" className={classes.facetSideNavComp}>
        {/*<Button onClick={() => alert('hello')}>Test Up</Button>*/}
        {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loadingIndicator} />
            </div>
        ) : (
        <div className={classes.innerFacetSideNav}>

            {childrenState.map((facetFieldInfo) => {
            const fid = makeFacetId(facetFieldInfo);
            const entityName = props.entityName;
            const id = makeFacetId(facetFieldInfo);
            const sideNavChildrenProps = {
              ...facetFieldInfo, onChange: handleFacetBubbleUp, entityName, id } as SideNavChildrenProps;

            return getFacetComponent(sideNavChildrenProps, fid);
          })}
        </div>)}
      </div>
  );
}

export default FacetSideNav;
