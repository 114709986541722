import _ from 'lodash';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import useAthenaClient from 'hooks/useAthenaClient';
import { IAthenaFileMetadata } from 'services/apiClients/AthenaClient';
import { hasPermission } from '../../security/utils/securityUtil';
// remove component 8/1/2023
import ImageWithAuth from '../../shared/imageWithAuth/ImageWithAuth';
import { useGlobalState } from '../../state/useGlobalState';
import {CircularProgress} from "@material-ui/core";
import {createUrlVariants, multiReplace} from "../util/primaryImageUtil";

const useStyles = makeStyles(() => ({
  imageContainer: {
    position: 'relative',
    // width: '62px', // Adjust as needed
    // height: '64px', // Adjust as needed
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      // minHeight: '100%', // Note: Use strings for percentage values
      // minWidth: '100%',
      width: 'auto',
      height: '100%',
      display: 'block',
      position: 'absolute',
      top: 0,
    },
  },
  icon: {
    position: 'absolute',
    right: '8px',
    bottom: '8px',
    cursor: 'pointer',
  },
}));

type PrimaryImageProps = {
  primaryImageSrc?: string;
  primaryThumbnail?: string;
  userName?: string;
  alt?: string;
  entityName?: string;
  entityId?: string;
  allowEditing?: boolean;
  style?: CSSProperties;
}

const BASE_AUTH_IMAGE_URLS = createUrlVariants((process.env.REACT_APP_PRINTING_BASE_URL as string) + '/pictures');
const BASE_API_URLS = createUrlVariants(process.env.REACT_APP_API_BASE_URL + '/v2');
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

console.log('!!22 variants', {BASE_AUTH_IMAGE_URLS, BASE_API_URLS, BASE_API_URL});

function PrimaryImage(props: PrimaryImageProps) {
  const classes = useStyles();
  const { athenaClient } = useAthenaClient();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [primaryImgSrc, setPrimaryImgSrc] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const gs = useGlobalState();
  const [count,setCount] = useState(0);

  const isPeople = props.entityName === 'people';

  let tmpAlt = ''; // props.primaryImageSrc ?? ''; // without altering url
  if (primaryImgSrc === undefined) {
    setPrimaryImgSrc(props.primaryImageSrc);
  }

  function alignPrimaryImgSrc(startingImgSrc?: string) {
    const res = {imagePath: '', steps: ['init'] };
    let tmpImageSrc = startingImgSrc ?? ''
    res.imagePath = tmpImageSrc; res.steps.push('make n/u empty string if needed');
    if (tmpImageSrc) {
      res.steps.push('tmpImageSrc truthy');
      if (tmpImageSrc.includes('/')) {
        res.steps.push('tmpImageSrc includes /');
        if (isPeople) {
          res.steps.push('entity is people');
          tmpImageSrc = tmpImageSrc + '?user=' + props.userName ?? '';
          res.imagePath = tmpImageSrc;
        } else {
          res.steps.push('entity is ' + props.entityName + '. Do nothing to tmpImageSrc');
        }
      } else {
        res.steps.push('tmpImageSrc does not include /');
        tmpImageSrc = BASE_API_URL + '/pictures/' + props.entityName + '/' + tmpImageSrc;
        res.imagePath = tmpImageSrc;
      }
    } else {
      res.steps.push('tmpImageSrc not truthy');
      const baseImagePath = 'images/missing-image.jpg';
      const missingImgPath = `${baseImagePath}${makeUserNameQryStr(props.entityName,props.userName)}`;
      tmpImageSrc = `${process.env.REACT_APP_API_BASE_URL + '/v2'}/${props.entityName}/${props.entityId}/${missingImgPath}`;
      res.steps.push('build up missing image path');
      res.imagePath = tmpImageSrc;
    }
    //tmpImageSrc = tmpImageSrc.replace(settings.BASE_API_URL, settings.BASE_AUTH_IMAGE_URL);
    //console.log('!!22 before multi replace', tmpImageSrc);
    tmpImageSrc = multiReplace(tmpImageSrc, BASE_API_URLS, BASE_AUTH_IMAGE_URLS);
    res.steps.push('after multi replace');
    res.imagePath = tmpImageSrc;
    //console.log('!!22 res', res);
    tmpAlt = props.alt ?? '';

    setPrimaryImgSrc(tmpImageSrc);
  }

  function makeUserNameQryStr(entityName?: string, userName?: string | null) {
    if (!entityName || entityName !== 'people'){
      return '';
    }
    let tmpUserName = (userName ?? '').trim();
    // exclude craft folks
    if (tmpUserName.includes('-')) tmpUserName = '';
    if (tmpUserName === '') {
      return '?user=IMAGEFILE&print=0';
    }
    return `?user=${tmpUserName}&print=0`;
  }

  useEffect(() => {
    alignPrimaryImgSrc(props.primaryImageSrc);
    console.log('!@#3 1 primaryImage', {primaryImgSrc,count,'pi':props.primaryImageSrc});
  }, [props.primaryImageSrc]);

  useEffect(() => {
    console.log('!@#3 2 primaryImage', {primaryImgSrc,count});
    setCount(x => x + 1);
  }, [primaryImgSrc]);


  const canContrib = hasPermission(['Contributor'], gs.userRoles);

  useEffect(() => {
    async function doUpload() {

      if (athenaClient && props.entityName && selectedFile && props.entityId) {

        const files = [selectedFile];
        const result = await athenaClient.uploadAssets(props.entityName, props.entityId,
          'images', files) as Array<IAthenaFileMetadata & { thumbnailUrl?: string }>;

        let resultUrl = '';
        if (props.entityName === 'people') {
          const peopleId = parseInt(props.entityId);
          await athenaClient.createOrUpdatePerson(peopleId,
            { primaryImageUrl: result[0].url, thumbnail: result[0].thumbnailUrl as string });
          resultUrl = result[0].url as string;
        } else if (props.entityName === 'projects') {
          // undefined ID in the actual packet here means leave it alone - gets error if called with it
          // ID is in URL though from the first param
          const updatedFields = { id: undefined, primaryImageUrl: result[0].url, thumbnail: result[0].thumbnailUrl as string };
          await athenaClient.updateProject(props.entityId, updatedFields);
          resultUrl = result[0].url as string;
        }

        if (fileInputRef.current?.value) {
          fileInputRef.current.value = '';
        }
        setSelectedFile(null);
        setLoading(false);
        alignPrimaryImgSrc(resultUrl);
      }
    }

    if (selectedFile) {
      setLoading(true);
      doUpload();
    }
  }, [selectedFile, loading]);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
    console.log('!!! file name', file?.name);
  };

  const handleButtonClick = () => {
    // Trigger a click on the hidden file input
    fileInputRef.current?.click();
  };

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div className={classes.imageContainer} style={props.style}>
      <img src={primaryImgSrc} alt={tmpAlt} loading="lazy"/>
      {/*<ImageWithAuth athenaHttpSrc={props.imageUrl ?? undefined} />*/}

      { !loading ?
      <div className={classes.icon} onClick={handleButtonClick}>
        { props.allowEditing && canContrib &&
          <AddPhotoAlternateOutlinedIcon fontSize="small" style={{color:'#A40000'}} />
        }
      </div>
        : <CircularProgress />
      }

      <div style={{ display: 'none' }}>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
}

export default PrimaryImage;
