import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Dialog, Switch} from "@material-ui/core";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";
import {IApiPerson, IApiProject} from "services/apiClients/AthenaClient";
import DashDetailsField from "../../DashDetailsField/DashDetailsField";
import DashMap from "../../DashMap";
import {projectInfotips} from "constants/metadata/infotips";
import MetaClient from "services/apiClients/MetaClient";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../shared/withDashSection";
import {v4 as uuidv4} from "uuid";
import {getNamedData} from "utils/forms";
import useSaveCount from "hooks/useSaveCount";
import useAthenaClient from "hooks/useAthenaClient";
import _ from "lodash";
import CoreDebug from "features/core/CoreDebug";
import CoreLocation from "features/core/CoreLocation";
import useToast from "hooks/useToast";
import DashToaster from "patterns/DashToaster";
import DashDivider from "../../DashDivider";
import {makeAutoObservable} from "mobx";
import {useObserver} from "mobx-react";

const SECTION_ID = 'locationSection';

class LocationSectionState {
  mapVisible = false;
  constructor() {
    makeAutoObservable(this);
  }
  toggleMap() {
    this.mapVisible = !this.mapVisible;
  }
}
const locationSectionState = new LocationSectionState();

const LocationSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const [model, setModel] = useState<IApiProject | IApiPerson>(props.model);
      const [saveCount, incr] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [editMode, setEditMode] = useState(props.editMode);
      const initDomesticMode = model.country?.trim().toUpperCase() === 'USA';

      const domesticModeRef = useRef(initDomesticMode);
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());

      const isProject = ('cost' in props.model)
      const toaster = useToast();
      const dashToaster = new DashToaster(toaster.displayToast);

      let combinedAddress = '';
      if ('streetAddress' in model) {
        combinedAddress = `${model.streetAddress} ${model?.city}, ${model?.state} ${model?.zip}`;
      } else {
        combinedAddress = `${model?.city}, ${model?.state} ${model?.zip}`;
      }

      const stateChoices = Object.keys(new MetaClient().getUSStateDictionary()).map(x => x.toUpperCase());

      const log = CoreDebug.getConsoleLogFunc(true);

      const entityName = CoreLocation.getEntityName();

      useEffect(() => {
        setEditMode(props.editMode);
      }, [props.editMode, domesticModeRef.current]);


      const handleDomesticChangeFromSwitch = (value: boolean) => {
        log('!@# outside', value);
        const originalValue = _.clone(domesticModeRef.current);
        if (originalValue !== value) {
          domesticModeRef.current = value;
          incr();
        }
      }

      const toggleEditMode = () => {
        props.setEditMode(!props.editMode);
      }

      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem) as Record<string, unknown>;
          log('!@# namedData was:', data);

          try {
            if (isProject) {
              const m = await athenaClient?.patchProject(data) as IApiProject;
              setModel(m as IApiProject);
              props.setEditMode(false);
              setSectionKey(uuidv4());
              // not needed toast
              //dashToaster.displaySavedToast(m.name as string);
              incr();
            } else {
              const m = await athenaClient?.createOrUpdatePerson(data.id as number, data) as IApiPerson;
              setModel(m as IApiPerson);
              props.setEditMode(false);
              setSectionKey(uuidv4());
              // not needed toast
              //dashToaster.displaySavedToast(m.name as string);
              incr();
            }
            return true;
          } catch (err) {
            console.log('There was an error updating the location');
            return false;
          }
        }
      }));

      const handleInfotip = (key: string) => {
        const tip = projectInfotips[key];
        setContent(tip.title + tip.description + tip.example);
        setShowInfotipModal(true);
      }

      return useObserver(() =>
          <React.Fragment key={sectionKey}>
            {!editMode &&
                <div style={{textAlign: 'right', width: '100%'}}>View map:&nbsp;
                    <Switch color="primary" checked={locationSectionState.mapVisible}
                            onChange={() => locationSectionState.toggleMap()} /></div>
            }

            <DashMap location={combinedAddress}
                     visible={locationSectionState.mapVisible && !editMode}
                     style={{width: '100%', height: '280px'}}/>

            <div className={classes.fieldArea}>
              {isProject ?
                  <>
                    <DashDetailsField width="47%" label={'Street Address'} mode={'text'}
                                      showLabel={true} editMode={editMode} name={'streetAddress'}
                                      infotipKey="_default"
                                      defaultValue={(model as IApiProject)?.streetAddress ?? ''}/>
                    <DashDetailsField width="47%" label={'City'} mode={'text'} name={'city'}
                                      infotipKey="_default"
                                      showLabel={true} editMode={editMode} defaultValue={model?.city ?? ''}/>

                    {domesticModeRef.current ?
                        <React.Fragment key={'state-' + domesticModeRef.current + '-' + editMode}>
                          <DashDetailsField width="47%" label={'State'} name={'state'}
                                            mode={'select'}
                                            choices={stateChoices}
                                            showLabel={true}
                                            editMode={editMode}
                                            infotipKey="_default"
                                            defaultValue={model?.state ?? ''}/>
                        </React.Fragment> :
                        <DashDetailsField width="47%" label={'State'} name={'state'}
                                          mode={'text'} showLabel={true}
                                          editMode={editMode}
                                          infotipKey="_default"
                                          defaultValue={model?.state ?? ''}/>

                    }
                    <DashDetailsField width="47%" label={'Zip Code'} mode={'text'} name={'zip'}
                                      infotipKey="_default"
                                      showLabel={true} editMode={editMode} defaultValue={model?.zip ?? ''}/>
                    <DashDetailsField width="47%" label={'Country'} mode={'text'} name={'country'}
                                      infotipKey="_default"
                                      showLabel={true} editMode={editMode && !domesticModeRef.current}
                                      defaultValue={model?.country ?? ''}/>
                    <DashDetailsField width="47%" label={'Domestic'} mode={'checkSwitch'}
                                      showLabel={true} editMode={editMode}
                                      value={domesticModeRef.current}
                                      infotipKey={'domestic'}
                                      onValueChange={handleDomesticChangeFromSwitch}/>
                  </> :
                  <>
                    <DashDetailsField width="47%" label={'City'} mode={'text'} name={'city'}
                                      showLabel={true} editMode={false} defaultValue={model?.city ?? ''}/>
                    <DashDetailsField width="47%" label={'State'} name={'state'}
                                      mode={'text'} showLabel={true} editMode={false}
                                      defaultValue={model?.state ?? ''}/>
                    <DashDetailsField width="47%" label={'Zip Code'} name={'zip'}
                                      mode={'text'} showLabel={true} editMode={false} defaultValue={model?.zip ?? ''}/>
                    <DashDetailsField width="47%" label={'Country'} name={'country'}
                                      mode={'text'} showLabel={true} editMode={false}
                                      defaultValue={model?.country ?? ''}/>

                  </>
              }
              {/* id */}
              <DashDetailsField mode="hidden"
                                label="Id"
                                showLabel={false}
                                editMode={props.editMode}
                                name="id"
                                defaultValue={model.id}
              />
            </div>

            <DashDivider width={'200px'} height={'20px'} />

            {/*<Dialog*/}
            {/*    open={showInfotipModal}*/}
            {/*    onClose={() => setShowInfotipModal(false)}>*/}
            {/*  <div className={classes.innerDialogPadding}>*/}
            {/*    <div dangerouslySetInnerHTML={{__html: content}}/>*/}
            {/*  </div>*/}
            {/*</Dialog>*/}
          </React.Fragment>
      );
    });


export const ProjectLocationSection = withDashSection('', SECTION_ID, LocationSection);
export const PersonLocationSection = withDashSection('', SECTION_ID, LocationSection, true);
