import { useEffect, useState } from 'react';
import { InteractionRequiredAuthError, AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { tokenRequest } from 'config/msalConfig';
import jwtDecode from 'jwt-decode';
import {guaranteeRelatedPermissions} from "app/security/utils/securityUtil";
import {useNavigate} from "react-router-dom";
import globalState from "../../app/state/GlobalState";

interface DecodedToken {
  roles?: string[];
  [key: string]: any;
}

export default function useAccessToken() {
  const gs = globalState;
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    instance
        .acquireTokenSilent(tokenRequest)
        .then((response: AuthenticationResult) => {
          if (response) {
            // console.log('acquireTokenSilent', response.accessToken);
            // console.log('response', response)
            setAccessToken(response.accessToken);

            const decodedToken: DecodedToken = jwtDecode(response.accessToken);

            // prep roles
            // todo: roles fix
            const tmpRoles = guaranteeRelatedPermissions(decodedToken.roles ?? []);
            //const tmpRoles = ['Reader'];
            //localStorage.setItem('roles', JSON.stringify(tmpRoles) || '');

            if (decodedToken.roles) {
              setUserRoles(tmpRoles);
              gs.setUserRoles(tmpRoles);
            }
          }
        })
        .catch((error) => {
          //alert('issue');
          //navigate('/bounce');
          //window.location.href = '/bounce';
          console.error(error);
          if (error instanceof InteractionRequiredAuthError) {
            return instance.acquireTokenRedirect(tokenRequest);
          }
        });
  }, [instance]);

  // console.log('TOKEN-APP: ', accessToken);
  // console.log('User Roles: ', userRoles); // Log the roles

  localStorage.setItem('token-app', accessToken || '');

  return { accessToken, userRoles }; // Return the roles as well
}
