import {Column, SortSettingsModel} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {Company, IApiPersonReference} from "services/apiClients/AthenaClient";
import {GreenCheck} from "../../../dashCore/GreenCheck";
import {RedX} from "../../../dashCore/RedX";
import {mkColumn} from 'components/detailViews/personViewRelated/gridConfigSettings/gridUtils';
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import {Info} from "@material-ui/icons";
import InfotipButton from "../../../InfotipButton/InfotipButton";

export function getReferenceConfig(userRoles: string[]) {
  // todo: use an elevated model in grids - with calculated fields
  // would allow for sorting on fullName in this first column
  const columns = [
    mkColumn('fullName','Name'),
    {
      headerText: 'Reference Type',
      field: 'referenceType',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'referenceType'}/></>
      )
    },
    {
      headerText: 'Role on Project',
      field: 'roleOnProject',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'referenceRole'}/></>
      )
    },
    {
      headerText: 'Company on Project',
      field: 'companyOnProject',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'referenceCompany'}/></>
      ),
      // template: (data: IApiProjectReference) => {
      //   return data.isManagedByCrm ? <GreenCheck />: <RedX />;
      // },
    } as unknown as Partial<Column>,
    {
      headerText: 'Synced with CRM',
      field: 'isManagedByCrm',
      headerTemplate: (props: { headerText: string }) => (
          <>{props.headerText}<InfotipButton infotipKey={'referenceSyncStatus'}/></>
      ),
      template: (data: IApiPersonReference) => {
        return data.isManagedByCrm ? <GreenCheck />: <RedX />;
      },
    } as unknown as Partial<Column>,
  ] as Column[];

  const exportColumns = [
    mkColumn('fullName','Name'),
    mkColumn('roleOnProject', 'Role on Project'),
    mkColumn('companyOnProject', 'Company on Project'),
    {
      headerText: 'Synced with CRM',
      field: 'isManagedByCrm',
      //displayAsColumnType: 'boolean',
      valueAccessor: (_: string, data: Partial<IApiPersonReference>): string => {
        return data.isManagedByCrm ? 'Yes' : 'No';
      },
    } as Partial<Column>,
  ] as Column[];

  const sortingOptions = {
    columns:  [{ field: 'fullName', direction: 'Ascending' }]
  } as SortSettingsModel;

  let toolbarOptions = [
    //{ text: 'Add Reference', prefixIcon: 'e-circle-add', id:'AddRef' },
    { text: 'View', prefixIcon: 'e-display-for-review', id:'ViewRef' },
    //{ text: 'Unlink Reference', prefixIcon: 'e-circle-remove', id:'UnlinkRef' },
    { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport' },
  ];

  if (hasPermission(['Contributor'], userRoles)) {
    toolbarOptions = [
      { text: 'Add Reference', prefixIcon: 'e-circle-add', id:'AddRef' },
      { text: 'View', prefixIcon: 'e-display-for-review', id:'ViewRef' },
      { text: 'Unlink Reference', prefixIcon: 'e-circle-remove', id:'UnlinkRef' },
      { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport' },
    ];
  }



  const config = {
    columnDirectives: columns,
    exportColumns: exportColumns,
    sortingOptions: sortingOptions,
    title: 'Awards',
    showTitle: false,
    allowAdd: true,
    //allowEdit: true,
    allowRemove: true,
    toolbarOptions: toolbarOptions,
  } as ISectionWithGridAndCardListConfig;
  return config;
}
