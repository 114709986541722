import {convertMimeTypeToFileExt} from "services/mimeTypeUtil";
import {friendlyFileSize} from "services/fileSizeUtil";
import DownloadButton from "components/dashCore/DownloadButton";
// contentType: "image/jpeg"
  // height: 1973
  // modifiedBy: null
  // modifiedOn: Sat Sep 08 2018 01:40:33 GMT-0400 (Eastern Daylight Time) {}
  // name: "10000605-KimAMcAuliffe-002.jpg"
  // sizeInBytes: 1570799
  // thumbnailUrl: "https://prod-dash-api.azurewebsites.net/v2/people/50000067/images/10000605-KimAMcAuliffe-002.thumb.jpg"
  // url: "https://prod-dash-api.azurewebsites.net/v2/people/50000067/images/10000605-KimAMcAuliffe-002.jpg"
  // width: 1538

import {Column, SortSettingsModel} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {IDocumentMetadata, IImageMetadata} from "services/apiClients/AthenaClient";
import _ from "lodash";

interface ICommonMetadata {
  contentType?: string | null;
  sizeInBytes?: number;
}

export function getBaseConfig() {
  const columns = [
    // {
    //   headerText: 'Replace With Specific Image/Document Icon/Video',
    //   field: '',
    //   width: 200,
    //   allowSorting: true,
    // } as Partial<Column>,
    {
      headerText: 'Name',
      field: 'name',
      width: 100,
      editable: false,
      allowSorting: true,
    } as Partial<Column>,
    {
      headerText: 'Upload Date',
      field: 'modifiedOn',
      format: 'yMd',
      width: 100,
      allowSorting: true,
    } as Partial<Column>,
    {
      headerText: 'Size',
      width: 150,
      allowSorting: true,
      valueAccessor: (_: string, data: unknown): string =>
          friendlyFileSize((data as ICommonMetadata).sizeInBytes),
    } as Partial<Column>,
    {
      headerText: 'File Type',
      width: 150,
      allowSorting: true,
      valueAccessor: (_: string, data: unknown): string =>
          convertMimeTypeToFileExt((data as ICommonMetadata).contentType).toUpperCase(),
    } as Partial<Column>,
    // {
    //   headerText: 'Actions',
    //   width: 150,
    //   commands: [{buttonOption: {content: <DownloadButton />}}],
    // }
    //   commands: [{buttonOption: {content:'Download',click: () => alert('hello')}}],
    // } as Partial<Column>,
  ] as Partial<Column>[];

  const sortingOptions = {
    columns:  [{ field: 'modifiedOn', direction: 'Descending' }]
  } as SortSettingsModel;

  const toolbarOptions = [] as {text:string}[];

  const config = {
    columnDirectives: columns,
    sortingOptions: sortingOptions,
    showTitle: false,
    allowAdd: false,
    //allowEdit: true,
    allowRemove: false,
    toolbarOptions: toolbarOptions,
  } as ISectionWithGridAndCardListConfig;
  return config;
}

// function ImageColumn() {
//   return (<span>hii</span>);
// }

function ImageColumn(props: IImageMetadata) {
  const handleClick = (url?: string) => {
    const btnEl = document.getElementById('launch-carousel') as HTMLInputElement;
    console.log('!@#91', btnEl);
    btnEl.value = url as string;
    btnEl.click();
  }
  return (
      <img src={props.thumbnailUrl} alt={props.name}
           className={'cursor-pointer'}
           onClick={() => handleClick(props.url)} />
  );
}

function DocumentColumn(props: IDocumentMetadata) {
  console.log('mimeType', props.contentType);
  const fileExt = convertMimeTypeToFileExt(props.contentType).toLowerCase();
  const iconUrl = `/images/details/${fileExt}_icon.png`;
  return (
      <img src={iconUrl} alt={props.name} />
  );
}

export function getImageAssetConfig() {
  const baseConfig = getBaseConfig();
  const columns = baseConfig.columnDirectives as Partial<Column>[];
  const newColumn = ({
    headerText: 'Image',
    width: 150,
    template: (data: IImageMetadata) => {
      return <ImageColumn {...data} />;
    }
  } as unknown) as Partial<Column>;

  const actionsColumn = ({
    headerText: 'Actions',
    width: 100,
    template: (data: IImageMetadata) => {
      return <DownloadButton downloadUrl={data.url} desiredFilename={data.name} />;
    }
  } as unknown) as Partial<Column>;

  columns.unshift(newColumn);
  columns.push(actionsColumn);

  return { ...baseConfig,
    title:'Image Assets',
    columnDirectives: columns as Column[]};
}

export function getDocumentAssetConfig() {
  const baseConfig = getBaseConfig();
  const columns = baseConfig.columnDirectives as Partial<Column>[];
  const newColumn = ({
    headerText: 'Document',
    width: 150,
    template: (data: IDocumentMetadata) => {
      return <DocumentColumn {...data} />;
    }
  } as unknown) as Partial<Column>;

  const actionsColumn = ({
    headerText: 'Actions',
    width: 100,
    template: (data: IImageMetadata) => {
      return <DownloadButton downloadUrl={data.url} desiredFilename={data.name} />;
    }
  } as unknown) as Partial<Column>;

  columns.unshift(newColumn);
  columns.push(actionsColumn);

  return { ...baseConfig,
    title:'Document Assets',
    columnDirectives: columns as Column[]};
}

export function getVideoAssetConfig() {
  const baseConfig = getBaseConfig();
  const columns = baseConfig.columnDirectives as Partial<Column>[];
  const newColumn = ({
    headerText: 'Document',
    width: 150,
    template: (data: IDocumentMetadata) => {
      return <DocumentColumn {...data} />;
    }
  } as unknown) as Partial<Column>;

  const actionsColumn = ({
    headerText: 'Actions',
    width: 100,
    template: (data: IImageMetadata) => {
      return <DownloadButton downloadUrl={data.url} desiredFilename={data.name} />;
    }
  } as unknown) as Partial<Column>;

  columns.unshift(newColumn);
  columns.push(actionsColumn);

  return { ...baseConfig,
    title:'Video Assets',
    columnDirectives: columns as Column[]};
}