//import FILENAME from '../macros/filename.macro';
import React, {useEffect, useRef, useState} from "react";
import AthenaClient, {
  ApiCompany,
  ApiProjectStakeholder,
  IApiPersonReference,
  IApiProject,
  IApiProjectStakeholder, IQueryableContact,
  ProjectCompany
} from "services/apiClients/AthenaClient";
import {Button, Card, CardContent, CardHeader, Grid, Tooltip, Typography} from "@material-ui/core";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
import {
  Column,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridComponent,
  Inject,
  Resize,
  Scroll,
  Sort,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import DashIconButton from "../../../dashCore/DashIconButton";
import {LibraryBooks} from "@material-ui/icons";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import {Excel, GreenCheck, RedX} from "../../../dashCore/customIcons";
import PickerDialogEx from "../../shared/PickerDialogEx";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import {getCrmAccountLink} from "config/microsoftDynamics";
import AddEditProjectStakeholderDialog from "./AddEditProjectStakeholderDialog";
import DashToaster from "patterns/DashToaster";
import useToast from "hooks/useToast";
import CombinedAddress from "../../../dashCore/CombinedAddress";
import {v4 as uuidv4} from "uuid";
import {mkColumn} from "../../personViewRelated/gridConfigSettings/gridUtils";
import {hasPermission} from "app/security/utils/securityUtil";
import {useGlobalState} from "app/state/useGlobalState";
import InfotipButton from "../../../InfotipButton/InfotipButton";
import {convertStakeholders, IInfoCard, IInfoField} from "services/infoCardConversion";
import {makeStyles} from "@material-ui/core/styles";
import globalState from "app/state/GlobalState";
import _ from "lodash";
import {useObserver} from "mobx-react";
import globalEventTarget from "../../../../app/events/globalEventTaget";
import {prepareAthenaFriendlySearchString} from "../../../../hooks/useDashUrlInfo";
import ownerState from "../../../../app/state/OwnerState";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    flex: '1 0 100%',
    width: '100%'
  },
  cardHolder: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    rowGap: '5px',
    columnGap: '5px',
    height: '800px',
    overflowY: 'auto',
  },
  hardBreak: {
    '&:after': {
      content: '',
      display: 'block',
    },
  },
  smallCard: {
    textAlign: 'left',
    display: 'block',
    width: '300px',
    flexGrow: 0,
    flexShrink: 0,

    '& > div.MuiCardHeader-root': {
      paddingBottom: '5px'
    },
  },
  cardHeader: {
    display: 'block',
    overflow: 'hidden'
  },
  cardHeaderRoot: {
    overflow: 'hidden'
  },
  cardHeaderContent: {
    overflow: 'hidden'
  }
}));
// end imports

const SECTION_ID = 'projectStakeholderAddSection';

type CompanyAndProjectCompany = { company: ApiCompany, projectCompany: ProjectCompany };
export type StakeholderPayload =
    string
    | null
    | undefined
    | ApiProjectStakeholder
    | ProjectCompany
    | CompanyAndProjectCompany;

function renderNameLink(record: IApiProjectStakeholder) {
  const appId = '6bc1724a-eb95-ea11-a811-000d3a3be645';
  const template = 'https://henselphelps.crm.dynamics.com/main.aspx?appid={appId}&pagetype=entityrecord&etn=account&id={id}';
  if (record.crmId && template && appId) {
    const url = template.replace('{appId}', appId).replace('{id}', record.crmId);
    return (<a target={'_blank'} key={record.projectId + '-' + record.name} href={url}>{record.name}</a>);
  } else {
    return (<span>{record.name}</span>);
  }
}

function ProjectStakeholderAssocSection(props: { model: IApiProject, referenceData: IReferenceData }) {
  const classes = useStyles();
  const {athenaClient, athenaClientRef} = useAthenaClient();
  const gs = useGlobalState();
  const [model, setModel] = useState<IApiProject | null>(props.model);
  const toaster = useToast();
  const dashToaster = new DashToaster(toaster.displayToast);
  const [dialogChildModel, setDialogChildModel] = useState<ApiProjectStakeholder>();
  const [dialogMode, setDialogMode] = useState<string | undefined | null>();
  const [saveCount, incr] = useSaveCount();
  const gridRef = useRef(null);

  let elem: HTMLElement | null;

  if (model === null) {
    setModel(props.model);
  }

  function updateOthers() {
    setTimeout(() => {
      gs.updateClientInformationTicks();
    }, 1700);
  }

  function checkForClientChange() {
    const stakeholders = model?.stakeholders ?? [];
    if (stakeholders.length === 0) return;

    if (ownerState.owner) {
      const owner = ownerState.owner;
        const clientName = owner.name;
        const clientLocation = owner.city + ', ' + owner.state + ', ' + owner.country;
        const customEvent = new CustomEvent('externalUpdateClientInfo', {detail: {clientName, clientLocation}});
        console.log('$x$ checkForClientChange 2-owner - customEvent', customEvent);
        globalEventTarget.dispatchEvent(customEvent);
    } else {
      const customEvent = new CustomEvent('externalUpdateClientInfo', {detail: {clientName: '', clientLocation: ''}});
      console.log('$x$ checkForClientChange 2-no-owner - customEvent', customEvent);
      globalEventTarget.dispatchEvent(customEvent);
    }
  }

  useEffect(() => {
    if (props.model) {
      const stakeholders = props.model.stakeholders ?? [];
      ownerState.checkForOwner(stakeholders);
    }
  }, []);

  useEffect(() => {
    async function reload() {
      if (athenaClientRef.current) {
        const client = athenaClientRef.current as AthenaClient;
        const m = await client.getProject(model?.id as string);
        setModel(m);
        ownerState.checkForOwner(m.stakeholders ?? []);
        checkForClientChange();
      }
    }

    reload();
  }, [athenaClientRef, saveCount]);

  // HANDLERS
  function handleAction(action: string, payload?: StakeholderPayload) {
    const client = athenaClientRef.current as AthenaClient;
    const projectId = model?.id as string;
    //console.log('inside ProjectStakeholderAssocSection handleAction - payload: ', payload);

    switch (action) {
      case 'refresh':
        client.getProject(projectId).then((proj) => {
          setModel(proj);
          gs.updateProjectStakeholdersTicks();
          // todo: Dash Leaf 1.5 API - Project Stakeholders
          // "Leaf" being any child like part of projects... could be
          // 1-to-1, 1-to-many, etc...
          // get Project Stakeholders by Project ID
        })
        break;
      case 'close':
        setDialogMode(null);
        break;
      case 'pick':
        setDialogMode('pick'); // will show the PickerDialogEx browsing Companies
        break;
      case 'view':
        setDialogMode('view');
        setDialogChildModel(payload as ApiProjectStakeholder);
        console.log('$$$p payload', payload)
        //setDialogSecondaryModel(model.)
        break;
      case 'new':
        setDialogMode('new');
        setDialogChildModel(payload as ApiProjectStakeholder);
        console.log('!@@@! NEW MODE', {action, payload});
        break;
      case 'edit':
        setDialogChildModel(payload as ApiProjectStakeholder);
        setDialogMode('edit');
        break;
      case 'create':
        console.log('!@@@! CREATE payload', payload);
        if (client) {
          const capc = payload as CompanyAndProjectCompany;
          client.createOrUpdateProjectCompanyLink2(capc.projectCompany.projectId as string, capc.projectCompany.companyId as string, capc.projectCompany)
              .then(() => {
                handleAction('close');
                handleAction('refresh');
                dashToaster.displayLinkedToast(capc.company.name as string, model?.name ?? '');
                incr();
                updateOthers();
              });
        }
        break;
      case 'update':
        console.log('!@#7 UPDATE payload', payload);
        if (client) {
          const capc = payload as CompanyAndProjectCompany;
          client.createOrUpdateProjectCompanyLink2(capc.projectCompany.projectId as string,
              capc.projectCompany.companyId as string, capc.projectCompany)
              .then(() => {
                handleAction('close');
                handleAction('refresh');
                dashToaster.displayLinkedToast(capc.company.name as string, model?.name ?? '');
                incr();
                updateOthers();
              });
        }
        break;
      case 'delete':
        if (client) {
          const ps = payload as ApiProjectStakeholder;
          console.log('!@@@! delete payload: ', payload, athenaClient, athenaClientRef.current);
          const confirmMessage = `Are you sure you want unlink ${ps.name} from this record?`;
          if (confirm(confirmMessage)) {
            // deleteProjectStakeholderLink(projectId: string, stakeholderId: string): Promise<Object>
            // deleteProjectCompanyLink2(projectId: string, companyId: string): Promise<Object>
            // todo: needs API undo linkage - "generic" that can unlink anything (future)
            client.isCompanyNode(ps.companyId as string).then(result => {
              if (result) {
                client.deleteProjectCompanyLink(ps.projectId as string, ps.companyId as string)
                    .then(() => {
                      dashToaster.displayUnlinkedToast(ps.name as string);
                      handleAction('refresh');
                      incr();
                      updateOthers();
                      gs.updateProjectStakeholdersTicks();
                    });
              } else {
                const stakeholderId = ps.companyId as string;
                client.deleteProjectStakeholderLink(ps.projectId as string, stakeholderId)
                    .then(() => {
                      dashToaster.displayUnlinkedToast(ps.name as string);
                      setModel(null);
                      handleAction('refresh');
                      incr();
                      gs.updateProjectStakeholdersTicks();
                      updateOthers();
                    });
              }
            });

          }
        }
        break;
    }
  }

  function renderActionButtons(record: ApiProjectStakeholder) {
    return (<div key={Math.random().toString()}>
      <DashIconButton size="small" onClick={() => {
        //console.log('!@ looking fot comments', record);
        handleAction('view', record);
        console.log('!@#7 view', record);
      }}>
        <LibraryBooks/>
      </DashIconButton>
      <DashIconButton size="small" onClick={() => handleAction('delete', record)}>
        <RemoveCircleOutline/>
      </DashIconButton>
    </div>);
  }

  // builds up columns to send to the flexible PickerDialogEx
  // name this function the same thing in other components that use PickerDialogEx
  function getPickerColumnDirectives() {
    const websiteTemplate = (ps: ApiProjectStakeholder) => {
      if (ps.website) {
        if (ps.website.startsWith('http://') || ps.website.startsWith('https://')) {
          return (<a href={ps.website} target="_blank">{ps.website}</a>);
        } else {
          return (<a href={'http://' + ps.website} target="_blank">{ps.website}</a>);
        }
      }
      return '';
    };
    const companyNameTemplate = (ps: ApiProjectStakeholder) => {
      const add = ps.displayAddress
      return (<a href={getCrmAccountLink(ps.companyId)} target="_blank">{ps.name}</a>);
    };
    const addToProject = (ps: ApiProjectStakeholder) => {
      return (<div key={Math.random().toString()}>
        <DashIconButton size="small" onClick={() => handleAction('new', ps)}>
          <AddCircleOutline/>
        </DashIconButton>
      </div>);
    }

    return [
      <ColumnDirective headerText="Name" field="name" template={companyNameTemplate}/>,
      <ColumnDirective headerText="Website" field="website" template={websiteTemplate}/>,
      <ColumnDirective headerText="Business Phone" field="phone"/>,
      <ColumnDirective headerText="Address"
                       template={(record: ApiCompany) => {
                         return <CombinedAddress streetAddress1={record.streetAddress}
                                                 streetAddress2={record.streetAddress2} city={record.city}
                                                 state={record.state} zip={record.zip}/>
                       }}/>,
      <ColumnDirective headerText="Add to Project" template={addToProject}/>];
  }

  // grid settings
  const gridServices = [Toolbar, Sort, Edit, Filter, Scroll, Resize, CommandColumn, ExcelExport] as object[];
  const gridProps = {
    showToolbar: false,
    allowSorting: true,
    allowFiltering: false,
    allowResizing: true,
    allowExcelExport: true
  };

  // model not ready
  if (!props.model) {
    return <DashItemProgress/>;
  }

  const exportColumns = [
    mkColumn('name', 'Name'),
    mkColumn('role', 'Role on Project'),
    mkColumn('commentsOnProject', 'Comments on Project'),
    {
      headerText: 'Synced with CRM',
      field: 'isManagedByCrm',
      //displayAsColumnType: 'boolean',
      valueAccessor: (_: string, data: Partial<IApiPersonReference>): string => {
        return data.isManagedByCrm ? 'Yes' : 'No';
      },
    } as Partial<Column>,
  ] as Column[];


  function handleInfoCardClick(_infoCard: IInfoCard) {
    _.noop();
  }

  const infoCards = convertStakeholders(model?.stakeholders ?? []);

  return useObserver(() =>
      <>
        <Grid id={SECTION_ID} container spacing={2} style={{width: '100%'}}>
          <Grid container item xs={12} alignItems={'flex-start'} justifyContent="space-between"
                style={{margin: '0 15px'}}>
            <Grid item>
              <Button startIcon={<AddCircleOutline/>}
                      hidden={!hasPermission(['Contributor'], gs.userRoles)}
                      onClick={() => {
                        handleAction('pick')
                      }}>Add Stakeholder</Button>
            </Grid>
            <Grid item>
              <Button startIcon={<Excel/>}
                      onClick={() => {
                        const ps: ExcelExportProperties = {};
                        ps.columns = exportColumns;
                        gridRef?.current.excelExport(ps);
                      }}
              >Excel Export</Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <React.Fragment key={gs.projectStakeholdersTicks}>
              {globalState.stakeholdersListViewOn ? <>
                    <GridComponent height={'550px'}
                                   gridLines={'Both'}
                                   ref={gridRef}
                                   statelessTemplates={['directiveTemplates']}
                                   {...gridProps}
                                   dataSource={model?.stakeholders ?? []}>

                      <ColumnsDirective>
                        <ColumnDirective headerText="Name" field="name" width={'23%'}
                                         template={(record: ApiProjectStakeholder) => renderNameLink(record)}/>
                        <ColumnDirective headerText="Role on Project" field="role"
                                         headerTemplate={(props: { headerText: string }) => (
                                             <>{props.headerText}<InfotipButton infotipKey={'stakeholderRole'}/></>)}
                                         width={'20%'}/>
                        <ColumnDirective headerText={'Comments on Project'} field={'commentsOnProject'}
                                         headerTemplate={(props: { headerText: string }) => (
                                             <>{props.headerText}<InfotipButton infotipKey={'stakeholderComments'}/></>)}
                                         width={'23%'}/>
                        <ColumnDirective headerText="Synced with CRM" width={'14%'}
                                         headerTemplate={(props: { headerText: string }) => (
                                             <>{props.headerText}<InfotipButton infotipKey={'stakeholderSyncStatus'}/></>)}
                                         template={(record: ApiProjectStakeholder) => record.isManagedByCrm
                                             ? <GreenCheck/> : <RedX/>}
                                         valueAccessor={(_: string, data: Partial<ApiProjectStakeholder>): string => {
                                           return data.isManagedByCrm ? 'Yes' : 'No';
                                         }}
                        />

                        <ColumnDirective headerText="Actions" width={'20%'}
                                         visible={hasPermission(['Contributor'], gs.userRoles)}
                                         template={(record: ApiProjectStakeholder) => renderActionButtons(record)}/>

                      </ColumnsDirective>
                      <Inject services={gridServices}/>
                    </GridComponent>
                  </>
                  : <div className={classes.cardHolder}>
                    {infoCards.map((infoCard: IInfoCard, index: number) =>
                        <section key={index}>
                          <Card className={classes.smallCard} raised={true} style={{height: '400px'}}
                                onClick={() => handleInfoCardClick(infoCard)}>
                            <CardHeader className="info-card-header"
                                        title={<Tooltip title={infoCard.title ?? ''} aria-label={infoCard.title ?? ''}>
                                          <Typography noWrap gutterBottom variant="h6" component="h6">
                                            {infoCard.title}
                                          </Typography>
                                        </Tooltip>} subheader={infoCard.subheader}
                                        classes={{
                                          root: classes.cardHeaderRoot,
                                          content: classes.cardHeaderContent
                                        }}
                            />
                            <CardContent>
                              {infoCard.website ?
                                  <div style={{width: 300, paddingBottom: '5px'}}>
                                    <span style={{fontWeight: 'bold', display: 'inline-block'}}>Website</span><br/>
                                    <a href={infoCard.website} target="_blank">{infoCard.website}</a>
                                  </div> :
                                  <div style={{width: 300, paddingBottom: '5px'}}>
                                    <span style={{fontWeight: 'bold', display: 'inline-block'}}>&nbsp;</span><br/>
                                    <span>&nbsp;</span>
                                  </div>
                              }
                              {infoCard.projectId &&
                                  <img src="/images/projects/building-silhouette.png" alt="" style={{width: '270px'}}/>
                              }
                              {infoCard.fieldAndValues?.map((x: IInfoField, idx: number) =>
                                  <div key={'inner' + idx} style={{width: 300, paddingBottom: '5px'}}>
                                    <span style={{fontWeight: 'bold', display: 'inline-block'}}>{x.field}</span><br/>
                                    <span style={{display: 'inline-block'}}>{x.value ?? ''}</span>
                                  </div>
                              )}
                            </CardContent>
                          </Card>
                        </section>
                    )}
                  </div>}
            </React.Fragment>
          </Grid>
        </Grid>

        <DashDivider width="80vw" height="5px"/>
        <>
          {dialogMode === 'pick' &&
              <PickerDialogEx
                  open={dialogMode === 'pick'}
                  mode={dialogMode}
                  childModel={dialogChildModel}
                  searchBoxLabel={'Search for company...'}
                  title={'Add Stakeholder for ' + model?.name}
                  entityName={'companies'}
                  onAction={handleAction}
                  columnDirectives={getPickerColumnDirectives()}/>
          }
          {(dialogMode === 'new' || dialogMode === 'edit' || dialogMode === 'view') &&
              (model) &&
              (<AddEditProjectStakeholderDialog model={model} referenceData={props.referenceData}
                                                childModel={dialogChildModel} dialogMode={dialogMode}
                                                open={!!dialogMode} onAction={handleAction}/>)
          }
        </>
      </>
  );
}

export default ProjectStakeholderAssocSection;
