import {useEffect, useRef, useState} from 'react';
import {FacetSettingsInfo, IFacetState, SideNavChildrenProps} from "types/searchTypes";
import {useSearchParams} from "react-router-dom";
import {deserialize, serialize} from "components/search/FacetSideNav/serdeUtil";
import useDashUrlInfo from "hooks/useDashUrlInfo";
import useAthenaClient from "hooks/useAthenaClient";
import {IAthenaFacet} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import useSaveCount from "hooks/useSaveCount";
import CoreDebug from "../../core/CoreDebug";
import _ from "lodash";
import FacetCache from "../../../app/FacetCache";


export default function useFacetSelections(props: SideNavChildrenProps, facetCache: FacetCache | null) {
  const [searchParams, setSearchParams] = useSearchParams();
  const {entityName, getCriteria} = useDashUrlInfo();
  const {athenaClient} = useAthenaClient();
  const facetStateRef = useRef(props as IFacetState);
  const [memberCounts, setMemberCounts] = useState<Record<string, number> | null>(null);
  const [keyValueStore, setKeyValueStore] = useState<Record<string,unknown>>({});
  const [savedCount, incr] = useSaveCount();

  useEffect(() => {
    // only to satisfy react lifecycle and pass the updated counts down
    _.noop();
    console.log("memberCounts updated: ", memberCounts);
    incr();
  }, [memberCounts]);

  useEffect(() => {
    // extra render to make React happy - get proper values later in Facet components
    _.noop();
  }, [savedCount]);

  useEffect(() => {
    async function refreshMemberCounts() {
      if (athenaClient) {
        console.log('!@@ refreshMemberCounts running and athenaClient valid');
        const criteria = getCriteria(props.field);

        // if (entityName === 'people') {
        //   const searchResultSet = await athenaClient.getAllPeople(criteria.search, criteria.filter, 0, 0, undefined);
        //   const facets = searchResultSet.facets as { [p: string]: IAthenaFacet[] };
        //   const dict = convertToDict(facets[props.field]);
        //   // setMemberCounts(dict);
        //   setMemberCounts(_currentState => {
        //     console.log("memberCounts updated: ", dict);
        //     return dict;
        //   });
        //   console.log(`!@@ facetMembers ${props.field}`, memberCounts);
        // } else {
        //  const searchResultSet = await athenaClient.getAllProjectsV2(criteria.search, criteria.filter, 0, 0, undefined);
        //   const facets = searchResultSet.facets as { [p: string]: IAthenaFacet[] };
        //   const dict = convertToDict(facets[props.field]);
        //   setMemberCounts(_currentState => {
        //     console.log("memberCounts updated: ", dict);
        //     return dict;
        //   });
        //   console.log(`!@@ facetMembers ${props.field}`, memberCounts);
        // }

        let searchResultSet;
        const search = criteria.search;
        const filter = criteria.filter;
        if (entityName === 'people') {
          searchResultSet = await facetCache.getPeopleFacets(search, filter);
          //searchResultSet = await athenaClient.getAllPeople(search, filter, 0, 0, undefined);
        } else {
          searchResultSet = await facetCache.getProjectFacets(search, filter);
          //searchResultSet = await athenaClient.getAllProjectsV2(search, filter, 0, 0, undefined);
        }
        try {
          _.noop();
        } catch (error) {
          console.warn('useFacetSelection error:', {error,entityName,search,filter});
          return;
        }

        const facets = searchResultSet.facets as { [p: string]: IAthenaFacet[] };
        const dict= convertToDict(facets[props.field]);

        setMemberCounts(_currentState => {
          console.log("memberCounts updated: ", dict);
          return dict;
        });
        //console.log(`facetMembers ${props.field}`, memberCounts);
      }
    }

    refreshMemberCounts();
  },[searchParams, athenaClient]);

  function convertToDict(facets: IAthenaFacet[]): Record<string, number> {
    if (facets == null) {
      facets = [];
    }
    const result: Record<string, number> = {};

    for (const facet of facets) {
      if (facet.value !== undefined && facet.count !== undefined) {
        result[facet.value] = facet.count;
      }
    }

    return result;
  }

  function updateSearchParams() {
    const {entityName, field, mode, values} = facetStateRef.current;

    const sp = Object.fromEntries(searchParams.entries());
    if (values.length === 0) {
      delete sp[field];
    } else {
      const fsi: FacetSettingsInfo = {field, mode, values};
      sp[field] = serialize(fsi);
    }
    const newSearchParams = new URLSearchParams(sp);
    setSearchParams(newSearchParams, { replace: true });
  }

  function getFacetSelections() {
    //const a = facetStateRef.current; // <-- old way
    // code based on URL Search Params
    const facetStr = searchParams.get(props.field);
    if (facetStr) {
      const facetSettingsInfo = deserialize(props.field, facetStr);
      return {...facetSettingsInfo, entityName} as IFacetState;
    } else {
      return {entityName, field: props.field, mode:facetStateRef.current.mode, values: []};
    }
  }

  function setFacetSelections(nextFacetState: IFacetState) {
    facetStateRef.current = nextFacetState;
    console.log('!@## nextFacetState', nextFacetState);
    props.onChange(nextFacetState);
    //updateSearchParams();
  }

  function toggleValue(value: string | number) {
    const facetState = getFacetSelections();
    console.log('!@## toggleValue called with', value, facetState);
    if (typeof value === 'string' && value === '') return;
    const currentValues = facetState?.values ?? [];
    const nextFacetState = currentValues.includes(value)
        ? {...facetState, values: currentValues.filter(v => v !== value)}
        : {...facetState, values: [...currentValues, value]};
    setFacetSelections(nextFacetState);
    updateSearchParams();
  }

  function addValue(value: string | number) {
    const facetState = getFacetSelections();
    console.log('!@## addValue called with', value, facetState);
    if (typeof value === 'string' && value === '') return;
    const currentValues = facetState?.values ?? [];

    if (!currentValues.includes(value)) {
      const nextFacetState = { ...facetState, values: [...currentValues, value] };
      setFacetSelections(nextFacetState);
      updateSearchParams();
    }
  }

  function removeValue(value: string | number) {
    const facetState = getFacetSelections();
    console.log('!@## removeValue called with', value, facetState);
    if (typeof value === 'string' && value === '') return;
    const currentValues = facetState?.values ?? [];

    if (currentValues.includes(value)) {
      const nextFacetState = { ...facetState, values: currentValues.filter(v => v !== value) };
      setFacetSelections(nextFacetState);
      updateSearchParams();
    }
  }

  function toggleMode() {
    const facetState = getFacetSelections();
    const nextFacetState = { ...facetState, mode: facetState.mode === 'allof' ? 'anyof' : 'allof' };
    setFacetSelections(nextFacetState);
    updateSearchParams();
  }

  function setRange(min: string, max: string) {
    const facetState = getFacetSelections();
    const nextFacetState = { ...facetState, mode: 'between', values: [min, max]};
    setFacetSelections(nextFacetState);
    updateSearchParams();
  }

  // todo: fix to handle returning to defaults
  function clearFacetSelections() {
    const facetState = getFacetSelections();
    const nextFacetState: IFacetState = { ...facetState, mode: '', values: [] };
    setFacetSelections(nextFacetState);
    updateSearchParams();
  }

  function addToKeyValueStore(key: string, value: unknown) {
    const next = { ...keyValueStore, [key]: value };
    setKeyValueStore(next);
  }

  function deleteFromKeyValueStore(key: string) {
    const next = { ...keyValueStore };
    delete next[key];
    setKeyValueStore(next);
  }

  function getKeyValueStore() {
    return keyValueStore;
  }

  function clearKeyValueStore(){
    const next = {};
    setKeyValueStore(next);
  }

  return [
      memberCounts,
      {
          getFacetSelections,
          //setFacetSelections,
          toggleValue,
          addValue,
          removeValue,
          toggleMode,
          setRange,
          clearFacetSelections,
          addToKeyValueStore,
          deleteFromKeyValueStore,
          getKeyValueStore,
        clearKeyValueStore,
    }] as const;
}

