
// a function that generates a format function for a given number format
// numberFormatter("#,0.00")(1000) => "1,000.00"
export default function numberFormatter(formatCode: string): (value: number) => string {
    let formatter: (value: number) => string;

    switch (formatCode) {
        case "0":
            formatter = value => value.toFixed(0);
            break;
        case "0.00":
            formatter = value => value.toFixed(2);
            break;
        case "#,0":
            formatter = value => value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            break;
        case "#,0.00":
            formatter = value => value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            break;
        case "$#,0":
            formatter = value => '$' + value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            break;
        case "$#,0.00":
            formatter = value => '$' + value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            break;
        case "0%":
            formatter = value => (value * 100).toFixed(0) + "%";
            break;
        case "0.00%":
            formatter = value => (value * 100).toFixed(2) + "%";
            break;
        case "#,0%":
            formatter = value => (value * 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
            break;
        case '#,0.00%':
            formatter = value => (value * 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
            break;
        default:
            formatter = value => value.toString();
    }
    return formatter;
}