import {ApiProject, IQueryableProject} from "services/apiClients/AthenaClient";

export class CoreProject {
  static isHenselPhelpsProject(apiProject: ApiProject | IQueryableProject): boolean | undefined {
    return !apiProject.nonHenselPhelpsProject;
  }

  static isIndustryProject(apiProject: ApiProject | IQueryableProject): boolean | undefined {
    return apiProject.nonHenselPhelpsProject;
  }

  static getLocation(apiProject: ApiProject | IQueryableProject): string | undefined {
    if (apiProject) {
      if (apiProject.city || apiProject.state) {
        const values: (string | undefined | null)[] = [];
        if (apiProject.city) {
          values.push(apiProject.city);
        }
        if (apiProject.state) {
          values.push(apiProject.state);
        }
        const filteredValues = values.filter((value) => value !== undefined && value !== null);
        if (filteredValues.length > 0) {
          return filteredValues.join(', ');
        }
      }
      return undefined;
    }
  }
}
