import useAthenaClient from 'hooks/useAthenaClient'
import useCurrentUser from 'hooks/useCurrentUser'
import React, { useEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import {
  ExportSchema,
  IExportSchema,
  ITemplate,
  Template,
} from 'services/apiClients/AthenaClient'
import MetaClient from 'services/apiClients/MetaClient'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import DraggableList, { DraggableItem } from '../../components/DraggableList'
import { onlyDefaultPeopleSchema, onlyDefaultProjectSchema } from './exportUtil'
import { makeSimpleTemplate } from './templateUtil'
import _ from 'lodash'
import TemplateAndExportService from 'services/TemplateAndExportService'
import ProgressButton from 'components/dashCore/ProgressButton'
import useSaveCount from 'hooks/useSaveCount'

const useStyles = makeStyles(() => ({
  dialog: {
    top: '5%',
    width: '90%',
    height: '90%',
    padding: 0,
    margin: 'auto',
    border: '5px solid #396993',
    overflowY: 'hidden',
  },
  columnHeading: {
    fontWeight: 'bold',
  },
  clickable: {
    cursor: 'pointer',
  },
  chooseColumns: {
    textAlign: 'center',
  },
  allColumnList: {
    height: 'calc(68vh - 1px)',
    overflowY: 'auto',
  },
  selectedColumnList: {
    height: 'calc(68vh - 1px)',
    overflowY: 'auto',
  },
}))

export interface IModifyColumnsDialogProps {
  title: string
  entityName: string
  open: boolean
  onClose: () => void
}

const caseInsensitiveSort = (a: string, b: string) =>
  a.toLowerCase().localeCompare(b.toLowerCase())

function changeColumnsDistrictToRegion(label?: string | null) {
  if (typeof label === 'string' && label === 'District') return 'Region'
  if (typeof label === 'string' && label === 'Districts') return 'Regions'
  if (!label) {
    return ''
  }
  return label
}

const removeFieldsFromSchema = (
  tmpSchema: ExportSchema,
  propertyName: string,
) => {
  if (tmpSchema && tmpSchema.items && tmpSchema.items.properties) {
    const schemaItemToRemove = tmpSchema.items.properties.find(
      (property) => property.propertyName === propertyName,
    )
    if (schemaItemToRemove) {
      const removedSchemaItemIndex = tmpSchema.items?.properties?.indexOf(
        schemaItemToRemove,
      )
      tmpSchema.items.properties.splice(removedSchemaItemIndex, 1)
    }
  }
}

const getAllColumns = (schema: ExportSchema) =>
  schema.items?.properties
    ?.map((x) => changeColumnsDistrictToRegion(x.label))
    .sort((a, b) => caseInsensitiveSort(a, b)) ?? []

const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const columnFilter = (columnNames: string[], filter: string): string[] => {
  return columnNames.filter((columnName) =>
    columnName.toLowerCase().includes(filter.toLowerCase()),
  )
}

export default function ModifyColumnsDialog(props: IModifyColumnsDialogProps) {
  const classes = useStyles()
  const currentUser = useCurrentUser()
  const { athenaClient } = useAthenaClient()
  const [filter, setFilter] = useState('')
  const [schema, setSchema] = useState({} as ExportSchema)
  const [defaultSchema, setDefaultSchema] = useState({} as ExportSchema)
  //const templateRef = useRef<ITemplate | null>(null);
  const [activeTemplate, setActiveTemplate] = useState<ITemplate | null>(null)
  const [templates, setTemplates] = useState([] as ITemplate[])
  const [resetButtonEnabled, setResetButtonEnabled] = useState(true)
  const [saveCount, incr] = useSaveCount()
  // lite reference to draggable data [ only has id/primary ] as propertyName/label
  const [selectedItems, setSelectedItems] = useState([] as DraggableItem[])

  const metaClient = new MetaClient()
  // note: replace this with default columns
  //
  // note: figure out how to get default columns in here / and correct order
  // note: what happens when a "client-computed column" is added and saved?

  useEffect(() => {
    let defaultSchema: ExportSchema | null = null
    const fetchSchema = async () => {
      if (athenaClient) {
        const tmplServ = new TemplateAndExportService(athenaClient)
        const tmpSchema = await athenaClient.getQueryableSchema(
          props.entityName,
        )
        removeFieldsFromSchema(tmpSchema, 'isSalary')
        removeFieldsFromSchema(tmpSchema, 'kudosNotes')
        removeFieldsFromSchema(tmpSchema, 'signedRelease')
        removeFieldsFromSchema(tmpSchema, 'autoUpdateContractValue')
        const fieldDefDefaults = tmplServ.getDefaultSearchFields(
          props.entityName,
        )
        defaultSchema = tmplServ.wrapInExportSchemaEnvelope(
          fieldDefDefaults,
          props.entityName,
        )
        const oldDefaultSchema = ((props.entityName === 'projects'
          ? onlyDefaultProjectSchema(_.cloneDeep(tmpSchema))
          : onlyDefaultPeopleSchema(
              _.cloneDeep(tmpSchema),
            )) as unknown) as ExportSchema

        console.log('!@#- schema compare', { defaultSchema, oldDefaultSchema })
        setSchema(tmpSchema)
      }
    }

    const fetchTemplates = async () => {
      if (athenaClient) {
        const owner = currentUser?.userPrincipalName ?? ''
        const data: ITemplate[] = await athenaClient.getTemplates(
          currentUser?.userPrincipalName ?? '',
          props.entityName,
          'searchResults',
        )

        let tmpTemplate = data[0] ?? null
        if (!tmpTemplate) {
          tmpTemplate = makeSimpleTemplate(
            owner,
            props.entityName,
            'searchResults',
            `${owner}-search-result-view`,
            defaultSchema ?? undefined,
          )
        }
        if (tmpTemplate.schema) {
          removeFieldsFromSchema(tmpTemplate.schema, 'isSalary')
          removeFieldsFromSchema(tmpTemplate.schema, 'kudosNotes')
          removeFieldsFromSchema(tmpTemplate.schema, 'signedRelease')
          removeFieldsFromSchema(tmpTemplate.schema, 'autoUpdateContractValue')
        }
        //templateRef.current = tmpTemplate;
        setActiveTemplate(tmpTemplate)
        resetSelectedItems(tmpTemplate)
      }
    }

    if (athenaClient && currentUser?.userPrincipalName) {
      fetchSchema()
        .then(() => {
          fetchTemplates().catch((error) => {
            console.log(error)
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [athenaClient, currentUser, saveCount])

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return
    const newItems = reorder(
      selectedItems,
      source.index,
      destination.index,
    ) as DraggableItem[]
    setSelectedItems(newItems)
  }

  const addColumnByLabel = (label: string) => {
    let adjLabel = label
    adjLabel = label === 'Region' ? 'District' : adjLabel
    adjLabel = label === 'Regions' ? 'Districts' : adjLabel
    const newColumn = schema.items?.properties?.find(
      (x) => x.label === adjLabel,
    )
    if (newColumn?.label === 'District') newColumn.label = 'Region'
    if (newColumn?.label === 'Districts') newColumn.label = 'Regions'

    if (
      newColumn &&
      !selectedItems.some((x) => x.id === newColumn.propertyName)
    ) {
      setSelectedItems([
        ...selectedItems,
        {
          id: newColumn.propertyName,
          primary: newColumn.label,
          tag: newColumn.type,
        } as DraggableItem,
      ])
    }
  }

  const removeColumnById = (id: string) => {
    setSelectedItems(selectedItems.filter((x) => x.id !== id))
  }

  const saveColumns = async () => {
    const tpl = (activeTemplate as unknown) as Template
    const templateId = tpl.id ?? undefined
    //const nonExportableProperties = metaClient.getNonExportableProperties(props.entityName);
    // assemble the new template
    // look through the schema for column metadata
    // for each column in the selectedItems list, find the column metadata
    // and add it to the new template

    console.log('!@#- before template adjust', activeTemplate)
    const tmpExportSchemaProperties = selectedItems.map(
      (x) =>
        new ExportSchema({
          label: x.primary,
          propertyName: x.id,
          type: x.tag,
        } as IExportSchema),
    ) // as unknown as IExportSchema[];
    if (tpl?.schema?.items?.properties) {
      tpl.schema.items.properties = tmpExportSchemaProperties
    }

    let savedTemplate: Template | undefined
    if (templateId) {
      //console.log('### - Saving existing template...');
      savedTemplate = await athenaClient?.updateTemplate(
        templateId,
        new Template(tpl),
      )
    } else {
      //console.log('### - Saving new template...');
      const templates = await athenaClient?.getTemplates(
        currentUser?.userPrincipalName ?? '',
        props.entityName,
        'searchResults',
      )
      if (templates) {
        for (let i = 0; i < templates.length; i++) {
          await athenaClient?.deleteTemplate(templates[i].id as string)
        }
      }
      savedTemplate = await athenaClient?.createTemplate(tpl)
    }
    await new Promise((resolve) => setTimeout(resolve, 3000))
    setActiveTemplate(savedTemplate as ITemplate)
    setFilter('')
    props.onClose()
  }

  const cancelChanges = () => {
    setFilter('')
    props.onClose()
  }

  function handleSearchColumnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilter(e.target.value)
  }

  if (!athenaClient) return null

  function resetSelectedItems(template: ITemplate) {
    if (template) {
      const flatProperties = template.schema?.items?.properties
      console.log('!@@! flatProperties: ', flatProperties, { activeTemplate })

      const columnItems = flatProperties
        ? flatProperties.map((x: IExportSchema) => {
            return {
              id: x.propertyName,
              primary: x.label,
              tag: x.type,
            } as DraggableItem
          })
        : ([] as DraggableItem[])
      console.log('!@#- column items', columnItems)
      setSelectedItems(columnItems ?? [])
    }
  }

  async function reloadDefaultCols() {
    let defaultSchema: ExportSchema | null = null
    if (athenaClient) {
      const tmplServ = new TemplateAndExportService(athenaClient)
      const tmpSchema = await athenaClient.getQueryableSchema(props.entityName)
      removeFieldsFromSchema(tmpSchema, 'isSalary')
      removeFieldsFromSchema(tmpSchema, 'kudosNotes')
      removeFieldsFromSchema(tmpSchema, 'signedRelease')
      removeFieldsFromSchema(tmpSchema, 'autoUpdateContractValue')

      const fieldDefDefaults = tmplServ.getDefaultSearchFields(props.entityName)
      defaultSchema = tmplServ.wrapInExportSchemaEnvelope(
        fieldDefDefaults,
        props.entityName,
      )
      const oldDefaultSchema = ((props.entityName === 'projects'
        ? onlyDefaultProjectSchema(_.cloneDeep(tmpSchema))
        : onlyDefaultPeopleSchema(
            _.cloneDeep(tmpSchema),
          )) as unknown) as ExportSchema

      console.log('!@#- schema compare', { defaultSchema, oldDefaultSchema })
      setSchema(tmpSchema)
      const owner = currentUser?.userPrincipalName ?? ''
      const tmpTemplate = makeSimpleTemplate(
        owner,
        props.entityName,
        'searchResults',
        `${owner}-search-result-view`,
        defaultSchema ?? undefined,
      )
      setActiveTemplate(tmpTemplate)
      resetSelectedItems(tmpTemplate)
    }
  }

  async function deleteTemplate() {
    if (athenaClient && activeTemplate && activeTemplate.id) {
      console.log('!@#5 template delete', activeTemplate.id)
      await athenaClient.deleteTemplate(activeTemplate.id)
    }
  }

  function reset() {
    setResetButtonEnabled(false)
    deleteTemplate().then(() => {
      reloadDefaultCols().then(() => {
        setResetButtonEnabled(true)
      })
    })
  }

  return props.open ? (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={props.onClose}
      fullScreen={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        className="text-white bg-black text-center"
      >
        Change Columns -{' '}
        {props.entityName === 'projects' ? 'Projects' : 'People'}
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.columnHeading}>
              Choose Columns:
            </Typography>
            <TextField
              label="Search columns"
              onChange={handleSearchColumnChange}
            />
            <List className={classes.allColumnList}>
              {columnFilter(getAllColumns(schema), filter).map(
                (columnName, index) => (
                  <ListItem
                    key={index}
                    onClick={() => addColumnByLabel(columnName)}
                  >
                    <ListItemText primary={columnName} />
                    <ListItemIcon className={classes.clickable}>
                      <Add />
                    </ListItemIcon>
                  </ListItem>
                ),
              )}
            </List>
          </Grid>
          <Grid item xs={6}>
            {/*<DashDivider width={50} height={26} />*/}
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                <Typography className={classes.columnHeading}>
                  Selected Columns:
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <ProgressButton
                  onClick={reset}
                  reset={resetButtonEnabled}
                  text={'Reset to Default'}
                />
              </Grid>
            </Grid>
            <DraggableList
              items={selectedItems}
              className={classes.selectedColumnList}
              onDragEnd={onDragEnd}
              onRemoveItem={removeColumnById}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <ProgressButton onClick={saveColumns} text={'Save'} />
        <Button onClick={cancelChanges}>Cancel</Button>
        {/*<Button onClick={saveModifiedColumns}>Save</Button>*/}
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  )
}
