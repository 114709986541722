import React, {useEffect, useState, CSSProperties, useRef} from 'react';
import {Button, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles(() => ({
  button: {
    //margin: theme.spacing,
    '&:disabled': {
      cursor: 'wait',
      backgroundImage: "url('/images/svg/bg-progress.svg')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
      backgroundSize: '100% auto',
    }
  },
}));

interface IProgressButtonProps {
  onClick?: () => void,
  text?: string,
  reset?: boolean,
  onReset?: () => void;
  hidden?: boolean;
  blurNeeded?: boolean;
  style?: CSSProperties;
}

const textInputStyle = {
  display: 'inline-block',
  width: '0',
  height: '0',
};

export default function ProgressButton(props: IProgressButtonProps) {
  const [disabled, setDisabled] = useState(false);
  const classes = useStyles();
  const textInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.reset) {
      setDisabled(false);
      if (props.onReset) {
        props.onReset();
      }
    }
  }, [props.reset, props.onReset]);

  function handleClick() {
    if (props.blurNeeded && textInputRef.current) {
      textInputRef.current.focus();
    }
    if (props.onClick) {
      props.onClick();
      setDisabled(true);
    }
  }

  return (
      !props.hidden ? (
          <>
            {props.blurNeeded && (
                <input type="text" style={textInputStyle} ref={textInputRef}/>
            )}
            <Button
                className={classes.button}
                style={props.style}
                onClick={handleClick}
                disabled={disabled}
            >
              {props.text}
            </Button>
          </>
      ) : null
  );
}

