import {withDashCard} from '../../shared/withDashCard';
import ClientSection from "./ClientSection";

const ClientInformationCard = withDashCard('Client Information', 'client-information-module',
    // list of components to add to the card (in order they should appear)
    [
      ClientSection,
    ]
);

export default ClientInformationCard;