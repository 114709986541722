import {IProjectAward, ProjectAward} from "services/apiClients/AthenaClient";

export type BetterProjectAward = {
  id?: string;
  title: string;
  level?: string;
  category?: string;
  year?: number | undefined;
  sponsor?: string;
  isPreffered?: string | undefined;
  modifiedOn?: Date | undefined;
  modifiedBy?: string | undefined;
}


export function ToProjectAward(award: BetterProjectAward): IProjectAward {
  return {
    title: award.title,
    citation: award.level,
    date: award.year !== undefined ? new Date(`${award.year}-01-01T00:00:00`) : undefined,
    sponsor: award.sponsor,
    category: award.category,
    isPreffered: award.isPreffered,
    id: award.id,
    modifiedOn: award.modifiedOn,
    modifiedBy: award.modifiedBy,
  };
}

export function ToBetterProjectAward(award: IProjectAward): BetterProjectAward {
  return {
    title: award.title || '',
    level: award.citation,
    category: award.category || '',
    year: award.date !== undefined ? award.date.getFullYear() : undefined,
    sponsor: award.sponsor || '',
    isPreffered: award.isPreffered || '',
    id: award.id || '',
    modifiedOn: award.modifiedOn,
    modifiedBy: award.modifiedBy,
  };
}

