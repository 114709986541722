import { ChangeEvent } from 'react';
import { MenuItem, TextField } from '@material-ui/core';

interface IProps {
  type: string;
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  choicesKey?: string;
}

export function createControls(config: IProps[],
  originalValues: Record<string, any>,
  onChangeCallback?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  choiceSet?: Record<string, string[]>,
  classesFromUseStyles?: Record<string, string>) {

  const values = originalValues;
  const controls = config.map(({ type, name, label, required, disabled, choicesKey }) => {
    const [componentType, className] = type.split('.');
    const isSelect = choiceSet && choicesKey && (choicesKey in choiceSet) ? true : undefined;

    switch (componentType) {
      case 'TextField':
      case 'DateField':
      case 'NumberField':
      case 'MoneyField':
        return (
          <TextField
            key={name}
            name={name}
            label={label}
            type={getInputType(componentType)}
            defaultValue={values[name]}
            onChange={onChangeCallback}
            required={required}
            disabled={disabled}
            select={isSelect ? true : undefined}
            className={(className && (className in classesFromUseStyles!)) ? classesFromUseStyles![className] : undefined}
            InputLabelProps={componentType === 'DateField' ? { shrink: true } : undefined}  // Conditional addition
          >
            {isSelect && choiceSet !== undefined && choicesKey !== undefined &&
              choiceSet[choicesKey].map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        );

      case 'h2':
        return (
          <h2
            className={(className && (className in classesFromUseStyles!)) ? classesFromUseStyles![className] : undefined}
          >{label ? label : values[name]}</h2>);
      case 'h3':
        return (
          <h3
            className={(className && (className in classesFromUseStyles!)) ? classesFromUseStyles![className] : undefined}
          >{label}</h3>);
      case '*hidden':
        return (
          <input key={name} name={name} type="hidden" value={values[name]} />
        )
      default:

        return null;
    }
  });

  return controls.filter(x => x !== null) as unknown as JSX.Element[];
}

function filterTypes(obj: Record<string, any>): Record<string, string | number | boolean> {
  const result: Record<string, string | number | boolean> = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string' || typeof obj[key] === 'number' || typeof obj[key] === 'boolean') {
      result[key] = obj[key];
    }
  }

  return result;
}

type FieldType = 'TextField' | 'DateField' | 'MoneyField' | 'NumberField';

const getInputType = (fieldType: string): string => {
  switch (fieldType) {
    case 'TextField':
      return 'text';
    case 'DateField':
      return 'date';
    case 'MoneyField':
      return 'number';
    case 'NumberField':
      return 'number';
    default:
      return 'text'; // Default case, though TypeScript should ensure one of the defined types is used
  }
};

