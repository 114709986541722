import useAthenaClient from "hooks/useAthenaClient";
import {useGlobalState} from "app/state/useGlobalState";
import InfotipButton from "../../../InfotipButton/InfotipButton";
import useToast from "hooks/useToast";
import DashToaster from "patterns/DashToaster";

import React, {useEffect, useRef, useState} from 'react';
import {
  ApiProjectReference,
  IApiProject,
  IApiProjectReference,
  IQueryableContact
} from "services/apiClients/AthenaClient";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {getReferenceConfig} from "../../personViewRelated/gridConfigSettings/projectReferenceGridConfig";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import SectionWithGridAndCardList from "../../shared/SectionWithGridAndCardList";
import {convertReferences, IInfoCard} from "services/infoCardConversion";
import ChangeViewButton from "../../../dashCore/ChangeViewButton";
import PickerDialog from "../AddProjectReference/PickerDialog";
import AddEditProjectReference from "../AddProjectReference";
import CoreDebug from "../../../../features/core/CoreDebug";
import _ from "lodash";

//const ProjectReferenceCard = (props: { model: IApiProject }) => observe(() => {
const ProjectReferenceCard: React.FC<{ model: IApiProject }> = (props) => {
  const sharedClasses = useSharedLargeCardStyles();
  const gs = useGlobalState();
  const {athenaClient} = useAthenaClient();
  const [recordModel, setRecordModel] = useState<IApiProjectReference | null>(null);
  const [model, setModel] = useState(props.model);

  const [listViewOn, setListViewOn] = useState<boolean>(true);
  const [dialogMode, setDialogMode] = useState<string | null>(null);
  const [contactId, setContactId] = useState<string | null>(null);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [addReferenceOpen, setAddReferenceOpen] = useState<boolean>(false);
  const [referenceManagedByCRM, setReferenceManagedByCRM] = useState<boolean>(false);
  const [projectModel, setProjectModel] = useState<IApiProject | null>(props.model);
  const initTicks = new Date().getTime();
  const [ticks, setTicks] = useState<number>(initTicks);

  const toaster = useToast();
  const dashToaster = new DashToaster(toaster.displayToast);
  const config = getReferenceConfig(gs.userRoles);
  const logger = useRef('');
  // todo: this may need to change a little
  const infoCards: IInfoCard[] = convertReferences(props.model.references ?? [])

  const refresh = () => setTicks(new Date().getTime());

  function handlePickerClose(payload: unknown) {
    console.log('$$$ begin func handlePickerClose - payload', payload);
    // contactId is the newer CRM concept, conceptId could also be ProjectReferenceId under the hood
    // check crmId for value to tell if its new style or old style "Contact"
    if (payload) {
      const contact = payload as IQueryableContact;
      const contactId = contact.id;

      if (contactId) {
        setContactId(contactId);
        setDialogMode('edit');
        setReferenceManagedByCRM(true);
        setAddReferenceOpen(true);

        console.log('$$$ ui state after picker');
        // {contactId, dialogMode: 'will be new', addReferenceOpen: true});
      }
      setPickerOpen(false);
    } else {
      console.log('$$$ must be a cancel, payload null');
      setPickerOpen(false);
    }
  }

  function handleAddReferenceClose() {
    setContactId('');
    setDialogMode(null);
    setAddReferenceOpen(false);
    refresh();
  }

  useEffect(() => {
    //console.log('$$$ reload model useEffect');

    async function reloadModel() {
      if (athenaClient) {
        //console.log('$$$ calling API getProject');
        const project = await athenaClient.getProject(props.model.id!);
        //setReferences(person.references ?? [] as IApiPersonReference[]);
        setModel(project);
        setProjectModel(project ?? null);
      }
    }

    //console.log('$$$ about to run reloadModel');
    reloadModel();
  }, [ticks]);

  function handleToolbarClick(args: any, data: object[]) {
    //console.log('handleOnToolbarClick args.item.text',args,data);
    const record = data.shift();
    switch (args.item.text) {
      case 'Add Reference':
        // ???? ui.setMode('edit');
        setPickerOpen(true);
        setReferenceManagedByCRM(true);
        args.cancel = true;
        break;
      case 'View':
        if (record) {
          console.log('$$$$ view', 'is is a record');
          setDialogMode('view');
          setAddReferenceOpen(true);
          const d = record as Record<string, unknown>;
          const tmpRecord = {...record, fullName: (d['personFullName'])} as IApiProjectReference;
          console.log('$$$ trying to View', tmpRecord);
          setContactId(tmpRecord.contactId ?? null);
          setReferenceManagedByCRM(tmpRecord?.isManagedByCrm ?? true);
          setRecordModel(record);
          console.log('$$$$ in view');
          refresh();
          console.log(`$$$- View action`, {record, dialogMode, addReferenceOpen});
        }
        args.cancel = true;
        break;
      case 'Unlink Reference':
        if (!record) {
          alert('You must select a record to edit first.');
        } else {
          if (athenaClient) {
            console.log('$$$ unlink', {record});
            const tmpRecord = record as Record<string, string>;
            const projectId = props.model.id as string;
            if (confirm(`Are you sure you want to unlink '${tmpRecord.fullName}' from this record?`)) {
              if (tmpRecord['isManagedByCrm']) {
                // CRM path
                athenaClient.deleteProjectContactLink2(projectId, tmpRecord.contactId).then(() => {
                //athenaClient.deleteProjectContactLink(projectId, tmpRecord.contactId).then(() => {
                  dashToaster.displayUnlinkedToast(tmpRecord.fullName);
                  refresh();
                });
              } else {
                // Non-CRM path
                // fn sig deleteProjectReferenceLink(projectId: string, referenceId: string)
                // contactId is same as referenceId - because backend

                // athenaClient.deleteProjectReferenceLink(projectId, tmpRecord.contactId).then(() => {
                //   dashToaster.displayUnlinkedToast(tmpRecord.fullName);
                //   refresh();
                // });
                athenaClient.deleteProjectContactLink2(projectId, tmpRecord.contactId).then(() => {
                  dashToaster.displayUnlinkedToast(tmpRecord.fullName);
                  refresh();
                });
              }
            }
          }
        }
        args.cancel = true;
        break;
    }
  }

  function handleAction(action: string, payload?: string | null | unknown) {
    console.log(payload);
    switch (action) {
      case 'close':
        setDialogMode(null);
        break;
    }
  }

  function normalizeReferences(apiProjectReferences: ApiProjectReference[] | undefined): IApiProjectReference[] {
    if (apiProjectReferences == null) return [];

    return apiProjectReferences.map(record => {
      if (!record.fullName && record.firstName && record.lastName) {
        return {...record, fullName: `${record.firstName} ${record.lastName}`};
      }
      return record;
    });
  }

  const projectReferences = normalizeReferences(model.references);
  //console.log('$$$$ out', {projectModel, projectReferences, dialogMode});

  return (
      <React.Fragment>
        <Card id={'project-references-module'} className={sharedClasses.defaultCard}>
          <CardHeader
              title={<>Project References <InfotipButton infotipKey={'references'}/></>}
              data-scroll-name={'reference'}
              action={<ChangeViewButton showListIcon={listViewOn}
                                        onClick={() => setListViewOn(x => !x)}/>}/>
          <CardContent className="flex">
            <SectionWithGridAndCardList listView={listViewOn} key={'grid' + ticks}
                                        items={projectReferences}
                                        columnDirectives={config.columnDirectives}
                                        toolbarOptions={config.toolbarOptions}
                                        showToolbar={true}
                                        sortingOptions={config.sortingOptions}
                                        title={config.title}
                                        showTitle={config.showTitle}
                                        allowAdd={config.allowAdd}
                                        allowRemove={config.allowRemove}
                                        infoCards={convertReferences(projectReferences ?? [])}
                                        onToolbarClick={handleToolbarClick}
                                        exportColumns={config.exportColumns}
                                        allowFiltering={true}
            />
          </CardContent>
          {/*<ReferenceDialog*/}
          {/*    model={CoreDebug.passThru('@a the model', _.cloneDeep(model))}*/}
          {/*    open={CoreDebug.passThru('@a ui.dialogOpen', ui.dialogOpen)}*/}
          {/*    initialMode={'search'}*/}
          {/*    majorEntityName={'projects'}*/}
          {/*    onClose={() => close()}/>*/}

          <PickerDialog
              title={'Add Reference'}
              searchBoxLabel={'Search for contact...'}
              open={pickerOpen}
              onClose={(payload) => handlePickerClose(payload)}
              dataSourceName={'contacts'}
              extraInfo={{personFullName: props.model.name!}}
          />
          <>
            {addReferenceOpen && dialogMode &&
                <>
                  {/*<div style={{height:'100px',color:'#0f0',backgroundColor:'#111'}}>*/}
                  {/*  {JSON.stringify(ui)}*/}
                  {/*</div>*/}

                    <AddEditProjectReference
                        key={`addEditProjectReference${ticks}`}
                        personFullName={model.name}
                        instanceName={'001'}
                        projectId={CoreDebug.passThru('$$$$ pt project model.id', model.id ?? 'missing')}
                        contactId={CoreDebug.passThru('$$$$ pt contactId', contactId ?? undefined)}
                        isManagedByCRM={referenceManagedByCRM}
                        mode={dialogMode}
                        projectModel={projectModel}
                        open={true}
                        onClose={handleAddReferenceClose}/>
                </>
            }

            {/*{addReferenceOpen && dialogMode === 'view' &&*/}
            {/*    recordModel !== null &&*/}
            {/*    recordModel['contactId'] &&*/}
            {/*    // <ViewProjectReference mode={dialogMode}*/}
            {/*    //                       open={true}*/}
            {/*    //                       model={recordModel}*/}
            {/*    //                       onAction={handleAction} />*/}
            {/*    <AddEditProjectReference personFullName={model.name}*/}
            {/*                             instanceName={'b'}*/}
            {/*                             projectId={model.id ?? 'missing'}*/}
            {/*                             projectModel={model}*/}
            {/*                             contactId={contactId ?? undefined}*/}
            {/*        //model={recordModel as IApiProjectReference}*/}
            {/*                             model={projectModel}*/}
            {/*                             mode={'view'}*/}
            {/*                             open={true}*/}
            {/*                             onClose={handleAddReferenceClose}/>*/}
            {/*}*/}
            {/*/!*<div>{ui.contactId}</div>*!/*/}
            {/*/!*<div className="hidden">{ui.forceCount}</div>*!/*/}
          </>
        </Card>
      </React.Fragment>);
};

export default ProjectReferenceCard;