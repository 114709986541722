import {Column, ColumnDirective, EditSettingsModel, SortSettingsModel} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardList, ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {hasPermission} from "../../../../app/security/utils/securityUtil";

export function getTrainingConfig(userRoles: string[]) {
  const columns = [
      {
        headerText: 'Course',
        field: 'course',
        width: 120,
        allowSorting: true,
        validationRules: { required: true },
      } as Partial<Column>,
      {
        headerText: 'Sponsor',
        field: 'sponsor',
        width: 100,
        editable: false,
        allowSorting: true,
      } as Partial<Column>,
      {
        headerText: 'Start Date',
        field: 'startDate',
        width: 100,
        editType: 'datePickerEdit',
        edit: {},
        format: {type: 'date', skeleton:'short'},
        allowSorting: true,
      } as Partial<Column>,
      {
        headerText: 'End Date',
        field: 'endDate',
        width: 100,
        editType: 'datePickerEdit',
        edit: {},
        format: {type: 'date', skeleton:'short'},
        allowSorting: true,
      } as Partial<Column>,
      {
        headerText: 'Goals',
        field: 'goals',
        width: 120,
        //format: {type: 'date', skeleton:'short'},
        //allowSorting: true,
      } as Partial<Column>,
    ] as Column[];

  const sortingOptions = {
    columns:  [{ field: 'endDate', direction: 'Descending' }]
  } as SortSettingsModel;

  let editOptions: EditSettingsModel = {
    allowEditing: false, allowAdding: false, allowDeleting: false,
    mode: 'Dialog',
    //allowEditOnDblClick: true,
  };

  if (hasPermission(['Contributor'],userRoles)) {
    const changes = { allowEditing: true, allowAdding: true, allowDeleting: true };
    editOptions = {...editOptions, ...changes}
  }
  const config = {
    columnDirectives: columns,
    sortingOptions: sortingOptions,
    editOptions: editOptions,
    title: 'Training',
    showTitle: true,
  } as ISectionWithGridAndCardListConfig;
  return config;
}
// SortingOptions: SortSettingsModel = {
//   columns: [{ field: 'EmployeeID', direction: 'Ascending' }]
// };