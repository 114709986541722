import {useState} from "react";

export default function useTicks() {
  const [ticks, setTicks] = useState(0);

  const updateTicks = () => {
    const nextTicks = new Date().getTime();
    setTicks(nextTicks);
    console.log('updateTicks', nextTicks);
  }

  return [ticks, updateTicks] as const;
}