export const PANCAKE_TYPOGRAPHY = {
  fontFamily: ['Helvetica LT std', 'Helvetica', 'Arial'].join(','),
  useNextVariants: true,
};

export const sharedOverrides = {
  MuiButtonGroup: { grouped: { minWidth: 30 } },
  MuiTableRow: {
    hover: { '&:hover': { cursor: 'pointer' } },
  },
  MUIDataTable: {
    responsiveBase: { padding: 16 },
  },
  MuiTableCell: {
    root: {
      paddingTop: 0 + '!important',
      paddingBottom: 0 + '!important',
      '@media (min-width: 960px)': {
        height: 30,
      },
    },
  },
  MUIDataTableBodyCell: {
    stackedCommon: {
      '@media (max-width: 960px)': {
        fontSize: '0.875rem',
      },
    },
  },
  MUIDataTablePagination: {
    navContainer: {
      justifyContent: 'center !important',
    },
  },
  MuiTablePagination: {
    spacer: {
      flex: 0,
    },
    toolbar: {
      justifyContent: 'start',
      '@media (max-width: 960px)': {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    actions: {
      '@media (max-width: 960px)': {
        marginLeft: 0,
      },
    },
  },

  // Active Input Styles
  MuiOutlinedInput: {
    inputMarginDense: { paddingTop: 9, paddingBottom: 6 },
    // Active Input Styles
    root: {
      '&$focused $notchedOutline': {
        borderWidth: 2,
      },
    },
  },
  MuiTableSortLabel: {
    icon: {
      fontSize: '1rem',
      marginTop: 16,
      fontWeight: 'bold',
    },
  },
  // this sets table filter options to 100% width
  MuiGridListTile: { root: { width: '100% !important' } },
  MuiFormControl: { root: { width: '100%' } },
  MuiInputBase: { root: { minHeight: 36 }, input: { fontSize: '0.875rem' } },
  MuiSelect: { icon: { color: 'hsl(0,0%,80%) !important' } },
  MuiPickersToolbarText: { toolbarTxt: { fontSize: '2rem' } },
};
