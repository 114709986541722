import React, {useEffect, useReducer, useRef, useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import {SideNavChildrenProps} from 'types/searchTypes';
import ClearFacetButton from 'components/search/facets/ClearFacetButton';
import {makeStyles} from "@material-ui/core/styles";
import withRangeFacet, {RangeProps} from "../withRangeFacet";
import {useGuid} from "features/core/Guid";
import _ from "lodash";
//import NumericDebouncedTextBox from "features/Facets/NumericDebouncedTextBox";
import {useGlobalState} from "../../../../app/state/useGlobalState";
import {useObserver} from "mobx-react";
import CoreDate from "../../../../features/core/CoreDate";
import DebouncedInputComponent from "../../../../features/Facets/DebouncedInputComponent";
import useAthenaClient from "../../../../hooks/useAthenaClient";
import CoreDebug from "../../../../features/core/CoreDebug";

const useStyles = makeStyles(() => ({
  itemLabel: {
    fontSize: '14px',
    color: '#000',
  },
  itemCount: {
    fontSize: '14px',
    color: '#999',
  },
  // start accordion style
  accordion: {
    width: '275px',
    //borderTop: '1px solid #bbbbb',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    //borderBottom: '1px solid #ccc',
    // '&.Mui-expanded': {
    //   borderBottom: '1px solid #999',
    // },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    fontSize: '14px',
    '& > div': {
      width: '100%',
    }
  },
  somewhatHidden: {
    color: 'white',
  }
}));

const formatter = new Intl.NumberFormat();
const nonDigitsRegex = /[^0-9]/g;

function NumberRangeFacet(props: SideNavChildrenProps & RangeProps) {
  const classes = useStyles();
  const gs = useGlobalState();
  const {athenaClient} = useAthenaClient();
  const [expanded, setExpanded] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [guidKeyMin, newGuidMin] = useGuid();
  const [guidKeyMax, newGuidMax] = useGuid();

  const [minimum, setMinimum] = useState<string | undefined>();
  const [maximum, setMaximum] = useState<string | undefined>();

  console.log('!@# props', props);



  useEffect(() => {
    console.log('!@# props', {'props.min': props.min, 'props.max': props.max, props: props});
    setMinimum(props.min);
    setMaximum(props.max);
  }, [props.min, props.max]);



  function handleMinInputChange(value: string | undefined) {
    console.log('!@#1 handleMinInputChange', {value, isModified});
    setMinimum(value);
    if (!isModified) {
      setIsModified(true);
      props.setIsModified(true);
    }
    props.setMin(value ?? null);
  }

  function handleMaxInputChange(value: string | undefined) {
    console.log('!@#1 handleMaxInputChange', value);
    setMaximum(value);
    if (!isModified) {
      setIsModified(true);
      props.setIsModified(true);
    }
    props.setMax(value ?? null);
  }

  const handleResetAndClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(false);
    // expandedFacetId_FIX
    gs.expandedFacetId = undefined;
    newGuidMin();
    newGuidMax();
    setMinimum('');
    setMaximum('');
    setIsModified(false);
    props.reset();
    //newGuid();
  }

  function handleAccordionChange(_event: unknown) {
    // if it is already my id then it shouldn't be me and I should close
    const nextId = (gs.expandedFacetId === props.id) ? 'none' : props.id;
    gs.setExpandedFacetId(nextId);
  }

  function display(value?: string | undefined | null) {
    if (!value) return '';
    const numValue = parseInt(value.replace(nonDigitsRegex, ''), 10);
    return formatter.format(numValue);
  }

  function normalize(value?: string | undefined) {
    if (!value) return undefined;
    const numericValue = parseFloat(value.replace(nonDigitsRegex, '')); // Parse the value to a floating-point number
    return String(Math.floor(numericValue)); // Floor the value to an integer, and then convert it to a string
  }

  // todo: extract common
  const allEqual = (...args: unknown[]) => args.every(val => val === args[0]);


  return useObserver(() =>
      <>
        <Accordion className={classes.accordion}
                   TransitionProps={{ timeout: 50 }}
                   onChange={handleAccordionChange}
                   expanded={gs.expandedFacetId === props.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <ClearFacetButton visible={
            !CoreDebug.passThru(['',minimum,maximum],
                allEqual('', minimum ?? '', maximum ?? ''))}
                            onClick={handleResetAndClose} />
            <Typography>{props.label}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
              <Grid item xs={12}>
                <React.Fragment>
                  <div className="text-left" style={{paddingBottom:'10px'}}>
                  <span>Min</span><br/>
                    { athenaClient &&
                    <DebouncedInputComponent key={guidKeyMin}
                      value={display(minimum)}
                      type="text"
                      comment={props.field + 'Min'}
                      onDebouncedChange={(v) => {
                        handleMinInputChange(normalize(v))
                      }} />
                    }
                    <br/>
                  </div>

                  <div className="text-left">
                    <span>Max</span><br/>
                    { athenaClient &&
                    <DebouncedInputComponent key={guidKeyMax}
                        value={display(maximum)}
                        type="text"
                        comment={props.field + 'Max'}
                        onDebouncedChange={(v) => {
                          handleMaxInputChange(normalize(v))
                        }} />
                    }
                  </div>
                  {/*<div>{React.version}</div>*/}
                </React.Fragment>

              </Grid>
              <Grid item xs={12} className={classes.somewhatHidden}>
                {props.defaultMin} : {props.defaultMax}
              </Grid>
              <Grid item xs={12} className={classes.somewhatHidden}>
                {String(isModified)} : {String(allEqual(undefined, minimum, maximum))} : {minimum} : {maximum} : {props.min} : {props.max}
              </Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>
        <div className="accordionDetailEnd"></div>

      </>
  );
}

export default withRangeFacet(NumberRangeFacet);