import React, {useState, useEffect, useContext, useRef} from 'react';
import { Card, CardContent, CardHeader} from "@material-ui/core";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";
import {
  IApiProject,
  IPersonAward,
  IProjectAward,
  ProjectAward,
} from "services/apiClients/AthenaClient";
import SectionWithGridAndCardList from "../shared/SectionWithGridAndCardList";
import {
  convertProjectAwards,
} from "services/infoCardConversion";
import ChangeViewButton from "../../dashCore/ChangeViewButton";
import {getProjectAwardConfig} from "../personViewRelated/gridConfigSettings/projectAward";
import {IEntityAction} from "utils/syncFusionUtil";
import useAthenaClient from "hooks/useAthenaClient";
import ProjectService from "services/ProjectService";
import useToast from "hooks/useToast";
import DashToaster from "patterns/DashToaster";
import useSaveCount from "hooks/useSaveCount";
import {useGlobalState} from "../../../app/state/useGlobalState";
import PeopleAwardDialog from "../personViewRelated/awards/PeopleAwardDialog";
import ProjectAwardDialog from "../personViewRelated/awards/ProjectAwardDialog";
import useTicks from "hooks/useTicks";
import {hasPermission} from "app/security/utils/securityUtil";
import {BetterProjectAward, ToBetterProjectAward, ToProjectAward} from "./AddProjectReference/projectAwardConvertors";


export default function ProjectAwardsCard(props: { model?: IApiProject }) {
  const sharedClasses = useSharedLargeCardStyles();
  const gs = useGlobalState();
  const {athenaClient} = useAthenaClient();
  const [listViewOn, setListViewOn] = useState(true);
  const changeView = () => setListViewOn(!listViewOn);
  const [count, incr] = useSaveCount();
  const [awardMode, setAwardMode] = useState('browse');
  const [awardItems, setAwardItems] = useState([] as IPersonAward[]);
  const projectAwardConfig = getProjectAwardConfig(gs.userRoles);

  const toaster = useToast();
  const dashToaster = new DashToaster(toaster.displayToast);

  const [dialogMode, setDialogMode] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentModel, setCurrentModel] = useState<IProjectAward | null>(null);
  const cardContentRef = useRef(null);
  const [ticks, incrTicks] = useTicks();

  const modelId = props?.model?.id ?? '';

  useEffect(() => {
    async function reloadData() {
      if (athenaClient && modelId) {
        const tmpModel = await athenaClient.getProject(modelId);
        setAwardItems(tmpModel.awards as IProjectAward[]);
      }
    }
    reloadData();
  }, [athenaClient, count]);

  // const cancel = () => {
  //   if (confirm('Are you sure you want to cancel without saving changes?')) {
  //     setAwardMode('normal');
  //   }
  // }

  // actions available via the toolbar
  function handleToolbarClick(args: any, data: object[]) {
    // data comes through as array of records
    // at this time we can only select one at a time use the [0] index
    switch (args.item.text) {
      case 'Add':
        incr();
        setCurrentModel(null);
        setDialogMode('new');
        setDialogOpen(true);
        args.cancel = true;
        break;
      case 'Edit':
        if (!data[0]) {
          alert('You must select a record to view first.');
        } else {
          const record = data.shift() as IProjectAward;
          //console.log('edit project award record', record);
          //setPersonAward(record as IPersonAward);
          setCurrentModel(record as unknown as IProjectAward);
          setDialogMode('edit');
          setDialogOpen(true);
        }
        args.cancel = true;
        break;
      case 'Delete':
        if (!data[0]) {
          alert('You must select a record to edit first.');
        } else {
          const pa = data.shift() as IPersonAward;
          const name = pa.title ?? '';
          if (confirm(`Are you sure you want to remove ${name} from this record`)) {
            if (athenaClient) {
              const tmpModel = props?.model as IApiProject;
              athenaClient.deleteAward2(tmpModel.id as string, pa.id as string).then(() => {
                setDialogMode(null);
                setDialogOpen(false);
                incr();
              });
            }
          }
        }
        args.cancel = true;
        break;
    }
  }

  function handleAwardDialogSubmit(data: BetterProjectAward, action: string) {
    handleAwardAction({data: data as BetterProjectAward, action: action} as IEntityAction);
    setDialogOpen(false);
  }

  //#region Project Award handler/dispatch/mutation
  function handleAwardAction(actionObj: IEntityAction | null) {
    const bpa = actionObj?.data as BetterProjectAward;
    const pa = ToProjectAward(bpa);

    if (actionObj) {
      //console.log('!!! actionObj.action', actionObj.action);
      switch (actionObj.action) {
        case 'new':
          athenaClient?.createAward(modelId as string, pa as ProjectAward)
              .then(() => {
                handleDialogClose();
                dashToaster.displaySavedToast(pa.title as string);
                setTimeout(() => incr(), 400);
              });
          break;
        case 'edit':
          athenaClient?.updateAward2(modelId as string, pa.id as string, pa as ProjectAward)
              .then(() => {
                handleDialogClose();
                dashToaster.displayUpdatedToast(pa.title as string);
                setTimeout(() => incr(), 400);
              });
          break;
        case 'delete':
          athenaClient?.deleteAward2(modelId as string, pa.id as string)
              .then(() => {
                handleDialogClose();
                dashToaster.displayDeletedToast(pa.title as string);
                setTimeout(() => incr(), 400);
              });
          break;
      }
    }
  }
  //#endregion

  function handleDialogClose() {
    setDialogMode(null);
    setDialogOpen(false);
    setCurrentModel(null);
    incr();
  }

  if (!athenaClient) return null;


  const awardItemsWithYear = awardItems.map(item => {
    const newItem = {...item} as IPersonAward & { year?: number | undefined };
    newItem.year = item.date ? item.date.getFullYear() : undefined;
    return newItem;
  });

  return (
      <Card id="project-awards-module" className={sharedClasses.defaultCard}>
        <CardHeader
            title={'Project Awards'} data-scroll-name={'awards'}
            action={<ChangeViewButton showListIcon={listViewOn} onClick={changeView}/>} />
        <CardContent style={{display:'flex'}}>
          <SectionWithGridAndCardList listView={listViewOn}
                                      items={awardItemsWithYear}
                                      columnDirectives={projectAwardConfig.columnDirectives}
                                      exportColumns={projectAwardConfig.exportColumns}
                                      sortingOptions={projectAwardConfig.sortingOptions}
                                      editOptions={projectAwardConfig.editOptions}
                                      showToolbar={hasPermission(['Contributor'], gs.userRoles)}
                                      title={projectAwardConfig.title}
                                      showTitle={projectAwardConfig.showTitle}
                                      infoCards={convertProjectAwards(awardItems)}
                                      allowExcelExport={true}
                                      onAction={handleAwardAction}
                                      onToolbarClick={(args, data) => handleToolbarClick(args, data)}
                                      specialCommand={'Award-Year/Date'}
          />

          <ProjectAwardDialog open={dialogOpen}
                              dialogMode={dialogMode}
                              model={currentModel ? ToBetterProjectAward(currentModel): undefined}
                              onClose={handleDialogClose}
                              ticks={ticks}
                              onSubmit={(data) => handleAwardDialogSubmit(data, data?.id ? 'edit' : 'new')}/>
        </CardContent>
      </Card>
  )
}