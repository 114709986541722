import { useEffect, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import AthenaClient, { IApiPerson } from 'services/apiClients/AthenaClient'
import CloseIcon from '@material-ui/icons/Close'
import DashRichTextEditor from '../shared/DashRichTextEditor'
import PersonDetailService from 'services/PersonService'
import { getNamedData } from 'utils/forms'
import { DashItemProgress } from 'components/dashCore/DashItemProgress'
import EntityDialog from '../shared/EntityDialog'
import useAthenaClient from 'hooks/useAthenaClient'
import useSaveCount from 'hooks/useSaveCount'
import { makeStyles } from '@material-ui/core/styles'
import AthenaAuthorize from '../../../app/security/ui/AthenaAuthorize'

const useStyles = makeStyles(() => ({
  htmlContainer: {
    width: '98%',
    padding: '2px',
    whiteSpace: 'normal',
  },
}))

export default function KudosSection(props: { person: IApiPerson }) {
  const classes = useStyles()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [person, setPerson] = useState<IApiPerson | null>(null)
  const [saveCount, incr] = useSaveCount()
  const { athenaClient } = useAthenaClient()

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  useEffect(() => {
    if (athenaClient && props.person.id) {
      const personService = new PersonDetailService(athenaClient)
      personService
        .getPerson(props.person.id as number)
        .then((value: IApiPerson) => {
          setPerson(value)
        })
    }
  }, [saveCount, athenaClient])

  async function save() {
    const data = getNamedData(document.querySelector('.MuiDialogContent-root'))
    const personDetailService = new PersonDetailService(
      athenaClient as AthenaClient,
    )
    await personDetailService.updatePerson(person as IApiPerson, data)
    setEditMode(false)
    setPerson(null)
    incr()
  }

  if (person === null) {
    return <DashItemProgress />
  }

  function KudosSectionDialog(props: { person: IApiPerson }) {
    const person = props.person
    return (
      <EntityDialog
        entityName={'people'}
        model={person}
        handleClose={toggleEditMode}
        handleSave={save}
        innerComponent={<InnerKudosSection person={person} />}
      />
    )
  }

  const displaySomeWhiteSpace = `<br/><br/><br/>`

  function InnerKudosSection(props: { person: IApiPerson }) {
    return (
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3 className={'sectionHeading'}>Quotes/Accolades</h3>
          </div>
          <div>
            <div>
              <AthenaAuthorize allowedRoles={'Contributor'}>
                {!editMode ? (
                  <EditIcon onClick={toggleEditMode} />
                ) : (
                  <CloseIcon onClick={toggleEditMode} />
                )}
              </AthenaAuthorize>
            </div>
          </div>
        </div>
        <div>
          {!editMode ? (
            <div
              className={classes.htmlContainer}
              dangerouslySetInnerHTML={{
                __html: props.person?.kudosNotes ?? displaySomeWhiteSpace,
              }}
            />
          ) : (
            <DashRichTextEditor
              name={'kudosNotes'}
              value={props.person?.kudosNotes ?? ''}
            />
          )}
        </div>
      </div>
    )
  }

  return editMode ? (
    <KudosSectionDialog person={person} />
  ) : (
    <InnerKudosSection person={person} />
  )
}
