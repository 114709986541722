import React from "react";
import noop from "utils/noop";
import {IconButton, SvgIconTypeMap} from "@material-ui/core";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";

interface IDashIconButtonProps {
  id?: string | number | null;
  entityName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  MuiIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  onClick: (id: string | number | null | undefined) => void;
}
interface IDashIconButtonExProps {
  id?: string | number | null;
  entityName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  MuiIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}
export default function DashIconButton(props: IDashIconButtonProps) {

  const MuiIcon = props.MuiIcon;

  noop(props);
  return (
      <IconButton onClick={() => props.onClick(props.id)}>
        <MuiIcon />
      </IconButton>
  );
}

export function DashIconButtonEx(props: IDashIconButtonExProps) {

  const MuiIcon = props.MuiIcon;

  noop(props);
  return (
      <IconButton onClick={props.onClick}>
        <MuiIcon />
      </IconButton>
  );
}