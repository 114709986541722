import {useEffect, useState} from 'react';
import AthenaClient, {
  IApiPerson,
  IAthenaFacet,
  ISearchResultSetOfQueryableProject,
  SearchResultSetOfQueryableProject
} from "services/apiClients/AthenaClient";
import MetaClient from "services/apiClients/MetaClient";
import {projectInfotips} from "constants/metadata/infotips";
import useAthenaClient from "../useAthenaClient";
import ValidDistrictChoice from "../../specifications/ValidDistrictChoice";
import {getAllRegionIndexedNames} from "../../services/metadata/regionsEx";

const initOptions: { [key: string]: string[] } = {};
const initOptionsWithImages = {} as { [key: string]: { name: string, image: string, members: string[] }[] };

export interface IReferenceData {
  options: { [key: string]: string[] };
  optionsWithImages: { [key: string]: IOptionWithImage[] };
  //infotips?: DashInfotipDict;
}

export interface IOptionWithImage {
  name: string;
  image: string;
  members: string[];
}

export default function useReferenceDataForProjects() {
  const [options, setOptions] = useState<{ [key: string]: string[] }>(initOptions);
  const [optionsWithImages, setOptionsWithImages] = useState<{ [key: string]: IOptionWithImage[] }>(initOptionsWithImages);
  const infotips = projectInfotips;
  //const [initialized, setInitialized] = useState(false);
  const {athenaClient} = useAthenaClient();

  const metaClient = new MetaClient();
  const newState = {} as { [key: string]: string[] };
  const newStateWithImages = {} as { [key: string]: IOptionWithImage[] };

  useEffect(() => {
    if (options && Object.keys(options).length === 0 && athenaClient) {
      // const {facets}: ISearchResultSetOfQueryableProject =
      athenaClient.getAllProjectsV2(undefined, undefined, undefined, 0, undefined)
          .then((rs: ISearchResultSetOfQueryableProject) => {
            if (rs.facets) {
              const facets = rs.facets;
              newState['secondaryCategories'] = normalizeFacetValues(facets['secondaryCategories']);
              newState['secondaryCategories'] = normalizeFacetValues(facets['secondaryCategory']);
              newState['constructionTypes'] = normalizeFacetValues(facets['constructionType']);
              newState['leedVersions'] = normalizeFacetValues(facets['leedVersion']);
              newState['leedStatuses'] = normalizeFacetValues(facets['leedStatus']);
              newState['leedRatings'] = normalizeFacetValues(facets['leedRating']);
              // not sure it will need these
              newState['districts'] = filterDistrictChoices(normalizeFacetValues(facets['district']));
              //console.log('!@!! DISTRICTS', JSON.stringify(newState['districts']));
              newState['clientTypes'] = normalizeFacetValues(facets['clientType']);
              newState['preconInContracts'] = normalizeFacetValues(facets['preconInContract']);
            }
            newStateWithImages['verticalMarkets'] = metaClient.getVerticalMarkets();
            newState['verticalMarkets'] = metaClient.getVerticalMarketNames();
            newState['regionWithCodes'] = getAllRegionIndexedNames();
            newState['states'] = Object.keys(metaClient.getUSStateDictionary()).sort()
                .map(x => x.toUpperCase());
            newState['sectors'] = metaClient.getSectors();
            newState['contractTypes'] = metaClient.getContractTypes();
            newState['procurementMethods'] = metaClient.getProcurementMethods();
            newState['deliveryMethods'] = metaClient.getDeliveryMethods();
            newState['dataCenterTiers'] = metaClient.getDataCenterTiers();
            newState['referenceTypes'] = ['Owner', 'Owner Representative',
                                          'End-User', 'Designer', 'Subcontrator'];
            newState['insuranceTypes'] = ['CCIP','OCIP','Standard'];
            newState['serviceBusinessUnits'] = [
              'Facility Solutions', 'Building Systems Integration', 'Specialized Construction', 'Facility Management'
            ];
            newState['percentageClassifications'] = ['N/A (no % tracked)', '% of Prime Contract', '% of Subcontracted Dollars'];
            //console.log('~~~ useFieldOptionsForProjects getProjectDropDownLists options', newState);
            setOptions(newState);
            setOptionsWithImages(newStateWithImages);
          });

    }
  }, [athenaClient]);

  useEffect(() => {

    console.log('~~~ useEffect', options);
  }, [options]);

  //console.log(newState);
  console.log('options', options);
  return {options, optionsWithImages, infotips} as IReferenceData;
}


function normalizeFacetValues(facets: IAthenaFacet[]) {
  if (!facets) {
    return [];
  }
  const values = facets.map(x => x.value).filter(x => typeof x === 'string') as string[];
  values.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
  return values;
}

export function filterDistrictChoices(input: string[]): string[] {
  const validDistrictChoice = new ValidDistrictChoice();
  const filtered = input.filter(str => validDistrictChoice.isSatisfiedBy(str));

  return filtered;
}
