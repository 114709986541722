import 'reflect-metadata';
import { Grid, GridProps} from "@material-ui/core";
import {IProjectChallengeSolution, ProjectChallengeSolution} from "services/apiClients/AthenaClient";
import {GreenCheck, RedX} from "components/dashCore/customIcons";
import dateFormat from "patterns/dateFormat";
import {DataRow} from "../DashList2";
import InfotipButton from "../../../InfotipButton/InfotipButton";

type ChallengeAndSolutionBlockProps = {
  model: DataRow<ProjectChallengeSolution>;
};

const ChallengeAndSolutionBlock: React.FC<ChallengeAndSolutionBlockProps> = (props) => {
  const dataRow = props.model; // DataRow of ProjectChallengeSolution;

 // console.log('ChallengeAndSolutionBlock dataRow', dataRow);

  return (
      <>
        <span></span>
        <Grid container spacing={2} style={{width:'100%',padding:'0 10px'}}>
          <Grid item xs={12}>
            <b>Challenge:</b>&nbsp;{dataRow.value.challenge}
            <InfotipButton infotipKey={'challenge'} />
          </Grid>
          <Grid item xs={12}>
            <b>Solution:</b>&nbsp;{dataRow.value.solution}
            <InfotipButton infotipKey={'solution'} />
          </Grid>
          <Grid item xs={12}>
            <b>Approved:</b>&nbsp;{ dataRow.value.approval?.isApproved
              ? <GreenCheck />: <RedX />
          }
          </Grid>
          <Grid item xs={12}>
            <b>Challenge & Solution Edited by:</b>&nbsp;{dataRow.value.modifiedBy}
          </Grid>
          <Grid item xs={12}>
            <b>Challenge & Solution Edited on:</b>&nbsp;{dateFormat(dataRow.value.modifiedOn)}
          </Grid>
          { dataRow.value.approval?.isApproved
            && <Grid item xs={12}>
                  <b>Approved By:</b>&nbsp;{ dataRow.value.approval?.modifiedBy} on {dateFormat(dataRow.value.approval?.modifiedOn)}
              </Grid>
          }
        </Grid>
      </>
  );
}

export default ChallengeAndSolutionBlock;