import React, {useEffect} from 'react';
import {msalConfig} from "../authConfig";

const ClearPage = () => {

    useEffect(() => {

        // const html = document.getElementsByName('HTML')[0] as HTMLElement;
        // html.innerHTML = '';

        // Clear localStorage
        localStorage.clear();

        // Clear sessionStorage
        sessionStorage.clear();

        // Clear cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });



    }, []);

    const homeURL = 'https://hpcc2013.sharepoint.com/sites/CORE';

    return (
        <div style={{padding:'30px'}}>
            <div className="flex flex-row justify-evenly">
                <a href={homeURL}>Continue to to the Core page</a>
            </div>
            <div className="flex flex-row justify-evenly">
                <a href="/">Return to the DASH application</a>
            </div>
        </div>

    );
};

export default ClearPage;
