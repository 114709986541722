import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {
  ApiPersonHistory,
  ApiProject,
  ApiProjectStakeholder,
  ProjectPersonHistory
} from "services/apiClients/AthenaClient";
import ProjectStaffContext from "./ProjectStaffContext";
import useAthenaClient from "hooks/useAthenaClient";
import PickerDialogEx from "../../shared/PickerDialogEx";
import {ColumnDirective, GridComponent} from "@syncfusion/ej2-react-grids";
import DashIconButton from "../../../dashCore/DashIconButton";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import noop from "../../../../utils/noop";
import {Button, TextField} from "@material-ui/core";
import {GreenCheck} from "../../../dashCore/customIcons";
import DashToaster from "patterns/DashToaster";
import useToast from "hooks/useToast";

// interface IProjectStaffProviderProps {
//   model: ApiProject;
// }

export const ProjectStaffProvider: React.FC<{ model?: ApiProject }> =
    ({ model, children }) => {
      const [project, setProject] = useState<ApiProject | undefined>(model);
      const [dialogMode, setDialogMode] = useState<string | null>();
      const dialogGridRef = useRef<GridComponent | null>(null);
      // maintain state from the revealed "titleOnProject" field
      const titleOnProjectRef = useRef('');
      const {athenaClientRef} = useAthenaClient();
      const toaster = useToast();
      const dashToaster = new DashToaster(toaster.displayToast);

  useEffect(() => {
    setProject(model);
  }, [model])

  function reloadProject(projectId: string): Promise<ApiProject> | undefined {
    return (
        athenaClientRef.current
            ?.getProject(projectId)
            .then((apiProject) => {
              setProject(apiProject);
              return apiProject;
            })
    );
  }

  function handlePreAdd(record: Record<string, unknown>, gridRef: React.MutableRefObject<GridComponent | null>) {
      const gc = gridRef.current as GridComponent;
      titleOnProjectRef.current = record.title as string;
      gc.showColumns('Title on Project');
      console.log('!@@@! select row issue', {selectedRowIndex:gc.selectedRowIndex,recordIndex:record.index});
      if (gc.selectedRowIndex.toString() !== record.index) {
        setTimeout(() => {
          gc.selectRow(parseInt(record.index as string), false);
          gc.startEdit();
        }, 180);
        setTimeout(() => {
          const elem = document.querySelector('.e-inline-edit input.MuiInput-input') as HTMLElement;
          elem.style.backgroundColor = 'rgba(100, 149, 237, 0.2)';
          //elem.style.color = 'white';
          //elem.style.borderBottom = '2px solid #111';
          elem.focus();
        }, 300)
      }
    }


  const renderAddToProject = (data: Record<string, unknown>, gridRef: React.MutableRefObject<GridComponent | null>, existingIds: number[]) => {
    if (existingIds.includes(data.id as number)) {
      return <div key={Math.random().toString()}><GreenCheck /></div>
    }
    return (<div key={Math.random().toString()}>
      <DashIconButton size="small" onClick={() => handlePreAdd(data, gridRef)}>
        <AddCircleOutline/>
      </DashIconButton>
    </div>);
  }

  const handleAction = (action: string, payload?: Record<string, unknown>) => {
    let values: Record<string, string | number> = {};
    if (['create','update','delete'].includes(action)) {
      const personId = action === 'create' ? payload?.id as string: payload?.personId as string;
      values = {
        personId: personId,
        projectId: model?.id as string,
        personTitleOnProject: payload?.personTitleOnProject as string,
      }
    }
    switch (action) {
      case 'close':
        setDialogMode(null);
        break;
      case 'create':
          // console.log('!@@@! handleAction in ProjectStaffProvider payload', payload);
          // console.log('!@@@! handleAction in ProjectStaffProvider titleOnProjectRef.current', titleOnProjectRef.current);
          athenaClientRef.current?.addPersonToProject(values.projectId as string, values as unknown as ProjectPersonHistory)
              .then(() => {
                dashToaster.displayLinkedToast(payload?.name as string, project?.name as string);
                setDialogMode(null);
                reloadProject(project?.id as string);
              });
        break;
      case 'update':
        athenaClientRef.current?.updatePersonProjectHistory(values.projectId as string,
                                          values.personId as number, values as unknown as ProjectPersonHistory)
            .then(() => {
              dashToaster.display(`The title for ${payload?.name} has been updated`);
              setDialogMode(null);
              reloadProject(project?.id as string);
            });
        setDialogMode(null);
        break;
      case 'delete':
        console.log(payload);
        if (confirm(`Are you sure you want unlink ${payload?.name} from this record?`)) {
          athenaClientRef.current?.deletePersonProjectHistory(values.projectId as string, values.personId as number)
              .then(() => {
                dashToaster.displayUnlinkedToast(payload?.name as string);
                setDialogMode(null);
                reloadProject(project?.id as string);
              });
        }
        break;
    }
  }

  const staffPickerChooseColumns = [
        <ColumnDirective headerText="Name" field="name" allowEditing={false} />,
        <ColumnDirective headerText="Region" field="district" allowEditing={false} width={'15%'} />,
        <ColumnDirective headerText="Current Title" field="title" allowEditing={false} />,
        <ColumnDirective headerText="Title on Project" visible={false}
          template={(data: Record<string, unknown>) => (<span>{data.title}</span>)}
          editTemplate={() => (
              <TextField InputProps={{ disableUnderline: true, style: {fontSize:'13px'}}}
                  onChange={(e) => titleOnProjectRef.current = e.target.value}
                         defaultValue={titleOnProjectRef.current} fullWidth />
            )}
        />,
    <ColumnDirective headerText="Add to Project" template={
      (data: Record<string, unknown>) => renderAddToProject(data, dialogGridRef, project?.personHistory?.map(x => x.personId as number) ?? [])
    } editTemplate={(data: Record<string, unknown>) => (
        <><Button onClick={() => handleAction('create',
                {...data, personTitleOnProject:titleOnProjectRef.current})}
          variant="outlined">Save</Button>&nbsp;
          <Button onClick={() => dialogGridRef?.current?.endEdit()} variant="outlined">Cancel</Button></>
      )}/>];

      function handleGridRef(ref: MutableRefObject<GridComponent> | null) {
        dialogGridRef.current = ref?.current ?? null;
      }

      //console.log('inside ProjectStaffProvider: ', {dialogMode});

      return (
        <ProjectStaffContext.Provider value={{ project, setDialogMode, handleAction }}>
          {children}
          { dialogMode !== undefined && dialogMode !== null &&
          <PickerDialogEx mode={dialogMode}
                          onGridInstance={handleGridRef}
                          title={'Add Staff to ' + project?.name}
                          searchBoxLabel={'Search for person...'}
                          entityName={'people'}
                          onAction={handleAction}
                          columnDirectives={staffPickerChooseColumns} /> }
        </ProjectStaffContext.Provider>
  );
};
