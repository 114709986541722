import * as React from 'react';
import { createContext, useContext } from 'react';
import {ApiPersonHistory, ApiProject, ProjectPersonHistory} from "services/apiClients/AthenaClient";

type ProjectStaffContextType = {
  project?: ApiProject | null;
  reloadProject: (projectId: string) => Promise<ApiProject> | undefined;
  addProjectStaff: (personHistory: ProjectPersonHistory) => Promise<ProjectPersonHistory> | undefined;
  setDialogMode: (mode?: string) => void;
  handleAction: (action: string, payload: Record<string, unknown>) => void;
};

const ProjectStaffContext = createContext<ProjectStaffContextType | null>(null);

export function useProjectStaffContext() {
  const context = useContext(ProjectStaffContext);
  if (!context) {
    throw new Error('ProjectStaffContext: context is null or undefined');
  }
  return context;
}

export default ProjectStaffContext;
