import {DashInfotipDict, IDashInfotip} from "./tooltipTypes";

export const formatInfotip = (tip: IDashInfotip) => {
  const {title, description, example} = tip;
  let content = `<h2 class="infotip-modal">${title}</h2>\n${description}\n`;
  content += ((example) ? `<h3>Example</h3>${example}` : '');
  return content;
}

export const projectInfotips: DashInfotipDict = {
        streetAddress : {
            title : 'Street Address',
            description : 'Street Address is the full street address for the project. Do not include any ' +
                'abbreviations. For example: 420 Sixth Avenue, NOT <del>420 6th Ave</del>. This field is required.',
            example : ''
        },
        city : {
            title : 'City',
            description : 'City is required. Spell out the full name of the City',
            example : 'Los Angeles not LA'
        },
        state : {
            title : 'State',
            description : 'State must be a valid two-letter US state or territory postal code, unless ' +
                'domestic is turned off.',
            example : ''
        },
        zip : {
            title : 'Zip',
            description : 'Zip must be 5 numeric digits, a valid US zip code. If domestic is turned off, ' +
                'it will not have any validation.',
            example : ''
        },
        country : {
            title : 'Country',
            description : 'Country is automatically set to USA, unless domestic is turned off. ' +
                'This field is required.',
            example : ''
        },
        domestic : {
            title : 'Domestic',
            description : 'Domestic sets the country to USA, and ensures that state and zip conform to ' +
                'US standards. If domestic is turned off, then no validation is performed on country, state, or zip.',
            example : ''
        },
        clientName : {
            title : 'Client Name',
            description : 'Client Name is synced from CRM. To change which Account is the client, go to the ' +
                'Stakeholder section, add the client (or make sure the CRM Account is already a stakeholder), and ' +
                'set its role to Owner using the Owner toggle. To change the name, it must be changed in CRM. If the ' +
                'client is <em>not</em> synced with CRM, this is legacy data from SMART and cannot be changed.',
            example : ''
        },
        clientLocation : {
            title : 'Client Location',
            description : 'Client Location is synced from CRM. To change which Account is the client, go to ' +
                'the Stakeholder section, add the client (or make sure the CRM Account is already a stakeholder), ' +
                'and set its role to Owner using the Owner toggle. To change the city, state, or country, it must be ' +
                'changed in CRM. If the client is <em>not</em> synced with CRM, this is legacy data from SMART and ' +
                'cannot be changed.',
            example : ''
        },
        clientContractProjectNumber : {
            title : 'Client Contract Number',
            description : 'Client Contract Number is the <em>client\'s</em> contract number for this project. ' +
                'In some cases this will be helpful and/or required on proposals when listing past work for ' +
                'that client. Every client will have a different numbering system and not all clients will provide ' +
                'this information, so this field is optional and has no format requirements.',
            example : ''
        },
        clientInfoSyncedWithCrm : {
            title : 'Synced With CRM',
            description : 'Synced With CRM indicates whether this client\'s information is synced with CRM or ' +
                'is legacy data from SMART. To sync the client with CRM, go to the Stakeholder section, add the ' +
                'client (or make sure the CRM Account is already a stakeholder), and set its role to Owner using the ' +
                'Owner toggle. If the client is not synced with CRM, then Client Name and Client Location are legacy ' +
                'data from SMART and cannot be changed.',
            example : ''
        },
        clientVerticalMarkets : {
            title : 'Client Vertical Markets',
            description : 'Client vertical markets are synced from CRM. To change which Account is the client, go ' +
                'to the Stakeholder section, add the client (or make sure the CRM Account is already a stakeholder), ' +
                'and set its role to Owner using the Owner toggle. The list of applicable vertical markets can only ' +
                'be changed in CRM.',
            example : ''
        },
        number : {
            title : 'Project Number',
            description : 'Project Number is the Hensel Phelps 7-digit job number given by the Finance department.',
            example : ''
        },
        projectNumbers : {
            title : 'Project Number',
            description : 'Project Number is the Hensel Phelps 7-digit job number given by the Finance department. ' +
                'In some cases, there may be more than one project number used as part of a single project. Some ' +
                'examples include projects with partnership between districts, IDIQ-style jobs with multiple project ' +
                'numbers, or certain accounting methods to track e.g. distinct sources of money. <strong>Only</strong> ' +
                'add more than one project number if this project page holds <strong>all</strong> information for ' +
                '<strong>all</strong> of the included project numbers. If there are multiple project pages to record all of ' +
                'the data, <strong>link the projects as part of a Program</strong>. All project numbers entered here must ' +
                'be 7 numeric digits.',
            example : ''
        },
        name : {
            title : 'Project Name',
            description : 'Project Name is the full name of the project, exactly as it appears on the ' +
                'prime contract. Put any common acronyms for the project under the project description. ' +
                'For Services projects, include the full project name as it appears on the New Project Number ' +
                'Report, and begin project name with abbreviation of Business Unit: FS –, BSI –, SC –, or FM –.',
            example : ''
        },
        squareFootage : {
            title : 'Square Footage',
            description : 'Square Footage means the total <em>building</em> square footage affected by ' +
                'this project -- new construction, renovation, expansion, etc.',
            example : ''
        },
        verticalMarket : {
            title : 'Vertical Market',
            description : '<p>Select the option that best describes the project\'s Vertical Market ' +
                'scope. If a project has more than one Vertical Market included in the scope of work, the ' +
                'Vertical Market with the largest component (based on square footage and/or dollar value) ' +
                'should be selected.</p>' +

                '<p><strong>Industrial</strong> type projects include warehouse, Steel & Non-ferrous metals, Chemicals ' +
                '(non-petroleum), Food and Beverage processing, Pulp & Paper plants.</p>' +
                '<p><strong>Manufacturing</strong> type projects include Pharmaceuticals, Auto Assembly & Parts Fabrication, ' +
                'Semiconductor Fabrication, Electronic assembly plants and Aerospace.</p>' +
                '<p><strong>Energy/Power</strong> type projects include Fossil Fuel plants, natural gas plants, Coal plants, ' +
                'Nuclear plants, Hydro plants, Cogeneration and waste fuels, Transmission & distribution, Wind ' +
                'generation and Solar generation.</p>' +

                '<p><strong>Mixed Use</strong> should only be used for private projects that contain multiple vertical markets.</p>',
            example : ''
        },
        clientType : {
            title : 'Sector',
            description : 'Sector refers to whether the <em>owner</em> is a private or public entity. ' +
                'If tax dollars are being used, the sector will be a public subtype. If public, choose ' +
                'the appropriate subtype -- federal, state, or local.',
            example : 'Federal: General Services Administration<br>' +
                'State: Colorado Department of Transportation<br>' +
                'Local: Maricopa County<br>' +
                'Private: Lockheed Martin'
        },
        region : {
            title : 'Region',
            description : 'Region indicates the HP region which performed the project. If the project was a ' +
                'partnership between multiple districts, indicate which was the <em>lead district</em> by putting ' +
                'that district first. This will influence search results, as well as where assets are stored in ' +
                'WebDAM. In the future, lead and associated districts may be separated into distinct fields.',
            example : ''
        },
        contractAwardDate : {
            title : 'Contract Award Date',
            description : 'The date the contract was awarded.',
            example : ''
        },
        startDate : {
            title : 'Start Date',
            description : 'Start Date refers to the start of construction on the project. It is calculated from ' +
                '(in order) Construction Actual Start Date and Construction Scheduled Start Date, to account for ' +
                'projects which have not yet started. In order to change this value, you must edit one of those fields.',
            example : ''
        },
        completionDate : {
            title : 'Completion Date',
            description : 'Completion Date refers to the date of substantial completion. It is calculated from (in order) ' +
                'Actual Substantial Completion Date, Modified Substantial Completion Date, and Scheduled Substantial ' +
                'Completion Date. This accounts for projects that are not yet substanially complete. In order to change ' +
                'this value, you must edit one of those fields.',
            example : ''
        },
        contractType : {
            title : 'Contract Type',
            description : 'The type of the <em>prime</em> contract.',
            example : ''
        },
        originalConstructionContractValue : {
            title : 'Original Contract Value',
            description : 'The value of the original contract. Do not include updates or changes to the contract value. ' +
                'Those are accounted for under Final Contract Value.',
            example : ''
        },
        cost : {
            title : 'Final Contract Value',
            description : 'Final Contract Value is the final value of the project, after accounting for all changes ' +
                'or updates. Before the project is complete, input the current contract value. This value is used when ' +
                'calculating Total Value on the Search Results page and when calculating a person\'s Total Project Value.',
            example : ''
        },
        deliveryMethod : {
            title: 'Delivery Method',
            description: '' +
                '<ul>' +
                '<li>' +
                '<strong>Design Build</strong> - the same team who designs the project constructs it as well. It is where ' +
                'the design and construc­tion are considered “single-point responsibility,” reduc­ing costs and delivering ' +
                'the project on time. ' +
                '</li>' +
                '<li>' +
                '<strong>Design Bid Build</strong> - also known as Com­petitive Sealed Price (CSP) the most traditional ' +
                'process in the construction industry, where the owner contracts separately with a designer and a ' +
                'contractor. The owner has to seek out each company separately. The architect and general contractor have ' +
                'no connection until the plans are placed up for bids. ' +
                '</li>' +
                '<li>' +
                '<strong>CM at Risk</strong> - "construction manager at risk." This is where the Construction Manager ' +
                'commits to delivering the project within a Guaranteed Maximum Price (GMP), which is based on the ' +
                'construction documents and specifications at the time of the GMP, plus any reason­ably inferred items or tasks. ' +
                '</li>' +
                '<li>' +
                '<strong>Cost Plus</strong> - a delivery method where an owner agrees to pay the cost of the work, ' +
                'including all trade subcontractor work, labor, materials, and equipment, plus an amount for contractor\'s ' +
                'overhead and profit. ' +
                '</li>' +
                '<li>' +
                '<strong>T&M</strong> - Time and materials (T&M) contracts involve both parties agreeing on predetermined ' +
                'unit rates for labor and materials with a fixed markup on costs. ' +
                '</li>' +
                '<li>' +
                '<strong>Integrated Project Delivery</strong> - a project delivery methodology that fully integrates ' +
                'project teams to maximize project outcomes and minimize time waste. ' +
                '</li>' +
                '<li>' +
                '<strong>PPP</strong> - a public-private partnership where some of the risks of executing a project are ' +
                'transferred to the private sector. It\'s an arrangement between two or more public and private sectors, ' +
                'typically of a long-term nature. ' +
                '</li>' +
                '<li>' +
                '<strong>Agency CM</strong> - a fee-based service in which the construction manager is responsible ' +
                'exclusively to the owner and acts in the owner\'s interests throughout each stage of the project. An agency ' +
                'CM does not contract with subcontractors. ' +
                '</li>' +
                '<li>' +
                '<strong>Facility Management</strong> - A contract for the post-construction management of a facility. ' +
                'Services could include preventative maintenance, custodial services, Operational Project Readiness, ' +
                'facility condition assessments, facility operations manuals and more. (Applicable to Services Group projects) ' +
                '</li>' +
                '</ul>',
            example: ''
        },
        nonHenselPhelpsProject : {
            title : 'Non-Hensel Phelps Project',
            description : 'Non-Hensel Phelps Project, if true, indicates that this project exists <em>only for industry ' +
                'resumes</em>. This field cannot be edited. The only way to turn this on is to create a new industry ' +
                'project from a person\'s resume. The industry project will be excluded from all statistics and search ' +
                'results. The only way to find it will be to visit that person\'s page, and click on the project from ' +
                'their experience section.',
            example : ''
        },
        usable : {
            title : 'Usable',
            description : 'Usable answers the question: Can this project be discussed publically and in pursuits? Some ' +
                'reasons why a project <em>may not</em> be usable: Security classification, litigation, bad reference, ' +
                'bad CPARS, NDA, confidentiality clauses, etc. If the project is usable but has limited public information ' +
                'release, describe the requirements for the limited public release at the beginning of the project ' +
                'description.',
            example : ''
        },
        autoUpdateContractValue : {
            title: 'Auto Update Contract Value',
            description: '',
            example: 'When Auto Update Contract Value is turned on the Original and Final Contract values will be ' +
                'automatically updated through Vista on the next daily sync. If you turn this off, you will need to manually ' +
                'update the contract values.'
        },
        jointVenture : {
            title : 'Joint Venture',
            description : 'Joint Venture should be true if and only if we are party to a joint venture which ' +
                'holds the <em>prime contract</em>. Some examples where this would be false: we are subcontracted ' +
                'to a joint venture, certain subcontractors or design firms are joint ventured, etc.',
            example : ''
        },
        programSubsection: {
            title: 'Program Information',
            description: 'Use this section for multiple projects each deserving its own write-up, or a single project ' +
                'with many small jobs.',
            example: '<p>If this project is part of a larger program, capture that information here. Most projects are ' +
                'standalone projects, and do not need any program information. There are two main types of projects that ' +
                'need program information:</p>' +
                '<ol>' +
                '<li>Multiple projects awarded as part of a client\'s large program. An example is the Kaiser San Diego ' +
                'campus projects. Each is a project in its own right, with a dedicated schedule, cost, staff, ' +
                'stakeholders, construction types, and perhaps vertical market. However, there is important information ' +
                'about the whole group of projects (the program) that should be captured here. In these cases, you will ' +
                'need to create a page for each project and associate it to this program.</li>' +
                '<li>Multiple small task orders conducted under a contract (e.g. IDIQ). In these cases, each project is ' +
                'very small and does not need a fully filled-out page in Dash. Use this section to capture the overall ' +
                'program information (name, description) and briefly describe each task order\'s relationship to the ' +
                'program. You will not need to associate other projects to the program in this case, because all the ' +
                'information is contained on a single project\'s page.</li>' +
                '</ol>' +
                '<p>To add a project to an existing program, follow these steps:</p>' +
                '<ol>' +
                '<li>Create the new project you wish to add to the program.</li>' +
                '<li>Go to a project\'s page that is already part of the program.</li>' +
                '<li>Search for and add the new project from the Program Information section on the older project page.</li>' +
                '</ol>' +
                '<p>At this time the only way to add a project to an existing program is from a project that is already ' +
                'part of that program. You cannot search for programs directly.</p>'
        },
        programName: {
            title: 'Program Name',
            description: 'Name of the program. This is always the same for all projects that are part of this program. ' +
                'In other words, changes here will appear on all associated projects, and vice versa.',
            example: ''
        },
        programDescription: {
            title: 'Program Description',
            description: '<p>Give a brief description of the overall program. If there is more than one project record ' +
                'associated with the program, explain the relationships between the projects as well as each project\'s ' +
                'relationship to the overall program.</p>' +
                '<p>This is always the same for all projects that are part of this program. In other words, changes here will ' +
                'appear on all associated projects, and vice versa.</p>',
            example: ''
        },
        programValue: {
            title: 'Program Value',
            description: 'Automatically calculated by adding up Final Contract Value for all projects associated with this ' +
                'program. Be aware that Services projects associated with this program will affect this total. If you require ' +
                'construction values only, subtract Services jobs from the total.',
            example: ''
        },
        programSquareFootage: {
            title: 'Program Square Footage',
            description: 'Automatically calculated by adding up Square Footage for all projects associated with this program. ' +
                'Be aware that Services projects associated with this program will affect this total. If you require construction ' +
                'values only, subtract Services jobs from the total.',
            example: ''
        },
        programAssociateProject: {
            title: 'Associate a Project',
            description: 'Search for and add projects to this program. All projects that are part of the same program will ' +
                'share the Program Name, Program Description, Program Value, and Program Square Footage. Any changes to ' +
                'these fields will appear on all projects that are part of that program. Associated projects will appear ' +
                'below, with a link to their record.',
            example: ''
        },
        programSearchForProject: {
            title: 'Search for a Project',
            description: 'Use the search bar to search for projects to add to this program. This is the same search ' +
                'function as the free text search on the main Search page. Add projects to the program by clicking the "+" ' +
                'next to them. Any project that is already part of this program will have a check mark next to it.',
            example: ''
        },
        metrics : {
            title : 'Metrics',
            description : 'Please complete the metrics applicable to the project. If the metric is not applicable, leave the field blank.',
            example : ''
        },
        overallSiteAcreage : {
            title : 'Overall Site Acreage',
            description : 'When relevant, how many total acres is the jobsite? Tenant Improvements ' +
                'are one example where this may not apply. Leave blank if not applicable.',
            example : ''
        },
        centralUtilityPlantSquareFootage : {
            title : 'Central Utility Plant Sq Ft',
            description : 'If the project contains a Central Utility Plant, how big is it in square feet? Leave ' +
                'blank if not applicable.',
            example : ''
        },
        numBeds : {
            title : 'Number of Beds',
            description : 'This field will be determined by vertical market type. If it is a healthcare project, then ' +
                'the number applied here will be hospital beds. If higher education is the vertical market, then this field ' +
                'will be student housing beds and so on. Therefore, running reports on this field must be done with the correct ' +
                'vertical markets selected. This field <em>is not</em> generally applicable to hotel projects. If this project ' +
                'is a hotel, please reference the "Number of Hotel Keys" field.',
            example : 'Healthcare example: If the project is an expansion or renovation of part of a hospital, how many total ' +
                'beds were affected -- e.g. if 20 are renovated and 20 added, this field should be 40. Apply this same guidance ' +
                'for the vertical market type of your project.'
        },
        numFloors : {
            title : 'Number of Floors Above Grade',
            description : 'How many affected floors are there above grade? Should be 0 if the project ' +
                'did not affect any floors above grade. Ex: a renovation of the bottom 3 floors of ' +
                'a 10-story building should have 3 in this field.',
            example : ''
        },
        numFloorsBelowGrade : {
            title : 'Number of Floors Below Grade',
            description : 'How many affected floors are there below grade? Should be 0 if the project ' +
                'did not affect any floors below grade. Ex: expanding and renovating an airport terminal ' +
                'with 2 sub-basements should have 2, if the project affected both sub-basements.',
            example : ''
        },
        parkingGarageSquareFootage : {
            title : 'Parking Garage Sq Ft',
            description : 'What is the size (in square feet) of any parking garage affected ' +
                'by this project? Round to the nearest thousand. Leave blank if not applicable.',
            example : ''
        },
        numParkingSpaces : {
            title : 'Number of Structured Parking Spaces',
            description : 'How many parking spaces in a parking structure (as opposed to directly on grade) ' +
                'are affected by this project? Include the first floor. Leave blank if not applicable.',
            example : ''
        },
        numOnGradeParkingSpaces : {
            title : 'Number of On Grade Parking Spaces',
            description : 'How many parking spaces that are directly on grade are affected by this project? ' +
                'Ignore the first floor of any parking structure. Leave blank if not applicable.',
            example : ''
        },
        numKeysHotel : {
            title : 'Number of Hotel Keys',
            description : 'If this project is or involves a hotel, how many keys were affected?',
            example : '<ul>' +
                '<li>If the project is (or includes) a new hotel, how big is the whole hotel (in terms of keys)?</li>' +
                '<li>If the project is an expansion or renovation of part of a hotel, how many total keys are ' +
                'affected – e.g. if 10 are renovated and 50 added, this field should be 60.</li>' +
                '</ul>'
        },
        dataCenterTierLevel : {
            title : 'Tier Level of Data Center',
            description : 'If the project is, contains, or affects a data center, what tier is the data center? ' +
                'Leave blank if not applicable.',
            example : ''
        },
        totalBuildMegawatts : {
            title : 'Total Build Megawatts',
            description : 'How large is this data center, in terms of megawatts? For Data Center projects only. ' +
                'If this is not applicable, leave blank or set to 0.',
            example : ''
        },
        scifSquareFootage : {
            title : 'SCIF Square Footage',
            description : 'If the project contains or affects a Secure Compartmentalized Information Facility (SCIF), ' +
                'how big is the SCIF area affected by the project, in square feet? Leave blank if not applicable.',
            example : ''
        },
        secondaryCategory : {
            title : 'Secondary Categories',
            description : '<p>Secondary categories are subsidiary to, or in addition to, the vertical market of the ' +
                'project. Select all applicable categories. For each category added, you must note and expand upon ' +
                'it in one of the narrative fields located in the Project Descriptions section.</p>' +

                '<p>If you cannot find a category type you are looking for, please submit a support ticket.</p>',
            example : '<p><strong>Clean Room</strong></p>' +

                '<p>The following text would be added to the Design Aspects Description field: This project consisted ' +
                'of four BSL-2 spaces and five BSL-3 spaces for a total of 32,000 square feet. Hensel Phelps partnered ' +
                'with Clean Room Specialists Inc. to ensure the clean room was installed to certification standards.</p>'
        },
        constructionType : {
            title : 'Construction Types',
            description : '<p>The types of construction involved on this project. Select all that apply. For each ' +
                'construction type added, you must note and expand upon it in one of the narrative fields located ' +
                'in the Project Descriptions section.</p>' +

                '<p>If you cannot find a construction type you are looking for, please submit a support ticket.<p>',
            example : '<p><strong>Concrete Frame</strong></p>' +

                '<p>The following text would be added to the Design Aspects Description field: The Concrete frame consisted ' +
                'of column spacing 24\' x 30\' and was 10 stories. Each Floor was 12 feet. The columns ranged in size from ' +
                '10 feet x 10 feet to 12 feet x 12 feet.</p>'
        },
        smallBusinessStory : {
            title : 'Small Business Story',
            description : 'Describe any small business utilization stories that might be any specific stories about ' +
                'helping subcontractors grow their business etc. while on the project. Describe any small business ' +
                'outreach events, programs established for the project, etc. Did we help provide trainings, bonding ' +
                'program, assist with gaining payment performance bonds? Describe any work force development efforts ' +
                'that were implemented on the project.',
            example : '<strong>GSA FOB </strong>One of our most significant contributions to the community was our ' +
                'support to the small business community in the South Florida area. We exceeded all of our subcontracting ' +
                'goals, directing approximately 38% of the work to the local and small business community, resulting in $48,455,014 ' +
                'million worth of work for small businesses. Of that percentage approximately 13% ($16,344,582) was contracted ' +
                'through Small Disadvantage Businesses (SDB), approximately 11% (13,764,057) was contracted through Women-Owned ' +
                'Small Businesses (WOSB), approximately 7% ($8,234,886) was contracted to HUBZONE businesses, and approximately ' +
                '7% ($8,673,960) was contracted to Veteran Owned Small Businesses.<strong> Allure Apollo </strong>Be Clean Cleaning ' +
                'Services is a small, woman-owned business in the D.C. metro area. Before hiring on to the Allure Apollo project ' +
                'with Hensel Phelps, Be Clean was not bonded. Hensel Phelps assisted Be Clean by providing them with two separate ' +
                'contracts which allowed them to attain bonding. After becoming a subcontractor of Hensel Phelps, Be Clean Cleaning ' +
                'services grew from 5 employees, to 18 employees. New employees were direct hires and not outsourced by a temp agency. ' +
                'Hensel Phelps also provided accounting training to Be Clean\'s owner educating her on proper invoicing for commercial ' +
                'contracts, overhead, changes and markups, and more. This training allowed the owner to make her business more viable ' +
                'and scalable.'
        },
        supplierDiversity : {
            title : 'Supplier Diversity',
            description : 'For the following categories of supplier diversity, use the "Goal %" field to indicate any project ' +
                'requirements in terms of contract percentage. Use the "Achieved %" field to indicate the actual percentage achieved.',
            example : ''
        },
        safetyNarrative : {
            title : 'Safety Narrative',
            description : 'Describe any innovative programs or methods related to safety: Describe your ' +
                'use of weekly toolbox meetings. Describe specialized safety training. Describe safety ' +
                'incentives. Explain how the subcontracting team is involved in the project safety culture.',
            example : ''
        },
        references : {
            title : 'References',
            description : 'Link any individuals who will be good future references for this project. All references ' +
                'should be synced with and have most of their data managed as Contacts in CRM. Be sure to contact someone ' +
                'who is familiar with the reference prior to listing them on a proposal.',
            example : ''
        },
        stakeholders : {
            title : 'Stakeholders',
            description : 'Link all applicable companies on this project that fall under Primary Focus in the linked PDF. ' +
                'If the company you are looking for does not exist, it needs to be created as an Account in CRM. You can add any ' +
                'companies that fall under Secondary Focus at your discretion. All stakeholders need to be synced with and have ' +
                'their data managed as Accounts in CRM. This will allow you to update the information in one place (CRM) and have ' +
                'it appear on all projects in Dash which it is linked to.',
            example : ''
        },
        designPreconScheduledStartDate : {
            title : 'Scheduled Start Date (Design/PreCon)',
            description : 'Date that the design or preconstruction was scheduled to begin, if applicable.',
            example : ''
        },
        designPreconActualStartDate : {
            title : 'Actual Start Date (Design/PreCon)',
            description : 'Date that the design or preconstruction actually began, if applicable.',
            example : ''
        },
        designPreconScheduledCompletionDate : {
            title : 'Scheduled Completion Date (Design/PreCon)',
            description : 'Date that the design or preconstruction was scheduled to be completed, if applicable.',
            example : ''
        },
        designPreconActualCompletionDate : {
            title : 'Actual Completion Date (Design/PreCon)',
            description : 'Date that the design or preconstruction was actually completed, if applicable.',
            example : ''
        },
        constructionScheduledStartDate : {
            title : 'Scheduled Start Date (Construction)',
            description : 'Date that construction was scheduled to begin. If Actual Start Date is blank, ' +
                'this will be the project\'s "Start Date"',
            example : ''
        },
        constructionActualStartDate : {
            title : 'Actual Start Date (Construction)',
            description : 'Date that construction actually began. If this field has a value, it will be the project\'s "Start Date".',
            example : ''
        },
        constructionScheduledSubstantialCompletionDate : {
            title : 'Scheduled Substantial Completion Date',
            description : 'Date that construction was initially scheduled to be substantially complete. If ' +
                'Modified Scheduled Substantial Completion Date and Actual Substantial Completion Date ' +
                'are blank, this will be the project\'s "Completion Date".',
            example : ''
        },
        constructionModifiedSubstantialCompletionDate : {
            title : 'Modified Scheduled Substantial Completion Date',
            description : 'Date that construction was scheduled to be substantially complete, after any ' +
                'modifications to the schedule. If there was more than one modification, use the most recent ' +
                'modified date. Leave blank if the schedule was not modified. If this field has a value and ' +
                'Actual Substantial Completion Date is blank, this will be the project\'s "Completion Date".',
            example : ''
        },
        constructionActualSubstantialCompletionDate : {
            title : 'Actual Substantial Completion Date',
            description : 'Actual date that construction achieved substantial completion. ' +
                'If this field has a value, it will be the project\'s "Completion Date".',
            example : ''
        },
        scheduleDifferenceReasons : {
            title : 'Reasons for Schedule Delay or Enhancement',
            description : 'If schedule was shorter than originally contracted or extended beyond original ' +
                'contract, reasoning for should be here. Provide detailed reasoning for changes to original ' +
                'contract schedule. Owner directed change orders is NOT a sufficient answer. Provide a context ' +
                'to each of the major changes and why it impacted the critical path to the schedule. If ' +
                'schedule was on time, indicate that as such.',
            example : 'Weather delays, resequencing a project due to other ongoing campus projects, owner ' +
                'changed design, defaulting trade partners, etc.'
        },
        preconEfforts : {
            title : 'Precon Efforts',
            description : 'Describe any preconstruction efforts or indicate that there were none. DO NOT leave blank. ' +
                'What precon services were provided? Who managed the precon design? What was the iterative process ' +
                'put in place for execution of precon efforts? How involved was the Owner in precon process? How timely ' +
                'were Owner directed changes? How involved was the design team/ subcontractors in the precon process?',
            example : ''
        },
        totalChangeOrderCount : {
            title : 'Total Change Order Count',
            description : 'Total count of any change orders to the prime contract. Include GMP, Precon, ' +
                'and Standard change orders. If there were no change orders, set this field to 0. Required.',
            example : ''
        },
        totalChangeOrderValue : {
            title : 'Total Change Order Value',
            description : 'Total value of any change orders to the prime contract. Include the value ' +
                'of any GMP, Precon, or Standard change orders. If there were no change orders, set this ' +
                'field to 0. Required. Can be negative, if there were deductive changes to the contract.',
            example : ''
        },
        changeOrderReasons : {
            title : 'Change Order Reasons',
            description : 'Combination of list of owner directed change orders plus the narrative ' +
                'of why the change order occurred broken down by values.',
            example : '<p>Throughout the design-build project, the Owner investigated value add scopes and directed changed orders including:</p> ' +
                '<ul>' +
                '<li>Skylight Allowances &amp; Overrun ($2.2M)</li> ' +
                '<li>Relocate shower diverter ($830k)</li> ' +
                '<li>Guestroom Entry Lockset to RF with Inncom RF and Inncom RF Thermostat and add ZIGBEE Capability to Guestroom Thermostat ($735k)</li> ' +
                '<li>Guestroom Hardware changes ($260k)</li> ' +
                '<li>Finalize AV Package ($500k)</li> ' +
                '</ul>' +
                '<p>(See Prime Change Order Log in Assets for additional Key Owner directed changes)</p>'
        },
        selfWorkNotes : {
            title : 'Self-Work Narrative',
            description : 'What scopes of work did Hensel Phelps self-perform? Were these scopes of work ' +
                'competitively bid? How many self-work manhours on the project? How many craft worked on the ' +
                'project? How many craft (new hires) were hired during this project? How did Hensel Phelps self-work ' +
                'assist in managing the cost and schedule? Did the subcontractor community appreciate Hensel Phelps ' +
                'self-performing work vs. being a CM firm?',
            example : 'Hensel Phelps was the design-builder and subsequently contracted with NAVFAC Washington ' +
                'for this project. In this capacity, Hensel Phelps was responsible for all design and construction ' +
                'management, contractual and bonding requirements for the project. Hensel Phelps continually monitored ' +
                'all subcontractors, in regards to safety, quality and overall performance. Hensel Phelps self-performed ' +
                'approximately 15% of the contract value which included foundations, concrete slab-on-deck, concrete ' +
                'slab-on-grade, sand filter, doors and hardware installation and underfloor cleaning and sealing.'
        },
        selfWorkPercent : {
            title : 'Self-Work Percent',
            description : 'What percent of the final contract value was self-performed by Hensel Phelps? This should be a ' +
                'combination of craft, materials, labor and salary staff project management, as a percentage of the total ' +
                'contract value.',
            example : 'Hensel Phelps was the design-builder and subsequently contracted with NAVFAC Washington for this ' +
                'project. As design-builder, Hensel Phelps was responsible for all design and construction management as ' +
                'well as contractual and bonding requirements. Hensel Phelps continually monitored all subcontractors, in ' +
                'regards to safety, quality and overall performance. Hensel Phelps self-performed approximately 19% of the ' +
                'contract value which included Construction layout, building concrete, site concrete force protection walls, ' +
                'doors and hardware, building specialties, rough carpentry, and general labor.'
        },
        selfWorkType : {
            title : 'Self-Work Scopes Performed',
            description : 'List all scopes of work self-performed by Hensel Phelps craft and Hensel Phelps salary. Craft ' +
                'scopes of work include but not limited to installation of doors, frames, and hardware, rough carpentry, ' +
                'slab on grade, structural excavation, foundation to grade, concrete work, form, place, and finish elevated ' +
                'slabs, installation of building specialties, and masonry. Salary scopes of work include but not limited to ' +
                'construction layout, design and/or construction project management, and monitoring all subcontractors',
            example : 'Hensel Phelps was the design-builder and subsequently contracted with NAVFAC Washington for this ' +
                'project. As design-builder, Hensel Phelps was responsible for all design and construction management as ' +
                'well as contractual and bonding requirements. Hensel Phelps continually monitored all subcontractors, in ' +
                'regards to safety, quality and overall performance. Hensel Phelps self-performed approximately 19% of the ' +
                'contract value which included Construction layout, building concrete, site concrete force protection walls, ' +
                'doors and hardware, building specialties, rough carpentry, and general labor.'
        },
        insuranceType : {
            title : 'Insurance Type',
            description : 'What type of insurance did this project have? Options include: Owner-Controlled ' +
                'Insurance Program (OCIP), Contractor-Controlled Insurance Program (CCIP), and Standard insurance.',
            example : ''
        },
        staff : {
            title : 'Staff',
            description : 'Link all Hensel Phelps people who worked on this project. Click the "add" button and search ' +
                'for a person to add them. Title on Project defaults to the person\'s current job title in Vista but ' +
                'can be edited. Record their highest position on this project. Current title, district, and status ' +
                '(Active or Terminated) all sync from Vista.',
            example : ''
        },
        overview : {
            title : 'Project Overview',
            description : 'Glossary overview format with 3-5 paragraphs of factual data and information.',
            example : '<p><strong>Kaiser Permanente San Diego – Hospital:</strong> Kaiser Permanente\'s San Diego Medical Center is ' +
                'one of the most patient-friendly, technologically-enhanced and energy-efficient full-service hospitals ' +
                'in the world. A fully integrated approach to project planning, design, construction and commissioning ' +
                'enabled the hospital to begin treating patients six months before the scheduled completion date. LEED ' +
                'for Healthcare Platinum Certified, it is the first hospital in California, the 2nd in the US, and the ' +
                '3rd in the world to achieve this designation. It is also the first hospital in the US to utilize 100% ' +
                'LED lighting and active chilled beam technology, two of the many innovative strategies that resulted ' +
                'in a 30% increase in energy efficiency over previously designed Kaiser template hospitals.</p> ' +

                '<p>The new campus includes a 565,000 SF hospital with 321 beds that incorporates many ideas from ' +
                'Kaiser Permanente\'s Small Hospital, Big Idea world-wide design competition. The hospital includes ' +
                '10 operating rooms plus two shelled ORs; two procedure rooms; five interventional radiology/' +
                'catheterization lab rooms; an emergency department with 39 treatment rooms; a 10-room imaging ' +
                'unit; maternal/child health services with 12 labor/delivery/recovery/postpartum (LDRP) beds, ' +
                '33 postpartum and 15 antepartum beds; 40 intensive care beds; a 29-bed neonatal intensive care ' +
                'unit (NICU); a 24-bed pediatrics unit; and 168 medical surgical beds along with central sterile, ' +
                'nutrition, laboratory, pharmacy and other support services.</p> ' +

                '<p>A 38,981 SF Energy Center (central utility plant) supports the campus and provides on-site ' +
                'power with a 650kW microturbine based trigeneration plant. The 62,512 SF Hospital Support Building ' +
                'provides out-patient services for the hospital, and was designed and constructed to OSHPD 1 requirements. ' +
                'The redevelopment and conversion of the existing 72,000 SF office building into the Viewridge MOB ' +
                'incorporated Kaiser Permanente\'s Reimagining Ambulatory Design (RAD) principles, providing a patient-' +
                'centered, design-driven approach to medicine. The 20-acre campus was developed with extensive water ' +
                'features and specimen trees to encourage staff and member interactions with nature. This Healing Garden ' +
                'provides a two-mile long walking trail on site to promote healthy living while also creating natural ' +
                'opportunities for healing. Hensel Phelps was also the design-builder for the 1,479 car Parking Structure ' +
                'which also provides 325kW of solar power to the campus. The solar array was brought online early providing ' +
                'a large chunk of the temporary construction power for the project.</p>'
        },
        resumeDescription : {
            title : 'Resume Description',
            description : '<p>Fill in the field with your project\'s information in the following order to meet the Project ' +
                'Resume data quality rules. Additional data can be included afterwards.' +
                '<ol><li>Project cost</li>' +
                '<li>Client name</li>' +
                '<li>Contracting method/Delivery Method</li>' +
                '<li>Square footage</li>' +
                '<li>Facility Type</li></ol>',
            example : 'On a $217M project for the County of Los Angeles Department of Public Works, Hensel Phelps was ' +
                'contracted to perform Design-Build services. Included in the scope, Hensel Phelps\' 282,000 SF renovation ' +
                'allowed for the inpatient tower to house 120 patient beds and other essential hospital services, such as ' +
                'emergency department, inpatient pharmacy, radiology, operating rooms, central sterilization, and support functions'
        },
        detailedDescription : {
            title : 'Detailed Description',
            description : 'Information about the building\'s overall purpose in support of the Owner; Overarching ' +
                'project goals established by the owner, design, and/or construction team. The detailed description ' +
                'must be a further developed project description that highlights the unique aspects between the design ' +
                'construction in terms of the different phases, and the overall purpose of the building. The intent ' +
                'of this section is to provide additional details on the relevancy of the project above is what was ' +
                'provided in the initial overview. Is there anything we should know about the campus, and how this ' +
                'project fits into a master plan?',
            example : '<p><strong>Marriot Marquis:</strong> The $392 million Marriott Marquis hotel project is a ' +
                'and LEED Silver certified convention center hotel. The 15-story building features 501 king guestrooms, ' +
                'full-service, four-star, 625 double-double guestrooms, 49 suites and a concierge\'s lounge. The main ' +
                'guestroom tower accommodates support and circulation areas, while the historic American Federation of ' +
                'Labor (AFL) Building features the health club, guestrooms and suites. The main guestroom tower features ' +
                'six complete public food and beverage outlets comprised of 18,879 square feet, with a seating capacity ' +
                'of 779. Restaurants include a three meal-restaurant, specialty restaurant, sports bar and lounge, ' +
                'lobby bar and lounge, Starbucks Coffee (shell only), and corner bar.</p> ' +

                'The hotels meeting and convention space includes one 30,082 square feet grand ballroom divisible into ' +
                'ten sections; two 10,791 square feet junior ballrooms, each divisible into eight sections; 53 meeting ' +
                'rooms containing 51,257 square feet; four board rooms; a second floor event terrace; and a rooftop terrace ' +
                'on the AFL Building. Additional features include a 910 square feet retail shop, a 1,010 square feet business ' +
                'center, with 84,353 square feet of public circulation, including lobby space, elevators and escalators, ' +
                'prefunction and registration areas and an underground pedestrian connection to the Convention Center.</p> ' +

                'The Marriott Marquis hotel public support areas feature 12,681 square feet of administration offices, ' +
                '25,212 square feet of kitchen, 4,781 square feet of employee facilities, and 212,220 square feet of ' +
                'miscellaneous areas including one exterior and one underground loading dock connected to the Convention ' +
                'Center\'s loading dock facility.</p> ' +

                'Hensel Phelps was tasked with incorporating the seven story historic American Federation of Labor (AFL) ' +
                'Building into the design and construction of the new hotel. To prepare the building for renovation, ' +
                'Hensel Phelps removed the existing interior features, mechanical/electrical systems and plumbing, ' +
                'leaving only the building shell.</p> ' +

                'The American Federation of Labor (AFL) Building, which is listed as a National Historic Landmark with ' +
                'the National Park Service, became fully incorporated into the Project, becoming an additional guestroom ' +
                'tower connected at the lobby and the fourth floor. Hensel Phelps integrated the hotel foundation with ' +
                'that of the AFL building and provided additional structural elements to support the new building loads. ' +
                'A new health/fitness center and dining facility on the first floor was incorporated into the renovated ' +
                'structure. The renovation of the AFL Building includes sustainable features such as a green roof.</p> ' +

                'The expression, "to fit a square peg in a round hole," took on a more literal meaning to the design team ' +
                'for the Marriott Marquis. The program called for a 1.25-million-square-foot building on a 100,000 square ' +
                'foot site. Due to local zoning height requirements, going 100 feet underground was the only option. The ' +
                'combination of extreme depth; the tight, complicated site and the need for large column-free ballrooms ' +
                'required the use of top-down construction and compelled the team to devise solutions to a number of ' +
                'design and construction challenges.</p> ' +

                'Conventional support of excavation bracing methods, such as tiebacks, rakers, or cross lot bracing was ' +
                'not feasible on this site due to the significant lateral earth and hydrostatic pressures and adjacent ' +
                'subgrade restrictions. The large two-story spaces below grade created diaphragms with very large openings. ' +
                'This resulted in very highly stressed portions of the slabs which required the use of structural steel ' +
                'members and plates to reinforce the concrete slabs.</p> ' +

                'The difficulty of top-down construction on a site where the building perimeter is essentially at the ' +
                'property line cannot be understated. Setting the building foundations, excavating and removing the soil, ' +
                'placing the structural slab system and repeating for each floor from the street elevation to the lowest ' +
                'level demanded the highest level of focus and planning. This project includes the installation of 1,450 ' +
                'feet of slurry cut-off wall systems. The cut-off wall is installed to a depth in excess of 120 feet. ' +
                'Additionally, 142 drilled shafts ranging from 60 to 72 inches in diameter are installed to a depth of ' +
                '120 feet. The extraordinary result achieved was 15 stories of above grade and eight stories below grade ' +
                'constructed simultaneously, and the deepest building excavation in Washington, DC. This was all accomplished ' +
                'while both temporarily and permanently supporting Pepco electrical duct banks through the site.<p> ' +

                'Special attention was directed to an existing Pepco building adjacent to site during construction. ' +
                'Through team oriented planning charettes during preconstruction, measures were put in place minimize ' +
                'interruptions to traffic flow, supporting live ductbanks and sewer system piping running along the ' +
                'perimeter of the excavation.</p> ' +

                '<strong>Benjamin P. Grogan Federal Building:</strong> The U.S. General Services Administration (GSA) selected Hensel ' +
                'Phelps to be the design-builder for the Benjamin P. Grogan and Jerry L. Dove Federal Building project ' +
                'located in Broward County, Florida. The Benjamin P. Grogan and Jerry L. Dove Federal Building is a ' +
                'bridging design-build project completed by the Hensel Phelps Design-Build Team consisting of four ' +
                'facilities in a campus-sytle environment located near the Everglades in Miramar, Florida. The facility ' +
                'operates around-the-clock, 24 hours a day, seven days a week, 365 days a year. It supports the Federal ' +
                'Bureau of Investigations (FBI) mission to protect and defend the United States against terrorist and ' +
                'foreign intelligence threats, to uphold and enforce the criminal laws of the United States, and to ' +
                'provide leadership and criminal justice services to federal, state, municipal, and international agencies ' +
                'and partners. This project was designed and built to be LEED Platinum Core and Shell (C&S) certified, ' +
                'LEED Gold Commercial Interiors (CI) certified, and Sustainable Sites Initiative Two Star certified.</p> ' +

                'This Benjamin P. Grogan and Jerry L. Dove Federal Building was designed and built to achieve the owner\'s ' +
                'vision for a world-class office building that exemplifies the GSA\'s Design Excellence Program. Totaling ' +
                '383,000 gross square feet, the facility had a construction budget of $160 million. The project includes ' +
                'enhanced security specifications, an enclosed parking structure, and multiple buildings on the 20-acre ' +
                'site. The GSA required a facility that met the Level 4 Interagency Security Committee criteria. The project ' +
                'included 787-space parking garage, as well as 400 surface parking spaces spread across the 20-acre site. ' +
                'With a March 2012 notice-to-proceed on the design, Hensel Phelps worked diligently to meet substantial ' +
                'completion in October of 2014. The project displays Design Excellence as part of the GSA\'s Design ' +
                'Excellence Program, which includes sustainable design features and has taken this project to the next ' +
                'level. The design-build project team also includes Gensler; Krueck & Sexton Architects; Syska Hennessy; ' +
                'Enclos Corp.; Rolf Jensen & Associates; and Atkins.</p> ' +

                'The 303,552 SF, six and seven-story facility is connected by a six-story link and includes executive ' +
                'office spaces, private offices, team office spaces, conference space, storage, fitness center, computer ' +
                'training, treatment rooms, work rooms, and armory. The office building required redundant power and UPS ' +
                'infrastructure to support the tenants operations, as well as extensive networking fiber optic and copper ' +
                'cabling to service classified work areas and Secure Work Environments (SWE). Primary features include a ' +
                'progressive collapse concrete structure with custom curtain wall facade that meets Government specified ' +
                'blast requirements and can withstand 180+ mph wind forces.</p> ' +

                'The Central Utility Plant (CUP) is a 3,000 SF facility that includes one 240-ton air-cooled chiller; ten ' +
                'end-suction pumps; three 385-ton capacity magnetic bearing chillers. In addition, the CUP has a 1,085 SF ' +
                'cooling yard that consist of three 385-ton induced draft cooling towers. All systems were designed and ' +
                'constructed to include N+1 redundancy. A detailed coordination model was developed for all aspects of work ' +
                'prior to construction. This model was so detailed that it included every support hangar with actual ' +
                'equipment and commissioning data.</p> ' +

                'The four-story precast parking garage has capacity for 787 vehicles. The top canopy is covered with ' +
                'photovoltaic panels that contribute to an overall total of one mega-watt of power generated for the ' +
                'campus. This equates to 20% of the power needed for the campus.</p> ' +

                'The facility was constructed on a greyfield site. A 32,000-gallon cistern was installed to harvest ' +
                'the rainwater from the office building roof drains and provides make-up water to the CUP. Reclaimed ' +
                'water was utilized for irrigation throughout the facilities opulent landscape. This project was part ' +
                'of the Sustainable Sites Initiative (SSI) Pilot program and achieved Two Stars. Major component was ' +
                'keeping the site balanced and not importing or exporting any fill or excavated material. The design ' +
                'met this criteria by utilizing the excavation from the ponds and utilizing it for the building pads. ' +
                'Topsoil was also excavated from the ponds and stored on site. The site design for the campus respects ' +
                'the natural Florida landscape, incorporating the wetlands throughout the project, as well as incorporating ' +
                'them into interior and exterior courtyards. The main building\'s signature curtain wall style integrates the ' +
                'wetlands into the building with glass transparency. The project includes a jogging path located around the ' +
                'reconstructed wetlands as well as locations for gatherings and relaxation. The project team made use of ' +
                'locally sourced materials with high recycled content, FSC wood and native and adaptive plant species ' +
                'throughout the design. The result is a sustainable native plant community located within aesthetically ' +
                'appealing grounds that also provides water quality treatment for the project area. The main goals identified ' +
                'in the project such as long-term flexibility, collaboration, and accessibility have been applied to the ' +
                'design of this project allowing for the creation of a facility that is uniquely suited to the tenant.</p> ' +

                'The Benjamin P. Grogan and Jerry L. Dove Federal Building had a 30-month duration with 25 months of ' +
                'construction from start of design to turnover. Communications rooms and electrical closets were turned ' +
                'over 60 days prior to substantial completion. The original schedule included two early access milestones ' +
                'to support the tenant\'s communication and furniture needs. 60-days prior to substantial completion, all ' +
                'communication support rooms were transferred to the owner so their vendor could begin installing their ' +
                'communication and security cabling. The second early access deadline was 45 days prior to substantial ' +
                'completion so that the furniture could be delivered and installed.</p> ' +

                'During the course of construction, the owner executed multiple change orders to the scope of the ' +
                'project, including a few that had a substantial impact on the schedule. The modification that was ' +
                'the most significant requested multiple changes and additions for the electrical and communication ' +
                'components to the offices and systems furniture (including the actual connections) for the FBI. The ' +
                'original time impact analysis (TIA) schedule for this work pushed the substantial completion date by ' +
                'four months, from September 2014 to December 2014. This would have been incredibly negative for the ' +
                'tenant, financially and operationally, as they had to be completely moved out of their existing ' +
                'facilities before the end of 2014. With this knowledge, Hensel Phelps worked closely with the owner ' +
                'and their furniture vendor to help push manufacturing of the systems, in addition to shortening lead ' +
                'times, the subcontractors also became heavily involved so that instead of bringing furniture in August ' +
                '2014 (45 days prior to substantial completion) areas of the building were ready and delivery started ' +
                'in June of 2014. This achievement allowed the schedule impact of four months to be mitigated and meet ' +
                'the original final completion date. Through constant communication and heavy involvement with the owner, ' +
                'tenant and subcontractors, the Hensel Phelps team was able to complete the project on time and under budget.</p>'
        },
        designAspectsDescription : {
            title : 'Design Aspects Description',
            description : 'Regardless of being involved in the design, we should highlight key aspects of the design. ' +
                'Describe how the design contributes to operational efficiency, user comfort, function, productivity, ' +
                'and business value. Describe how the project embodies any of the following characteristics that ' +
                'contribute to the enhancement of the built environment: Memorable, inspirational, sustainable, ' +
                'innovative, holistic awareness, state-of-the-art, contextual response, ambitious, etc.',
            example : '<p><strong>Social Security Administration National Support Center (SSA NSC):</strong></p>' +
                '<p><strong>How functional design contributes to operational efficiency, user comfort and function, ' +
                'productivity/business value; and building systems integration.</strong></p>' +

                '<p>The team\'s primary objective was to exemplify design, engineering and construction excellence ' +
                'through an interactive charrette process that allowed the aesthetic quality of the facility to ' +
                'flourish while maintaining the reliability, functionality sustainability and scalability of the ' +
                'Data Center and support spaces. This balance of <strong>aesthetic design</strong> and <strong>data ' +
                'center performance</strong> was reinforced with analysis of constructability, life cycle cost, schedule, ' +
                'budget and safety, thus assuring that the three imperatives of design, engineering and construction ' +
                'were achieved.</p>' +

                '<p>To resolve the programmatic and functional requirements, the team addressed the site, facility ' +
                'design and building systems engineering. It was important to achieve an appropriate balance among a ' +
                'number of conflicting ideals, including:</p>' +

                '<ul>' +
                '<li>Maintenance of a discrete building profile suitable to a highly secure facility, while also ' +
                'conveying the dignity and stature of a federal government facility.</li>' +
                '<li>Maintenance of a rigorously secure building envelope around an inherently inward-focused ' +
                'facility, while at the same time avoiding an overtly uninviting and closed appearancee</li>' +
                '<li>Implementing a design language of enduring and universal quality, while at the same time ' +
                'engaging and reinforcing the unique characteristics of the site.</li>' +
                '</ul>' +

                '<p>The team\'s solution resolved these conflicts and created a campus organization that solves the ' +
                'various programmatic functions and directly responded to functional adjacencies and the workflow ' +
                'for the occupants. At the center of this complex, is the Data Center, designed to <strong>maximize ' +
                'efficiency, functionality, circulation, and security</strong>. All other programmatic functions ' +
                'radiate their adjacencies from the Data Center while minimizing travel distances.</p>' +

                '<p>One of the main focal points of the team\'s aesthetic expression through design excellence was ' +
                'the main entrance of the Office Building through a two-story glass clad Arrival Hall designed to ' +
                'receive all occupants of the facility. High quality finishes evoke the dignity, technological ' +
                'advancement, and permanence of the SSA. Durability, sustainability, and low maintenance were ' +
                'significant contributing factors in the selection of all interior finishes. Operational and ' +
                'life-cycle requirements were considered for the design of the Data Center, service areas and ' +
                'corridors, and surrounding support buildings.</p>' +

                '<p>The Data Center exterior wall design is a tenent that clearly exemplify design excellence. The ' +
                'team developed the curved precast envelope system used in the Data Center to create the visual ' +
                'perception of a reduced building mass. The system contains folded concrete planes oriented in a ' +
                'running bond pattern, providing additional storage and utility spaces within the white space while ' +
                'maintaining a rectilinear appearance to embody the efficiency and functionality of the data center. ' +
                'The precast system provided robust interior protection from the elements; allowed for a high degree ' +
                'of quality control and design flexibility; and supported a fast-track construction schedule. ' +
                'Additionally, this system was chosen for security compliance, optimal energy performance properties, ' +
                'durability – longevity over the functional life of the facilities, and insulation properties.</p>' +

                '<br></br>' +
                '<p><strong>Characteristics that contribute to the enhancement of the built environment</strong></p>' +
                '<p>The NSC demonstrates that mission critical facilities can be delivered efficiently, within ' +
                'established budgets and schedules, while providing exemplary architecture and engineering design ' +
                'excellence. The facility is fully responsive to operational and security requirements, as well as ' +
                'design, construction and building management objectives. The care and innovative thinking that ' +
                'permeated every aspect of the design and the implementation strategy embodies the ideals of design ' +
                'excellence. Key features include:</p>' +

                '<ul>' +
                '<li><strong>Holistic Design Approach:</strong> The team took a holistic approach to the design and ' +
                'visualized the facility as a singular gesture, incorporating each primary structure into a harmonious ' +
                'whole. Each component retained its primary function either to manage, power, or service the facility. ' +
                'Adjacencies were meticulously scrutinized to determine an optimal and functional composition.</li>' +
                '<li><strong>Site Master Plan that functions as both a campus and individual structures</strong>. The ' +
                'overall site planning and orientation of the buildings reinforced security, functionality and represent ' +
                'a memorable expression of a modern, high-tech data center.</li>' +
                '<li><strong>Functional efficient layouts with required adjacencies</strong>. The arrangement of the ' +
                'campus, its individual buildings, and its interior functional layout of the Data Center not only complied ' +
                'with all required adjacencies, but employed best practices to achieve enhanced security, operational ' +
                'efficiency (connection of buildings & program) and optimized work-flow.</li>' +
                '<li><strong>Achievement of Energy Policy Act and USGBC LEED® Gold Certification</strong>. The MEP systems ' +
                'required to support data centers typically represent a significant component in regards to energy ' +
                'consumption. The systems for the NSC were designed to ensure that energy reduction goals were achieved, ' +
                'while still providing a robust, reliable, and expandable infrastructure. One key mechanical system that ' +
                'was of particular concern, from an energy consumption and maintainability standpoint, was the hot and ' +
                'cold aisle containment system in the Data Center. The goal of this system is to conserve energy and lower ' +
                'cooling costs by managing air flow. Historically, servers have been arranged in the same orientation ' +
                'creating a continuous mixing of hot and cold air. Modern server arrangements involve lining up server ' +
                'racks in alternating rows with cold air intakes facing one way (creating a cold aisle) and hot air exhausts ' +
                'facing the other (creating a hot aisle). A barrier or containment system is then used to isolate hot aisles ' +
                'and cold aisles from each other and prevent hot and cold air from mixing. The team collaborated with project ' +
                'stakeholders to develop a state-of-the-art soft and rigid barrier containment system that exceeded the ' +
                'projects energy reduction goals while being easily maintainable.</li>' +
                '<li><strong>Ambitious commissioning practices to achieve Tier III Uptime Certification.</strong> The ' +
                'commissioning of the facility was so critical that coordination meetings were initiated the week after ' +
                'project award. Project stakeholders quickly reached an understanding of how to execute three levels of ' +
                'commissioning (standard building, integrated systems testing (IST), and Tier III Uptime Certification) ' +
                'while the program was evolving.The NSC is the first Federal data center, and one of only 67 data centers ' +
                'worldwide, to achieve Uptime Tier III Certification.</li>' +
                '</ul>' +

                '<br></br>' +
                '<p><strong>Sensitivity to the Environment and Surroundings</strong></p>' +
                '<p><em>Energy Efficient Mechanical and Electrical Design</em></p>' +
                '<p>The NSC is the first LEED Gold Certified data center constructed for the General Services Administration. ' +
                'A large data center by nature consumes a significant amount of energy through the operation of the IT ' +
                'equipment. This presented an opportunity for the design team to reduce the energy usage required for ' +
                'the facility. Significant savings were realized with the use of water-cooled chillers and heat recovery ' +
                'wheels to operate the chillers with little to no load in the data center White Space, while using heat ' +
                'recovery chillers for latent cooling and hot water production. The use of an elevated chilled water supply ' +
                'temperature for sensible cooling allowed the waterside economizer to be used at over 70% of the yearly ' +
                'hours. This central plant design, along with custom computer room air handlers (CRAH) and the associated ' +
                'chilled water distribution system, helped achieve 40% LEED energy savings and 52% EPACT energy savings.' +
                'A 1 MW photovoltaic system will produce electricity onsite and a solar thermal system will produce 30% ' +
                'of the domestic hot water demand for the facility. Additionally, the plumbing design incorporated domestic ' +
                'solar hot water and storm water reclamation systems that provide greywater distribution to the facility\'s ' +
                'toilets and makeup water to the cooling towers.</p>' +

                '<p><em>Integrated Landscape Design</em></p>' +
                '<p>One example of the team\'s sensitivity to the environment and surroundings was realized through ' +
                'successfully integrating the landscape design with Design Excellence criteria. The site\'s clearly ' +
                'ordered geometry reinforced three distinct landscape zones to model a delicate intervention on the ' +
                'site. The three areas balanced and integrated the site and buildings while reflecting a contemporary ' +
                'reinvention of the existing wheat field/hedge row landscape. The first zone featured an arched band ' +
                'of large native trees along the formal pedestrian entry. The second zone along the Interstate 270 ' +
                'corridor featured over five acres of reforestation and reestablishment of native ecosystems including ' +
                'meadow grasses, wildflowers, riparian barriers with shrubs and a variety of deciduous/coniferous trees ' +
                'to protect the existing wetlands. The third zone providing panorama views over the existing landscape ' +
                'was preserved and accentuated with large specimen trees. Each zone was designed to uniquely reflect ' +
                'the rolling site concept.</p>' +

                '<p><em>Sustainable Stormwater Management Systems</em></p>' +
                '<p>Special consideration was given to the projects stormwater management design. 65-acres of the ' +
                'site drain into nearby Bennett Creek and eventually flowing into the Monocacy River and ultimately ' +
                'into the Chesapeake Bay. The team design 14 micro-bioretention and bio-swale features which will ' +
                'filter 2.5 million gallons of runoff each year. Two underground cisterns hold 2.7 million gallons ' +
                'of rooftop runoff for reuse in the Data Center\'s cooling towers and site landscaping. Finally the ' +
                'design included 105,000 square feet of pervious surfaces which will prevent 2.3 million gallons of ' +
                'runoff each year. <em>See image labeled Site Hydrology.</em></p>' +

                '<p><em>Sustainable Vegetation</em></p>' +
                '<p>The team incorporated 48 species of native plants suitable to the Upper Piedmont of Maryland, ' +
                'which thrive with minimal maintenance and year year-round interest with flowers, foliage, and fruit, ' +
                'throughout the site. These plants supply habitat and food for wildlife, including bees and other ' +
                'pollinators. 32-acres of meadow mix were planted in place of typical lawn, reducing the sites ' +
                'environmental footprint and maintenance costs by requiring minimal mowing and fertilizer use. ' +
                'Additionally, the plants play a significant role in stormwater management features and help reduce ' +
                'the heat island effect created by paved parking, walkways, and buildings  <em>See image labeled ' +
                'Vegetation.</em></p>' +

                '<p><em>Sustainable Vegetation</em></p>' +
                '<p>Understanding the fragile ecosystem of the Upper Piedmont, the design left as much of the ' +
                'site undisturbed as possible while restoring areas that had been previously used as farmlands. ' +
                'Seeded meadows, punctuated by trees, support populations of pollinators, such as honeybees, ' +
                'while leaving views open for employees. Additionally, the team organized vegetation into plant ' +
                'communities that reflect regional ecosystems. Reforestation areas that reduce soil erosion and ' +
                'water pollution, also support security measures by screening the site along the north and west ' +
                'sides of the site. These protected and restored areas on the site will, over time, meld together ' +
                'to create functional ecosystems, as well as a seasonally transformative landscape  <em>See image ' +
                'labeled Ecosystems</em></p>' +

                '<p>Our team\'s sensitivity to the environment is exemplified by Les Shepherd, Chief Architect, ' +
                'GSA who recently stated, <em>"The design-build team of Hensel Phelps, SOM, Corgan, and Timmons ' +
                'should be commended on their efforts for providing a solution that effectively uses the wonderful ' +
                'site features and incorporates the extensive landscape into the various elements. Our client\'s ' +
                'recognition and appreciation for how the team has developed the project with a sensitivity to ' +
                'the American taxpayers while meeting SSA\'s program of requirements, further speaks to the team\'s ' +
                'hard work and the strong partnership that has developed."</em></p>' +

                '<br></br>' +
                '<p><strong>Adding value, both tangible and intangible, through sustainable design</strong></p>' +
                '<p>The design-build team provided numerous tangible and intangible value adding elements to the ' +
                'NSC project. A few examples include:</p>' +

                '<ul>' +
                '<li>The consolidated building and compact site helped to prevent impacts to wetlands and stream ' +
                'systems.</li>' +
                '<li>Enhanced architectural pre-cast exterior panels respond to the natural landscape in form and ' +
                'materiality while providing a high degree of quality control and enhanced wall performance properties</li>' +
                '<li>The massing of the Data Center takes on a gentle curve responding to the site landscape. This ' +
                'responsiveness blurs the line between nature and the built environment.</li>' +
                '<li>Storm water is collected from building roofs with on-site reuse in plumbing and mechanical ' +
                'systems to reduce long term utility costs. Additionally, the storm water collection system provided ' +
                'irrigation for landscaped employee circulation areas.</li>' +
                '<li>The team provided supplemental solar domestic water heating and used 100% gray water for all ' +
                'water closet and urinal flushing, as well as cooling tower makeup.</li>' +
                '<li>The efficiency of the UPS systems is a minimum of 95% at 25%, 50%, 75% and 100% load factors. ' +
                'This exceeds the 93% UPS efficiency established in the POR and has significantly contributed to the ' +
                'favorable low Power Utilization Efficiency (PUE). The higher efficiency also reduces the annual energy ' +
                'consumption associated with all loads fed from the UPS, including the White Space IT loads.</li>' +
                '<li>Stormwater management features capture, infiltrate and store water on site for use in the irrigation ' +
                'system and cooling towers.</li>' +
                '<li>Native species and other plants adapted to the local climate screen the site for security, reduce ' +
                'the heat island effect of paved surfaces, and frame views</li>' +
                '<li>Plant communities reflect regional ecology, blending in with nearby meadows and woodlands and ' +
                'restoring habitats to support wildlife, including pollinators.</li>' +
                '</ul>' +

                '<p>Ultimately, sustainability was an integrated building systems approach, influencing all portions ' +
                'of the design, construction and operation of the new facility. Utilizing the design-build method allowed ' +
                'the team to carefully establish and track performance goals, develop and emphasize sustainable strategies, ' +
                'and solve problems and conflicts that occurred during design.</p>' +

                '<p>The team had a strong commitment to sustainability and the achievement of LEED Gold Certification. ' +
                'Traditionally, data centers rank among the largest power users in modern facilities, which makes achieving ' +
                'LEED Gold Certification a monumental feat. The facility achieves energy efficiency through advanced building ' +
                'control systems, climatically responsive layouts, waste reduction principles, and natural day-lighting. ' +
                'Additional high performance sustainable design permeates the site with such elements as the recapture of ' +
                'rainwater to assist with cooling, greywater reuse, and a 1 MW array of photovoltaic panels.</p>'
        },
        technicalAspectsDescription : {
            title : 'Technical Aspects Description',
            description : 'Used to describe they key and unique technical aspects of the project. What information do we need ' +
                'to know about the project mechanically, electrically, or from a construction logistics/phasing standpoint?',
            example : '<p><strong>Lockheed SSL Renovation PH 1/2/3:</strong> <em>(xii) Description of current experience ' +
                'managing and understanding multi-discipline projects involving fabrication, installation and testing of ' +
                'large quantities of Ground Support Equipment (GSE) systems hydraulic equipment; cryogenic (vacuum jacketed) ' +
                'piping and equipment; pneumatic tubing and equipment; electrical power terminations, mechanical cooling ' +
                'systems, piping and ductwork; controls, and specialty communications wiring and equipment.</em></p>' +

                '<ol style="list-style-type: lower-alpha;">' +
                '<li><em>The electrical controls cabling will require potting and molding of the connectors, termination ' +
                'of cables and complete checkout of cabling.</em></li>' +
                '<li><em>The piping and tubing installation will include bending, flaring and welding (AL6XN) SS tubing ' +
                'and welding of specialized vacuum jacketed pressurized piping. Precision cleanliness requirements shall ' +
                'be imposed for the piping and tubing component of the work.</em></li>' +
                '</ol>' +

                '<p>This project included a complete upgrade of the existing 29X65 TVAC chamber&rsquo;s LN2 and GN2 ' +
                'lines, including the installation of new Temperature Control Units (TCUs) and new stainless steel ' +
                'shrouds on the interior of the chamber were completed. A new concrete moment frame was constructed ' +
                'to hold two new and two future 15,000-gallon LN2 dewars. These new dewars supplement the existing ' +
                '90,000 gallon dewar. These tanks also had six cryogenic pumps.</p>' +

                '<p>The piping on this project was extensive and highly specialized.<strong> New vacuum jacketed ' +
                '(VJ) piping, control and isolation valves were installed from the new dewars to the 12 new LN2 ' +
                'pumps that reside in the new addition. </strong>From the new LN2 pumps, VJ piping was installed ' +
                'to transport the LN2 to the existing TVAC chambers and to the 29X65\'s new TCUs and then to the ' +
                'chamber itself. This included more than<strong> 1 million lineal feet of VJ piping, excluding ' +
                'the associated vents, PSVs, and drain piping.</strong></p>' +

                '<p>Precision cleanliness was required and imposed for the piping and tubing component of this ' +
                'project. This project also required very high quality control standards and stringent tolerances ' +
                'due to the amount of hazderous materials that flow through this facility. </p>'
        },
        transitionalServicesDescription : {
            title : 'Facility Solutions Offerings Description',
            description : 'Insert the applicable FS service offerings from the bullet list below for project sheets and other marketing uses:' +
                '<ul>' +
                '<li>' +
                'Facility Condition Assessments' +
                '</li>' +
                '<li>' +
                'Operational Assessments' +
                '</li>' +
                '<li>' +
                'Operational Project Readiness' +
                '</li>' +
                '<li>' +
                'Facility Operations Manual' +
                '</li>' +
                '<li>' +
                'Asset Tagging &amp; COBie Data' +
                '</li>' +
                '<li>' +
                'Computerized Maintenance Management Systems' +
                '</li>' +
                '<li>' +
                'Training/Videography' +
                '</li>' +
                '<li>' +
                'Staffing Solutions/Continuity of Service/Account Transition' +
                '</li>' +
                '<li>' +
                'Life Cycle Cost Analysis/Total Cost of Ownership' +
                '</li>' +
                '<li>' +
                'Commissioning Agent/Commissioning Coordinator' +
                '</li>' +
                '<li>' +
                'Warranty Management Program' +
                '</li>' +
                '</ul>',
            example : ''
        },
        operationsAndMaintenanceDescription : {
            title : 'Building Systems Integration Service Offerings Description',
            description : 'Insert the applicable BSI service offerings from the bullet list below for project sheets and other marketing uses:' +
                '<ul>' +
                '<li>' +
                'Facility Condition Assessments' +
                '</li>' +
                '<li>' +
                'Consulting' +
                '</li>' +
                '<li>' +
                'Turn-Key Low Voltage Integration' +
                '</li>' +
                '<li>' +
                'Building Controls' +
                '</li>' +
                '<li>' +
                'Telecom' +
                '</li>' +
                '<li>' +
                'Security/Access Control' +
                '</li>' +
                '<li>' +
                'Fire Alarm' +
                '</li>' +
                '<li>' +
                'Cybersecurity' +
                '</li>' +
                '<li>' +
                'Audiovisual' +
                '</li>' +
                '<li>' +
                'Energy Management' +
                '</li>' +
                '<li>' +
                'Specialty Systems' +
                '</li>' +
                '</ul>',
            example : ''
        },
        facilityManagementDescription : {
            title : 'Facility Management Service Offerings Description',
            description : 'Insert the applicable FM service offerings from the bullet list below for project sheets and other marketing uses:' +
                '<ul>' +
                '<li>' +
                'Integrated Facilities Management' +
                '</li>' +
                '<li>' +
                'Preventative Maintenance' +
                '</li>' +
                '<li>' +
                'Reactive Maintenance' +
                '</li>' +
                '<li>' +
                'Janitorial/Custodial' +
                '</li>' +
                '<li>' +
                'Building Envelope' +
                '</li>' +
                '<li>' +
                'Grounds/Landscaping' +
                '</li>' +
                '<li>' +
                'Security' +
                '</li>' +
                '</ul>',
            example : ''
        },
        notes : {
            title : 'Notes',
            description : 'Anything that helps tell the story of the project but doesn\'t fit in other descriptions.',
            example : ''
        },
        quality : {
            title : 'Quality Narrative',
            description : 'Describe any innovative programs relating to quality control, the construction quality and the ' +
                'craftsmanship, how the project attained overall project quality above what was specified in the contract, ' +
                'statistical data for Quality Control Management, average length it took to close an item, any tablet use, anyone ' +
                'who was dedicated to Quality Control, and how the punch list items were communicated to the subcontracting community.',
            example : ''
        },
        valueEngineering : {
            title : 'Value Engineering and Cost Management Strategies',
            description : 'Include a short description and a cost and/or schedule improvement statistic for all value engineering ' +
                'and cost management strategies and solutions approved by the owner in order to reduce cost and/or schedule impacts. ' +
                'How did you provide the Owner with the "best value for dollar spent"? Provide specific examples of "value adding" ' +
                'elements or factors that you/your team brought to the project. These may be quantitative or qualitative in nature.',
            example : '<p><strong>Marriott Marquis:</strong> The small site made building layout a challenge - one area ' +
                'in particular was the loading dock and pedestrian connector which initially were designed in two separate ' +
                'locations. Hensel Phelps worked with design-team to get openings for both in the same location rather than ' +
                'spreading them apart. Cost savings of approximately $3 million.</p>' +

                '<p>Value Engineering and Cost Management Strategies include (see Trend Log in assets)</p>' +
                '<ul>' +
                '<li>Reduce the number of ribs in sunshade cornice by 50% - Change from 3-0 on center to 6-0 on center ($154 thousand)</li>' +
                '<li>Eliminate sunshades at interior atrium ($144 thousand)</li>' +
                '<li>Eliminate jet grout at slurry wall and replace with slurry wall panels ($502 thousand)</li>' +
                '<li>Eliminate masonry liner wall at slurry wall ($289 thousand)</li>' +
                '<li>Eliminate glass cladding at meeting rooms replace with fabric wrapped panels ($522 thousand)</li>' +
                '<li>Eliminate glass shelves at all guest bathrooms ($114 thousand)</li>' +
                '<li>Eliminate marble tile at guestroom elevator lobbies - all tile to carpet ($152 thousand)</li>' +
                '<li>Eliminate all Tele Data &amp; CATV conduits in guestrooms and replace with plenum rated cable ($130 thousand)</li>' +
                '<li>Utilize fiberglass insulation on chilled water piping system in place of closed cell thermal insulation ($125 thousand)</li>' +
                '<li>Utilize fiberglass insulation on domestic water piping systems in place of closed cell thermal insulation ($185 thousand)</li>' +
                '<li>Change closet rod and shelf to Beverly Coat Hanger product ($277 thousand)</li>' +
                '<li>Use PVC pipe for sanitary pipe 2" or less ($400 thousand)</li>' +
                '<li>Public space interiors VE ($2.75 million)</li>' +
                '<li>Utilize PVC for all underground piping ($140 thousand)</li>' +
                '<li>Change base in standard guest rooms form Johnsonite base to carpet base ($128 thousand)</li>' +
                '<li>Change Guest Room Interior Door Frames (Closet, Bath, &amp; Communicating) to Timely System ($150 thousand)</li>' +
                '<li>Adjust shore tower and dance floor design plan for atrium truss erection and atrium wall construction ($982 thousand)</li>' +
                '<li>Adjust tower crane/material hoist budget to correct math error and update to vendor pricing ($250 thousand)</li>' +
                '</ul>'
        },
        collaborativeEfforts : {
            title : 'Collaborative Efforts',
            description : 'Describe our working relationship with the Owner, or specialized processes developed by the ' +
                'team on site to help foster the success of the project. Describe the project mission statement and what ' +
                'it meant to the project team. Describe how the Owner, various client stakeholders, and/or end users have ' +
                'been engaged throughout the process. "Teaming philosophy" — discuss the mission beyond the bricks and mortar ' +
                'on the project. Provide a joint statement from the designer, contractor and owner on how you came together as ' +
                'a team. This statement should be created by the team involved in the commitment, process and design of this ' +
                'project. Describe your issue resolution procedure, how many claims were filed on the project, and describe ' +
                'how individual craft workers, subcontractors, and stakeholders were brought intothe partnering process.',
            example : ''
        },
        commissioningEfforts : {
            title : 'Comissioning Efforts',
            description : 'Describe the level of commissioning that was required, as well as LEED Cx requirements, ' +
                'scopes of work that were required to be commissioned and if there was a dedicated Hensel Phelps Cx ' +
                'manager. Describe involvement of owner and subcontractors in the Cx process, and the lessons that ' +
                'were learned and resolved. Include if the owner had a 3rd party Cx Agent.',
            example : ''
        },
        lessonsLearned : {
            title : 'Lessons Learned',
            description : 'Describe all lessons learned through the various phases of the project. Content should ' +
                'be written for Hensel Phelps employees.',
            example : ''
        },
        projectInnovations : {
            title : 'Project Innovations',
            description : 'Describe any state-of-the-art advancement, any innovation in construction techniques ' +
                'or materials, and attributes that make this job unique.',
            example : ''
        },
        vdcImplementation : {
            title : 'VDC Implementation',
            description : 'At what stage of the project was VDC implemented and how? What VDC deliverables did the ' +
                'owner require, and how did we meet these needs? Provide case study information and detailed unique ' +
                'aspects of how VDC positively impacted the course of the project.',
            example : ''
        },
        community : {
            title : 'Community',
            description : 'Describe any community services or contributions made to the community (if any) the ' +
                'jobsite participated in.',
            example : ''
        },
        quotes : {
            title : 'Quotes',
            description : 'Input all quotes from owners, subcontractors, users, articles, etc. Make sure to indicate ' +
                'who the quote came from, the date, their title and their company. Separate quotes with two new lines.',
            example : ''
        },
        securityNarrative : {
            title : 'Security',
            description : 'Describe any security protocols required by the client for the project and how we implemented ' +
                'the requirements. Describe the security standards that were followed (ICD 705, DCID 6/9, UFCs, etc) as ' +
                'each client follows different standards. Provide metrics such as linear feet of fence, square feet of ' +
                'SCIF, and quantity of bollards for all security elements that were installed on the project.',
            example : ''
        },
        challengesAndSolutions : {
            title : 'Overview',
            description : 'Describe any significant scope creep/modifications. Provide details on value, schedule ' +
                'impacts, and mitigation methods used to incorporate into project. Describe meeting the challenges ' +
                'of a difficult job. Indicate any special obstacles you overcame in completing the project.',
            example : ''
        },
        awardTitle : {
            title : 'Title',
            description : 'The name of the award.',
            example : ''
        },
        awardCitation : {
            title : 'Level',
            description : 'Level of award.',
            example : 'Merit, Overall Winner, etc.'
        },
        awardDate : {
            title : 'Year',
            description : 'Year the award was won. Four digits.',
            example : ''
        },
        awardSponsor : {
            title : 'Sponsor',
            description : 'Enter the organization that is sponsoring the award.',
            example : ''
        },
        awardCategory : {
            title : 'Category',
            description : 'Specifics about the category of the award.',
            example : 'Mixed Use over 20 million, Educational Facilities, Innovation, etc.'
        },
        challengeTitle : {
            title : 'Title',
            description : '5-7 words that describe the challenge.',
            example : ''
        },
        challenge : {
            title : 'Challenge',
            description : 'Describe in detail the challenge faced on the project.',
            example : ''
        },
        solution : {
            title : 'Solution',
            description : 'Describe in detail the solution(s) implemented to address that challenge.',
            example : ''
        },
        referenceType : {
            title : 'Reference Type',
            description : 'Pick the option that most accurately describes this individual\'s affiliation with ' +
                'the project. Should be one of Owner, Owner\'s Representative, End-User, Designer, or Subcontractor.',
            example : ''
        },
        referenceRole : {
            title : 'Role on Project',
            description : 'Indicate the reference\'s job title or most relevant role during the project.',
            example : ''
        },
        referenceCompany : {
            title : 'Company on Project',
            description : 'Defaults to their current company in CRM. If necessary, change to reflect the company ' +
                'they were with during the project.',
            example : ''
        },
        referenceSyncStatus : {
            title : 'Synced with CRM',
            description : 'If this is a project with old references ported over from SMART, then a red "X" ' +
                'indicates a stale reference that came from SMART. These need to be updated by linking the ' +
                'corresponding contact from CRM and unlinking the stale SMART reference.',
            example : ''
        },
        stakeholderRole : {
            title : 'Role on Project',
            description : 'Indicate what role the stakeholder played on this project. Required.',
            example : 'Owner\'s Representative, End-User, Designer, Architect of Record, Mechanical Contractor, etc.'
        },
        stakeholderComments : {
            title : 'Comments on Project',
            description : 'Further description of the stakeholder\'s role, performance, and/or capacity on this ' +
                'project. Not required.',
            example : ''
        },
        stakeholderSyncStatus : {
            title : 'Synced with CRM',
            description : 'If this is a project with old stakeholders ported over from SMART, then a red "X" ' +
                'indicates a stale stakeholder that came from SMART. These need to be updated by linking the ' +
                'corresponding account from CRM and unlinking the stale SMART stakeholder.',
            example : ''
        },
        stakeholderOwner : {
            title : 'Owner',
            description : 'If this stakeholder is the owner on the project, use this toggle to indicate it. The client ' +
                'info section at the top of the page will display the name &amp; location from this CRM account. There ' +
                'can be only one stakeholder marked "Owner", so if this toggle is disabled, be sure to (a) unlink any legacy ' +
                'Owner stakeholders or (b) unset the owner toggle on any other CRM-synced stakeholders, then refresh the page.',
            example : ''
        },
        diversityHbcuMinority : {
            title : '',
            description : 'Historically Black Colleges &amp; Universities',
            example : ''
        },
        diversityHubZone : {
            title : '',
            description : 'Historically under-utilized business zone. A designation from the federal government.',
            example : ''
        },
        diversityDav : {
            title : '',
            description : 'Disabled Veteran',
            example : ''
        },
        technologySection: {
            title: 'Technology Narratives',
            description: 'Use this section to record information about phase-specific technology usage, cost, and effort.',
            example: ''
        },
        technologyPhase: {
            title: 'Phase',
            description: 'Which phase of the project is this information for?',
            example: ''
        },
        technologies: {
            title: 'Technologies',
            description: 'Include all technologies that were used in this phase of the project. Click the "Technologies" ' +
                'button for more information on the technologies themselves.',
            example: ''
        },
        technologyWorkHours: {
            title: 'Work Hours',
            description: 'An estimate of the total work hours spent on the technology scopes during this phase.',
            example: ''
        },
        technologyImplementationCost: {
            title: 'Implementation Cost',
            description: 'A rough (within ~$10,000) estimate of the cost of the technologies during this phase of the ' +
                'project. Include device, software license, and other costs directly applicable to the selected technologies.',
            example: ''
        },
        technologyNarrative: {
            title: 'Technology Phase Narrative',
            description: 'During this phase of the project, what value did technology bring? How did we win owner\'s ' +
                'confidence, cut cost, improve schedule, safety, or quality? Be specific about what tools, processes, and ' +
                'innovations helped us achieve these.',
            example: ''
        },
        technologyInternalNarrative: {
            title: 'Overall Technology Narrative (Internal)',
            description: 'This field\'s purpose is to explain and clarify the additional value that technology brought to ' +
                'this project for an internal (Hensel Phelps) audience. Clearly indicate any intellectual property, so readers ' +
                'know what is sensitive.',
            example: ''
        },
        technologyExternalNarrative: {
            title: 'Overall Technology Narrative',
            description: 'This field\'s purpose is to explain and clarify the additional value that technology brought to ' +
                'this project for an external audience – e.g. proposals. Focus on telling the story of the project as a whole, ' +
                'using the phase narratives to dive into specifics on different phases. How did we cut cost, amaze the owner, improve ' +
                'schedule, safety, or quality? Mention any tools, processes, or innovations. Include publications, awards, ' +
                'partnerships, webinars, conferences, or anywhere else the technology scopes were recognized. Clearly indicate ' +
                'any intellectual property, so readers know what is sensitive.',
            example: ''
        },
        vdcTechnologySection : {
            title : 'PBM Technology',
            description : 'The "PBM Technology" section aims to list all applicable processes and technologies used on this ' +
                'project and use it as a data point reference for this particular project in the future.',
            example: ''
        },
        vdcTechnologyPhase : {
            title : 'PBM Technology Phase',
            description : 'Please use this section to add all processes & software used in this phase of this project. ',
            example: ''
        },
        vdcTechnologies : {
            title : 'Pick a PBM Technology',
            description : 'Pick the applicable PBM Technology process and/or technology used on this project fore use as a ' +
                'data point reference for this particular project in the future.',
            example: ''
        },
        vdcTechnologyStarRating : {
            title : 'Star Rating',
            description : '<p>Use this section to define the success or performance of this process/software on your project.</p>' +
                '<p>5 Star = Amazing, 4 Star = Good, 3 Star = Average, 2 Star =Below Average, 1 Star = Poor</p>',
            example: ''
        },
        vdcTechnologiesSme : {
            title : 'The Subject Matter Expert (SME)',
            description : 'The Subject Matter Expert (SME) is the individual responsible for implementing a particular process ' +
                'or software on the project. The field is linked to the Hensel Phelps employee database, so you can begin typing ' +
                'the name, and the software should be able to provide recommendations based on your input. ',
            example: ''
        },
        vdcTechnologyWorkHours : {
            title : 'Work Hours',
            description : 'Please enter the number of hours spent on this effort.',
            example: ''
        },
        vdcTechnologyDuration : {
            title : 'Duration',
            description : 'Please enter the total duration (days) spent on this effort.',
            example: ''
        },
        vdcTechnologyCost : {
            title : 'Cost',
            description : 'Please provide the total expenditure spent on this effort.',
            example: ''
        },
        vdcTechnologyNumPeople : {
            title : 'Number of People',
            description : 'Use this section to define the number of people involved in implementing this technology.',
            example: ''
        },
        vdcTechnologyNotes : {
            title : 'Notes',
            description : 'Please use this field to add any additional notes or comments you may have on this item. ' +
                'More information will be significantly helpful during future efforts.',
            example: ''
        },
        sustainability : {
            title : 'Sustainability',
            description : 'Add any sustainability certifications that this project is designed to meet, is pursuing, ' +
                'or has been achieved. LEED certifications should be entered here.',
            example : ''
        },
        sustainabilityNarrative : {
            title : 'Sustainability Narrative',
            description : 'Describe sustainability efforts on this project. What innovative or unique steps were taken? ' +
                'What challenges did we overcome to achieve any certifications?',
            example : ''
        },
        sustainabilityCertification : {
            title : 'Sustainability Certificatioin',
            description : 'Choose the certification standard (LEED, SITES, Fitwel, etc.) Required.',
            example : ''
        },
        sustainabilityCertificationVersion : {
            title : 'Sustainability Version',
            description : 'Choose the certification version. This is linked to certification types, ' +
                'so if the version you want is unavailable, try changing or unsetting the type.',
            example : ''
        },
        sustainabilityCertificationLevel : {
            title : 'Sustainability Level',
            description : 'Choose the certification level',
            example : ''
        },
        sustainabilityCertificationType : {
            title : 'Sustainability Type',
            description : 'Choose the certification type. This is linked to certification versions, ' +
                'so if the type you want is unavailable, try changing or unsetting the version.',
            example : ''
        },
        sustainabilityCertificationStatus : {
            title : 'Sustainability Status',
            description : 'Choose “Pursued” to indicate that this project did or is currently pursuing certification under ' +
                'the selected program. Choose “Achieved” to indicate this project has officially achieved the certification. ' +
                'Choose “Design Equivalent” to indicate that this project was designed to meet the selected standard, but chose ' +
                'not to pursue official certification. Required.',
            example : ''
        },
        sustainabilityCertificationAwardDate : {
            title : 'Sustainability Achieved Date',
            description : 'If the certification status is “Achieved”, indicate the date achieved here.',
            example : ''
        },
        sustainabilityCertificationAssets : {
            title : 'Sustainability Documenting Assets',
            description : 'Choose “Uploaded” once you have uploaded any assets that document this certification (e.g. LEED Scorecard). ' +
                'Choose “Not Applicable” if there are no assets to upload documenting this certification.',
            example : ''
        },
        supplierDiversityGoals : {
            title : 'Supplier Diversity Goals',
            description : 'Use this section to quantify any Supplier Diversity or Small Business goals and achievements that were ' +
                'tracked on the project, regardless of whether they were specified in the contract.',
            example : ''
        },
        supplierDiversityGoalClassifications : {
            title : 'Supplier Diversity Classifications',
            description : 'Pick the classification for this goal. Classifications are usually designated by a government authority, ' +
                'e.g. HBCU Minority from the federal government. ',
            example : ''
        },
        supplierDiversityInContract : {
            title : 'Contractual Supplier Diversity',
            description : 'Was tracking and reporting on this goal contractually obligated? ',
            example : ''
        },
        supplierDiversityGoalDollars : {
            title : 'Supplier Diversity Goal ($)',
            description : 'What was the original goal, in dollars? Zero may be entered if there was no goal. This is a number between 0-100.',
            example : ''
        },
        supplierDiversityAchievedDollars : {
            title : 'Supplier Diversity Achieved ($)',
            description : 'What was achieved, in dollars?',
            example : ''
        },
        supplierDiversityGoalPercent : {
            title : 'Supplier Diversity Goal (%)',
            description : 'What was the original goal, either as a percentage of the prime contract or as a percentage of ' +
                'subcontracted dollars (see Percentage Classification)? Zero may be entered if there was no goal. This needs to ' +
                'be a number between 0-100.',
            example : ''
        },
        supplierDiversityAchievedPercent : {
            title : 'Supplier Diversity Achieved (%)',
            description : 'What was achieved, either as a percentage of the prime contract or as a percentage of ' +
                'subcontracted dollars (see Percentage Classification)? This needs to be a number between 0-100.',
            example : ''
        },
        supplierDiversityPercentContext : {
            title : 'Supplier Diversity Percentage Classification',
            description : 'What did percentage refer to for this goal? Was it a percent of the prime contract, or a percent ' +
                'of subcontracted dollars?',
            example : ''
        },
        supplierDiversityPlans : {
            title: 'Supplier Diversity Plans',
            description: 'Use these toggles to indicate whether or not the project had the relevant plans. This will also control ' +
                'which narrative fields appear below.',
            example: 'If this project has a Small Business Participation plan and a Workforce Development plan, but no Community ' +
                'Engagement plan, you should turn on Small Business Participation and Workforce Development, and turn off Community ' +
                'Development.'
        },
        supplierDiversitySmallBusinessParticipationToggle : {
            title : 'Small Business Participation',
            description : 'Did this project have a Small Business Participation plan?',
            example : ''
        },
        supplierDiversitySmallBusinessParticipation : {
            title : 'Small Business Participation',
            description : 'Include a summary of the project\'s Small Business Participation plan.',
            example : ''
        },
        supplierDiversityWorkforceDevelopmentToggle : {
            title : 'Workforce Development',
            description : 'Did this project have a Workforce Development plan?',
            example : ''
        },
        supplierDiversityWorkforceDevelopment : {
            title : 'Workforce Development',
            description : 'Include a summary of the project\'s Workforce Development Participation plan.',
            example : ''
        },
        supplierDiversityCommunityEngagementToggle : {
            title : 'Community Engagement',
            description : 'Did this project have a Community Engagement plan?',
            example : ''
        },
        supplierDiversityCommunityEngagement : {
            title : 'Community Engagement',
            description : 'Include a summary of the project\'s Community Engagement plan.',
            example : ''
        },
        supplierDiversityNotes : {
            title : 'Supplier Diversity Notes',
            description : 'Use this  field for general purpose notes related to Supplier Diversity and Small Business on this project.',
            example : ''
        },
        finalPaymentDate : {
            title : 'Final Payment Date',
            description : 'Enter the date the final payment was received for the project.',
            example: ''
        },
        groundbreakingDate : {
            title : 'Groundbreaking Date',
            description : 'If applicable, enter the date the groundbreaking was performed for the project. Leave blank if the project did not include this milestone.',
            example: ''
        },
        toppingOutDate : {
            title : 'Topping Out Date',
            description : 'If applicable, enter the date the topping out was performed for the project. Leave blank if the project did not include this milestone.',
            example: ''
        },
        buildingEnclosedDate : {
            title : 'Building Enclosed Date',
            description : 'If applicable, enter the date the building was enclosed on the project. Leave blank if the project did not include this milestone.',
            example: ''
        },
        ribbonCuttingDate : {
            title : 'Ribbon Cutting Date',
            description : 'If applicable, enter the date the ribbon cutting was performed for the project. Leave blank if the project did not include this milestone.',
            example: ''
        },
        numHousingUnits : {
            title : 'Number of Housing Units',
            description : 'Enter the Number of units on the project. A housing unit is defined as a single apartment, condo, or "entity" that could include multiple bedrooms, kitchen, living rooms, etc.',
            example: ''
        },
        servicesSection : {
            title : 'Services',
            description : 'This section should only be filled out for Services projects.',
            example : ''
        },
        servicesFteCount : {
            title : 'FTE Count',
            description : 'FM projects require a Full Time Employee (FTE) staffing count – includes HPS employees and ' +
                'subcontractors managed under contract',
            example : ''
        },
        servicesBusinessUnit : {
            title : 'Business Unit',
            description : 'For Services Projects, please select the associated Business Unit for this project number: Facility ' +
                'Solutions, Building Systems Integration, Specialized Construction, or Facility Management',
            example : ''
        },
        linearFeet: {
            title: 'Linear Feet',
            description: 'Size of a Water/Wastewater project in terms of amount of piping.',
            example: ''
        },
        millionGallonsPerDay: {
            title: 'Million Gallons per Day (MGD)',
            description: 'Size of a Water/Wastewater project in terms of daily capacity.',
            example: ''
        }
    } as DashInfotipDict;

