import { getAdUserInfo } from 'config/microsoftGraph';
import useAdAccessToken from 'hooks/useAdAccessToken';
import useRoles from 'hooks/useRoles';
import { useEffect } from 'react';
import { useCurrentUserState } from 'recoil/currentUser';


const useSetCurrentUser = () => {
  const [, { setCurrentUserState }] = useCurrentUserState();
  const { roles } = useRoles();
  const { accessToken } = useAdAccessToken();

  useEffect(() => {
    if (accessToken) {
      getAdUserInfo(accessToken)
        .then((response) => {
          console.log({roles});

          setCurrentUserState({
            profilePicture: response?.userPhoto,
            displayName: response?.displayName,
            userPrincipalName: response?.userPrincipalName,
            email: response?.email,
            roles: roles,
          });
        })
        .catch((error) => {
          console.error('Error trying to retrieve AD user info from microsoft graph api: ', error);
        });
    }
  }, [accessToken, roles, setCurrentUserState]);
};

export default useSetCurrentUser;
