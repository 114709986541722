import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useGlobalState} from "./useGlobalState";
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import {Tooltip} from "@material-ui/core";



const UrlCaptureComponent: React.FC = () => {
  const gs = useGlobalState();
  const location = useLocation();

  useEffect(() => {
    gs.setUrl(location.pathname + location.search);
  }, [location]);

  return (
      <div className="hidden">
        <Tooltip title={'Current URL: ' + (gs.url ?? '')}>
          <DnsOutlinedIcon/>
        </Tooltip>
      </div>
  );
};

export default UrlCaptureComponent;
