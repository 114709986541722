import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';

interface DashIconButtonProps extends IconButtonProps {
  hidden?: boolean;
}

const DashIconButton: React.FC<DashIconButtonProps> = ({ hidden, children, ...props }) => {
  return hidden ? null : (
      <IconButton {...props}>
        {children}
      </IconButton>
  );
};

export default DashIconButton;
