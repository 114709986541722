import { createContext } from 'react';
import {AxiosCacheInstance, buildWebStorage, CacheOptions, setupCache} from "axios-cache-interceptor";
import Axios from "axios";

export const ServicesContext = createContext<ServiceContainer | null>(null);

class ServiceContainer {
  private axios: AxiosCacheInstance | undefined;
  private isCacheReady = false;

  constructor() {
    if (!this.isCacheReady) {
      const options: CacheOptions = {
        ttl: 2000 * 60,
        storage: buildWebStorage(localStorage, 'axios-cache:')
      }
      this.axios = setupCache(Axios, options);
      // this.axios.interceptors.request.use((config) => {
      //   // Assuming getToken() is a function that retrieves the latest token
      //   const token = this.getToken();
      //   config.headers.Authorization = `Bearer ${token}`;
      //   return config;
      // }, function (error) {
      //   // Do something with request error
      //   return Promise.reject(error);
      // });
    }
    this.isCacheReady = true;
  }

  getAxiosInstance(): AxiosCacheInstance {
    return this.axios as AxiosCacheInstance;
  }
}

export default new ServiceContainer();