import { useEffect } from 'react';

const ShortcutsComponent = () => {
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.altKey && e.code === 'KeyP') {
        const path = window.location.pathname + window.location.search;
        const command = prompt('Enter your command');
        switch (command) {
          case 'qaa': // QA "old" Angular
            window.open('http://qa-dash.henselphelps.io/#' + path, '_blank');
            break;
          case 'qar': // QA "new" React
            window.open('http://qa-dashnew.henselphelps.io' + path, '_blank');
            break;
        }
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return null;
};

export default ShortcutsComponent;