import React, { useState } from 'react';
import { Switch } from '@material-ui/core';
import { GreenCheck, RedX } from '../../components/dashCore/customIcons';

interface ISimpleApproval {
  approval: boolean;
  approvalModifiedBy?: string | null;
  approvalModifiedOn?: Date | null;
}

interface ISimpleApprovalProps extends ISimpleApproval {
  editMode?: boolean;
  label?: string;
  subject?: string;
  showLabelOnEdit: boolean;
  showLabelOnNonEdit: boolean;
  infoMode?: 'detailed' | 'minimal' | undefined,
  style?: React.CSSProperties;
}

const SimpleApproval: React.FC<ISimpleApprovalProps> = ({
                                                          approval,
                                                          approvalModifiedBy,
                                                          approvalModifiedOn,
                                                          editMode = false,
                                                          label,
                                                          subject,
                                                          showLabelOnNonEdit,
                                                          showLabelOnEdit,
                                                          infoMode,
                                                          style
                                                        }) => {
  const [hiddenApproval, setHiddenApproval] = useState(approval);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHiddenApproval(event.target.checked);
  };

  const displayApproval = () => {
    if (editMode) {
      return (
          <Switch
              color="primary"
              checked={hiddenApproval}
              onChange={handleSwitchChange}
              inputProps={{ 'aria-label': 'controlled' }}
          />
      );
    }

    return hiddenApproval ? <GreenCheck /> : <RedX />;
  };

  // const displayModifiedInfo = () => {
  //   if (!editMode && approvalModifiedBy && approvalModifiedOn) {
  //     const formattedDate = new Date(approvalModifiedOn).toLocaleDateString();
  //
  //     if (infoMode === 'minimal') {
  //       return <span className="font-bold">
  //         {subject} not approved.
  //       </span>
  //     }
  //
  //     return (
  //         <span className="font-bold">
  //         {subject} approved by {approvalModifiedBy} on {formattedDate}
  //       </span>
  //     );
  //   }
  //   return null;
  // };

  const displayModifiedInfo = () => {
    if (!editMode && approvalModifiedBy && approvalModifiedOn) {
      const formattedDate = new Date(approvalModifiedOn).toLocaleDateString();
      const approvalText = !hiddenApproval
          ? `${subject} not approved.`
          : `${subject} approved by ${approvalModifiedBy} on ${formattedDate}`;

      return <span className="font-bold">{approvalText}</span>;
    }
    return null;
  };

  const showLabel = editMode ? showLabelOnEdit : showLabelOnNonEdit;

  return (
      <>
        <div className="flex flex-col" style={style}>
          <div className="flex flex-row justify-content-start">
            {label && showLabel && <div className={'font-bold'}>{label}</div>}
            {/* displayApproval only handles the GreenCheck or RedX*/}
            {displayApproval()}
            {displayModifiedInfo()}
          </div>
        </div>
        {editMode && (
            <>
              <input type="hidden" name="approval" data-value-type="boolean" value={hiddenApproval.toString()} />
              {/*user principle name and modified date are handled by the API*/}
            </>
        )}
      </>
  );
};

export default SimpleApproval;
