import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Rating} from "@material-ui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  GridSize,
  MenuItem,
  Switch
} from "@material-ui/core";
import VdcTechTreeView from "./VdcTechTreeView";
import TextField from "@material-ui/core/TextField";
import DashDivider from "../../../DashDivider";
import {ApiPhaseVdcTechnology, IApiPhaseVdcTechnology} from "services/apiClients/AthenaClient";
import {getNamedData} from "utils/forms";
import useAthenaClient from "../../../../hooks/useAthenaClient";

// interface Props {
//   title: string;
//   description: string;
//   image: string;
// }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
      },
      image: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
    }),
);

function createTextField(name: string,
                         defaultValue?: string | unknown,
                         label?: string,
                         required?: boolean,
                         sizeProps?: { xs?: GridSize, sm?: GridSize, md?: GridSize } | undefined,
                         onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
                         infotipKey?: string) {
  const comp = (
      <Grid item {...sizeProps}>
        <TextField name={name} defaultValue={defaultValue}
                   label={label} fullWidth
                   InputLabelProps={{
                     shrink: true,
                   }}
                   required={required} onChange={onChange}
        />
      </Grid>);

  return comp;
}

interface IAddEditPBMTechDialogProps {
  dialogMode?: string | null;
  model?: IApiPhaseVdcTechnology | Record<string, unknown> | null;
  onAction: (action: string, payload?: ApiPhaseVdcTechnology | string | null | Record<string, unknown>) => void;
}

function AddEditPBMTechDialog(props: IAddEditPBMTechDialogProps) {
  const classes = useStyles();
  const {athenaClientRef} = useAthenaClient();
  const isNew = props.dialogMode === 'new';
  const [showTechPicker, setShowTechPicker] = useState(isNew);
  const [vdcTechnologyId, setVdcTechnologyId] = useState<string | undefined>(
      isNew ? undefined : props.model?.vdcTechnologyId);
  const [vdcTechnologyPath, setVdcTechnologyPath] = useState<string | undefined>(
      isNew ? undefined : props.model?.path as string);

  console.log('!@! test inst', {isNew, vdcTechnologyId, vdcTechnologyPath});

  // useEffect(() => {
  //   async function getTree() {
  //
  //     const tmpResult = await athenaClientRef.current?.getVdcTechnologies();
  //     console.log('!@@@! treeModel', tmpResult);
  //     setTreeModel(tmpResult);
  //   }
  //
  //   if (athenaClientRef.current && !) {
  //     getTree();
  //   }
  // }, [athenaClient, treeModel])


  function handleVdcTechSelect(id: string, path: string) {
    console.log('!@@@! handleVdcSelect', {id, path});
    setVdcTechnologyId(id);
    setVdcTechnologyPath(path);
    setShowTechPicker(false);
  }

  function chooseAgain() {
    setVdcTechnologyId(undefined);
    setVdcTechnologyPath(undefined);
    setShowTechPicker(true);
  }

  const companyCategories = ['3rd Party', 'Trade Led'];

  function handleSave() {
    const data = getNamedData(document.querySelector('#' + 'addOrEditVdcTechDialog'));
    const tmpPhaseVdcTechnology = data as Record<string, unknown>;
    const idTest = (tmpPhaseVdcTechnology.id ?? false)
    props.onAction(idTest ? 'update' : 'create', tmpPhaseVdcTechnology);
  }

  return (
      <>
        {!!props.dialogMode &&
            <Dialog id="addOrEditVdcTechDialog" fullWidth={true} maxWidth={'lg'}
                    open={!!props.dialogMode} className="bg-black text-white">
                <DialogTitle>{props.dialogMode === 'new' ? 'Add' : 'Edit'} Technology</DialogTitle>
                <DialogContent>
                    <div style={{paddingBottom: '30px'}}>
                        <Grid container spacing={5} style={{width: '100%'}}>
                            <Grid item xs={6}>
                                <div style={{width: '95%', height: '260px', overflowY: 'auto'}}>
                                    <input type="hidden"
                                           name="id"
                                           value={props.model?.id}/>
                                    <input type="hidden" id="vdcTechnologyId"
                                           name="vdcTechnologyId"
                                           value={vdcTechnologyId}/>
                                    <input type="hidden"
                                           id="vdcTechnologyPath" value={vdcTechnologyId}/>

                                  {showTechPicker ?
                                      <VdcTechTreeView onSelect={handleVdcTechSelect}/> :
                                      (<div>
                                        <span>{vdcTechnologyPath}</span><br/>
                                        <br/>
                                        <Button onClick={chooseAgain}>Select Different Technology</Button>
                                      </div>)
                                  }
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Rating id="starRating" value={props.model?.starRating as number | undefined}
                                        name="starRating"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                Hensel Phelps? <Switch name={'isHenselPhelps'} color="primary"
                                                       defaultValue={undefined}/>
                            </Grid>
                          {createTextField('smeCompany', props.model?.smeCompany, 'Company', false, {xs: 6})}
                          {createTextField('smePosition', props.model?.smePosition, 'Position', false, {xs: 6})}

                            <Grid item xs={12}>
                                <TextField name={'companyCategory'}
                                           defaultValue={props.model?.companyCategory}
                                           label={'Company Category'}
                                           InputLabelProps={{
                                             shrink: true,
                                           }}
                                           select
                                           fullWidth>
                                  {companyCategories.map((name, index) => (
                                      <MenuItem key={index} value={name}>{name}</MenuItem>
                                  ))}
                                </TextField>
                            </Grid>
                          {createTextField('smeFirstName', props.model?.smeFirstName, 'First Name', false, {xs: 6})}
                          {createTextField('smeLastName', props.model?.smeLastName, 'Last Name', false, {xs: 6})}
                          {createTextField('smePhone', props.model?.smePhone, 'Phone Number', false, {xs: 6})}
                          {createTextField('smeEmail', props.model?.smeEmail, 'Email', false, {xs: 6})}
                            <Grid item xs={12}>
                                <DashDivider width={'80%'} height={'1px'} backgroundColor={'#eee'}/>
                            </Grid>
                          {createTextField('workHours', props.model?.workHours, 'Work Hours', false, {xs: 6})}
                          {createTextField('duration', props.model?.duration, 'Duration', false, {xs: 6})}
                          {createTextField('cost', props.model?.cost, 'Cost', false, {xs: 6})}
                          {createTextField('numPeople', props.model?.numPeople, 'Number of People', false, {xs: 6})}
                        </Grid>
                    </div>

                </DialogContent>
                <DialogActions>
                    <div className="flex flex-row" data-example="ability to reverse order later with css">
                        <div>

                            <Button onClick={handleSave}>Save</Button>
                        </div>
                        <div>
                            <Button onClick={() => props.onAction('close')}>Cancel</Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        }
      </>
  );
}

export default AddEditPBMTechDialog;
