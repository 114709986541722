import {withDashCard} from '../../shared/withDashCard';
import ChallengeSolutionSection from "./ChallengeSolutionSection";

const ProjectChallengesAndSolutionsCard = withDashCard('Challenges & Solutions', 'project-challenges-module',
    // list of components to add to the card (in order they should appear)
    [
      ChallengeSolutionSection,
    ]
);

export default ProjectChallengesAndSolutionsCard;