import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import { FC } from 'react';

type ReloadWindowButtonProps = {
  callback?: () => void;
};

const ReloadWindowButton: FC<ReloadWindowButtonProps> = ({ callback }) => {
  const reload = () => {
    window.location.reload();
  };
  const action = callback ?? reload;
  return <StyledButtonPrimary color="primary" label={'Reload'} onClick={action} />;
};

export default ReloadWindowButton;
