import {withDashCard} from '../../shared/withDashCard';
import PBMTechnologySection from "./PBMTechnologySection";

const PBMTechnologyCard = withDashCard('PBM Technology', 'pbm-technology-module',
    // list of components to add to the card (in order they should appear)
    [
      PBMTechnologySection,
    ]
, 'vdcTechnologySection');

export default PBMTechnologyCard;