
export const addProjectFormSettings = `
  type|name|label|required_b|disabled_b|choicesKey
  TextField.textField|name|Project Name|true|true
  TextField.textField|personTitleOnProject|Title on Project|true|false
  TextField.textField|clientName|Client Name|false|true
  TextField.textField|constructionActualSubstantialCompletionDate|Completion Date|false|true
  TextField.textField|city|City|false|true
  TextField.textField|state|State|false|true
  TextField.textField|verticalMarket|Vertical Market|false|true
  TextField.textField|deliveryMethod|Delivery Method|false|true
  TextField.textField|contractType|Contract Type|false|true
  MoneyField.textField|cost|Published Cost|false|true
  NumberField.textField|squareFootage|Square Footage|false|true
`;

export const editProjectFormSettings = `
  type|name|label|required_b|disabled_b|choicesKey
  TextField.textField|name|Project Name|true|true
  TextField.textField|personTitleOnProject|Title on Project|true|false
  TextField.textField|clientName|Client Name|false|true
  TextField.textField|constructionActualSubstantialCompletionDate|Completion Date|false|true
  TextField.textField|city|City|false|true
  TextField.textField|state|State|false|true
  TextField.textField|verticalMarket|Vertical Market|false|true
  # does not exist in original DASH - API needed to cleanup this moving forward
  #TextField.textField|deliveryMethod|Delivery Method|false|true
  #TextField.textField|contractType|Contract Type|false|true
  MoneyField.textField|cost|Published Cost|false|true
  NumberField.textField|squareFootage|Square Footage|false|true
`;

export const addIndustryProjectFormSettings = `
  type|name|label|required_b|disabled_b|choicesKey
  TextField.textField|name|Project Name|true|false
  TextField.textField|personTitleOnProject|Title on Project|true|false
  TextField.textField|clientName|Client Name|true|false
  DateField.textField|constructionActualSubstantialCompletionDate|Completion Date|false|false
  TextField.textField|city|City|true|false
  TextField.textField|state|State|true|false|states
  TextField.textField|verticalMarket|Vertical Market|true|false|verticalMarkets
  TextField.textField|deliveryMethod|Delivery Method|false|false|deliveryMethods
  TextField.textField|contractType|Contract Type|false|false|contractTypes
  MoneyField.textField|cost|Published Cost|true|false
  NumberField.textField|squareFootage|Square Footage|false|false
`;