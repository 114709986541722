import {ExportRequestBody, ExportSchema, IExportRequestBody, IExportSchema} from "services/apiClients/AthenaClient";


export function onlyDefaultProjectSchema(node: IExportSchema): IExportSchema | undefined {
  const allowed = [
    'name',
    // 'streetAddress',
    'city',
    // 'state',
    // 'zip',
    'districts', // will change label value to Regions
    'cost', // will change label value to PublishedCost
    'squareFootage',
    'completionDate',
    'verticalMarket'
  ];

  if (node.label === 'Projects' && node.type === 'array') {
    if (node.items?.label === 'Project') {
      const inode = node.items;
      if (inode.properties && inode.properties.length > 0) {
        const filteredProperties = inode.properties.filter(x => allowed.includes(x.propertyName ?? ''));

        // Order the filtered properties according to the order in the 'allowed' array
        filteredProperties.sort((a, b) => allowed.indexOf(a.propertyName ?? '') - allowed.indexOf(b.propertyName ?? ''));

        for (let i = 0; i < filteredProperties.length; i++) {
          if (filteredProperties[i].propertyName === 'districts') {
            filteredProperties[i].items = undefined;
            filteredProperties[i].label = 'Regions';
            filteredProperties[i].type = 'string';
          }
          if (filteredProperties[i].propertyName === 'cost') {
            filteredProperties[i].label = 'PublishedCost';
          }
          if (filteredProperties[i].propertyName === 'city') {
            filteredProperties[i].propertyName = 'location';
            filteredProperties[i].label = 'Location';
          }
        }

        console.log('!@@! filteredProperties', filteredProperties);
        node.items.properties = filteredProperties;
      }
    }
  }
  console.log('!@#- inside exportUtil - ', node);
  return node;
}


export function onlyDefaultPeopleSchema(node: IExportSchema): IExportSchema | undefined {
  const allowed = [
    'name',
    'title',
    'city',
    'hireDate',
    'terminationDate',
    'totalProjectValue',
    'totalSquareFootage'
  ];

  if (node.label === 'People' && node.type === 'array') {
    console.log('reached 1');
    if (node.items?.label === 'Person') {
      console.log('reached 2');
      const inode = node.items;
      if (inode.properties && inode.properties.length > 0) {
        const filteredProperties = inode.properties.filter(x => allowed.includes(x.propertyName ?? ''));

        // Order the filtered properties according to the order in the 'allowed' array
        filteredProperties.sort((a, b) => allowed.indexOf(a.propertyName ?? '') - allowed.indexOf(b.propertyName ?? ''));

        for (let i = 0; i < filteredProperties.length; i++) {
          if (filteredProperties[i].propertyName === 'name') {
            filteredProperties[i].label = 'Name';
          }
          if (filteredProperties[i].propertyName === 'title') {
            filteredProperties[i].label = 'JobTitle';
          }
          // if (filteredProperties[i].propertyName === 'cost') {
          //   filteredProperties[i].label = 'PublishedCost';
          // }
          if (filteredProperties[i].propertyName === 'city') {
            filteredProperties[i].propertyName = 'location';
            filteredProperties[i].label = 'Location';
          }
        }

        console.log('!@@! filteredProperties', filteredProperties);
        node.items.properties = filteredProperties;
      }
    }
  }
  return node;
}

export function makeExportRequestBody(exportSchema: ExportSchema, athenaQuerySyntax: string, fileType?: string | null) {
  console.log('!@@! enter makeExportRequestBody')
  const mimeType = (fileType === 'xlsx')
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'application/xml';

  const exportRequest = {
    fileType: mimeType,
    records: athenaQuerySyntax,
    schema: exportSchema,
  } as IExportRequestBody;

  return new ExportRequestBody(exportRequest);
}