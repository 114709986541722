import {withDashCard} from '../../shared/withDashCard';
import SustainabilityCertificationsSection from "./CertificationsSection";
import SustainabilityNarrativeSection from "./SustainabilityNarrativeSection";
import LeedSection from "./LeedSection";

const ProjectSustainabilityCard = withDashCard('Project Sustainability', 'project-sustainability-module',
    // list of components to add to the card (in order they should appear)
    [
      SustainabilityCertificationsSection,
      SustainabilityNarrativeSection,
      LeedSection,
    ]
);

export default ProjectSustainabilityCard;