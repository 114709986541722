import {
  IconButton,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core'
import GoalsTableHead from './GoalsTableHead'
import GoalsTableRows from './GoalsTableRows'
import {
  ApiProject,
  ApiSupplierDiversityGoal,
  IApiProject,
} from 'services/apiClients/AthenaClient'
import SelectClassificationMenu from './SelectionClassification'
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import useAthenaClient from 'hooks/useAthenaClient'
import {
  IWithDashSectionMethods,
  IWithDashSectionProps,
} from '../../../shared/withDashSection'
import { getNamedData } from '../../../../../utils/forms'
import { v4 as uuidv4 } from 'uuid'
import { GreenCheck, RedX } from '../../../../dashCore/customIcons'
import { RemoveCircleOutline } from '@material-ui/icons'
import useReferenceDataForProjects from 'hooks/useFieldOptions/useReferenceDataForProjects'
import DashDivider from '../../../../DashDivider'

interface IGoalTableProps {
  editMode?: boolean
  //goals?: ApiSupplierDiversityGoal[],
  projectId?: string
}

export interface IGoalTableMethods {
  saveTable: () => void
}

const GoalsTable = forwardRef<IGoalTableMethods, IGoalTableProps>(
  (props, forwardRef: ForwardedRef<IGoalTableMethods>) => {
    const { athenaClient } = useAthenaClient()
    const referenceData = useReferenceDataForProjects()
    const editMode = props.editMode ?? false
    //const [goals, setGoals] = useState<ApiSupplierDiversityGoal[] | null>(null);
    const [project, setProject] = useState<ApiProject | null>(null)
    const [classificationWidth, setClassificationWidth] = React.useState('22%')
    const [goals, setGoals] = useState([] as ApiSupplierDiversityGoal[])
    const [initProject, setInitProject] = useState<ApiProject | null>(null)
    const [initGoals, setInitGoals] = useState([] as ApiSupplierDiversityGoal[])
    const [initClassifications, setInitClassifications] = useState<Record<
      string,
      string
    > | null>(null)

    const SECTION_ID = 'supplierDiversityGoalsSection'

    useEffect(() => {
      setClassificationWidth(props.editMode ? '17%' : '22%')

      if (props.editMode === false) {
        setProject(initProject)
        setGoals(initGoals)
        setClassifications(initClassifications)
      }
    }, [props.editMode])

    const [classifications, setClassifications] = useState<Record<
      string,
      string
    > | null>(null)
    useEffect(() => {
      setClassificationWidth(props.editMode ? '17%' : '22%')
    }, [props.editMode])

    useEffect(() => {
      async function loadData() {
        const tmpProject = (await athenaClient?.getProject(
          props.projectId as string,
        )) as ApiProject
        console.log('!@! project.goals', tmpProject.supplierDiversityGoals)
        setProject(tmpProject)
        setInitProject(tmpProject)
        setGoals(tmpProject.supplierDiversityGoals)
        setInitGoals(tmpProject.supplierDiversityGoals)

        const tmpClassifications = await athenaClient?.getSupplierDiversityClassifications()
        const tmpClassDict: Record<string, string> = {}
        if (tmpClassifications) {
          for (let i = 0; i < tmpClassifications.length; i++) {
            const id = tmpClassifications[i].id as string
            tmpClassDict[id] = tmpClassifications[i].name as string
          }
        }
        setClassifications(tmpClassDict)
        setInitClassifications(tmpClassDict)

        console.log('!@! classifications', tmpClassDict)
      }

      if (athenaClient && props.projectId) {
        loadData()
      }
    }, [athenaClient])

    useImperativeHandle(forwardRef, () => ({
      saveTable: async () => {
        console.log('!@@! begin saveTable - json')

        athenaClient?.syncSupplierDiversityGoalsBulk(
          project?.id as string,
          goals,
        )

        const data = { id: project?.id, supplierDiversityGoals: goals }

        await athenaClient?.patchProject(data)
        setGoals(goals)
      },
    }))

    function handleClassificationSelect(classificationId: string) {
      if (classifications) {
        console.log(
          '!@@! classification selected',
          classificationId,
          classifications[classificationId],
        )
        if (classificationId) {
          const newSDG = {
            classificationId,
            classificationName: classifications[classificationId],
          } as ApiSupplierDiversityGoal
          const tmpSDG = [...(goals ?? []), newSDG]
          setGoals(tmpSDG)
        }
      }
    }
    function handleRowDelete(classificationId: string) {
      const updatedGoals = [...goals]
      console.log('updated goals', goals)
      const index = updatedGoals.findIndex(
        (goal) => goal.classificationId === classificationId,
      )
      if (index !== -1) {
        updatedGoals.splice(index, 1)
        setGoals(updatedGoals)
        console.log('new goals', updatedGoals)
      } else {
        console.log('fail', classificationId)
      }
    }

    const currentGoalIds = goals
      ? goals.map((x) => x.classificationId as string)
      : ([] as string[])

    function updateGoal(
      goalState: ApiSupplierDiversityGoal,
      fieldUpdate: Record<string, unknown>,
    ) {
      const nextState = { ...goalState, ...fieldUpdate } as Record<
        string,
        unknown
      >
      const index = goals.findIndex(
        (goal) => goal.classificationId === goalState.classificationId,
      )
      const nextGoals = [...goals]
      nextGoals.splice(
        index,
        1,
        (nextState as unknown) as ApiSupplierDiversityGoal,
      )
      setGoals(nextGoals)
      console.log('!@@! next Goals: idx ' + index, nextGoals)
    }

    return (
      <div style={{ width: '90vw' }}>
        <Table style={{ width: '100%' }}>
          <GoalsTableHead editMode={editMode} />
          <TableBody>
            {goals &&
              goals.map((goal, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: classificationWidth }}>
                    {goal.classificationName}
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    {props.editMode ? (
                      <Switch
                        color={'primary'}
                        checked={goal.inContract}
                        onChange={(e) =>
                          updateGoal(goal, { inContract: e.target.checked })
                        }
                      />
                    ) : goal.inContract ? (
                      <GreenCheck />
                    ) : (
                      <RedX />
                    )}
                  </TableCell>
                  <TableCell style={{ width: '16%' }}>
                    {props.editMode ? (
                      <TextField
                        defaultValue={goal.goalDollars}
                        fullWidth
                        onChange={(e) =>
                          updateGoal(goal, { goalDollars: e.target.value })
                        }
                        inputProps={{ min: 0 }}
                        type="number"
                      />
                    ) : (
                      goal.goalDollars
                    )}
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    {props.editMode ? (
                      <TextField
                        defaultValue={goal.achievedDollars}
                        onChange={(e) =>
                          updateGoal(goal, { achievedDollars: e.target.value })
                        }
                        fullWidth
                        inputProps={{ min: 0 }}
                        type="number"
                      />
                    ) : (
                      goal.achievedDollars
                    )}
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    {props.editMode ? (
                      <TextField
                        defaultValue={goal.goalPercent}
                        onChange={(e) =>
                          updateGoal(goal, { goalPercent: e.target.value })
                        }
                        fullWidth
                        inputProps={{ min: 0, max: 100 }}
                        type="number"
                      />
                    ) : (
                      goal.goalPercent
                    )}
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    {props.editMode ? (
                      <TextField
                        defaultValue={goal.achievedPercent}
                        onChange={(e) =>
                          updateGoal(goal, { achievedPercent: e.target.value })
                        }
                        fullWidth
                        inputProps={{ min: 0, max: 100 }}
                        type="number"
                      />
                    ) : (
                      goal.achievedPercent
                    )}
                  </TableCell>
                  <TableCell style={{ width: '22%' }}>
                    {/*     <TextField defaultValue={goal.percentContext}*/}
                    {/*                onChange={(e) => updateGoal(goal, {'percentContext':e.target.value})}*/}
                    {/*                fullWidth />*/}
                    {props.editMode ? (
                      <Select
                        onChange={(e) =>
                          updateGoal(goal, { percentContext: e.target.value })
                        }
                        label="State"
                        defaultValue={goal.percentContext}
                      >
                        {referenceData.options[
                          'percentageClassifications'
                        ]?.map((x) => (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      goal.percentContext
                    )}
                  </TableCell>
                  <TableCell>
                    {props.editMode ? (
                      <IconButton
                        onClick={() =>
                          handleRowDelete(goal.classificationId as string)
                        }
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <DashDivider width="65vw" height="5px" />

        <SelectClassificationMenu
          hide={!editMode}
          classifications={classifications ?? {}}
          currentGoalIds={currentGoalIds}
          onSelect={handleClassificationSelect}
        />
      </div>
    )
  },
)

export default GoalsTable
