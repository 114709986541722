import {FacetMemberInfo, IAutocompleteHelper, IFacetFieldInfo} from "types/searchTypes";
import {ISearchableFacetItem} from "./localTypes";
import {
    AwardSponsorAutocompleteHelper,
    LocationAutocompleteHelper,
    NormalAutocompleteHelper,
    TechnologiesAutocompleteHelper,
    VdcTechnologiesAutocompleteHelper,
} from './helpers';

export default class AutocompleteSearcher {
    private autocompleteHelper: IAutocompleteHelper;
    private readonly searchableFacetItems: ISearchableFacetItem[];

    constructor(fieldName: string, members: IFacetFieldInfo[]) {
        this.autocompleteHelper = this.getAutocompleteHelper(fieldName);
        this.searchableFacetItems = this.makeFacetMembersSearchable(members); // fix
    }

    getAutocompleteHelper(fieldName: string): IAutocompleteHelper {
        switch (fieldName) {
            case 'location': return new LocationAutocompleteHelper();               // Specialized
            case 'awardSponsors': return new AwardSponsorAutocompleteHelper();       // Specialized
            case 'stakeholders': return new NormalAutocompleteHelper();             // Normal
            case 'schools': return new NormalAutocompleteHelper();                  // Normal
            case 'supplierDiversityGoals': return new NormalAutocompleteHelper();    // Normal
            //case 'supplierDiversity': return new NormalAutocompleteHelper();        // Normal
            case 'technologies': return new TechnologiesAutocompleteHelper();       // Specialized
            case 'vdcTechnologies': return new VdcTechnologiesAutocompleteHelper(); // Specialized
            default: throw new Error("No autocomplete helper found for field: " + fieldName);
        }
    }

    public makeFacetMembersSearchable(facetMembers: FacetMemberInfo[]): ISearchableFacetItem[] {
        return this.autocompleteHelper.updateSearchValues(
            this.autocompleteHelper.updateDisplayNames(facetMembers));
    }

    public search(searchText: string) {
        return filterBySearchText(this.searchableFacetItems);

        function filterBySearchText(facetItems: ISearchableFacetItem[]) {
            return facetItems.filter(matchesSearchText);
        }

        function matchesSearchText(facetItem: ISearchableFacetItem) {
            return facetItem.searchValues.some((searchValue: string) => {
                return searchValue.toLowerCase().startsWith(searchText);
            });
        }
    }
}