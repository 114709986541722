import { useState } from 'react';
import { projectInfotips, formatInfotip } from "constants/metadata/infotips";
import { Dialog } from "@material-ui/core";

function useInfotipModal() {
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState('');

  const showInfotipModal = (key: string) => {
    const infotip = projectInfotips[key];
    setContent(formatInfotip(infotip));
    setShowModal(true);
  }

  const hideInfotipModal = () => {
    setShowModal(false);
  }

  const InfotipModal = () => (
      <Dialog
          open={showModal}
          onClose={hideInfotipModal}>
        <div className="innerDialogPadding">
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Dialog>
  );

  return { showInfotipModal, InfotipModal };
}

export default useInfotipModal;
