import {FacetSettingsInfo, FilterOperator} from 'types/searchTypes';
import * as su from "utils/stringUtils";

//example filter
const filter: any = {
  cost: {
    operator: 'between',
    values: ['100000', '500000']
  },
  verticalMarket: {
    operator: 'oneof',
    values: ['Aerospace', 'Education', 'Hospitality']
  }
};

export default class AthenaFilterTranslator {

  translate(filterDict: { [field: string]: FacetSettingsInfo }): string {
    let result = '';
    const fieldNames: string[] = Object.keys(filterDict);
    if (fieldNames.length > 0) {
      const fields = fieldNames.map(k => this.translateField(filterDict[k]));
      const translated = `(${fields.join(') and (')})`;
      //result = encodeURIComponent(translated);
      result = translated;
    }
    //console.log('translated', translated)
    return result;
  }

  private translateField(facetSettingsInfo: FacetSettingsInfo): string {
    //console.log('translateField:', facetSettingsInfo);
    const fieldName = facetSettingsInfo.field;
    const filterOp = facetSettingsInfo.mode as FilterOperator;
    const fieldTranslator = this.getTranslator(filterOp);
    let translatedFld = fieldTranslator.translate(fieldName, facetSettingsInfo.values.map(x => x.toString()));
    if (translatedFld === `nonHenselPhelpsProject eq 'false'`) return translatedFld = `nonHenselPhelpsProject eq false`;
    //console.log('aft', {translatedFld});
    return translatedFld;
  }

  private getTranslator(op: FilterOperator): FieldTranslatorBase {
    switch (op) {
      case "allof":
        return new AllOfTranslator();
      case "anyof":
        return new AnyOfTranslator();
      case "oneof":
        return new OneOfTranslator();
      case "between":
        return new BetweenTranslator();
    }
    return new NoneTranslator();
  }

}

// supporting classes below

abstract class FieldTranslatorBase {
  abstract translate(fieldName: string, queryValues: string[]): string

  protected escapeFilterClauses(fieldName: string, queryValues: string[]) {
    const filterClauses = queryValues.map(qv => {
      const eqv = su.escapeSingleQuotes(qv);
      return `${fieldName}/any(f: f eq '${eqv}')`;
    });
    return filterClauses;
  }
}

class AllOfTranslator extends FieldTranslatorBase {
  translate(fieldName: string, queryValues: string[]): string {
    const filterClauses = this.escapeFilterClauses(fieldName, queryValues);
    return filterClauses.join(' and ');
  }
}

class AnyOfTranslator extends FieldTranslatorBase {
  translate(fieldName: string, queryValues: string[]): string {
    const filterClauses = this.escapeFilterClauses(fieldName, queryValues);
    return filterClauses.join(' or ');
  }
}

class OneOfTranslator extends FieldTranslatorBase {
  translate(fieldName: string, queryValues: string[]): string {
    const subClauses = queryValues.map(qv => this.fieldEqQueryValue(fieldName, qv));
    return subClauses.join(' or ');
  }

  private fieldEqQueryValue(fieldName: string, queryValue: string): string {
    if (!su.stringIsBoolean(queryValue)) {
      queryValue = su.escapeSingleQuotes(queryValue);
    }
    return `${fieldName} eq '${queryValue}'`;
  }
}

class BetweenTranslator extends FieldTranslatorBase {
  translate(fieldName: string, queryValues: string[]): string {
    const begin = queryValues[0];
    const end = queryValues[1];
    const conds = [] as string[];
    if (begin?.trim() !== '') conds.push(`${fieldName} ge ${begin}`);
    if (end?.trim() !== '') conds.push(`${fieldName} le ${end}`);
    return conds.join(' and ');
    //return `${fieldName} ge ${begin} and ${fieldName} le ${end}`;
  }
}

class NoneTranslator extends FieldTranslatorBase {
  translate(fieldName: string, queryValues: string[]): string {
    throw new Error("Used the none translator: with " + fieldName + " values " + queryValues.length);
  }
}
