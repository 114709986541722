import React, {ForwardedRef, forwardRef, useImperativeHandle, useRef, useState} from "react";
import {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {formatInfotip, projectInfotips} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
import GoalsTable, {IGoalTableMethods} from "./goalsRelated/GoalsTable";
// end imports

const SECTION_ID = 'supplierDiversityGoalsSection';

const SupplierDiversityGoalsSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {

      const classes = useSharedLargeCardStyles();
      const ref = useRef<IGoalTableMethods>(null);
      const [model, setModel] = useState<IApiProject>(props.model);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());

      if (model === null) {
        setModel(props.model);
      }


      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          try {
            console.log('!@@! begin ' + SECTION_ID + ' save');
            if (ref.current) {
              await new Promise(resolve => setTimeout(resolve, 2000));
              ref.current.saveTable();
            }
            return true;
          } catch (error: unknown) {
            alert(`An error occurred: ${(error as Error).message}`);
            return false;
          }
        }
      }));


      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (!props.model) {
        return <DashItemProgress/>;
      }

      return (
          <React.Fragment key={sectionKey}>
            <GoalsTable editMode={props.editMode} projectId={model.id} ref={ref}/>

            <DashDivider width="80vw" height="5px"/>
          </React.Fragment>
      );
    });

export default withDashSection('Goals', SECTION_ID, SupplierDiversityGoalsSection,
    false, 'supplierDiversityGoals');
