import './index.scss';
import 'reflect-metadata';
import {MsalProvider} from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import App from 'components/App';
import AzureAdProtector from 'components/AzureAdProtector';
import CacheBuster from 'components/CacheBuster';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import {getMsalInstance} from 'config/msalConfig';
import {detect} from 'detect-browser';
import UnsupportedBrowserDisclaimerPage from 'pages/UnsupportedBrowserDisclaimerPage';
import PancakeThemeProvider from 'providers/PancakeThemeProvider';
import ToastProvider from 'providers/ToastProvider';
import React, {Suspense, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';

//import {SearchContext} from './app/searchContext';
import ServiceContainer, {ServicesContext} from './app/servicesContext';
import {ENV} from './constants';
import CoreDebug from './features/core/CoreDebug';
import {ExportAndSchemaProvider} from './features/export/ExportAndSchemaProvider';
import DashProvider from './providers/DashProvider';
import InfotipProvider from './providers/InfotipProvider';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {GlobalStateProvider} from "./app/state/useGlobalState";
import CounterComponent from "./app/Reactive/CounterComponent";
import ClearPage from "./pages/ClearPage";
import Blank from "./pages/Blank";


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://aa393e21fc9945f09864aa4fe875be61@o322387.ingest.sentry.io/6012703',
    environment: ENV,
    tracesSampleRate: 0,
  });
}

const msalInstance = getMsalInstance();

const target = document.getElementById('root');
const browser = detect();

CoreDebug.publish('reactVersion', React.version);
const search = window.location.search;
const params = new URLSearchParams(search);
const clearParam = params.get('clear');
console.log('$$$$$$ cleanParam', {search, params, clearParam});

if (browser && browser.name === 'ie') {
  ReactDOM.render(
      <Suspense fallback={<LoadingSpinner isFullScreen/>}>
        <UnsupportedBrowserDisclaimerPage/>
      </Suspense>,
      target,
  );
} else if (clearParam === '1') {
  ReactDOM.render(<ClearPage />, target);
} else {
  // nesting doll
  ReactDOM.render(
      <RecoilRoot>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            {/* <Suspense fallback={<LoadingSpinner isFullScreen />}> */}
            <PancakeThemeProvider>
              <GlobalStateProvider>
                <ServicesContext.Provider value={ServiceContainer}>
                  {/*<SearchContext.Provider value={SearchStore}>*/}
                    <DashProvider>
                      <ToastProvider>
                        <InfotipProvider>
                          <ExportAndSchemaProvider>
                            <AzureAdProtector>
                              <CacheBuster>
                                <App />
                              </CacheBuster>
                            </AzureAdProtector>
                          </ExportAndSchemaProvider>
                        </InfotipProvider>
                      </ToastProvider>
                    </DashProvider>
                  {/*</SearchContext.Provider>*/}
                </ServicesContext.Provider>
              </GlobalStateProvider>
            </PancakeThemeProvider>
          </BrowserRouter>
        </MsalProvider>
      </RecoilRoot>,
      target);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
