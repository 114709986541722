import React, {ReactNode} from 'react'
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import {IconButton} from "@material-ui/core";


export function GreenCheckOrAdd(testValue: boolean, onClick: () => void) {
  return ( testValue
      ? <GreenCheck />
      : (<IconButton size="small" onClick={onClick}>
        <AddCircleOutline/>
      </IconButton>));
}

export function GreenCheck(props: {title?:string}) {
  return (
      <span style={{color:'green'}} title={props.title}><Check /></span>
  )
}

export function RedX(props: {title?:string}) {
  return (
      <span style={{color: 'red'}} title={props.title}><Clear/></span>
  )
}

export function Excel() {
  return (
      <span><img src="/images/details/xlsx_icon.png" style={{width:'24px',height:'24px'}} alt="Excel" /></span>
  )
}