import {DetailNavItemModel} from "./detailSideNavBarData";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContactsIcon from '@material-ui/icons/Contacts';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GroupIcon from '@material-ui/icons/Group';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import DescriptionIcon from '@material-ui/icons/Description';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import MemoryIcon from '@material-ui/icons/Memory';
import BuildIcon from '@material-ui/icons/Build';
import EcoIcon from '@material-ui/icons/Eco';
import WcIcon from '@material-ui/icons/Wc';
import ReplayIcon from '@material-ui/icons/Replay';
import AttachFileIcon from '@material-ui/icons/AttachFile';

export const projectIcons: Record<string, JSX.Element> = {
  LocationOn: <LocationOnIcon />,
  AssignmentInd: <AssignmentIndIcon />,
  Assignment: <AssignmentIcon />,
  Contacts: <ContactsIcon />,
  PersonPin: <PersonPinIcon />,
  Schedule: <ScheduleIcon />,
  AttachMoney: <AttachMoneyIcon />,
  Group: <GroupIcon />,
  LocalActivity: <LocalActivityIcon />,
  Description: <DescriptionIcon />,
  PriorityHigh: <PriorityHighIcon />,
  Memory: <MemoryIcon />,
  Build: <BuildIcon />,
  Eco: <EcoIcon />,
  Wc: <WcIcon />,
  Replay: <ReplayIcon />,
  AttachFile: <AttachFileIcon />,
};

export function getProjectNavBarData() {
  return [
    {text: 'Project Location', to: 'location-module', iconName: 'LocationOn'},
    {text: 'Client Information', to: 'client-information-module', iconName: 'AssignmentInd'},
    {text: 'Project Details', to: 'project-details-module', iconName: 'Assignment'},
    {text: 'Project References', to: 'project-references-module', iconName: 'Contacts'},
    {text: 'Project Stakeholders', to: 'project-stakeholders-module', iconName: 'PersonPin'},
    {text: 'Schedule Information', to: 'schedule-information-module', iconName: 'Schedule'},
    {text: 'Project Cost', to: 'project-cost-module', iconName: 'AttachMoney'},
    {text: 'Project Staff', to: 'project-staff-module', iconName: 'Group'},
    {text: 'Project Awards', to: 'project-awards-module', iconName: 'LocalActivity'},
    {text: 'Project Descriptions', to: 'project-descriptions-module', iconName: 'Description'},
    {text: 'Challenges & Solutions', to: 'project-challenges-module', iconName: 'PriorityHigh'},
    {text: 'Technology Narratives', to: 'technology-narratives-module', iconName: 'Memory'},
    {text: 'PBM Technology', to: 'pbm-technology-module', iconName: 'Build'},
    {text: 'Sustainability', to: 'project-sustainability-module', iconName: 'Eco'},
    {text: 'Supplier Diversity', to: 'supplier-diversity-module', iconName: 'Wc'},
    {text: 'Services', to: 'services-module', iconName: 'Replay'},
    {text: 'Project Assets', to: 'assets-module', iconName: 'AttachFile'},
  ] as DetailNavItemModel[];
}