import React, {useEffect, useState} from 'react';
import {getReferenceConfig} from "./gridConfigSettings/reference";

import {Card, CardContent, CardHeader} from "@material-ui/core";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";

import {IApiPerson, IApiPersonReference, IApiProjectReference} from "services/apiClients/AthenaClient";
import SectionWithGridAndCardList from "../shared/SectionWithGridAndCardList";
import {convertReferences, IInfoCard} from "services/infoCardConversion";
import ChangeViewButton from "../../dashCore/ChangeViewButton";
import useSaveCount from "hooks/useSaveCount";
import useAthenaClient from "hooks/useAthenaClient";
import PickerDialog from "./AddReference/PickerDialog";
import _ from "lodash";
import PersonReferenceDialog from "./AddReference/PersonReferenceDialog";
import {useGlobalState} from "app/state/useGlobalState";
import CoreDebug from "features/core/CoreDebug";


export default function PersonReferenceCard(props: { person: IApiPerson }) {
  const sharedClasses = useSharedLargeCardStyles();
  const gs = useGlobalState();
  const {athenaClient} = useAthenaClient();
  const [listViewOn, setListViewOn] = useState(true);
  // phaseout for boolean useState
  //const [dialogOpen, open, close] = useOnOff();

  const [step, setStep] = useState(1);
  const [mode, setMode] = useState('edit');
  const [person, setPerson] = useState(props.person);
  // for AddReference
  const [contactId, setContactId] = useState<string>('');
  const [addReferenceMode, setAddReferenceMode] = useState<string>('');
  const [addReferenceOpen, setAddReferenceOpen] = useState<boolean>(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [personReference, setPersonReference] = useState<IApiPersonReference | null>(null);
  const [saveCount, incr] = useSaveCount();
  //const [references, setReferences] = useState([] as IApiPersonReference[]);
  const infoCards: IInfoCard[] = convertReferences(props.person.references ?? [])
  const config = getReferenceConfig(gs.userRoles);
  _.noop(props);

  function changeView() {
    setListViewOn(!listViewOn);
  }

  interface IHaveItem {
    item: { properties: { id: string } }
  }

  function handlePickerClose(contactId?: string | null) {
    console.log('QWE inside handlePickerClose', contactId);
    setPickerOpen(false);
    setPersonReference(null);

    if (contactId) {
      console.log('QWE should be opening AddReference');
      setContactId(contactId);
      setAddReferenceMode('add');
      setAddReferenceOpen(true);
    }
  }

  function handleAddReferenceClose() {
    setContactId('');
    setAddReferenceOpen(false);
    incr();
  }

  useEffect(() => {
    async function reloadPerson() {
      if (athenaClient) {
        const person = await athenaClient.getPerson(props.person.id!);
        setPerson(person);
      } else {
        //setReferences(props.person.references ?? [] as IApiPersonReference[]);
      }
    }

    reloadPerson();
  }, [saveCount]);

  function handleToolbarClick(args: any, data: object[]) {
    // data comes through as array of records
    // at this time we can only select one at a time use the [0] index
    switch (args.item.text) {
      case 'Add Reference':
        setMode('edit');
        setPickerOpen(true);
        args.cancel = true;
        break;
      case 'View':
        if (!data[0]) {
          alert('You must select a record to view first.');
        } else {
          //setMode('view');
          const record = data.shift() as IApiProjectReference;
          console.log('view record', {record});
          //open();
          setPersonReference(record as IApiPersonReference);
          //setContactId(personReference.contactId as string);
          setAddReferenceMode('view');
          setAddReferenceOpen(true);
        }
        args.cancel = true;
        break;
      case 'Unlink Reference':
        if (!data[0]) {
          alert('You must select a record to edit first.');
        } else {
          const pr = data.shift() as IApiProjectReference;
          const name = pr.firstName + ' ' + pr.lastName;
          if (confirm(`Are you sure you want to unlink ${name} from this record`)) {
            console.log('record', pr);
            if (athenaClient) {
              try {
                athenaClient.deletePersonContactLink2(person.id as number, pr.contactId as string).then(() => {
                  incr();
                });
                athenaClient.deletePersonReferenceLink(person.id as number, pr.contactId as string).then(() => {
                  incr();
                });
              } catch (err) {
                athenaClient.deletePersonReferenceLink(person.id as number, pr.contactId as string).then(() => {
                  incr();
                });
              }
            }
          }
        }
        args.cancel = true;
        break;
    }
  }


  // last minute for ui
  const references = person.references ?? [];
  references.forEach(x => x.fullName = x.fullName ?? x.firstName + ' ' + x.lastName);

  return (
      <Card id="references-module" className={sharedClasses.defaultCard}>
        <CardHeader
            title={'References'}
            data-scroll-name={'reference'}
            action={<ChangeViewButton showListIcon={listViewOn} onClick={changeView}/>}/>
        <CardContent style={{display: 'flex'}}>

          <SectionWithGridAndCardList
              listView={listViewOn}
              items={references}
              columnDirectives={config.columnDirectives}
              toolbarOptions={config.toolbarOptions}
              showToolbar={true}
              sortingOptions={config.sortingOptions}
              title={config.title}
              showTitle={config.showTitle}
              allowAdd={config.allowAdd}
              allowRemove={config.allowRemove}
              infoCards={convertReferences(references)}
              //onAction={handleAction}
              onToolbarClick={handleToolbarClick}
              exportColumns={config.exportColumns}
              allowFiltering={true}
              // itemLevelActions={itemLevelActions}
              // onItemLevelAction={handleItemLevelActions}
          />
        </CardContent>

        {!addReferenceOpen &&
            <PickerDialog
                title={'Add Reference'}
                searchBoxLabel={'Search for contact...'}
                open={pickerOpen}
                onClose={handlePickerClose}
                dataSourceName={'contacts'}
                extraInfo={{personFullName: props.person.name!}}
            />
        }

        {addReferenceOpen &&
            <PersonReferenceDialog personFullName={person.name}
                                   key={'prd-' + saveCount}
                                   personId={person.id ?? 0}
                                   personGuid={person.guid ?? ''}
                                   contactId={contactId}
                                   personReference={personReference}
                                   mode={addReferenceMode}
                                   open={addReferenceOpen}
                                   onClose={handleAddReferenceClose}/>
        }

        {/*<div>*/}
        {/*  addReferenceOpen: {addReferenceOpen}<br/>*/}
        {/*  addReferenceMode: {addReferenceMode}<br/>*/}
        {/*  contactId: {contactId}<br/>*/}
        {/*  personId: {person.id ?? 0}<br/>*/}
        {/*</div>*/}

      </Card>
  );
}