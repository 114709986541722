import React, { createContext, useState } from 'react';
import {Dialog} from "@material-ui/core";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import noop from "utils/noop";
import {makeStyles, Theme} from "@material-ui/core/styles";

interface InfotipContextType {
  openInfotip: (key: string) => void;
  closeInfotip: () => void;
}

export const InfotipContext = createContext<InfotipContextType>({
  openInfotip: () => noop(),
  closeInfotip: () => noop(),
});

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: '20px 20px 30px 20px',
  }
}));

const InfotipProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  const [showInfotipModal, setShowInfotipModal] = useState(false);
  const [content, setContent] = useState('');

  const openInfotipModal = (key: string) => {
    // get the content for the specified key and set it in state
    const infotip = projectInfotips[key];
    setContent(formatInfotip(infotip));
    //setContent(`Infotip content for ${key}`);
    setShowInfotipModal(true);
  };

  const closeInfotipModal = () => {
    setShowInfotipModal(false);
  };

  return (
      <InfotipContext.Provider value={{ openInfotip: openInfotipModal, closeInfotip: closeInfotipModal }}>
        <Dialog open={showInfotipModal} onClose={closeInfotipModal}>
          <div className={classes.dialogContent} dangerouslySetInnerHTML={{ __html: content }} />
        </Dialog>
        {children}
      </InfotipContext.Provider>
  );
};

export default InfotipProvider;