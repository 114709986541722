import {withDashCard} from '../../shared/withDashCard';
import ChangeOrderInfoSection from "./ChangeOrderInfoSection";
import ChangeOrderReasonsSection from "./ChangeOrderReasonsSection";
import SelfWorkSection from "./SelfWorkSection";
import SelfWorkNarrativeSection from "./SelfWorkNarrativeSection";
import InsuranceTypeSection from "./InsuranceTypeSection";

const ProjectCostCard = withDashCard('Project Cost', 'project-cost-module',
    // list of components to add to the card (in order they should appear)
    [
      ChangeOrderInfoSection,
      ChangeOrderReasonsSection,
      SelfWorkSection,
      SelfWorkNarrativeSection,
      InsuranceTypeSection,
    ]
);

export default ProjectCostCard;