import React, {
  useState, useEffect,
  useImperativeHandle, forwardRef, useRef, ForwardedRef
} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import DisplayField from "../../shared/DisplayField";
import EditIcon from '@material-ui/icons/Edit';
import AthenaClient, {
  ApiPerson, IApiPerson, IApiProject,
  IApiProjectStakeholder
} from "services/apiClients/AthenaClient";
import noop from "utils/noop";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {
  Card, CardContent, CardHeader,
  CircularProgress, Switch, Dialog
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import DashDetailsField from "components/DashDetailsField";
import numberFormatter from "services/shared/numberFormatter";
import EntityDialog from "../../shared/EntityDialog";
import {getNamedData} from "utils/forms";
import PersonDetailService from "services/PersonService";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
import {commasFmt, currencyFmt} from "utils/numberToStringUtil";
import {IReferenceData} from "hooks/useFieldOptions/useReferenceDataForProjects";
// end imports

const SECTION_ID = 'designPreconScheduleSection';

const DesignPreconScheduleSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & {
  onSave?: () => void
}>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const [model, setModel] = useState<IApiProject>(props.model);
      const [saveCount, incr] = useSaveCount();
      const {athenaClientRef} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());

      const toggleEditMode = () => {
        props.setEditMode(!props.editMode);
      }

      useEffect(() => {
        noop();
      }, [sectionKey]);


      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          console.log('begin ' + SECTION_ID + ' save');
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);
          try {
            const client = (athenaClientRef.current as AthenaClient);
            // try const client = (athenaClient as AthenaClient);
            const m = await client.patchProject(data);
            setModel(m as IApiProject);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));
      // end useImperativeHandle


      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (!props.model) {
        return <DashItemProgress/>;
      }

      return (
          <React.Fragment key={sectionKey}>
            {/* id */}
            <DashDetailsField
                mode="hidden"
                label="Id"
                showLabel={false}
                editMode={props.editMode}
                name="id"
                defaultValue={model.id}
            />

            {/* designPreconScheduledStartDate */}
            <DashDetailsField
                mode="date"
                label="Scheduled Start Date"
                showLabel={true}
                width="47%"
                editMode={props.editMode}
                name="designPreconScheduledStartDate"
                infotipKey={'designPreconScheduledStartDate'}
                defaultValue={model.designPreconScheduledStartDate}
            />

            {/* designPreconActualStartDate */}
            <DashDetailsField
                mode="date"
                label="Actual Start Date"
                showLabel={true}
                width="47%"
                editMode={props.editMode}
                name="designPreconActualStartDate"
                infotipKey={'designPreconActualStartDate'}
                defaultValue={model.designPreconActualStartDate}
            />

            {/* designPreconScheduledCompletionDate */}
            <DashDetailsField
                mode="date"
                label="Scheduled Completion Date"
                showLabel={true}
                width="47%"
                editMode={props.editMode}
                name="designPreconScheduledCompletionDate"
                infotipKey={'designPreconScheduledCompletionDate'}
                defaultValue={model.designPreconScheduledCompletionDate}
            />

            {/* designPreconActualCompletionDate */}
            <DashDetailsField
                mode="date"
                label="Actual Completion Date"
                showLabel={true}
                width="47%"
                editMode={props.editMode}
                name="designPreconActualCompletionDate"
                infotipKey={'designPreconActualCompletionDate'}
                defaultValue={model.designPreconActualCompletionDate}
            />

            <DashDivider width="80vw" height="5px"/>

            {/*<Dialog*/}
            {/*  open={showInfotipModal}*/}
            {/*  onClose={() => setShowInfotipModal(false)}>*/}
            {/*  <div dangerouslySetInnerHTML={{ __html: content }} />*/}
            {/*</Dialog>*/}

          </React.Fragment>
      );
    });

export default withDashSection('Design / PreCon', SECTION_ID, DesignPreconScheduleSection);
