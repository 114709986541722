import React, {useState, useEffect, useContext} from 'react';
import { Card, CardContent, CardHeader} from "@material-ui/core";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";
import ChangeViewButton from "components/dashCore/ChangeViewButton";
import {IApiPerson, IApiProjectHistory, IDegree, ITraining} from "services/apiClients/AthenaClient";
import SectionWithGridAndCardList from "../shared/SectionWithGridAndCardList";
import {convertDegree, convertProjectHistory, convertTraining, IInfoCard} from "services/infoCardConversion";
import {getEducationConfig} from "./gridConfigSettings/education";
import {getTrainingConfig} from "./gridConfigSettings/training";
import PersonService from "services/PersonService";
import {IEntityAction} from "utils/syncFusionUtil";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import {useGlobalState} from "../../../app/state/useGlobalState";


export default function EducationAndTrainingCard(props: { person: IApiPerson }) {
  const sharedClasses = useSharedLargeCardStyles();
  const gs = useGlobalState();
  const {athenaClient} = useAthenaClient();
  const [listViewOn, setListViewOn] = useState(true);
  const [educationMode, setEducationMode] = useState('browse');
  const [trainingMode, setTrainingMode] = useState('browse');
  const [educationItems,setEductionItems] = useState([] as IDegree[]);
  const [trainingItems, setTrainingItems] = useState([] as ITraining[]);
  const [countEducation, incrEducation] = useSaveCount();
  const [countTraining, incrTraining] = useSaveCount();
  const educationConfig = getEducationConfig(gs.userRoles);
  const trainingConfig = getTrainingConfig(gs.userRoles);
  let educationInfoCards: IInfoCard[] = convertDegree(educationItems);
  let trainingInfoCards: IInfoCard[] = convertTraining(trainingItems);
  const personId = props?.person?.id ?? 0;

  const cancel = () => {
    if (confirm('Are you sure you want to cancel without saving change?')) {
      setEducationMode('normal');
      setTrainingMode('normal');
    }
  }

  useEffect(() => {
    async function reloadData() {
      if (athenaClient && personId) {
        const personService = new PersonService(athenaClient);
        const p = await personService.getPerson(personId);
        setEductionItems(p.degrees as IDegree[]);
        setTrainingItems(p.trainings as ITraining[]);
        educationInfoCards = convertDegree(educationItems);
        trainingInfoCards =  convertProjectHistory(trainingItems);
      }
    }

    reloadData();
  }, [athenaClient, countEducation, countTraining]);

  //#region Degree handler/dispatch
  function handleDegreeAction(actionObj: IEntityAction) {
    const personService = new PersonService(athenaClient!);
    if (actionObj) {
      switch (actionObj.action) {
        case 'add':
          console.log('@tmp trying create',actionObj);
          personService.addChild<IDegree, IDegree>(
              String(personId), actionObj.data as IDegree,'degree')
              .then(() => incrEducation());
          break;
        case 'edit':
          console.log('@tmp trying update',actionObj);
          personService.modifyChild<IDegree, IDegree>(
              String(personId), actionObj.data as IDegree,'degree')
              .then(() => incrEducation());
          break;
        case 'delete':
          console.log('@tmp trying delete',actionObj);
          personService.deleteChild(
              String(personId), actionObj.data.id as string,'degree')
              .then(() => incrEducation());
          break;
      }
    }
  }
  //#endregion

  //#region Training handler/dispatch
  function handleTrainingAction(actionObj: IEntityAction) {
    console.log('trying to handle TRAINING action', {entityAction: actionObj});
    const personService = new PersonService(athenaClient!);

    if (actionObj) {
      switch (actionObj.action) {
        case 'add':
          console.log('@tmp trying add', actionObj);
          personService.addChild<ITraining, ITraining>(
              String(personId), actionObj.data as ITraining,'training')
              .then(() => incrTraining());
          break;
        case 'edit':
          console.log('@tmp trying update',actionObj);
          personService.modifyChild<ITraining, ITraining>(
              String(personId), actionObj.data as ITraining,'training')
              .then(() => incrTraining());
          break;
        case 'delete':
          console.log('@tmp trying delete',actionObj);
          personService.deleteChild(
              String(personId), actionObj.data.id as string,'training')
              .then(() => incrTraining());
          break;
      }
    }
  }
  //#endregion

  function changeView() {
    setListViewOn(!listViewOn);
  }

  if (!athenaClient) return null;

  return (
      <Card id="education-training-module" className={sharedClasses.defaultCard}>
        <CardHeader data-scroll-name={'educationAndTraining'}
            title={'Education and Professional Training'}
            action={<ChangeViewButton showListIcon={listViewOn} onClick={changeView}/>} />
        <CardContent style={{display:'flex'}}>
          {/*EDUCATION*/}
        <SectionWithGridAndCardList listView={listViewOn}
                                    items={educationItems}
                                    columnDirectives={educationConfig.columnDirectives}
                                    sortingOptions={educationConfig.sortingOptions}
                                    editOptions={educationConfig.editOptions}
                                    title={educationConfig.title}
                                    showTitle={educationConfig.showTitle}
                                    infoCards={educationInfoCards}
                                    onAction={handleDegreeAction}
        />


          {/*TRAINING*/}
          <SectionWithGridAndCardList listView={listViewOn}
                                      items={trainingItems}
                                      columnDirectives={trainingConfig.columnDirectives}
                                      sortingOptions={trainingConfig.sortingOptions}
                                      editOptions={trainingConfig.editOptions}
                                      title={trainingConfig.title}
                                      showTitle={trainingConfig.showTitle}
                                      infoCards={trainingInfoCards}
                                      onAction={handleTrainingAction}
          />


        </CardContent>
      </Card>
  )
}


