import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {ApiVdcTechnology, IApiVdcTechnology} from "../../../../services/apiClients/AthenaClient";
import useAthenaClient from "../../../../hooks/useAthenaClient";


const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
  treeViewContainer: {
    textAlign: 'left',
  }
});

export default function VdcTechTreeView(props: { onSelect: (id:string,path:string)=>void}) {
  const classes = useStyles();
  const {athenaClient} = useAthenaClient();
  const [treeModel, setTreeModel] = useState<ApiVdcTechnology[]>();

  function SortCompare(a: IApiVdcTechnology, b: IApiVdcTechnology) {
    const aName = a.name as string;
    const bName = b.name as string;
    return aName.localeCompare(bName, 'en', { sensitivity: 'base' });
  }

  useEffect(() => {
    async function getTree() {
      const tmpResult = await athenaClient?.getVdcTechnologies();
      console.log('!@@@ treeModel', tmpResult);
      setTreeModel(tmpResult);
    }

    if (athenaClient && !treeModel) {
      getTree();
    }
  }, [athenaClient, treeModel])

  // function handleLabelClick(e: unknown) {
  //   console.log(e);
  // }

  function handleNodeClick(id: string | undefined, path: string | undefined) {
    props.onSelect(id as string, path as string);
  }

  function renderTree(nodes: IApiVdcTechnology) {
    const isEndNode = !nodes.childVdcTechnologies || nodes.childVdcTechnologies.length === 0;
    const nodeLabel = isEndNode ? '👉' + nodes.name?.toUpperCase(): nodes.name;
    const nodeClick = isEndNode ? (e:unknown) => handleNodeClick(nodes.id,nodes.path): undefined;

    return (
        <>
        <input id="vdcTechnologyId" value="" type="hidden" />
        <TreeItem key={nodes.id} nodeId={nodes.id as string}
                  label={nodeLabel} onClick={nodeClick}>
          { Array.isArray(nodes.childVdcTechnologies)
              ? nodes.childVdcTechnologies
                  .slice()
                  .sort((a,b) => SortCompare(a,b))
                  .map((node) => renderTree(node))
              : null}
        </TreeItem>
        </>
    );
  }

  return (
      <div className={classes.treeViewContainer}>
      <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={['root']}
          defaultExpandIcon={<ChevronRightIcon />}
      >
        { treeModel && treeModel
            .slice()
            .sort((a,b) => SortCompare(a,b))
            .map(topLevelNode => renderTree(topLevelNode)) }
      </TreeView>
      </div>
  );
}