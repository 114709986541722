import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {IFacetFieldInfo, SideNavChildrenProps} from 'types/searchTypes';
import AutoCompleteSearcher from "./AutocompleteSearcher";
import {ISearchableFacetItem, orderByDisplay} from "./localTypes";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import TaggedChip from "../../../dashCore/TaggedChip";
import useFacetState, {IFacetState} from "hooks/useFacetState";
import {adjustCountAndOrder2} from "./util";
import ClearFacetButton from "../ClearFacetButton";
import useFacetSelections from "../../../../features/Facets/hooks/useFacetSelections";
import {useSearchParams} from "react-router-dom";
import {alignMemberCounts, makeAutocompleteFacetPlaceholder} from "../facetUtil";
import _, {cloneDeep} from "lodash";
import {FacetContext} from "../../../../app/facetContext";
import {useGlobalState} from "../../../../app/state/useGlobalState";
import {useObserver} from "mobx-react";

const useStyles = makeStyles(() => ({
  autocomplete: {
    '& .MuiInputBase-input ': {
      width: '85%',
    }
  },
  itemLabel: {
    fontSize: '14px',
    color: '#000'
  },
  itemCount: {
    fontSize: '14px',
    color: '#999'
  },
  // start accordion style
  accordion: {
    width: '275px',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    fontSize: '14px',
    '& > div': {
      width: '100%',
    }
  }
}));

const DEBUG_LABEL = 'Companies';

export default function AutocompleteFacet(props: SideNavChildrenProps) {
  const classes = useStyles();
  const gs = useGlobalState();
  //const {athenaClient} = useAthenaClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const facetCache = useContext(FacetContext);
  const [memberCounts,
    facetActions] = useFacetSelections(props, facetCache);
  const [filter, setFilter] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [clearCount, setClearCount] = useState(0);
  // manages items that appear in the autocomplete as choices
  const acs = new AutoCompleteSearcher(props.field, props.members as IFacetFieldInfo[]);
  const [filteredOptions, setFilteredOptions] = useState([] as ISearchableFacetItem[]);
  const {field, mode, setMode, selectedItems, setSelectedItems} =
      useFacetState<ISearchableFacetItem>({
        entityName: props.entityName,
        field: props.field,
        mode: props.defaultMode ?? 'anyof',
        selectedItems: [],
      }, handleOnChange);

  // prep latest counts
  useEffect(() => {
    if (memberCounts) {
      alignMemberCounts(props.members!, memberCounts!);
      const tmpSelectedValues = facetActions.getFacetSelections().values;
      const allPossibleItems = adjustCountAndOrder2(acs, memberCounts!, '');
      let currentSelectedItems: ISearchableFacetItem[] = [];
      console.log('!@!5 allPossibleItems count', allPossibleItems.length);
      if (tmpSelectedValues.length > 0) {
        currentSelectedItems = allPossibleItems.filter(x => tmpSelectedValues.includes(x.value));
        console.log('!@!5', {currentSelectedItems})
      }
      console.log('!@!5', {tmpSelectedValues, currentSelectedItems})
      alignSelectedItemsCounts(currentSelectedItems, memberCounts!);
      setSelectedItems(currentSelectedItems);

      console.log(`AutoComplete inside useEffect`, {memberCounts, props_members: props.members, searchParams});
    }
  }, [memberCounts, searchParams]);

  function alignSelectedItemsCounts(selectedItems: ISearchableFacetItem[], counts: Record<string, number>) {
    const selectedValues = facetActions.getFacetSelections();
    const clonedItems = cloneDeep(selectedItems);
    for (let i = 0; i < clonedItems.length; i++) {
      const item = clonedItems[i];
      if (!item.value) continue;
      const count = counts[item.value];
      item.count = count !== undefined ? count : 0;
      item.displayWithCount = (count) ? `${item.display} (${count})` : `${item.display}`;
    }
    setSelectedItems(clonedItems);
    if (props.field === 'stakeholders') {
      console.log('#@!97 SELECTED ITEMS ISSUE: ', {selectedItems, clonedItems});
    }
  }

  function handleFilterTextFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const filter = event.target.value.trim();
    const filtered = adjustCountAndOrder2(acs, memberCounts!, filter);
    console.log('!@@ AutoComplete handleFilterTextFieldChange', {memberCounts, filtered});
    setFilteredOptions(filtered);
  }

  function handleAutocompleteChange(event: ChangeEvent<unknown>, value: ISearchableFacetItem,
                                    _reason: AutocompleteChangeReason, _details?: AutocompleteChangeDetails<ISearchableFacetItem> | undefined) {
    if (event.type === 'click' || event.type === 'keydown') {
      if (value) {
        const newItem = value;
        if (selectedItems.length === 0 || selectedItems.some(item => item.value !== newItem.value)) {
          const next = orderByDisplay([...selectedItems, newItem]);
          setSelectedItems(next);
          facetActions.addValue(newItem.value);
          facetActions.addToKeyValueStore(newItem.value, newItem);
          if (props.field === 'stakeholders') console.log('!@#97 Companies NEXT', next);
          setTimeout(() => {
            setClearCount(x => x + 1);
          }, 100);
        }
      }
    }
  }

  function handleChipDelete(tag: unknown) {
    const tagToDelete = tag as ISearchableFacetItem;
    const filtered = selectedItems.filter(x => x.value !== tagToDelete.value).sort();
    setSelectedItems(filtered);
    facetActions.removeValue(tagToDelete.value);
    facetActions.deleteFromKeyValueStore(tagToDelete.value);
  }

  const handleResetAndClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedItems([]);
    facetActions.clearFacetSelections();
    facetActions.clearKeyValueStore();
    //setExpanded(false);
    // expandedFacetId_FIX
    gs.expandedFacetId = undefined;
  }

  function handleAccordionChange(_event: unknown) {
    // if it is already my id then it shouldn't be me and I should close
    const nextId = (gs.expandedFacetId === props.id) ? 'none' : props.id;
    gs.setExpandedFacetId(nextId);
  }

  function handleOnChange(facetState: IFacetState<ISearchableFacetItem>) {
    _.noop(facetState);
  }

  if (props.label === DEBUG_LABEL) {
    console.log('#@!97', {selectedItems, hookVersion: facetActions.getFacetSelections()});
  }

  const facetSelections = facetActions.getFacetSelections();

  return useObserver(() =>
      <>
        <Accordion className={classes.accordion}
                   TransitionProps={{timeout: 50}}
                   onChange={handleAccordionChange}
                   expanded={gs.expandedFacetId === props.id}>
          <AccordionSummary className={classes.accordionSummary}
                            expandIcon={<ExpandMoreIcon/>}>
            <ClearFacetButton visible={facetSelections?.values?.length > 0}
                              onClick={handleResetAndClose}/>
            <Typography>{props.label}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div>
              <div>
                {selectedItems && selectedItems.map((item, index) => (
                    <TaggedChip label={item.displayWithCount} style={{margin: '4px'}}
                                key={index} tag={item}
                                showTooltip={true} onDelete={handleChipDelete}/>
                ))}
              </div>

              <div>
                <Autocomplete key={clearCount}
                              className={classes.autocomplete}
                              options={filteredOptions}
                              getOptionLabel={(option: ISearchableFacetItem) => option.displayWithCount ?? ''}
                              onChange={handleAutocompleteChange}
                              disableClearable
                              renderInput={params =>
                                  <TextField {...params}
                                             autoFocus
                                             placeholder={makeAutocompleteFacetPlaceholder(props.field)}
                                             onChange={handleFilterTextFieldChange}/>}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="accordionDetailEnd"></div>
      </>
  );
}