import useAthenaClient from 'hooks/useAthenaClient';
import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { useCurrentUserState } from 'recoil/currentUser';
import AthenaClient, {ITemplate, Template} from 'services/apiClients/AthenaClient';
import { CurrentUser } from 'types';
import noop from 'utils/noop';
import ExportingDialog from './ExportingDialog';
import TemplateDesigner from './TemplateDesigner';
import CoreLocation from "../core/CoreLocation";

interface IDefaultExportAndSchemaContext {
  templates?: Template[];
  startExport: (pathAndSearch: string, fileType?: string, templateId?: string | undefined, tmpTemplate?: Template | undefined | null) => void;
  checkExport: () => void;
  startTemplateDesigner: () => void;
  currentUser: CurrentUser;
}

export const ExportAndSchemaContext = createContext({ templates: undefined } as IDefaultExportAndSchemaContext);

// Create the provider component
export const ExportAndSchemaProvider: React.FC = ({ children }) => {
  const [ currentUser] = useCurrentUserState();
  const { athenaClient } = useAthenaClient();
  //const [currentschema, setCurrentSchema] = useState(null);
  const [templates, setTemplates] = useState<Template[]>();
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [templateDesignerOpen, setTemplateDesignerOpen] = useState(false);
  const [templateDesignerMode, setTemplateDesignerMode] = useState('index');

  const [pathAndSearch, setPathAndSearch] = useState<string>();
  const [activeTemplateId, setActiveTemplateId] = useState<string | null | undefined>(null);
  const [adhocTemplate, setAdhocTemplate] = useState<ITemplate | null | undefined>(null);
  const [fileType, setFileType] = useState<string>();
  const [exportDialogMode, setExportDialogMode] = useState('normal');

  const entity = CoreLocation.getEntityName();
  const entityId = CoreLocation.getEntityId();

  useEffect(() => {
    async function loadTemplates(athenaClient: AthenaClient, entity: string, userPrincipleName: string) {
      const tmpTemplates = await athenaClient.getTemplates(userPrincipleName, entity, 'searchResults');
      setTemplates(tmpTemplates);
    }

    if (athenaClient && entity && currentUser.userPrincipalName) {
      loadTemplates(athenaClient, entity, currentUser.userPrincipalName);
    }
  }, [athenaClient, entity, currentUser.userPrincipalName]);

  function startExport(pathAndSearch: string,
                       fileType?: string,
                       templateId?: string | null, template?: ITemplate | null) {

    console.log('called startExport', {pathAndSearch, fileType, templateId, 'next adhocTemplate': template});

    setPathAndSearch(pathAndSearch);
    setActiveTemplateId(templateId);
    setAdhocTemplate(template);
    setFileType(fileType);
    setExportDialogOpen(true);

    console.log('called startExport', {pathAndSearch, fileType, templateId, 'next adhocTemplate': template});
  }

  function checkExport() {
    setExportDialogMode('recover');
    setExportDialogOpen(true);
  }

  function startTemplateDesigner() {
    setExportDialogMode('index');
    setTemplateDesignerOpen(true);
  }

  const contextValue: IDefaultExportAndSchemaContext = { templates, startExport, checkExport, startTemplateDesigner, currentUser };

  function handleExportDialogClose() {
    setExportDialogOpen(false);
    setExportDialogMode('normal');
  }

  function handleNamedExport(templateId: string) {
    noop(templateId);
  }

  function handleTemplateDesignerClose() {
    setTemplateDesignerOpen(false);
  }

  useEffect(() => {
    console.log('pathAndSearch', {pathAndSearch});
  }, [pathAndSearch])

  return (
    <ExportAndSchemaContext.Provider value={contextValue}>
      {children}
      { exportDialogOpen &&
        <ExportingDialog open={exportDialogOpen}
                         mode={exportDialogMode}
                         onClose={handleExportDialogClose}
                         pathAndSearch={pathAndSearch}
                         entityId={entityId}
                         templateId={activeTemplateId}
                         adhocTemplate={adhocTemplate}
                         fileType={fileType ?? 'xlsx'}
                         entity={entity} />
      }
      { templateDesignerOpen &&
          <TemplateDesigner open={templateDesignerOpen}
                            mode={templateDesignerMode}
                            onClose={handleTemplateDesignerClose}
                            entityName={entity ?? ''}
                            onNamedExport={handleNamedExport} />
      }
    </ExportAndSchemaContext.Provider>
  );
}
