import React, {useState} from 'react';
import {Box, FormControl, InputLabel, Menu, MenuItem,
  Select, TableCell, TableRow, TextField} from '@material-ui/core';

interface SelectClassificationProps {
  hide?: boolean;
  classifications?: Record<string, string>;
  currentGoalIds: string[];
  onSelect: (classificationId: string) => void;
}

const SelectClassificationMenu: React.FC<SelectClassificationProps> = (props) => {
  const [selected, setSelected] = useState<string | null>(null);
  const classifications = props.classifications ?? []

  const handleSelectChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    props.onSelect(e.target.value as string);
  };

  if (props.hide) return <></>;

  console.log('!@@! remove these from list', props.currentGoalIds);

  return (
      <>
        <br/>
        <FormControl variant="outlined" style={{width:'450px', margin: '5px'}}>
          <InputLabel>Select a Classification</InputLabel>
          <Select
              value={selected || ''}
              onChange={handleSelectChange}
              label="Select a Classification">
            {Object.entries(classifications)
                    .filter(([key, _]) => !props.currentGoalIds?.includes(key))
                    .map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
  );
};

export default SelectClassificationMenu;
