import {
  convertSchemaIntoSimpleDict,
  convertTemplateIntoSimpleDict, firstOrValue,
  getDefaultPersonTemplate,
  other,
  reverseGuidsAndChildren,
  SimpleDict,
} from 'features/export/defaultTemplateFuncs';
import _ from 'lodash';
import React, { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import noop from 'utils/noop';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { enableRipple } from '@syncfusion/ej2-base';
import {
  DragAndDropEventArgs,
  FieldsSettingsModel,
  NodeCheckEventArgs,
  TreeViewComponent,
} from '@syncfusion/ej2-react-navigations';

import useAthenaClient from 'hooks/useAthenaClient';
import useSaveCount from 'hooks/useSaveCount';
import { ExportSchema, IExportSchema, ITemplate } from 'services/apiClients/AthenaClient';
import {useRecoilState, useRecoilValue} from "recoil";
import {itemToDeleteState} from "./DeletedItemState";

//enableRipple(true);

const useStyles = makeStyles({
  nodeTemplateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '98%',
  },
  treeViewContainer: {
    '& li .e-text-content .e-list-text': {
      width: '100%',
    },
  },
  lessPadding: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    }
  }
});

interface INodeTemplateProps {
  guid: string;
  label: string;
  children: unknown[];
  onDelete: (e: React.MouseEvent<HTMLElement>) => void;
}

// Define your protected nodes array here.
const protectedNodes = ['Projects', 'People'];

const NodeTemplate: FC<INodeTemplateProps> = (props) => {
  const classes = useStyles();
  const [, setItemToDelete] = useRecoilState(itemToDeleteState);

  console.log('!@@! NodeTemplate props', props)

  const labelStyle = {
    flexGrow: 1,
  };

  function handleDelete(guid: string) {
    setItemToDelete(guid);
  }

  return (
      <div className={classes.nodeTemplateContainer}>
        <div style={labelStyle}>{props.label}</div>
        <div>
          {!protectedNodes.includes(props.label) &&
              <img alt="delete node"
                  src={'/images/circle-minus.png'}
                  onClick={() => handleDelete(props.guid)}
              />
          }
        </div>
      </div>
  );
}



interface IEditTemplateSchemaProps {
  entityName: string;
  templateId?: string;
  template?: ITemplate | null;
  schema?: IExportSchema | null;
}

export interface IEditTemplateSchemaMethods {
  startingSchema: (schema: IExportSchema) => void;
  mergeSchema: (schema: IExportSchema) => void;
  getCurrentSchema: () => IExportSchema;
}

const EditTemplateSchema: React.ForwardRefRenderFunction<IEditTemplateSchemaMethods, IEditTemplateSchemaProps> = (props, ref) => {
  noop(props);
  const classes = useStyles();
  const { athenaClient } = useAthenaClient();
  const [dataSource, setDataSource] = useState<unknown | null>(null);
  //const [activeTemplate, setActiveTemplate] = useState<ITemplate | null>(null);
  const [activeSchema, setActiveSchema] = useState<SimpleDict[] | null>(null);
  const activeTemplateRef = useRef<SimpleDict[] | null>(null);
  const [mode, setMode] = useState<string>();
  const treeViewRef = useRef<TreeViewComponent | null>(null);
  const [count, incr] = useSaveCount();
  const [itemToDelete, setItemToDelete] = useRecoilState(itemToDeleteState);


  useEffect(() => {
    if (!activeSchema && props.schema) {
      setActiveSchema(convertSchemaIntoSimpleDict(props.schema));
    }
    if (treeViewRef.current && athenaClient && activeSchema) {
      console.log('!@@! activeSchema', activeSchema);

      setDataSource(activeSchema);
      const tv = treeViewRef.current;
      setTimeout(() => {
        tv.expandAll();
      }, 200);
    }
  }, [treeViewRef.current, athenaClient, count]);

  useEffect(() => {
    if (itemToDelete !== null && treeViewRef?.current) {
        const newNodes = treeViewRef.current.removeNodes([itemToDelete]);
        setDataSource(newNodes);
        setItemToDelete(null);
    }
  },[itemToDelete]);

  // Method to load schema
  const startingSchema = (schema: IExportSchema) => {
    // Implementation code here
  };

  interface LabeledObject {
    label?: string;
    children?: LabeledObject[];
    properties?: LabeledObject[];
    items?: LabeledObject;
    [key: string]: unknown;
  }

  function mergeArraysByLabel(target: LabeledObject[], source: LabeledObject[]): LabeledObject[] {
    // First, create a map of existing objects in the target array by 'label'
    const targetMap = new Map(target.map(item => [item.label, item]));

    // Then iterate over the source array and merge each item into the target array by 'label'
    for (const item of source) {
      if (targetMap.has(item.label)) {
        targetMap.set(item.label, mergeByLabel(targetMap.get(item.label) as LabeledObject, item));
      } else {
        targetMap.set(item.label, item);
      }
    }

    // Return array of merged objects
    return Array.from(targetMap.values());
  }

  function mergeByLabel(obj1: LabeledObject, obj2: LabeledObject): LabeledObject | null {
    // If both objects are undefined or null, return null
    if ((obj1 === undefined || obj1 === null) && (obj2 === undefined || obj2 === null)) {
      return null;
    }

    return _.mergeWith({}, obj1, obj2, function customizer(objValue, srcValue, key) {
      if (key === 'label' && objValue === srcValue) {
        return objValue; // This should be just a string
      } else if (key === 'children') {
        if (Array.isArray(objValue) && Array.isArray(srcValue)) {
          const valueMap = new Map((objValue as LabeledObject[]).map((item, index) => {
            if (!item) {
              throw new Error(`An item at index ${index} in ${key} array is undefined.`);
            }
            if (!item.label) {
              throw new Error(`An item at index ${index} in ${key} array does not have a 'label'. Item: ${JSON.stringify(item)}`);
            }
            return [item.label, item];
          }));

          // Go through each item in the source value array
          for (const item of srcValue) {
            // If there is no matching label in the value map, add the item
            if (!item.label || !valueMap.has(item.label)) {
              valueMap.set(item.label, item);
            } else {
              // If there is a matching label, merge the children of the matched items
              const matchedItem = valueMap.get(item.label);
              matchedItem.children = mergeByLabel(matchedItem, item)?.children;
              valueMap.set(matchedItem.label, matchedItem);
            }
          }

          return Array.from(valueMap.values());
        }
      }
    });
  }

  // Method to merge schema
  const mergeSchema = (schema: IExportSchema) => {
    const incomingSchema = convertSchemaIntoSimpleDict(schema);
    const existingSchema = treeViewRef.current?.getTreeData();
    console.log('!@@! EXISTING SCHEMA', existingSchema); // do log below for incomingSchema
    console.log('!@@! INCOMING SCHEMA', incomingSchema);


    const merged = mergeArraysByLabel(existingSchema as SimpleDict[], incomingSchema);
    console.log('!@@! MERGED', merged);
    console.log('!@@! MERGED JSON', JSON.stringify(merged));

    //setActiveTemplate({...activeTemplate, schema: merged as unknown as ExportSchema});
    setActiveSchema(merged);
    incr();
  };

  function getCurrentSchema() {
    console.log('!!! TREEVIEW', treeViewRef.current?.getTreeData());
    const dictArr = treeViewRef.current?.getTreeData() as SimpleDict[];
    console.log('!!! dictArr', treeViewRef.current?.getTreeData());
    const exportSchemaArr = reverseGuidsAndChildren(dictArr);
    console.log('!!! exportSchemaArr', exportSchemaArr);
    const exportSchema = firstOrValue(exportSchemaArr); // resistant to inner graph handling changes
    console.log('!!! exportSchema ', exportSchema);
    return exportSchema as unknown as ExportSchema;
  }

  useImperativeHandle(ref, () => ({
    startingSchema,
    mergeSchema,
    getCurrentSchema,
  }));

  function handleDelete(guid: string) {
    noop(guid)
  }

  function nodeDrag(args: DragAndDropEventArgs): void {
    noop(args); // for future use if needed
  }

  function dragStop(args: DragAndDropEventArgs): void {

    console.log(args.dropIndicator);

    if (args.droppedNodeData['parentID'] === args.draggedNodeData['parentID']) {
      if (args.dropIndicator === 'e-drop-next') {
        if (args.dropLevel.toString() === args.draggedNode.ariaLevel) {
          console.log('same level');
          args.cancel = false;
          return;
        } else {
          console.log('not same level');
          console.log(args.dropLevel, args.draggedNode.ariaLevel);
        }
      } else {
        console.log(args.dropIndicator + ' not e-drop-next');
      }
    } else {
      console.log('not the same parent');
      console.log(`args.dropped and dragged`, args.droppedNodeData, args.draggedNodeData);
    }
    args.cancel = true;
  }

  return (
    <div style={{
      textAlign: 'left',
      width: '100%',
      height: 'calc(60svh - 120px)',
      // height: '500px',
      border: '1px solid #ccc', backgroundColor: 'white'
    }}
      className={classes.treeViewContainer}>
      <TreeViewComponent
        style={{ height: '100%', overflowY: 'scroll' }}
        ref={treeViewRef}
        nodeTemplate={NodeTemplate}
        fields={{ dataSource: dataSource as Array<{[key: string]: string}>, id: 'guid', text: 'label', child: 'children' }}
        showCheckBox={false}
        allowDragAndDrop={true}
        // cssClass={'overflow-y-scroll'}
        nodeDragStop={dragStop}
        nodeDragging={nodeDrag} />
    </div>
  )
}

export default forwardRef(EditTemplateSchema);