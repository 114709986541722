import React, {useEffect, useRef, useState} from 'react';
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {useSharedLargeCardStyles} from "../shared/sharedStyles";
import {ApiPerson, IApiPerson, IApiProjectReference, IPersonAward, PersonAward} from "services/apiClients/AthenaClient";
import SectionWithGridAndCardList from "../shared/SectionWithGridAndCardList";
import {convertAwards, IInfoCard} from "services/infoCardConversion";
import ChangeViewButton from "../../dashCore/ChangeViewButton";
import {getPeopleAwardConfig} from "./gridConfigSettings/peopleAwards";
import PersonService from "services/PersonService";
import {IEntityAction} from "utils/syncFusionUtil";
import useAthenaClient from "hooks/useAthenaClient";
import {useGlobalState} from "../../../app/state/useGlobalState";
import PeopleAwardDialog from "./awards/PeopleAwardDialog";
import useTicks from "../../../hooks/useTicks";


export default function PersonAwardsCard(props: { person?: IApiPerson }) {
  const sharedClasses = useSharedLargeCardStyles();
  const gs = useGlobalState();
  const {athenaClient} = useAthenaClient();
  const [listViewOn, setListViewOn] = useState(true);
  const [person, setPerson] = useState<IApiPerson | undefined>(props?.person);
  const [awardMode, setAwardMode] = useState('browse');
  const [awardItems, setAwardItems] = useState(props?.person?.awards);
  const awardConfig = getPeopleAwardConfig(gs.userRoles);
  let awardInfoCards: IInfoCard[] = convertAwards(props.person?.awards);

  //setPersonAward(record as IPersonAward);
  const [dialogMode, setDialogMode] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentModel, setCurrentModel] = useState<IPersonAward | null>(null);
  const cardContentRef = useRef(null);
  const [ticks, incr] = useTicks();

  const personId = props.person?.id ?? 0;


  useEffect(() => {
    async function reloadData() {
      console.log('!@#!@# PRE DATA ISSUE', {athenaClient, personId});
      if (athenaClient && personId) {
        const personService = new PersonService(athenaClient);
        const p = await personService.getPerson(personId);
        console.log('!@#!@# DATA ISSUE', p, p.awards);
        setAwardItems(p.awards as PersonAward[]);
        awardInfoCards = convertAwards(awardItems);
      }
    }

    reloadData();
  }, [athenaClient, ticks]);

  function handleToolbarClick(args: any, data: object[]) {
    // data comes through as array of records
    // at this time we can only select one at a time use the [0] index
    switch (args.item.text) {
      case 'Add':
        incr();
        setCurrentModel(null);
        setDialogMode('new');
        setDialogOpen(true);
        args.cancel = true;
        break;
      case 'Edit':
        if (!data[0]) {
          alert('You must select a record to view first.');
        } else {
          const record = data.shift() as IApiProjectReference;
          console.log('$$$@ edit award record', record);
          //setPersonAward(record as IPersonAward);
          setCurrentModel(record as unknown as IPersonAward);
          setDialogMode('edit');
          setDialogOpen(true);
        }
        args.cancel = true;
        break;
      case 'Delete':
        if (!data[0]) {
          alert('You must select a record to edit first.');
        } else {
          const pa = data.shift() as IPersonAward;
          const name = pa.title ?? '';
          if (confirm(`Are you sure you want to remove ${name} from this record`)) {
            //console.log('record', pa);
            if (athenaClient) {
              athenaClient.deleteAward(props?.person.id as number, pa.id as string).then(() => {
                setDialogMode(null);
                setDialogOpen(false);
                incr();
              });
            }
          }
        }
        args.cancel = true;
        break;
    }
  }

  function handleAwardDialogSubmit(data: unknown, action: string) {
    // new version 2 form re-routing to old logic
    // if (action === 'edit') {
    //   (data as Record<string, unknown>).awardId = data.id;
    // }

    handleAwardAction({data: data as IPersonAward, action: action} as IEntityAction);
  }

  //#region Award handler/dispatch
  function handleAwardAction(actionObj: IEntityAction | null) {
    //const personService = new PersonService(athenaClient!);
    //console.log('@tmp trying Award action', actionObj);
    const tmpPersonAward = actionObj.data as unknown as IPersonAward;
    if (actionObj) {
      switch (actionObj.action) {
        case 'new':
          if (athenaClient) {
            athenaClient.createPersonAward(person?.id as number, tmpPersonAward as PersonAward)
                .then(() => {
                  setDialogMode(null);
                  setDialogOpen(false);
                  incr();
                })
          }
          break;
        case 'edit':
          console.log('trying update', actionObj);
          if (athenaClient) {
            const tmpPersonAward = actionObj.data as unknown as IPersonAward;
            athenaClient.updateAward(person?.id as number, `${tmpPersonAward.id}`, tmpPersonAward as PersonAward)
                .then(() => {
                  setDialogMode(null);
                  setDialogOpen(false);
                  incr();
                });
          }
          break;
        case 'delete':
          console.log('trying delete', actionObj);
          if (athenaClient) {
            athenaClient.deleteAward(person?.id as number, tmpPersonAward.id as string)
                .then(() => {
                  setDialogMode(null);
                  setDialogOpen(false);
                  incr();
                });
          }
          break;
      }
    }
  }

  //#endregion

  function changeView() {
    setListViewOn(!listViewOn);
  }

  if (!athenaClient) {
    return null;
  }


  function handleDialogClose() {
    setDialogMode(null);
    setDialogOpen(false);
    setCurrentModel(null);
    incr();
  }

  return (
      <Card id="awards-module" className={sharedClasses.defaultCard} key={'people-award-card-'}>
        <CardHeader
            title={'Awards'} data-scroll-name={'awards'}
            action={<ChangeViewButton showListIcon={listViewOn} onClick={changeView}/>}/>
        <CardContent style={{display: 'flex'}} ref={cardContentRef}>
          <SectionWithGridAndCardList listView={listViewOn}
                                      id={'awardGrid'}
                                      items={awardItems ?? []}
                                      columnDirectives={awardConfig.columnDirectives}
                                      sortingOptions={awardConfig.sortingOptions}
                                      editOptions={awardConfig.editOptions}
                                      title={awardConfig.title}
                                      showTitle={awardConfig.showTitle}
                                      infoCards={convertAwards(props.person?.awards ?? [])}
                                      onAction={handleAwardAction}
                                      onToolbarClick={(args, data) => handleToolbarClick(args, data)}
                                      specialCommand="award-year-date"
          />

          <PeopleAwardDialog open={dialogOpen}
                             dialogMode={dialogMode}
                             model={currentModel ?? undefined}
                             onClose={handleDialogClose}
                             ticks={ticks}
                             onSubmit={(data) => handleAwardDialogSubmit(data, data?.id ? 'edit' : 'new')}/>
        </CardContent>
      </Card>
  )
}