import hpLogo from 'assets/images/logos/hpLogoLight.png';
import useCurrentUser from 'hooks/useCurrentUser';
import React, {useContext} from 'react';

import {AppBar, Avatar, Grid, Toolbar, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import MiddleMenu from './MiddleMenu';
import {useNavigate} from "react-router-dom";
import SearchViewMenu from "../SearchViewMenu";
import {DashInstancesContext} from "../../providers/DashProvider";
import CoreLocation from "../../features/core/CoreLocation";
import BackButton from "./BackButton";
import HomeButton from "./HomeButton";
import UrlCaptureComponent from "../../app/state/UrlCaptureComponent";
import KeepWarm from "../dashCore/KeepWarm";

// TODO: get background color from a theme

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'sticky',
    boxShadow: 'none',
    height: '64px',
    zIndex: theme.zIndex.drawer + 1,
    //backgroundColor: theme.primaryAppbarBackgroundColor,
    background: 'linear-gradient(to bottom, #222, #111)',
    [theme.breakpoints.down('sm')]: {paddingTop: theme.spacing(0)},
  },
  hpLogoImg: {
    width: 200,
    display: 'block',
    float: 'left',
    padding: 12,
  },
  displayName: {
    display: 'block',
    float: 'left',
    marginLeft: 8,
    marginRight: 8,
  },
  profilePicture: {
    width: 48,
    height: 48,
    display: 'block',
    float: 'left',
  },
  helpButton: {
    float: 'left',
    color: 'white',
  },
  appName: {fontWeight: 'bold'},
  testingIconRoot: {
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    boxShadow: '2px -2px 36px 4px rgba(255, 255, 255, .75)',
    boxSizing: 'unset',
    color: 'white',
    width: 20,
    height: 20,
  },
  badge: {fontWeight: 'bold'},
}));

interface IPrimaryAppBarProps {
  appName: string | undefined;
}

const PrimaryAppBar = (props: IPrimaryAppBarProps) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const {dashState, setDashState} = useContext(DashInstancesContext);

  const listOrCards = dashState?.displayModeOnSearch as string;

  console.log('!@# location', location);

  const segments = pathname.split('/')
      .filter(part => part !== '');
  // let entityName = (segments && segments.length > 0)
  //     ? segments[0].toLowerCase()
  //     : 'projects';
  const entityName = CoreLocation.getEntityName();

  // if (entityName !== 'people' && entityName)

  const showBackButton = !!CoreLocation.getEntityId();

  function handleSearchViewChange(viewName: string) {
    setDashState(prevState => {
      return {
        ...prevState,
        displayModeOnSearch: viewName
      }
    });
  }

  return (
      <React.Fragment>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    {/*<SideNavigationMenu />*/}
                    <UrlCaptureComponent />
                    <Grid container spacing={0}>
                      <Grid item>
                        <BackButton visible={showBackButton}/>
                      </Grid>
                      <Grid item>
                        <KeepWarm />
                        <HomeButton/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <img data-testid="hpLogoImg" src={hpLogo} className={classes.hpLogoImg} alt="Hensel Phelps Logo"/>
                  </Grid>
                  <Grid item>
                    <div style={{visibility: 'hidden'}}>
                      <Typography className={classes.appName}>{props?.appName}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid id={'middleMenuContainer'} item>
                <MiddleMenu/>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  {currentUser?.profilePicture !== null && (
                      <div className={classes.profilePicture}>
                        <Avatar src={currentUser?.profilePicture} alt="Hensel Phelps User Avatar"/>
                      </div>
                  )}
                  {currentUser?.displayName !== null && (
                      <div className={classes.displayName}>
                        <p>{currentUser?.displayName}</p>
                      </div>
                  )}
                  <Grid item>
                    {/*<a href="https://henselphelps.service-now.com/" rel="noreferrer" target="_blank">*/}
                    {/*  <IconButton data-testid="helpButton" className={classes.helpButton}>*/}
                    {/*    <HelpOutlineIcon />*/}
                    {/*  </IconButton>*/}
                    {/*</a>*/}
                    <div id="dash-right-menu">
                      <SearchViewMenu entityName={entityName}
                                      currentView={listOrCards}
                                      onViewChange={handleSearchViewChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
  );
};

export default PrimaryAppBar;
