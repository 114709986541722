import React, {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Resize,
  Scroll,
  Sort,
  Toolbar, EditClickEventArgsModel,
} from "@syncfusion/ej2-react-grids";
import noop from "utils/noop";
import useAthenaClient from "hooks/useAthenaClient";
import {QueryableCompany, getAllEntitiesValue} from "services/apiClients/AthenaClient";
import {enableRipple} from '@syncfusion/ej2-base';
import {Inject} from "@syncfusion/ej2-react-richtexteditor";
import EditIcon from "@material-ui/icons/Edit";
import {LibraryBooks} from "@material-ui/icons";
import {IMightHaveId} from "types/croneDashTypes";
import DashIconButton from "../../../dashCore/DashIconButton";

//enableRipple(true);


const useStyles = makeStyles((theme) => ({
  topBox: {
    width: '100%',
  },
  leftBox: {
    alignSelf: 'flex-start',
  },
  rightBox: {
    alignSelf: 'flex-end',
  },
  // bottomBox: {},
  activeStepIcon: {
    color: theme.palette.info.main,
  },
  completedStepIcon: {
    color: theme.palette.success.main,
  },
  dialog: {
    width: '90vh',
    maxWidth: 'none'
  },
}));

interface IEditProps {
  record: IMightHaveId;
}

interface IViewProps {
  record: IMightHaveId;
}

interface IPickerDialogExProps<T> {
  id?: string;
  //open: boolean;
  mode?: string | null;
  title: string;
  searchBoxLabel: string;
  entityName: string;
  childModel?: T
  extraInfo?: Record<string, string>;
  onAction: (action: string, payload?: null | string | T | Record<string, unknown>) => void;
  // View: React.ComponentType<IViewProps>;
  // Edit: React.ComponentType<IEditProps>;
  onGridInstance?: (gridRef: React.MutableRefObject<GridComponent> | null) => void;
  columnDirectives: unknown[];
  allowAdd?: boolean;
  allowEdit?: boolean;
  allowView?: boolean;
  alternateCancelText?: string;
}

export default function PickerDialogEx<T>(props: IPickerDialogExProps<T>) {
  const classes = useStyles();
  const {athenaClient} = useAthenaClient();
  const [searchText, setSearchText] = useState<string>();
  const [records, setRecords] = useState([] as T[]);
  const [id, setId] = useState<string | undefined>(props.id);
  const [mode, setMode] = useState(props.mode);
  const [record, setRecord] = useState<T | undefined>();
  const gridRef = useRef<GridComponent | null>(null);

  useEffect(() => {
    if (gridRef.current !== null && props.onGridInstance) {
      props.onGridInstance(gridRef);
    }
  }, [gridRef.current])

  useEffect(() => {
    async function reloadData() {
      const u = undefined;
      if (athenaClient) {
        const tmpSearchText = searchText?.length ? searchText + '*': 'a';
        const resultSet = await athenaClient.getAllEntities(props.entityName,
            tmpSearchText, u, u, 50, u, true);
        //
        if (resultSet.value) {
          const entities = getAllEntitiesValue<QueryableCompany>(resultSet);
          entities.sort((a: QueryableCompany, b: QueryableCompany) => {
            // Check if tmpSearchText is in name of a and b
            const aNameIncludes = a.name && a.name.includes(tmpSearchText);
            const bNameIncludes = b.name && b.name.includes(tmpSearchText);
          
            // If only one name includes tmpSearchText, that entity should come first
            if (aNameIncludes && !bNameIncludes) return -1;
            if (!aNameIncludes && bNameIncludes) return 1;
          
            // If tmpSearchText is in both names or in neither, compare by address
            const aAddressIncludes = a.address && a.address.includes(tmpSearchText);
            const bAddressIncludes = b.address && b.address.includes(tmpSearchText);
          
            // If only one address includes tmpSearchText, that entity should come first
            if (aAddressIncludes && !bAddressIncludes) return -1;
            if (!aAddressIncludes && bAddressIncludes) return 1;
          
            // If tmpSearchText is in both addresses or in neither, compare by website
            const aWebsiteIncludes = a.website && a.website.includes(tmpSearchText);
            const bWebsiteIncludes = b.website && b.website.includes(tmpSearchText);
          
            // If only one website includes tmpSearchText, that entity should come first
            if (aWebsiteIncludes && !bWebsiteIncludes) return -1;
            if (!aWebsiteIncludes && bWebsiteIncludes) return 1;
          
            // If tmpSearchText is in both websites or in neither, the entities are equal for sorting purposes
            return 0;
          });
          setRecords(entities as T[]);
        }
      }
    }

    reloadData();
  }, [athenaClient, searchText]);

  // function setGridInstance(comp: GridComponent | null) {
  //   if (comp === null) return;
  //   gridInstance = comp;
  //   // todo: fix grid height
  //   //  gridInstance.element.style = '100px';
  //   props.onGridInstance()
  // }

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value ?? '');
  }

  const handleDataBound = () => {
    //setSelectedRow(undefined);
    noop();
  }

  const handleItemSelection = (id?: string | null) => {
    noop(id);
  }

  const gridServices = [Toolbar, Sort, Edit, Filter, Scroll, Resize, CommandColumn, ExcelExport] as object[];

  const title: string = (props?.extraInfo?.personFullName)
      ? `${props.title} for ${props.extraInfo!.personFullName!}`
      : props.title;

  function handleAdd() {
    noop();
  }

  function addAction(record: T) {
    setRecord(record);
    setMode('add');
    console.log(record);
  }

  function editAction(record: T) {
    console.log(record);
  }

  function viewAction(record: T) {
    console.log(record);
  }

// style={{minHeight:'600px',maxHeight:'600px',height:'600px'}}

  if (!props.mode) {
    return null;
  }
   // style={{height: '85vh',overflow:'hidden'}}
  return (
      <Dialog open={true} fullWidth maxWidth={'lg'} data-style={{height: '800px'}}>
        <DialogTitle className="bg-black text-white">{title}</DialogTitle>
        <DialogContent >
              <>
                  <Grid container justifyContent="space-between" style={{padding: '10px 5px', overflow: 'visible'}}>
                      <Grid item className={classes.leftBox}>
                        {/*reserved for future use*/}
                      </Grid>

                      <Grid item className={classes.rightBox}>
                          <TextField name="searchText" label={props.searchBoxLabel} onChange={(e) => setSearchText(e.target.value)} />
                      </Grid>
                  </Grid>
                  <Grid >
                      <GridComponent dataSource={records ?? []} height={'460px'}
                                     statelessTemplates={['directiveTemplates']}
                          ref={gridRef}
                          selectionSettings={{type: 'Single'}}
                          editSettings={{allowEditing: true, mode: 'Normal', allowEditOnDblClick: false}}
                          allowSorting allowFiltering allowResizing filterSettings={{type: 'Excel'}}
                          dataBound={handleDataBound}>
                             <ColumnsDirective>
                                {props.columnDirectives}
                              </ColumnsDirective>
                              <Inject services={gridServices}/>
                      </GridComponent>
                  </Grid>
              </>

        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="space-between" style={{width: '100%', marginLeft: '16px'}}>
            <Grid item className={classes.leftBox}>
              {/*future use*/}
              {/*props: {JSON.stringify(props)}*/}
            </Grid>
            <Grid item className={classes.rightBox}>
              <Button onClick={() => props.onAction('close')}>
                { props.alternateCancelText ? props.alternateCancelText: 'Cancel' }
              </Button>
            </Grid>
          </Grid>
        </DialogActions>

      </Dialog>
  );
}