import React from 'react';

interface PreloadImagesProps {
  imageSources?: string[];
}

const hiddenStyle: React.CSSProperties = {
  height: 1,
  width: 1,
  position: 'absolute',
  overflow: 'hidden',
  top: -9999,
  left: -9999,
  zIndex: -1
};

const PreloadImages: React.FC<PreloadImagesProps> = ({ imageSources }) => {
  return (
    <>
      {imageSources?.map((src, index) => (
        <img key={index} src={src} alt="" style={hiddenStyle} />
      ))}
    </>
  );
}

export default PreloadImages;
