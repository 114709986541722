import {withDashCard} from "../shared/withDashCard";
import {ProjectLocationSection, PersonLocationSection} from "./LocationSection";

export const ProjectLocationCard = withDashCard('Location', 'location-module',
    // list of components to add to the card (in order they should appear)
    [
      ProjectLocationSection,
    ]
);

export const PersonLocationCard = withDashCard('Location', 'location-module',
    // list of components to add to the card (in order they should appear)
    [
      PersonLocationSection,
    ]
);