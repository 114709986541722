import React, { useEffect, useState } from 'react'
import {
  ApiCompany,
  ApiProjectStakeholder,
  IApiContact,
  IApiProject,
  ProjectCompany,
} from 'services/apiClients/AthenaClient'
import { useSharedLargeCardStyles } from '../../shared/sharedStyles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import DashDetailsField from 'components/DashDetailsField'
import { getNamedData } from 'utils/forms'
import { DashItemProgress } from '../../../dashCore/DashItemProgress'
import useAthenaClient from 'hooks/useAthenaClient'
import useSaveCount from 'hooks/useSaveCount'
import { v4 as uuidv4 } from 'uuid'
import { IReferenceData } from 'hooks/useFieldOptions/useReferenceDataForProjects'
import { StakeholderPayload } from './ProjectStakeholderAssocSection'
import ProgressButton from '../../../dashCore/ProgressButton'
import _ from 'lodash'
import { getCrmAccountLink } from 'config/microsoftDynamics'
import { EditOutlined } from '@material-ui/icons'
import { hasPermission } from 'app/security/utils/securityUtil'
import { useGlobalState } from 'app/state/useGlobalState'
import AthenaAuthorize from 'app/security/ui/AthenaAuthorize'
import { useObserver } from 'mobx-react'

// end imports

interface IAddEditProjectStakeholderDialog {
  model: IApiProject
  childModel?: ApiCompany | ApiProjectStakeholder | null
  open?: boolean
  dialogMode?: string | null
  referenceData: IReferenceData
  onAction: (action: string, payload: StakeholderPayload) => void
}

const getInitialValue = (
  key: string,
  dialogMode?: string | null,
  childModel?: any,
): string | undefined => {
  if (dialogMode !== 'new' && childModel && key in childModel) {
    return childModel[key] ?? undefined
  }
  return undefined
}

function AddEditProjectStakeholderDialog(
  props: IAddEditProjectStakeholderDialog,
) {
  const classes = useSharedLargeCardStyles()
  const [model, setModel] = useState<
    ApiCompany | IApiContact | null | undefined
  >(props.childModel)
  const [saveCount, incr] = useSaveCount()
  const { athenaClient } = useAthenaClient()
  const [showInfotipModal, setShowInfotipModal] = useState(false)
  const [content, setContent] = useState('')
  const [sectionKey, setSectionKey] = useState(uuidv4())
  const [resetValue, setResetValue] = useState(false)
  const gs = useGlobalState()
  const [editMode, setEditMode] = useState(props.dialogMode ?? 'view')

  const [roleOnProject, setRoleOnProject] = useState<string | undefined>(
    getInitialValue('role', props.dialogMode, props.childModel),
  )

  const [commentsOnProject, setCommentsOnProject] = useState<
    string | undefined
  >(getInitialValue('commentsOnProject', props.dialogMode, props.childModel))

  if (!model) {
    setModel(props.childModel)
  }

  useEffect(() => {
    gs.setStakeholdersEditMode(props.dialogMode ?? 'view')
  }, [])

  function handleSave() {
    const scopingElement = document.querySelector('#fields-container')
    const data = getNamedData(scopingElement, false)
    const action = editMode === 'edit' ? 'update' : 'create'
    if (typeof data['roleOnProject'] === 'string' && hasOtherOwner()) {
      const r = data['roleOnProject'].trim() as string
      const roleStringContainSingleWord = /^\w+$/.test(r)
      const roleStringContainsOwner = /owner/i.test(r)
      if (roleStringContainSingleWord && roleStringContainsOwner) {
        alert(
          'You cannot use the "Owner" role in more than one project stakeholder association. You may need to ' +
            'remove this role from another stakeholder before you can add it here',
        )
        setResetValue(true)
        return
      }
    }
    const newStakeholder = data as ApiProjectStakeholder
    console.log('!@@@! handleSave Stakeholder', {
      action,
      data,
      newStakeholder,
    })
    const payload = {
      company: model as ApiCompany,
      projectCompany: data as ProjectCompany,
    }
    //props.onAction(action, {company: props.childModel as ApiCompany, projectCompany: data as ProjectCompany});
    props.onAction(action, payload)
  }

  function switchToEditMode() {
    gs.setStakeholdersEditMode('edit')
    setEditMode('edit')
  }

  const hasOtherOwner = () => {
    const stakeholders = props.model.stakeholders ?? []
    const records = stakeholders.filter(
      (x) => x.name !== props.childModel?.name,
    )
    const result = records.some((x) => x.role?.toLowerCase() === 'owner')
    console.log('!@#7 hasOtherOwner called', { records, result, props })
    return result
  }

  const isOwner = roleOnProject === 'Owner'

  function ownerSwitchChanged(value: boolean) {
    console.log('!@#8 ownerSwitch changed', typeof value, value)
    if (value === true) {
      setRoleOnProject('Owner')
      incr()
    } else {
      if (roleOnProject?.toLowerCase() === 'owner') {
        setRoleOnProject('')
      }
      incr()
    }
  }

  useEffect(() => {
    //console.log('!@#7 roleOnProject', roleOnProject);
    _.noop()
  }, [editMode])

  function handleReset() {
    _.noop()
    setResetValue(false)
  }

  if (props.model === null || model === null) {
    console.log('project is null')
    return <DashItemProgress />
  }

  const shouldSeeEdit = editMode === 'view'
  const canEdit = hasPermission(['Contributor'], gs.userRoles)
  const canEditButton = shouldSeeEdit && canEdit
  const businessRoles = model?.businessRoles?.filter((x) => x.trim())
  const businessLines = model?.businessLines?.filter((x) => x.trim())
  const verticalMarkets = model?.verticalMarkets?.filter((x) => x.trim())
  const districts = model?.districts?.filter((x) => x.trim())

  const editModeTextMap: Record<string, string> = {
    new: 'Add Stakeholder to ',
    edit: 'Edit Stakeholder for ',
    view: 'View Stakeholder for ', // view is default state
  }

  return (
    <Dialog open={!!props.open} fullWidth maxWidth={'lg'}>
      <DialogTitle className="bg-black text-white">
        {(editModeTextMap[editMode ?? 'view'] as string) + props.model.name}
      </DialogTitle>
      <DialogContent>
        {useObserver(() => (
          <div
            id="fields-container"
            style={{
              border: '0 dotted #eee',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'stretch',
            }}
          >
            <DashDetailsField
              mode="hidden"
              label="Project Id"
              showLabel={false}
              editMode={false}
              name="projectId"
              defaultValue={props.model.id}
            />

            <DashDetailsField
              mode="hidden"
              data-key={'CompanyId-' + (editMode ?? 'blank')}
              label="Company Id"
              showLabel={false}
              editMode={false}
              name="companyId"
              defaultValue={
                editMode === 'new'
                  ? model?.id
                  : ((props.childModel as unknown) as {
                      companyId: string
                    }).companyId
              }
            />

            <div style={{ width: '80%' }}>
              <h3 style={{ color: '#a6192e' }}>Details</h3>
            </div>
            <div style={{ width: '20%', textAlign: 'right' }}>
              {props.childModel?.crmId && (
                <a
                  href={getCrmAccountLink(props.childModel?.crmId)}
                  target={'_blank'}
                >
                  View in CRM
                </a>
              )}
            </div>

            <div style={{ width: '100%', textAlign: 'right' }}>
              <AthenaAuthorize allowedRoles={'Contributor'}>
                <IconButton
                  onClick={switchToEditMode}
                  hidden={!(editMode === 'view')}
                >
                  <span>
                    {gs.stakeholdersEditMode === 'view' && <EditOutlined />}
                  </span>
                </IconButton>
              </AthenaAuthorize>
            </div>

            {/* name */}
            <DashDetailsField
              mode="text"
              label="Name"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.name}
            />

            {/* acronyms */}
            <DashDetailsField
              mode="text"
              label="Acronyms"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.acronyms}
            />

            {/* roleOnProject */}
            <DashDetailsField
              mode="text"
              key={'roleOnProjectCtrl-' + saveCount}
              label="Role on Project"
              showLabel={true}
              width="50%"
              editMode={editMode !== 'view'}
              name="roleOnProject"
              required={true}
              defaultValue={roleOnProject ?? ''}
            />

            {/* commentsOnProject */}
            <DashDetailsField
              mode="text"
              label="Comments on Project"
              showLabel={true}
              width="50%"
              editMode={editMode !== 'view'}
              name="comments"
              defaultValue={commentsOnProject ?? ''}
            />

            {/* owner */}
            <DashDetailsField
              mode="switch"
              label="Owner"
              showLabel={true}
              width="50%"
              editMode={editMode !== 'view' && hasOtherOwner() === false}
              onValueChange={(x) => ownerSwitchChanged(!!x)}
              infotipKey={'stakeholderOwner'}
              name="owner"
              value={isOwner}
            />

            {businessRoles && businessRoles.length > 0 && (
              <>
                <DashDetailsField
                  mode="subheading"
                  label="Business Roles"
                  defaultValue="Business Roles"
                  showLabel={false}
                  width="100%"
                  editMode={false}
                />

                {/* businessRoles */}
                <DashDetailsField
                  mode="multi"
                  label="Business Roles"
                  showLabel={false}
                  width="50%"
                  editMode={false}
                  values={model?.businessRoles?.filter((x) => x.trim())}
                  choices={props.referenceData.options['']}
                />
              </>
            )}
            {businessLines && businessLines.length > 0 && (
              <>
                <DashDetailsField
                  mode="subheading"
                  label="Business Lines"
                  showLabel={false}
                  width="100%"
                  editMode={false}
                  defaultValue="Business Lines"
                />

                {/* business lines */}
                <DashDetailsField
                  mode="multi"
                  label="Business Lines"
                  showLabel={false}
                  width="50%"
                  editMode={false}
                  values={model?.businessLines?.filter((x) => x.trim())}
                  choices={props.referenceData.options['']}
                />
              </>
            )}
            {verticalMarkets && verticalMarkets.length > 0 && (
              <>
                <DashDetailsField
                  mode="subheading"
                  label="Vertical Markets"
                  showLabel={false}
                  width="100%"
                  editMode={false}
                  defaultValue="Vertical Markets"
                />

                {/* vertical markets */}
                <DashDetailsField
                  mode="multi"
                  label="Vertical Markets"
                  showLabel={false}
                  width="50%"
                  editMode={false}
                  values={model?.verticalMarkets?.filter((x) => x.trim())}
                  choices={props.referenceData.options['']}
                />
              </>
            )}
            {districts && districts.length > 0 && (
              <>
                <DashDetailsField
                  mode="subheading"
                  label="Regions"
                  showLabel={false}
                  width="100%"
                  editMode={false}
                  defaultValue="Regions"
                />

                {/* districts / regions */}
                <DashDetailsField
                  mode="multi"
                  label="Regions"
                  showLabel={false}
                  width="50%"
                  editMode={false}
                  values={model?.districts?.filter((x) => x.trim())}
                  choices={props.referenceData.options['']}
                />
              </>
            )}
            <DashDetailsField
              mode="subheading"
              label="Contact Information"
              showLabel={false}
              width="100%"
              editMode={false}
              defaultValue="Contact Information"
            />

            {/* website */}
            <DashDetailsField
              mode="text"
              label="Website"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.website}
            />

            {/* businessPhone */}
            <DashDetailsField
              mode="text"
              label="Business Phone"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.phone ?? model?.businessPhone}
            />

            {/* otherPhone */}
            <DashDetailsField
              mode="text"
              label="Other Phone"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.otherPhone}
            />

            {/* streetAddress */}
            <DashDetailsField
              mode="text"
              label="Street Address"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.streetAddress ?? model?.address}
            />

            {/* streetAddress2 */}
            <DashDetailsField
              mode="text"
              label="Street Address 2"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.streetAddress2 ?? model?.address2}
            />

            {/* city */}
            <DashDetailsField
              mode="text"
              label="City"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.city}
            />

            {/* state */}
            <DashDetailsField
              mode="text"
              label="State"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.state}
            />

            {/* zip */}
            <DashDetailsField
              mode="text"
              label="Zip"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.zip}
            />

            {/* country */}
            <DashDetailsField
              mode="text"
              label="Country"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.country}
            />

            <DashDetailsField
              mode="subheading"
              label="CRM Details"
              defaultValue="CRM Details"
              showLabel={false}
              width="100%"
            />

            {/* lastModified */}
            <DashDetailsField
              mode="date"
              label="Last Modified"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.crmModifiedOn}
            />

            {/* modifiedBy */}
            <DashDetailsField
              mode="text"
              label="Modified By"
              showLabel={true}
              width="50%"
              editMode={false}
              defaultValue={model?.crmModifiedBy}
            />
          </div>
        ))}
      </DialogContent>

      <DialogActions key={'dialogActions-' + saveCount}>
        <div
          className="flex flex-row"
          data-example="ability to reverse order later with css"
        >
          {['new', 'edit'].includes(editMode ?? '') && (
            <div>
              <ProgressButton
                onClick={handleSave}
                onReset={handleReset}
                reset={resetValue}
                text={'Save'}
              />
            </div>
          )}
          <div>
            <Button onClick={() => props.onAction('close', null)}>
              {props.dialogMode === 'view' ? 'Close' : 'Cancel'}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AddEditProjectStakeholderDialog
