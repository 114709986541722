export const psvAddProjectReference = `
  type|name|label|required_b|disabled_b|choicesKey
  #h2|fullName
  #h3.sectionTitle||Details
  *hidden|projectId
  #*hidden|personGuid
  *hidden|contactId
  TextField.textField|jobTitle|Current Title|false|true|
  TextField.textField|companyName|Current Company|false|true|
  TextField.textField|roleOnProject|Role on Project|true|false
  TextField.textField|companyOnProject|Company on Project|true|false
  TextField.textField|referenceType|Reference Type|true|false|referenceTypes
  #TextField.textField|comments|Comments|true|false|
  h3.sectionTitle||Contact Information
  TextField.textField|email|Email|false|true|
  TextField.textField|email2|Alternate Email|false|true|
  TextField.textField|businessPhone|Business Phone|false|true|
  TextField.textField|mobilePhone|Mobile Phone|false|true|
  TextField.textField|homePhone|Home Phone|false|true|
  TextField.textField|streetAddress|Street Address|false|true|
  TextField.textField|streetAddress2|Street Address 2|false|true|
  TextField.textField|city|City|false|true|
  TextField.textField|state|State|false|true|
  TextField.textField|zip|Zip|false|true|
  TextField.textField|country|Country|false|true|
  h3.sectionTitle||CRM Details
  TextField.textField|crmModifiedOn|Last Modified|false|true|
  TextField.textField|crmModifiedBy|Modified by|false|true|
`;

export const psvEditProjectReference = `
  type|name|label|required_b|disabled_b|choicesKey
  #h2|fullName
  #h3.sectionTitle||Details
  *hidden|projectId
  #*hidden|personGuid
  *hidden|contactId
  TextField.textField|jobTitle|Current Title|false|true|
  TextField.textField|companyName|Current Company|false|true|
  TextField.textField|roleOnProject|Role on Project|true|false
  TextField.textField|companyOnProject|Company on Project|true|false
  TextField.textField|referenceType|Reference Type|true|false|referenceTypes
  #TextField.textField|comments|Comments|true|false|
  h3.sectionTitle||Contact Information
  TextField.textField|email|Email|false|true|
  TextField.textField|email2|Alternate Email|false|true|
  TextField.textField|businessPhone|Business Phone|false|true|
  TextField.textField|mobilePhone|Mobile Phone|false|true|
  TextField.textField|homePhone|Home Phone|false|true|
  TextField.textField|streetAddress|Street Address|false|true|
  TextField.textField|streetAddress2|Street Address 2|false|true|
  TextField.textField|city|City|false|true|
  TextField.textField|state|State|false|true|
  TextField.textField|zip|Zip|false|true|
  TextField.textField|country|Country|false|true|
  h3.sectionTitle||CRM Details
  TextField.textField|crmModifiedOn|Last Modified|false|true|
  TextField.textField|crmModifiedBy|Modified by|false|true|
`;

export const psvViewProjectReference = `
  type|name|label|required_b|disabled_b|choicesKey
  #h2|fullName
  #h3.sectionTitle||Details
  *hidden|projectId
  #*hidden|personGuid
  *hidden|contactId
  TextField.textField|jobTitle|Current Title|false|true|
  TextField.textField|companyName|Current Company|false|true
  TextField.textField|roleOnProject|Role on Project|false|true
  TextField.textField|companyOnProject|Company on Project|false|true
  TextField.textField|referenceType|Reference Type|false|true
  #TextField.textField|comments|Comments|true|false|
  h3.sectionTitle||Contact Information
  TextField.textField|email|Email|false|true|
  TextField.textField|email2|Alternate Email|false|true|
  TextField.textField|businessPhone|Business Phone|false|true|
  TextField.textField|mobilePhone|Mobile Phone|false|true|
  TextField.textField|homePhone|Home Phone|false|true|
  TextField.textField|streetAddress|Street Address|false|true|
  TextField.textField|streetAddress2|Street Address 2|false|true|
  TextField.textField|city|City|false|true|
  TextField.textField|state|State|false|true|
  TextField.textField|zip|Zip|false|true|
  TextField.textField|country|Country|false|true|
  h3.sectionTitle||CRM Details
  TextField.textField|crmModifiedOn|Last Modified|false|true|
  TextField.textField|crmModifiedBy|Modified by|false|true|
`;

export const psvOldProjectReference = `
  type|name|label|required_b|disabled_b|choicesKey
  #h2|fullName
  #h3.sectionTitle||Details
  *hidden|projectId
  #*hidden|personGuid
  *hidden|contactId
  TextField.textField|jobTitle|Current Title|false|true|
  TextField.textField|companyName|Current Company|false|true
  TextField.textField|roleOnProject|Role on Project|false|true
  TextField.textField|companyOnProject|Company on Project|false|true
  TextField.textField|referenceType|Reference Type|false|true
  #TextField.textField|comments|Comments|true|false|
  h3.sectionTitle||Contact Information
  TextField.textField|email2|Alternate Email|false|true|
  TextField.textField|businessPhone|Business Phone|false|true|
`;


type MyTuple = [string,string,string?,boolean?,boolean?,string?]

const first: MyTuple = ['h2','My Section']



export const addPersonReferenceSettings = `
  type|name|label|required_b|disabled_b|section
  h2|fullName
  h3.sectionTitle||Details
  *hidden|personId
  *hidden|personGuid
  *hidden|contactId
  TextField.textField|jobTitle|Current Title|false|true|Details
  TextField.textField|companyName|Current Company|false|true|Details
  TextField.textField|referenceType|Reference Type|true|false|Details
  TextField.textField|comments|Comments|true|false|Details
  h3.sectionTitle||Contact Information
  TextField.textField|email|Email|false|true|Contact Information
  TextField.textField|email2|Alternate Email|false|true|Contact Information
  TextField.textField|businessPhone|Business Phone|false|true|Contact Information
  TextField.textField|mobilePhone|Mobile Phone|false|true|Contact Information
  TextField.textField|homePhone|Home Phone|false|true|Contact Information
  TextField.textField|streetAddress|Street Address|false|true|Contact Information
  TextField.textField|streetAddress2|Street Address 2|false|true|Contact Information
  TextField.textField|city|City|false|true|Contact Information
  TextField.textField|state|State|false|true|Contact Information
  TextField.textField|zip|Zip|false|true|Contact Information
  TextField.textField|country|Country|false|true|Contact Information
  h3.sectionTitle||CRM Details
  TextField.textField|crmModifiedOn|Last Modified|false|true|CRM Details
  TextField.textField|crmModifiedBy|Modified by|false|true|CRM Details
`;

interface IApiPersonReference {
  businessPhone?: string | undefined;
  city?: string | undefined;
  comments?: string | undefined;
  company?: string | undefined;
  companyId?: string | undefined;
  contactId?: string | undefined;
  country?: string | undefined;
  crmId?: string | undefined;
  crmModifiedBy?: string | undefined;
  crmModifiedOn?: Date | undefined;
  email?: string | undefined;
  email2?: string | undefined;
  firstName?: string | undefined;
  fullName?: string | undefined;
  homePhone?: string | undefined;
  isActive?: boolean | undefined;
  isManagedByCrm?: boolean | undefined;
  jobTitle?: string | undefined;
  lastName?: string | undefined;
  middleName?: string | undefined;
  modifiedBy?: string | undefined;
  modifiedOn?: Date | undefined;
  mobilePhone?: string | undefined;
  personGuid?: string | undefined;
  personId?: number | undefined;
  quoteCommendation?: string | undefined;
  referenceType?: string | undefined;
  salutation?: string | undefined;
  state?: string | undefined;
  streetAddress?: string | undefined;
  streetAddress2?: string | undefined;
  zip?: string | undefined;
}
