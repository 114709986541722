import React, {useEffect, useState} from 'react';
import {
  ExcelExport,
  Edit,
  Filter,
  Inject,
  Toolbar,
  GridComponent,
  Column,
  ToolbarItems,
  EditSettingsModel,
  SortSettingsModel,
  Sort,
  CommandColumn,
  DialogEditEventArgs,
  FilterSettingsModel,
  GridActionEventArgs,
  ColumnDirective,
  Resize,
  ExcelExportProperties, ClickEventArgs, ActionEventArgs,
} from '@syncfusion/ej2-react-grids';
import {Dialog} from '@syncfusion/ej2-popups';
import {IInfoCard, IInfoField} from 'services/infoCardConversion';

import {makeStyles} from "@material-ui/core/styles";
import {Card, CardHeader, CardContent, Typography, Tooltip, Box, IconButton, Grid,} from '@material-ui/core';
import {IEntity, IEntityAction, mapActionsToEntityActions} from "utils/syncFusionUtil";
import _ from "lodash";
import clsx from "clsx";
import CoreDebug from "../../../features/core/CoreDebug";

export type ItemAction = [key: string, label: string, icon: JSX.Element];

export interface ISectionWithGridAndCardListConfig {
  id?: string;
  listView: boolean,
  title?: string,
  showTitle: boolean,
  allowAdd: boolean,
  allowRemove: boolean,
  allowExcelExport?: boolean,
  allowFiltering?: boolean,
  editWithBuiltinDialog?: boolean,
  columnDirectives: Column[],
  infoCards: IInfoCard[],
  sortingOptions?: SortSettingsModel,
  filterOptions?: FilterSettingsModel,
  toolbarOptions?: object[]; //ToolbarSettingsModel,
  editOptions?: EditSettingsModel,
  onToolbarClick?: (args: object, selectedRecords: object[], gridInstance?: GridComponent) => void;
  showToolbar?: boolean,
  gridHeight?: number,
  itemLevelActions?: ItemAction[];
  onItemLevelAction?: (record: object | any) => void;
  onAction?: (entityAction: IEntityAction) => void;
  customButtonsLeft?: React.ReactNode[];
  exportColumns?: Column[];
  isReadOnly?: boolean;
  afterToolbarClick?: (args: ClickEventArgs) => void;
  specialCommand?: string;
}

export interface ISectionWithGridAndCardList<T> extends ISectionWithGridAndCardListConfig {
  items: T[],
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    flex: '1 0 100%',
    width: '100%'
  },
  cardHolder: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    rowGap: '5px',
    columnGap: '5px',
  },
  hardBreak: {
    '&:after': {
      content:'',
      display:'block',
    },
  },
  smallCard: {
    textAlign: 'left',
    display: 'block',
    width: '300px',
    flexGrow: 0,
    flexShrink: 0,

    '& > div.MuiCardHeader-root': {
      paddingBottom: '5px'
    },
  },
  cardHeader: {
    display: 'block',
    overflow: 'hidden'
  },
  cardHeaderRoot: {
    overflow: 'hidden'
  },
  cardHeaderContent: {
    overflow: 'hidden'
  }
}));

export default function SectionWithGridAndCardList<T>(props: ISectionWithGridAndCardList<T>) {
  const classes = useStyles();
  let gridInstance: GridComponent | null;

  function setGridInstance(grid: GridComponent) {
    gridInstance = grid;
  }

  function handleToolbarClick(args: any) {
    if (props.onToolbarClick) {
      props.onToolbarClick(args, gridInstance?.getSelectedRecords() as object[]);
    }

    switch (args.item.text) {
      case 'Excel Export':
        if (props.exportColumns) {
          const excelExportProperties: ExcelExportProperties = {
            exportType: 'CurrentPage',
          }
          excelExportProperties.columns = props.exportColumns;
          gridInstance?.excelExport(excelExportProperties);
        } else {
          gridInstance?.excelExport();
        }
        break;
    }

    if (props.afterToolbarClick) {
      props.afterToolbarClick(args as ClickEventArgs);
    }
  }



  const actionComplete = (_args: DialogEditEventArgs & { data?: string }) => {
    _.noop();
  }

  const filterOptions = {
    ...props.filterOptions ?? {},
    mode: 'Immediate', immediateModeDelay: 250, type: 'Menu'
  } as FilterSettingsModel;

  const toolbarOptions = props.toolbarOptions ?
      props.toolbarOptions :
      ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'ExcelExport'];
  console.log('SW@ toolbarOptions', toolbarOptions);

  const gridServices = [Sort, Edit, Filter, CommandColumn, ExcelExport, Resize] as object[];
  console.log('SW@ props.showToolbar', props.showToolbar);
  if (props.showToolbar) {
    gridServices.push(Toolbar);
  }

  function runCommand(specialCommand: string, args: unknown) {
    _.noop(specialCommand, args);
    // if (specialCommand === "award-year-date") {
    //   // CoreDebug.publish('grid', gridInstance);
    //   // CoreDebug.publish('args', args);
    //   const colIndex = gridInstance?.getColumnByField('date').index;
    //   args.dialog.contentEle.querySelectorAll('.e-rowcell')[colIndex]
    //           .querySelector('.e-float-text').innerHTML = 'Date';
    // }
  }

  const handleActionComplete = (args: any) => {
    //console.log('handleActionComplete', args);
    if (props.onAction) {
      switch (args.requestType) {
        case "save":
          props.onAction({action: args.action === 'add' ? 'add' : 'edit', data: args.data} as IEntityAction);
          break;
        case "delete":
          props.onAction({action: 'delete', data: args.data[0]} as IEntityAction);
          break;
        case "beginEdit":
          if (props.specialCommand) {
            runCommand(props.specialCommand, args);
          }
          break;
        default:
          console.log('non-handled action');
          break;
      }
    }
  };

  function handleInfoCardClick(infoCard: IInfoCard) {
    if (infoCard.projectId) {
      window.open(`/projects/${infoCard.projectId}`, '_blank');
    }
  }

  console.log('#@! infoCards: ', props.infoCards);

  return (
      <>
        {(props.title && props.showTitle) ?
            <h3 className={classes.sectionTitle}>{props.title}</h3> : ''
        }
        <Grid item>
          {props.customButtonsLeft}
        </Grid>
        {(props.listView) ?
            <Grid item style={{width: '100%', minHeight: '256px'}}>
              <GridComponent
                  ref={grid => setGridInstance(grid as GridComponent)}
                  id={props.id}
                  height={props.gridHeight}
                  //actionComplete={actionComplete}
                  toolbar={toolbarOptions}
                  toolbarClick={handleToolbarClick}
                  editSettings={props.editOptions}
                  sortSettings={props.sortingOptions}
                  allowSorting={true}
                  allowResizing={true}
                  allowFiltering={props.allowFiltering}
                  dataSource={props.items}
                  columns={props.columnDirectives}
                  gridLines={'Both'}
                  filterSettings={filterOptions}
                  allowExcelExport={props.allowExcelExport ?? true}
                  actionComplete={(e) => handleActionComplete(e)}>
                <Inject services={gridServices}/>
              </GridComponent>
            </Grid>
            :
            <div className={classes.cardHolder}>
              {props.infoCards.map((infoCard: IInfoCard) =>
                  <section>
                    <Card className={classes.smallCard} raised={true} onClick={() => handleInfoCardClick(infoCard)}>
                      <CardHeader className="info-card-header"
                                  title={<Tooltip title={infoCard.title ?? ''} aria-label={infoCard.title ?? ''}>
                                    <Typography noWrap gutterBottom variant="h6" component="h6">
                                      {infoCard.title}
                                    </Typography>
                                  </Tooltip>} subheader={infoCard.subheader}
                                  classes={{
                                    root: classes.cardHeaderRoot,
                                    content: classes.cardHeaderContent
                                  }}
                      />
                      <CardContent>
                        {infoCard.projectId &&
                          <img src="/images/projects/building-silhouette.png" alt="" style={{width: '270px'}}/>
                        }
                        {infoCard.fieldAndValues?.map((x: IInfoField) =>
                            <div style={{width: 300, paddingBottom: '5px'}}>
                              <span style={{fontWeight: 'bold', display: 'inline-block'}}>{x.field}</span><br/>
                              <span style={{display: 'inline-block'}}>{x.value ?? ''}</span>
                            </div>
                        )}
                      </CardContent>
                    </Card>
                  </section>

              )}
            </div>
        }
      </>
  );
}

