import React, { createContext, useState, useContext } from 'react';

type DashResolverContextType = {
  forceReset: () => void;
};

const DashResolverContext = createContext<DashResolverContextType | undefined>(undefined);

const DashResolverProvider: React.FC = ({ children }) => {
  const [shouldInvalidate, setShouldInvalidate] = useState(false);
  const [isReloading, setIsReloading] = useState(false);

  const forceReset = () => {
    setIsReloading(true);
    setShouldInvalidate(true);
    setTimeout(() => {
      setIsReloading(false);
      setShouldInvalidate(false);
    }, 200); // half a second timeout
  };

  return (
      <DashResolverContext.Provider value={{ forceReset }}>
        {shouldInvalidate ? null : children}
        {isReloading && <div>reloading...</div>}
      </DashResolverContext.Provider>
  );
};

const useDashResolverContext = () => {
  const context = useContext(DashResolverContext);
  if (!context) {
    throw new Error('useDashResolverContext must be used within a DashResolverProvider');
  }
  return context;
};

export { DashResolverProvider, useDashResolverContext };
