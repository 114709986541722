import React from 'react';
import {SideNavChildrenProps} from "types/searchTypes";

export default function BasicFacet(facetFieldInfo: SideNavChildrenProps) {
  return (
    <div>
      <span>{facetFieldInfo.field}</span><br />
      <span>{facetFieldInfo.label}</span><br />
      <span>{facetFieldInfo.type}</span><br />
    </div>
  )
}