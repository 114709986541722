import {Column, ColumnDirective, EditSettingsModel, SortSettingsModel} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardList, ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {IApiProjectHistory} from "services/apiClients/AthenaClient";
import {ValidationRule} from "react-hook-form";
import noop from "utils/noop";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
const y = ({} as Column).editType;
noop(y);

//// types of editType's
// defaultEdit
// dropDownEdit
// booleanEdit
// datePickerEdit
// dateTimePickerEdit
// numericEdit


export function getEducationConfig(userRoles: string[]) {
  const columns = [
      {
        headerText: 'School',
        field: 'school',
        width: 200,
        allowSorting: true,
        validationRules: { required: true },
      } as Partial<Column>,
      {
        headerText: 'Year',
        field: 'year',
        width: 100,
        format: {format:'####', useGrouping:false},
        editable: false,
        editType: 'numericedit',
        edit: {params:{format:'####', useGrouping:false, decimals:0}},
        allowSorting: true,
        validationRules: { required: true, number: true },
      } as Partial<Column>,
      {
        headerText: 'Degree',
        field: 'degree',
        width: 100,
        allowSorting: true,
        validationRules: { required: true },
      } as Partial<Column>,
      {
        headerText: 'Major',
        field: 'major',
        width: 150,
        allowSorting: true,
        validationRules: { required: true },
      } as Partial<Column>,
    ] as Column[];

  const sortingOptions = {
    columns:  [{ field: 'year', direction: 'Descending' }]
  } as SortSettingsModel;

  let editOptions: EditSettingsModel = {
    allowEditing: false, allowAdding: false, allowDeleting: false,
    mode: 'Dialog',
    //allowEditOnDblClick: true,
  };

  if (hasPermission(['Contributor'],userRoles)) {
    const changes = { allowEditing: true, allowAdding: true, allowDeleting: true };
    editOptions = {...editOptions, ...changes}
  }

  const config = {
    columnDirectives: columns,
    sortingOptions: sortingOptions,
    editOptions: editOptions,
    title: 'Education',
    showTitle: true,
  } as ISectionWithGridAndCardListConfig;

  return config;
}
