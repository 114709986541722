import {makeStyles} from "@material-ui/core/styles";

export const useCardViewStyles = makeStyles(() => ({
  mainBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    alignContent: "stretch"
  },
  cardViewWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  searchCardBlock: {
    display: 'block',
    height: 'calc(100vh - 160px)',
    overflowY: 'auto',
  }
}));