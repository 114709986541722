import { useRef } from "react";
import {FacetMemberInfo} from "../../../types/searchTypes";
import {splitOnCaseChange} from "../../../utils/stringUtils";



export function alignMemberCounts(originalMembers: FacetMemberInfo[], counts: Record<string, number>) {
  for (let i = 0; i < originalMembers.length; i++) {
    const member = originalMembers[i];
    if (!member.value) continue;
    const count = counts[member.value];


    member.count = count !== undefined ? count : 0;
  }
}

export function filterMembersForBusinessReason(entityName: string, field: string, members?: FacetMemberInfo[]) {
  if (members) {
    let filteredMembers = members;
    // bad data
    if (entityName === 'people' && field === 'status') {
      filteredMembers = members!.filter(x => x.value !== 'overPowered');
    }

    return filteredMembers;
  } else {
    return members;
  }
}

export function makeAutocompleteFacetPlaceholder(field?: string): string {
  if (!field) return 'Search';
  const phrase = splitOnCaseChange(field as string).map(word => word.toLowerCase()).join(' ');
  return 'Search ' + phrase;
}













export const Ticks = () => {
  return <div>{new Date().getTime()}</div>
}

export const Counter = ({title = ''}) => {
  const renderCounter  = useRef(0);
  renderCounter.current = renderCounter.current + 1;
  return <div>Renders: {renderCounter.current}, {title}</div>;
};
