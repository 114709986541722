import React, {useEffect, useRef, useState} from 'react';
import {IQueryablePerson, IQueryableProject} from "services/apiClients/AthenaClient";
import ProjectCard from "./ProjectCard";
import PersonCard from "./PersonCard";
import CardViewSortBar from "./CardViewSortBar";
import useAthenaClient from "hooks/useAthenaClient";
import useDashUrlInfo from "hooks/useDashUrlInfo";
import {ICriteria} from "services/SearchService";
import {encodeForAthenaFilters} from "services/search/QueryConvertor";
import DashSort from "types/domain/sorting/DashSort";
import AthenaSort from "types/domain/sorting/AthenaSort";
import {useNavigate} from "react-router-dom";
import {useCardViewStyles} from "./cardViewStyles";
import {noop} from "lodash";

export interface CardViewProps {
  items?: IQueryableProject[] | IQueryablePerson[],
  onViewChange?: (viewName: string) => void,
}

export const CardView = (props: CardViewProps) => {
  const classes = useCardViewStyles();
  const {athenaClient} = useAthenaClient();
  const navigate = useNavigate();
  const [items, setItems] = useState([] as IQueryableProject[] | IQueryablePerson[]);
  const [sort, setSort] = useState<DashSort>(new DashSort('name', 'asc'));
  const [loading, setLoading] = useState(false);
  const {entityName, id, filters, search} = useDashUrlInfo();

  function getCriteria() {
    const criteria: ICriteria = {
      entityName: entityName,
      search: search,
      filter: encodeForAthenaFilters(filters),
      orderby: ''
    }
    noop(props);
    return criteria;
  }

  const criteria = getCriteria();

  useEffect(() => {
    const refreshData = async () => {
      if (athenaClient) {
        const athenaSort = AthenaSort.fromDashSort(sort);
        const searchResultSet = await athenaClient.getAllEntities(entityName, criteria.search, criteria.filter, 0, 60, athenaSort.toString());
        setItems(searchResultSet.value as IQueryableProject[] | IQueryablePerson[]);
        setLoading(false);
      }
    }

    refreshData();
  }, [athenaClient, criteria.entityName, criteria.search, criteria.filter, sort.field, sort.direction]);

  function handleSort(incomingField: string) {
    const {field} = sort;
    const newDirection = field === incomingField ? (sort.isAscending() ? 'desc' : 'asc') : 'asc';
    const newSort = new DashSort(incomingField, newDirection);
    setSort(newSort);
  }

  function handleNavDetails(entityName: string, id: string) {
    const entity = entityName.toLowerCase().trim() === 'projects' ? 'projects' : 'people';
    const urlPart = entity;
    const path = `/${urlPart}/${id}`;
    navigate(path);
  }

  const [page, setPage] = useState(0); // To keep track of current page number
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Function to load more items
  const loadMoreItems = async () => {
    setLoading(true);
    const athenaSort = AthenaSort.fromDashSort(sort);
    const newPage = page + 1;
    const searchResultSet = await athenaClient.getAllEntities(entityName, criteria.search, criteria.filter, newPage * 60, 60, athenaSort.toString());
    setItems(prevItems => [...prevItems, ...(searchResultSet.value as IQueryableProject[] | IQueryablePerson[])]);
    setPage(newPage);
    setLoading(false);
  };

  // Effect for infinite scrolling
  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const isBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
        if (isBottom) {
          loadMoreItems();
        }
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [items, loading]); // Only re-run if items or loading state changes

  useEffect(() => {
    const refreshData = async () => {
      if (athenaClient) {
        const athenaSort = AthenaSort.fromDashSort(sort);
        const searchResultSet = await athenaClient.getAllEntities(entityName, criteria.search, criteria.filter, 0, 60, athenaSort.toString());
        setItems(searchResultSet.value as IQueryableProject[] | IQueryablePerson[]);
        setLoading(false);
      }
    }

    refreshData();
    setPage(0);  // Reset the page counter
  }, [athenaClient, criteria.entityName, criteria.search, criteria.filter, sort.field, sort.direction]);

  return (
      <div className={classes.cardViewWrap}>
        <CardViewSortBar sort={sort.toString()} sortClick={handleSort}/>
        <div
            ref={scrollContainerRef}
            style={{height: 'calc(100vh - 160px)'}}
            className="flex flex-row flex-wrap justify-start items-baseline content-stretch overflow-y-auto"
        >
          {items.map(m => (
              <div>
                {(entityName === 'projects')
                    ? <ProjectCard model={m as IQueryableProject} onNavDetails={handleNavDetails}/>
                    : <PersonCard model={m as IQueryablePerson} onNavDetails={handleNavDetails}/>
                }
              </div>
          ))}
        </div>
      </div>
  );
};

// todo: refactor this to simple system
// const possibleOrderByStates = `name|name desc|cost|cost desc|
//           squareFootage|squareFootage desc|
//           location|location desc|hpExperience|hpExperience desc`
//         .split('|').map(x => x.trim());