import React from 'react';

interface IDashMapProps {
  location: string;
  style?: React.CSSProperties;
  visible?: boolean;
}

function DashMap({ location, style, visible = true }: IDashMapProps) {
  const API_KEY = 'AIzaSyCHezmO-FsvZyFPVyr6FkPBz5GcvvVYD1U';
  const encodedLocation = encodeURIComponent(location ?? '');
  const mapSourceURL = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${encodedLocation}`;

  const mergedStyle: React.CSSProperties = {
    ...(style || {}),
    ...(visible
        ? { visibility: 'visible' }
        : { width: 0, height: 0, visibility: 'hidden' }),
  };

  return (
      <iframe
          title="Google Maps"
          style={mergedStyle}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={mapSourceURL}>
      </iframe>
  );
}

export default DashMap;

