
// future .env file
const CRM_APP_ID = '6bc1724a-eb95-ea11-a811-000d3a3be645';
const CRM_BASE_URL = 'https://henselphelps.crm.dynamics.com/main.aspx';

export function getCrmAccountLink(id?: string | null) {
  if (!id) return '';
  const sp = new URLSearchParams(
      { appid: CRM_APP_ID, pagetype: 'entityrecord', etn: 'account', id: id }
  );
  return CRM_BASE_URL + '?' + sp.toString();
}

export function getCrmContactLink(id?: string | null) {
  if (!id) return '';
  const sp = new URLSearchParams(
      { appid: CRM_APP_ID, pagetype: 'entityrecord', etn: 'contact', id: id }
  );
  return CRM_BASE_URL + '?' + sp.toString();
}