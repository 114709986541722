import {
  Column,
  ColumnDirective,
  EditSettingsModel,
  FilterSettingsModel, IEditCell,
  SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import {ISectionWithGridAndCardList, ISectionWithGridAndCardListConfig} from "../../shared/SectionWithGridAndCardList";
import {IApiProjectHistory} from "services/apiClients/AthenaClient";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import _ from "lodash";

export function getPeopleAwardConfig(userRoles: string[]) {
  const columns = [
    {
      headerText: 'Title',
      field: 'title',
      width: 200,
      allowSorting: true,
      validationRules: {required: true},
    } as Partial<Column>,
    {
      headerText: 'Subtitle',
      field: 'subtitle',
      width: 100,
      editable: false,
      allowSorting: true,
    } as Partial<Column>,
    {
      headerText: 'Sponsor',
      field: 'sponsor',
      width: 100,
      allowSorting: true,
    } as Partial<Column>,
    {
      headerText: 'Date',
      field: 'date',
      width: 150,
      editType: 'datePickerEdit',
      validationRules: {required: true},
      edit: {},
      format: {type: 'date', skeleton: 'yMd'},
      allowSorting: true,
    } as Partial<Column>,
    {
      headerText: 'Citation',
      field: 'citation',
      width: 150,
      allowSorting: true,
    } as Partial<Column>,
  ] as Column[];

  const exportColumns = _.cloneDeep(columns);

  const sortingOptions = {
    columns: [{field: 'date', direction: 'Descending'}]
  } as SortSettingsModel;

  const filterOptions = {
    ignoreAccent: true,
    columns: [{field: 'title', operator: 'startsWith'}]
  } as FilterSettingsModel;

  let editOptions: EditSettingsModel = {
    allowEditing: false, allowAdding: false, allowDeleting: false,
    mode: 'Dialog',
    //allowEditOnDblClick: true,
  };

  if (hasPermission(['Contributor'],userRoles)) {
    const changes = { allowEditing: true, allowAdding: true, allowDeleting: true };
    editOptions = {...editOptions, ...changes}
  }

  const config = {
    columnDirectives: columns,
    exportColumns: exportColumns,
    sortingOptions: sortingOptions,
    editOptions: editOptions,
    filterOptions: filterOptions,
    title: 'Awards',
    showTitle: false,
    allowAdd: true,
    allowRemove: true,
  } as ISectionWithGridAndCardListConfig;
  return config;
}
