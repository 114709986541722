export interface IJobTemplate {
  jobTitle: string
  template: string
}

export function getJobTemplate(searchJobTitle: string): string | null {
  const lowerJobTitle = searchJobTitle.toLowerCase()
  const jobTemplate = jobTemplates.find((job) => job.jobTitle === lowerJobTitle)
  return jobTemplate ? jobTemplate.template : null
}

const jobTemplates: IJobTemplate[] = [
  {
    jobTitle: 'area superintendent',
    template:
      'In {{possessivePronoun}} position as Area Superintendent, {{firstName}} supervises and coordinates work for Hensel Phelps’ employees and our trade partners. {{capitalizedPossessivePronoun}} knowledge of production, purchasing, equipment and scheduling distinguish {{objectPronoun}} as a top-to-bottom leader. {{capitalizedSubjectPronoun}} has a strong focus on safety culture, is fully conversant in MEP coordination, cost control, quality and management skills from {{possessivePronoun}} prior experience on a range of project types. {{capitalizedSubjectPronoun}} is thoroughly knowledgeable of building codes, permitting and other regulatory processes, bringing effective management and efficient organization of the day-to-day onsite administration to every project.',
  },
  {
    jobTitle: 'business development manager',
    template:
      '{{firstName}}, Business Development Manager, provides support for the procurement effort by actively gathering information on potential opportunities and managing client relationships to gain insight on their goals. {{capitalizedSubjectPronoun}} is responsible for ensuring a potential client’s business model matches Hensel Phelps’ risk tolerance. {{capitalizedSubjectPronoun}} supports procurement efforts to ensure a streamlined, focused approach for the pursuit.',
  },
  {
    jobTitle: 'chief estimator',
    template:
      'As the Chief Estimator, {{firstName}} leads a team responsible for all estimating and procurement for the {{districtName}} region. Reporting directly to the Regional Manager, {{subjectPronoun}} manages all budget controls, project margins and procurement decisions for all projects. {{capitalizedSubjectPronoun}} coordinates future market analysis and construction cost tracking and leads all pricing reviews and bid management. {{capitalizedSubjectPronoun}} is committed to Hensel Phelps’ ethical procurement and purchasing procedures.',
  },
  {
    jobTitle: 'ceo',
    template:
      '{{firstName}} serves as the Chairman and Chief Executive Officer for Hensel Phelps. In this role, {{subjectPronoun}} is responsible for providing strategic leadership for the company by working with the executive management team and board of directors, including the vice presidents/regional managers, to establish long-range goals, strategies, plans and policies. {{capitalizedSubjectPronoun}} is also charged with advancing employee engagement and overseeing all operations and business activities to ensure they produce the desired results consistent with Hensel Phelps’ overall strategy and mission. With over 25 years of experience, {{subjectPronoun}} provides extensive construction and managerial expertise to support all aspects of field operations and project management. ',
  },
  {
    jobTitle: 'chairman and chief executive officer (CEO)',
    template:
      '{{firstName}} serves as the Chairman and Chief Executive Officer for Hensel Phelps. In this role, {{subjectPronoun}} is responsible for providing strategic leadership for the company by working with the executive management team and board of directors, including the vice presidents/regional managers, to establish long-range goals, strategies, plans and policies. {{capitalizedSubjectPronoun}} is also charged with advancing employee engagement and overseeing all operations and business activities to ensure they produce the desired results consistent with Hensel Phelps’ overall strategy and mission. With over 25 years of experience, {{subjectPronoun}} provides extensive construction and managerial expertise to support all aspects of field operations and project management. ',
  },
  {
    jobTitle: 'corporate director of safety and health',
    template:
      'As the Corporate Director of Safety & Health, {{firstName}} provides overall direction and leadership to achieve Hensel Phelps’ safety goals, as well as maintain compliance with all applicable federal, state, local and client/industry safety requirements. {{capitalizedSubjectPronoun}} directs and supports executive leadership through the Corporate Safety Task Force. Additionally, {{subjectPronoun}} is responsible for developing, implementing and overseeing Hensel Phelps’ Safety and Health, Risk Management and Loss Control Programs including the management of general and automobile liability, worker’s compensation and all medical claims. {{capitalizedSubjectPronoun}} participates in regional, national and international safety activities and represents the company in all dealings with OSHA and other regulatory agencies.',
  },
  {
    jobTitle: 'chief health & safety officer',
    template:
      'As Chief Health & Safety Officer, {{firstName}} provides overall direction and leadership to achieve Hensel Phelps’ safety goals, as well as maintain compliance with all applicable federal, state, local and client/industry safety requirements. {{capitalizedSubjectPronoun}} directs and supports executive leadership through the Corporate Safety Task Force. Additionally, {{subjectPronoun}} is responsible for developing, implementing and overseeing Hensel Phelps’ Safety and Health, Risk Management and Loss Control Programs, including the management of general and automobile liability, worker’s compensation and all medical claims. {{capitalizedSubjectPronoun}} participates in regional, national and international safety activities and represents the company in all dealings with OSHA and other regulatory agencies.',
  },
  {
    jobTitle: 'chief administrative officer',
    template:
      'The Chief Administrative Officer oversees key corporate departments to ensure administrative and operational efforts throughout Hensel Phelps are executed in an efficient and effective way. Matt rose through the ranks from field engineer, office engineer, project manager, operations manager and director of operations prior to being appointed chief administrative officer.',
  },
  {
    jobTitle: 'project superintendent',
    template:
      'As Project Superintendent, {{firstName}} leverages {{possessivePronoun}} leadership expertise to supervise on-site self-performed labor and trade partners and manage of all aspects of safety and quality control. {{capitalizedPossessivePronoun}} recent pre-planning efforts and home office support has provided {{objectPronoun}} with a well-rounded knowledge of what makes a project successful. {{capitalizedPossessivePronoun}} supervisory strengths include labor relations, labor supervision, labor cost control, equipment selection, contract administration, safety management and various scheduling techniques. {{capitalizedSubjectPronoun}} actively participates in in-house superintendent training programs to establish the consistency and quality that Hensel Phelps demands.',
  },
  {
    jobTitle: 'director of design & sustainability',
    template:
      'As Director of Design and Sustainability, {{firstName}} oversees all design and sustainability efforts within Hensel Phelps. {{capitalizedSubjectPronoun}} establishes policies, training and strategies that implement growth within the firm, along with the ability to support our clients with outstanding preconstruction services. {{firstName}} works with clients and senior management within the organization to formulate the most qualified project team to deliver a successful project that incorporates effective design and sustainability practices. Working alongside our development, construction and services divisions, {{firstName}} ensures the company maintains the highest levels of design quality, constructability, sustainability and overall service it has become known for across the country.',
  },
  {
    jobTitle: 'design manager',
    template:
      'Design Manager, {{firstName}}, coordinates and communicates design-related activities, including design requirements and program modifications, between the architectural, engineering, estimating and operations teams. {{capitalizedSubjectPronoun}} functions as the primary representative for Hensel Phelps with the client during the design phase of the project and leads the Hensel Phelps design-build team through value engineering studies, design charettes, constructability reviews and design presentations with the stakeholders. {{capitalizedSubjectPronoun}} brings a wealth of experience on many different project types and is knowledgeable on the building and zoning codes, production of design contract documents and construction-related activities.',
  },
  {
    jobTitle: 'director of operations',
    template:
      'As Director of Operations, {{firstName}} manages operations for the {{districtName}} region. Highly focused on the client experience, {{firstName}} establishes policies and strategies for growth, while ensuring Hensel Phelps maintains the highest levels of safety, quality, and service. Having risen through the Hensel Phelps ranks, {{subjectPronoun}} has comprehensive experience in the construction industry and provides the leadership and technical expertise to meet client expectations and bring each project to successful completion.',
  },
  {
    jobTitle: 'corporate director of project development',
    template:
      '{{firstName}} serves as Hensel Phelps’ procurement management representative and implements comprehensive and strategic procurement strategies. Leveraging {{possessivePronoun}} experience and connections in the construction industry, {{subjectPronoun}} is focused on the client experience and identifies key markets and projects to pursue. In addition, {{subjectPronoun}} develops and executes procurement strategies and analyses project performance compared to projections.',
  },
  {
    jobTitle: 'project development director',
    template:
      '{{firstName}} serves as Hensel Phelps’ procurement management representative and implements comprehensive and strategic procurement strategies. Leveraging {{possessivePronoun}} experience and connections in the construction industry, {{subjectPronoun}} is focused on the client experience and identifies key markets and projects to pursue. In addition, {{subjectPronoun}} develops and executes procurement strategies and analyses project performance compared to projections.',
  },
  {
    jobTitle: 'director of safety and health',
    template:
      'As the Director of Safety & Health for the {{districtName}} Region, {{firstName}} manages the Hensel Phelps Safety and Health Accident Program and Risk Management and Loss Control Program. This includes the management of general and automobile liability, worker’s compensation and all medical claims.{{capitalizedSubjectPronoun}} participates in pre-job planning conferences to establish safety plans for all new contracts and represents Hensel Phelps in all dealings with OSHA. {{firstName}} conducts periodic safety inspections of each jobsite in the region, including both trade partner operations and Hensel Phelps self-work, using OSHA criteria as well as the, often more restrictive, Hensel Phelps safety program criteria.',
  },
  {
    jobTitle: 'director of vdc',
    template:
      'As the Director of Virtual Design & Construction (VDC), {{firstName}} is pushing the limits of how Hensel Phelps and the Architecture/Engineering/Construction (AEC) industry use virtual design and construction tools. He specializes in identifying processes and systems that enhance design and construction integration through innovation. This includes the development of the VDC Plan of Work at the start of every project, putting the scope of work on every phase of the job starting from procurement through owner turnover. {{firstName}} also oversees the Reality Capture group, which continues to leverage new technologies towards establishing existing conditions, design support and validation of installations.',
  },
  {
    jobTitle: 'estimator',
    template:
      'As a member of the estimating team, {{firstName}} develops trade partner costs and estimates on a CSI Division or Uniformat basis. {{capitalizedSubjectPronoun}} develops estimates for all Hensel Phelps self-performed work including concrete and formwork, building specialties, carpentry, millwork and other finishes. Using architectural and engineering drawings and specifications, {{subjectPronoun}} oversees the development of trade partner and supplier scopes of work and monitors trade partner pricing.',
  },
  {
    jobTitle: 'field engineer',
    template:
      '{{firstName}} reports to the area or project superintendent to interpret and coordinate efforts related to the architectural and engineering documents in the field. Upon Notice to Proceed, {{subjectPronoun}} develops and implements the plan for project layout, site surveys and site controls. {{firstName}} coordinates all RFIs between the Hensel Phelps management team, trade partners and the design professionals. As the chief assistant to the area or project superintendent, {{subjectPronoun}} tracks all materials, trade partner and labor activities pertaining to the contract documents.',
  },
  {
    jobTitle: 'general superintendent',
    template:
      "{{firstName}} has extensive experience in the coordination and direction of large and complex construction projects. {{capitalizedPossessivePronoun}} organizational skills include a deep understanding of the importance of project logistics and team building from the inception of a contract. With an outstanding safety performance record, {{subjectPronoun}} has completed all projects assigned on schedule, within budget and to the client's complete satisfaction. {{firstName}}’s portfolio of successfully completed projects is evidence of {{possessivePronoun}} exceptional planning and leadership skills through a variety of project types and delivery methods.",
  },
  {
    jobTitle: 'lead estimator',
    template:
      'As the Lead Estimator, {{firstName}} develops CSI Division and Uniformate estimates and produces all documentation and forms necessary to produce a complete estimate. {{capitalizedSubjectPronoun}} works closely in conjunction with the senior estimator to develop pricing strategies and specific purchasing methodology for major projects. It is {{possessivePronoun}} responsibility to develop a working relationship with trade partner principals and estimating departments.',
  },
  {
    jobTitle: 'lead quality control engineer',
    template:
      'As an experienced Lead Quality Control Engineer, {{firstName}} manages all quality control functions, with emphasis on the use of preparatory and initial inspection phases to avoid field problems. {{capitalizedSubjectPronoun}} oversees all quality control aspects including documentation for each definable feature of work, testing of construction materials, inspection of construction methods and verification of compliance with plans and specifications.',
  },
  {
    jobTitle: 'lead vdc engineer',
    template:
      'As Hensel Phelps’ Lead Virtual Design and Construction (VDC) Engineer, {{firstName}} manages the building model information and supports the regional VDC Manager across multiple VDC implementation efforts. {{firstName}} applies {{possessivePronoun}} project engineering experience to successfully implement and coordinate cost and schedule information into the project’s Building Information Modeling (BIM) software. {{capitalizedSubjectPronoun}} works closely with on-site VDC Engineers as a resource, supporting the team to use BIM software as tool to better manage all elements of the project.',
  },
  {
    jobTitle: 'marketing coordinator',
    template:
      'As Marketing Coordinator, {{firstName}} assists with the preparation of regional marketing collateral, RFQ/RFP responses and production, local advertisements, special events and database management for projects and campaigns. {{capitalizedSubjectPronoun}} works closely with the Proposal Manager and Senior Marketing Coordinator on various other tasks including the preparation of presentations, industry award applications and additional marketing collateral.',
  },
  {
    jobTitle: 'marketing & communications mgr',
    template:
      'As Marketing and Communications Manager, {{firstName}} oversees and implements internal and external marketing and communications programs, effectively describing and promoting the organization and its services to our clients. {{capitalizedSubjectPronoun}} manages, develops and drives marketing content and activities to efficiently and effectively communicate to both internal and external audiences. Responsible for developing marketing strategies and initiatives that align with the overall growth of Hensel Phelps, {{firstName}} is a thought leader within Hensel Phelps, promoting brand awareness through an organized communications plan.',
  },
  {
    jobTitle: 'office engineer',
    template:
      'As an office engineer, {{firstName}} processes and submits client and trade partner pay applications, coordinates material deliveries, checks shop drawings, processes submittal and updates project schedule in addition to coordination of trade partner operations. {{capitalizedPossessivePronoun}} experience in the field has provided {{objectPronoun}} with a well-rounded understanding of the day-to-day operations on a construction site where {{subjectPronoun}} has been responsible for utility layout, MEP coordination and supervision of Hensel Phelps field operations, preparation of daily field logs, 3-week schedules, quality control experience including coordination of quality control testing and preparation of daily QC reports.',
  },
  {
    jobTitle: 'president and chief operating officer (COO)',
    template:
      '{{firstName}} {{lastName}} serves as the President and Chief Operating Officer for Hensel Phelps and associated entities. In this role, {{firstName}} has responsibility for corporate initiatives and overall operational excellence. {{firstName}} began his career with Hensel Phelps, rising through the ranks from Field Engineer, Office Engineer, Project Engineer, Project Manager, Operations Manager, Regional Manager/Vice President and Executive Vice President prior to being appointed President and Chief Operating Officer.',
  },
  {
    jobTitle: 'chief operating officer',
    template:
      '{{firstName}} {{lastName}} serves as the President and Chief Operating Officer for Hensel Phelps and associated entities. In this role, {{firstName}} has responsibility for corporate initiatives and overall operational excellence. {{firstName}} began his career with Hensel Phelps, rising through the ranks from Field Engineer, Office Engineer, Project Engineer, Project Manager, Operations Manager, Regional Manager/Vice President and Executive Vice President prior to being appointed President and Chief Operating Officer.',
  },
  {
    jobTitle: 'regional manager / vice president',
    template:
      '{{firstName}} serves as Regional Manager/Vice President, responsible for the overall management and operations of the {{districtName}} region. As Vice President, {{subjectPronoun}} establishes policies and strategies for the growth of the firm, while ensuring Hensel Phelps maintains the high levels of safety, quality and service it has become known for throughout the United States. Having risen through the ranks by serving in both field and office supervisory roles, {{possessivePronoun}}  extensive experience in the construction industry enables {{objectPronoun}} to provide leadership and technical expertise to each project. {{capitalizedSubjectPronoun}} ensures that the necessary resources are available to meet client expectations and bring each project to successful completion.',
  },
  {
    jobTitle: 'vice president',
    template:
      '{{firstName}} serves as Regional Manager/Vice President, responsible for the overall management and operations of the {{districtName}} region. As Vice President, {{subjectPronoun}} establishes policies and strategies for the growth of the firm, while ensuring Hensel Phelps maintains the high levels of safety, quality and service it has become known for throughout the United States. Having risen through the ranks by serving in both field and office supervisory roles, {{possessivePronoun}}  extensive experience in the construction industry enables {{objectPronoun}} to provide leadership and technical expertise to each project. {{capitalizedSubjectPronoun}} ensures that the necessary resources are available to meet client expectations and bring each project to successful completion.',
  },
  {
    jobTitle: 'operations manager',
    template:
      'As Operations Manager, {{firstName}} provides leadership and vision to our project teams and clients, a role crucial to the success of each project. By leveraging our proven systems for project finances, schedule and schedule management, resource allocation and quality assurance, as well as {{possessivePronoun}}  experience in construction, {{subjectPronoun}} assures that all client budgetary, safety and quality goals are met. {{firstName}} is highly experienced in all areas of estimating, purchasing, engineering, superintendency and project management on both new construction and renovation projects through various delivery methods.',
  },
  {
    jobTitle: 'project engineer',
    template:
      'As a project engineer, {{firstName}} oversees the onsite project administration. {{capitalizedSubjectPronoun}} is experienced in cost control, submittal coordination, MEP coordination, expediting material deliveries, trade partner management, negotiating changes, scheduling, and general administrative operations. Ensuring that all work is approved prior to fabrication, delivery, and installation, {{firstName}} places special attention on the resolution of interface conflicts, revisions, exception and required submittals. {{capitalizedPossessivePronoun}} project experience covers both public and private clients through various delivery methods.',
  },
  {
    jobTitle: 'project manager',
    template:
      "{{firstName}} serves as Hensel Phelps' management representative, responding to all day-to-day requirements and concerns of the client. {{capitalizedSubjectPronoun}} has extensive experience in developing and monitoring project master schedules, estimating, job cost reports and establishing and implementing effective communication procedures for all team components. Having worked closely with a variety of corporate clients on a wide range of project types under various contracting methods, {{firstName}} can isolate and resolve various design and compliance issues early in a project's evolution. {{capitalizedPossessivePronoun}} aptitude and expertise are proven by the many successful projects in {{possessivePronoun}} resume.",
  },
  {
    jobTitle: 'proposal manager',
    template:
      'As Proposal Manager, {{firstName}} oversees procurement opportunities for the {{districtName}} region. {{capitalizedSubjectPronoun}} develops win strategies as well as the development of the pursuit execution plan. {{capitalizedPossessivePronoun}} organizational and managerial skills are a key component in the success of the Project Development Department. In addition to knowledge and skills in overall proposal management, {{subjectPronoun}} is also skilled in advertising, communications, event management, editing, media & public relations and the development of marketing collateral for the {{districtName}} region.',
  },
  {
    jobTitle: 'regional director of planning',
    template:
      'As the Regional Director of Project Planning and Development, {{firstName}} evaluates the technical needs of all project pursuits within the Division. {{capitalizedSubjectPronoun}} advises and supports specific divisional pursuits and maintains strong client and consultant relationships on a national level. {{capitalizedSubjectPronoun}} coordinates corporate efforts among the regions and provides strategic leadership to ensure the consistency and integrity of the brand is maintained. Additionally, {{subjectPronoun}} is involved in spearheading corporate marketing initiatives to continually elevate the Project Development Department.',
  },
  {
    jobTitle: 'quality control manager',
    template:
      '{{firstName}} is responsible for coordination of trade partner activities, approval of submittals, preparing/coordinating contract changes, inspections and tests required to ensure that the specified materials are used and installation is acceptable to produce the required end product as well as timely documentation. {{capitalizedSubjectPronoun}} is adept at applying {{possessivePronoun}} construction knowledge and experience to control quality and accomplish specific contract requirements. {{capitalizedSubjectPronoun}} utilizes the three-phase control concept (preparatory, initial, follow-up/completion) to prevent problems and deficiencies.',
  },
  {
    jobTitle: 'safety engineer',
    template:
      '{{firstName}} is thoroughly knowledgeable of all safety and health technical requirements, considerations and protective measures to achieve an accident-free work site. {{capitalizedSubjectPronoun}} is particularly skilled in safety training and performing safety inspections and hazard/risk analysis. {{capitalizedPossessivePronoun}} safety experience also translates into review and analysis of all trade partners on-site to assure that all participants of the construction effort maintain the highest safety standards.',
  },
  {
    jobTitle: 'safety manager',
    template:
      '{{firstName}} is responsible for supporting the project team in the successful completion of a construction project. {{capitalizedSubjectPronoun}} is thoroughly knowledgeable of all safety and health technical requirements, considerations, and protective measures to be instituted to achieve an accident-free work site. Particularly skilled in safety training and performing safety inspections and hazard/risk analysis, {{possessivePronoun}} safety experience also includes review and analysis of all trade partners on-site to assure that all participants of the construction effort maintain the highest safety standards.',
  },
  {
    jobTitle: 'senior safety manager',
    template:
      '{{firstName}} supports and oversees multiple project teams and safety initiatives. {{capitalizedSubjectPronoun}} is thoroughly knowledgeable of all safety and health technical requirements, considerations and protective measures to achieve an accident-free work site. To assist the regional director of safety & health, {{subjectPronoun}} actively participates in pre-job planning conferences to help establish safety plans for all new contracts. An essential element of {{possessivePronoun}} approach is to conduct periodic safety inspections of each jobsite, including both trade partner operations and Hensel Phelps self-work, using OSHA criteria and the, often more restrictive, Hensel Phelps safety program criteria.',
  },
  {
    jobTitle: 'senior estimator',
    template:
      'As Senior Estimator, {{firstName}} manages project costs by establishing preliminary budgets and final cost estimates. {{capitalizedSubjectPronoun}} is experienced in all aspects of procurement for hard bid, conceptual and design-build projects; as well as preconstruction cost controls involving cost estimating, cost analysis and value engineering. {{capitalizedSubjectPronoun}} has performed cost estimates for all phases of design (schematic design, design development and construction documents) and leads the project team in achieving the common goal of completing projects within budget and without sacrificing quality.',
  },
  {
    jobTitle: 'senior marketing coordinator',
    template:
      'As Senior Marketing Coordinator, {{firstName}} oversees the preparation of regional RFQ/RFP responses and production, special events, marketing and communication activities and database management for projects and campaigns. {{capitalizedSubjectPronoun}} leads various marketing activities including the preparation of presentations, industry award applications and additional collateral including qualification statements, local advertisements, innovative marketing techniques, graphics and strategies. Additionally, {{subjectPronoun}} supports and trains the marketing coordinators on the team.',
  },
  {
    jobTitle: 'vdc engineer',
    template:
      'As Virtual Design and Construction (VDC) Engineer, {{firstName}} applies {{possessivePronoun}} project engineering experience to the successful implementation and coordination of Hensel Phelps’ cost and schedule information into the project’s Building Information Modeling (BIM) technology. In this role, {{subjectPronoun}} is responsible for the coordination of all Hensel Phelps and trade partner information as it relates to the BIM model during the design of the project and {{subjectPronoun}} leads the effort to perform all collision checks and other necessary coordination prior to the team performing work in the field. {{capitalizedSubjectPronoun}} also works closely with the project’s engineering staff to enable to the team to use BIM software as a tool to better manage all elements of the project.',
  },
  {
    jobTitle: 'vdc manager',
    template:
      'As Virtual Design & Construction Manager, {{firstName}} is highly skilled at coordinating various design disciplines utilizing Building Information Modeling (BIM) technology. {{capitalizedSubjectPronoun}} works closely with design teams and trade partners to facilitate constructability reviews and to develop efficient solutions regarding the coordination of building systems among various disciplines. Additionally, {{subjectPronoun}} supports procurement needs for animations and renderings throughout the pursuit process. As VDC Manager, {{subjectPronoun}} is responsible for managing the {{districtName}} VDC team and ensuring each project has the necessary training and resources to be successful.',
  },
  {
    jobTitle: 'director of mechanical systems',
    template:
      'As Director of Mechanical Systems, {{firstName}} is a corporate resource for project teams throughout all procurement and preconstruction efforts. {{capitalizedSubjectPronoun}} has extensive knowledge in mechanical systems design and applications and can quickly assess what best fits the need of the client or owner program requirements during the earliest stages of a project.',
  },
  {
    jobTitle: 'corp dir of supplier diversity',
    template:
      'As Corporate Director of Supplier Diversity, {{firstName}} oversees utilizing, coordinating, monitoring and reporting small and minority business initiatives for Hensel Phelps. {{capitalizedSubjectPronoun}} works directly with the procurement and project teams to promote and ensure small business inclusion, workforce development and community outreach. Additionally, {{subjectPronoun}} is responsible for all corporate documentation and adherence to Federal Small Business requirements.',
  },
  {
    jobTitle: 'cmms engineer',
    template:
      'As CMMS Engineer, {{firstName}} is a computerized maintenance manager responsible for the development, review and establishment of workflow processes and procedures utilizing industry best practices and client-established workflows. The CMMS Engineer is also responsible for the coordination and asset identification, data gathering and population of the CMMS solution.',
  },
  {
    jobTitle: 'development coordinator',
    template:
      '{{firstName}} coordinates the day-to-day predevelopment, development and post-development activities associated with projects, including the review of pay applications and associated approvals. {{capitalizedSubjectPronoun}} is responsible for maintaining and updating the development budget, as well as producing monthly development status reports.',
  },
  {
    jobTitle: 'director of operations',
    template:
      '{{firstName}} provides extensive operations, maintenance and managerial expertise and experience to support all aspects of the Services Group’s offerings. {{capitalizedSubjectPronoun}} establishes policies and strategies that allow for the growth of the Services Group, while ensuring the company maintains high levels of safety, quality, and service. {{capitalizedSubjectPronoun}} also provides leadership and technical expertise and ensures the necessary resources are available to meet client expectations and bring each project to successful completion.',
  },
  {
    jobTitle: 'director of planning',
    template:
      '{{firstName}} is responsible for the planning, set up and initial delivery of services for consulting, facility services and specialized construction.',
  },
  {
    jobTitle: 'director of technical services',
    template:
      'As the Director of Technical Services, {{firstName}} oversees the assets under management and is responsible for all aspects of client and tenant satisfaction as it pertains to operations and maintenance of the client’s facilities.',
  },
  {
    jobTitle: 'lead development coordinator',
    template:
      'As Lead Development Coordinator, {{firstName}} assists Hensel Phelps’ clients with project administration, financial and procurement activities. Responsibilities include: working closely with owners on the entitlement process, working closely with clients on design management, change management, owner payment, project tracking, budgeting, bid packaging, subcontracting and FF&E / OS&E procurement.',
  },
  {
    jobTitle: 'manager of technical procedure',
    template:
      'As Manager of Technical Procedure, {{firstName}} works directly with the team and/or client to ensure a thorough understanding of its investment objectives and the development of acceptable property-specific strategic and operating capital plans. Once developed and approved, {{subjectPronoun}} must oversee the implementation of approved initiatives and programs by managing and coordinating the efforts of the operations team (i.e. vendors, contractors and employees).',
  },
  {
    jobTitle: 'manager of technical services',
    template:
      '{{firstName}} provides senior-level technical support and direction to all facility operations personnel. {{capitalizedSubjectPronoun}} creates and implements annual risk and reliability life cycle programs, resulting in greater capital spend planning capabilities. {{capitalizedSubjectPronoun}} develops comprehensive client reports detailing productivity, quality, financial performance, customer satisfaction and operational execution. {{capitalizedSubjectPronoun}} will also develop and implement monthly Key Performance Indicators (KPIs) reports detailing operational, financial and work management statistics to demonstrate the health of the facility.',
  },
  {
    jobTitle: 'manager of transition / critical environment',
    template:
      'As Manager of Transition / Critical Environment, {{firstName}} creates and implements operational best practices and monthly reporting in the areas of operational Key Performance Indicators (KPIs), staffing, scheduling and financial. {{capitalizedSubjectPronoun}} is responsible for building a cohesive work environment and team to ensure superior client service, high retention of employees and a preferred place to work. {{capitalizedSubjectPronoun}} directs the start of all new tasks including budget analysis, assessment of owner needs and initiates development of an overall account plan.',
  },
  {
    jobTitle: 'area safety manager',
    template:
      'The Area Safety Manager oversees safety on multiple projects or in a specific region. {{capitalizedSubjectPronoun}} works closely with the regional or business unit director of safety and health to identify developing safety issues, solve chronic problem areas, implement new technology and training programs and similar company-wide initiatives. {{capitalizedSubjectPronoun}} also supports project teams when {{possessivePronoun}} experience is needed due to the complexity of the issue encountered.',
  },
  {
    jobTitle: 'bim engineer',
    template:
      'The BIM Engineer is an entry level VDC resource. {{capitalizedSubjectPronoun}} works with the design manager, field engineers, office engineers, project engineers and VDC engineers in a support function. {{capitalizedSubjectPronoun}} assists in model management, VDC-specific initiatives and VDC coordination between the design team, the engineering team, the specialty trades and operations.',
  },
  {
    jobTitle: 'chief people officer',
    template:
      '{{firstName}} oversees all People Operations within Hensel Phelps, including Human Resources and Continuous Improvement + Training. {{capitalizedSubjectPronoun}} develops effective processes and systems to support Hensel Phelps employees throughout their time with the company. {{firstName}} supports the Hensel Phelps vision and core values while positioning the company to continue to perform and grow for generations to follow. {{capitalizedSubjectPronoun}} understands that people are the heart of the company and it’s this culture that is the key to attracting, developing and engaging the diverse and talented individuals of Hensel Phelps.',
  },
  {
    jobTitle: 'coord of supplier diversity',
    template:
      'The Coordinator of Supplier Diversity supports the manager of supplier diversity, project manager and superintendents by overseeing the small business inclusion, project-specific workforce development and community outreach efforts at the project level.',
  },
  {
    jobTitle: 'director of electrical service',
    template:
      '{{firstName}} {{lastName}} offers more than 20 years of expertise in the preconstruction planning, coordination, constructability review and alternative solutions of electrical services on large, multi-use projects. {{capitalizedPossessivePronoun}} responsibilities include initial and follow-up discipline review meetings, alternative solutions for design and the management of Design Action Logs and Constructability Review Action Logs. {{firstName}} has a thorough understanding of the specific MEP requirements involved in a variety of projects types.',
  },
  {
    jobTitle: 'director of innovation',
    template:
      '{{firstName}} {{lastName}} oversees all technologically innovative initiatives within Hensel Phelps. {{capitalizedSubjectPronoun}} leads cross-functional teams, identifies growth opportunities and fosters a culture of continuous improvement. With expertise in emerging technologies, {{firstName}} collaborates with internal and external stakeholders to generate and implement creative ideas. {{capitalizedSubjectPronoun}} uses strong strategic thinking, effective communication and leadership to shape the organization’s future through cutting-edge solutions.',
  },
  {
    jobTitle: 'director of mechanical service',
    template:
      '{{firstName}} {{lastName}} leads Hensel Phelps’ technical engineering and preconstruction services. {{capitalizedSubjectPronoun}} collaborates with owners to create conceptual designs to develop scope of work and budget estimates. {{firstName}} also works with project management teams to develop overall schedule while managing time and tasks within schedule. {{capitalizedSubjectPronoun}} collaborates with vendors and trade partners to gather quotes and put together detailed estimates.',
  },
  {
    jobTitle: 'director of supplier diversity',
    template:
      'The Director of Supplier Diversity is responsible for leading {{possessivePronoun}} region’s or business unit’s diversity programs. {{capitalizedSubjectPronoun}} works closely with the Operations and Estimating teams to track and manage the success of the programs and report the results to senior management and owners.',
  },
  {
    jobTitle: 'executive vice president',
    template:
      'The Executive Vice President is responsible for overseeing and guiding all aspects of Hensel Phelps’ operations in their territory. This role involved strategic planning, executive decision-making and effective management of diverse construction projects. The EVP collaborates closely with the executive team to ensure the company’s overall success, profitability and continued growth.',
  },
  {
    jobTitle: 'marketing & communications lead',
    template:
      'The Marketing and Communications Lead is a key member of the marketing team that is charged with growing the company’s presence in the region through client development, marketing and communication outreach and project procurement. The marketing and communications lead will steer all internal and external communication efforts to ensure alignment with internally developed strategies while ensuring firm-wide brand compliance.',
  },
  {
    jobTitle: 'manager of supplier diversity',
    template:
      'As the Manager of Supplier Diversity, {{subjectPronoun}} provides management oversight to ensure full compliance with project specific MBE/DBE requirements. In this capacity, {{subjectPronoun}} conducts extensive outreach and develops community, small and local business partnering and workforce incorporation programs. {{capitalizedSubjectPronoun}} develops small and local business development strategies and implements the corporate trade partner Technical Assistance Program at the project level. {{capitalizedSubjectPronoun}} provides support for Mentor-Protégé programs, engages industry-based organizations and coordinates outreach initiatives with project management.',
  },
  {
    jobTitle: 'project estimator',
    template:
      'Project Estimators are routinely responsible for the development of all estimating forms and formats for both the conceptual estimate and final bid. {{capitalizedSubjectPronoun}} is also responsible for the development of pricing strategies and specific purchasing methodology for major projects. {{capitalizedSubjectPronoun}} develops a working relationship with trade partner principals and estimating departments while supporting the development of conceptual estimates for design-build and guaranteed maximum price projects and lead procurements for more complicated projects.',
  },
  {
    jobTitle: 'proposal lead',
    template:
      'The Proposal Lead grows the company’s presence in the region through client development, marketing and communication outreach and project procurement. {{capitalizedSubjectPronoun}} develops and designs proposal content, client and statements of qualifications and presentations. The proposal lead is responsible for creating and supporting the development of proposal responses including request for qualifications (RFQ) and request for proposals (RFP). {{capitalizedSubjectPronoun}} works closely with the proposal manager and regional or business unit leadership in executing effective proposals and marketing initiatives.',
  },
  {
    jobTitle: 'quality control engineer',
    template:
      'The Quality Control Engineer (QCE) is responsible for performing many of the quality control tasks at the project level as defined in the quality control plan for the project. The QC engineer exercises judgment and discretion in making quality control-related recommendations, implementing quality control policies and procedures and handling a wide variety of quality control matters in the field and office. The QC engineer is typically assigned to a single project but may be required to support multiple smaller projects.',
  },
  {
    jobTitle: 'scheduling engineer',
    template:
      'The Scheduling Engineer assists the project team with the creation, monitoring and maintenance of both procurement and project schedules along with maintenance of those schedules and associated monthly narratives. The scheduling engineer ensures the development and completion of job progress milestones through scheduling, analysis of work breakdown structure and resource requirements.',
  },
  {
    jobTitle: 'scheduling manager',
    template:
      'The Scheduling Manager is responsible for overseeing the development, maintenance, updating and reporting of project construction schedules. They are responsible for facilitating schedule communication between all internal stakeholders assigned to the project and may act as the liaison for the owner’s scheduler.',
  },
  {
    jobTitle: 'senior quality control manager',
    template:
      'The Senior Quality Control Manager is responsible for overseeing and managing the QC program for their region or business unit to ensure projects meet quality and efficiency standards. They will support the project QC teams, working to analyze existing operations, systems and processes to identify pain points and prioritize high-value improvement opportunities.',
  },
  {
    jobTitle: 'senior vdc manager',
    template:
      'The Senior VDC Manager is the senior regional or business unit VDC resource and is responsible for the overall management, direction and implementation of Hensel Phelps’ VDC/BIM processes. They partner with regional or business unit directors of safety, senior QC managers, technology and project development teams to involve those groups in any technology or process improvements that may be beneficial. They also coordinate with corporate VDC objectives and the director of VDC in support of project development, marketing, safety, technology, continuous improvement and training and services.',
  },
]
