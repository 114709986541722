import { ISearchableFacetItem } from '../localTypes';
import {IAutocompleteHelper} from "./IAutocompleteHelper";

export default class TechnologiesAutocompleteHelper implements IAutocompleteHelper {
    public updateSearchValues(facetItems: ISearchableFacetItem[]): ISearchableFacetItem[] {
        const newFacetItems = [] as ISearchableFacetItem[];
        for (let i = 0; i < facetItems.length; i++) {
            facetItems[i].searchValues = this.makeSearchValues(facetItems[i].value);
            newFacetItems.push(facetItems[i]);
        }
        return newFacetItems;
    }

    private makeSearchValues(facetValue: string) {
        return facetValue.split(' > ')
            .map(function(item) { return item.toLowerCase(); })
            .map(function(item) { return item.replace(/[^0-9a-zA-Z ]/g, ''); })
            .map(function(item) { return item.split(' '); })
            .reduce(function(a, b) { return a.concat(b); }, []);
    }

    public updateDisplayNames(facetItems: ISearchableFacetItem[]): ISearchableFacetItem[] {
        const newFacetItems = [] as ISearchableFacetItem[];
        for (let i = 0; i < facetItems.length; i++) {
            // update the .display name of the facet item based on the .value property
            facetItems[i].display = facetItems[i].value;
            newFacetItems.push(facetItems[i]);
        }
        return newFacetItems;
    }

    public matchesString(facetItem: ISearchableFacetItem, searchValue: string): boolean {
        return facetItem.value === searchValue;
    }
}