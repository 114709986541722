import useFieldOptionsForPeople from 'hooks/useFieldOptions/useFieldOptionsForPeople'
import React from 'react'
import { IApiPerson } from 'services/apiClients/AthenaClient'

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ResumeIntro from './ResumeIntro'
import SectionWithChipList from '../shared/SectionWithChipList'
import { useSharedLargeCardStyles } from '../shared/sharedStyles'
import AboutSection from './AboutSection'
import NotesSection from './NotesSection'
import ResumeSection from './ResumeSection'
import { hasPermission } from '../../../app/security/utils/securityUtil'
import { useGlobalState } from '../../../app/state/useGlobalState'
import KudosSection from './KudosSection'

const useStyles = makeStyles(() => ({
  detailView: {
    //position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    height: '100%',
  },
  outerSideNavBar: {
    flex: '1 0 64px',
  },
  detailViewContent: {
    flex: '1 1 auto',
    backgroundColor: 'cornflowerblue',
    overflowY: 'scroll',
    height: '100%',
  },
  defaultCard: {
    textAlign: 'left',
  },
}))

export default function DetailsCard(props: {
  person?: IApiPerson
  comment?: string
}) {
  //const classes = useStyles();
  const sharedClasses = useSharedLargeCardStyles()
  const gs = useGlobalState()
  const { options } = useFieldOptionsForPeople()

  // todo: don't think I need anymore
  // if (!props.person) {
  //   console.log('@@DetailsCard', {'props.person':props.person})
  //   return (<div>loading DetailsCard...</div>);
  // }

  const person = (props.person as unknown) as IApiPerson

  return (
    <>
      <Card id="person-details-module" className={sharedClasses.defaultCard}>
        <CardHeader
          className={sharedClasses.cardTitle}
          title={'Details'}
          data-scroll-name={'details'}
        />
        <CardContent>
          <div>
            <AboutSection person={person} />

            <ResumeSection person={person} />

            <NotesSection person={person} />

            <KudosSection person={person} />

            <SectionWithChipList
              model={person}
              readOnly={!hasPermission(['Contributor'], gs.userRoles)}
              autocomplete={true}
              showTitle={true}
              editMode={false}
              values={
                (person.accreditation ? person.accreditation : []) as string[]
              }
              choices={options['accreditations']}
              title={'Accreditations'}
              fieldName={'accreditation'}
            />

            <SectionWithChipList
              readOnly={true}
              model={person}
              showTitle={true}
              editMode={false}
              values={
                (person.verticalMarkets
                  ? person.verticalMarkets
                  : []) as string[]
              }
              choices={[]}
              title={'Vertical Markets'}
            />

            <SectionWithChipList
              readOnly={true}
              model={person}
              showTitle={true}
              editMode={false}
              values={
                (person.clientTypes ? person.clientTypes : []) as string[]
              }
              choices={[]}
              title={'Client Types'}
            />

            <SectionWithChipList
              readOnly={true}
              model={person}
              showTitle={true}
              editMode={false}
              values={
                (person.contractTypes ? person.contractTypes : []) as string[]
              }
              choices={[]}
              title={'Contract Types'}
            />

            <SectionWithChipList
              readOnly={true}
              model={person}
              showTitle={true}
              editMode={false}
              values={
                (person.constructionTypes
                  ? person.constructionTypes
                  : []) as string[]
              }
              choices={[]}
              title={'Construction Types'}
            />

            <SectionWithChipList
              readOnly={true}
              model={person}
              showTitle={true}
              editMode={false}
              values={
                (person.procurementMethods
                  ? person.procurementMethods
                  : []) as string[]
              }
              choices={[]}
              title={'Procurement Methods'}
            />

            <SectionWithChipList
              readOnly={true}
              model={person}
              showTitle={true}
              editMode={false}
              values={
                (person.secondaryCategories
                  ? person.secondaryCategories
                  : []) as string[]
              }
              choices={[]}
              title={'Secondary Categories'}
            />

            <SectionWithChipList
              readOnly={true}
              model={person}
              showTitle={true}
              editMode={false}
              values={
                (person.stakeholders ? person.stakeholders : []) as string[]
              }
              choices={[]}
              title={'Companies Worked With'}
            />
          </div>
        </CardContent>
      </Card>
    </>
  )
}
