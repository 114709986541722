import DashProjectSustainabilityCertification from "./models/DashProjectSustainabilityCertification";
import React from "react";
import {findAndSplit, IViewField} from "types/DashDisplayModel";
import 'reflect-metadata';
import { Grid, GridProps} from "@material-ui/core";

type CertificationBlockProps = {
  model: DashProjectSustainabilityCertification;
  setTitle: (title: string) => void;
};

const CertificationBlock: React.FC<CertificationBlockProps> = (props) => {
  const innerGridProps: GridProps = {item: true, xs: 12, sm: 6};

  const model = new DashProjectSustainabilityCertification(props.model);
  const all = model.toViewFieldArray();
  const [title, items] = findAndSplit(all, (f) => f.property === 'name');

  return (
      <>
      <Grid container spacing={2} style={{width:'100%',padding:'0 10px'}}>
            {items.filter(x => x.property !== 'id')
                .map((fld: IViewField, index) => (
                <Grid {...innerGridProps} key={index}>
                  <div key={index}><b>{fld.label}:</b>&nbsp;{fld.value}</div>
                </Grid>
            ))}
      </Grid>
      </>
  )
}

export default CertificationBlock;