import React, { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    Dialog,
    DialogTitle, DialogActions, Button, DialogContent
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dashHelpConfig from '../dashHelpConfig';
import DashDivider from "../../../components/DashDivider";
import path from 'path-browserify';
import _ from "lodash";

const HelpDialog = (props: { open?: boolean; onClose: () => void }) => {
    //const [helpData, setHelpData] = useState(HelpSection[]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [content, setContent] = useState('');
    const [expandedId, setExpandedId] = useState<string | undefined>();

    useEffect(() => {
        setDialogOpen(props.open ?? false);

        if (props.open) {
            fetchContent(dashHelpConfig.defaultUrl);
        }
    }, [props.open]);

    const fetchContent = async (url: string) => {
        const helpUrl = '/' + path.join('images', url)
        const response = await fetch(helpUrl);
        const data = await response.text();
        console.log('Help - fetchContent from: ' + helpUrl, {data});
        setContent(data);
    };

    function handleClose() {
        setDialogOpen(false);
        props.onClose();
    }

    const handleAccordionChange = (expandedId?: string) => {
        setExpandedId(expandedId);
    };

    const paperProps = { style: { width: '90vh', height: '60vh' }}

    return (<>
        {dialogOpen &&
            <Dialog open={dialogOpen} fullWidth={true} maxWidth={'xl'}>
                {/*scroll={'paper'}*/}
                {/*    PaperProps={paperProps}>*/}
                <DialogTitle className="w-full text-white bg-black">
                    <Typography>Help</Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="flex flex-row" style={{ height: '60vh'}}>
                        <nav style={{width: '240px', height: '60vh', overflowY: 'auto', borderRight: '1px solid #cccccc'}}>
                            {dashHelpConfig.sections.map((section) => (
                                <Accordion key={section.id}
                                           expanded={expandedId === section.id}
                                           onChange={() => handleAccordionChange(section.id)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>{section.label}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            {section.children.map((child) => (
                                                <ListItem key={child.id} button
                                                          onClick={() => fetchContent(child.templateUrl)}>
                                                    <Typography>{child.label}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </nav>
                        <div id="help-content" style={{width: 'calc(100% - 240px)', padding: '30px', overflow: 'auto'}}
                             dangerouslySetInnerHTML={{__html: content}}></div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => handleClose()}>Close</Button>
                </DialogActions>
            </Dialog>}
    </>);
}

export default HelpDialog;
