import {withDashCard} from '../../shared/withDashCard';
import ProjectDescriptionsSection from "./ProjectDescriptionsSection";
import ResumeDescriptionSection from "./ResumeDescriptionSection";
import DetailedDescriptionsSection from "./DetailedDescriptionsSection";
import DesignAspectsDescriptionSection from "./DesignAspectsDescriptionSection";
import TechnicalAspectsDescriptionSection from "./TechnicalAspectsDescriptionSection";
import NotesSection from "./NotesSection";
import QualitySection from "./QualitySection";
import ValueEngineeringSection from "./ValueEngineeringSection";
import CollaborativeEffortsSection from "./CollaborativeEffortsSection";
import CommissioningEffortsSection from "./CommissioningEffortsSection";
import LessonsLearnedSection from "./LessonsLearnedSection";
import ProjectInnovationsSection from "./ProjectInnovationsSection";
import QuotesSection from "./QuotesSection";
import SecurityNarrativeSection from "./SecurityNarrativeSection";

const ProjectDescriptionsCard = withDashCard('Project Descriptions', 'project-descriptions-module',
    // list of components to add to the card (in order they should appear)
    [
      ProjectDescriptionsSection,
      ResumeDescriptionSection,
      DetailedDescriptionsSection,
      DesignAspectsDescriptionSection,
      TechnicalAspectsDescriptionSection,
      NotesSection,
      QualitySection,
      ValueEngineeringSection,
      CollaborativeEffortsSection,
      CommissioningEffortsSection,
      LessonsLearnedSection,
      ProjectInnovationsSection,
      QuotesSection,
      SecurityNarrativeSection,
    ]
);

export default ProjectDescriptionsCard;