import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import AthenaClient, {ApiProgram, IApiProject, IProgramProject, ProgramProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {Button, IconButton, Switch} from "@material-ui/core";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {v4 as uuidv4} from 'uuid';
import {commasFmt, currencyFmt} from "utils/numberToStringUtil";
import {AddCircleOutline, Delete, RemoveCircleOutline} from "@material-ui/icons";
import PickerDialogEx from "../../shared/PickerDialogEx";
import {ColumnDirective} from "@syncfusion/ej2-react-grids";
import useToast from "hooks/useToast";
import DashToaster from "patterns/DashToaster";
import {GreenCheck, RedX} from "../../../dashCore/customIcons";
import DashIconButton from "../../../dashCore/DashIconButton";
import {makeStyles} from "@material-ui/core/styles";
import ChangeFragment from "features/coreComponents/ChangeFragment";
import _ from "lodash";
import SimpleApproval from "features/coreComponents/SimpleApproval";
import AthenaAuthorize from "../../../../app/security/ui/AthenaAuthorize";
import programInformationState from "./ProgramInformationState";
import {useObserver} from "mobx-react";
import globalState from "../../../../app/state/GlobalState";
import {observe} from "mobx";

// end imports

const useTableStyles = makeStyles(() => ({
  nameCell: {
    width: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  numberCell: {
    width: '15%',
  },
  redGreenCell: {
    width: '10%',
  }
}));

const SECTION_ID = 'programInformationSection';

const COST_FIELD = 'includeContractValueInProgram';
const SQFT_FIELD = 'includeSquareFootageInProgram';

const ProgramInformationSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const typedModel = props.model as IApiProject;
      const [model, setModel] = useState<IApiProject>(typedModel);
      //const [programModelId, setProgramModelId] = useState<string | undefined>(typedModel.programId);
      const [programModel, setProgramModel] = useState<ApiProgram | null>(null);
      const [programProjects, setProgramProjects] = useState<ProgramProject[]>([]);
      const [saveCount, incr] = useSaveCount();
      const {athenaClientRef} = useAthenaClient();
      // const [showInfotipModal, setShowInfotipModal] = useState(false);
      // const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());
      const [includeContractValue, setIncludeContractValue] = useState(false);
      const [includeSquareFootage, setIncludeSquareFootage] = useState(false);
      const [description, setDescription] = useState('');
      const [reloadProgram, setReloadProgram] = useState(false);
      const [loading, setLoading] = useState(false);

      const [dialogMode, setDialogMode] = useState<string | null>(null);
      const {displayToast} = useToast();
      const dashToaster = new DashToaster(displayToast);
      const tableClasses = useTableStyles();

      useEffect(() => {
        async function reloadProgramModel() {
          const client = athenaClientRef.current as AthenaClient;
          const project = await client.getProject(model.id as string);
          if (project.programId) {
            const program = await client.getProgram(project.programId);
            setModel(project);
            setProgramModel(program);
            setDescription(program.description ?? '');
            setProgramProjects(program?.projects ?? []);
            //console.log({program});
            // fix current program check marks
            const currentProject = program?.projects?.find(x => model.id === x.id);
            if (currentProject) {
              setIncludeContractValue(currentProject.includeContractValueInProgram ?? false);
              setIncludeSquareFootage(currentProject.includeSquareFootageInProgram ?? false);
            }
          } else {
            setProgramModel(null);
            setDescription('');
            setProgramProjects([]);
          }
          //incr();
          globalState.updateProgramInformationTicks();
        }

        if (athenaClientRef.current) {
            reloadProgramModel();
        }

      }, [athenaClientRef.current, saveCount]);

      function isNonEmptyString(value: unknown): value is string {
        return typeof value === 'string' && value.trim() !== '';
      }

      // SAVE V2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          const scopingElement = document.querySelector('#' + SECTION_ID) as Element;
          const data = getNamedData(scopingElement) as Record<string, unknown>;
          const programData = _.omit(data, ['includeSquareFootageInProgram', 'includeContractValueInProgram']);

          try {
            const client = athenaClientRef.current as AthenaClient;
            let programId = '';
            let program: ApiProgram | undefined;

            if ('programId' in data && data.programId && isNonEmptyString(data.programId)) {
              programId = data.programId;
              program = await client.updateProgram(_.omit(programData, ['id', 'programId']) as unknown as ApiProgram, programId);
            } else {
              // Handle the case when 'id' is not an attribute in data, data.id is falsy, or the value is not a non-empty string.
              program = await client.createProgram(programData as unknown as ApiProgram);
              programId = program.id as string;
            }

            await client.linkProjectToProgram(programId as string, model.id as string, {
              includeSquareFootageInProgram: includeSquareFootage,
              includeContractValueInProgram: includeContractValue
            } as ProgramProject);

            await submitProgramProjectsUpdates();

            //setProgramModelId(program.id);
            setProgramModel(program);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            incr();
            globalState.setDelayedStateChange(4600, () => globalState.updateProgramInformationTicks());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));
      // end useImperativeHandle

      function clickCancel() {
        const buttons = Array.from(document.querySelectorAll('button')) as HTMLButtonElement[];
        for (const button of buttons) {
          if (button.textContent === 'Cancel') {
            button.click();
            break;  // Exit the loop once the first "Cancel" button is clicked
          }
        }
      }

      function handleDeleteProgram() {
        // find program ID
        if (confirm('Are you sure you want to delete this program?')) {
          clickCancel();
          setDialogMode(null);
          setProgramModel(null);
          setLoading(true);
          setTimeout(() => {
            if (athenaClientRef.current && programModel?.id) {
              athenaClientRef.current?.deleteProgram(programModel.id)
                  .then(() => {
                    console.log('$$$@ made it here programId: ', programModel.id);

                    setTimeout(() => {
                      incr();
                      programInformationState.updateProgramInformation();
                      setLoading(false);
                    }, 500);
                  });
            }
          }, 50);
        }
      }

      function handleIncludeContractValue(checked: boolean) {
        setIncludeContractValue(checked)
      }

      function handleIncludeSqFeet(checked: boolean) {
        setIncludeSquareFootage(checked);
      }


      function handleProgramDialogAction(action: string, payload?: IApiProject | string | null) {
        switch (action) {
          case 'reload':
            if (athenaClientRef && model.programId) {
              const client = athenaClientRef.current as AthenaClient;
              const program = client.getProgram(model.programId).then(program => {
                setProgramModel(program);
                setProgramProjects(program.projects ?? []);
                //saved();
                programInformationState.updateProgramInformation();
              });
            }
            if (!model.programId) {
              setProgramModel(undefined);
              programInformationState.updateProgramInformation();
            }
            break;
          case 'close':
          case 'cancel':
            setDialogMode(null);
            handleProgramDialogAction('reload');
            programInformationState.updateProgramInformation();
            break;
          case 'link':
            if (payload) {
              const projectPayload = payload as IApiProject;
              // API doesn't require any information in the JSON body
              athenaClientRef.current?.linkProjectToProgram(programModel?.id as string, projectPayload.id as string, {} as ProgramProject)
                  .then(() => {
                    dashToaster.displayLinkedToast(`${projectPayload.name}`, `${programModel?.name}`);
                    setSectionKey(uuidv4());
                    // not yet setProgramProjects(existing => [...existing, projectPayload]);
                    handleProgramDialogAction('reload');
                  });
            }
            break;
          case 'unlink':
            if (payload) {
              const projectId = payload as string;
              // API doesn't require any information in the JSON body
              if (confirm(`Are you sure you want to remove this project from the program?`)) {
                //alert(payload);
                athenaClientRef.current?.unlinkProjectFromProgram(programModel?.id as string, projectId)
                    .then(() => {
                      dashToaster.display('You have unlinked the project from this program successfully');
                      setSectionKey(uuidv4());
                      handleProgramDialogAction('reload');
                    });
              }
            }
            break;
        }
      }

      function changeIncludedValue(e: React.ChangeEvent<HTMLInputElement>,
                                   projectId: string, fieldName: string) {
        const isChecked = e.target.checked;
        if (!projectId) return;
        _.noop()
        if (fieldName === 'includedContractValueInProgram') {
          //includedCvipDict.current[projectId] = isChecked;
        }

        if (fieldName === 'includeSquareFootageInProgram') {
          _.noop();
          //includedSfipDict.current[projectId] = isChecked;
        }
      }

      // includeContractValueInProgram: boolean;
      // includeSquareFootageInProgram: boolean;
      async function updateProjectProgramLink(projectId: string, programId: string, data: IProgramProject) {
        //    includeContractValueInProgram, includeSquareFootageInProgram
        await athenaClientRef.current?.linkProjectToProgram(programId, projectId, data as unknown as ProgramProject)
      }

      async function submitProgramProjectsUpdates() {
        if (!programModel || !programProjects || programProjects.length < 1) return;
        const otherProjects = programProjects.filter(p => p.id !== model.id) || [];

        try {
          const results = await Promise.all(
              otherProjects.map(async (item) => {
                if (!athenaClientRef.current || programModel?.id === undefined || item.id === undefined) {
                  return null;  // Or throw an error, or handle this case as needed
                }

                return await athenaClientRef.current.linkProjectToProgram(
                    programModel.id,
                    item.id as string, {
                      includeContractValueInProgram: item.includeContractValueInProgram,
                      includeSquareFootageInProgram: item.includeSquareFootageInProgram
                    } as unknown as ProgramProject
                );
              })
          );

          console.log('All data processed:', results);
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }

      function handleCheckChange(e: React.ChangeEvent<HTMLInputElement>, projectId: string | undefined, field: string) {
        if (!projectId) return;
        //console.log({projectId, field}, e.target.checked);

        const nextProgramProjects = _.cloneDeep(programProjects);
        const idx = nextProgramProjects.findIndex(project => project.id === projectId);
        if (idx === -1) return;

        switch (field) {
          case COST_FIELD:
            nextProgramProjects[idx].includeContractValueInProgram = e.target.checked;
            break;
          case SQFT_FIELD:
            nextProgramProjects[idx].includeSquareFootageInProgram = e.target.checked;
            break;
          default:
            break;
        }
        setProgramProjects(nextProgramProjects);
      }

      // TABLE GENERATION
      function generateProgramProjectsTable(key: string, editMode: boolean, programProjects: ProgramProject[]) {
        let otherProjects = programProjects;
        otherProjects = otherProjects.map(x => x);
        // pull itself out of the project list from program
        otherProjects = otherProjects.filter(p => p.id !== model.id) || [];

        return (
            <React.Fragment key={'inner-' + key}>
              <div style={{width: 'calc(100%-10px)', flex: 1, border: '1px dotted #999', padding: '1px'}}>
                <span
                    className="text-bold">Other Projects that are Part of this Program (Associated Projects):</span><br/>
                {(otherProjects.length < 1)
                    ? (<div style={{width: '100%', padding: '5px'}}>
                      <DashDivider width={'100%'} height={'30px'}/>
                      <span>No other projects associated with this program.</span>
                    </div>)
                    : <table style={{width: '100%'}}>
                      <thead style={{
                        whiteSpace: 'normal',
                        verticalAlign: 'top',
                        textAlign: 'center',
                        backgroundColor: '#eee'
                      }}>
                      <tr>
                        <th className={tableClasses.nameCell}>Project</th>
                        <th className={tableClasses.numberCell}>Final Contract Value</th>
                        <th className={tableClasses.redGreenCell}>Include Contract Value in Program?</th>
                        <th className={tableClasses.numberCell}>Sq Ft</th>
                        <th className={tableClasses.redGreenCell}>Include Square Footage in Program?</th>
                        {props.editMode && <th>Unlink</th>}
                      </tr>
                      </thead>
                      <tbody>
                      {otherProjects && otherProjects.map((prj, index) => (
                          <tr key={index}>
                            <td className={tableClasses.nameCell}>
                              <a href={`/projects/${prj.id}`} target={'_blank'}>
                                {prj.name}</a>
                            </td>
                            <td className={tableClasses.numberCell}>{prj.value != null && currencyFmt.format(prj.value)}</td>
                            {!editMode
                                ? <td className={tableClasses.redGreenCell}>{prj.includeContractValueInProgram ?
                                    <GreenCheck/> : <RedX/>}</td>
                                : <td className={tableClasses.redGreenCell}>
                                  <Switch color="primary"
                                          checked={prj.includeContractValueInProgram}
                                          onChange={(e) => handleCheckChange(e, prj.id, COST_FIELD)}/>
                                </td>}
                            <td className={tableClasses.numberCell}>{prj.squareFootage && commasFmt.format(prj.squareFootage)}</td>
                            {!editMode
                                ? <td className={tableClasses.redGreenCell}>{prj.includeSquareFootageInProgram ?
                                    <GreenCheck/> : <RedX/>}</td>
                                : <td className={tableClasses.redGreenCell}>
                                  <Switch color="primary"
                                          checked={prj.includeSquareFootageInProgram}
                                          onChange={(e) => handleCheckChange(e, prj.id, SQFT_FIELD)}
                                  />
                                </td>}
                            {editMode &&
                                <td><DashIconButton
                                    data-project-id={prj.id}
                                    size={'small'}
                                    onClick={(e) =>
                                        handleProgramDialogAction('unlink', e.currentTarget.dataset.projectId)}>
                                  <RemoveCircleOutline/>
                                </DashIconButton></td>
                            }
                          </tr>
                      ))}
                      </tbody>
                    </table>}
              </div>
            </React.Fragment>
        )
      }

      function handleDescriptionChange(value: string) {
        setDescription(value);
      }

      const key = '88902568905423478';

      return useObserver(() => {
            if (!props.model) {
              return <DashItemProgress/>;
            }

            if (loading) {
              return <DashItemProgress />;
            }

            if (!programModel && !props.editMode) {
              return (<div>
                <span>This project is not part of a program.</span>
              </div>);
            }

            return (
                <React.Fragment key={key + saveCount.toString() + globalState.programInformationTicks.toString()}>
                  {/* id */}
                  <DashDetailsField mode="hidden" label="Id" showLabel={false}
                                    editMode={props.editMode} name="id" defaultValue={model.id}
                  />
                  {/* programId */}
                  <DashDetailsField mode="hidden"
                                    label="Program Id"
                                    showLabel={false}
                                    editMode={props.editMode}
                                    name="programId"
                                    defaultValue={programModel?.id ?? undefined}
                  />

                  {/* name */}
                  <DashDetailsField
                      mode="text" label="Program Name" showLabel={true}
                      infotipKey="programName"
                      width="47%" editMode={props.editMode} name="name"
                      defaultValue={programModel?.name}
                  />

                  <SimpleApproval approval={programModel?.approval ?? false}
                                  style={{width: '47%'}}
                                  infoMode={'minimal'}
                                  approvalModifiedBy={programModel?.approvalModifiedBy}
                                  approvalModifiedOn={programModel?.approvalModifiedOn}
                                  editMode={props.editMode}
                                  showLabelOnEdit={true}
                                  showLabelOnNonEdit={false}
                                  label={'Description Approval'}
                                  subject={'Description'}
                  />

                  {props.editMode &&
                      <AthenaAuthorize allowedRoles={'contributor'}>
                        <IconButton onClick={handleDeleteProgram} title={'Delete this Program'}><Delete/>
                        </IconButton>
                      </AthenaAuthorize>
                  }


                  {/* programDescription */}
                  <DashDetailsField
                      mode="richText2"
                      label="Program Description"
                      showLabel={true}
                      infotipKey="programDescription"
                      width="100%"
                      editMode={props.editMode}
                      name="description"
                      onValueChange={(value) => handleDescriptionChange(value)}
                      value={description}
                  />

                  {/* programValue */}
                  <DashDetailsField label="Program Contract Value"
                                    showLabel={true}
                                    mode="number"
                                    width="47%"
                                    editMode={false}
                                    infotipKey="programValue"
                                    name="programContractValue"
                                    formatFn={currencyFmt.format}
                                    defaultValue={programModel?.value}
                  />

                  {/* programSquareFootage */}
                  <DashDetailsField label="Program Square Footage" showLabel={true}
                                    mode="number" width="47%"
                                    editMode={false}
                                    infotipKey="programSquareFootage"
                                    name="programSquareFootage"
                                    formatFn={commasFmt.format}
                                    defaultValue={programModel?.squareFootage}
                  />

                  <DashDetailsField label="Include this project's Contract Value in Program total?"
                                    mode="checkSwitch" editMode={props.editMode}
                                    onValueChange={handleIncludeContractValue}
                                    showLabel={true} width="47%"
                                    name="includeContractValueInProgram"
                                    value={includeContractValue}
                  />
                  <DashDetailsField label="Include this project's Square Footage in Program total?"
                                    mode="checkSwitch" editMode={props.editMode}
                                    onValueChange={handleIncludeSqFeet}
                                    showLabel={true} width="47%"
                                    name="includeSquareFootageInProgram"
                                    value={includeSquareFootage}
                  />

                  <DashDetailsField mode="subheading"
                                    label={'Other Projects that are Part of this Program (Associated Projects):'}
                                    value={'Other Projects that are Part of this Program (Associated Projects):'}
                                    showLabel={true}/>

                  {/*programAssociateProject*/}

                  {(programProjects.length > 0 && props.editMode) &&
                      <Button startIcon={<AddCircleOutline/>}
                              onClick={() => setDialogMode('pick')}>
                                Associate a Project to this Program</Button>
                  }

                  <DashDivider width="80vw" height="5px"/>

                  <div style={{width: '100%'}}>
                    <ChangeFragment>
                      {generateProgramProjectsTable(uuidv4(), props.editMode, programProjects)}
                    </ChangeFragment>
                  </div>

                  <DashDivider width="80vw" height="20px"/>

                  <PickerDialogEx mode={dialogMode}
                                  title={`Add project(s) to program ${programModel?.name}`}
                                  searchBoxLabel={'Search for project...'}
                                  entityName={'projects'}
                                  onAction={handleProgramDialogAction}
                                  alternateCancelText={'Close'}
                                  columnDirectives={[
                                    <ColumnDirective headerText={'Project Name'} field={'name'}/>,
                                    <ColumnDirective headerText={'Final Contract Value'}
                                                     field="finalConstructionContractValue"/>,
                                    <ColumnDirective headerText={'Square Footage'} field="squareFootage"/>,
                                    <ColumnDirective headerText="Add to Program"
                                                     template={(record: Record<string, unknown>) =>
                                                         <DashIconButton size="small"
                                                                         onClick={() => handleProgramDialogAction('link', record)}>
                                                           <AddCircleOutline/>
                                                         </DashIconButton>}/>
                                  ]}
                  />
                </React.Fragment>
            )
          }
      );
    });

export default withDashSection('Program Information', SECTION_ID, ProgramInformationSection, false, 'programSubsection');


