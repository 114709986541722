import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from "react";
import AthenaClient, {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";

import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {formatInfotip, projectInfotips} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
import {useGlobalState} from "../../../../app/state/useGlobalState";
// end imports

const SECTION_ID = 'constructionScheduleSection';

const ConstructionScheduleSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & {
  onSave?: () => void
}>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {

      const classes = useSharedLargeCardStyles();
      const [model, setModel] = useState<IApiProject>(props.model);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());
      const globalState = useGlobalState();

      const toggleEditMode = () => {
        props.setEditMode(!props.editMode);
      }

      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          console.log('begin ' + SECTION_ID + ' save');
          const elem = document.querySelector('#' + SECTION_ID);
          const data = getNamedData(elem);
          console.log('namedData was:', data);
          // eslint-disable-next-line no-useless-catch
          try {
            const client = (athenaClient as AthenaClient);
            const m = await client.patchProject(data);
            setModel(m as IApiProject);
            globalState.setCurrentProject(m); // model
            props.setEditMode(false);
            setSectionKey(uuidv4());
            setTimeout(() => {
              globalState.updateProjectDetailTicks();
              setTimeout(() => {
                globalState.updateProjectDetailTicks();
              }, 500);
            }, 1800);

            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));

      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (!props.model) {
        return <DashItemProgress/>;
      }

      return (
          <React.Fragment key={sectionKey}>
            {/* id */}
            <DashDetailsField mode="hidden"
                              label="Id"
                              showLabel={false}
                              editMode={props.editMode}
                              name="id"
                              defaultValue={model.id}
            />

            {/* constructionScheduledStartDate */}
            <DashDetailsField mode="date"
                              label="Scheduled Start Date"
                              showLabel={true}
                              width="47%"
                              editMode={props.editMode}
                              name="constructionScheduledStartDate"
                              infotipKey={'constructionScheduledStartDate'}
                              defaultValue={model.constructionScheduledStartDate}
            />

            {/* constructionActualStartDate */}
            <DashDetailsField mode="date"
                              label="Actual Start Date"
                              showLabel={true}
                              width="47%"
                              editMode={props.editMode}
                              name="constructionActualStartDate"
                              infotipKey={'constructionActualStartDate'}
                              defaultValue={model.constructionActualStartDate}
            />

            {/* constructionScheduledSubstantialCompletionDate */}
            <DashDetailsField mode="date"
                              label="Scheduled Substantial Completion Date"
                              showLabel={true}
                              width="47%"
                              editMode={props.editMode}
                              name="constructionScheduledSubstantialCompletionDate"
                              infotipKey={'constructionScheduledSubstantialCompletionDate'}
                              defaultValue={model.constructionScheduledSubstantialCompletionDate}
            />

            {/* constructionModifiedSubstantialCompletionDate */}
            <DashDetailsField mode="date"
                              label="Modified Substantial Completion Date"
                              showLabel={true}
                              width="47%"
                              editMode={props.editMode}
                              name="constructionModifiedSubstantialCompletionDate"
                              infotipKey={'constructionModifiedSubstantialCompletionDate'}
                              defaultValue={model.constructionModifiedSubstantialCompletionDate}
            />

            {/* constructionActualSubstantialCompletionDate */}
            <DashDetailsField mode="date"
                              label="Actual Substantial Completion Date"
                              showLabel={true}
                              width="47%"
                              editMode={props.editMode}
                              name="constructionActualSubstantialCompletionDate"
                              infotipKey={'constructionActualSubstantialCompletionDate'}
                              defaultValue={model.constructionActualSubstantialCompletionDate}
            />

            {/* finalPaymentDate */}
            <DashDetailsField mode="date"
                              label="Final Payment Date"
                              showLabel={true}
                              width="47%"
                              editMode={props.editMode}
                              name="finalPaymentDate"
                              infotipKey={'finalPaymentDate'}
                              defaultValue={model.finalPaymentDate}
            />

            <DashDivider width="80vw" height="5px"/>
          </React.Fragment>
      );
    });

export default withDashSection('Construction', SECTION_ID, ConstructionScheduleSection);
