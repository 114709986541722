import React, {FunctionComponent, ReactElement, useCallback, useContext, useEffect, useReducer, useState} from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
  List, ListItem, ListItemText, Switch
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SideNavChildrenProps, FacetMemberInfo } from 'types/searchTypes';
import {useExpandedFacetId} from "recoil/expandedFacet";
import ClearFacetButton from "../ClearFacetButton";
import {useGlobalState} from "../../../../app/state/useGlobalState";
import {useObserver} from "mobx-react";

const useStyles = makeStyles(() => ({
  itemLabel: {
    fontSize: '14px',
    color: '#000',
  },
  itemCount: {
    fontSize: '14px',
    color: '#999',
  },
  // start accordion style
  accordion: {
    width: '275px',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    fontSize: '14px',
    '& > div': {
      width: '100%',
    }
  }
}));

// export type PluggableFacetProps =
//     SideNavChildrenProps & {
//   Component: FunctionComponent<SideNavChildrenProps>
// };


function withPluggableFacet(Component:FunctionComponent<SideNavChildrenProps>) {
  return function WrappedComponent(props: SideNavChildrenProps) {
    // const { Component, ...restProps } = props;
    const classes = useStyles();
    //const [expandedFacetId, { setExpandedFacetId }] = useExpandedFacetId();
    const gs = useGlobalState();
    const [expanded, setExpanded] = useState(false);
    //const [reset, setReset] = useState(false);
    const [pristine, setPristine] = useState(true);

    const closeAccordionIfTrue = (value: boolean) => {
      if (value) setExpanded(false);
    }

    const handleResetAndClose = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setExpanded(false);
      gs.expandedFacetId = undefined;
      setPristine(true);
    }

    function handleAccordionChange(_event: unknown) {
      // if it is already my id then it shouldn't be me and I should close
      const nextId = (gs.expandedFacetId === props.id) ? 'none' : props.id;
      gs.setExpandedFacetId(nextId);
    }

    return useObserver(() =>
        <React.Fragment>
          <Accordion className={classes.accordion}
                     TransitionProps={{ timeout: 50 }}
                     onChange={handleAccordionChange}
                     expanded={gs.expandedFacetId === props.id}>
            <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}>
              <ClearFacetButton visible={!pristine}
                                onClick={handleResetAndClose} />
              <Typography className="text-left">{props.label}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails} style={{ fontSize: '14px' }}>

              <Component {...props} pristine={pristine} setPristine={setPristine} />

            </AccordionDetails>
          </Accordion>
          <div className="accordionDetailEnd"></div>
        </React.Fragment>
    );
  }
}

export default withPluggableFacet;
