import { ISearchableFacetItem } from '../localTypes';
import {IAutocompleteHelper} from "./IAutocompleteHelper";

export default class AwardSponshipAutocompleteHelper implements IAutocompleteHelper {
    readonly DELIMITER = ' - ';

    public updateSearchValues(facetItems: ISearchableFacetItem[]): ISearchableFacetItem[] {
        const newFacetItems = [] as ISearchableFacetItem[];
        for (let i = 0; i < facetItems.length; i++) {
            facetItems[i].searchValues = facetItems[i].value.split(this.DELIMITER)
                            .map(x => x.toLowerCase());
            newFacetItems.push(facetItems[i]);
        }
        return newFacetItems;
    }

    public updateDisplayNames(facetItems: ISearchableFacetItem[]): ISearchableFacetItem[] {
        const newFacetItems = [] as ISearchableFacetItem[];
        for (let i = 0; i < facetItems.length; i++) {
            // update the .display name of the facet item based on the .value property
            facetItems[i].display = facetItems[i].value;
            newFacetItems.push(facetItems[i]);
        }
        return newFacetItems;
    }

    public matchesString(facetItem: ISearchableFacetItem, searchValue: string): boolean {
        return facetItem.value === searchValue;
    }
}