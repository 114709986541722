import { createTheme } from '@material-ui/core/styles';

import { brightWhite, hpRedDarkened, hpRedLightened, trueBlack } from './colors';
import { PANCAKE_TYPOGRAPHY, sharedOverrides } from './shared';

// THEME COLORS: LIGHT
const hpRed = '#A6192E';
const lightAppleGreen = '#749116';
const blueSea = '#1975A6';
const featherLightGray = '#f2f2f2';
const blackGray = '#443c3c';
const mediumLightGray = '#d9d9d9';
const peach = '#DF6C2C';
const darkPeach = '#B25623';
const raspberry = '#cd0000';
const darkRaspberry = '#A40000';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    primaryAppbarBackgroundColor: string;
    secondaryAppbarBackgroundColor: string;
  }
  interface ThemeOptions {
    primaryAppbarBackgroundColor?: string;
    secondaryAppbarBackgroundColor?: string;
  }
}

export const HP_LIGHT_THEME = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: hpRed,
      light: hpRedLightened,
      dark: hpRedDarkened,
      contrastText: brightWhite,
    },
    secondary: {
      main: featherLightGray,
      light: brightWhite,
      dark: mediumLightGray,
      contrastText: trueBlack,
    },
    error: {
      main: raspberry,
      dark: darkRaspberry,
    },
    warning: {
      main: peach,
      dark: darkPeach,
    },
    info: {
      main: blueSea,
    },
    success: {
      main: lightAppleGreen,
    },
    background: {
      default: featherLightGray,
      paper: brightWhite,
    },
  },

  // App wide
  typography: PANCAKE_TYPOGRAPHY,

  // AppBar Styles
  primaryAppbarBackgroundColor: hpRed,
  secondaryAppbarBackgroundColor: featherLightGray,

  // Overrides
  // overrides: Object.assign({}, sharedOverrides, {
  //   MuiChip: {
  //     deleteIcon: {
  //       color: trueBlack,
  //     },
  //   },
  //   MuiStepIcon: {
  //     active: { color: hpRed + '!important' },
  //     completed: { color: lightAppleGreen + '!important' },
  //   },
  //   MUIDataTableToolbar: {
  //     icon: {
  //       '&:hover': {
  //         color: trueBlack,
  //       },
  //     },
  //     iconActive: {
  //       color: hpRed,
  //     },
  //   },
  //   MUIDataTableHeadCell: {
  //     root: {
  //       color: trueBlack,
  //     },
  //   },
  //   MUITableCell: {
  //     root: {
  //       color: trueBlack,
  //     },
  //   },
  //   MuiTableSortLabel: {
  //     icon: {
  //       color: trueBlack,
  //     },
  //   },
  // })

});
