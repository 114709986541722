import React, {createContext, useContext, ReactNode} from 'react';
import globalState, {GlobalState} from "./GlobalState";

// Create a context and a provider
const GlobalStateContext= createContext<GlobalState>(globalState);

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({children}) => {
  return (
      <GlobalStateContext.Provider value={globalState}>
        {children}
      </GlobalStateContext.Provider>
  );
};

// Create a custom hook to use the global state in any component
export const useGlobalState = (): GlobalState => {
  return useContext(GlobalStateContext);
};

// Using the state in a component
// const MyComponent = () => {
//   const globalState = useGlobalState();
//
//   return useObserver(() => (
//       <div>
//         Project Detail Refresh Time: {globalState.refreshTimes.projectDetail}
//         {/* Show other times as needed */}
//       </div>
//   ));
//};
