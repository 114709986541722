
export function hpParseInt(input: string) {
  //console.log('$$$@ hpParseInt', {inputType: typeof input, input});
  const str = numberToString(input);
  if (str == null) {
    return str;
  }
  const sanitized = str.replace(/[^\d.]/g, ''); // Remove non-digit, non-decimal characters
  const firstDecimalIndex = sanitized.indexOf('.');
  // Discard everything after the first decimal
  const cleanInput = firstDecimalIndex !== -1 ? sanitized.substring(0, firstDecimalIndex) : sanitized;
  return cleanInput ? parseInt(cleanInput, 10) : null;
}

export const numberToString = (input?: number | string | null): string | undefined | null => {
  if (input === undefined) return undefined;
  if (input === null) return null;
  if (typeof input === 'string') return input.toString();
  return input.toString();
}

