

export function focusedFindElement<T>(
    initialScope: string,
    focusedScope: string
): T | null {
  const initialElement = document.querySelector(initialScope);

  if (!initialElement) {
    return null;
  }

  const focusedElement = initialElement.querySelector(focusedScope);

  if (!focusedElement) {
    return null;
  }

  return focusedElement as T;
}