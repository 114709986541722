import React, {useState, useImperativeHandle, forwardRef, ForwardedRef} from "react";
import {
  ApiPhaseVdcTechnology,
  ApiProjectVdcTechnologyPhase,
  IApiProject,
  ProjectChallengeSolution
} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {Dialog} from "@material-ui/core";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {projectInfotips, formatInfotip} from "constants/metadata/infotips";
import { v4 as uuidv4 } from 'uuid';
import DashList2 from "../DashList2";
import {confirmDelete} from "patterns/confirmations";
import useToast from "hooks/useToast";
import DashToaster from "patterns/DashToaster";
import DashDetailsField from "../../../DashDetailsField";

import AddEditPBMTechDialog from "./AddEditPBMTechDialog";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {focusedFindElement} from "patterns/focusedFindElement";
import phases from './phases.json';
import _ from "lodash";
import VdcPhaseBlock from "./VdcPhaseBlock";
import DataRow from "patterns/DataRow";
import {useNavigate} from "react-router-dom";
import {DashResolverProvider} from "../../../../providers/DashResolver/DashResolver";
import globalState from "../../../../app/state/GlobalState";
import {hasPermission} from "../../../../app/security/utils/securityUtil";
import {useGlobalState} from "../../../../app/state/useGlobalState";

// end imports

const SECTION_ID = 'pbmPhasesSection';

const PBMTechnologyPhasesSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const gs = useGlobalState();
      const [saveCount, incr] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const toaster = useToast();
      const [model, setModel] = useState<IApiProject | null>(null);
      const [dataRows, setDataRows] = useState<DataRow<ApiProjectVdcTechnologyPhase>[]>();
      const [showInfotipModal, setShowInfotipModal] = useState(false);

      const [content, setContent] = useState('');
      const [sectionKey,setSectionKey] = useState(uuidv4());
      const [dialogOpen, setDialogOpen] = useState(false);
      const [dialogEditMode, setDialogEditMode] = useState<string>();
      const [dialogId, setDialogId] = useState<string>();
      const [dialogModel, setDialogModel] = useState<ApiPhaseVdcTechnology>();

      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      const allPhaseNames = phases.sort(x => x.order).map(x => x.label);
      const excludedLabels = props.model.vdcTechnologyPhases?.map(x => x.phaseLabel);

      const phaseNamesToDisplay  = _.difference(allPhaseNames, excludedLabels ?? []);
      console.log('!@! phaseNamesToDisplay on control load', {allPhaseNames,excludedLabels,'props.model.vdcTechnologyPhases':props.model.vdcTechnologyPhases});

      const dashToaster = new DashToaster(toaster.displayToast);

      if (model === null) {
        setModel(props.model);
        const tmpRows = props.model.vdcTechnologyPhases?.map(x => new DataRow(x)) || [];
        setDataRows(tmpRows);
      }
      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      function getTitleIfPossible(itemId?: string) {
        if (!itemId) return '';
        if (model?.vdcTechnologyPhases) {
          const tmpItem = model.vdcTechnologyPhases.find(x => x.id === itemId);
          return tmpItem?.phaseLabel ?? '';
        }
        return '';
      }

      function handleAction(action: string, payload?: string | DataRow<ApiProjectVdcTechnologyPhase> | undefined) {
        if (payload === undefined || typeof payload === 'string') {
          const parentId = model?.id as string;
          const id = payload as string;
          const title = getTitleIfPossible(payload);
          switch (action) {
            case 'edit':
              if (payload) {
                setDialogEditMode('edit');
                setDialogId(payload);
                if (model && model.challengesAndSolutions) {
                  const tmpDialogModel = model.vdcTechnologyPhases?.find(x => x.id === id) as ApiProjectVdcTechnologyPhase;
                  setDialogModel(tmpDialogModel);
                }
                setDialogOpen(true);
              }
              break;
            case 'new':
                // scoping - to avoid const/let in switch case
               {
                  const tmpEl = focusedFindElement<HTMLElement>('#' + SECTION_ID, '[data-dash-id="new-button"]');
                  handlePhaseMenuClick(tmpEl);
                }
              break;
            case 'new-phase':
              console.log(action);
              break;
            case 'delete':
              if (confirmDelete(title)) {
                athenaClient?.deleteChallengeSolution(parentId, id)
                    .then(result => {
                      console.log(result);
                      handleAction('close-refresh');
                      dashToaster.displayDeletedToast(title);
                    });
              }
              break;
            case 'close':
              setDialogOpen(false);
              setDialogId(undefined);
              setDialogModel(undefined);
              break;
            case 'refresh':
              setDialogOpen(false);
              setDialogId(undefined);
              setDialogModel(undefined);
              athenaClient?.getProject(props.model.id as string).then(project => {
                setModel(project);
                const tmpCollection = project.vdcTechnologyPhases?.map(x => new DataRow(x)) || [];
                setDataRows(tmpCollection);
              });
              break;

          }
        }
        if (typeof payload !== 'string') {
          console.log('!@@@! payload dump', payload);
        }
        if (payload instanceof DataRow) {
          processDataRow(payload as DataRow<ApiProjectVdcTechnologyPhase>);
        }
      }

      function processDataRow(dataRow: DataRow<ApiProjectVdcTechnologyPhase>) {
        const parentId = model?.id as string;
        const id = dataRow.value.id as string;
        switch (dataRow.status) {
          case 'added':
            athenaClient?.createProjectVdcTechnologyPhase(dataRow.value, parentId)
                .then(result => {
                  console.log(result);
                  handleAction('close');
                  dashToaster.displaySavedToast(dataRow.value.phaseLabel ?? '');
                  handleAction('refresh');
                });
            break;
          case 'modified':
            athenaClient?.updateProjectVdcTechnologyPhase(dataRow.value, dataRow.value.id as string, parentId)
                .then(result => {
                  console.log(result);
                  handleAction('close');
                  dashToaster.displaySavedToast(dataRow.value.phaseLabel ?? '');
                  handleAction('refresh');
                });
            break;
        }
      }

      const handlePhaseMenuClick = (elem: HTMLElement) => {
        setAnchorEl(elem);
      };

      const handlePhaseMenuClose = () => {
        setAnchorEl(null);
      };

      const technologyUrl = 'https://' +
          'hpcc2013.sharepoint.com' +
          '/:b:/s/VDCO/ETjGiC2Hd8RDkvjgnnHZYAABP1VYjgddVdUi8tIO3qpznQ?e=2DO5Q8';

      if (props.model === null || model === null) {
        console.log('project is null');
        return <DashItemProgress/>;
      }

      const setTags = (ds: DataRow<ApiProjectVdcTechnologyPhase>[]) => {
        ds.forEach(item => {
          item.tag = model.id;
        });
        return ds;
      }

      function handlePhaseMenuItemSelected(name?: string) {
        if (!name) return;

        console.log('@@@ display phase', name);
        // close menu
        setAnchorEl(null);
      }

      const roles = globalState.userRoles;
      const showAdd = hasPermission(['VDC_Contributor'], roles);
      return (
          <>
            <DashResolverProvider>
              <DashDetailsField mode={"reference"} label={'Technologies'} showLabel={true}
                                value={technologyUrl} />
              <DashList2 list={setTags(dataRows as DataRow<ApiProjectVdcTechnologyPhase>[])}
                         Component={VdcPhaseBlock}
                         showEdit={hasPermission(['VDC_Contributor'],gs.userRoles)}
                         showDelete={hasPermission(['VDC_Contributor'],gs.userRoles)}
                         onAction={handleAction}
                         addButtonText={'Add New Phase'}
                         showAdd={showAdd}
                         infotipKey={'vdcTechnologyPhase'}
                         titleExpression={(m) => m.value.phaseLabel ?? ''}
              />
            </DashResolverProvider>


            <DashDivider width="80vw" height="1px"/>
            {/*

            DIALOGS and MENUS below

            */}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handlePhaseMenuClose}
            >
              {phaseNamesToDisplay.map((name) => (
                  <MenuItem key={name} onClick={() => handlePhaseMenuItemSelected(name)}>
                    {name}
                  </MenuItem>
              ))}
            </Menu>

            {dialogOpen &&
                <></>

                // <AddEditCasDialog open={dialogOpen} parentModel={model} mode={dialogEditMode}
                //                   model={dialogModel}
                //                   choices={[]}
                //                   onRequestFromChild={handleAction} onInfotipClick={handleInfotipClick}/>
            }
          </>
      )
    });

export default withDashSection('Phases', SECTION_ID, PBMTechnologyPhasesSection,
    true);

