import React, {useEffect, useState} from 'react';
import {Location, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import noop from "utils/noop";
import {ICriteria, ICursorPos, IGridData, PeopleSearch, ProjectSearch} from 'services/SearchService';
import TemplateAndExportService from "services/TemplateAndExportService";
import {isEmptyObject, isNullish} from "utils/general";
import {makeStyles} from '@material-ui/core/styles';
import {IQueryablePerson} from "services/apiClients/AthenaClient";
import {prepareQueryResultRows} from "./ListView/listViewUtil";
import useCurrentUser from "hooks/useCurrentUser";
import {IPColumnDef} from "types/gridTypes";
import {encodeForAthenaFilters} from "services/search/QueryConvertor";
import { IndicatorType } from '@syncfusion/ej2-react-grids'; // Update the import path if needed
import {
  ColumnDirective,
  ColumnsDirective,
  DataStateChangeEventArgs,
  GridColumnModel,
  GridComponent,
  InfiniteScroll,
  Inject, LoadingIndicator, Resize,
  Sort,
  Sorts,
} from "@syncfusion/ej2-react-grids";
import {enableRipple} from '@syncfusion/ej2-base';
import useAthenaClient from "hooks/useAthenaClient";
import useDashUrlInfo from "hooks/useDashUrlInfo";
import AthenaOrderbyTranslator from "../../services/athenaSpecific/AthenaOrderbyTranslator";

enableRipple(false);

const useStyles = makeStyles(() => ({
  statsToolbar: {
    padding: 0,
    border: '1px solid #ccc',
    backgroundColor: '#eee',
  },
}));

const formatFields = (fields: string[], direction?: string): string => {
  if (!fields || fields.length === 0) return '';
  if (direction === undefined) return '';

  let formattedField = fields[0];
  if (direction === 'desc' || direction === 'descending') {
    formattedField += ' desc';
  } else if (direction === 'asc' || direction === 'ascending') {
    formattedField += ' asc';
  }

  return [formattedField, ...fields.slice(1)].join(', ');
};


export default function SearchGrid() {
  const classes = useStyles();
  const athenaOrderByTranslator = new AthenaOrderbyTranslator();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const {athenaClient} = useAthenaClient();
  const [columns, setColumns] = useState<IPColumnDef[]>([]);

  const pageSettings = { pageSize: 50 };
  const { entityName, id, filters, search } = useDashUrlInfo();

  function getCriteria() {
    const criteria: ICriteria = {
      entityName: entityName,
      search: search,
      filter: encodeForAthenaFilters(filters),
      orderby:'',
    }
    return criteria;
  }

  useEffect(() => {
    // todo: search column defaults and config - update this to be correct - backtrack it
    // compare to original
    async function setupColumns() {
      if (athenaClient) {
        const criteria = getCriteria();
        console.log('!@@ useEffect of SearchGrid.tsx', criteria);
        const templateAndExport = new TemplateAndExportService(athenaClient);
        let columnDefs = await templateAndExport.getSearchColumnDefsFromTemplate(
            currentUser?.userPrincipalName ?? '', criteria.entityName, 'searchResults');
        if (!columnDefs) {
          columnDefs = templateAndExport.getSearchColumnDefsFromDefaults(criteria.entityName);
        }
        setColumns(columnDefs);
      }
    }

    if (!isNullish(currentUser) && !isEmptyObject(currentUser)) {
      setupColumns();
    }
  },[athenaClient, entityName, filters, search]);

  function getGrid() {
    const el = document.querySelector('.e-grid') as HTMLElement & {ej2_instances:GridComponent[]};
    return el.ej2_instances[0] as GridComponent;
  }

  function onload() {
    const state = { skip: 0, take: pageSettings.pageSize };
    dataStateChange(state);
  }

  function convertSort(sortInfo?: Sorts[]) {
    if (sortInfo === undefined) {
      return '';
    } else {
      const fields = athenaOrderByTranslator.translate(sortInfo[0].name).split(',');
      return formatFields(fields, sortInfo[0].direction);
    }
  }

  const dataStateChange = (state: DataStateChangeEventArgs) => {
    if (!athenaClient) return;
    const criteria = getCriteria();
    // next 6 lines are needed to avoid a CSS width issue with the Grid #mjl
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const grid = document.getElementsByClassName('e-grid')[0].ej2_instances[0] as GridComponent;
    grid.gridLines = 'None';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementsByClassName('e-grid')[0].style.width = '100%';
    const search = criteria.entityName === 'people'
        ? new PeopleSearch(athenaClient)
        : new ProjectSearch(athenaClient);

    const currentSorts = convertSort(state.sorted);
    console.log('!@#- sort info', {'state.sorted': state.sorted, 'currentSorts': currentSorts});
    const tmpCriteria = {entityName: criteria.entityName, search: criteria.search,
                          filter: criteria.filter, orderby: currentSorts};



    if (state.action && (state.action.requestType)) {
      switch (state.action.requestType) {
        case 'sorting':
          search.entitySearch(tmpCriteria,state.skip ?? 0, state.take ?? 0)
              .then(gridData => {
                const newGridData = {
                  result: prepareQueryResultRows('people', gridData.result as IQueryablePerson[])
                };
                grid.dataSource = { result: newGridData.result };
                if (state.dataSource) {
                  state.dataSource(gridData);
                } else {
                  console.log('state.dataSource not defined');
                }
              });
          break;

        case 'infiniteScroll':
          search.entitySearch(tmpCriteria,state.skip ?? 0, state.take ?? 0)
            .then(gridData => {
              noop(gridData)
              const newGridData = {
                result: prepareQueryResultRows(criteria.entityName, gridData.result as IQueryablePerson[])
              };
              grid.dataSource = { result: newGridData.result };
            });
      }
    } else {
      search.entitySearch({...tmpCriteria, orderby: ''},state.skip ?? 0, state.take ?? 0)
          .then(gridData => {
            if (gridData.result) {
              if (gridData && gridData.result && gridData.result.length) {
              //$console.log('one record', (gridData.result[0]));
            }
              const newGridData = {
                count: gridData.count,
                result: prepareQueryResultRows(
                    criteria.entityName, gridData.result as IQueryablePerson[])
              }
              grid.dataSource = newGridData;
            }
          });
    }
  };

  const rowSelected = () => {
    const record = getGrid().getSelectedRecords()[0];
    const {id} = record as { id: string };
    const urlPart = entityName.toLowerCase().trim();
    const path = `/${urlPart}/${id}`;
    navigate(path);
  }

  const dqsJSON = JSON.stringify({entityName,filters,search});
  const gridKey = dqsJSON.trim();

  //const spinnerType: "Spinner" | "Shimmer" = "Spinner"; // or "Shimmer"

  // const loadingIndicatorProps = {
  //   indicatorType: spinnerType
  // };

  return (columns && columns.length > 0) ? (
      <GridComponent key={gridKey} load={onload}
                     rowSelected={rowSelected}
                     allowResizing={true}
                    enableInfiniteScrolling={true}
                    infiniteScrollSettings={{initialBlocks: 1}}
                    width={'99.5%'}
                     height={'100%'}
                    // loadingIndicator={loadingIndicatorProps}

                    allowSorting={true} gridLines={'Both'}
                    pageSettings={pageSettings}
                    dataStateChange={(state: DataStateChangeEventArgs) => dataStateChange(state)}
                  >
                  <ColumnsDirective>
                    {columns.map((c: IPColumnDef) => {
                      const columnSpec = c.columnSpec as Partial<GridColumnModel>;
                      return c.field !== 'isSalary' ?(
                          <ColumnDirective key={columnSpec.field} {...columnSpec} allowSorting={c.type !== 'array' ? true : false} />
                      ) : ''
                    })}
                  </ColumnsDirective>
                  <Inject services={[InfiniteScroll, Sort, Resize]}/>
                </GridComponent>
  ): <></>;
}