import useAthenaClient from 'hooks/useAthenaClient';
import useDashUrlInfo from 'hooks/useDashUrlInfo';
import React, {Fragment, useEffect, useState} from 'react';
import {
  IPeopleSearchStatistics,
  IProjectSearchStatistics,
} from 'services/apiClients/AthenaClient';
import { encodeForAthenaFilters } from 'services/search/QueryConvertor';
import { commasFmt, currencyFmt } from 'utils/numberToStringUtil';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import { ICriteria, PeopleSearch, ProjectSearch } from '../../../services/SearchService';
import { DashItemProgress } from '../../dashCore/DashItemProgress';
import globalState from "../../../app/state/GlobalState";
import {h} from "../../../app/reactUtils";

const useStyles = makeStyles(() => ({
  statsToolbar: {
    padding: 0,
    border: '1px solid #ccc',
    backgroundColor: '#eee',
  },
  statsList: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    padding: 0,
    "& li": {
      fontSize: '10pt',
      maxWidth: '220px',
      //minWidth: '50px',
      padding: '0 15px 0 5px',
    },
    "& .MuiListItemIcon-root": {
      backgroundColor: '#999',
      // filter: 'invert(1)',
      minHeight: '24px',
      minWidth: '24px',
      verticalAlign: 'middle',
      padding: '8px',
      margin: '8px',
      borderRadius: '50%',
    },
    "& .MuiSvgIcon-root": {
      fill: '#fff',
    },
    "& .MuiListItemText-primary": {
      whiteSpace: 'nowrap'
    },
    "& .MuiListItemText-secondary": {
      whiteSpace: 'nowrap'
    }
  }
}));

export interface IStatsData {
  entityName: string;
  stats: IPeopleSearchStatistics | IProjectSearchStatistics;
}

interface BarCellProps {
  secondaryText: string;
  icon: React.ReactElement;
  primary?: string;
}

const BarCell: React.FC<BarCellProps> = ({ secondaryText, icon, primary }) => {
  const listItem = h(ListItemText, { secondary: secondaryText, primary: primary });

  return h(
      Fragment,
      null,
      h(ListItem, null, h(ListItemIcon, null, icon), listItem),
      h(Divider, { orientation: "vertical", flexItem: true })
  );
};

/**
 * People stats
 * @param stats
 * @returns
 */
const makePeopleStatsListItems = (stats: IPeopleSearchStatistics | null) => {
  return (
      <>
        <BarCell secondaryText="People"
                 icon={<PeopleIcon />}
                 primary={stats?.recordCount ? commasFmt.format(stats!.recordCount!) : '0'} />

        <BarCell secondaryText="Total Value"
                 icon={<AttachMoneyIcon />}
                 primary={stats?.totalValue ? currencyFmt.format(stats!.totalValue!) : '$0'} />

        <BarCell secondaryText="FT²"
                 icon={<BorderInnerIcon />}
                 primary={stats?.totalSquareFootage ? commasFmt.format(stats!.totalSquareFootage!) : '0'} />

        <BarCell secondaryText="Total Industry Years"
                 icon={<TagFacesIcon />}
                 primary={stats?.totalIndustryYears ? commasFmt.format(stats!.totalIndustryYears!) : '0'} />

        <BarCell secondaryText="Total HP Years"
                 icon={<TagFacesIcon />}
                 primary={stats?.totalHPYears ? commasFmt.format(stats!.totalHPYears!) : '0'} />
      </>
  );
}

/**
 * Project stats
 * @param stats
 * @returns
 */
const makeProjectsStatsListItems = (stats: IProjectSearchStatistics | null) => {
  return (<>
      <BarCell secondaryText="Projects"
               icon={<BusinessIcon />}
               primary={stats?.recordCount ? commasFmt.format(stats!.recordCount!) : '0'} />

      <BarCell secondaryText="Total Value"
               icon={<AttachMoneyIcon />}
               primary={stats?.totalValue ? currencyFmt.format(stats!.totalValue!) : '$0'} />

      <BarCell secondaryText="FT²"
               icon={<BorderInnerIcon />}
               primary={stats?.totalSquareFootage ? commasFmt.format(stats!.totalSquareFootage!) : '0'} />
  </>);
}

export default function StatsBar() {
  const classes = useStyles();
  const { athenaClient } = useAthenaClient();
  let tmpStatsData: { stats: IProjectSearchStatistics, entityName: string } | undefined;
  const { entityName, id, filters, search } = useDashUrlInfo();
  const [loading, setLoading] = useState(true);
  const [statsData, setStatsData] =
    useState<{ entityName: string, stats: IPeopleSearchStatistics | IProjectSearchStatistics } | null>(null);
  ////const setRecordCount = useSetRecoilState(flexState('current-record-count'));

  const filterStr = encodeForAthenaFilters(filters);
  const criteria: ICriteria = {
    entityName: entityName,
    search: search ?? '',
    filter: filterStr,
    orderby: ''
  }

  useEffect(() => {
    if (tmpStatsData) {
      setStatsData(tmpStatsData);
    }
  }, [tmpStatsData]);

  useEffect(() => {
    const refresh = async () => {
      if (athenaClient) {
        const peopleSearch = new PeopleSearch(athenaClient);
        const projectsSearch = new ProjectSearch(athenaClient);
        const tmpData = (criteria.entityName === 'people') ?
          await peopleSearch.getSearchStats(criteria) :
          await projectsSearch.getSearchStats(criteria);
        tmpStatsData = {
          stats: entityName === "projects" ?
            tmpData ?? {} as IProjectSearchStatistics :
            tmpData ?? {} as IPeopleSearchStatistics,
          entityName: entityName
        };

        setLoading(false);
        setStatsData(tmpStatsData);
        //setRecordCount(tmpStatsData.stats.recordCount);
        if (tmpStatsData.stats.recordCount !== globalState.currentRecordCount) {
          globalState.setCurrentRecordCount(tmpStatsData.stats.recordCount ?? 0);
        }
      }
    };

    setLoading(true);
    refresh();
  }, [athenaClient, entityName, search, filters]);

  if (loading) {
    return <div style={{ height: '56px' }}>
      <DashItemProgress />
    </div>;
  }

  return (
    <div>
      <Toolbar className={classes.statsToolbar}>
        <List className={classes.statsList}>
          {(entityName === 'people') ?
            makePeopleStatsListItems(statsData?.stats ?? null) :
            makeProjectsStatsListItems(statsData?.stats ?? null)}
        </List>
      </Toolbar>
    </div>
  );
}
