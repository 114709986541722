

export default class PassThru {
  static log(comment: string, value: any) {
    console.log(comment, value);
    return value;
  }

  static logTests(comment: string, ...values: any[]) {
    console.log(comment, ...values);
    const allPassed = values.every(Boolean);
    return allPassed;
  }
}